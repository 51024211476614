import {RwApiAuthError} from "@/app/RwEnums";

export class RwApiAuth {
    public ApiKeyId: string;
    public ApiKey: string;
    public AccountId: string;
    public OrgId: string;
    public CD: Date;
    public LU: Date;
    public IsActive: boolean;
    public Error: RwApiAuthError;

    constructor(json?: Object) {
        if (json != null) {
            this.fromJson(json);
        }
    }

    fromJson(json: Object) {
        let skuInfo = new RwApiAuth();

        for (let key in json) {
            switch (key) {
                case "ApiKeyId":
                    this.ApiKeyId = json[key];
                    break;
                case "ApiKey":
                    this.ApiKey = json[key];
                    break;
                case "AccountId":
                    this.AccountId = json[key];
                    break;
                case "CD":
                    this.CD = new Date(json[key]);
                    break;
                case "LU":
                    this.LU = new Date(json[key]);
                    break;
                case "IsActive":
                    this.IsActive = json[key];
                    break;
                case "OrgId":
                    this.OrgId = json[key];
                    break;
                case "Error":
                    this.Error = json[key];
                    break;
                //case "active": if (json[key] === 1) { this.active = true } else { this.active = false; } break;
                //case "exp": this.expires = new Date(json[key]); break;
                //case "quantity": this.quantity = json[key]; break;
                //case "sku": this.sku = json[key]; break;
            }
        }
    }
}
