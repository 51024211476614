import {RwUser} from "@/app/dem/RwUser";
import {ResubscribeModel} from "@/app/dem/Profile/RwRegResponse";
import {RwSubscribeResult} from "@/app/dem/Profile/RwSubscribeResult";
import {RwProfileModel} from "@/app//dem/Profile/RwProfileModel";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import theGlobals from "@/app/RwGlobals";
import {RwError, RwLoginError} from "@/app/RwErrors";
import {MigrateError, MigrateLegacyToFlexResult} from "@/app/dem/Profile/MigrateLegacyToFlexResult";
import {RwLog} from "@/app/dal/RwLog";
import {ResumeAutoRenewError, RwLoginIssues, RwSetNewEmailEnum} from "@/app/RwEnums";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import RwSetNewEmailResponse from "@/app/dem/Profile/RwSetNewEmailResponse";
import RwCouponResult from "@/app/dem/Profile/RwCouponResult";
import {RWCPPResult} from "@/app/views/account/purch/RWCPPResult";


export class RwTaskBillingProfile {

    static ReSubWithExistingCC(model: ResubscribeModel): Promise<RwSubscribeResult> {
        return new Promise<RwSubscribeResult>(function (resolve, reject) {
            let payload = JSON.stringify(model);
            let url = `${RwConstants.CoreUri}/profile/ReSubWithExistingCC`;

            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.ExtendedTimeout})
                .then(res => {
                    const json = res.data;
                    resolve(<RwSubscribeResult>json);
                })
                .catch((error: RwError) => {
                    reject(error);
                });
        });
    }

    static ValidateCoupon(couponCode: string): Promise<RwCouponResult> {
        return new Promise<RwCouponResult>((resolve, reject) => {
            let url = `${RwConstants.CoreUri}/profile/ValidateCoupon?couponCode=${couponCode}`;
            dal.callWithToken
                .get(url, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    resolve(<RwCouponResult>json);
                })
                .catch((error: RwError) => {
                    reject(error);
                });
        });
    }

    static ApplyCoupon(couponCode: string): Promise<RwCouponResult> {
        return new Promise<RwCouponResult>((resolve, reject) => {
            let url = `${RwConstants.CoreUri}/profile/ApplyCoupon?couponCode=${couponCode}`;
            dal.callWithToken
                .get(url, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    resolve(<RwCouponResult>json);
                })
                .catch((error: RwError) => {
                    reject(error);
                });
        });
    }

    static MigrateLegacyToFlex(driversToKeep: Array<string>): Promise<void> {
        const SOURCE = "MigrateLegacyToFlex";
        return new Promise<void>(function (resolve, reject) {
            let payload = JSON.stringify(driversToKeep);
            let url = `${RwConstants.CoreUri}/profile/MigrateLegacyToFlex`;
            dal.callWithToken
                .post(url, payload)
                .then(res => {
                    const json = <MigrateLegacyToFlexResult>res.data;
                    if (res.status === 200 && json && (json.Error === undefined || json.Error == MigrateError.None)) {
                        resolve();
                    } else {
                        let respText = JSON.stringify(res.data);
                        let errText = json && json.Error ? MigrateError[json.Error] : '';
                        RwLog.error(SOURCE, `Status: ${res.status}, Error: ${errText} \n\n resp:${respText} \n\n payload: ${payload}`);
                        reject(json);
                    }
                })
                .catch((error: RwError) => {
                    const respText = error.error?.response?.data;
                    const errText = respText && respText.Error ? MigrateError[respText.Error] : '';
                    RwLog.error(SOURCE, `Status: ${error.status}, Error: ${errText} \n\n resp: ${respText} \n\n payload: ${payload}`);
                    reject(respText);
                });
        });
    }

    static GetBillingProfile(): Promise<RwProfileModel> {
        const SOURCE = "RwTaskBilling.GetBillingProfile";
        const self = this;
        return new Promise<RwProfileModel>(function (resolve, reject) {

            if (dal.gotToken()) {

                let url = `${RwConstants.CoreUri}/profile/GetBillingProfile`;
                dal.callWithToken
                    .get(url)
                    .then(res => {
                        if (res.status === 204) {
                            resolve(null);
                        } else {
                            const json = res.data;
                            const profileObj = <RwProfileModel>json;
                            profileObj.Drivers = RwUser.fromJsonArray(json.Drivers);
                            if (profileObj.PODEnabled) {
                                theGlobals.showProofOfDelivery = true;
                            }
                            else if (theGlobals.hasOnboarded) {
                                theGlobals.setShowPODCallout();
                            }
                            resolve(profileObj);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });

            } else {
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }

    static GetReSubPurchProfile(): Promise<RwProfileModel> {
        return new Promise<RwProfileModel>(function (resolve, reject) {
            let url = `${RwConstants.CoreUri}/profile/GetReSubPurchProfile`;
            dal.callWithToken
                .get(url)
                .then(res => {
                    if (res.status === 204) {
                        resolve(null);
                    } else {
                        const json = res.data;

                        let purchProfile = json as RwProfileModel;
                        theGlobals.hasCurrPurch = purchProfile.ProStatus.IsPro;
                        purchProfile.Drivers = RwUser.fromJsonArray(json.Drivers);
                        resolve(purchProfile);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static SetNewEmail(newUserName: string, currentPassword: string): Promise<RwSetNewEmailEnum> {
        const self = this;
        return new Promise<RwSetNewEmailEnum>(function (resolve, reject) {

            let unameEnc = encodeURIComponent(newUserName);
            let pwEnc = encodeURIComponent(currentPassword)
            let url = `${RwConstants.CoreUri}/profile/SetNewEmail?email=${unameEnc}&password=${pwEnc}`;
            dal.callWithToken
                .post(url, null)
                .then(res => {
                    //console.log("SetNewEmail callback", res)
                    //On Timeout, res == null
                    if (res && res.data) {
                        let response = res.data as RwSetNewEmailResponse;
                        //debugger;
                        if (!response.error) {
                            theGlobals.token = response.token;
                            resolve(RwSetNewEmailEnum.Success);
                        } else {
                            resolve(response.error);
                        }
                    } else {
                        resolve(RwSetNewEmailEnum.InternalError)
                    }
                })
                .catch((error) => {
                    // console.error("Error is in the DAL")
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });

        });
    }

    static CancelAutoRenew(): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/profile/CancelAutoRenew`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static HasTrial(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const data = RwPrefUtils.attribData;
            let url = `${RwConstants.CoreUri}/profile/HasTrial`;
            dal.callSansToken
                .post(url, data)
                .then(res => {
                    if (res.status === 202) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static CancelTrial(): Promise<ResumeAutoRenewError> {
        return new Promise<ResumeAutoRenewError>((resolve, reject) => {
            let url = `${RwConstants.CoreUri}/profile/CancelTrial`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    if (res.data) {
                        if (!res.data.Error) {
                            resolve(ResumeAutoRenewError.None);
                        } else {
                            resolve(res.data.Error);
                        }
                    } else {
                        resolve(ResumeAutoRenewError.UnhandledError);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static ResumeAutoRenew(): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/profile/ResumeAutoRenew`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    if (res.data.Error && res.data.Error != ResumeAutoRenewError.None) {
                        reject(res.data.Error);
                        return;
                    }
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static ResolveCPP(ppb: string, ppd: string, ppt: string): Promise<RWCPPResult> {
        return new Promise<RWCPPResult>((resolve, reject) => {
            let url = `${RwConstants.CoreUri}/profile/ResolveCPP?ppb=${ppb}&ppd=${ppd}&ppt=${ppt}`;

            if (theGlobals.isLoggedIn) {
                dal.callWithToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        const json = res.data;
                        resolve(<RWCPPResult>json);
                    })
                    .catch((error: RwError) => {
                        reject(error);
                    });
            } else {
                dal.callSansToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        const json = res.data;
                        resolve(<RWCPPResult>json);
                    })
                    .catch((error: RwError) => {
                        reject(error);
                    });
            }

        });
    }
}
