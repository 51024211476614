import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwSubTeam} from "@/app/dem/RwSubTeam";

export default {
    state: {
        subTeams: [] as RwSubTeam[],
        showSubTeamPop: false,
        showSubTeamDock: RwPrefUtils.showSubTeamDock,
        selectedSubTeam: null,
        showSubTeamEdit: false,
        popupView: RwPrefUtils.subTeamPopupView
    },
    getters: {

        subTeams(state): RwSubTeam[] {
            return state.subTeams;
        },

        showSubTeamDock(state): boolean {
            return state.showSubTeamDock;
        },

        showSubTeamPop(state): boolean {
            return state.showSubTeamPop;
        },

        selectedSubTeam(state): RwSubTeam {
            return state.selectedSubTeam;
        },

        showSubTeamEdit(state): boolean {
            return state.showSubTeamEdit;
        },

        popupView(state): boolean {
            return state.popupView;
        }
    },
    actions: {
        subTeams({state, commit}, val: RwSubTeam[]) {
            commit("SUB_TEAMS", val);
        },

        showSubTeamDock({state, commit}, val: boolean) {
            commit("SHOW_SUBTEAM_DOCK", val);
        },

        showSubTeamPop({state, commit}, val: boolean) {
            commit("SHOW_SUBTEAM_POP", val);
        },

        selectedSubTeam({state, commit}, val: RwSubTeam) {
            commit("SELECTED_SUB_TEAM", val);
        },

        showSubTeamEdit({state, commit}, val: boolean) {
            commit("SHOW_SUB_TEAM_EDIT", val);
        },

        popupView({state, commit}, val: boolean) {
            commit("POPUP_VIEW", val);
        }
    },
    mutations: {
        SUB_TEAMS(state, val: RwSubTeam[]) {
            state.subTeams = val;
        },

        SHOW_SUBTEAM_DOCK(state, val: boolean) {
            state.showSubTeamDock = val;
        },

        SHOW_SUBTEAM_POP(state, val: boolean) {
            state.showSubTeamPop = val;
        },

        SELECTED_SUB_TEAM(state, val: RwSubTeam) {
            state.selectedSubTeam = val;
        },

        SHOW_SUB_TEAM_EDIT(state, val: boolean) {
            state.showSubTeamEdit = val;
        },

        POPUP_VIEW(state, val: boolean) {
            state.popupView = val;
        }
    }
};
