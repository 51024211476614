

import {Component, Prop, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwLog} from "@/app/dal/RwLog";
import {RwImgUtils} from "@/app/utils/RwImgUtils";


@Component
export default class RwColorPicker extends Vue {
  @Prop(String) readonly initColor: string | undefined;


  //#region Props

  m_color = "#FF0000FF";

  get globals(): RwGlobals {
    return theGlobals;
  }

  get space(): RwPlannerSpace {
    return thePlanSpace;
  }

  get color(): string {
    return this.m_color;
  }

  set color(val: string) {
    //console.log("set color:", val);
    this.m_color = val;
    this.onColorPick(val);
  }

  get backColor() {
    return this.globals.isDarkMode ? "rwBackDark" : "rwBackLight";
  }

  get loading(): boolean {
    return this.globals.isLoading;
  }

  get isDarkMode(): boolean {
    return this.globals.isDarkMode;
  }


  //#endregion Props


  //#region User Events


  onEscape(event: Event) {
    event.stopPropagation();
    this.close();
  }


  onColorPick(color: string) {
    if (RwImgUtils.validatedColorHex(color)) {
      this.close(color);
    } else {
      RwLog.error("RwColorPicker.onColorPick", `Invalid color hex: ${color}`);
    }
  }

  onCancel() {
    this.close();
  }


  close(colorPick?: string) {
    if (colorPick) {
      //console.log("RwColorPicker.close color", colorPick);
      this.$emit("onColorPick", colorPick);
    }
    this.globals.m_showColorPicker = false;
  }


  //#endregion UI Nav


  //#region LifeCycle

  mounted() {
    //console.log('RwColorPicker.mounted colorInit', this.colorInit);
    if (this.initColor && this.initColor.length > 0) {
      this.m_color = this.initColor;
    }
  }

  //#endregion


}

