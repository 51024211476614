

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwRoute, RwRoutePick} from "@/app/dem/RwRoute";
import {RwLog} from "@/app/dal/RwLog";

@Component
export default class RwGenericRoutePicker extends Vue {
  //#region Props
  @Prop(String) readonly initSrcId: string;
  @Prop(Boolean) readonly open: boolean;
  @Prop(Boolean) readonly showNewRoute: boolean;
  @Prop() readonly filter: any;

  searchFilter = "";

  get globals(): RwGlobals {
    return theGlobals;
  }

  get space(): RwPlannerSpace {
    return thePlanSpace;
  }

  get title(): string {
    return "Pick Target Route";
  }

  @Watch("open", {immediate: true})
  onOpenChanged(openVal: boolean) {
    if (openVal) {
      setTimeout(() => {
        if (this.$refs.search) {
          (this.$refs.search as any).$el.querySelector("input").focus();
        }
      }, 500)
    }
  }

  get targetRoutes(): RwRoute[] {
    let routes: RwRoute[] = [];
    let srcId = this.initSrcId;
    routes = this.globals.routes;
    routes = routes.filter(this.filter ?? (() => true));
    if (srcId && srcId.length > 0) {
      routes = routes.filter(r => r.routeId != srcId);
      routes = routes.sort((r1, r2) => r2.startTime.getTime() > r1.startTime.getTime() ? -1 : 1);
    } else {
      routes = this.globals.routes;
    }

    if (this.searchFilter) {
      const srch = this.searchFilter.toLowerCase().trim();
      routes = routes.filter(rt => rt.name?.toLowerCase()?.indexOf(srch) !== -1);
    }

    return routes;
  }

  //#endregion


  //#region Navigation

  onEscape(event: Event) {
    event.stopPropagation();
    this.close();
  }

  //#endregion Navigation


  onRoutePick(route: RwRoute, isNew = false) {
    const pick = new RwRoutePick();
    pick.isMoveOp = false;
    pick.tarRouteId = route.routeId;
    pick.isNew = isNew;
    this.$emit("onRoutePick", pick);
    this.close();
  }


  close() {
    this.$emit("onClose");
  }

  mounted() {
  }

  newRoute() {
    const SOURCE = "RwGenericRoutePicker.newRoute";
    let route = RwRoute.blankRoute();
    this.globals.addRouteDB(route, [SOURCE], false)
        .then(routeNew => {
          this.globals.addRoute(route);
          this.onRoutePick(routeNew, true);
        })
        .catch(err => {
          let errDesc = err && err.description ? err.description : "no description";
          this.globals.showSnack(`Route Creation Failed: ${errDesc}`, "error");
          RwLog.error(SOURCE, `Unhandled: ${err}`);
        });
  }

}
