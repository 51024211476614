import {RwLoginIssues, RwPushNoteType, RwPushStatus} from "@/app/RwEnums";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import {RwError, RwLoginError} from "@/app/RwErrors";
import {RwRoute} from "@/app/dem/RwRoute";
import {RwPushNote} from "@/app/dem/Push/RwPushMessage";
import {RwLog} from "@/app/dal/RwLog";
import theGlobals from "@/app/RwGlobals";
import globals, {RwGlobals} from "@/app/RwGlobals";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import NotifyResponseModel from "@/app/dem/Push/RwNotifyResponseModel";

export class RwTaskPush {

    get globals(): RwGlobals {
        return theGlobals;
    }

    static updatePushToken(pushToken: string): Promise<boolean> {
        const SOURCE = "RwTaskPush.updatePushToken";

        return new Promise<boolean>(function (resolve, reject) {
            //console.log("RwTaskPush.sendPushToken");

            if (dal.gotToken()) {

                if (pushToken && theGlobals.gotUser && theGlobals.isLoggedIn) {

                    let payload = JSON.stringify({pushToken: pushToken});
                    let url = `${RwConstants.CoreUri}/push/updatePushToken`;

                    dal.callWithToken
                        .post(url, payload)
                        .then(res => {
                            //console.log("RwTaskPush.sendPushToken resolve(true)");
                            resolve(true);
                        })
                        .catch((err) => {
                            let status = RwTaskPush.resolveStatus(err);
                            let errStr = JSON.stringify(err);

                            if (globals.checkNotHandled(err)) {
                                if (status == 401) {
                                    RwLog.warn(SOURCE, `Unauthorized: \nPOST ${url} \nAuthToken:${RwPrefUtils.token}  \nPayload:\n${payload} \n\nError:\n${errStr} `);
                                } else if ((err.error && (err.error.code === "ECONNABORTED" || err.error.message === "Network Error"))
                                    || err.message === "Network Error") {
                                    RwLog.warn(SOURCE, `Network Error: ${errStr}`);
                                } else if (status === 403) {
                                    RwLog.warn(SOURCE, `Forbidden: ${errStr}`);
                                } else {
                                    RwLog.error(SOURCE, `Unhandled: \nPOST ${url} \nAuthToken:${RwPrefUtils.token} \nPayload:\n${payload} \n\nError:\n${errStr} `);
                                }
                            }

                            reject(err);
                        });
                } else {
                    //console.warn("sendPushToken NO PushToken");
                    reject(new RwError(201, false));
                }

            } else {
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }

    static resolveStatus(error): number {
        let status = 0;
        if (error) {
            if (error && error.config) {
                const url = error.config.url;
                const method = error.config.method;
                if (error.response) {
                    status = error.response ? error.response.status : null;
                }
            }
        }
        return status;
    }


    // static sendPushNoteXXX(pushNote: RwPushNote, suppressErrors: boolean = false): Promise<number> {
    //   return new Promise<number>(function (resolve, reject) {
    //
    //     //console.warn("sendPushNote", pushNote.Action, pushNote);
    //
    //     let payload = JSON.stringify(pushNote);
    //     let url = `${RwConstants.CoreUri}/push/sendMessage`;
    //     dal.axios
    //       .post(url, payload)
    //       .then(res => {
    //         //console.log("RwTaskPush.sendPushNote SUCCESS", res.status);
    //         resolve(res.status);
    //       })
    //       .catch((error) => {
    //         //REFACTOR: UI Code: Move to caller
    //         reject(error);
    //       });
    //
    //   });
    // }


    static sendPushNotify(routeId: string): Promise<NotifyResponseModel> {
        const SOURCE = "RwTaskPush.sendPushNotify";
        return new Promise<NotifyResponseModel>(function (resolve, reject) {

            //console.warn("sendPushNote", pushNote.Action, pushNote);
            //let payload = JSON.stringify(pushNote);

            let url = `${RwConstants.CoreUri}/routes/notify?rid=${routeId}`;
            if (dal.gotToken()) {
                dal.callWithToken
                    .get(url)
                    .then(res => {
                        //console.log(SOURCE, "callback SUCCESS", res.status);
                        resolve(res.data as NotifyResponseModel);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject({message: "invalid token"});
            }
        });
    }


    static sendPushDriverPing(driverId: string): Promise<any> {
        let self = this;

        return new Promise<any>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/push/pingUserDevices?userId=${driverId}`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    //console.log("RwTaskPush.sendPushDriverPing", "callback", res.data);
                    resolve(res)
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static getPushStatus(msgId: string, driverId): Promise<RwPushStatus> {
        const self = this;
        const SOURCE = "RwTaskPush.getPushStatus";

        return new Promise<RwPushStatus>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/push/checkPingStatus?userId=${driverId}&msgId=${msgId}`;
            dal.callWithToken
                .get(url)
                .then(res => {
                    //console.log(SOURCE, "callback", res);
                    let status = <RwPushStatus>parseInt(res.data);
                    resolve(status);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }


    static setPushMsgStatus(msgId: string, status: RwPushStatus): Promise<boolean> {
        let self = this;
        const SOURCE = "RwTaskPush.setPushMsgStatus";

        return new Promise<boolean>(function (resolve, reject) {

            if (dal.gotToken()) {

                let url = `${RwConstants.CoreUri}/push/updatePushStatus?msgId=${msgId}&status=${status}`;
                dal.callWithToken
                    .post(url)
                    .then(res => {
                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                RwLog.warn(SOURCE, `Invalid token: ${RwPrefUtils.token}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }


    static createPushRouteAssigned(route: RwRoute): RwPushNote {
        const SOURCE = "RwTaskPush.createPushRouteAssigned";
        let note = new RwPushNote();
        try {
            note.IsTopicMsg = false;
            note.Topic = null;
            note.UserId = route.driverId;
            note.PushType = RwPushNoteType.RouteAssigned;
            note.Title = `Route '${route.name}' assigned`;
            note.RouteId = route.routeId;
            note.Action = "assignment";
            if (route.isDynLoc) {
                note.IconType = "dynLoc";
            } else {
                note.IconType = (route.isRoundTrip) ? "fixRndTrip" : "fixOneWay";
            }

            // If optimized already
            let stopsText = route.stops && route.stops.length > 0 ? route.stops.length + " stops" : "";
            if (route.totalDist !== 0 && route.totalTime !== 0) {
                note.Body = `${route.totalTimeString} · ${route.totalDistText} · ${stopsText}`;
            } else {
                note.Body = `${stopsText}`;
            }
        } catch (ex) {
            RwLog.error(SOURCE, `Unhandled: ${ex}`);
        }

        return note;
    }


    static createPushRouteUpdate(route: RwRoute): RwPushNote {
        const SOURCE = "RwTaskPush.createPushRouteUpdate";
        let note = new RwPushNote();
        try {
            note.IsTopicMsg = false;
            note.Topic = null;
            note.UserId = route.driverId;
            note.PushType = RwPushNoteType.RouteDelta;
            note.Title = `Route '${route.name}'`;
            note.Body = `This route has been modified.'`;
            note.RouteId = route.routeId;
            note.Action = "routeupdated";
            if (route.isDynLoc) {
                note.IconType = "dynLoc";
            } else {
                note.IconType = (route.isRoundTrip) ? "fixRndTrip" : "fixOneWay";
            }

            // If optimized already
            // let stopsText = route.stops && route.stops.length > 0 ? route.stops.length + " stops" : "";
            // if (route.totalDist !== 0 && route.totalTime !== 0) {
            //   note.Body = `${route.totalTimeString} · ${route.totalDistText} · ${stopsText}`;
            // }
            // else {
            //   note.Body = `${stopsText}`;
            // }
        } catch (ex) {
            RwLog.error(SOURCE, `Unhandled: ${ex}`);
        }

        return note;
    }


}