import Vue from "vue";
import Vuex from "vuex";
import {RwRoute} from "@/app/dem/RwRoute";
import {RwSked} from "@/app/dem/RwSked";
import {RwSite} from "@/app/dem/RwSite";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwAvatarInfo, RwUser} from "@/app/dem/RwUser";
import {RwPin} from '@/app/dem/RwPin';
import {RwHistory} from "@/app/dem/RwHistory";
import {RwStep} from "@/app/dem/RwStep";
import {RwStop} from "@/app/dem/RwStop";
import {RwSearchResult} from "@/app/dem/RwSearchResult";
import RwGenericDialog from "@/app/utils/RwGenericDialog";
import {RwPinTypes, VersionOutdatedEnum} from "@/app/RwEnums";

import SubTeamsModule from '@/store/subTeamsModule';
import UploadModule from "@/store/uploadModule";
import ReportsModule from "@/store/reportsModule";
import AdminModule from "@/store/adminModule";
import DialogModule from "@/store/dialogModule";
import RwAddReport from "@/app/dem/RwAddReport";
import PurchModule from "@/store/purchModule";
import OnboardModule from "@/store/onboardModule";
import PODModule from "@/store/podModule";

Vue.use(Vuex);

export default new Vuex.Store({

    modules: {
        subTeams: SubTeamsModule,
        uploads: UploadModule,
        reports: ReportsModule,
        admin: AdminModule,
        dialog: DialogModule,
        purch: PurchModule,
        onboard: OnboardModule,
        pod: PODModule
    },
    state: {
        loading: false,
        isDataLoaded: false,
        activeRoute: null,
        selectedRoute: null,
        routeToEdit: null,
        selectedRouteSpace: null,
        selectedRoutes: [],
        routes: new Array<RwRoute>(),
        hist: new Array<RwHistory>(),
        sites: new Array<RwSite>(),
        tasks: new Array<RwStop>(),
        drivers: new Array<RwUser>(),
        skeds: new Array<RwSked>(),
        openRoutes: new Array<RwRoute>(),
        isDarkMode: RwPrefUtils.rwTheme === "rwDark",
        isSimpleMap: RwPrefUtils.isSimpleMap,
        isTeam: RwPrefUtils.isTeam,
        isAutoRenewing: RwPrefUtils.isAutoRenewing,
        isProMonthly: RwPrefUtils.isProMonthly,
        isFlexSolo: RwPrefUtils.isFlexSolo,
        flexDisplay: RwPrefUtils.flexDisplay,
        isDisp: RwPrefUtils.isDisp,
        role: RwPrefUtils.role,
        isLoggedIn: RwPrefUtils.isLoggedIn,
        lastSyncMS: 0,
        lastDeltaMS: 0,
        lastStatusMS: 0,
        lastTokenMS: RwPrefUtils.lastTokenMS,
        driverLastSyncMS: 0,
        hasCurrPurch: RwPrefUtils.hasActiveSub,
        hasProBetaPrimed: RwPrefUtils.hasProBetaPrimed,
        updateStartPref: RwPrefUtils.updateStartPref,
        pinnedStatus: RwPrefUtils.pinnedStatus,
        token: RwPrefUtils.token,
        startPin: null,
        sitePins: new Array<RwPin>(),
        stopPins: new Array<RwPin>(),
        taskPins: new Array<RwPin>(),
        driverPins: new Array<RwPin>(),
        steps: new Array<RwStep>(),
        editAfterAdd: RwPrefUtils.editAfterAdd,
        editFromCopy: RwPrefUtils.editFromCopy,
        hidePingPopup: RwPrefUtils.hidePingPopup,
        hideDeletePopup: RwPrefUtils.hideDeletePopup,
        hideCreatePopup: RwPrefUtils.hideCreatePopup,
        hide450Popup: RwPrefUtils.hide450Popup,
        prompt450Popup: RwPrefUtils.prompt450Popup,
        warned450Popup: RwPrefUtils.warned450Popup,
        prompt500Popup: RwPrefUtils.prompt500Popup,
        hideTasksWarn: RwPrefUtils.hideTasksWarn,
        hideStopStatus: RwPrefUtils.hideStopStatus,

        hideCCPopup: RwPrefUtils.hideCCPopup,
        valCCPopup: RwPrefUtils.valCCPopup,
        warnedCCPopup: RwPrefUtils.warnedCCPopup,
        warnedCCPopupOverdue: RwPrefUtils.warnedCCPopupOverdue,

        //showEditDriver: RwPrefUtils.showEditDriver,
        showDriverEdit: false,
        driverEditInitDriver: null,

        showEditAvatar: RwPrefUtils.showEditAvatar,
        showEditDriverPass: RwPrefUtils.showEditDriverPass,
        showEditDriverUname: RwPrefUtils.showEditDriverUname,
        showReplaceDriver: RwPrefUtils.showReplaceDriver,
        showDeleteDriver: RwPrefUtils.showDeleteDriver,
        showCreateDriver: RwPrefUtils.showCreateDriver,
        showCreateBulkUser: RwPrefUtils.showCreateBulkUser,
        showDomainPopup: RwPrefUtils.showDomainPopup,
        showPingTest: RwPrefUtils.showPingTest,
        showDeletePrompt: RwPrefUtils.showDeletePrompt,
        showCreatePrompt: RwPrefUtils.showCreatePrompt,

        //showSkedEdit: RwPrefUtils.showSkedEdit,
        showSkedEdit: false,
        skedEditInitSked: null,

        showSkedCreate: RwPrefUtils.showSkedCreate,
        showProBetaPrimer: RwPrefUtils.showProBetaPrimer,
        showProfileEdit: RwPrefUtils.showProfileEdit,
        showLimitPopup: RwPrefUtils.showLimitPopup,
        // showUserSiteBanner: RwPrefUtils.showUserSiteBanner,
        hasClosedUserSiteBanner: RwPrefUtils.hasClosedUserSiteBanner,
        lastClosedPaymentBanner: RwPrefUtils.lastClosedPaymentBanner,
        showNewPass: RwPrefUtils.showNewPass,
        showRegistrationType: RwPrefUtils.showRegistrationType,
        showProToTeam: RwPrefUtils.showProToTeam,
        showCCExpire: RwPrefUtils.showCCExpire,
        // showSkedDelete: RwPrefUtils.showSkedDelete,
        // showEditDefault: RwPrefUtils.showEditDefault,
        // showStopDefault: RwPrefUtils.showStopDefault,
        // showTaskDefault: RwPrefUtils.showTaskDefault,
        // showSiteDefault: RwPrefUtils.showSiteDefault,
        // showOptDefault: RwPrefUtils.showOptDefault,
        // showTravelDefault: RwPrefUtils.showTravelDefault,
        // showOneWayDefault: RwPrefUtils.showOneWayDefault,
        // showAvoidsDefault: RwPrefUtils.showAvoidsDefault,
        // showUnitDefault: RwPrefUtils.showUnitDefault,
        // showSvcTimeDefault: RwPrefUtils.showSvcTimeDefault,
        // showRouteTimeDefault: RwPrefUtils.showRouteTimeDefault,
        // showCenterDefault: RwPrefUtils.showCenterDefault,
        // showStartDefault: RwPrefUtils.showStartDefault,
        selectedDriver: null,
        selectedSite: null,
        selectedSked: null,
        selectedStop: null,
        selectedTask: null,
        selectedPins: new Array<RwPin>(),
        multiMode: 0,
        multiModeTool: RwPrefUtils.planMultiModeTool,
        isMultiMode: false,
        mapShowTraffic: RwPrefUtils.mapShowTraffic,
        mapShowIncidents: RwPrefUtils.mapShowIncidents,
        mapShowSatellite: RwPrefUtils.mapShowSatellite,
        showDataLayers: false,
        showPanelStatus: RwPrefUtils.showPanelStatus,
        showPanelMap: RwPrefUtils.showPanelMap,
        showPanelList: RwPrefUtils.showPanelList,
        showPinPanel: RwPrefUtils.showPinPanel,
        showSearchPanel: false,
        highLightManual: 0,
        sizeDelta: 0,
        avInfo: {
            avInits: RwPrefUtils.avInits,
            avColor: RwPrefUtils.avColor,
            avType: RwPrefUtils.avType,
            avUrl: RwPrefUtils.avUrl,
            userId: RwPrefUtils.userId
        },
        // avUrl: RwPrefUtils.avUrl,
        // avType: RwPrefUtils.avType,
        // avColor: RwPrefUtils.avColor,
        // avInits: RwPrefUtils.avInits,
        showStopEdit: false,
        showHistoryEdit: false,

        showRouteEdit: false,
        routeEditInitRoute: null,

        showRouteAssign: false,
        showRouteCopy: false,
        // showRouteArchive: false,
        showRouteComplete: false,
        showRouteDelete: false,
        showSiteEdit: false,
        siteEditInitSite: null,
        showSiteCreate: false,
        // showSiteRelocate: false,
        // showSiteDelete: false,
        showSiteMultiColor: false,
        showTaskEdit: false,
        showPinPopup: false,
        showRouteOrder: false,
        showRouteSummary: false,
        showAddReport: false,
        showAddMissingReport: false,
        addReport: null,
        selectedSearchResult: null,
        searchReportInfo: [],
        showDataLayerRoute: RwPrefUtils.showDataLayerRoute,
        showDataLayerSites: RwPrefUtils.showDataLayerSites,
        showDataLayerTasks: RwPrefUtils.showDataLayerTasks,
        //showDataLayerDrivers: RwPrefUtils.showDataLayerDrivers,
        searchResultsWeb: [],
        searchResultsSites: [],
        searchResultsTasks: [],
        searchResultsCoords: [],
        snackBarText: "",
        snackBarColor: "primary",
        showSnackbar: false,
        showClusterPicker: false,
        selectedClusterPin: null,
        showStopPop: false,
        showStopDock: RwPrefUtils.showStopDock,
        showDriverPop: false,
        showDriverDock: RwPrefUtils.showDriverDock,
        showSitePop: false,
        showSiteDock: RwPrefUtils.showSiteDock,
        showSkedPop: false,
        showSkedDock: RwPrefUtils.showSkedDock,
        showRoutePop: false,
        showRouteDock: RwPrefUtils.showRouteDock,
        isOpenDrawer: RwPrefUtils.isOpenDrawer,
        showConfirm: false,
        confirmTitle: "",
        confirmBody: "",
        confirmPosText: "",
        confirmNegText: "",
        confirmDontShowCheck: false,
        confirmDontShowCallback: null,
        showMapOptions: false,
        isStopFiltered: RwPrefUtils.isStopFiltered,
        isSiteFiltered: RwPrefUtils.isSiteFiltered,
        isTaskFiltered: RwPrefUtils.isTaskFiltered,
        isDriverFiltered: RwPrefUtils.isDriverFiltered,
        refreshRouteTrigger: 0,
        showColorPicker: false,
        showAddRoutePicker: false,
        colorPickerInitColor: "",
        showVisitPicker: false,
        visitPickerInit: 1,
        showSkedPicker: false,
        skedPickerPriority: RwPrefUtils.skedPriority,
        skedPickerStart: RwPrefUtils.skedStartMins,
        skedPickerFinish: RwPrefUtils.skedFinishMins,
        showRoutePicker: false,
        routePickerSrcId: "",

        showReloPicker: false,
        reloPickerInitTitle: "",
        reloPickerInitLat: 0,
        reloPickerInitLng: 0,
        enableReloPickerSearch: false,
        reloPickerAddReport: false,

        showStatusPicker: false,
        statusPickerInitName: "",
        statusPickerInitStatus: 0,

        showAssignPicker: false,
        assignPickerInitRoute: null,
        stopEditInitStop: null,
        histEditInitHistory: null,
        showSearchOptions: false,

        stopColor: RwPrefUtils.stopColor,
        taskColor: RwPrefUtils.taskColor,
        siteColor: RwPrefUtils.siteColor,
        optMode: RwPrefUtils.optimizeType,
        travelMode: RwPrefUtils.travelMode,
        isRoundTrip: RwPrefUtils.isRoundTrip,
        avoids: RwPrefUtils.avoids,
        distUnits: RwPrefUtils.distUnits,
        visitTime: RwPrefUtils.visitTime,
        routeTime: RwPrefUtils.routeSpan,
        startLat: RwPrefUtils.startLat,
        startLng: RwPrefUtils.startLng,
        isDynPoint: RwPrefUtils.isDynPoint,
        startFriendlyName: RwPrefUtils.startFriendlyName,
        registerEmail: RwPrefUtils.registerEmail,
        skuInfosJson: RwPrefUtils.skuInfosJson,
        
        showRouteNew: false,
        routeNewInitEmpty: false,
        routeNewInitRoute: null,

        genericDialog: new RwGenericDialog(),
        centerMapData: {lat: 0, lng: 0},
        searchPinData: {pinType: RwPinTypes.Stop, lat: 0, lng: 0},
        showPlannerToolbar: true,
        mapCentroid: {lat: 0, lng: 0, zoom: 0},

        showInvalidPinBanner: false,
        invalidPinError: "",
        invalidPinIcon: "",

        previousPin: null,
        showDropSelect: false,
        stopDropOrigin: null,
        showDropRemove: false,
        showFlexOptIn: false,
        showOptInPostMessage: false,
        showStopsViolations: RwPrefUtils.showStopViolations || [],
        outdatedVersion: VersionOutdatedEnum.Current,
        showSiteRoutePicker: false,
        showTaskRoutePicker: false,
        showGenericRoutePicker: false,
        genericRoutePickerFilter: () => true,
        changePin: null,
        zoomLevel: 0,
        isMobileView: false,
        isTabletView: false,
        recentStopAdd: false,
        greedyTime: 0,
        greedyDist: 0
    },

    getters: {

        mapCentroid(state): { lat: number, lng: number, zoom: number } {
            return state.mapCentroid;
        },

        showRouteNew(state): boolean {
            return state.showRouteNew;
        },

        routeNewInitEmpty(state): boolean {
            return state.routeNewInitEmpty;
        },

        routeNewInitRoute(state): RwRoute {
            return state.routeNewInitRoute;
        },

        showSearchOptions(state): boolean {
            return state.showSearchOptions;
        },

        stopEditInitStop(state): RwStop {
            return state.stopEditInitStop;
        },

        histEditInitHistory(state): RwHistory {
            return state.histEditInitHistory;
        },

        showStatusPicker(state): boolean {
            return state.showStatusPicker;
        },

        statusPickerInitName(state): string {
            return state.statusPickerInitName;
        },

        statusPickerInitStatus(state): number {
            return state.statusPickerInitStatus;
        },

        showReloPicker(state): boolean {
            return state.showReloPicker;
        },

        reloPickerInitTitle(state): string {
            return state.reloPickerInitTitle;
        },

        reloPickerInitLat(state): number {
            return state.reloPickerInitLat;
        },

        reloPickerInitLng(state): number {
            return state.reloPickerInitLng;
        },

        enableReloPickerSearch(state): boolean {
            return state.enableReloPickerSearch;
        },

        reloPickerAddReport(state): boolean {
            return state.reloPickerAddReport;
        },

        selectedSearchResult(state): RwSearchResult {
            return state.selectedSearchResult
        },

        searchReportInfo(state): Array<RwAddReport> {
            return state.searchReportInfo
        },

        showRoutePicker(state): boolean {
            return state.showRoutePicker;
        },

        routePickerSrcId(state): string {
            return state.routePickerSrcId;
        },

        showSkedPicker(state): boolean {
            return state.showSkedPicker;
        },

        skedPickerPriority(state): number {
            return state.skedPickerPriority;
        },

        skedPickerStart(state): number {
            return state.skedPickerStart;
        },

        skedPickerFinish(state): number {
            return state.skedPickerFinish;
        },

        visitPickerInit(state): number {
            return state.visitPickerInit;
        },

        showVisitPicker(state): boolean {
            return state.showVisitPicker;
        },


        colorPickerInitColor(state): string {
            return state.colorPickerInitColor;
        },

        showColorPicker(state): boolean {
            return state.showColorPicker;
        },

        showAddRoutePicker(state): boolean {
            return state.showAddRoutePicker;
        },

        refreshRouteTrigger(state): number {
            return state.refreshRouteTrigger;
        },

        isStopFiltered(state): boolean {
            return state.isStopFiltered;
        },

        isSiteFiltered(state): boolean {
            return state.isSiteFiltered;
        },

        isTaskFiltered(state): boolean {
            return state.isTaskFiltered;
        },

        isDriverFiltered(state): boolean {
            return state.isDriverFiltered;
        },

        showMapOptions(state): boolean {
            return state.showMapOptions;
        },

        confirmTitle(state): string {
            return state.confirmTitle;
        },

        confirmBody(state): string {
            return state.confirmBody;
        },

        confirmPosText(state): string {
            return state.confirmPosText;
        },

        confirmNegText(state): string {
            return state.confirmNegText;
        },

        showConfirm(state): boolean {
            return state.showConfirm;
        },

        confirmDontShowCheck(state): boolean {
            return state.confirmDontShowCheck;
        },

        confirmDontShowCallback(state): Function | null {
            return state.confirmDontShowCallback;
        },

        isOpenDrawer(state): boolean {
            return state.isOpenDrawer;
        },

        selectedTask(state): RwStop {
            return state.selectedTask;
        },

        showStopDock(state): boolean {
            return state.showStopDock;
        },

        showStopPop(state): boolean {
            return state.showStopPop;
        },

        showDriverDock(state): boolean {
            return state.showDriverDock;
        },

        showDriverPop(state): boolean {
            return state.showDriverPop;
        },

        showRouteDock(state): boolean {
            return state.showRouteDock;
        },

        showRoutePop(state): boolean {
            return state.showRoutePop;
        },

        showSitePop(state): boolean {
            return state.showSitePop;
        },

        showSiteDock(state): boolean {
            return state.showSiteDock
        },

        showSkedPop(state): boolean {
            return state.showSkedPop
        },

        showSkedDock(state): boolean {
            return state.showSkedDock
        },

        selectedClusterPin(state): RwPin {
            return state.selectedClusterPin;
        },

        showClusterPicker(state): boolean {
            return state.showClusterPicker;
        },

        showAssignPicker(state): boolean {
            return state.showAssignPicker;
        },

        assignPickerInitRoute(state): RwRoute {
            return state.assignPickerInitRoute;
        },

        snackBarText(state): string {
            return state.snackBarText;
        },

        snackBarColor(state): string {
            return state.snackBarColor;
        },

        showSnackbar(state): boolean {
            return state.showSnackbar;
        },

        showRouteOrder(state): boolean {
            return state.showRouteOrder;
        },

        showRouteSummary(state): boolean {
            return state.showRouteSummary;
        },

        searchResultsWeb(state): RwSearchResult[] {
            return state.searchResultsWeb;
        },

        searchResultsSites(state): RwSearchResult[] {
            return state.searchResultsSites;
        },

        searchResultsTasks(state): RwSearchResult[] {
            return state.searchResultsTasks;
        },

        searchResultsCoords(state): RwSearchResult[] {
            return state.searchResultsCoords;
        },

        showDataLayerRoute(state): boolean {
            return state.showDataLayerRoute;
        },

        showDataLayerSites(state): boolean {
            return state.showDataLayerSites;
        },

        showDataLayerTasks(state): boolean {
            return state.showDataLayerTasks;
        },

        // showDataLayerDrivers(state): boolean{
        //   return state.showDataLayerDrivers;
        // },

        selectedStop(state): RwStop {
            return state.selectedStop;
        },

        showTaskEdit(state): boolean {
            return state.showTaskEdit;
        },

        isDataLoaded(state): boolean {
            //console.warn("store get isDataLoaded", state.isDataLoaded);
            return state.isDataLoaded;
        },

        tasks(state): RwStop[] {
            return state.tasks;
        },

        taskPins(state): RwPin[] {
            return state.taskPins;
        },

        showRouteEdit(state): boolean {
            return state.showRouteEdit;
        },

        routeEditInitRoute(state): RwSked {
            return state.routeEditInitRoute;
        },

        showRouteAssign(state): boolean {
            return state.showRouteAssign;
        },

        showRouteCopy(state): boolean {
            return state.showRouteCopy;
        },

        // showRouteArchive(state): boolean{
        //   return state.showRouteArchive;
        // },

        showRouteComplete(state): boolean {
            return state.showRouteComplete;
        },

        // showRouteDelete(state): boolean{
        //   return state.showRouteDelete;
        // },

        // showRouteComplete(state): boolean{
        //   return state.showRouteComplete;
        // },


        steps(state): RwStep[] {
            return state.steps;
        },

        showPinPanel(state): boolean {
            return state.showPinPanel;
        },

        showPinPopup(state): boolean {
            return state.showPinPopup;
        },

        // showDriverPanel(state): boolean{
        //   return state.showDriverPanel;
        // },
        //
        // showDriverPopup(state): boolean{
        //   return state.showDriverPopup;
        // },

        showStopEdit(state): boolean {
            return state.showStopEdit;
        },

        showHistoryEdit(state): boolean {
            return state.showHistoryEdit;
        },

        showSiteEdit(state): boolean {
            return state.showSiteEdit;
        },
        showAddReport(state): boolean {
            return state.showAddReport;
        },
        showAddMissingReport(state): boolean {
            return state.showAddMissingReport;
        },
        addReport(state): RwAddReport {
            return state.addReport;
        },

        siteEditInitSite(state): RwSite {
            return state.siteEditInitSite;
        },

        // showSiteRelocate(state):boolean{
        //   return state.showSiteRelocate;
        // },

        // showSiteDelete(state): boolean{
        //   return state.showSiteDelete;
        // },

        showSiteCreate(state): boolean {
            return state.showSiteCreate;
        },

        showSiteMultiColor(state): boolean {
            return state.showSiteMultiColor;
        },

        showEditDriverPass(state): boolean {
            return state.showEditDriverPass;
        },

        showEditDriverUname(state): boolean {
            return state.showEditDriverUname;
        },

        showReplaceDriver(state): boolean {
            return state.showReplaceDriver;
        },

        showDeleteDriver(state): boolean {
            return state.showDeleteDriver;
        },

        showCreateDriver(state): boolean {
            return state.showCreateDriver;
        },

        showCreateBulkUser(state): boolean {
            return state.showCreateBulkUser;
        },

        showDomainPopup(state): boolean {
            return state.showDomainPopup;
        },

        showPingTest(state): boolean {
            return state.showPingTest;
        },

        showDeletePrompt(state): boolean {
            return state.showDeletePrompt;
        },

        showCreatePrompt(state): boolean {
            return state.showCreatePrompt;
        },

        avInfo(state): RwAvatarInfo {
            return state.avInfo;
        },
        //
        // avUrl(state): string{
        //   return state.avUrl;
        // },
        //
        // avType(state): number{
        //   return state.avType;
        // },
        //
        // avColor(state): string{
        //   return state.avColor;
        // },
        //
        // avInits(state): string{
        //   return state.avInits;
        // },

        editAfterAdd(state): boolean {
            return state.editAfterAdd;
        },

        editFromCopy(state): boolean {
            return state.editFromCopy;
        },

        hidePingPopup(state): boolean {
            return state.hidePingPopup;
        },

        hideDeletePopup(state): boolean {
            return state.hideDeletePopup;
        },

        hideCreatePopup(state): boolean {
            return state.hideCreatePopup;
        },

        hideCCPopup(state): boolean {
            return state.hideCCPopup;
        },

        valCCPopup(state): number {
            return state.valCCPopup;
        },

        warnedCCPopup(state): string[] {
            return state.warnedCCPopup;
        },

        warnedCCPopupOverdue(state): string[] {
            return state.warnedCCPopupOverdue;
        },

        hide450Popup(state): boolean {
            return state.hide450Popup;
        },

        prompt450Popup(state): string[] {
            return state.prompt450Popup;
        },

        warned450Popup(state): string[] {
            return state.warned450Popup;
        },

        prompt500Popup(state): string[] {
            return state.prompt500Popup;
        },

        hideTasksWarn(state): boolean {
            return state.hideTasksWarn;
        },

        hideStopStatus(state): boolean {
            return state.hideStopStatus;
        },

        showDriverEdit(state): boolean {
            return state.showDriverEdit;
        },

        driverEditInitDriver(state): RwUser {
            return state.driverEditInitDriver;
        },

        showSkedEdit(state): boolean {
            return state.showSkedEdit;
        },

        skedEditInitSked(state): RwSked {
            return state.skedEditInitSked;
        },

        showSkedCreate(state): boolean {
            return state.showSkedCreate;
        },

        // showSkedDelete(state):boolean{
        //   return state.showSkedDelete;
        // },

        showProBetaPrimer(state): boolean {
            return state.showProBetaPrimer;
        },

        showProfileEdit(state): boolean {
            return state.showProfileEdit;
        },

        showLimitPopup(state): boolean {
            return state.showLimitPopup;
        },

        showNewPass(state): boolean {
            return state.showNewPass;
        },

        // showUserSiteBanner(state):boolean{
        //   return state.showUserSiteBanner;
        // },

        hasClosedUserSiteBanner(state): boolean {
            return state.hasClosedUserSiteBanner;
        },
        lastClosedPaymentBanner(state): Date {
            return state.lastClosedPaymentBanner;
        },

        showRegistrationType(state): boolean {
            return state.showRegistrationType;
        },

        showProToTeam(state): boolean {
            return state.showProToTeam;
        },

        showCCExpire(state): boolean {
            return state.showCCExpire;
        },

        showEditAvatar(state): boolean {
            return state.showEditAvatar;
        },

        selectedRoute(state): RwRoute {
            return state.selectedRoute;
        },

        routeToEdit(state): RwRoute {
            return state.routeToEdit;
        },

        selectedRouteSpace(state): RwRoute {
            return state.selectedRouteSpace;
        },

        selectedRoutes(state): RwRoute[] {
            return state.selectedRoutes;
        },

        selectedSite(state): RwSite {
            return state.selectedSite;
        },

        selectedSked(state): RwSked {
            return state.selectedSked;
        },

        hist(state): RwHistory[] {
            return state.hist;
        },

        selectedDriver(state): RwUser {
            return state.selectedDriver;
        },

        highLightManual(state): number {
            return state.highLightManual;
        },

        showPanelStatus(state): boolean {
            return state.showPanelStatus;
        },

        showPanelMap(state): boolean {
            return state.showPanelMap;
        },

        showPanelList(state): boolean {
            return state.showPanelList;
        },

        showSearchPanel(state): boolean {
            return state.showSearchPanel;
        },

        showDataLayers(state): boolean {
            return state.showDataLayers;
        },

        mapShowSatellite(state): boolean {
            return state.mapShowSatellite;
        },

        mapShowTraffic(state): boolean {
            return state.mapShowTraffic;
        },

        mapShowIncidents(state): boolean {
            return state.mapShowIncidents;
        },

        multiMode(state): number {
            return state.multiMode;
        },

        multiModeTool(state): number {
            return state.multiModeTool;
        },

        isMultiMode(state): boolean {
            return state.isMultiMode;
        },

        sizeDelta(state): number {
            return state.sizeDelta;
        },

        selectedPins(state): RwPin[] {
            return state.selectedPins;
        },

        stopPins(state): RwPin[] {
            return state.stopPins;
        },

        sitePins(state): RwPin[] {
            return state.sitePins;
        },

        driverPins(state): RwPin[] {
            return state.driverPins;
        },

        startPin(state): RwPin {
            return state.startPin;
        },

        // pinList(state): RwPin[] {
        //   return state.pinList;
        // },

        activeRoute(state): RwRoute {
            return state.activeRoute;
        },

        openRoutes(state): RwRoute[] {
            return state.openRoutes;
        },

        routes(state): RwRoute[] {
            let routes: RwRoute[] = [];
            if (state.routes) {
                routes = state.routes;
            }
            return routes;
        },

        sites(state): RwSite[] {
            return state.sites;
        },

        skeds(state): RwSked[] {
            return state.skeds;
        },

        drivers(state): RwUser[] {
            return state.drivers;
        },

        isDarkMode(state): boolean {
            return state.isDarkMode;
        },

        isLoading(state): boolean {
            return state.loading;
        },

        hasCurrPurch(state): boolean {
            return state.hasCurrPurch;
        },

        hasProBetaPrimed(state): boolean {
            return state.hasProBetaPrimed;
        },

        updateStartPref(state): number {
            return state.updateStartPref;
        },

        pinnedStatus(state): string[] {
            return state.pinnedStatus;
        },


        token(state): string {
            return state.token;
        },

        isLoggedIn(state): boolean {
            return state.isLoggedIn;
        },

        lastSyncMS(state): number {
            return state.lastSyncMS;
        },

        lastDeltaMS(state): number {
            return state.lastDeltaMS;
        },

        driverLastSyncMS(state): number {
            return state.driverLastSyncMS;
        },

        lastStatusMS(state): number {
            return state.lastStatusMS;
        },

        lastTokenMS(state): number {
            return state.lastTokenMS;
        },

        isTeam(state): boolean {
            return state.isTeam;
        },

        isAutoRenewing(state): boolean {
            return state.isAutoRenewing;
        },

        isProMonthly(state): boolean {
            return state.isProMonthly;
        },

        isFlexSolo(state): boolean {
            return state.isFlexSolo;
        },

        flexDisplay(state): number {
            return state.flexDisplay;
        },

        isDisp(state): boolean {
            return state.isDisp;
        },

        getRole(state): number {
            return state.role;
        },

        isSimpleMap(state): boolean {
            return state.isSimpleMap;
        },

        stopColor(state): string {
            return state.stopColor;
        },

        taskColor(state): string {
            return state.taskColor;
        },

        siteColor(state): string {
            return state.siteColor;
        },

        optMode(state): number {
            return state.optMode;
        },

        travelMode(state): string {
            return state.travelMode;
        },

        isRoundTrip(state): boolean {
            return state.isRoundTrip;
        },

        avoids(state): string[] {
            return state.avoids;
        },

        distUnits(state): number {
            return state.distUnits;
        },

        visitTime(state): number {
            return state.visitTime;
        },

        routeTime(state): number {
            return state.routeTime;
        },

        startLat(state): number {
            return state.startLat;
        },

        startLng(state): number {
            return state.startLng;
        },

        isDynPoint(state): boolean {
            return state.isDynPoint;
        },

        startFriendlyName(state): string {
            return state.startFriendlyName;
        },

        registerEmail(state): string {
            return state.registerEmail;
        },

        skuInfosJson(state): string {
            return state.skuInfosJson;
        },

        genericDialog(state): RwGenericDialog {
            return state.genericDialog;
        },

        centerMapData(state): { lat: number, lng: number } {
            return state.centerMapData;
        },

        searchPinData(state): { pinType: RwPinTypes, lat: number, lng: number } {
            return state.searchPinData;
        },

        showPlannerToolbar(state): boolean {
            return state.showPlannerToolbar;
        },

        showInvalidPinBanner(state) {
            return state.showInvalidPinBanner;
        },

        invalidPinError(state) {
            return state.invalidPinError;
        },

        invalidPinIcon(state) {
            return state.invalidPinIcon;
        },

        previousPin(state) {
            return state.previousPin;
        },

        showDropSelect(state) {
            return state.showDropSelect;
        },

        stopDropOrigin(state) {
            return state.stopDropOrigin;
        },

        showDropRemove(state) {
            return state.showDropRemove;
        },

        showFlexOptIn(state) {
            return state.showFlexOptIn;
        },
        showOptInPostMessage(state) {
            return state.showOptInPostMessage;
        },

        showStopsViolations(state) {
            return state.showStopsViolations;
        },

        outdatedVersion(state) {
            return state.outdatedVersion;
        },

        showSiteRoutePicker(state) {
            return state.showSiteRoutePicker;
        },

        showTaskRoutePicker(state) {
            return state.showTaskRoutePicker;
        },

        showGenericRoutePicker(state) {
            return state.showGenericRoutePicker;
        },

        genericRoutePickerFilter(state) {
            return state.genericRoutePickerFilter;
        },

        changePin(state): RwPin {
            return state.changePin
        },

        zoomLevel(state): number {
            return state.zoomLevel;
        },

        isMobileView(state) {
            return state.isMobileView;
        },

        isTabletView(state) {
            return state.isTabletView;
        },

        recentStopAdd(state): boolean {
            return state.recentStopAdd;
        },

        greedyTime(state): number {
            return state.greedyTime;
        },

        greedyDist(state): number {
            return state.greedyDist;
        }

    },

    actions: {

        mapCentroid({state, commit}, val: { lat: number, lng: number, zoom: number }) {
            commit("MAP_CENTROID", val);
        },

        showRouteNew({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_NEW", val);
        },

        routeNewInitEmpty({state, commit}, val: boolean) {
            commit("ROUTE_NEW_INIT_EMPTY", val);
        },

        routeNewInitRoute({state, commit}, val: RwRoute) {
            commit("ROUTE_NEW_INIT_ROUTE", val);
        },

        showSearchOptions({state, commit}, val: boolean) {
            commit("SHOW_SEARCH_OPTIONS", val);
        },

        stopEditInitStop({state, commit}, val: RwStop) {
            commit("STOP_EDIT_INIT_STOP", val);
        },

        histEditInitHistory({state, commit}, val: RwHistory) {
            commit("HIST_EDIT_INIT_HISTORY", val);
        },

        showStatusPicker({state, commit}, val: boolean) {
            commit("SHOW_STATUS_PICKER", val);
        },

        statusPickerInitName({state, commit}, val: string) {
            commit("STATUS_PICKER_INIT_NAME", val);
        },

        statusPickerInitStatus({state, commit}, val: number) {
            commit("STATUS_PICKER_INIT_STATUS", val);
        },

        showReloPicker({state, commit}, val: boolean) {
            commit("SHOW_RELO_PICKER", val);
        },

        reloPickerInitTitle({state, commit}, val: string) {
            commit("RELO_PICKER_INIT_TITLE", val);
        },

        reloPickerInitLat({state, commit}, val: number) {
            commit("RELO_PICKER_INIT_LAT", val);
        },

        reloPickerInitLng({state, commit}, val: number) {
            commit("RELO_PICKER_INIT_LNG", val);
        },

        enableReloPickerSearch({state, commit}, val: boolean) {
            commit("ENABLE_RELO_PICKER_SEARCH", val);
        },

        reloPickerAddReport({state, commit}, val: boolean) {
            commit("RELO_PICKER_ADD_REPORT", val);
        },

        selectedSearchResult({state, commit}, val: RwSearchResult) {
            commit("SELECTED_SEARCH_RESULT", val);
        },

        searchReportInfo({state, commit}, val: Array<RwAddReport>) {
            commit("SEARCH_REPORT_INFO", val);
        },

        showRoutePicker({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_PICKER", val);
        },

        routePickerSrcId({state, commit}, val: string) {
            commit("ROUTE_PICKER_ROUTE_ID", val);
        },

        showSkedPicker({state, commit}, val: boolean) {
            commit("SHOW_SKED_PICKER", val);
        },

        skedPickerPriority({state, commit}, val: number) {
            commit("SKED_PICKER_PRIORITY", val);
        },

        skedPickerStart({state, commit}, val: number) {
            commit("SKED_PICKER_START", val);
        },

        skedPickerFinish({state, commit}, val: number) {
            commit("SKED_PICKER_FINISH", val);
        },

        showVisitPicker({state, commit}, val: boolean) {
            commit("SHOW_VISIT_PICKER", val);
        },

        visitPickerInit({state, commit}, val: number) {
            commit("VISIT_PICKER_INIT", val);
        },

        colorPickerInitColor({state, commit}, val: string) {
            commit("COLOR_PICKER_INIT_COLOR", val);
        },

        showColorPicker({state, commit}, val: boolean) {
            commit("SHOW_COLOR_PICKER", val);
        },

        showAddRoutePicker({state, commit}, val: boolean) {
            commit("SHOW_ADD_ROUTE_PICKER", val);
        },

        refreshRouteTrigger({state, commit}, val: number) {
            commit("REFRESH_ROUTE_TRIGGER", val);
        },

        isStopFiltered({state, commit}, val: boolean) {
            commit("IS_STOP_FILTERED", val);
        },

        isSiteFiltered({state, commit}, val: boolean) {
            commit("IS_SITE_FILTERED", val);
        },

        isTaskFiltered({state, commit}, val: boolean) {
            commit("IS_TASK_FILTERED", val);
        },

        isDriverFiltered({state, commit}, val: boolean) {
            commit("IS_DRIVER_FILTERED", val);
        },

        showMapOptions({state, commit}, val: boolean) {
            commit("SHOW_MAP_OPTIONS", val);
        },

        confirmTitle({state, commit}, val: string) {
            commit("CONFIRM_TITLE", val);
        },

        confirmBody({state, commit}, val: string) {
            commit("CONFIRM_BODY", val);
        },

        confirmPosText({state, commit}, val: string) {
            commit("CONFIRM_POS_TEXT", val);
        },

        confirmNegText({state, commit}, val: string) {
            commit("CONFIRM_NEG_TEXT", val);
        },


        showConfirm({state, commit}, val: boolean) {
            commit("SHOW_CONFIRM", val);
        },

        confirmDontShowCheck({state, commit}, val: boolean) {
            commit("CONFIRM_DONT_SHOW_CHECK", val);
        },

        confirmDontShowCallback({state, commit}, val: Function | null) {
            commit("CONFIRM_DONT_SHOW_CALLBACK", val);
        },

        isOpenDrawer({state, commit}, val: boolean) {
            commit("IS_OPEN_DRAWER", val);
        },

        selectedTask({state, commit}, val: RwStop) {
            commit("SELECTED_TASK", val);
        },

        showStopDock({state, commit}, val: boolean) {
            commit("SHOW_STOP_DOCK", val);
        },

        showStopPop({state, commit}, val: boolean) {
            commit("SHOW_STOP_POP", val);
        },

        showDriverDock({state, commit}, val: boolean) {
            commit("SHOW_DRIVER_DOCK", val);
        },

        showDriverPop({state, commit}, val: boolean) {
            commit("SHOW_DRIVER_POP", val);
        },

        showRouteDock({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_DOCK", val);
        },

        showRoutePop({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_POP", val);
        },

        showAddReport({state, commit}, val: boolean) {
            commit("SHOW_ADD_REPORT", val);
        },
        showAddMissingReport({state, commit}, val: boolean) {
            commit("SHOW_ADD_MISSING_REPORT", val);
        },

        addReport({state, commit}, val: RwAddReport) {
            commit("ADD_REPORT", val);
        },

        showSiteDock({state, commit}, val: boolean) {
            commit("SHOW_SITE_DOCK", val);
        },

        showSitePop({state, commit}, val: boolean) {
            commit("SHOW_SITE_POP", val);
        },


        showSkedDock({state, commit}, val: boolean) {
            commit("SHOW_SKED_DOCK", val);
        },

        showSkedPop({state, commit}, val: boolean) {
            commit("SHOW_SKED_POP", val);
        },

        selectedClusterPin({state, commit}, val: RwPin) {
            commit("SELECTED_CLUSTER_PIN", val);
        },

        showClusterPicker({state, commit}, val: boolean) {
            commit("SHOW_CLUSTER_PICKER", val);
        },

        showAssignPicker({state, commit}, val: boolean) {
            commit("SHOW_ASSIGN_PICKER", val);
        },

        assignPickerInitRoute({state, commit}, val: RwRoute) {
            commit("ASSIGN_PICKER_INIT_ROUTE", val);
        },

        snackBarText({state, commit}, val: string) {
            commit("SNACK_BAR_TEXT", val);
        },

        snackBarColor({state, commit}, val: string) {
            commit("SNACK_BAR_COLOR", val);
        },

        showSnackbar({state, commit}, val: boolean) {
            commit("SHOW_SNACK_BAR", val);
        },

        showRouteOrder({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_ORDER", val);
        },

        showRouteSummary({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_SUMMARY", val);
        },

        searchResultsWeb({state, commit}, val: RwSearchResult[]) {
            commit("SEARCH_RESULTS_WEB", val);
        },

        searchResultsSites({state, commit}, val: RwSearchResult[]) {
            commit("SEARCH_RESULTS_SITES", val);
        },

        searchResultsTasks({state, commit}, val: RwSearchResult[]) {
            commit("SEARCH_RESULTS_TASKS", val);
        },

        searchResultsCoords({state, commit}, val: RwSearchResult[]) {
            commit("SEARCH_RESULTS_COORDS", val);
        },

        showDataLayerRoute({state, commit}, val: boolean) {
            commit("SHOW_DATA_LAYER_ROUTE", val);
        },

        showDataLayerSites({state, commit}, val: boolean) {
            commit("SHOW_DATA_LAYER_SITES", val);
        },

        showDataLayerTasks({state, commit}, val: boolean) {
            commit("SHOW_DATA_LAYER_TASKS", val);
        },

        // showDataLayerDrivers({state, commit}, val: boolean) {
        //   commit("SHOW_DATA_LAYER_DRIVERS", val);
        // },


        selectedStop({state, commit}, val: RwStop) {
            commit("SELECTED_STOP", val);
        },

        showTaskEdit({state, commit}, val: boolean) {
            commit("SHOW_TASK_EDIT", val);
        },

        isDataLoaded({state, commit}, val: boolean) {
            //console.warn("store set isDataLoaded", val);
            commit("IS_DATA_LOADED", val);
        },

        tasks({state, commit}, val: RwStop[]) {
            commit("TASKS", val);
        },

        taskPins({state, commit}, val: RwPin[]) {
            commit("TASK_PINS", val);
        },


        showRouteAssign({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_ASSIGN", val);
        },

        showRouteCopy({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_COPY", val);
        },


        showRouteComplete({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_COMPLETE", val);
        },

        showRouteDelete({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_DELETE", val);
        },

        showRouteEdit({state, commit}, val: boolean) {
            commit("SHOW_ROUTE_EDIT", val);
        },

        routeEditInitRoute({state, commit}, val: RwSked) {
            commit("ROUTE_EDIT_INIT_ROUTE", val);
        },


        steps({state, commit}, val: RwStep[]) {
            commit("STEPS", val);
        },

        showPinPanel({state, commit}, val: boolean) {
            commit("SHOW_PREVIEW_PANEL", val);
        },

        showPinPopup({state, commit}, val: boolean) {
            commit("SHOW_PREVIEW_POPUP", val);
        },
        //
        // showDriverPanel({state, commit}, val: boolean) {
        //   commit("SHOW_DRIVER_PANEL", val);
        // },
        //
        // showDriverPopup({state, commit}, val: boolean) {
        //   commit("SHOW_DRIVER_POPUP", val);
        // },

        showStopEdit({state, commit}, val: boolean) {
            commit("SHOW_STOP_EDIT", val);
        },

        showHistoryEdit({state, commit}, val: boolean) {
            commit("SHOW_HISTORY_EDIT", val);
        },

        showSiteEdit({state, commit}, val: boolean) {
            commit("SHOW_SITE_EDIT", val);
        },

        siteEditInitSite({state, commit}, val: RwSite) {
            commit("SITE_EDIT_INIT_SITE", val);
        },

        // showSiteRelocate({state,commit}, val:boolean) {
        //   commit("SHOW_SITE_RELOCATE", val);
        // },

        // showSiteDelete({state, commit}, val: boolean) {
        //   commit("SHOW_SITE_DELETE", val);
        // },

        showSiteCreate({state, commit}, val: boolean) {
            commit("SHOW_SITE_CREATE", val);
        },

        showSiteMultiColor({state, commit}, val: boolean) {
            commit("SHOW_SITE_MULTI_COLOR", val);
        },

        showEditDriverPass({state, commit}, val: boolean) {
            commit("SHOW_EDIT_DRIVER_PASS", val);
        },

        showEditDriverUname({state, commit}, val: boolean) {
            commit("SHOW_EDIT_DRIVER_UNAME", val);
        },

        showReplaceDriver({state, commit}, val: boolean) {
            commit("SHOW_REPLACE_DRIVER", val);
        },

        showDeleteDriver({state, commit}, val: boolean) {
            commit("SHOW_DELETE_DRIVER", val);
        },

        showCreateDriver({state, commit}, val: boolean) {
            commit("SHOW_CREATE_DRIVER", val);
        },

        showCreateBulkUser({state, commit}, val: boolean) {
            commit("SHOW_CREATE_BULK_USER", val);
        },

        showDomainPopup({state, commit}, val: boolean) {
            commit("SHOW_DOMAIN_POPUP", val);
        },

        showPingTest({state, commit}, val: boolean) {
            commit("SHOW_PING_TEST", val);
        },

        showDeletePrompt({state, commit}, val: boolean) {
            commit("SHOW_DELETE_PROMPT", val);
        },

        showCreatePrompt({state, commit}, val: boolean) {
            commit("SHOW_CREATE_PROMPT", val);
        },

        avInfo({state, commit}, val: RwAvatarInfo) {
            commit("AV_INFO", val);
        },
        //
        // avUrl({state, commit}, val: string) {
        //   commit("AV_URL", val);
        // },
        //
        // avType({state, commit}, val: number) {
        //   commit("AV_TYPE", val);
        // },
        //
        // avColor({state, commit}, val: string) {
        //   commit("AV_COLOR", val);
        // },
        //
        // avInits({state, commit}, val: string) {
        //   commit("AV_INITS", val);
        // },

        editAfterAdd({state, commit}, val: boolean) {
            commit("EDIT_AFTER_ADD", val);
        },

        editFromCopy({state, commit}, val: boolean) {
            commit("EDIT_FROM_COPY", val);
        },

        hidePingPopup({state, commit}, val: boolean) {
            commit("HIDE_PING_POPUP", val);
        },

        hideDeletePopup({state, commit}, val: boolean) {
            commit("HIDE_DELETE_POPUP", val);
        },

        hideCreatePopup({state, commit}, val: boolean) {
            commit("HIDE_CREATE_POPUP", val);
        },

        hideCCPopup({state, commit}, val: boolean) {
            commit("HIDE_CC_POPUP", val);
        },

        valCCPopup({state, commit}, val: number) {
            commit("VAL_CC_POPUP", val);
        },

        warnedCCPopup({state, commit}, val: string[]) {
            commit("WARNED_CC_POPUP", val);
        },

        warnedCCPopupOverdue({state, commit}, val: string[]) {
            commit("WARNED_CC_POPUP_OVERDUE", val);
        },

        hide450Popup({state, commit}, val: boolean) {
            commit("HIDE_450_POPUP", val);
        },

        prompt450Popup({state, commit}, val: string[]) {
            commit("PROMPT_450_POPUP", val);
        },

        warned450Popup({state, commit}, val: string[]) {
            commit("WARNED_450_POPUP", val);
        },

        prompt500Popup({state, commit}, val: string[]) {
            commit("PROMPT_500_POPUP", val);
        },

        hideTasksWarn({state, commit}, val: boolean) {
            commit("HIDE_TASKS_WARN", val);
        },

        hideStopStatus({state, commit}, val: boolean) {
            commit("HIDE_STOP_STATUS", val);
        },

        showDriverEdit({state, commit}, val: boolean) {
            commit("SHOW_DRIVER_EDIT", val);
        },

        driverEditInitDriver({state, commit}, val: RwUser) {
            commit("DRIVER_EDIT_INIT_DRIVER", val);
        },

        showSkedEdit({state, commit}, val: boolean) {
            commit("SHOW_SKED_EDIT", val);
        },

        skedEditInitSked({state, commit}, val: RwSked) {
            commit("SKED_EDIT_INIT_SKED", val);
        },

        showSkedCreate({state, commit}, val: boolean) {
            commit("SHOW_SKED_CREATE", val);
        },

        // showSkedDelete({state, commit}, val:boolean){
        //   commit("SHOW_SKED_DELETE", val);
        // },

        showProBetaPrimer({state, commit}, val: boolean) {
            commit("SHOW_PRO_BETA_PRIMER", val);
        },

        showProfileEdit({state, commit}, val: boolean) {
            commit("SHOW_PROFILE_EDIT", val);
        },

        showLimitPopup({state, commit}, val: boolean) {
            commit("SHOW_LIMIT_POPUP", val);
        },

        showNewPass({state, commit}, val: boolean) {
            commit("SHOW_NEW_PASS", val);
        },
        //
        // showUserSiteBanner({state, commit}, val:boolean) {
        //   commit("SHOW_USER_SITE_BANNER", val);
        // },

        hasClosedUserSiteBanner({state, commit}, val: boolean) {
            commit("HAS_CLOSED_USER_SITE_BANNER", val);
        },

        lastClosedPaymentBanner({state, commit}, val: boolean) {
            commit("LAST_CLOSED_UPDATE_PAYMENT_BANNER", val);
        },

        showRegistrationType({state, commit}, val: boolean) {
            commit("SHOW_REGISTRATION_TYPE", val);
        },

        showProToTeam({state, commit}, val: boolean) {
            commit("SHOW_PRO_TO_TEAM", val);
        },

        showCCExpire({state, commit}, val: boolean) {
            commit("SHOW_CC_EXPIRE", val);
        },

        showEditAvatar({state, commit}, val: boolean) {
            commit("SHOW_EDIT_AVATAR", val);
        },

        selectedRoute({state, commit}, val: RwRoute) {
            commit("SELECTED_ROUTE", val);
        },

        routeToEdit({state, commit}, val: RwRoute) {
            commit("ROUTE_TO_EDIT", val);
        },

        selectedRouteSpace({state, commit}, val: RwRoute) {
            commit("SELECTED_ROUTE_SPACE", val);
        },

        selectedRoutes({state, commit}, val: RwRoute[]) {
            commit("SELECTED_ROUTES", val);
        },

        selectedSite({state, commit}, val: RwSite) {
            commit("SELECTED_SITE", val);
        },

        selectedSked({state, commit}, val: RwSked) {
            commit("SELECTED_SKED", val)
        },

        hist({state, commit}, val: RwHistory[]) {
            commit("HIST", val);
        },

        selectedDriver({state, commit}, val: RwUser) {
            commit("SELECTED_DRIVER", val);
        },

        highLightManual({state, commit}, val: number) {
            commit("HIGHLIGHT_MANUAL", val);
        },

        showPanelStatus({state, commit}, val: boolean) {
            commit("SHOW_PANEL_STATUS", val);
        },

        showPanelMap({state, commit}, val: boolean) {
            commit("SHOW_PANEL_MAP", val);
        },

        showPanelList({state, commit}, val: boolean) {
            commit("SHOW_PANEL_LIST", val);
        },

        showSearchPanel({state, commit}, val: boolean) {
            commit("SHOW_SEARCH_PANEL", val);
        },

        showDataLayers({state, commit}, val: boolean) {
            commit("SHOW_DATA_LAYERS", val);
        },

        mapShowSatellite({state, commit}, val: boolean) {
            commit("MAP_SHOW_SATELLITE", val);
        },


        mapShowTraffic({state, commit}, val: boolean) {
            commit("MAP_SHOW_TRAFFIC", val);
        },

        mapShowIncidents({state, commit}, val: boolean) {
            commit("MAP_SHOW_INCIDENTS", val);
        },

        routes({state, commit}, routes: RwRoute[]) {
            commit("ROUTES", routes);
        },

        sites({state, commit}, sites: RwSite[]) {
            commit("SITES", sites);
        },

        skeds({state, commit}, skeds: RwSked[]) {
            commit("SKEDS", skeds);
        },

        drivers({state, commit}, drivers: RwUser[]) {
            commit("DRIVERS", drivers);
        },

        setRoute({state, commit}, route: RwRoute) {
            route.invalidateCache();
            commit("ROUTE", route);
        },

        activeRoute({state, commit}, route: RwRoute) {
            commit("ACTIVE_ROUTE", route);
        },

        openRoutes({state, commit}, routes: RwRoute[]) {
            commit("OPEN_ROUTES", routes);
        },

        isLoading({state, commit}, isLoading: boolean) {
            commit("IS_LOADING", isLoading);
        },

        hasCurrPurch({state, commit}, hasCurrPurch: boolean) {
            commit("HAS_CURRENT_PURCHASE", hasCurrPurch);
        },
        
        hasProBetaPrimed({state, commit}, hasOnboarded: boolean) {
            commit("HAS_PRO_BETA_PRIMED", hasOnboarded);
        },

        updateStartPref({state, commit}, updateStartPref) {
            commit("UPDATE_START_PREF", updateStartPref);
        },

        pinnedStatus({state, commit}, pinnedStatus: string[]) {
            commit("PINNED_STATUS", pinnedStatus);
        },

        token({state, commit}, token: string) {
            commit("TOKEN", token);
        },

        isDarkMode({state, commit}, isDarkVal) {
            commit("IS_DARK_MODE", isDarkVal);
        },

        isLoggedIn({state, commit}, isLoggedIn) {
            commit("IS_LOGGED_IN", isLoggedIn);
        },

        lastSyncMS({state, commit}, lastSyncMS) {
            commit("LAST_SYNC_MS", lastSyncMS);
        },

        lastDeltaMS({state, commit}, lastDeltaMS) {
            commit("LAST_DELTA_MS", lastDeltaMS);
        },

        driverLastSyncMS({state, commit}, driverLastSyncMS) {
            commit("DRIVER_LAST_SYNC_MS", driverLastSyncMS);
        },

        lastStatusMS({state, commit}, lastStatusMS) {
            commit("LAST_STATUS_MS", lastStatusMS);
        },

        lastTokenMS({state, commit}, lastTokenMS) {
            commit("LAST_TOKEN_MS", lastTokenMS);
        },

        isTeam({state, commit}, isTeam: boolean) {
            commit("IS_TEAM", isTeam);
        },

        isAutoRenewing({state, commit}, val: boolean) {
            commit("IS_AUTO_RENEWING", val);
        },

        isProMonthly({state, commit}, val: boolean) {
            commit("IS_PRO_MONTHLY", val);
        },

        isFlexSolo({state, commit}, val: boolean) {
            commit("IS_FLEX_SOLO", val);
        },

        flexDisplay({state, commit}, val: number) {
            commit("FLEX_DISPLAY", val);
        },

        isDisp({state, commit}, isDisp: boolean) {
            commit("IS_DISP", isDisp);
        },

        isSimpleMap({state, commit}, val: boolean) {
            commit("IS_SIMPLE_MAP", val);
        },

        setRole({state, commit}, role: number) {
            commit("SET_ROLE", role);
        },

        stopPins({state, commit}, val: RwPin[]) {
            commit("STOP_PINS", val);
        },

        sitePins({state, commit}, val: RwPin[]) {
            commit("SITE_PINS", val);
        },

        startPin({state, commit}, val: RwPin) {
            commit("START_PIN", val);
        },

        driverPins({state, commit}, val: RwPin[]) {
            commit("DRIVER_PINS", val);
        },

        selectedPins({state, commit}, val: RwPin[]) {
            //console.log("selectedPins mutated");
            commit("SELECTED_PINS", val);
        },

        sizeDelta({state, commit}, val: number) {
            commit("SIZE_DELTA", val);
        },

        isMultiMode({state, commit}, val: boolean) {
            commit("IS_MULTI_MODE", val);
        },

        multiMode({state, commit}, val: number) {
            commit("MULTI_MODE", val);
        },

        multiModeTool({state, commit}, val: number) {
            commit("MAP_SELECTOR", val);
        },

        stopColor({state, commit}, val: string) {
            commit("STOP_COLOR", val);
        },

        taskColor({state, commit}, val: string) {
            commit("TASK_COLOR", val);
        },

        siteColor({state, commit}, val: string) {
            commit("SITE_COLOR", val);
        },

        optMode({state, commit}, val: number) {
            commit("OPT_MODE", val);
        },

        travelMode({state, commit}, val: string) {
            commit("TRAVEL_MODE", val);
        },

        isRoundTrip({state, commit}, val: boolean) {
            commit("IS_ROUND_TRIP", val);
        },

        avoids({state, commit}, val: string[]) {
            commit("AVOIDS", val);
        },

        distUnits({state, commit}, val: number) {
            commit("DIST_UNITS", val);
        },

        visitTime({state, commit}, val: number) {
            commit("VISIT_TIME", val);
        },

        routeTime({state, commit}, val: number) {
            commit("ROUTE_TIME", val);
        },

        startLat({state, commit}, val: number) {
            commit("START_LAT", val);
        },

        startLng({state, commit}, val: number) {
            commit("START_LNG", val);
        },

        isDynPoint({state, commit}, val: boolean) {
            commit("IS_DYN_POINT", val);
        },

        startFriendlyName({state, commit}, val: string) {
            commit("START_FRIENDLY_NAME", val);
        },

        registerEmail({state, commit}, val: string) {
            commit("REGISTER_EMAIL", val);
        },

        skuInfosJson({state, commit}, val: string) {
            commit("SKUINFOS_JSON", val);
        },

        genericDialogShow({state, commit}, val: boolean) {
            commit("GENERIC_DIALOG_SHOW", val);
        },

        genericDialogTitle({state, commit}, val: string) {
            commit("GENERIC_DIALOG_TITLE", val);
        },

        genericDialogText({state, commit}, val: string) {
            commit("GENERIC_DIALOG_TEXT", val);
        },

        genericDialogIcon({state, commit}, val: string) {
            commit("GENERIC_DIALOG_ICON", val);
        },

        genericDialogConfirmCallback({state, commit}, val: Function | null) {
            commit("GENERIC_DIALOG_CONFIRM_CALLBACK", val);
        },

        genericDialogOnCloseCallback({state, commit}, val: Function | null) {
            commit("GENERIC_DIALOG_ON_CLOSE_CALLBACK", val);
        },

        genericDialogReset({state, commit}) {
            commit("GENERIC_DIALOG_RESET", false);
        },

        centerMapData({state, commit}, val: { lat: number, lng: number }) {
            commit("CENTER_MAP_DATA", val);
        },

        searchPinData({state, commit}, val: { pinType: RwPinTypes, lat: number, lng: number }) {
            commit("SEARCH_PIN_DATA", val);
        },

        showPlannerToolbar({state, commit}, val: boolean) {
            commit("SHOW_PLANNER_TOOLBAR", val);
        },

        showInvalidPinBanner({state, commit}, val: boolean) {
            commit("SHOW_INVALID_PIN_BANNER", val);
        },

        invalidPinError({state, commit}, val: string) {
            commit("INVALID_PIN_ERROR", val);
        },

        invalidPinIcon({state, commit}, val: string) {
            commit("INVALID_PIN_ICON", val);
        },

        previousPin({state, commit}, val: RwPin | null) {
            commit("PREVIOUS_PIN", val);
        },

        showDropSelect({state, commit}, val: boolean) {
            commit("SHOW_DROP_SELECT", val);
        },

        stopDropOrigin({state, commit}, val: RwStop | null) {
            commit("STOP_DROP_ORIGIN", val);
        },

        showDropRemove({state, commit}, val: boolean) {
            commit("SHOW_DROP_REMOVE", val);
        },

        showFlexOptIn({state, commit}, val: boolean) {
            commit("SHOW_FLEX_OPT_IN", val);
        },

        showOptInPostMessage({state, commit}, val: boolean) {
            commit("SHOW_OPT_IN_POST_MESSAGE", val);
        },

        showStopsViolations({state, commit}, val: string[]) {
            commit('SHOW_STOPS_VIOLATIONS', val);
        },

        outdatedVersion({state, commit}, val: VersionOutdatedEnum) {
            commit('OUTDATED_VERSION', val);
        },

        showSiteRoutePicker({state, commit}, val: boolean) {
            commit('SHOW_SITE_ROUTE_PICKER', val);
        },

        showTaskRoutePicker({state, commit}, val: boolean) {
            commit('SHOW_TASK_ROUTE_PICKER', val);
        },

        showGenericRoutePicker({state, commit}, val: boolean) {
            commit('SHOW_GENERIC_ROUTE_PICKER', val);
        },

        genericRoutePickerFilter({state, commit}, val: any) {
            commit('GENERIC_ROUTE_PICKER_FILTER', val);
        },

        changePin({state, commit}, val: RwPin) {
            commit("CHANGE_PIN", val);
        },

        zoomLevel({state, commit}, val: number) {
            commit("ZOOM_LEVEL", val);
        },

        isMobileView({state, commit}, val: boolean) {
            commit("IS_MOBILE_VIEW", val);
        },

        isTabletView({state, commit}, val: boolean) {
            commit("IS_TABLET_VIEW", val);
        },

        recentStopAdd({state, commit}, val: boolean) {
            commit("RECENT_STOP_ADD", val);
        },

        greedyTime({state, commit}, val: number) {
            commit("GREEDY_TIME_SET", val);
        },
        greedyDist({state, commit}, val: number) {
            commit("GREEDY_DIST_SET", val);
        },
    },

    mutations: {

        MAP_CENTROID(state, val: { lat: number, lng: number, zoom: number }) {
            state.mapCentroid = val;
        },

        SHOW_ROUTE_NEW(state, val: boolean) {
            state.showRouteNew = val;
        },

        ROUTE_NEW_INIT_EMPTY(state, val: boolean) {
            state.routeNewInitEmpty = val;
        },

        ROUTE_NEW_INIT_ROUTE(state, val: RwRoute) {
            state.routeNewInitRoute = val;
        },

        SHOW_SEARCH_OPTIONS(state, val: boolean) {
            state.showSearchOptions = val;
        },

        STOP_EDIT_INIT_STOP(state, val: RwStop) {
            state.stopEditInitStop = val;
        },

        HIST_EDIT_INIT_HISTORY(state, val: RwHistory) {
            state.histEditInitHistory = val;
        },

        SHOW_STATUS_PICKER(state, val: boolean) {
            state.showStatusPicker = val;
        },

        STATUS_PICKER_INIT_NAME(state, val: string) {
            state.statusPickerInitName = val;
        },

        STATUS_PICKER_INIT_STATUS(state, val: number) {
            state.statusPickerInitStatus = val;
        },

        SHOW_RELO_PICKER(state, val: boolean) {
            state.showReloPicker = val;
        },

        RELO_PICKER_INIT_TITLE(state, val: string) {
            state.reloPickerInitTitle = val;
        },

        RELO_PICKER_INIT_LAT(state, val: number) {
            state.reloPickerInitLat = val;
        },

        RELO_PICKER_INIT_LNG(state, val: number) {
            state.reloPickerInitLng = val;
        },

        ENABLE_RELO_PICKER_SEARCH(state, val: boolean) {
            state.enableReloPickerSearch = val;
        },

        RELO_PICKER_ADD_REPORT(state, val: boolean) {
            state.reloPickerAddReport = val;
        },

        SELECTED_SEARCH_RESULT(state, val: RwSearchResult) {
            state.selectedSearchResult = val;
        },

        SEARCH_REPORT_INFO(state, val: Array<RwAddReport>) {
            state.searchReportInfo = val;
        },

        SHOW_ROUTE_PICKER(state, val: boolean) {
            state.showRoutePicker = val;
        },

        ROUTE_PICKER_ROUTE_ID(state, val: string) {
            state.routePickerSrcId = val;
        },

        SHOW_SKED_PICKER(state, val: boolean) {
            state.showSkedPicker = val;
        },

        SKED_PICKER_PRIORITY(state, val: number) {
            state.skedPickerPriority = val;
        },

        SKED_PICKER_START(state, val: number) {
            state.skedPickerStart = val;
        },

        SKED_PICKER_FINISH(state, val: number) {
            state.skedPickerFinish = val;
        },

        SHOW_VISIT_PICKER(state, val: boolean) {
            state.showVisitPicker = val;
        },

        VISIT_PICKER_INIT(state, val: number) {
            state.visitPickerInit = val;
        },

        COLOR_PICKER_INIT_COLOR(state, val: string) {
            state.colorPickerInitColor = val;
        },

        SHOW_COLOR_PICKER(state, val: boolean) {
            state.showColorPicker = val;
        },

        SHOW_ADD_ROUTE_PICKER(state, val: boolean) {
            state.showAddRoutePicker = val;
        },

        REFRESH_ROUTE_TRIGGER(state, val: number) {
            state.refreshRouteTrigger = val;
        },

        IS_STOP_FILTERED(state, val: boolean) {
            state.isStopFiltered = val;
        },

        IS_SITE_FILTERED(state, val: boolean) {
            state.isSiteFiltered = val;
        },

        IS_TASK_FILTERED(state, val: boolean) {
            state.isTaskFiltered = val;
        },

        IS_DRIVER_FILTERED(state, val: boolean) {
            state.isDriverFiltered = val;
        },

        SHOW_MAP_OPTIONS(state, val: boolean) {
            state.showMapOptions = val;
        },

        CONFIRM_TITLE(state, val: string) {
            state.confirmTitle = val;
        },

        CONFIRM_BODY(state, val: string) {
            state.confirmBody = val;
        },

        CONFIRM_POS_TEXT(state, val: string) {
            state.confirmPosText = val;
        },

        CONFIRM_NEG_TEXT(state, val: string) {
            state.confirmNegText = val;
        },

        SHOW_CONFIRM(state, val: boolean) {
            state.showConfirm = val;
        },

        CONFIRM_DONT_SHOW_CHECK(state, val: boolean) {
            state.confirmDontShowCheck = val;
        },

        CONFIRM_DONT_SHOW_CALLBACK(state, val: Function | null) {
            state.confirmDontShowCallback = val;
        },

        IS_OPEN_DRAWER(state, val: boolean) {
            state.isOpenDrawer = val;
        },

        SELECTED_TASK(state, val: RwStop) {
            state.selectedTask = val;
        },

        SHOW_STOP_DOCK(state, val: boolean) {
            state.showStopDock = val;
        },

        SHOW_STOP_POP(state, val: boolean) {
            state.showStopPop = val;
        },

        SHOW_DRIVER_DOCK(state, val: boolean) {
            state.showDriverDock = val;
        },

        SHOW_DRIVER_POP(state, val: boolean) {
            state.showDriverPop = val;
        },

        SHOW_SITE_POP(state, val: boolean) {
            state.showSitePop = val;
        },

        SHOW_SITE_DOCK(state, val: boolean) {
            state.showSiteDock = val;
        },

        SHOW_SKED_POP(state, val: boolean) {
            state.showSkedPop = val;
        },

        SHOW_SKED_DOCK(state, val: boolean) {
            state.showSkedDock = val;
        },

        SHOW_ROUTE_DOCK(state, val: boolean) {
            state.showRouteDock = val;
        },

        SHOW_ROUTE_POP(state, val: boolean) {
            state.showRoutePop = val;
        },

        SELECTED_CLUSTER_PIN(state, val: RwPin) {
            state.selectedClusterPin = val;
        },

        SHOW_CLUSTER_PICKER(state, val: boolean) {
            state.showClusterPicker = val;
        },

        SHOW_ASSIGN_PICKER(state, val: boolean) {
            state.showAssignPicker = val;
        },

        SHOW_ADD_REPORT(state, val: boolean) {
            state.showAddReport = val;
        },
        SHOW_ADD_MISSING_REPORT(state, val: boolean) {
            state.showAddMissingReport = val;
        },

        ADD_REPORT(state, val: RwAddReport) {
            state.addReport = val;
        },

        ASSIGN_PICKER_INIT_ROUTE(state, val: RwRoute) {
            state.assignPickerInitRoute = val;
        },

        SNACK_BAR_TEXT(state, val: string) {
            state.snackBarText = val;
        },

        SNACK_BAR_COLOR(state, val: string) {
            state.snackBarColor = val;
        },

        SHOW_SNACK_BAR(state, val: boolean) {
            state.showSnackbar = val;
        },

        SHOW_ROUTE_ORDER(state, val: boolean) {
            state.showRouteOrder = val;
        },

        SHOW_ROUTE_SUMMARY(state, val: boolean) {
            state.showRouteSummary = val;
        },

        SEARCH_RESULTS_WEB(state, val: RwSearchResult[]) {
            state.searchResultsWeb = val;
        },

        SEARCH_RESULTS_SITES(state, val: RwSearchResult[]) {
            state.searchResultsSites = val;
        },

        SEARCH_RESULTS_TASKS(state, val: RwSearchResult[]) {
            state.searchResultsTasks = val;
        },

        SEARCH_RESULTS_COORDS(state, val: RwSearchResult[]) {
            state.searchResultsCoords = val;
        },

        SHOW_DATA_LAYER_ROUTE(state, val: boolean) {
            state.showDataLayerRoute = val;
        },

        SHOW_DATA_LAYER_SITES(state, val: boolean) {
            state.showDataLayerSites = val;
        },

        SHOW_DATA_LAYER_TASKS(state, val: boolean) {
            state.showDataLayerTasks = val;
        },

        // SHOW_DATA_LAYER_DRIVERS(state, val: boolean) {
        //   state.showDataLayerDrivers = val;
        // },

        SELECTED_STOP(state, val: RwStop) {
            state.selectedStop = val;
        },

        SHOW_TASK_EDIT(state, val: boolean) {
            state.showTaskEdit = val;
        },

        IS_DATA_LOADED(state, val: boolean) {
            state.isDataLoaded = val;
        },

        TASKS(state, val: RwStop[]) {
            state.tasks = val;
        },

        TASK_PINS(state, val: RwPin[]) {
            state.taskPins = val;
        },

        SHOW_ROUTE_EDIT(state, val: boolean) {
            state.showRouteEdit = val;
        },

        ROUTE_EDIT_INIT_ROUTE(state, val: RwSked) {
            state.routeEditInitRoute = val;
        },

        SHOW_ROUTE_DELETE(state, val: boolean) {
            state.showRouteDelete = val;
        },

        SHOW_ROUTE_ASSIGN(state, val: boolean) {
            state.showRouteAssign = val;
        },

        SHOW_ROUTE_COPY(state, val: boolean) {
            state.showRouteCopy = val;
        },

        SHOW_ROUTE_COMPLETE(state, val: boolean) {
            state.showRouteComplete = val;
        },

        STEPS(state, val: RwStep[]) {
            state.steps = val;
        },

        SHOW_PREVIEW_PANEL(state, val: boolean) {
            state.showPinPanel = val;
        },

        SHOW_PREVIEW_POPUP(state, val: boolean) {
            state.showPinPopup = val;
        },

        SHOW_STOP_EDIT(state, val: boolean) {
            state.showStopEdit = val;
        },

        SHOW_HISTORY_EDIT(state, val: boolean) {
            state.showHistoryEdit = val;
        },

        SHOW_SITE_EDIT(state, val: boolean) {
            state.showSiteEdit = val;
        },

        SITE_EDIT_INIT_SITE(state, val: RwSite) {
            state.siteEditInitSite = val;
        },

        // SHOW_SITE_RELOCATE(state, val: boolean){
        //   state.showSiteRelocate = val;
        // },

        SHOW_SITE_CREATE(state, val: boolean) {
            state.showSiteCreate = val;
        },
        SHOW_SITE_MULTI_COLOR(state, val: boolean) {
            state.showSiteMultiColor = val;
        },

        SHOW_EDIT_DRIVER_PASS(state, val: boolean) {
            state.showEditDriverPass = val;
        },

        SHOW_EDIT_DRIVER_UNAME(state, val: boolean) {
            state.showEditDriverUname = val;
        },

        SHOW_REPLACE_DRIVER(state, val: boolean) {
            state.showReplaceDriver = val;
        },

        SHOW_DELETE_DRIVER(state, val: boolean) {
            state.showDeleteDriver = val;
        },

        SHOW_CREATE_DRIVER(state, val: boolean) {
            state.showCreateDriver = val;
        },

        SHOW_CREATE_BULK_USER(state, val: boolean) {
            state.showCreateBulkUser = val;
        },

        SHOW_DOMAIN_POPUP(state, val: boolean) {
            state.showDomainPopup = val;
        },

        SHOW_PING_TEST(state, val: boolean) {
            state.showPingTest = val;
        },

        SHOW_DELETE_PROMPT(state, val: boolean) {
            state.showDeletePrompt = val;
        },

        SHOW_CREATE_PROMPT(state, val: boolean) {
            state.showCreatePrompt = val;
        },

        AV_INFO(state, val: RwAvatarInfo) {
            state.avInfo = val;
        },
        //
        // AV_URL(state, val: string) {
        //   state.avUrl = val;
        // },
        //
        // AV_TYPE(state, val: number) {
        //   state.avType = val;
        // },
        //
        // AV_COLOR(state, val: string) {
        //   state.avColor = val;
        // },
        //
        // AV_INITS(state, val: string) {
        //   state.avInits = val;
        // },

        EDIT_AFTER_ADD(state, val: boolean) {
            state.editAfterAdd = val;
        },

        EDIT_FROM_COPY(state, val: boolean) {
            state.editFromCopy = val;
        },

        HIDE_PING_POPUP(state, val: boolean) {
            state.hidePingPopup = val;
        },

        HIDE_DELETE_POPUP(state, val: boolean) {
            state.hideDeletePopup = val;
        },

        HIDE_CREATE_POPUP(state, val: boolean) {
            state.hideCreatePopup = val;
        },

        HIDE_450_POPUP(state, val: boolean) {
            state.hide450Popup = val;
        },

        PROMPT_450_POPUP(state, val: string[]) {
            state.prompt450Popup = val;
        },

        WARNED_450_POPUP(state, val: string[]) {
            state.warned450Popup = val;
        },

        PROMPT_500_POPUP(state, val: string[]) {
            state.prompt500Popup = val;
        },

        HIDE_TASKS_WARN(state, val: boolean) {
            state.hideTasksWarn = val;
        },

        HIDE_STOP_STATUS(state, val: boolean) {
            state.hideStopStatus = val;
        },

        HIDE_CC_POPUP(state, val: boolean) {
            state.hideCCPopup = val;
        },

        VAL_CC_POPUP(state, val: number) {
            state.valCCPopup = val;
        },

        WARNED_CC_POPUP(state, val: string[]) {
            state.warnedCCPopup = val;
        },

        WARNED_CC_POPUP_OVERDUE(state, val: string[]) {
            state.warnedCCPopupOverdue = val;
        },

        SHOW_DRIVER_EDIT(state, val: boolean) {
            state.showDriverEdit = val;
        },

        DRIVER_EDIT_INIT_DRIVER(state, val: RwUser) {
            state.driverEditInitDriver = val;
        },

        SHOW_SKED_EDIT(state, val: boolean) {
            state.showSkedEdit = val;
        },

        SKED_EDIT_INIT_SKED(state, val: boolean) {
            state.skedEditInitSked = val;
        },


        SHOW_SKED_CREATE(state, val: boolean) {
            state.showSkedCreate = val;
        },

        // SHOW_SKED_DELETE(state, val: boolean){
        //   state.showSkedDelete = val;
        // },

        SHOW_PRO_BETA_PRIMER(state, val: boolean) {
            state.showProBetaPrimer = val;
        },

        SHOW_PROFILE_EDIT(state, val: boolean) {
            state.showProfileEdit = val;
        },

        SHOW_LIMIT_POPUP(state, val: boolean) {
            state.showLimitPopup = val;
        },

        SHOW_NEW_PASS(state, val: boolean) {
            state.showNewPass = val;
        },
        //
        // SHOW_USER_SITE_BANNER(state, val: boolean){
        //   state.showUserSiteBanner = val;
        // },

        HAS_CLOSED_USER_SITE_BANNER(state, val: boolean) {
            state.hasClosedUserSiteBanner = val;
        },

        LAST_CLOSED_UPDATE_PAYMENT_BANNER(state, val: Date) {
            state.lastClosedPaymentBanner = val;
        },

        SHOW_REGISTRATION_TYPE(state, val: boolean) {
            state.showRegistrationType = val;
        },

        SHOW_PRO_TO_TEAM(state, val: boolean) {
            state.showProToTeam = val;
        },

        SHOW_CC_EXPIRE(state, val: boolean) {
            state.showCCExpire = val;
        },

        SHOW_EDIT_AVATAR(state, val: boolean) {
            state.showEditAvatar = val;
        },

        SELECTED_ROUTE(state, val: RwRoute) {
            state.selectedRoute = val;
        },

        ROUTE_TO_EDIT(state, val: RwRoute) {
            state.routeToEdit = val;
        },


        SELECTED_ROUTE_SPACE(state, val: RwRoute) {
            state.selectedRouteSpace = val;
        },

        SELECTED_ROUTES(state, val: RwRoute[]) {
            state.selectedRoutes = val;
        },

        SELECTED_SITE(state, val: RwSite) {
            state.selectedSite = val;
        },

        SELECTED_SKED(state, val: RwSked) {
            state.selectedSked = val;
        },

        HIST(state, val: RwHistory[]) {
            state.hist = val;
        },

        SELECTED_DRIVER(state, val: RwUser) {
            state.selectedDriver = val;
        },

        HIGHLIGHT_MANUAL(state, val: number) {
            state.highLightManual = val;
        },

        SHOW_PANEL_STATUS(state, val: boolean) {
            state.showPanelStatus = val;
        },

        SHOW_PANEL_MAP(state, val: boolean) {
            state.showPanelMap = val;
        },

        SHOW_PANEL_LIST(state, val: boolean) {
            state.showPanelList = val;
        },

        SHOW_SEARCH_PANEL(state, val: boolean) {
            state.showSearchPanel = val;
        },

        SHOW_DATA_LAYERS(state, val: boolean) {
            state.showDataLayers = val;
        },

        MAP_SHOW_SATELLITE(state, val: boolean) {
            state.mapShowSatellite = val;
        },

        MAP_SHOW_TRAFFIC(state, val: boolean) {
            state.mapShowTraffic = val;
        },

        MAP_SHOW_INCIDENTS(state, val: boolean) {
            state.mapShowIncidents = val;
        },


        ACTIVE_ROUTE(state, route: RwRoute) {
            state.activeRoute = route;
        },

        ROUTE(state, route: RwRoute) {
            //RwLog.consoleLog(`store ROUTE`, route);
        },

        ROUTES(state, routes: RwRoute[]) {
            //Vue.set(state, "routes", routes);
            state.routes = routes;
        },

        SITES(state, sites: RwSite[]) {
            Vue.set(state, "sites", sites);
            //state.routes = routes;
        },

        SKEDS(state, skeds: RwSked[]) {
            //Vue.set(state, "scheds", skeds);
            state.skeds = skeds;
        },

        DRIVERS(state, drivers: RwUser[]) {
            //console.warn("SET_DRIVERS", drivers);
            state.drivers = drivers;
            //Vue.set(state, "drivers", drivers);
        },

        OPEN_ROUTES(state, routes: RwRoute[]) {
            state.openRoutes = routes;
        },

        IS_LOADING(state, val: boolean) {
            state.loading = val;
        },

        HAS_CURRENT_PURCHASE(state, val: boolean) {
            state.hasCurrPurch = val;
        },

        HAS_PRO_BETA_PRIMED(state, val: boolean) {
            state.hasProBetaPrimed = val;
        },

        UPDATE_START_PREF(state, val: number) {
            state.updateStartPref = val;
        },

        PINNED_STATUS(state, val: string[]) {
            state.pinnedStatus = val;
        },

        TOKEN(state, val: string) {
            state.token = val;
        },

        IS_DARK_MODE(state, val: boolean) {
            state.isDarkMode = val;
        },

        IS_LOGGED_IN(state, val: boolean) {
            state.isLoggedIn = val;
        },

        LAST_SYNC_MS(state, val: number) {
            state.lastSyncMS = val;
        },

        LAST_DELTA_MS(state, val: number) {
            state.lastDeltaMS = val;
        },

        DRIVER_LAST_SYNC_MS(state, val: number) {
            state.driverLastSyncMS = val;
        },

        LAST_STATUS_MS(state, val: number) {
            state.lastStatusMS = val;
        },

        LAST_TOKEN_MS(state, val: number) {
            state.lastTokenMS = val;
        },

        IS_TEAM(state, val: boolean) {
            state.isTeam = val;
        },

        IS_AUTO_RENEWING(state, val: boolean) {
            state.isAutoRenewing = val;
        },

        IS_PRO_MONTHLY(state, val: boolean) {
            state.isProMonthly = val;
        },

        IS_FLEX_SOLO(state, val: boolean) {
            state.isFlexSolo = val;
        },

        FLEX_DISPLAY(state, val: number) {
            state.flexDisplay = val;
        },

        IS_DISP(state, val: boolean) {
            state.isDisp = val;
        },

        IS_SIMPLE_MAP(state, val: boolean) {
            state.isSimpleMap = val;
        },

        SET_ROLE(state, val: number) {
            state.role = val;
        },


        STOP_PINS(state, val: RwPin[]) {
            state.stopPins = val;
        },

        SITE_PINS(state, val: RwPin[]) {
            state.sitePins = val;
        },

        START_PIN(state, val: RwPin) {
            state.startPin = val;
        },

        DRIVER_PINS(state, val: RwPin[]) {
            state.driverPins = val;
        },


        SELECTED_PINS(state, val: RwPin[]) {
            state.selectedPins = val;
        },

        SIZE_DELTA(state, val: number) {
            state.sizeDelta = val;
        },

        IS_MULTI_MODE(state, val: boolean) {
            state.isMultiMode = val;
        },

        MULTI_MODE(state, val: number) {
            //Vue.set(state, "selectMode", val);
            state.multiMode = val;
        },

        MAP_SELECTOR(state, val: number) {
            state.multiModeTool = val;
        },

        STOP_COLOR(state, val: string) {
            state.stopColor = val;
        },

        TASK_COLOR(state, val: string) {
            state.taskColor = val;
        },

        SITE_COLOR(state, val: string) {
            state.siteColor = val;
        },

        OPT_MODE(state, val: number) {
            state.optMode = val;
        },

        TRAVEL_MODE(state, val: string) {
            state.travelMode = val;
        },

        IS_ROUND_TRIP(state, val: boolean) {
            state.isRoundTrip = val;
        },

        AVOIDS(state, val: string[]) {
            state.avoids = val;
        },

        DIST_UNITS(state, val: number) {
            state.distUnits = val;
        },

        VISIT_TIME(state, val: number) {
            state.visitTime = val;
        },

        ROUTE_TIME(state, val: number) {
            state.routeTime = val;
        },

        START_LAT(state, val: number) {
            state.startLat = val;
        },

        START_LNG(state, val: number) {
            state.startLng = val;
        },

        IS_DYN_POINT(state, val: boolean) {
            state.isDynPoint = val;
        },

        START_FRIENDLY_NAME(state, val: string) {
            state.startFriendlyName = val;
        },

        REGISTER_EMAIL(state, val: string) {
            state.registerEmail = val;
        },

        SKUINFOS_JSON(state, val: string) {
            state.skuInfosJson = val;
        },

        GENERIC_DIALOG_SHOW(state, val: boolean) {
            state.genericDialog = Object.assign(state.genericDialog, {show: val});
        },

        GENERIC_DIALOG_TITLE(state, val: string) {
            state.genericDialog = Object.assign(state.genericDialog, {title: val});
        },

        GENERIC_DIALOG_TEXT(state, val: string) {
            state.genericDialog = Object.assign(state.genericDialog, {text: val});
        },

        GENERIC_DIALOG_ICON(state, val: string) {
            state.genericDialog = Object.assign(state.genericDialog, {icon: val});
        },

        GENERIC_DIALOG_CONFIRM_CALLBACK(state, val: Function | null) {
            state.genericDialog = Object.assign(state.genericDialog, {confirmCallback: val});
        },

        GENERIC_DIALOG_ON_CLOSE_CALLBACK(state, val: Function | null) {
            state.genericDialog = Object.assign(state.genericDialog, {onCloseCallback: val});
        },

        GENERIC_DIALOG_RESET(state) {
            state.genericDialog = new RwGenericDialog();
        },

        CENTER_MAP_DATA(state, val: { lat: number, lng: number }) {
            state.centerMapData = val;
        },

        SEARCH_PIN_DATA(state, val: { pinType: RwPinTypes, lat: number, lng: number }) {
            state.searchPinData = val;
        },

        SHOW_PLANNER_TOOLBAR(state, val: boolean) {
            state.showPlannerToolbar = val;
        },

        SHOW_INVALID_PIN_BANNER(state, val: boolean) {
            state.showInvalidPinBanner = val;
        },

        INVALID_PIN_ERROR(state, val: string) {
            state.invalidPinError = val;
        },

        INVALID_PIN_ICON(state, val: string) {
            state.invalidPinIcon = val;
        },

        PREVIOUS_PIN(state, val: RwPin | null) {
            state.previousPin = val;
        },

        SHOW_DROP_SELECT(state, val: boolean) {
            state.showDropSelect = val;
        },

        STOP_DROP_ORIGIN(state, val: RwStop | null) {
            state.stopDropOrigin = val;
        },

        SHOW_DROP_REMOVE(state, val: boolean) {
            state.showDropRemove = val;
        },

        SHOW_FLEX_OPT_IN(state, val: boolean) {
            state.showFlexOptIn = val;
        },

        SHOW_OPT_IN_POST_MESSAGE(state, val: boolean) {
            state.showOptInPostMessage = val;
        },

        SHOW_STOPS_VIOLATIONS(state, val: string[]) {
            state.showStopsViolations = val;
        },
        OUTDATED_VERSION(state, val: VersionOutdatedEnum) {
            state.outdatedVersion = val;
        },

        SHOW_SITE_ROUTE_PICKER(state, val: boolean) {
            state.showSiteRoutePicker = val;
        },

        SHOW_TASK_ROUTE_PICKER(state, val: boolean) {
            state.showTaskRoutePicker = val;
        },

        SHOW_GENERIC_ROUTE_PICKER(state, val: boolean) {
            state.showGenericRoutePicker = val;
        },

        GENERIC_ROUTE_PICKER_FILTER(state, val: any) {
            state.genericRoutePickerFilter = val;
        },

        CHANGE_PIN(state, val: RwPin) {
            state.changePin = val;
        },

        ZOOM_LEVEL(state, val: number) {
            state.zoomLevel = val;
        },

        IS_MOBILE_VIEW(state, val: boolean) {
            state.isMobileView = val;
        },

        IS_TABLET_VIEW(state, val: boolean) {
            state.isTabletView = val;
        },

        RECENT_STOP_ADD(state, val: boolean) {
            state.recentStopAdd = val;
        },

        GREEDY_TIME_SET(state, val: number) {
            state.greedyTime = val;
        },

        GREEDY_DIST_SET(state, val: number) {
            state.greedyDist = val;
        }
    }
});

