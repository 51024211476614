import Vue from "vue";
import {RwLog} from "@/app/dal/RwLog";

export default class RwDeferredLog {
    static log(source: string, message: string): void {
        Vue.nextTick(() => RwLog.log(source, message));
    }

    static warn(source: string, message: string): void {
        Vue.nextTick(() => RwLog.warn(source, message));
    }

    static error(source: string, message: string): void {
        Vue.nextTick(() => RwLog.error(source, message));
    }
}
