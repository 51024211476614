

import moment from "moment";
import dal from "@/app/dal/RwDal";
import theGlobals, {RwGlobals} from '@/app/RwGlobals';
import thePlanSpace from "@/app/views/planner/RwPlannerSpace";
import rwPlannerSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwConstants} from "@/app/RwConstants";
import RwPages from "@/app/consts/RwPages";
import {RwLog} from "@/app/dal/RwLog";
import {Component, Vue, Watch} from "vue-property-decorator";
import {RwRoute, RwRoutePick, RwRouteStatusCodes} from "@/app/dem/RwRoute";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwAvatarInfo} from "@/app/dem/RwUser";
import {RwMultiMode, RwRoleTypes, SubscriptionState, VersionOutdatedEnum} from "@/app/RwEnums";
import RwConfirm from "@/app/views/pickers/RwConfirm.vue";
import RwColorPicker from "@/app/views/pickers/RwColorPicker.vue";
import RwVisitPicker from "@/app/views/pickers/RwVisitPicker.vue";
import {RwSked} from "@/app/dem/RwSked";
import RwSkedPicker from "@/app/views/pickers/RwSkedPicker.vue";
import RwReloPicker from "@/app/views/pickers/RwReloPicker.vue";
import {RwGeoPoint} from "@/app/utils/RwGeoUtils";
import RwStatusPicker from "@/app/views/pickers/RwStatusPicker.vue";
import RwAssignPicker from "@/app/views/pickers/RwAssignPicker.vue";
import RwStopEdit from "@/app/views/planner/RwStopEdit.vue";
import RwHistoryEdit from "@/app/views/history/RwHistoryEdit.vue";
import {RwStop} from "@/app/dem/RwStop";
import {RwDeltas} from "@/app/dal/RwDeltas";
import {RwSite} from "@/app/dem/RwSite";
import RwRouteNew from "@/app/views/pickers/RwRouteNew.vue";
import RwEventBus from "@/utils/RwEventBus";
import RwSiteEdit from "@/app/views/sites/RwSiteEdit.vue";
import RwRouteEdit from "@/app/views/routes/RwRouteEdit.vue";
import RwSkedEdit from "@/app/views/skeds/RwSkedEdit.vue";
import RwDriverEdit from "@/app/views/drivers/RwDriverEdit.vue";
import RwGenericDialog from "@/app/views/components/RwGenericDialog.vue";
import theSubTeamSpace, {RwSubTeamSpace} from "@/app/views/subteams/RwSubTeamSpace";
import RwFlexOptIn from "@/app/views/account/RwFlexOptIn.vue";
import RwFlexOptInPostMessage from "@/app/views/account/RwFlexOptInPostMessage.vue";
import RwGenericRoutePicker from "@/app/views/pickers/RwGenericRoutePicker.vue";
import RwProToTeam from "@/app/views/account/RwProToTeam.vue";
import AttributionData from "@/app/utils/attribution";
import {RwTaskBillingProfile} from "@/app/dal/RwTaskBillingProfile";
import Cookies from 'js-cookie';
import PurchSpace from '@/app/views/account/purch/RwPurchSpace';
import RwPODTutorial from "@/app/views/marketplace/RwPODTutorial.vue";

@Component({
  components: {
    RwProToTeam,
    RwFlexOptInPostMessage,
    RwFlexOptIn,
    RwRouteEdit,
    RwStopEdit,
    RwHistoryEdit,
    RwSiteEdit,
    RwSkedEdit,
    RwDriverEdit,
    RwAssignPicker,
    RwStatusPicker,
    RwReloPicker,
    RwConfirm,
    RwColorPicker,
    RwVisitPicker,
    RwSkedPicker,
    RwRouteNew,
    RwGenericDialog,
    RwGenericRoutePicker,
    RwPODTutorial
  }
})
export default class App extends Vue {


  //#region Props

  idxSidebar = 0;
  showUserMenu = false;
  colorInit = "";
  loader = null;
  syncing = false;
  isFirstLoad = true;
  cacheRoutes: RwRoute[];
  lastRefreshRoutes = 0;

  // Dunning Banner
  needsBillingUpdate = false;
  gotBillingData = false;
  billingExpiryMonth = 0;
  billingExpiryYear = 0;
  trialEndingDate: moment.Moment = null;
  lastFour = "";
  cardType = "";
  updateBillingUrl = "";
  lastClosedUpdatePayment = RwPrefUtils.lastClosedPaymentBanner;
  showUpdatePaymentDialog = false;
  showTrialDialog = false;

  get showNavDrawer() {
    return this.isLoggedIn && !this.globals.showSearchPanel &&
        (this.globals.hasActiveFlexSku || this.globals.hasActiveProSku) && this.$route.name !== RwPages.ReferralOnboard && this.$route.name !== RwPages.Login;
  }

  get inTest() {
    return this.globals.isTestSubDomain;
  }

  get globals(): RwGlobals {
    return theGlobals;
  }

  get plan(): RwPlannerSpace {
    return thePlanSpace
  }

  get subTeamsSpace(): RwSubTeamSpace {
    return theSubTeamSpace;
  }

  get showActive10(): string {
    let isActive = this.idxSidebar === 10;
    let style = isActive ? 'custom-active' : 'custom-inactive';
    //console.warn("showActive10 isActive:", isActive, this.idxSidebar, style)
    return style;
  }

  get showActive11(): string {
    let isActive = this.idxSidebar === 11;
    let style = isActive ? 'custom-active' : 'custom-inactive';
    //console.warn("showActive11 isActive:", isActive, this.idxSidebar, style)
    return style;
  }

  get avatarUrl(): string {
    return this.globals.avInfo.avUrl;
  }

  get toolBarColor() {
    return '#E2004F';
  }

  get trialEndingDays() {
    return this.isLoggedIn && this.gotBillingData && this.trialEndingDate ? this.trialEndingDate.diff(moment(), "days") + 1 : "";
  }

  isActiveTab(tab: number): boolean {
    let isActive: boolean = false;

    //@formatter:off
    switch (tab) {
      case 0:
        if (this.idxSidebar === 0) {
          isActive = true;
        }
        break;
      case 1:
        if (this.idxSidebar === 1) {
          isActive = true;
        }
        break;
      case 2:
        if (this.idxSidebar === 2) {
          isActive = true;
        }
        break;
      case 3:
        if (this.idxSidebar === 3) {
          isActive = true;
        }
        break;
      case 4:
        if (this.idxSidebar === 4) {
          isActive = true;
        }
        break;
      case 5:
        if (this.idxSidebar === 5) {
          isActive = true;
        }
        break;
      case 6:
        if (this.idxSidebar === 6) {
          isActive = true;
        }
        break;
      case 7:
        if (this.idxSidebar === 7) {
          isActive = true;
        }
        break;
      case 8:
        if (this.idxSidebar === 8) {
          isActive = true;
        }
        break;
      case 9:
        if (this.idxSidebar === 9) {
          isActive = true;
        }
        break;
      case 10:
        if (this.idxSidebar === 10) {
          isActive = true;
        }
        break;
      case 11:
        if (this.idxSidebar === 11) {
          isActive = true;
        }
        break;
      case 12:
        if (this.idxSidebar === 12) {
          isActive = true;
        }
        break;
    }
    //@formatter:on
    return isActive;
  }

  userName(): string {
    let uname = "";
    if (this.globals.userName) {
      uname = this.globals.userName;
    }
    return uname;
  }

  get appBarColor(): string {
    return this.globals.isDarkMode ? "#333" : "#ddd";
  }

  get titleTextColor(): string {
    return theGlobals.isDarkMode ? "titleDarkMode" : "titleLightMode";
  }

  get appName(): string {
    if (this.globals.hasActiveProSku && !this.globals.isTeam) {
      return "RoadWarrior Pro";
    }
    return "RoadWarrior Flex";
  }

  get syncText(): string {
    let syncText = "";
    if (this.globals.lastSyncMS > 0) {
      let now = moment();
      let deltaSecs = now.diff(this.globals.lastSync, "seconds");
      let duration = moment.duration(deltaSecs, "seconds");
      let humanizeText = duration.humanize();
      syncText = `Last Sync: ${humanizeText} ago`;
    }
    return syncText;

    //return (this.globals.lastSyncMS > 0) ? this.globals.lastSync.format('YYYY.MM.DD HH:mm') : '';
  }

  get activeRoute(): RwRoute {
    return this.plan.activeRoute;
  }

  get isLoggedIn(): boolean {
    return this.globals.isLoggedIn;
  }

  get isTeam(): boolean {
    return this.globals.isTeam;
  }

  get isAdmin(): boolean {
    return this.globals.isAdmin;
  }

  get isOwner(): boolean {
    return this.globals.role === RwRoleTypes.Owner || this.globals.role === RwRoleTypes.Admin;
  }

  get isDisp(): boolean {
    return this.globals.isDisp;
  }

  get isSubTeam(): boolean {
    return this.globals.isSubTeam;
  }

  get isDarkMode(): boolean {
    return this.globals.isDarkMode;
  }

  get loading(): boolean {
    return this.globals.isLoading;
  }

  get taskCount(): number {
    let count = this.globals.tasks ? this.globals.tasks.length : 0;
    return count;
  }

  get sideIconColor(): string {
    if (this.idxSidebar === 0) {
      return `'custom-active'`;
    } else if (this.isDarkMode) {
      return `'custom-inactive'`;
    } else {
      return '#2f2f2f';
    }
  }

  get needsUpdate() {
    return theGlobals.outdatedVersion === VersionOutdatedEnum.NeedsUpdate;
  }

  get needsUpdateSevere() {
    return theGlobals.outdatedVersion === VersionOutdatedEnum.NeedsUpdateSevere;
  }

  get trialButtonTitle() {
    if (this.globals.isLoggedIn && this.globals.hasActiveFlexTrial && this.gotBillingData && this.trialEndingDate) {
      return `${this.trialEndingDays} trial day${this.trialEndingDays !== 1 ? 's' : ''} remaining`;
    }
    return "";
  }

  get showTitlePaymentWarningButton() {
    return this.globals.isLoggedIn &&
        this.globals.hasActiveFlexSku &&
        this.gotBillingData &&
        this.ccExpiring
  }

  get showPaymentBanner() {
    return this.globals.isLoggedIn &&
        this.globals.hasActiveFlexSku &&
        moment().diff(moment(this.globals.lastClosedPaymentBanner), "hours") > 24 &&
        this.gotBillingData &&
        this.needsBillingUpdate;
  }

  get showTrialBanner() {
    return this.globals.isLoggedIn && this.gotBillingData && this.globals.hasActiveFlexTrial;
  }

  get showTitlePaymentButton() {
    return this.globals.isLoggedIn &&
        this.globals.hasActiveFlexSku &&
        moment().diff(moment(this.globals.lastClosedPaymentBanner), "hours") < 24 &&
        this.gotBillingData &&
        this.needsBillingUpdate;
  }

  get ccExpiring() {
    let now = moment();
    if (now.year() >= this.billingExpiryYear) {
      if (now.month() >= this.billingExpiryMonth) {
        return true;
      }
    }
    return false;
  }

  get CardLastFour() {
    if (this.lastFour) {
      return "ending in " + this.lastFour;
    }
    return "";
  }

  get CardType() {
    if (this.cardType) {
      return this.cardType;
    }
    return "";
  }

  get showAppBar() {
    const rtPath = this.$route.path;
    return !rtPath?.startsWith(`/${RwPages.ReferralOnboard}`) && !rtPath?.startsWith(`/${RwPages.Login}`);
  }

  avatarClick() {
    this.showUserMenu = false;
    this.$router.push({name: "account"}).catch();
  }

  //#endregion Props


  //#region UI Navigation

  // upgradeToFlex() {
  //   this.$router.push(RwPages.Upgrade).catch(() => {
  //   });
  // }

  /*  popAdminWarning() {
    if (this.globals.role === -1) {
      this.globals.showSnack("Certain operations like upgrades may not work correctly with an admin account", "warning");
    }
  }*/

  ClosePaymentBanner() {
    this.globals.lastClosedPaymentBanner = new Date();
  }

  OpenTrialDialog() {
    this.showTrialDialog = true;
  }

  OpenFlexGuide() {
    window.open("https://flexguide.roadwarrior.app/getting-started", "_blank");
  }

  UpdatePaymentMethodWarning() {
    RwLog.log("RwApp.UpdatePaymentMethod", "Clicked update payment method warning banner btn");
    this.showUpdatePaymentDialog = true;
  }

  UpdatePaymentMethod() {
    RwLog.log("RwApp.UpdatePaymentMethod", "Clicked update payment method banner btn");
    window.open(rwPlannerSpace.updatePaymentUrl, "_blank");
  }

  upgradeToFlex() {
    // this.$router.push({name: RwPages.Register, params: {email: this.globals.userName}})
    //         .catch(err => {
    //         });
    this.globals.showProToTeam = true;
  }

  contactSupport() {
    window.open(`mailto:support@roadwarrior.app?subject=Flex Support: ${theGlobals.accountId}`, "_blank");
    this.showUserMenu = false;
  }

  cachePath(): string {
    let newPath = this.$route.fullPath;
    if (newPath.includes("planner")) {
      newPath = "/planner";
    }
    //console.log(`newPath: ${newPath}`);
    return newPath;
  }


  navToPlanner(evt, inTaskMode?: boolean) {
    if (this.idxSidebar !== 0) {
      let url = "";
      if (this.activeRoute) {
        url = this.activeRoute.plannerUrl;
      } else {
        let routeId = RwPrefUtils.lastRouteId;
        url = (routeId) ? `/planner/${routeId}` : `/planner`;
      }

      if (inTaskMode) {
        this.plan.multiMode = RwMultiMode.tasks;
      }

      //console.log("navToPlanner", url)
      this.$router.push({path: url}).catch();
    }
  }

  navToRoutes() {
    if (this.idxSidebar !== 1) this.$router.push({name: "routes"}).catch();
  }

  navToUsers() {
    if (this.idxSidebar !== 2) this.$router.push({name: "drivers"}).catch();
  }

  navToSites() {
    if (this.idxSidebar !== 3) this.$router.push({name: "sites"}).catch();
  }

  navToTasks() {
    if (this.idxSidebar !== 4) this.$router.push({name: "tasks"}).catch();
  }

  navToSkeds() {
    if (this.idxSidebar !== 5) this.$router.push({name: "skeds"}).catch();
  }

  navToReports() {
    if (this.idxSidebar !== 6) this.$router.push({name: "reports"}).catch();
  }

  navToUploads() {
    if (this.idxSidebar !== 7) this.$router.push({name: "uploads"}).catch();
  }

  navToSubTeams() {
    if (this.idxSidebar !== 8) this.$router.push({name: "subteams"}).catch();
  }

  navToSettings() {
    if (this.idxSidebar !== 9) this.$router.push({name: "settings"}).catch();
  }

  navToLogReports() {
    if (this.idxSidebar !== 10) this.$router.push({name: "logs"}).catch();
  }

  navToAddReports() {
    if (this.idxSidebar !== 11) this.$router.push({name: "adds"}).catch();
  }

  navToMarketplace() {
    if (this.idxSidebar !== 12) this.$router.push({name: "marketplace"}).catch();
  }


  //#endregion


  //#region User Actions

  onConfirm(isConfirmed: boolean, dontShow: boolean = false) {
    //console.log("RwApp.onConfirm isConfirmed", isConfirmed);
    this.globals.onConfirm(isConfirmed, dontShow);
  }

  onColorPick(color: string) {
    //console.log("RwApp.onColorPick color", color);
    this.globals.onColorPick(color);
  }


  onVisitPick(visitTimeMins: number) {
    //console.log("RwApp.onVisitPick visitTimeMins", visitTimeMins);
    this.globals.onVisitPick(visitTimeMins);
  }

  onSkedPick(sked: RwSked) {
    //console.log("RwApp.onSkedPick sked", sked);
    this.globals.onSkedPick(sked);
  }

  onReloPick(coord: RwGeoPoint) {
    //console.log("RwApp.onReloPick coord", coord);
    this.globals.onReloPick(coord);
  }

  onStatusPick(status: RwRouteStatusCodes) {
    //console.log("RwApp.onReloPick coord", coord);
    this.globals.onStatusPick(status);
  }

  // newRouteCreated(route:RwRoute){
  //   this.globals.newRouteCreated(route);
  // }


  onAssignPick(route: RwRoute) {
    //console.log("RwApp.onReloPick coord", coord);
    this.globals.onAssignPick(route);
  }


  onRouteEdited(isCommit: boolean) {
    //console.log("RwApp.onRouteEdited isCommit", isCommit);
    this.globals.onRouteEdited(isCommit);
  }

  onStopEdited(isCommit: boolean) {
    //console.log("RwApp.onStopEdited isCommit", isCommit);
    this.globals.onStopEdited(isCommit);
  }

  onHistoryEdited(isCommit: boolean) {
    this.globals.onHistoryEdited(isCommit);
  }

  onSiteEdited(isCommit: boolean) {
    //console.log("RwApp.onSiteEdited isCommit", isCommit);
    this.globals.onSiteEdited(isCommit);
  }

  onDriverEdited(isCommit: boolean) {
    //console.log("RwApp.onDriverEdited isCommit", isCommit);
    this.globals.onDriverEdited(isCommit);
  }

  onSkedEdited(isCommit: boolean) {
    //console.log("RwApp.onSkedEdited isCommit", isCommit);
    this.globals.onSkedEdited(isCommit);
  }


  onRouteNew(route: RwRoute) {
    //console.log("RwApp.onRouteNew route", route);
    this.globals.onRouteNew(route);
  }

  onFileUpload(e) {
    const SOURCE = "App.onFileUpload";
    const self = this;
    //Check if we have a file
    const files = e.target.files;
    if (files && files.length > 0) {

      //Submit File
      let uid = RwPrefUtils.userId;
      let file = files[0];
      let formData = new FormData();
      formData.append('file', file);

      //console.warn("submit file", file);
      let url = `${RwConstants.CoreUri}/users/uimage?uid=${uid}`;
      dal.callWithToken
          .post(
              url,
              formData,
              {headers: {'Content-Type': 'multipart/form-data'}}
          )
          .then(res => {
            if (res) {
              if (res.data) {
                let avInfo = RwAvatarInfo.fromJson(res.data)
                if (avInfo && avInfo.avUrl) {

                  //Update current user if match
                  if (uid == RwPrefUtils.userId) {
                    theGlobals.avInfo = avInfo;
                    //theGlobals.avUrl = avInfo.avUrl;
                  }

                  //Update drivers pin
                  let driver = theGlobals.drivers.find(d => d.userId == uid);
                  if (driver) {
                    driver.imageUrl = avInfo.avUrl;
                  }
                }
              } else {
                RwLog.error(SOURCE, `POST url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}`)
              }
            } else {
              RwLog.error(SOURCE, `POST url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}`)
            }
          })
          .catch(function (err) {
            RwLog.error(SOURCE, `Unhandled: \n\n${err}`);
          });
    }
  }


  onSync() {
    const self = this;
    self.syncing = true;
    //console.warn("onSync")
    this.globals
        .syncDelta()
        .finally(() => self.syncing = false);
  }

  detectAttribParams() {
    const SOURCE = "App.detectAttribParams";
    const params = new URLSearchParams(window.location.search)


    try {
      const storedAttr = RwPrefUtils.attribData;
      let existingAttr = JSON.parse(storedAttr) as AttributionData;
      const hasExistingAttr = !!existingAttr && (existingAttr.utmSource || existingAttr.aid || existingAttr.utmTerm || existingAttr.utmCampaign || existingAttr.agid);

      if (!hasExistingAttr) {
        existingAttr = new AttributionData();
      }

      let attrCookieValues = {} as AttributionData;
      const attrCookie = Cookies.get("attrc");
      if (attrCookie) {
        attrCookieValues = JSON.parse(attrCookie) as AttributionData;
      }
      let attr = new AttributionData();
      attr.aid = params.get('s1aid') ?? (attrCookieValues.aid ? attrCookieValues.aid : existingAttr.aid);
      attr.cid = params.get('s1cid') ?? (attrCookieValues.cid ? attrCookieValues.cid : existingAttr.cid);
      attr.agid = params.get('s1agid') ?? (attrCookieValues.agid ? attrCookieValues.agid : existingAttr.agid);
      attr.kid = params.get('s1kid') ?? (attrCookieValues.kid ? attrCookieValues.kid : existingAttr.kid);
      attr.gclid = params.get('gclid') ?? (attrCookieValues.gclid ? attrCookieValues.gclid : existingAttr.gclid);
      attr.utmSource = params.get('utm_source') ?? (attrCookieValues.utmSource ? attrCookieValues.utmSource : existingAttr.utmSource);
      attr.utmCampaign = params.get('utm_campaign') ?? (attrCookieValues.utmCampaign ? attrCookieValues.utmCampaign : existingAttr.utmCampaign);
      attr.utmTerm = params.get('utm_term') ?? (attrCookieValues.utmTerm ? attrCookieValues.utmTerm : existingAttr.utmTerm);
      attr.utmMedium = params.get('utm_medium') ?? (attrCookieValues.utmMedium ? attrCookieValues.utmMedium : existingAttr.utmMedium);
      attr.utmExp = params.get('utm_exp') ?? (attrCookieValues.utmExp ? attrCookieValues.utmExp : existingAttr.utmExp);
      attr.referDate = params.get('referDate') ?? (attrCookieValues.referDate ? attrCookieValues.referDate : existingAttr.referDate);
      attr.rc = params.get('rc') ?? (attrCookieValues.rc ? attrCookieValues.referDate : existingAttr.referDate);
      attr.ppb = params.get('ppb') ?? (attrCookieValues.ppb ? attrCookieValues.ppb : existingAttr.ppb);
      attr.ppd = params.get('ppd') ?? (attrCookieValues.ppd ? attrCookieValues.ppd : existingAttr.ppd);
      attr.ppt = params.get('ppt') ?? (attrCookieValues.ppt ? attrCookieValues.ppt : existingAttr.ppt);
      attr.fbclid = params.get('fbclid') ?? (attrCookieValues.fbclid ? attrCookieValues.fbclid : existingAttr.fbclid);

      const adidStr = params.get('adid');
      if (adidStr) {
        const parsed = parseInt(adidStr);
        if (!isNaN(parsed)) {
          attr.adid = parsed;
        } else {
          attr.adid = attrCookieValues.adid ?? (existingAttr.adid ? existingAttr.adid : 0);
        }
      } else {
        attr.adid = attrCookieValues.adid ?? (existingAttr.adid ? existingAttr.adid : 0);
      }

      const skipOnboardStep = parseInt(params.get('skipSchedule') ?? '0');

      theGlobals.skipOnboardFirstStep = skipOnboardStep === 1;

      if (attr.ppb) {
        PurchSpace.pricePerBaseCode = attr.ppb;
      }
      if (attr.ppd) {
        PurchSpace.pricePerDriverCode = attr.ppd;
      }
      if (attr.ppt) {
        PurchSpace.pricePerTeamCode = attr.ppt;
      }


      try {
        const fvid = Cookies.get(RwConstants.VIDCookieName);
        if (fvid) {
          attr.fvid = fvid;
        }
      } catch (fvidErr) {
        RwLog.error(SOURCE, `Error getting fvid from fortifi ${JSON.stringify(fvidErr)}`);
      }

      if (attr.rc && !RwPrefUtils.isLoggedIn && !RwPrefUtils.lastGoodUserName) {
        this.globals.isDarkMode = false;
      }

      RwPrefUtils.attribData = JSON.stringify(attr);
    } catch (err) {
      RwLog.error(SOURCE, `Error during attribution detection ${JSON.stringify(err)}`);
    }
  }

  detectSubDomain() {
    const SOURCE = "App.detectSubDomain";

    let domain = "";
    try {
      domain = window.location.host.split('.')[0].toLowerCase();
      if (domain.indexOf("fedex") !== -1) {
        this.globals.isFedExSubDomain = true;
      } else if (domain.indexOf("ontrac") !== -1) {
        this.globals.isOnTracSubDomain = true;
      } else if (domain.indexOf("flextest") !== -1 || domain.indexOf("beta") !== -1) {
        this.globals.isTestSubDomain = true;
      }
      //@formatter:off
      switch (domain) {
        case "fedex":
          RwConstants.SignupUrl = RwConstants.ChargifyProdFedexUrlV2;
          break;
        case "ontrac":
          RwConstants.SignupUrl = RwConstants.ChargifyProdOnTracUrlV2;
          break;
        case "flex":
          RwConstants.SignupUrl = RwConstants.ChargifyProdFlexUrlV2;
          break;
        case "team":
          RwConstants.SignupUrl = RwConstants.ChargifyProdTeamUrlV2;
          break;
        case "flextest":
          RwConstants.SignupUrl = RwConstants.ChargifyFlexTestUrl;
          break;
        case "user":
          RwConstants.SignupUrl = RwConstants.ChargifyProdUserUrlV2;
          break;
        case "beta":
          RwConstants.SignupUrl = RwConstants.ChargifyBetaUrlV2;
          break;
      }
      //@formatter:on
    } catch (err) {
      RwLog.error(SOURCE, `Unhandled: domain:${domain}  \n\n${err}`);
    }
  }

  onLogOut(): void {
    //RwLog.consoleLog("Logging Out");
    this.showUserMenu = false;
    this.globals.logOut();
    this.globals.logoutOtherTabs();
    this.$destroy();
    this.$nextTick(() => {
      window.location.replace(RwPages.Login);
    });

  }

  //#endregion


  //region Watch

  @Watch("activeRoute")
  watchActiveRoute(route: RwRoute) {
    //console.warn(`RwApp.watchActiveRoute`, route);
    //this.plan.startPin = null;
    this.plan.stopPins = [];
    this.plan.clearPinSelections();
  }

  @Watch("activeRoute.stops")
  watchStops(stops: RwStop[]) {
    const self = this;
    const SOURCE = "RwApp.watchStops";
    //console.log(SOURCE);

    let deltas = RwDeltas.getDeltas(stops, this.plan.cacheStops, this.plan.lastRefreshStops);
    //console.warn(SOURCE, deltas)
    let gotRouteUpdate = self.activeRoute && self.activeRoute.lastHash > this.plan.lastRefreshStops;

    this.plan.lastRefreshStops = Date.now();
    if (stops && stops.length > 0) {
      this.plan.cacheStops = [...stops];
    } else {
      this.plan.cacheStops = [];
    }

    if (deltas.gotAdds || deltas.gotRemoves || deltas.gotDeltas || gotRouteUpdate) {
      //deltas.print(SOURCE);
      this.plan.updateStopPins(deltas.adds, deltas.removes, deltas.deltas);
    } else {
      //console.error("force refreshPins")
      let newPins = [...self.plan.stopPins];
      self.plan.stopPins = newPins;
    }

  }

  @Watch("globals.sites")
  watchSites(sites: RwSite[]) {
    //console.warn("App.watchSites");
    if (RwPrefUtils.showDataLayerSites) {
      this.plan.genSitePins();
    }
  }

  @Watch("globals.tasks")
  watchTasks(tasks: RwStop[]) {
    //console.warn("App.watchTasks");
    if (RwPrefUtils.showDataLayerTasks) {
      this.globals.genTaskPins();
    }
  }

  @Watch("globals.routes")
  watchRoutes(routes: RwRoute[]) {
    const self = this;
    //console.warn("App.watchRoutes");

    let deltas = RwDeltas.getDeltas(routes, this.cacheRoutes, this.plan.lastRefreshStops);
    //console.warn("watchRoutes deltas", deltas);

    this.lastRefreshRoutes = Date.now();
    if (routes && routes.length > 0) {
      this.cacheRoutes = [...routes];
    } else {
      this.cacheRoutes = [];
    }

    if (deltas.gotAdds || deltas.gotRemoves || deltas.gotDeltas) {
      //console.log("watchRoutes adds, removes, deltas", deltas.adds.length, deltas.removes.length, deltas.deltas.length);

      if (deltas.removes && deltas.removes.length > 0) {
        deltas.removes.forEach(r => {
          if (self.plan.isOpenRoute(r)) {
            self.plan.closeRoute(r);
          }
        });
      }

    }

  }

  @Watch("plan.openRoutes")
  watchOpenRoutes(openRoutes: RwRoute[], oldRoutes: RwRoute[]) {
    //console.warn("App.watchOpenRoutes", openRoutes.length, oldRoutes.length);
    //Try to resolve first by the RoutId on the url
    let actRoute = this.globals.activeRoute;
    let isResolvedViaUrl = false;
    const routeIdViaUrl = this.$route.params.routeId;

    if (theGlobals.isLoggedIn) {
      //console.log("App.watchOpenRoutes routeIdUrl", routeIdViaUrl);
      if (routeIdViaUrl) {
        const isUrlOpenMatch = routeIdViaUrl && openRoutes.find(r => r.routeId === routeIdViaUrl);
        //console.log("App.watchOpenRoutes isUrlOpenMatch", isUrlOpenMatch);
        if (isUrlOpenMatch) {
          const isActiveRoute = actRoute && actRoute.routeId === routeIdViaUrl;
          if (isActiveRoute) {
            isResolvedViaUrl = true;
            //console.log("App.watchOpenRoutes Happy Path. No action needed");
          } else {
            isResolvedViaUrl = true;
            this.plan.setActiveRouteById(routeIdViaUrl);
            //console.warn(`App.watchOpenRoutes setActiveRouteById`, routeIdViaUrl);
          }
        } else {
          if (this.isFirstLoad) {
            let existingRoute = this.globals.routes.find(r => r.routeId === routeIdViaUrl);
            //console.log("App.watchOpenRoutes targetRoute", existingRoute);
            if (existingRoute) {
              isResolvedViaUrl = true;

              //console.warn("watchOpenRoutes", "isFirstLoad", existingRoute.plannerUrl)
              this.plan.openRoute(existingRoute);
              this.plan.setActiveRouteById(existingRoute.routeId);
              //console.log("App.watchOpenRoutes Open the target route", existingRoute.name);
            }
          }
        }
      }

      if (!isResolvedViaUrl) {

        if (actRoute) {
          let idxRoute = this.globals.routes.findIndex(r => r.routeId === actRoute.routeId);
          if (idxRoute >= 0) {
            const isActiveOpened = openRoutes.find(r => r.routeId === routeIdViaUrl);
            //if (isActiveOpened) {
            //console.log("App.watchOpenRoutes isActiveOpened; should be OK");
            //}
            //else {
            //console.warn("watchOpenRoutes", "unresolved", actRoute.plannerUrl)

            //this.plan.openRouteInPlanner(actRoute)

            // this.plan.openRoute(actRoute);
            // this.plan.activeRoute = actRoute;
            // this.$router.push(actRoute.plannerUrl);
            //console.warn("App.watchOpenRoutes Open the active route", actRoute.name, actRoute.routeId);
            //}
          }
        } else {
          if (openRoutes.length > 0) {

            let firstRoute = openRoutes[0];
            this.plan.openRoute(firstRoute);
            this.plan.setActiveRouteById(firstRoute.routeId);
            //console.warn("watchOpenRoutes", "firstRoute", firstRoute.plannerUrl)
            this.$router.push(firstRoute.plannerUrl);
            //console.warn("App.watchOpenRoutes No Active; Opening First Open");
          } else {
            //console.warn("watchOpenRoutes", "justPlanner")
            this.plan.activeRoute = null;
            this.$router.push("/planner");
          }
        }
      }

      this.isFirstLoad = false;
    }
  }

  onRoutePick(pick: RwRoutePick) {
    this.globals.onRoutePick(pick);
  }

  onUploadsPopupClick() {
    this.globals.showOnboardUploadsPopup = false;
    this.navToUploads();
  }

  //endregion


  //#region LifeCycle

  created() {
    //console.log(`App.created`, this.$route.fullPath);
    this.$vuetify.theme.dark = RwPrefUtils.rwTheme === "rwDark";
    this.globals.isDarkMode = RwPrefUtils.rwTheme === "rwDark";
    this.detectSubDomain();
    this.detectAttribParams();
  }

  mounted() {
    RwEventBus.$on("onRouteNew", this.onRouteNew);
    this.$nextTick(function () {
      window.addEventListener('resize', this.onWindowWidth);
    });
    this.onWindowWidth();

    //Gtm
    theGlobals.updateGtmDataLayer({
      isLoggedIn: true,
      isTrialFlex: theGlobals.hasActiveFlexTrial,
      isActiveFlex: theGlobals.hasActiveFlexSku,
      isMobilePro: theGlobals.hasActiveProSku && !theGlobals.hasActiveFlexSku
    })

    if (this.isLoggedIn && this.globals.hasActiveFlexSku) {

      RwTaskBillingProfile.GetBillingProfile()
          .then(profile => {
            if (profile) {
              if (profile.ProStatus) {
                //this.needsBillingUpdate = profile.ProStatus.ChargifySubState === SubscriptionState.Active; // To Test Banner
                this.needsBillingUpdate = profile.ProStatus.ChargifySubState === SubscriptionState.Past_Due;

                if (profile.ProStatus.ChargifySubState === SubscriptionState.Trialing || profile.ProStatus.ChargifySubState === undefined) {
                  this.trialEndingDate = moment(profile.ProStatus.NextPaymentDate)
                }
                PurchSpace.pricePerBaseAmt = profile.ProStatus.BasePriceAmount;
                PurchSpace.pricePerDriverAmt = profile.ProStatus.DriverPriceAmount;
                PurchSpace.pricePerTeamAmt = profile.ProStatus.TeamPriceAmount;
              }

              this.updateBillingUrl = profile.UpdatePaymentUrl;
              if (profile.PaymentMethod) {
                this.cardType = profile.PaymentMethod.Brand;
                if (profile.PaymentMethod.MaskedCard && profile.PaymentMethod.MaskedCard.length > 4) {
                  this.lastFour = profile.PaymentMethod.MaskedCard.substring(profile.PaymentMethod.MaskedCard.length - 4)
                }

                // For dialog testing
                //this.billingExpiryMonth = 3;
                //this.billingExpiryYear = 2020;

                this.billingExpiryMonth = parseInt(profile.PaymentMethod.ExpirationMonth);
                this.billingExpiryYear = parseInt(profile.PaymentMethod.ExpirationYear);
              }
              this.gotBillingData = true;
            }
          })
    }
  }

  onWindowWidth() {
    this.globals.isMobileView = window.innerWidth <= 512;
    this.globals.isTabletView = window.innerWidth <= 1024;
  }

  updated() {
    let path = this.cachePath().toLowerCase();
    switch (path) {
        //@formatter:off
        //case "/": this.idxSidebar = 0; break;
      case "/planner":
        this.idxSidebar = 0;
        break;
      case "/routes":
        this.idxSidebar = 1;
        break;
      case "/drivers":
        this.idxSidebar = 2;
        break;
      case "/sites":
        this.idxSidebar = 3;
        break;
      case "/tasks":
        this.idxSidebar = 4;
        break;
      case "/skeds":
        this.idxSidebar = 5;
        break;
      case "/reports":
        this.idxSidebar = 6;
        break;
      case "/uploads":
        this.idxSidebar = 7;
        break;
      case "/subteams":
        this.idxSidebar = 8;
        break;
      case "/settings":
        this.idxSidebar = 9;
        break;
      case "/logs":
        this.idxSidebar = 10;
        break;
      case "/adds":
        this.idxSidebar = 11;
        break;
      case "/marketplace":
        this.idxSidebar = 12;
        break;
        //@formatter:on
    }
    //console.log("idxSideBar", this.idxSidebar, path);
  }


  //#endregion


}

