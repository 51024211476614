import theStore from "@/store";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import {RwSked} from "@/app/dem/RwSked";


export class RwSkedSpace {

    get globals(): RwGlobals {
        return theGlobals;
    }


    get selectedSked(): RwSked {
        return theStore.getters.selectedSked;
    }

    set selectedSked(val: RwSked) {
        theStore.dispatch("selectedSked", val);
    }

    selectedSkeds: RwSked[] = [];

    formatPriority(priority: number) {
        let val = Math.floor(priority * 10);
        val = (val == 0) ? val = 1 : val;
        return val;
    }


}

export default new RwSkedSpace();
