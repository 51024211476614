import {RwStop} from "@/app/dem/RwStop";
import {RwUser} from "@/app/dem/RwUser";
import {RwLog} from "@/app/dal/RwLog";
import {RwRoute} from "@/app/dem/RwRoute";
import {RwModel} from "@/app/dem/RwModel";
import {RwStopWithRoute} from "@/app/dem/Reporting/RwStopWithRoute";
import {RwStopCheckDateModel} from "@/app/dem/Reporting/RwStopCheckDate";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import store from "@/app/RwGlobals"
import {RwEntityTypes} from "@/app/RwEnums";
import {RwWebUtils} from "@/app/utils/RwWebUtils";
import {RwError} from "@/app/RwErrors";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";

export class RwTaskStops {


    static getTasks(): Promise<RwStop[]> {
        //console.warn("getTasks");

        return new Promise<RwStop[]>(function (resolve, reject) {

            let uinfo = new RwUser();
            if (uinfo.token) {

                let url = `${RwConstants.CoreUri}/stops/tasks`;
                dal.callWithToken.get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        const json = res.data;
                        let stops = RwStop.fromJsonArray(json);
                        stops.forEach(s => (s.isTask = true));
                        resolve(stops);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                RwLog.consoleError("RwTaskStops.getTasks", "called w/ invalid tkn");
                reject(new Error());
            }
        });
    }


    static addStop(stop: RwStop, route: RwRoute): Promise<RwModel> {
        const SOURCE = "RwTaskStops.addStop";

        return new Promise<RwModel>(function (resolve, reject) {
            let payload = JSON.stringify(stop.toJSON());
            let url = `${RwConstants.CoreUri}/stops/stops?uid=${route.accountId}&sid=${stop.stopId}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    if (res) {
                        if (res.data) {
                            const json = res.data;
                            let deltas = new RwModel();
                            deltas.fromJson(json);
                            resolve(deltas);
                        } else {
                            RwLog.error(SOURCE, `POST url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                            reject();
                        }
                    } else {
                        RwLog.error(SOURCE, `POST url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                        reject();
                    }
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static addStops(stops: RwStop[], route: RwRoute): Promise<boolean> {
        const SOURCE = "RwTaskStops.addStops";

        return new Promise<boolean>(function (resolve, reject) {
            let payload = JSON.stringify(stops.map(s => s.toJSON()));
            //console.log("addStops payload", payload);
            let url = `${RwConstants.CoreUri}/stops/stopsBulk?rid=${route.routeId}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(true);
                    // const json = res.data;
                    // let deltas = new RwModel();
                    // deltas.fromJson(json);
                    // resolve(deltas);
                })
                .catch((err) => {
                    let status = !!err.Code ? err.Code : err.status;
                    if (status == 403) {
                        RwLog.warn(SOURCE, `Unhandled \n${url} \nstops:\n${JSON.stringify(stops.map(e => e.toJSON()))}  \n\nerr:\n${JSON.stringify(err)}`);
                    } else {
                        RwLog.error(SOURCE, `Unhandled \n${url} \nstops:\n${JSON.stringify(stops.map(e => e.toJSON()))}  \n\nerr:\n${JSON.stringify(err)}`);

                    }
                    // const errorData = RwLog.getErrorFormatted(error, {title: "stops", data: stops}, {title: "route", data: route})
                    // RwLog.error("RwTaskStops.addStops", errorData);
                    reject(err);
                });

        });
    }


    static addTasks(tasks: RwStop[]): Promise<RwModel> {

        return new Promise<RwModel>((resolve, reject) => {
            let jStops = [];
            tasks.forEach(stop => {
                jStops.push(stop.toJSON());
            });
            let payload = JSON.stringify(jStops);

            //let payload = JSON.stringify(task);
            let url = `${RwConstants.CoreUri}/stops/tasks?ls=${store.lastSyncMS}`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let deltas = new RwModel();
                    deltas.fromJson(json);
                    //console.log("RwTaskStops.addTask success", json);
                    resolve(deltas);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static addTasksToRoute(tasks: RwStop[], routeId: string, nextSeq?: number): Promise<boolean> {

        return new Promise<boolean>(function (resolve, reject) {

            let stopIds = tasks.map(t => t.stopId);
            let payload = JSON.stringify(stopIds);
            //console.log("addTasksToRoute", payload);

            let maxSeqArg = ""
            if (nextSeq) {
                maxSeqArg = `&nextSeq=${nextSeq}`
            }

            let url = `${RwConstants.CoreUri}/stops/tasks/toRoute?rid=${routeId}&ls=${store.lastSyncMS}${maxSeqArg}`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static updateStop(stop: RwStop): Promise<RwModel> {

        return new Promise<RwModel>(function (resolve, reject) {

            let payload = JSON.stringify(stop.toJSON());
            //console.log('RwTaskStops.updateStop payload', payload);
            let url = `${RwConstants.CoreUri}/stops/stops?uid=${store.orgId}&sid=${stop.stopId}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .put(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let deltas = new RwModel();
                    deltas.fromJson(json);
                    resolve(deltas);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static checkInStop(stop: RwStop, histId: string, siteId?: string): Promise<boolean> {
        return new Promise<boolean>(function (resolve) {
            let payload = JSON.stringify(stop.toJSON(false, true));
            let siteParm = siteId ? `&siteId=${siteId}` : "";
            let url = `${RwConstants.CoreUri}/stops/checkIn?histId=${histId}${siteParm}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .put(url, payload, {timeout: RwConstants.NetTimeout})
                .then(() => {
                    //console.log("RwGlobals.checkInStop callback");
                    resolve(true)
                })
                .catch(() => resolve(false));
        });
    }


    static deleteStops(stopIds: string[]): Promise<RwModel> {
        const SOURCE = "RwTaskStops.deleteStops"

        return new Promise<RwModel>(function (resolve, reject) {
            let payload = JSON.stringify(stopIds);
            let url = `${RwConstants.CoreUri}/stops/stops?ls=${store.lastSyncMS}`;
            dal.callWithToken
                .delete(url, {data: payload, timeout: RwConstants.NetTimeout})
                .then(res => {
                    if (res) {
                        if (res.data) {
                            const json = res.data;
                            let deltas = new RwModel();
                            deltas.fromJson(json);
                            resolve(deltas);
                        } else {
                            RwLog.warn(SOURCE, `DELETE url:${url} -> ${res.status} data \n\npayload:\n${payload}`)
                            reject(new RwError(400, false));
                        }
                    } else {
                        RwLog.warn(SOURCE, `DELETE url:${url} -> Missing \n\npayload:\n${payload}`)
                        reject(new RwError(400, false));
                    }
                })
                .catch((error) => {
                    reject(error);
                });


        });
    }


    static deleteTasks(stopIds: string[]): Promise<RwModel> {

        return new Promise<RwModel>(function (resolve, reject) {
            let payload = JSON.stringify(stopIds);
            let url = `${RwConstants.CoreUri}/stops/tasks?ls=${store.lastSyncMS}`;
            dal.callWithToken
                .delete(url, {data: payload, timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let deltas = new RwModel();
                    deltas.fromJson(json);
                    resolve(deltas);
                })
                .catch((error) => {
                    reject(error);
                });


        });
    }


    static multiColor(ntt: RwEntityTypes, ids: string[], colorHash: string): Promise<boolean> {

        return new Promise<boolean>(function (resolve, reject) {

            let payload = JSON.stringify(ids);
            //console.log("multiColor ntt, color, payload", ntt, colorHash, payload);

            let colorEnc = RwWebUtils.encode(colorHash);
            let url = `${RwConstants.CoreUri}/stops/multiColor?ntt=${ntt}&color=${colorEnc}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .put(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    // const json = res.data;
                    // let deltas = new RwModel();
                    // deltas.fromJson(json);
                    //console.log("RwTaskStops.addTask success", json);
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static multiVisit(ntt: RwEntityTypes, ids: string[], visitTime: number): Promise<boolean> {

        return new Promise<boolean>(function (resolve, reject) {

            let payload = JSON.stringify(ids);
            //console.log("multiVisit ntt, color, payload", ntt, visitTime, payload);

            let url = `${RwConstants.CoreUri}/stops/multiVisit?ntt=${ntt}&visit=${visitTime}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .put(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    // const json = res.data;
                    // let deltas = new RwModel();
                    // deltas.fromJson(json);
                    //console.log("RwTaskStops.addTask success", json);
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static multiSked(ids: string[], skedId: string, priority: number, openTime: number, closeTime: number): Promise<boolean> {

        return new Promise<boolean>(function (resolve, reject) {

            let payload = JSON.stringify(ids);
            //console.log("multiSked pri, open, close", priority, openTime, closeTime);

            let url = `${RwConstants.CoreUri}/stops/multiSked?skedId=${skedId}&pri=${priority}&open=${openTime}&close=${closeTime}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .put(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    //console.log("RwTaskStops.addTask success", json);
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static multiCopy(ids: string[], srcId: string, tarId: string, isMove: boolean): Promise<boolean> {

        return new Promise<boolean>(function (resolve, reject) {

            let payload = JSON.stringify(ids);
            //console.log("multiCopy srcId, tarId, isMove", srcId, tarId, isMove);

            let opName = isMove ? "multiMove" : "multiCopy"
            let url = `${RwConstants.CoreUri}/stops/${opName}?srcId=${srcId}&tarId=${tarId}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .put(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    //console.log("RwTaskStops.addTask success", json);
                    if (res.data && res.data.Error) {
                        const errCode = res.data.Error;
                        if (errCode === 404 || errCode === 500) {
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    } else {
                        resolve(true);
                    }

                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static getRelatedRoutesAsync(stopIds: string[]): Promise<RwStopWithRoute[]> {

        return new Promise<RwStopWithRoute[]>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/reporting/GetRoutesForStops`;
            //RwLog.logConsole("getRelatedRoutesAsync: ", url);

            let payload = JSON.stringify(stopIds);

            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    var stopsWithRts = RwStopWithRoute.fromArray(json);
                    resolve(stopsWithRts);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }


    static getStopCheckDatesAsync(stopIds: string[]): Promise<RwStopCheckDateModel[]> {

        return new Promise<RwStopCheckDateModel[]>(function (resolve, reject) {

            let payload = JSON.stringify(stopIds);
            let url = `${RwConstants.CoreUri}/reporting/CheckDateForStops`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    var stopCheckins = RwStopCheckDateModel.fromArray(json);
                    resolve(stopCheckins);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

}
