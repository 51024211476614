

import theStore from "@/store"
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import theDriverSpace, {RwDriverSpace} from "@/app/views/drivers/RwDriverSpace";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {RwUser} from "@/app/dem/RwUser";
import {RwLog} from "@/app/dal/RwLog";

@Component
export default class RwDriverEdit extends Vue {
  @Prop(RwUser) readonly initDriver: RwUser;

  idxTab = (this.globals.showEditAvatar) ? 1 : 0;
  valid: boolean = false;
  m_name: string;
  m_email: string;
  m_accessType: number = 0;
  m_avType: number = this.selectedDriver.avType;
  m_avColor: string = "";
  m_avInits: string;
  m_avUrl: string;
  m_teamDomainDot: string;

  ///formattedAccessTypes = ["Restricted Driver", "Normal Driver", "Trusted Driver"];
  formattedAccessTypes = ["Restricted", "Normal", "Elevated", "Dispatcher (Restricted)"];
  file: File = null;

  initialName = '';
  initialEmail = '';
  initialAccessType = 0;
  initialAvType = 0;
  initialAvInits = "";
  //REVIEW: Good practice to default to valid value
  initialAvColor = "";
  disableApply = false;


  get emailRules(): any[] {
    if (this.email) {
      return [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please enter a valid email.'
      ]
    }
  }

  get nameRules(): any[] {
    if (this.name) {
      return [
        v => v.length >= 4 || 'Minimum 4 characters.',
        v => this.globals.isValidName(v) || 'Name cannot contain special characters and must be less than 50 chars'
      ]
    }
  }

  get initsRules() {
    if (this.avInits) {
      return [
        v => this.globals.isAlpha(v) || "Initials must be alphanumeric."
      ]
    }
  }

  get disableSave(): boolean {
    return !this.email ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email) ||
        this.name.length >= 4 ||
        this.globals.isAlpha(this.name);
  }

  readonly IMAGE_SIZE_LIMIT = 2097152; //2 MB, 2,097,152 bytes, (2 * 2^20)

  //2MB == 2,097,152 bytes  (2 * 2^20)
  imageRules = [
    value => value.size < this.IMAGE_SIZE_LIMIT || "Avatar size should be less than 2 MB"
  ];


  initDriverVals() {
    let driver = this.initDriver;
    //console.log("RwUser.fromJson", `name:${driver.name}, userName:${driver.userName}, accessType:${driver.accessType}`)

    this.initialName = driver.name;
    this.initialEmail = driver.email;
    this.initialAccessType = driver.accessType;
    this.initialAvType = driver.avType;
    this.initialAvInits = driver.avInits;
    this.initialAvColor = driver.avColor;

    this.m_accessType = driver.accessType;
    this.m_name = driver.name;
    this.m_avColor = driver.avColor;
    this.m_avType = driver.avType;
    this.m_avColor = driver.avColor;
    this.m_avInits = driver.avInits;

    // this.initialUserNameSuffix = this.selectedDriver.userName;
  }


  //#region globals/space setup
  get globals(): RwGlobals {
    return theGlobals;
  }

  get driverSpace(): RwDriverSpace {
    return theDriverSpace;
  }

  get selectedDriver(): RwUser {
    return this.initDriver
  }


  //#endregion globals/space setup


  //#region Avatar getters and setters

  get avType(): number {
    return this.m_avType;
  }

  set avType(val: number) {
    this.m_avType = val;
    // console.warn("avType SET: ", val)
  }

  get avColor(): string {
    let finalColor = (!this.m_avColor) ? this.selectedDriver.avColor : this.m_avColor;
    this.m_avColor = finalColor;
    return this.m_avColor;
  }

  set avColor(val: string) {
    this.m_avColor = this.globals.validateAndSaveColor(val);
  }

  get avInits(): string {
    let avInits = (!this.m_avInits) ? this.selectedDriver.avInits : (this.m_avInits);
    this.m_avInits = avInits;
    return this.m_avInits.substring(0, 3);
  }

  set avInits(val: string) {
    this.disableApply = !this.globals.isAlpha(val.substring(0, 3));
    this.m_avInits = val.substring(0, 3);
  }

  //#endregion Avatar getters and setters

  //#region first tab getters and setters
  get isFirstTab(): boolean {
    return this.idxTab === 0;
  }

  get name(): string {
    let finalName = (!this.m_name) ? this.selectedDriver.name : (this.m_name);
    this.m_name = finalName;
    return this.m_name;
  }

  set name(val: string) {
    this.m_name = val;
  }

  get email(): string {
    if ((!this.m_email) && this.selectedDriver.email) {
      //console.warn("SET @ get name", this.selectedDriver.name);
      this.m_email = this.selectedDriver.email;
    }
    return this.m_email;
  }

  set email(val: string) {
    this.m_email = val;
  }


  get accessTypeSelected(): string {
    let accessText = "Normal";
    let accessType = this.m_accessType;
    switch (accessType) {
        //@formatter:off
      case 0:
        accessText = "Restricted";
        break;
      case 1:
        accessText = "Normal";
        break;
      case 2:
        accessText = "Elevated";
        break;
      case 3:
        accessText = "Dispatcher (Restricted)";
        break;
        //@formatter:on
    }
    //console.log("GET accessTypeSelected", accessText)
    return accessText
  }

  set accessTypeSelected(val: string) {
    let accessType = 1;
    switch (val) {
        //@formatter:off
      case "Restricted":
        accessType = 0;
        break;
      case "Normal":
        accessType = 1;
        break;
      case "Elevated":
        accessType = 2;
        break;
      case "Dispatcher (Restricted)":
        accessType = 3;
        break;
        //@formatter:on
    }
    //console.log("SET accessTypeSelected", accessType)
    this.m_accessType = accessType;
  }


  //#endregion first tab getters and setters

  //#region save actions

  saveColor() {
    this.selectedDriver.avColor = this.globals.validateAndSaveColor(this.m_avColor);
    this.idxTab = 1;
    this.updateDisplay();
  }


  updateDisplay() {
    let self = this;
    let SOURCE = "RwDriverEdit.updateDisplay";
    let finalAvInits = (this.m_avInits) ? this.m_avInits.substring(0, 3) : this.selectedDriver.avInits;
    this.globals.selectedDriver = this.selectedDriver;
    this.selectedDriver.avType = this.m_avType;
    this.selectedDriver.avInits = finalAvInits;
    this.selectedDriver.avColor = this.m_avColor;
    //upload file
    if (this.avType == 1) {
      // console.warn("file size:", this.file[0].size);
      if (!this.file) {
        this.onSaveAvatar();
        //this.globals.showSnack("No file detected - please select a file or switch to initials mode", "error")
      } else {
        if (this.file.size < this.IMAGE_SIZE_LIMIT) {
          this.globals.onFileUpload(this.file, this.selectedDriver.userId).then(() => {
            this.onSaveAvatar();
          });
          // console.warn("POSTing photo, avType:", this.selectedDriver.avType);
        } else {
          this.globals.showSnack("2 MB File Maximum Exceeded - Choose Smaller File", "error")
        }
      }
    } else {
      this.onSaveAvatar();
    }
    // console.warn("POSTing inits, avType:", this.selectedDriver.avType);
    //update color, inits, and type


  }

  onSaveAvatar() {
    const SOURCE = 'RwDriverEdit.onSaveAvatar';
    if (this.selectedDriver) {
      this.selectedDriver.avColor = this.globals.validateAndSaveColor(this.selectedDriver.avColor)
      this.globals.saveAvatar(this.selectedDriver)
          .then(() => {
            this.globals.showSnack("User Avatar Updated", "success");
            this.selectedDriver.lastUpdated = new Date();
          })
          .catch(err => {
            this.rollbackDriver();
            RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
            this.globals.showSnack("Failed to Update User Avatar", "error");
          });
    } else {
      this.globals.showSnack("Sorry, we had an issue updating your avatar - please refresh the page and try again.", "error");
      RwLog.error(SOURCE, "Calling onSaveAvatar with selectedDriver == null");
    }
  }


  onEnter() {
    if (this.idxTab === 0) {
      this.saveDriverChanges();
      //event.stopPropagation();
    } else if (this.idxTab === 1) {
      if (this.avType === 0) {
        this.saveAvatarChanges(true);
      } else {
        if (this.avType === 1) {
          if (this.file) {
            // console.warn("this.file exists:", this.file);
            if (this.file[0].size > 2000000) {
              this.saveAvatarChanges(false);
            } else {
              this.saveAvatarChanges(true);
            }
          } else {
            this.saveAvatarChanges(true);
          }
        }
      }
    } else {
      this.saveColor();
    }
  }

  onEscape(event: Event) {
    //console.log("RwDriverEdit.onEscape")
    if (this.idxTab == 0) {
      event.stopPropagation();
      this.close();
    } else {
      if (this.idxTab == 1) {
        this.globals.showEditAvatar = false;
        this.idxTab = 0;

      }
    }
  }

  close() {
    //console.log("RwDriverEdit.close")
    theStore.dispatch("showDriverEdit", false).catch();
  }


  saveDriverChanges() {
    const self = this;
    const SOURCE = "RwDriverEdit.saveDriverChanges";
    let isValidForm = this.isValidForm();
    if (isValidForm) {

      //console.warn("on save, Driver Access Type enum is: ", this.m_accessType);
      this.selectedDriver.name = this.m_name;
      this.selectedDriver.email = this.m_email;
      this.selectedDriver.accessType = this.m_accessType;

      //update name, email, accessType
      this.globals
          .saveDriver(this.selectedDriver)
          .then(() => {
            self.globals.showSnack("User Updated", "success");
            self.selectedDriver.lastUpdated = new Date();
            self.close();
          })
          .catch(err => {
            self.rollbackDriver();
            RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
            self.globals.showSnack("Failed to Update Driver", "error");
          });
    }
  }

  isValidForm() {
    let vueForm = this.$refs.form as any;
    let isValid = vueForm.validate();
    return isValid;
  }


  saveAvatarChanges(save: boolean) {
    const SOURCE = "RwDriverEdit.saveAvatarChanges";
    if (save) {
      let finalAvInits = (this.avInits) ? this.avInits.substring(0, 3) : this.selectedDriver.avInits;
      if (this.selectedDriver) {
        this.globals.selectedDriver = this.selectedDriver;

        this.selectedDriver.avType = this.m_avType;
        this.selectedDriver.avInits = finalAvInits;
        this.selectedDriver.avColor = this.m_avColor;

        if (this.selectedDriver.avColor) {
          this.globals.saveAvatar(this.selectedDriver)
              .then(() => {
                this.globals.showSnack("User Avatar Updated", "success");
                this.selectedDriver.lastUpdated = new Date();
                this.close();
              })
              .catch(err => {
                this.rollbackDriver();
                RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
                this.globals.showSnack("Failed to Update User Avatar", "error");
              });
          this.idxTab = 1;
        } else {
          this.globals.showSnack("Please select a color and try again", 'error');
          RwLog.warn(SOURCE, 'Save avatar called with no color selected: ' + this.selectedDriver.avColor);
        }
      } else {
        RwLog.warn(SOURCE, 'Save avatar called with no driver selected: ' + this.selectedDriver);
        this.globals.showSnack("Please refresh your page and try again. If problems persist, contact support@roadwarrior.app", 'error');
      }
    }


    if (!save) {
      this.idxTab = 3;
    }
  }


  //#endregion save actions


  rollbackDriver() {
    // this.selectedDriver.userName = this.initialUserNameSuffix;

    this.selectedDriver.avType = this.initialAvType;
    this.selectedDriver.avInits = this.initialAvInits;
    this.selectedDriver.avColor = this.initialAvColor;

    this.selectedDriver.name = this.initialName;
    this.selectedDriver.email = this.initialEmail;
    this.selectedDriver.accessType = this.initialAccessType;
  }

  //
  // onFileUpload() {
  //   let self = this;
  //   const SOURCE = "RwAvatarEdit.onFileUpload";
  //   if (this.file && this.file.length > 0) {
  //     //submit file
  //     let newFile = this.file[0];
  //     let formData = new FormData();
  //     let uid = this.selectedDriver.userId;
  //     formData.append('newFile', newFile);
  //     let url = `${RwConstants.CoreUri}/users/uimage?uid=${uid}`;
  //     dal.callWithToken
  //       .post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
  //       .then(res => {
  //         if (res && res.data) {
  //           let avInfo = RwAvatarInfo.fromJson(res.data);
  //           if (avInfo && avInfo.avUrl) {
  //             //Update current user if match
  //             //Update drivers pin
  //             let driver = theGlobals.drivers.find(d => d.userId == uid);
  //             if (driver) {
  //               driver.imageUrl = avInfo.avUrl;
  //             }
  //             else {
  //               //Log this!
  //             }
  //           }
  //         }
  //         else {
  //           //Log this!
  //         }
  //       })
  //       .catch(function (err) {
  //         //Log this!
  //         self.rollbackDriver();
  //         RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
  //         self.globals.showSnack("Failed to Update User Avatar", "error");
  //       });
  //   }
  // }


  mounted() {
    if (this.initDriver) {
      this.initDriverVals();
    } else {
      RwLog.consoleError("mounted", "!initDriver")
    }
  }


//region Unused


  // get accessType(): number {
  //   return this.m_accessType;
  // }
  //
  // set accessType(val: number) {
  //   this.m_accessType = val;
  // }

  // get accessTypeSelected(): string {
  //   let accessType = this.selectedDriver.accessType;
  //   if (accessType) {
  //     this.m_accessType = accessType;
  //   }
  //   else {
  //     this.m_accessType = 0;
  //   }
  //   if (this.m_accessType == 0) {
  //     return "Restricted Driver"
  //   }
  //   else {
  //     return (this.m_accessType === 2) ? "Elevated Driver" : "Normal Driver";
  //   }
  // }

  // set accessTypeSelected(val: string) {
  //   let accessType: number;
  //   if (val == 'Restricted Driver') {
  //     accessType = 0;
  //   }
  //   else {
  //     accessType = (val == "Trusted Driver") ? 2 : 1;
  //   }
  //   this.m_accessType = accessType;
  // }


// get userNameSuffix(): string {
//   let rawName = ""
//   if (this.initDriver && this.initDriver.userName && this.initDriver.userName.length > 0) {
//     //console.warn("get userNameSuffix userName", this.selectedDriver.userName);
//     rawName = this.selectedDriver.userName.substring(this.selectedDriver.userName.lastIndexOf(".") + 1);
//   }
//   return rawName;
// }
//
//
// get teamDomainDot(): string {
//   if (!this.m_teamDomainDot) {
//     if (this.globals.isSubTeam) {
//       this.m_teamDomainDot = this.globals.teamDomain + "." + this.globals.subDomain + ".";
//       //console.warn("this.globals.isSubTeam==TRUE: ", this.m_teamDomainDot);
//     }
//     else {
//       this.m_teamDomainDot = this.globals.teamDomain + ".";
//       //console.warn("this.globals.isSubTeam==FALSE: ", this.m_teamDomainDot);
//     }
//   }
//   //console.warn("this.teamDomainDot gotten:", this.m_teamDomainDot);
//   return this.m_teamDomainDot;
// }


//endregion

  @Watch("avType")
  watchAvType(val) {
    //console.log(this.m_avInits, !this.globals.isAlpha(this.m_avInits));
    this.disableApply = val === 1 ? false : !this.globals.isAlpha(this.m_avInits);
  }
}

