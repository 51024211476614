import theStore from "@/store";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import {RwSite} from "@/app/dem/RwSite";
import {RwRoutePick} from "@/app/dem/RwRoute";


export class RwSiteSpace {

    get globals(): RwGlobals {
        return theGlobals;
    }

    selectedSites: RwSite[] = [];

    get showSiteMultiColor(): boolean {
        return theStore.getters.showSiteMultiColor;
    }

    set showSiteMultiColor(val: boolean) {
        theStore.dispatch("showSiteMultiColor", val);
    }

    get selectedSite(): RwSite {
        return theStore.getters.selectedSite;
    }

    set selectedSite(val: RwSite) {
        theStore.dispatch("selectedSite", val);
    }

    get showSiteRoutePicker(): boolean {
        return theStore.getters.showSiteRoutePicker;
    }

    set showSiteRoutePicker(val: boolean) {
        theStore.dispatch("showSiteRoutePicker", val);
    }

    pendingRoutePick;

    showRoutePicker(srcId: string): Promise<RwRoutePick> {
        this.showSiteRoutePicker = true;
        //console.log("showRoutePicker srcId m_routePickerSrcId", srcId, self.m_routePickerSrcId);
        return new Promise<RwRoutePick>((resolve) => {
            this.pendingRoutePick = resolve;
        });
    }

    // get showSiteEdit(): boolean { return theStore.getters.showSiteEdit; }
    // set showSiteEdit(val: boolean) { theStore.dispatch("showSiteEdit", val); }
    //
    // get showSiteDelete(): boolean { return  theStore.getters.showSiteDelete; }
    // set showSiteDelete(val: boolean){ theStore.dispatch("showSiteDelete", val); }
    //
    // get showSiteRelocate():boolean {return theStore.getters.showSiteRelocate; }
    // set showSiteRelocate(val: boolean){ theStore.dispatch("showSiteRelocate", val); }
}

export default new RwSiteSpace();
