import {StopStatus} from "@/app/RwEnums";

const statusNames =
    ["Not Completed", "Nobody There", "Not Open", "Incorrect Address", "Address Unreachable", "Customer Refused",
        "Come Back Another Day", "Do Not Re-Attempt", "Not Completed - Other", "Completed", "Left on Porch/Front Door", "Left at Gate/Lobby",
        "Left in Mail Area", "Handed to Customer", "Signature Collected", "Service Rendered", "Package Picked Up",
        "Communicated with Contact", "Sent Text/Email", "Left Voicemail", "Spoke on Phone", "Spoke Directly w/ Customer",
        "Left a Note", "Other", "Address Updated at Customer Req.", "New Delivery", "New Customer"];

function returnStatusItem(status: StopStatus) {
    return {
        id: status,
        name: statusNames[status]
    }
}

function generateStatusItems(from: number, to: number) {
    return Array.from(new Array(to - from), (x, i) => i + from).map(i => returnStatusItem(i));
}

const tree = [
    {
        id: StopStatus.NotCompleted,
        name: statusNames[StopStatus.NotCompleted],
        children: [
            generateStatusItems(StopStatus.NobodyThere, StopStatus.NotCompletedOther)
        ],
    },
    {
        id: StopStatus.Completed,
        name: statusNames[StopStatus.NotCompleted],
        children: [
            generateStatusItems(StopStatus.LeftFrontDoorPorch, StopStatus.LeftNote)
        ],
    },
    {
        id: StopStatus.Other,
        name: statusNames[StopStatus.NotCompleted],
        children: [
            generateStatusItems(StopStatus.AddressUpdated, StopStatus.NewCustomer)
        ],
    },
]

export default tree;

export {statusNames};