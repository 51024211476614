import moment from "moment";


export class RwTimeEntry {
    text: string;
    value: number;
}


export class RwDateUtils {


    static get is12H(): boolean {
        let is12H = moment().format("LT").toUpperCase().indexOf("M") > 0;
        return is12H;
    }


    static get is24H(): boolean {
        return !RwDateUtils.is12H;
    }

    static addHoursToDate(date: Date, hours: number): Date {
        let newDate = new Date();
        newDate.setTime(date.getTime() + hours * 60 * 60 * 1000);
        return newDate;
    }

    static addMinutesToDate(date: Date, minutes: number): Date {
        let newDate = new Date();
        newDate.setTime(date.getTime() + minutes * 60 * 1000);
        return newDate;
    }


    static formatDate(date: Date): string {
        let nowMoment = moment();
        let newMoment = moment(date);
        let isToday = nowMoment.isSame(newMoment, "day");
        let dateText = isToday ? newMoment.format("LT") : newMoment.format("L LT");
        return dateText;
    }

    static formatMonthDate(date: Date, addYear: boolean = false): string {
        let newMoment = moment(date);
        let dateText = addYear ? newMoment.format("MMMM DD, YYYY") : newMoment.format("MMMM DD");
        return dateText;
    }

    static formatDateLog(date: Date): string {
        let nowMoment = moment();
        let newMoment = moment(date);
        let isToday = nowMoment.isSame(newMoment, "day");
        let dateText = isToday ? newMoment.format("HH:mm:ss") : newMoment.format("MM.DD HH:mm:ss");
        return dateText;
    }


    static formatTimeSpan(dateStart: Date, dateFinish: Date): string {
        let now = moment();

        let start = moment(dateStart);
        let isStartToday = now.isSame(start, "day");
        let textStart = isStartToday ? start.format("LT") : start.format("L LT");

        let finish = moment(dateFinish);
        let isSameStartEnd = finish.isSame(start, "day");
        let textFinish = isSameStartEnd ? finish.format("LT") : finish.format("L LT");

        return textStart + " to " + textFinish;
    }


    static msToString(millisFromMidnight: number): string {
        // required for all combo boxes that use time as well as in sites where the associated schedules time is displayed
        let time = moment().startOf('day').add(millisFromMidnight, 'ms');
        return time.format('LT');
    }


    static validateTimes(start: number, end: number): number[] {
        end = (86400000 <= end) ? end - 86400000 : end;
        let verifiedStart = (!start) ? 0 : start;
        let verifiedEnd = (!end) ? 0 : end;
        if (verifiedStart == verifiedEnd) {
            verifiedStart = 9 * 3600000;
            verifiedEnd = 17 * 3600000;
        }
        return [verifiedStart, verifiedEnd];
    }


    static genTimeList(hoursCount: number, startHour: number, minInterval: number, endTimes?: boolean): string[] {
        //endTimes optional boolean displays the time with the duration next to it away from the start time for the end time combo box

        let times: string[] = [];
        let start = moment().startOf('day');
        start.add(startHour, "hours");
        let countOfTimeList = hoursCount * 60 / minInterval;
        for (let i = 0; i < countOfTimeList; i++) {
            let time = start.clone().add((minInterval * i), "minutes");
            let isToday = start.isSame(time, "day");
            let timeText = isToday ? time.format('LT') : time.format('LT') + " tmrw";
            timeText += (endTimes) ? " | " + (i * 0.5).toString() + " h" : "";
            times.push(timeText);
        }
        return times;
    }


}


export default new RwDateUtils();






