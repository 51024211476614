import {ConfirmationType, MediaType} from "@/app/dem/ProofOfDelivery/ProofOfDeliveryEnums";
import theGlobals from "@/app/RwGlobals";
import { RwConstants } from "@/app/RwConstants";

export class RwCoreMedia {
    mediaId: string;
    aid: string;
    mediaType: MediaType;
    del: boolean;
    oid: string;
    confType: ConfirmationType;
    stopId: string;
    histId: string;
    uid: string;
    rid: string;

    static getLink(media: RwCoreMedia): string {
        return `${RwConstants.tusFileURL}${media.mediaId}?s=${theGlobals.token}`;
    }
}
