import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {HistSortOrder, RouteSortOrder, SiteSortOrder, SortDirection} from "@/app/RwEnums";

const reportsModule = {
    state: {
        routeReportSortDir: RwPrefUtils.routeReportSortDir,
        routeReportSortMode: RwPrefUtils.routesReportSortBy,
        favsReportSortDir: RwPrefUtils.favoritesReportSortDir,
        favsReportSortMode: RwPrefUtils.favoritesReportSortBy,
        histReportSortDir: RwPrefUtils.historyReportSortDir,
        histReportSortMode: RwPrefUtils.historyReportSortBy
    },
    getters: {
        routeReportSortDir(state): SortDirection {
            return state.routeReportSortDir;
        },
        routeReportSortMode(state): RouteSortOrder {
            return state.routeReportSortMode;
        },
        favsReportSortDir(state): SortDirection {
            return state.favsReportSortDir;
        },
        favsReportSortMode(state): SiteSortOrder {
            return state.favsReportSortMode;
        },
        histReportSortDir(state): SortDirection {
            return state.histReportSortDir;
        },
        histReportSortMode(state): HistSortOrder {
            return state.histReportSortMode;
        },
    },
    actions: {
        routeReportSortDir({state, commit}, val: SortDirection) {
            commit("ROUTE_REPORT_SORT_DIR", val);
        },
        routeReportSortMode({state, commit}, val: RouteSortOrder) {
            commit("ROUTE_REPORT_SORT_MODE", val);
        },
        favsReportSortDir({state, commit}, val: SortDirection) {
            commit("FAVS_REPORT_SORT_DIR", val);
        },
        favsReportSortMode({state, commit}, val: SiteSortOrder) {
            commit("FAVS_REPORT_SORT_MODE", val);
        },
        histReportSortDir({state, commit}, val: SortDirection) {
            commit("HIST_REPORT_SORT_DIR", val);
        },
        histReportSortMode({state, commit}, val: HistSortOrder) {
            commit("HIST_REPORT_SORT_MODE", val);
        },
    },
    mutations: {
        ROUTE_REPORT_SORT_DIR(state, val: SortDirection) {
            state.routeReportSortDir = val;
            RwPrefUtils.routeReportSortDir = val;
        },
        ROUTE_REPORT_SORT_MODE(state, val: RouteSortOrder) {
            state.routeReportSortMode = val;
            RwPrefUtils.routesReportSortBy = val;
        },
        FAVS_REPORT_SORT_DIR(state, val: SortDirection) {
            state.favsReportSortDir = val;
            RwPrefUtils.favoritesReportSortDir = val;
        },
        FAVS_REPORT_SORT_MODE(state, val: SiteSortOrder) {
            state.favsReportSortMode = val;
            RwPrefUtils.favoritesReportSortBy = val;
        },
        HIST_REPORT_SORT_DIR(state, val: SortDirection) {
            state.histReportSortDir = val;
            RwPrefUtils.historyReportSortDir = val;
        },
        HIST_REPORT_SORT_MODE(state, val: HistSortOrder) {
            state.histReportSortMode = val;
            RwPrefUtils.historyReportSortBy = val;
        },
    }
};

export default reportsModule;