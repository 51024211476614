import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import {RwRoute} from "@/app/dem/RwRoute";
import theStore from "@/store"


export class RwRouteSpace {
    //isNewRoute: boolean = false;

    get globals(): RwGlobals {
        return theGlobals;
    }

    isMultiMode = false;

    get selectedRouteSpace(): RwRoute {
        return theStore.getters.selectedRouteSpace;
    }

    set selectedRouteSpace(driver: RwRoute) {
        theStore.dispatch("selectedRouteSpace", driver);
    }

    selectedRoutes: RwRoute[] = [];


}

export default new RwRouteSpace();

