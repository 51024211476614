export enum FulfillmentStatus {
    None = 0,
    FillSuccess = 1,
    FillFailure = 2,
}

export enum ConfirmationType {
    Photo = 1,
    Signature = 2,
    Barcode = 3
}

export enum MediaType {
    Image = 0
}

export enum PODDefaultSettings {
    None = 0,
    Photo = 1,
    Signature = 2,
    PhotoAndSignature = 3
}
