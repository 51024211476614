import router from "@/router";
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {RwLog} from "@/app/dal/RwLog";
import {RwConstants} from "@/app/RwConstants";
import theGlobals from "@/app/RwGlobals";
import {RwTaskAccounts} from "@/app/dal/RwTaskAccounts";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwError, RwLoginError} from "@/app/RwErrors";
import {RwLoginIssues} from "../RwEnums";
import RwPages from "@/app/consts/RwPages";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import moment from "moment";


class RwDal {

    callWithToken: AxiosInstance;
    callSansToken: AxiosInstance;

    private requestQueue = [];
    private refreshCount = 0;
    private isRefreshing = false;
    static isRefreshing = false;

    constructor() {
        this.configCallWithToken();
        this.configCallSansToken();
    }


    config: AxiosRequestConfig = {
        timeout: RwConstants.NetTimeout,
        baseURL: RwConstants.CoreUri,
        headers: {
            Accept: "application/json",
            AuthToken: RwPrefUtils.token,
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            "RwVer": RwConstants.flexVersion
        }
    };


    configCallWithToken() {
        const self = this;
        this.callWithToken = axios.create(this.config);

        this.callWithToken.interceptors.request.use(function (config: AxiosRequestConfig) {
            const SOURCE = "callWithToken.request";

            let tkn = RwPrefUtils.token;
            if (RwSysUtils.isGuidReal(tkn)) {
                self.addBusy();
                config.headers["AuthToken"] = tkn;

                self.getAuthToken()
                    .then(token => {

                        if (!RwSysUtils.isGuidReal(token)) {
                            self.removeBusy();
                            RwLog.error(SOURCE, `getAuthToken -> token == null, prefs.token == null`);
                            throw new axios.Cancel("Canceled Request");
                        }
                        config.headers["AuthToken"] = token;

                    })
                    .catch(err => {
                        self.removeBusy();
                        throw new axios.Cancel("Canceled Request");
                    })
            } else {
                self.removeBusy();
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                self.forceLogout(logErr);

                throw new axios.Cancel("Canceled Request");
            }

            return config;
        });

        this.callWithToken.interceptors.response.use(
            function (resp: AxiosResponse) {
                const SOURCE = "callWithToken.onFulfilled";
                if (resp) {
                    if (resp.config) {
                        //console.log("RwDal resp", `${resp.config.url} -> ${resp.status}`, resp.data);
                        //RwLog.consoleLog("RwDal resp", `${resp.config.url} -> ${resp.status}`, JSON.stringify(resp.data ));
                    } else {
                        RwLog.error(SOURCE, `NO CONFIG \n\nResponse:\n${resp}`);
                    }
                } else {
                    RwLog.error(SOURCE, "NO Response");
                }

                self.removeBusy();
                return resp;
            },

            function (error: AxiosError) {
                const SOURCE = "callWithToken.onRejected";

                let isNetError = self.isNetworkError(error);
                if (!isNetError) {

                    const url = error.config?.url ?? "";
                    const method = error.config?.method?.toUpperCase() ?? "";
                    let token = error.config?.headers["AuthToken"] ?? RwConstants.EmptyGuid;
                    let payload = error.config?.data ?? "";
                    let tknText = (token == RwPrefUtils.token) ? `\nAuthToken:${token}` : `\nAuthToken:${token} != RwPrefs.Token:${RwPrefUtils.token}`
                    let payText = method == "GET" ? "" : `\nPayload:\n${payload}`;
                    let status = error.response ? error.response.status : 0;
                    let reqText = `${method} ${url} -> ${status} ${tknText} ${payText}`;

                    if (error && error.config) {

                        let errText = JSON.stringify(error);
                        if (error.response) {
                            // client received an error response (5xx, 4xx)

                            //HACK: sometimes token == null;
                            if (!token && RwPrefUtils.token) {
                                RwLog.error(SOURCE, `HACK: token == null, prefs:${RwPrefUtils.token}`);
                                error.config.headers["AuthToken"] = RwPrefUtils.token;
                                self.config.headers["AuthToken"] = RwPrefUtils.token;
                            }

                            switch (status) {

                                case 401: {

                                    const originalRequest = error.config;
                                    self.getRefreshedToken()
                                        .then(function (newToken) {
                                            //console.log(SOURCE,`getRefreshedToken -> pref:${RwPrefUtils.token}, ${newToken}`)

                                            //HACK:
                                            if (!newToken && RwPrefUtils.token) {
                                                RwLog.error(SOURCE, `HACK getRefreshedToken, newToken == null, set to prefs:${RwPrefUtils.token}`);
                                                newToken = RwPrefUtils.token;
                                            }
                                            originalRequest.headers["AuthToken"] = newToken;
                                            axios(originalRequest)
                                                .then(resp => {
                                                    self.removeBusy();
                                                })
                                                .catch(error => {
                                                    self.removeBusy();
                                                    RwLog.warn(SOURCE, `RETRY ${originalRequest.url} \nAuthToken:${newToken} \n\n${error}`)
                                                    return Promise.reject(error);
                                                });
                                        })
                                        .catch(errRefresh => {
                                            self.removeBusy();
                                            //console.error(SOURCE, `getRefreshedToken -> ${errRefresh}`)
                                            let logErr = new RwLoginError(status, true, errRefresh, RwLoginIssues.RefreshFailed);
                                            self.forceLogout(logErr);
                                            return Promise.reject(logErr);
                                        });

                                }
                                case 403: {
                                    self.removeBusy();
                                    RwLog.warn(SOURCE, `${reqText} \n\n${errText}`);
                                    let logErr = new RwLoginError(status, true, error, RwLoginIssues.LoginDisabled);
                                    self.forceLogout(logErr);
                                    return Promise.reject(logErr);
                                }
                                case 404: {
                                    self.removeBusy();
                                    RwLog.warn(SOURCE, `\n${reqText} \n\n${errText}`);
                                    return Promise.reject(new RwError(status, false, error));
                                }
                                case 406: {
                                    self.removeBusy();
                                    RwLog.warn(SOURCE, `\n${reqText} \n\n${errText}`);
                                    let logErr = new RwLoginError(status, true, error, RwLoginIssues.SeshAlt);
                                    self.forceLogout(logErr);
                                    return Promise.reject(logErr);
                                }
                                case 409: {
                                    self.removeBusy();
                                    RwLog.warn(SOURCE, `\n${reqText} \n\n${errText}`);
                                    return Promise.reject(new RwError(status, false, error));
                                }
                                case 412:
                                case 499: {
                                    // Note 499: This is the response if FedEx/OnTrac needs ReAuth
                                    self.removeBusy();
                                    RwLog.warn(SOURCE, `${reqText}, needs external auth \n\n${errText}`);
                                    return Promise.reject({code: status, data: error.response.data});
                                }
                                case 420: {
                                    //Note 409, 420: This is the response from Too Many Stations relative to number of drivers
                                    self.removeBusy();
                                    RwLog.warn(SOURCE, `${reqText}, Too Many Stations / Drivers \n\n${errText}`);
                                    return Promise.reject(new RwError(status, false, error));
                                }
                                // Network/service errors
                                case 503: {
                                    self.removeBusy();
                                    RwLog.warn(SOURCE, `503 network err${reqText} \n\n${errText}`);
                                    return Promise.reject(new RwError(status, true, error));
                                }
                                case 504: {
                                    self.removeBusy();
                                    RwLog.warn(SOURCE, `504 network err ${reqText} \n\n${errText}`);
                                    return Promise.reject(new RwError(status, true, error));
                                }

                                default: {
                                    self.removeBusy();
                                    RwLog.error(SOURCE, `${reqText} \n\n${errText}`);
                                    return Promise.reject(new RwError(status, false, error));
                                }
                            }
                        } else {
                            self.removeBusy();
                            if (error.request) {
                                let reqText = JSON.stringify(error.request);
                                RwLog.error(SOURCE, `NO RESPONSE: ${error.message} \n${reqText}`);
                            } else {
                                // anything else
                                RwLog.error(SOURCE, `NO RESPONSE; NO REQUEST: ${error.message} \n${reqText}`);
                            }
                            return Promise.reject(new RwError(status, false, error));
                        }
                    } else if (error && error.message == "Forced log out") {
                        self.removeBusy();
                        return Promise.reject(new RwError(403, true, error));
                    } else {
                        self.removeBusy();
                        //let errJson = JSON.stringify(error);
                        let isCanceled = error && error.message && error.message === "Canceled Request";
                        if (isCanceled) {
                            RwLog.warn(SOURCE, `CANCELED \n${reqText}`);
                            let rwErr = new RwError(403, true, "Canceled request: Empty token");
                            self.forceLogout(rwErr);
                            return Promise.reject(rwErr);
                        } else {
                            RwLog.error(SOURCE, `$Missing Error && Error.Config \n${reqText}`);
                            return Promise.reject(new RwError(500, false, error));
                        }
                    }
                } else {
                    self.removeBusy();
                    if (!self.isNetworkDown(error)) {
                        RwLog.warn(SOURCE, `isNetError: ${error.message} \n\n${JSON.stringify(error)}`);
                    }
                    return Promise.reject(new RwError(504, true, error));
                }
            }
        );
    }


    configCallSansToken() {
        const self = this;

        this.callSansToken = axios.create(this.config);

        this.callSansToken.interceptors.request.use(function (config: AxiosRequestConfig) {
            return config;
        });

        this.callSansToken.interceptors.response.use(
            function (resp: AxiosResponse) {
                const SOURCE = "callSansToken.onFulfilled";

                if (resp) {
                    if (!resp.config) {
                        RwLog.error(SOURCE, `NO CONFIG \n\nResponse:\n${resp}`);
                    }
                } else {
                    RwLog.error(SOURCE, "NO Response");
                }

                return resp;
            },

            function (error: AxiosError) {
                const SOURCE = "callSansToken.onRejected";

                let isNetError = self.isNetworkError(error);
                if (!isNetError) {

                    if (error) {

                        if (error.config) {

                            const url = error.config.url;
                            const method = error.config.method.toUpperCase();
                            const payload = error.config.data;
                            let payloadText = (method == "GET") ? "" : `\nPayload:${payload}`;
                            let status = error.response ? error.response.status : "?";
                            let reqText = `${method} ${url} -> ${status} ${payloadText}`;
                            let errText = JSON.stringify(error);

                            if (error.response) {
                                switch (status) {
                                    case 400:
                                        let urlLow = url.toLowerCase();
                                        let isImgError = urlLow.indexOf("/img/pin") > -1;
                                        if (isImgError) {
                                            //HACK: Set img errors to warn level; they are overly cluttering logs.
                                            RwLog.warn(SOURCE, `${reqText} \n\n${errText}`);
                                        } else {
                                            RwLog.error(SOURCE, `${reqText} \n\n${errText}`);
                                        }
                                        break;

                                    case 401:
                                    case 403:
                                    case 404:
                                        RwLog.warn(SOURCE, `${reqText} \n\n${errText}`);
                                        break;

                                    default:
                                        RwLog.error(SOURCE, `${reqText} \n\n${errText}`);
                                        break;
                                }
                            } else {
                                RwLog.error(SOURCE, `NO RESPONSE: ${error.message} \n${reqText} \n\n${errText}`);
                            }
                        } else {
                            RwLog.error(SOURCE, `NO CONFIG: ${error}`);
                        }
                    } else {
                        RwLog.error(SOURCE, `NO ERROR`);
                    }

                    return Promise.reject(error);
                } else {
                    if (!self.isNetworkDown(error)) {
                        RwLog.warn(SOURCE, `isNetError: ${error.message} \n\n${error.toJSON}`);
                    }
                    return Promise.reject(new RwError(0, true, error));
                }

            }
        );

    }


    isNetworkDown(error: AxiosError<any>) {
        let isNetDown = false;
        if (error && error.message && error.stack) {
            let msgLow = error.message.toLowerCase();
            let stackLow = error.stack.toLowerCase();

            isNetDown =
                msgLow.indexOf("network error") > -1 ||
                stackLow.indexOf("network error") > -1;
        }
        return isNetDown;
    }


    isNetworkError(error: AxiosError<any>) {
        let isNetError = false;
        if (error && error.message && error.stack) {
            let msgLow = error.message.toLowerCase();
            let stackLow = error.stack.toLowerCase();

            isNetError =
                msgLow.indexOf("network error") > -1 ||
                msgLow.indexOf("request aborted") > -1 ||
                msgLow.indexOf("timeout") > -1 ||
                stackLow.indexOf("network error") > -1 ||
                stackLow.indexOf("request aborted") > -1 ||
                stackLow.indexOf("timeout") > -1;
        }
        return isNetError;
    }


    forceLogout(error?: RwError) {
        this.isRefreshing = false;
        this.refreshCount = 0;
        this.requestQueue = [];

        RwLog.warn("RwDal.logout", `Forced Logout: ${error}`);
        //RwLog.warn("RwDal.logout", `Forced Logout: \n${JSON.stringify(error)}`);
        theGlobals.logOut();


        if (error instanceof RwLoginError) {
            let logErr = error as RwLoginError;
            window.location.replace(`/login/${logErr.failCode}`);
        } else {
            window.location.replace(RwPages.Login);
        }

    }


    addSubscriber(callback) {
        this.requestQueue.push(callback);
    }


    addBusy() {
        if (this.isRefreshing == false) {
            this.refreshCount++;
            if (this.refreshCount >= 1) {
                theGlobals.isLoading = true;
            }
        }
    }


    removeBusy() {
        if (this.isRefreshing == false) {
            this.refreshCount--;
            if (this.refreshCount <= 0) {
                theGlobals.isLoading = false;
                this.refreshCount = 0;
            }
        }
    }


    public gotToken(): boolean {
        let token = RwPrefUtils.token;
        let gotToken = RwSysUtils.isGuidReal(token);
        return gotToken;
    }


    public getAuthToken(): Promise<string> {
        let self = this;
        const SOURCE = "RwDal.getAuthToken";

        return new Promise<string>(function (resolve, reject) {

            let isValidToken = false;
            let token = RwPrefUtils.token;
            if (self.gotToken()) {
                let now = moment();
                let lastRefresh = theGlobals.lastToken;
                let duration = moment.duration(now.diff(lastRefresh));
                if (duration.asDays() < 5.0) {
                    isValidToken = true;
                }
                //console.log(SOURCE, `isTokenSet:${true}, token:${token}, lastRefresh:${lastRefresh}, isValidToken:${isValidToken}`);
            }

            if (isValidToken) {
                //console.log(SOURCE, "GOT cached token", token);
                resolve(token)
            } else {
                //console.warn(SOURCE, "refreshing cached token", token);

                self.getRefreshedToken()
                    .then(function (newToken: string) {
                        resolve(newToken);
                    })
                    .catch(ex => {
                        let err = new RwLoginError(403, true, ex, RwLoginIssues.SeshExpired);
                        self.forceLogout(err);
                        reject(err)
                    });
            }

        });
    }


    refreshPromise: Promise<string>;

    public async getRefreshedToken(): Promise<string> {
        return await this.getRefreshSingleton();
    }

    private async getRefreshSingleton(): Promise<string> {
        const self = this;
        if (!self.refreshPromise) {
            //console.log("RwDal.getRefreshSingleton", "NEW PROMISE SET")
            self.refreshPromise = self.refreshToken();
            //self.refreshPromise = RwTaskAccounts.RenewSesh();
        }
        return self.refreshPromise;
    }


    private refreshToken(): Promise<string> {
        const self = this;
        return new Promise<string>(function (resolve, reject) {
            RwTaskAccounts.RenewSesh()
                .then(function (newToken: string) {
                    //console.log("RwDal.refreshToken", `NEW TOKEN: pref:${RwPrefUtils.token}, newToken:${newToken}`)
                    resolve(newToken);
                    self.refreshPromise = null;
                })
                .catch(err => {
                    reject(err);
                    self.refreshPromise = null;
                });
        });
    }

}


export default new RwDal();
