import theStore from "@/store"
import {RwTaskBillingProfile} from "@/app/dal/RwTaskBillingProfile";
import {RwLog} from "@/app/dal/RwLog";
import {RWCPPResult} from "@/app/views/account/purch/RWCPPResult";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";

export class RwPurchSpace {

    get pricePerBaseCode() {
        return RwPrefUtils.ppb;
    }

    set pricePerBaseCode(value: string) {
        RwPrefUtils.ppb = value;
    }

    get pricePerDriverCode() {
        return RwPrefUtils.ppd;
    }

    set pricePerDriverCode(value: string) {
        RwPrefUtils.ppd = value;
    }

    get pricePerTeamCode() {
        return RwPrefUtils.ppt;
    };

    set pricePerTeamCode(value: string) {
        RwPrefUtils.ppt = value;
    }

    get pricePerBaseAmt() {
        return theStore.getters.pricePerBaseAmt;
    }

    set pricePerBaseAmt(val: number) {
        theStore.dispatch("pricePerBaseAmt", val).catch();
    }

    get pricePerDriverAmt() {
        return theStore.getters.pricePerDriverAmt;
    }

    set pricePerDriverAmt(val: number) {
        theStore.dispatch("pricePerDriverAmt", val).catch();
    }

    get pricePerTeamAmt() {
        return theStore.getters.pricePerTeamAmt;
    }

    set pricePerTeamAmt(val: number) {
        theStore.dispatch("pricePerTeamAmt", val).catch();
    }

    RefreshPricePoints() {
        const SOURCE = "RefreshPricePoints";
        return RwTaskBillingProfile.ResolveCPP(this.pricePerBaseCode, this.pricePerDriverCode, this.pricePerTeamCode)
            .then(result => {
                this.pricePerBaseAmt = result.ppb;
                this.pricePerDriverAmt = result.ppd;
                this.pricePerTeamAmt = result.ppt;

                const cppData = JSON.stringify({ppb: result.ppb, ppd: result.ppd, ppt: result.ppt});
                const expireDate = new Date()
                expireDate.setTime(expireDate.getTime() + (10 * 365 * 24 * 60 * 60 * 1000));  // expire in 10 years
                document.cookie = `rw_cpp=${cppData};path=/;expires=${expireDate.toUTCString()};domain=roadwarrior.app`;

                return result;
            })
            .catch((err) => {
                RwLog.error(SOURCE, "Error resolving CPP:" + JSON.stringify(err));
                return <RWCPPResult>{ppb: this.pricePerBaseAmt, ppd: this.pricePerDriverAmt, ppt: this.pricePerTeamAmt};
            })

    }
}

export default new RwPurchSpace();