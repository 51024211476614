import moment from "moment";
import {RwStop} from "@/app/dem/RwStop";
import {RwUser} from "@/app/dem/RwUser";
import {RwSite} from "@/app/dem/RwSite";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwDateUtils} from "@/app/utils/RwDateUtils";
import {RwLog} from "@/app/dal/RwLog";
import {RwDistUnits, RwRouteCopyType, RwTravelModes} from "@/app/RwEnums";
import {RwConstants} from "@/app/RwConstants";
import theGlobals, {RwGlobals} from "@/app/RwGlobals"
import thePlan, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace"
import {RwTextUtils} from '../utils/RwTextUtils';
import settingSpace from "@/app/views/account/RwSettingSpace";


export enum RwRouteAssignCodes {
    rejected = -1,
    assigned = 0,
    pending = 1,
}


export enum RwRouteStatusCodes {
    problem = -1,
    created = 0,
    active,
    complete
}


export class RwRoutePick {
    isMoveOp = false;
    tarRouteId = "";
    isNew = false;
}


export class RwSyncStatus {
    routeId: string;
    completeCount: number;
    activeCount: number;
    statusCode: RwRouteStatusCodes;
    assignCode: RwRouteAssignCodes;
    driverId: string;
    driverLat: number;
    driverLng: number;
    driverLU: Date;
    driverLS: Date;
    targetDriverId: string;


    static fromJsonArray(jsonArray: JSON[]): RwSyncStatus[] {
        let self = this;
        let list: RwSyncStatus[] = [];
        if (jsonArray != null) {
            jsonArray.forEach(function (json) {
                let status = new RwSyncStatus();
                for (let key in json) {
                    switch (key) {
                        //@formatter:off
                        //case "rnm": status.routeName = json[key]; break;
                        case "rid":
                            status.routeId = json[key];
                            break;
                        case "scc":
                            status.completeCount = json[key];
                            break;
                        case "sac":
                            status.activeCount = json[key];
                            break;
                        case "status":
                            status.statusCode = json[key];
                            break;
                        case "assign":
                            status.assignCode = json[key];
                            break;
                        case "did":
                            status.driverId = json[key];
                            break;
                        case "lat":
                            status.driverLat = json[key];
                            break;
                        case "lng":
                            status.driverLng = json[key];
                            break;
                        case "dlu":
                            status.driverLU = new Date(json[key]);
                            break;
                        case "dls":
                            status.driverLS = new Date(json[key]);
                            break;

                        //@formatter:on
                    }
                }
                list.push(status);

            });
        }

        return list;
    }


    toJSON(): JSON {
        let json = {};
        if (this.routeId) json["rid"] = this.routeId;
        if (this.completeCount) json["scc"] = this.completeCount;
        if (this.activeCount) json["sac"] = this.activeCount;
        if (this.statusCode) json["status"] = this.statusCode;
        if (this.assignCode) json["assign"] = this.assignCode;
        if (this.driverId) json["did"] = this.driverId;
        if (this.targetDriverId) json["tid"] = this.targetDriverId;
        return json as JSON;
    }

}


export class RwRoute {

    static blankRoute(): RwRoute {
        //console.log("RwRoute.blankRoute");
        let route = new RwRoute();
        return route;
    }

    constructor(json?) {
        if (json != null) {
            this.fromJson(json);
        } else {
            this.routeId = RwSysUtils.guidNew();
            this.accountId = theGlobals.orgId;
            this.driverId = theGlobals.userId;
            this.name = this.getRouteName();

            let start = new Date();
            let end = new Date();
            //SETTINGS: this should use route duration preference
            end.setHours(end.getHours() + RwPrefUtils.routeSpan / 60.0);
            this.startTime = start;
            //this.setFinishDate("RwRoute()", end)
            this.finishTime = end;

            //console.warn("RwRoute() SET startTime NOW");

            this.travelMode = RwPrefUtils.travelMode;
            this.optType = RwPrefUtils.optimizeType;

            this.isDynLoc = RwPrefUtils.isDynPoint;
            //console.log("RwRoute() isDynPoint", RwPrefUtils.isDynPoint);

            this.isHardStart = RwPrefUtils.isHardStart;
            this.isHardStop = RwPrefUtils.isHardStop;
            //DEFER: Consider making "isHardStop = true" required by default


            // this.avoidFerries = false;
            // this.avoidHighways = false;
            // this.avoidTolls = false;
            // this.isRoundTrip = false;

            this.avoidFerries = RwPrefUtils.avoids.includes("ferries");
            this.avoidHighways = RwPrefUtils.avoids.includes("highways");
            this.avoidTolls = RwPrefUtils.avoids.includes("tolls");
            this.isRoundTrip = RwPrefUtils.isRoundTrip;

            this.lat = RwPrefUtils.startLat;
            this.lng = RwPrefUtils.startLng;

            //console.log("RwRoute.ctor() done", this.name);
        }
    }

    //region Properties

    isDeleted = false;
    version = 0;
    routeId: string;
    accountId: string;
    driverId: string;
    isHardStart: boolean = false;
    isHardStop: boolean = false;
    isDynLoc: boolean = false;
    isRoundTrip: boolean = false;
    isManOrder: boolean = false;
    firstStopId: string;
    finalStopId: string;
    lat: number = 0;
    lng: number = 0;
    name: string;
    note: string;
    optType: number = 2;
    path: string;
    startTime: Date;
    finishTime: Date;
    timeDrive: number = 0;
    timeIdle: number = 0;
    totalDist: number = 0;
    totalTime: number = 0;
    violations: number = 0;
    travelMode: string = "driving";
    stops: RwStop[] = [];
    statusCode: RwRouteStatusCodes = RwRouteStatusCodes.created;
    assignCode: RwRouteAssignCodes = RwRouteAssignCodes.assigned;
    roundTripTime = 0;
    roundTripDist = 0;
    address: string;
    action: null;
    isArchived: boolean = false;
    isComplete: boolean = false;
    isTemplate: boolean = false;
    stopCountActive: number = 0;
    stopCountComplete: number = -1; //initialization changed to negitive one to improve progress text loading.
    isOpen: boolean = false;
    restTimes: string;

    isSelectedItem: boolean = false;
    lastUpdateCached: number = 0;
    zoomedTo = false;

    lastHash: number = 0;
    loadTimeStops: number = 0;

    //xTEST: Remove when test complete
    // setFinishDate(source:string, val:Date){
    //   if(!val){
    //     console.warn(source, val)
    //   }
    //   this.finishTime = val
    // }

    get globals(): RwGlobals {
        return theGlobals;
    }

    get plan(): RwPlannerSpace {
        return thePlan;
    }

    get index(): number {
        if (this.globals) {
            return this.globals.routes.indexOf(this);
        }
        return 0;
    }

    get startEpoc(): number {
        return this.startTime.getTime()
    }

    get finishEpoc(): number {
        return this.finishTime.getTime()
    }

    finishTimeAdjDur(): Date {
        let time: Date;
        if (this.isOpenEnded) {
            //xFOCUS: isOpenEnded; Use the default routeSpan
            time = moment(this.startTime).add(RwPrefUtils.routeSpan, "minutes").toDate();
        } else {
            time = this.finishTime;
        }
        return time;
    }


    get nextSeq(): number {
        let nextSeq: number;
        if (RwPrefUtils.searchSetSeq) {
            let seqVals = this.stops.map(s => s.seq);
            let seqSet = seqVals.filter(seq => seq && seq > 0 && seq <= 240);
            if (seqSet.length > 0) {
                nextSeq = Math.max(...seqSet);
            }
            nextSeq = (nextSeq) ? nextSeq + 1 : 1;
        }
        //console.warn("nextSeq nextSeq", nextSeq)
        return nextSeq;
    }

    get subType(): number {
        let subType = 0;
        if (this.isDynLoc) {
            subType = 1;
        } else {
            if (this.isRoundTrip) {
                subType = 2;
            } else {
                subType = 0;
            }
        }
        return subType;
    }


    get driver(): RwUser {
        let assignedDriver: RwUser;
        if (this.driverId) {
            let driver = this.globals.findDriver(this.driverId);
            if (driver) {
                assignedDriver = driver;
            } else {
                let disp = this.globals.dispatcher;
                this.driverId = disp.userId;
                assignedDriver = disp;
            }
        }
        return assignedDriver;
    }

    // get driverUrl(): string {
    //   let driverUrl: string;
    //   if (this.driver) {
    //     driverUrl = this.driver.imageUrl;
    //   }
    //   return driverUrl;
    // }

    get progress(): number {
        let prog = 0;
        let totalCount = this.stopCountComplete + this.stopCountActive;
        if (totalCount > 0) {
            prog = this.stopCountComplete / totalCount;
        }
        return prog * 100;
    }

    getProgressColor(): string {
        return this.statusCode === RwRouteStatusCodes.complete ? '#222' : '#e2004f'
    }

    getProgressText(): string {

        if (!this.stopCountComplete) {
            this.stopCountComplete = 0
        }
        let text = '.';
        let totalCount = this.stopCountComplete + this.stopCountActive;
        if (totalCount >= 0) {
            if (this.stopCountComplete == -1) {
                this.stopCountComplete = 0;
                totalCount++;
            }
            text = `${this.stopCountComplete} of ${totalCount} complete`;
        }

        if (totalCount == 0) {
            text = 'no stops';
        }
        if (totalCount == -1) {
            text = 'no stop info'
        }
        return text;
    }

    get plannerUrl(): string {
        return `/planner/${this.routeId}`;
    }

    get isOpenStart(): boolean {
        return !this.isHardStart;
    }

    get isOpenEnded(): boolean {
        return !this.isHardStop;
    }

    get avoids(): number {
        let avoidsNum = 0;
        if (this.avoidFerries) {
            avoidsNum += 1;
        }
        if (this.avoidHighways) {
            avoidsNum += 1;
        }
        if (this.avoidFerries) {
            avoidsNum += 1;
        }

        return avoidsNum;
    }

    _avoidHighways: boolean = false;

    get avoidHighways(): boolean {
        if (RwPrefUtils.avoids.includes('highways')) {
            this._avoidHighways = true;
        }
        return this._avoidHighways;
    }

    set avoidHighways(value: boolean) {
        this._avoidHighways = value;
    }

    _avoidTolls: boolean = false;

    get avoidTolls(): boolean {
        if (RwPrefUtils.avoids.includes('tolls')) {
            this._avoidTolls = true;
        }
        return this._avoidTolls;
    }

    set avoidTolls(value: boolean) {
        this._avoidTolls = value;
    }

    _avoidFerries: boolean = false;

    get avoidFerries(): boolean {
        if (RwPrefUtils.avoids.includes('ferries')) {
            this._avoidFerries = true;
        }
        return this._avoidFerries;
    }

    set avoidFerries(value: boolean) {
        this._avoidFerries = value;
    }


    get hasDriver(): boolean {
        let gotDriver = false;
        if (this.driverId && this.driverId !== "" && this.driverId !== RwUser.NoDriverId) {
            gotDriver = true;
        }
        return gotDriver;
    }

    get isActive(): boolean {
        const activeRoute = this.plan.activeRoute;
        if (activeRoute) {
            return this.routeId === activeRoute.routeId;
        }
        return false;
    }

    get attrClass(): string {
        if (this.isComplete) {
            return "rt-complete";
        }
        return "";
    }


    get routeIcon(): string {
        let iconUrl = RwConstants.IconGreenFlag;

        if (this.statusCode === RwRouteStatusCodes.complete) {
            iconUrl = RwConstants.IconCompleteAll;
        } else if (this.isDynLoc) {
            iconUrl = RwConstants.IconStartRing;
        } else {
            if (this.isRoundTrip) {
                iconUrl = RwConstants.IconRoundTripFlag;
            } else {
                iconUrl = RwConstants.IconGreenFlag;
            }
        }
        return iconUrl;
    }


    //private _routeIconCached: string = null;

    // get routeIcon(): string {
    //   if (this._routeIconCached == undefined) {
    //     this._routeIconCached = RwConstants.IconGreenFlag;
    //     if (this.routeId === this.globals.orgId) {
    //       this._routeIconCached = RwConstants.IconFolder;
    //     }
    //     else {
    //       if (this.isComplete || this.statusCode === RwRouteStatusCodes.complete) {
    //         this._routeIconCached = RwConstants.IconCompleteAll;
    //       }
    //       else if (this.isDynLoc) {
    //         this._routeIconCached = RwConstants.IconStartRing;
    //       }
    //       else {
    //         if (this.isRoundTrip) {
    //           this._routeIconCached = RwConstants.IconRoundTripFlag;
    //         }
    //         else {
    //           this._routeIconCached = RwConstants.IconGreenFlag;
    //         }
    //       }
    //     }
    //   }
    //   return this._routeIconCached;
    // }

    get driverShortString(): string {
        if (this.driverId && this.driverId !== RwUser.NoDriverId) {
            let driver = this.globals.findDriver(this.driverId);
            if (driver) {
                return driver.userName;
            }
        }

        return RwConstants.EmptyString;
    }

    private _startDateCached: string = null;

    get sortStartDate(): string {
        let text: string = "";
        if (this._startDateCached) {
            text = this._startDateCached;

        } else {
            if (this.startTime) {
                text = moment(this.startTime).format("YYYY.MM.DD HH:mm");
                this._startDateCached = text;
            }
        }

        return text;
    }

    get formattedStartDate(): string {
        return RwDateUtils.formatDate(this.startTime)
        //return RwDateUtils.formatDateToLocal(this.startTime)
    }


    private _driverName: string = null;
    private _driverId: string = null;

    public get driverName(): string {
        let driverText: string = "";
        if (this._driverName && this._driverId === this.driverId) {
            driverText = this._driverName;
            //console.log("driverName cached", this.driverId, driverText);
        } else {
            const gotDriverId = this.driverId && this.driverId !== RwUser.NoDriverId;
            const gotDrivers = this.globals.drivers && this.globals.drivers.length > 0;
            if (gotDriverId && gotDrivers) {
                let driver = this.globals.findDriver(this.driverId);
                if (driver) {
                    driverText = driver.userName;
                    this._driverName = driver.userName;
                    this._driverId = driver.userId;
                    //console.log("driverName", this.driverId, driverText);
                }
            }

        }
        return driverText;
    }


    copy(copyType?: RwRouteCopyType): RwRoute {
        let copy = this.clone();
        copy.timeDrive = 0;
        copy.timeIdle = 0;
        copy.totalDist = 0;
        copy.totalTime = 0;

        copy.name = copy.name + " Copy";
        copy.routeId = RwSysUtils.guidNew();
        copy.stopCountActive = this.stops.length;
        copy.stopCountComplete = 0;
        let stopIdLookup: { [id: string]: string } = {};

        // Update start and end times
        if (this.isHardStart) {
            copy.startTime = this.startTime;
            //console.warn("RwRoute copy startTime", this.startTime);
        } else {
            copy.startTime = moment().toDate();
            //console.warn("RwRoute copy NOW", this.startTime);
        }

        if (this.isHardStop) {
            copy.finishTime = this.finishTime;
            //copy.setFinishDate("copy isHardStop", this.finishTime)
        } else {
            let currDuration = moment(this.finishTime).diff(this.startTime)
            let newFinishTime = moment().add(currDuration, "ms").toDate();
            copy.finishTime = newFinishTime
            // copy.setFinishDate("copy !isHardStop", newFinishTime)
        }

        if (this.stops) {
            copy.stops = [];
            // Copy stops

            if (!copyType || (copyType !== 2)) {

                this.stops.forEach(stop => {

                    let isCleared = false;
                    if (copyType || (copyType === 1)) {
                        if (!stop.isComplete) {
                            isCleared = true;
                        }
                    } else {
                        isCleared = true;
                    }

                    if (isCleared) {

                        let copyStop = stop.copy(copy.routeId);
                        if (copyStop && !copyStop.markedForDelete) {
                            copy.stops.push(copyStop);
                            // Update look up table for drop stops
                            if (typeof stopIdLookup[stop.stopId] === "undefined") {
                                stopIdLookup[stop.stopId] = copyStop.stopId;
                            }

                            // Update first and final stop ids
                            if (this.firstStopId) {
                                if (stop.stopId === this.firstStopId) {
                                    copy.firstStopId = copyStop.stopId;
                                }
                            }
                            if (this.finalStopId) {
                                if (stop.stopId == this.finalStopId) {
                                    copy.finalStopId = copyStop.stopId;
                                }
                            }
                        }

                    }
                });

                copy.stops.forEach(stop => {
                    if (stop.hasDrops) {
                        let dropTargets = stop.getDropIds();
                        if (dropTargets) {
                            dropTargets.forEach(dropTarget => {
                                if (typeof stopIdLookup[dropTarget] !== "undefined") {
                                    let newDropId = stopIdLookup[dropTarget];
                                    if (newDropId) {
                                        stop.addDrop(newDropId);
                                    }
                                }
                            });
                        }
                    }
                });

            }
        }

        return copy;
    }

    clone(): RwRoute {
        let clone = new RwRoute();
        clone.routeId = this.routeId;
        clone.accountId = this.accountId;
        clone.driverId = this.driverId;
        clone.name = this.name;
        clone.lat = this.lat;
        clone.lng = this.lng;
        clone.startTime = this.startTime;

        clone.finishTime = this.finishTime;
        // clone.setFinishDate("clone", this.finishTime)


        clone.travelMode = this.travelMode;
        clone.optType = this.optType;
        clone.isDynLoc = this.isDynLoc;
        clone.isHardStart = this.isHardStart;
        clone.isHardStop = this.isHardStop;
        clone.avoidFerries = this.avoidFerries;
        clone.avoidHighways = this.avoidHighways;
        clone.avoidTolls = this.avoidTolls;
        clone.isRoundTrip = this.isRoundTrip;
        clone.stopCountActive = this.stopCountActive;
        clone.stopCountComplete = this.stopCountComplete;
        clone.totalTime = this.totalTime;
        clone.totalDist = this.totalDist;
        clone.note = this.note;
        clone.violations = this.violations;
        clone.stops = this.stops;
        clone.lastUpdateCached = this.lastUpdateCached;
        clone.zoomedTo = this.zoomedTo;
        clone.isManOrder = this.isManOrder;
        clone.isSelectedItem = this.isSelectedItem;
        clone.path = this.path;
        clone.timeDrive = this.timeDrive;
        clone.timeIdle = this.timeIdle;
        clone.isOpen = this.isOpen;
        clone.isArchived = this.isArchived;
        clone.isTemplate = this.isTemplate;
        clone.isComplete = this.isComplete;
        clone.statusCode = this.statusCode;
        clone.assignCode = this.assignCode;
        clone.address = this.address;

        return clone;
    }

    private getRouteName(): string {
        let newName = `Route.${moment().format("YYYY.MM.DD")}`;
        let routeNameTaken = this.globals.routes.find(r => r.name === newName);
        if (routeNameTaken) {
            let counter = 0;
            let maxCount = 100;
            let done = false;
            while (!done) {
                counter++;
                newName = `Route.${moment().format("YYYY.MM.DD")} (${counter})`;
                routeNameTaken = this.globals.routes.find(r => r.name === newName);
                if (!routeNameTaken) {
                    done = true;
                } else {
                    if (counter >= maxCount) {
                        done = true;
                        break;
                    }
                }
            }
        }
        return newName;
    }


    get driverString(): string {
        if (this.driverId && this.driverId !== RwUser.NoDriverId) {
            let driver = this.globals.findDriver(this.driverId);
            if (driver) {
                let hasName = driver.name && driver.name !== "";
                return driver.userName + (hasName ? ` (${driver.name})` : "");
            }
        }
        return RwConstants.EmptyString;
    }

    _totalTimeShort: string = null;

    get totalTimeShort(): string {
        let text = "";
        if (this._totalTimeShort) {
            text = this._totalTimeShort;
        } else {
            if (this.totalTime === 0) {
                text = RwConstants.EmptyString;
            } else {
                this._totalTimeShort = RwTextUtils.formatDuration(this.totalTime);
                text = this._totalTimeShort;
            }
        }
        return text;
    }

    _totalTimeString: string = null;

    get totalTimeString(): string {
        let text = "";
        if (this._totalTimeString) {
            text = this._totalTimeString;
        } else {
            if (this.totalTime === 0) {
                text = RwConstants.EmptyString;
            }
            // var duration = moment.duration(this.totalTime, "seconds") as any;
            // moment.utc(duration.asMilliseconds()).format("hh:mm:ss");
            //text = duration.format("d [days] h [hrs], m [min]");
            //text = this.getTimeDurationText(duration);

            text = RwTextUtils.formatDuration(this.totalTime);


            // let mo = moment.utc(duration.asMilliseconds());
            // text = mo.format("d [days] h [hrs], m [min]");

            this._totalTimeString = text;

            //var duration = moment.duration(this.totalTime, "seconds") as any;
            //text = duration.format("d [days] h [hrs], m [min]");
            // this._totalTimeString;
        }
        return text;
    }

    private _travelTimeText: string = null;

    get travelTimeText(): string {
        if (this._travelTimeText) {
            return this._travelTimeText;
        } else {
            if (this.timeDrive === 0) {
                return RwConstants.EmptyString;
            }
            this._travelTimeText = RwTextUtils.formatDuration(this.timeDrive);

            // var duration = moment.duration(this.timeDrive, "seconds") as any;
            // //this._travelTimeText = duration.format("d [days] h [hrs], m [min]");
            // this._travelTimeText = this.getTimeDurationText(duration);

            // var duration = moment.duration(this.timeDrive, "seconds");
            // this._travelTimeText = moment.utc(duration.asMilliseconds()).format("d [days] h [hrs], m [min]");

            return this._travelTimeText;
        }
    }

    get travelReturnText(): string {
        let displayText = "";
        if (this.isActive) {
            let unit = RwPrefUtils.distUnits;
            let distText = "";
            if (unit == RwDistUnits.Kilometers) {
                let dist = (this.roundTripDist / 1000).toFixed(1);
                distText = `${dist} km`;
            } else {
                let dist = (this.roundTripDist / 1609).toFixed(1);
                distText = `${dist} mi`;
            }
            let timeText = RwTextUtils.formatDuration(this.roundTripTime);
            displayText = `drive ${distText} in ${timeText}`;
        }
        return displayText;
    }

    get routeStatusText(): string {
        let text: string
        switch (this.statusCode) {
            //@formatter:off
            case RwRouteStatusCodes.problem:
                text = "Problem";
                break;
            case RwRouteStatusCodes.created:
                text = "Created";
                break;
            case RwRouteStatusCodes.active:
                text = "In Progress";
                break;
            case RwRouteStatusCodes.complete:
                text = "Complete";
                break;
            //@formatter:on
        }
        return text;
    }

    get assignmentString(): string {
        switch (this.assignCode) {

            case RwRouteAssignCodes.assigned:
                return "Assigned";

            case RwRouteAssignCodes.rejected:
                return "Rejected";

            case RwRouteAssignCodes.pending:
                return "Pending";
        }
        return "";
    }

    get assignTextInline(): string {
        switch (this.assignCode) {

            case RwRouteAssignCodes.assigned:
                return "(assigned)";

            case RwRouteAssignCodes.pending:
                return "(pending)";

            case RwRouteAssignCodes.rejected:
                return "(rejected)";
        }
        return "";
    }


    get startTimeString(): string {
        return moment(this.startTime).format("L LT");
    }

    get finishTimeString(): string {
        let finishMillis = this.startTime.getTime() + (1000 * this.totalTime);
        let finishDate = new Date(finishMillis);
        let dateText = RwDateUtils.formatDate(finishDate);
        //let dateText = RwDateUtils.formatDateToLocal(finishDate);
        return dateText;
    }

    get departureTimeString(): string {
        let nowMoment = moment();
        let startMoment = moment(this.startTime);
        let endMoment;

        if (this.isHardStop) {
            endMoment = moment(this.finishTime);
        } else {
            endMoment = moment(this.startTime).add(this.totalTime, "seconds");
        }

        let isTodayStart = startMoment.format("L") === nowMoment.format("L");
        let isTodayFinish = endMoment.format("L") === nowMoment.format("L");

        let startStr = isTodayStart
            ? startMoment.format("LT")
            : startMoment.format("L LT");
        let endStr = isTodayFinish ? endMoment.format("LT") : endMoment.format("L LT");

        if (this.isHardStart && this.isHardStop) {
            if (this.totalTime === 0) {
                return `Specific Time Frame (${startStr})`;
            } else {
                return `Specific Time Frame (${startStr} to ${endStr})`;
            }
        } else if (this.isHardStart && !this.isHardStop) {
            if (this.totalTime === 0) {
                return `Specific Start Time (${startStr})`;
            } else {
                return `Specific Start Time (${startStr} to ${endStr})`;
            }
        } else if (!this.isHardStart && this.isHardStop) {
            if (this.totalTime === 0) {
                return `Specific Finish Time (${startStr})`;
            } else {
                return `Specific Finish Time (${startStr} to ${endStr})`;
            }
        }

        if (this.totalTime === 0) {
            return `Time of Optimize (${startStr})`;
        } else {
            return `Time of Optimize (${startStr} to ${endStr})`;
        }
    }

    get departurePointString(): string {
        if (this.isDynLoc === true) {
            return "Location of Optimize (GPS)";
        } else {
            if (this.address) {
                return `Specific Location (${this.address})`;
            }
            return `Specific Location (${this.lat.toFixed(4)},${this.lng.toFixed(
                4
            )})`;
        }
    }

    get travelModeText(): string {
        return RwRoute.getTravelModeText(this.travelMode);
    }

    get travelModeIcon(): string {
        //console.warn(this.travelMode)
        let url: string;
        switch (this.travelMode.toLowerCase()) {
            //@formatter:off
            case "driving":
                url = "mdi-car";
                break;
            case "biking":
            case "bicycling":
                url = "mdi-bike";
                break;
            case "walking":
                url = "mdi-walk";
                break;
            default:
                url = "mdi-car";
                break;
            //@formatter:on
        }
        return url;
    }

    get travelModeEnum(): RwTravelModes {
        // 0 = driving, 1 = biking, 2 = walking
        let code: RwTravelModes = RwTravelModes.driving;
        switch (this.travelMode.toLowerCase()) {
            //@formatter:off
            case "driving":
                code = RwTravelModes.driving;
                break;
            case "walking":
                code = RwTravelModes.walking;
                break;
            case "biking":
                code = RwTravelModes.biking;
                break;
            case "bicycling":
                code = RwTravelModes.biking;
                break;
            default:
                code = RwTravelModes.driving;
                break;
            //@formatter:on
        }
        return code;
    }


    static getTravelModeText(travelMode: string): string {
        // 0 = driving, 1 = biking, 2 = walking
        let text: string;
        switch (travelMode.toLowerCase()) {
            //@formatter:off
            case "driving":
                text = "Driving";
                break;
            case "walking":
                text = "Walking";
                break;
            case "bicycling":
                text = "Biking";
                break;
            case "biking":
                text = "Biking";
                break;
            default:
                text = "Driving";
                break;
            //@formatter:on
        }
        //console.log("getTravelModeText", travelMode, text);
        return text;
    }

    static getTravelMode(travelMode: string): string {
        // 0 = driving, 1 = biking, 2 = walking
        let text: string;
        switch (travelMode.toLowerCase()) {
            //@formatter:off
            case "driving":
                text = "driving";
                break;
            case "walking":
                text = "walking";
                break;
            case "bicycling":
                text = "bicycling";
                break;
            case "biking":
                text = "bicycling";
                break;
            default:
                text = "Driving";
                break;
            //@formatter:on
        }
        return text;
    }


    departurePointType: number = 1; // 0 = dynamic, 1 = fixed point
    departureTimeType: number = 0; // 0 = dynamic, 1 = fixed time

    private _totalDistText: string = null;

    get totalDistText(): string {
        let distText = RwConstants.EmptyString;
        if (this._totalDistText) {
            distText = this._totalDistText;
        } else {
            if (this.totalDist != 0) {
                let unit = settingSpace.distUnits;
                // let unit = RwPrefUtils.distUnits;
                // let unit = theStore.getters.distUnits;
                if (unit == RwDistUnits.Kilometers) {
                    let dist = (this.totalDist / 1000).toFixed(1);
                    distText = `${dist} km`;
                } else {
                    let dist = (this.totalDist / 1609).toFixed(1);
                    distText = `${dist} mi`;
                }
                this._totalDistText = distText;
            }
        }
        return distText;

        //Do this in the control
        //return "N/A";
    }

    get fullVioText(): string {
        let text = "";
        let hasRouteVios: boolean = this.violations != 0;
        let hasStopVios = this.hasStopViolations();

        if (hasRouteVios && hasStopVios) {
            text = this.routeVioTextVerbose;
            text += "\n\n";
            text +=
                "Stop Violations\nMake sure all stops are reachable from start.\nDouble check your time windows (schedules).";
        } else if (hasRouteVios) {
            text = this.routeVioTextVerbose;
        } else if (hasStopVios) {
            text =
                "Stop Violations\nMake sure all stops are reachable from start.\nDouble check your time windows (schedules).";
        }
        return text;
    }

    get routeVioText(): string {
        let text = "";
        if (this.violations != 0) {
            switch (this.violations) {
                case 1:
                    text = "Overtime";
                    break;
                case 2:
                    text = "Overdist";
                    break;
                case 4:
                    text = "Error";
                    break;
                default:
                    break;
            }
        }
        return text;
    }

    get routeVioTextVerbose(): string {
        let text = "";
        if (this.violations != 0) {
            switch (this.violations) {
                case 1:
                    text =
                        "Overtime Violation\nConsider extending your route time.\nConsider reducing number of stops.";
                    break;
                case 2:
                    text = "Overdist";
                    break;
                case 4:
                    text =
                        "Route Not Optimized\nEnsure start point is valid\nEnsure all stops are valid.";
                    break;
                default:
                    break;
            }
        }
        return text;
    }

    get routeVioHtmlVerbose(): string {
        let text = "";
        if (this.violations != 0) {
            switch (this.violations) {
                case 1:
                    text =
                        "<div><b>Overtime Violation</b></div><div>Consider extending your route time.</div><div>Consider reducing number of stops.</div>";
                    break;
                case 2:
                    text = "Overdist";
                    break;
                case 4:
                    text =
                        "<div><b>Route Not Optimized<b></div><div>Ensure start point is valid</div><div>Ensure all stops are valid.</div>";
                    break;
                default:
                    break;
            }
        }
        return text;
    }


    getStatusBackground(): string {
        return RwRoute.getStatusBackground(this.statusCode);
    }

    getAssignBackground(): string {
        return RwRoute.getAssignBackground(this.assignCode);
    }

    getStatusTextStyle(): string {
        return RwRoute.getStatusTextStyle(this.statusCode);
    }

    getAssignTextStyle(): string {
        return RwRoute.getAssignTextStyle(this.assignCode);
    }


    static getStatusBackground(status: number): string {
        let style = "";
        switch (status) {
            //@formatter:off
            case RwRouteStatusCodes.created:
                style = theGlobals.isDarkMode ? "statusColorGreen" : "statusColorGreenLight";
                break;
            case RwRouteStatusCodes.active:
                style = theGlobals.isDarkMode ? "statusColorYellow" : "statusColorYellowLight";
                break;
            case RwRouteStatusCodes.problem:
                style = theGlobals.isDarkMode ? "statusColorRed" : "statusColorRedLight";
                break;
            case RwRouteStatusCodes.complete:
                style = theGlobals.isDarkMode ? "statusColorGrey" : "statusColorGreyLight";
                break;
            //@formatter:on
        }
        //console.log("getStatusBodyStyle", status, style);
        return style;
    }

    static getAssignBackground(assignCode: number): string {
        let style = "";
        switch (assignCode) {
            //@formatter:off
            case RwRouteAssignCodes.assigned:
                style = theGlobals.isDarkMode ? "statusColorGrey" : "statusColorGreyLight";
                break;
            case RwRouteAssignCodes.pending:
                style = theGlobals.isDarkMode ? "statusColorYellow" : "statusColorYellowLight";
                break;
            case RwRouteAssignCodes.rejected:
                style = theGlobals.isDarkMode ? "statusColorRed" : "statusColorRedLight";
                break;
            //@formatter:on
        }
        return style;
    }


    static getStatusTextStyle(status: number): string {
        let style = "";
        switch (status) {
            case RwRouteStatusCodes.created:
                style = theGlobals.isDarkMode ? "statusColorGreenLightText" : "statusColorGreenText";
                break;
            case RwRouteStatusCodes.active:
                style = theGlobals.isDarkMode ? "statusColorYellowLightText" : "statusColorYellowText";
                break;
            case RwRouteStatusCodes.problem:
                style = theGlobals.isDarkMode ? "statusColorRedLightText" : "statusColorRedText";
                break;
            case RwRouteStatusCodes.complete:
                style = theGlobals.isDarkMode ? "statusColorGreyLightText" : "statusColorGreyText";
                break;
        }
        //console.log("getStatusText", status, style);
        return style;
    }

    static getAssignTextStyle(assign: number): string {
        let style = "";
        switch (assign) {
            //case RwRouteAssignCodes.assigned: style = this.globals.isDarkMode ? "statusColorGreyText" : "statusColorGreyLightText"; break;
            case RwRouteAssignCodes.pending:
                style = theGlobals.isDarkMode ? "statusColorYellowLightText" : "statusColorYellowText";
                break;
            case RwRouteAssignCodes.rejected:
                style = theGlobals.isDarkMode ? "statusColorRedLightText" : "statusColorRedText";
                break;
        }
        //console.warn("getAssignText", style);
        return style;
    }


    invalidateCache() {
        //this._routeIconCached = null;
        this._startDateCached = null;
        this._driverName = null;
        this._totalTimeString = null;
        this._travelTimeText = null;
        this._totalDistText = null;
        this._totalTimeShort = null;
    }


    //endregion


    //region JSON APIs

    static fromJsonArray(jsonArray: Object): RwRoute[] {
        let routes: RwRoute[] = [];
        if (jsonArray != null) {
            for (let key in jsonArray) {
                let jval = jsonArray[key];
                let route = new RwRoute(jval);
                routes.push(route);
            }
        }
        return routes;
    }

    fromJsonOpt(json: JSON) {
        for (let key in json) {
            //RwLog.warnConsole("fromJsonOpt", key, json[key]);
            switch (key) {
                //case "JOBID": RwLog.warnConsole("JOBID", json[key]); break;
                case "AAROUTE": {
                    let jsonRoute = json[key];
                    this.fromJson(jsonRoute);
                    break;
                }

                case "gdtime": {
                    theGlobals.greedyTime = json[key];
                    break;
                }

                case "gddist": {
                    theGlobals.greedyDist = json[key];
                    break;
                }
            }
        }
    }

    fromJson(json: JSON) {
        const self = this;
        const SOURCE = "RwRoute.fromJson"
        //set default values or initialize on declaration

        const now = Date.now();


        for (let key in json) {
            switch (key) {
                //@formatter:off
                case "d":
                    this.isDeleted = true;
                    break;
                case "nm":
                case "rnm":
                    this.name = json[key];
                    break;
                case "rid":
                    this.routeId = json[key];
                    break;
                case "uid":
                    this.accountId = json[key];
                    break;
                case "ssm": {
                    this.startTime = new Date(json[key]);
                    break;
                }
                case "sem": {
                    this.finishTime = new Date(json[key]);
                    // this.setFinishDate("fromJson", new Date(json[key]));
                    break;
                }
                case "lat":
                    this.lat = json[key];
                    break;
                case "lon":
                    this.lng = json[key];
                    break;
                case "om":
                    this.optType = json[key];
                    break;
                case "mode":
                    this.travelMode = json[key];
                    if (this.travelMode === "fastest") this.travelMode = "driving";
                    break;
                case "avoids":
                    this.setAvoids(json[key]);
                    break;
                case "vio":
                    this.violations = json[key];
                    break;
                case "tdist":
                    this.totalDist = json[key];
                    break;
                case "ttime":
                    this.totalTime = json[key];
                    break;
                case "idl":
                    this.isDynLoc = json[key];
                    break;
                case "ihstt":
                    this.isHardStart = json[key];
                    break;
                case "ihstp":
                    this.isHardStop = json[key];
                    break;
                case "t2d":
                    this.timeDrive = json[key];
                    break;
                case "t2i":
                    this.timeIdle = json[key];
                    break;
                case "irt":
                    this.isRoundTrip = json[key];
                    break;
                case "imo":
                    this.isManOrder = json[key];
                    break;
                case "note":
                    this.note = json[key];
                    break;
                case "poly":
                    this.path = json[key];
                    break;
                case "prm":
                    this.firstStopId = json[key];
                    break;
                case "fin":
                    this.finalStopId = json[key];
                    break;
                case "asc":
                    this.stopCountActive = json[key];
                    break;
                case "csc":
                    this.stopCountComplete = json[key];
                    break;
                case "did":
                    this.driverId = json[key];
                    break;
                case "isArch":
                    this.isArchived = json[key];
                    break;
                case "isComp":
                    this.isComplete = json[key];
                    break;
                case "isTemp":
                    this.isTemplate = json[key];
                    break;
                case "rtime":
                    this.roundTripTime = json[key];
                    break;
                case "rdist":
                    this.roundTripDist = json[key];
                    break;
                case "add":
                    this.address = json[key];
                    break;
                case "status":
                    this.statusCode = json[key];
                    break;
                case "assign":
                    this.assignCode = json[key];
                    break;

                case "stps": {
                    //console.log(key, json[key]);
                    let stopsJson = json[key];
                    let newStops = RwStop.fromJsonArray(stopsJson);
                    //console.log("RwRoute.fromJson newStops", newStops)
                    this.mergeStops(newStops);
                    break;
                }

            }
        }

        if (!this.name) {
            this.name = "";
            RwLog.consoleError(SOURCE, "name == null", this.routeId);
        }
        this.lastHash = now;
    }


    mergeStops(newStops: RwStop[]) {
        const self = this;

        if (newStops != null && newStops.length > 0) {
            newStops.filter(s => s.routeId === this.routeId).forEach(function (stop) {
                let existing = self.findStop(stop.stopId);
                let isMarkedForDelete = stop.markedForDelete;
                if (isMarkedForDelete) {
                    if (existing) {
                        //console.log("mergeStops removing stop: " + stop.name);
                        self.removeStopById(existing.stopId);
                    }
                } else {
                    if (existing != null) {
                        //console.log("mergeStops updating stop: " + stop.name, stop);
                        existing.fromStop(stop);
                    } else {
                        //console.log("mergeStops creating stop: " + stop.name);
                        self.addStop(stop);
                    }
                }
            });
        }
    }

    fromRoute(route: RwRoute) {
        this.name = route.name;
        this.startTime = route.startTime;
        this.finishTime = route.finishTime;
        // this.setFinishDate("fromRoute", route.finishTime);

        this.lat = route.lat;
        this.lng = route.lng;
        this.optType = route.optType;
        this.travelMode = route.travelMode;

        this.avoidFerries = route.avoidFerries;
        this.avoidHighways = route.avoidHighways;
        this.avoidTolls = route.avoidTolls;

        this.violations = route.violations;
        this.totalDist = route.totalDist;
        this.totalTime = route.totalTime;

        this.isDynLoc = route.isDynLoc;
        this.isHardStart = route.isHardStart;
        this.isHardStop = route.isHardStop;

        this.timeDrive = route.timeDrive;
        this.timeIdle = route.timeIdle;
        this.isRoundTrip = route.isRoundTrip;
        this.isManOrder = route.isManOrder;
        this.note = route.note;
        this.path = route.path;
        this.firstStopId = route.firstStopId;
        this.finalStopId = route.finalStopId;
        this.stopCountActive = route.stopCountActive;
        this.stopCountComplete = route.stopCountComplete;
        this.driverId = route.driverId;
        this.isArchived = route.isArchived;
        this.isComplete = route.isComplete;
        this.isTemplate = route.isTemplate;
        this.statusCode = route.statusCode;
        this.assignCode = route.assignCode;
        this.roundTripDist = route.roundTripDist;
        this.roundTripTime = route.roundTripTime;
        this.address = route.address;

        this.lastHash = Date.now();
    }

    toJSON() {
        const SOURCE = "RwRoute.toJSON";
        if (!this.startTime || this.startTime.getTime() === 0) {
            try {
                let stack = RwSysUtils.printStackTrace();
                let message = `routeId:${this.routeId}) start: ${this.startTime ? this.startTime.getTime() : this.startTime}, \n\n ${stack}`;
                RwLog.error(SOURCE, message);
            } catch (e) {
                RwLog.consoleError(e.toString());
            }
        }

        let json = {
            nm: this.name,
            rid: this.routeId,
            uid: this.accountId,
            rnm: this.name,
            ssm: this.startTime
                ? this.startTime.getTime()
                : moment()
                    .toDate()
                    .getTime(),
            sem: this.finishTime
                ? this.finishTime.getTime()
                : moment(this.startTime)
                    .add(this.totalTime, "seconds")
                    .toDate()
                    .getTime(),
            lat: this.lat,
            lon: this.lng,
            om: this.optType,
            mode: this.travelMode,
            vio: this.violations,
            tdist: this.totalDist,
            t2d: this.timeDrive,
            ttime: this.totalTime,

            uhard: true,
            ihstt: this.isHardStart,
            ihstp: this.isHardStop,
            //idt: this.isDynTime,

            idl: this.isDynLoc,
            t2i: this.timeIdle,
            irt: this.isRoundTrip,
            imo: this.isManOrder,
            isArch: this.isArchived,
            isTemp: this.isTemplate,
            isComp: this.isComplete,
            status: this.statusCode,
            assign: this.assignCode,
            rtime: this.roundTripTime,
            rdist: this.roundTripDist
        };

        //add optional values
        if (this.firstStopId !== null) {
            json["prm"] = this.firstStopId;
        }
        if (this.finalStopId !== null) {
            json["fin"] = this.finalStopId;
        }
        if (this.note != null) {
            json["note"] = this.note;
        }
        if (this.path != null) {
            json["poly"] = this.path;
        }
        if (this.driverId && this.driverId !== RwUser.NoDriverId) {
            json["did"] = this.driverId;
        }
        if (this.address) {
            json["add"] = this.address;
        }

        json["avoids"] = this.getAvoidsJson();

        // Null checks
        if (!json.ssm) {
            json.ssm = moment()
                .toDate()
                .getTime();
        }
        if (!json.sem) {
            json.sem = moment()
                .add(8, "hours")
                .toDate()
                .getTime();
        }

        return json;
    }

    toJsonWithAllStops() {
        let json = this.toJSON();

        if (this.stops != null) {
            let jstops = [];
            for (let key in this.stops) {
                let stop = this.stops[key];
                let jstop = stop.toJSON();
                jstops.push(jstop);
                //println("Restriction: \(interval.description)")
            }
            json["stps"] = jstops;
        }

        return json;
    }

    toJsonWithStops(stopList: RwStop[], isOptimize: boolean = false) {
        let json = this.toJSON();
        let jstops = [];
        stopList.forEach(stop => {
            let jstop = stop.toJSON(isOptimize);
            jstops.push(jstop);
        });
        json["stps"] = jstops;
        return json;
    }

    toJsonOpt(jobId: string, actStops: RwStop[]) {
        let jsonJob = {};

        jsonJob["LAST_SYNC"] = Date.now();
        jsonJob["JOBID"] = jobId;
        jsonJob["tkn"] = this.globals.token;

        let jsonRoute = this.toJsonWithStops(actStops, true);
        jsonRoute["poly"] = "";
        jsonJob["AAROUTE"] = jsonRoute;

        return jsonJob;
    }

    getAvoidsJson(): string[] {
        let avoidsArray = new Array<string>();
        if (this.avoidFerries) {
            avoidsArray.push("ferries");
        }
        if (this.avoidTolls) {
            avoidsArray.push("tolls");
        }
        if (this.avoidHighways) {
            avoidsArray.push("highways");
        }
        return avoidsArray;
    }

    setAvoids(flag: String[]) {
        this.avoidFerries = flag.indexOf("ferries") !== -1;
        this.avoidHighways = flag.indexOf("highways") !== -1;
        this.avoidTolls = flag.indexOf("tolls") !== -1;
    }

    // DEFER: UX Code in DEM; returning bool fine; CSS not so much
    // get hasNoteVisible(): string {
    //   if (this.note && this.note !== "") {
    //     return "";
    //   }
    //   return "display-gone";
    // }

    //endregion

    createStop(name: string, address: string, lat: number, lng: number): RwStop {
        let stop = new RwStop();
        stop.isActive = true;
        stop.name = name;
        stop.address = address;
        stop.lat = lat;
        stop.lng = lng;
        stop.baseColor = RwPrefUtils.stopColor;
        stop.visitTime = RwPrefUtils.visitTime;
        stop.routeId = this.routeId;
        if (this.isManOrder || RwPrefUtils.searchSetSeq) stop.seq = this.stops.length + 1;
        //if (this.isManOrder) stop.seq = this.stops.length + 1;
        return stop;
    }

    createStopFromSite(site: RwSite) {
        let stop = this.createStop(site.name, site.address, site.lat, site.lng);
        stop.siteId = site.siteId;
        stop.note = site.note;
        stop.baseColor = site.baseColor;
        stop.visitTime = site.getVisitTime();

        if (site.email && site.email.length > 0) {
            stop.email = site.email;
        }

        if (site.phone && site.phone.length > 0) {
            stop.phone = site.phone;
        }

        //DEFER: SKED INLINING: createStopFromSite; Copy relevant values; e.g. SKEDS inline
        // let skeds = site.getActiveAvailability();
        // if (skeds) {
        //   //console.log("onSiteToRoute gotStop name, add, lat, lng", stop.name, stop.address, stop.lat, stop.lng);
        //   //1) Detect if weekly skeds occur more than once during route time frame;
        //   //2a) Single: Inline the single sked to the stop
        //   //2b) Multiple: Prompt use to choose the sked; on choice; inline chosen sked to stop
        // }

        return stop;
    }

    addStop(stop: RwStop) {
        stop.lastHash = Date.now();
        this.stops.push(stop);
        this.updateStopCounts();
    }


    removeStopById(stopId: string) {
        let stop = this.findStop(stopId);
        this.removeStop(stop);
    }

    removeStop(stop: RwStop) {
        if (stop) {
            let idx = this.stops.indexOf(stop);
            if (idx > -1) {
                this.stops.splice(idx, 1);
                this.updateStopCounts();
            }
        }
    }

    updateStopCounts() {
        this.stopCountActive = this.stops.filter(s => !s.isComplete).length;
        this.stopCountComplete = this.stops.filter(s => s.isComplete).length;
    }

    isFirstStop(stop: RwStop): boolean {
        let isFirst = false;
        if (this.firstStopId != null) {
            isFirst = this.firstStopId == stop.stopId;
        }
        return isFirst;
    }

    isFinalStop(stop: RwStop): boolean {
        let isFinal = false;
        if (this.finalStopId != null) {
            isFinal = this.finalStopId == stop.stopId;
        }
        return isFinal;
    }

    findStop(stopId: String): RwStop {
        return this.stops.find(s => s.stopId == stopId);
    }

    updateTimeFrame() {

        if (this.isOpenStart) {
            this.startTime = new Date();
            //console.warn("RwRoute updateTimeFrame SET startTime", this.startTime);
        }

        //Use default route duration
        if (this.isOpenEnded) {
            let newFinish = RwDateUtils.addMinutesToDate(this.startTime, RwPrefUtils.routeSpan);
            this.finishTime = newFinish;
            // this.setFinishDate("updateTimeFrame", newFinish);
        }
    }

    getActiveStops(): RwStop[] {
        let actives: RwStop[] = null;
        if (this.stops != null && this.stops.length > 0) {
            //RwLog.warnConsole("getActiveStops stops.length", this.stops.length);

            this.stops.forEach(stop => {
                //Reset base stop values
                let isStopActive = false;
                stop.timeWindows = null;
                if (stop.violations == 4) {
                    stop.violations = 0;
                    //stop.availCacheTime = 0;
                    //if(stop.isAvailCached) {stop.isAvailCached = false;}
                }

                if (stop.isComplete) {
                    stop.seq = 1001;
                    //RwLog.warnConsole("getActiveStops isComplete", stop.name);
                } else {

                    if (stop.isScheduled) {

                        let sked = stop.getSked();
                        //RwLog.consoleWarn("getActiveStops hasSchedule", stop.name);
                        let tws = stop.getTimeWindows(sked, this.startTime, this.finishTime);
                        //RwLog.consoleWarn("tws", tws);
                        if (tws != null) {
                            isStopActive = true;
                            stop.timeWindows = tws;
                            stop.priority = sked.priority;
                        } else {
                            //RwLog.warnConsole("getActiveStops no timeWindows", stop.name);
                            if (stop.violations != 4 || stop.seq != 1002) {
                                // if (stop.isAvailCached) {
                                //   stop.isAvailCached = false;
                                // }
                                stop.violations = 4;
                                stop.seq = 1002;
                            }
                        }
                    } else {
                        let siteId = stop.siteId;
                        if (siteId == null) {
                            //no schedule or availability constraints
                            isStopActive = true;
                        } else {
                            let site = this.globals.findSite(siteId);
                            if (site == null) {
                                isStopActive = true;
                            } else {
                                //RwLog.log("getActiveStops hasSite", stop.name);
                                if (site.getActiveAvailability() != null) {
                                    //RwLog.log("getActiveStops hasAvailability", stop.name);

                                    let tws = site.getTimeWindows(this.startTime, this.finishTime);
                                    if (tws != null) {
                                        //RwLog.log("getActiveStops site skeds matched", stop.name);
                                        isStopActive = true;
                                        stop.timeWindows = tws;
                                    } else {

                                        //RwLog.warn("getActiveStops NO MATCH: site skeds", stop.name);

                                        //This happens when a stop has schedules but not available.
                                        if (stop.violations != 4 || stop.seq != 1002) {
                                            stop.violations = 4;
                                            stop.seq = 1002;
                                        }
                                    }
                                } else {
                                    isStopActive = true;
                                }
                            }
                        }
                    }
                }

                stop.isActive = isStopActive;
                if (isStopActive) {
                    if (actives == null) {
                        actives = [];
                    }
                    //RwLog.logConsole("getActiveStops isActive", stop.name);
                    actives.push(stop);
                } else {
                    //RwLog.warnConsole("getActiveStops not Active", stop.name);
                }
            });
        }
        return actives;
    }

    hasRouteViolations(): boolean {
        let hasVios = this.violations != 0;
        return hasVios;
    }

    hasStopViolations(): boolean {
        let hasVios = false;
        let actStops = this.getActiveStops();
        if (actStops != null && actStops.length > 0) {
            actStops.forEach(stp => {
                if (stp.violations != 0 && stp.violations != 16) {
                    hasVios = true;
                }
            });
        }
        return hasVios;
    }

    toString() {
        return this.toJSON().toString();
    }

    isRouted(site: RwSite): boolean {
        //console.log("Route.isRouted", site.name);
        let isRouted = false;
        let routedSite = this.getStopForSite(site);
        //console.log("Route.isRouted routedSite", routedSite);
        if (routedSite != null) {
            isRouted = true;
        }
        return isRouted;
    }


    getStopForSite(site: RwSite): RwStop {
        let stopFound: RwStop = null;
        if (this.stops != null && this.stops.length > 0) {
            let siteId = site.siteId.toLowerCase();
            for (let i = 0; i < this.stops.length; i++) {
                let stop = this.stops[i];
                let sid = stop.siteId;
                if (sid != null) {
                    if (sid.toLowerCase() == siteId) {
                        stopFound = stop;
                        break;
                    }
                }
            }
        }
        return stopFound;
    }

    // getStopForSite(site: RwSite): RwStop {
    //   let stopFound: RwStop = null;
    //
    //   if (this.stops != null && this.stops.length > 0) {
    //     let findSid = site.siteId.toLowerCase();
    //     this.stops.forEach(stop => {
    //       if (stopFound == null) {
    //         let sid = stop.siteId;
    //         if (sid != null) {
    //           if (sid.toLowerCase() == findSid) {
    //             stopFound = stop;
    //           }
    //         }
    //       }
    //     });
    //   }
    //   return stopFound;
    // }

    isAddressOnRoute(address: String): boolean {
        let isOnRoute = false;
        let addLower = address.toLowerCase();
        if (this.stops != null && this.stops.length > 0) {
            this.stops.forEach(stop => {
                if (isOnRoute == false) {
                    let address = stop.address;
                    if (address != null) {
                        if (address.toLowerCase() == addLower) {
                            isOnRoute = true;
                        }
                    }
                }
            });
        }
        return isOnRoute;
    }


    canAddStop(stopAddress: string, stopCount: number, p_route: RwRoute | null = null): Promise<boolean> {
        const self = this;
        //console.log("canAddStop", stopAddress, stopCount);

        return new Promise<boolean>(function (resolve) {

            let isInProgress = self.globals.plan.addingStop;
            //console.log("canAddStop addingStop", isInProgress);
            if (!isInProgress) {

                let route = p_route != null ? p_route : self.globals.activeRoute;
                if (route != null) {


                    //self.plan.maxRouteSize = 8;
                    if (self.canAddToRoute(stopCount)) {

                        //Address on Route
                        if (stopAddress && self.isAddressOnRoute(stopAddress)) {

                            let title = "Add Stop";
                            let body = "Address already exists on route.  Do you want to add it anyway?";
                            self.globals
                                .showConfirmDialog(title, body)
                                .then(isConfirmed => {
                                    //console.log("canAddStop isConfirmed");
                                    resolve(isConfirmed)
                                });

                            // let dialog = <any>document.querySelector("#popConfirmStop");
                            // let txtTitle = <any>document.querySelector("#popConfirmStopTitle");
                            // let txtBody = <any>document.querySelector("#popConfirmStopBody");
                            // let cmdYes = <any>document.querySelector("#popConfirmStopYes");
                            // let cmdNo = <any>document.querySelector("#popConfirmStopNo");
                            //
                            // txtTitle.innerHTML = "Add Stop?";
                            // txtBody.innerHTML =
                            //   "Address already exists on route.  Do you want to add it anyway?";
                            //
                            // cmdYes.addEventListener("click", function () {
                            //   resolve(true);
                            // });
                            // cmdNo.addEventListener("click", function () {
                            //   resolve(false);
                            // });
                            // dialog.toggle();


                        } else {
                            resolve(true);
                        }
                    } else {
                        //xWIP: UX: canAddStop: Over limit handling
                        self.globals.showSnack("Over Stop Limit");
                        resolve(false);

                        //RwApp.popLimitWarning(RwLimitType.RouteSize);

                        //Handling dependent on whether they have an upgrade option...

                        // let title = "Over Stop Limit";
                        // let body = "Do you want to see the options?";
                        // self.globals
                        //   .showConfirmDialog(title, body)
                        //   .then(showOptions => {
                        //     if (showOptions) {
                        //       //xFOCUS: ACTIONS: Navigate to purchase screen
                        //     }
                        //     resolve(false);
                        //   });
                    }

                } else {
                    RwLog.consoleError("WTF: No Active Route");
                    resolve(false);
                }
            } else {
                RwLog.consoleError("WTF: Add Stop In Progress");
                resolve(false);
            }

        });
    }

    canAddToRoute(count: number): boolean {
        //console.log("RwRoute.canAddToRoute count", count);
        let canAdd = false;
        let maxStops = this.plan.maxRouteSize;
        if (this.stops != null) {
            if (this.stops.length + count <= maxStops) {
                canAdd = true;
            }
        }
        //console.log("canAddToRoute canAdd", canAdd, count, maxStops);
        return canAdd;
    }


    static getRouteSpanText(timeOfStart: Date, timeOfFinish: Date, isHardStart: boolean, isHardStop: boolean, totalTime: number): string {
        let spanText: string;

        let startMoment = moment(timeOfStart);
        let endMoment;
        if (isHardStop) {

            endMoment = moment(timeOfFinish);
        } else {


            endMoment = moment(timeOfStart).add(totalTime, "seconds");
        }

        let startText = RwDateUtils.formatDate(timeOfStart);
        let finishText = RwDateUtils.formatDate(timeOfFinish);
        let sameTime = startMoment.isSame(endMoment);

        if (isHardStart) {

            if (sameTime) {
                spanText = `${startText}`;
            } else {
                spanText = `${startText} to ${finishText}`;
            }
        } else {

            if (isHardStop) {
                spanText = `Finishes at ${finishText}`;
            } else {

                if (sameTime) {


                    spanText = `Time of Optimize`;
                } else {

                    spanText = `Time of Optimize`;
                }
            }
        }


        // if (isHardStart && isHardStop) {
        //   if (sameTime) {
        //     spanText = `${startText}`;
        //   }
        //   else {
        //     spanText = `${startText} to ${finishText}`;
        //   }
        // }
        // else if (isHardStart && !isHardStop) {
        //   if (sameTime) {
        //     spanText = `Specific Start Time \n${startText}`;
        //   }
        //   else {
        //     spanText = `Specific Start Time \n${startText} to ${finishText}`;
        //   }
        // }
        // else if (!isHardStart && isHardStop) {
        //   if (sameTime) {
        //     spanText = `Specific Finish Time \n${startText}`;
        //   }
        //   else {
        //     spanText = `Specific Finish Time \n${startText} to ${finishText}`;
        //   }
        // }
        // else {
        //   if (sameTime) {
        //     spanText = `Time of Optimize: \n${startText}`;
        //   }
        //   else {
        //     spanText = `Time of Optimize: \n${startText} to ${finishText}`;
        //   }
        // }
        return spanText;
    }


    // static getDepartureTimeString(timeOfStart: Date, timeOfFinish: Date, isHardStart: boolean, isHardStop: boolean, totalTime: number): string {
    //   //let nowMoment = moment();
    //   let startMoment = moment(timeOfStart);
    //   let endMoment;
    //
    //   if (isHardStop) {
    //     endMoment = moment(timeOfFinish);
    //   }
    //   else {
    //     endMoment = moment(timeOfStart).add(totalTime, "seconds");
    //   }
    //
    //   let startText = RwDateUtils.formatDate(timeOfStart);
    //   let finishText = RwDateUtils.formatDate(timeOfFinish);
    //
    //   // let startToday = startMoment.format("L") === nowMoment.format("L");
    //   // let endToday = endMoment.format("L") === nowMoment.format("L");
    //   // let startStr = startToday
    //   //   ? startMoment.format("LT")
    //   //   : startMoment.format("L LT");
    //   // let endStr = endToday ? endMoment.format("LT") : endMoment.format("L LT");
    //
    //   let sameTime = startMoment.isSame(endMoment);
    //
    //   if (isHardStart && isHardStop) {
    //     if (sameTime) {
    //       return `Specific Time Frame \n(${startText})`;
    //     }
    //     else {
    //       return `Specific Time Frame \n(${startText} to ${finishText})`;
    //     }
    //   }
    //   else if (isHardStart && !isHardStop) {
    //     if (sameTime) {
    //       return `Specific Start Time \n(${startText})`;
    //     }
    //     else {
    //       return `Specific Start Time \n(${startText} to ${finishText})`;
    //     }
    //   }
    //   else if (!isHardStart && isHardStop) {
    //     if (sameTime) {
    //       return `Specific Finish Time \n(${startText})`;
    //     }
    //     else {
    //       return `Specific Finish Time \n(${startText} to ${finishText})`;
    //     }
    //   }
    //
    //   if (sameTime) {
    //     return `Time of Optimize \n(${startText})`;
    //   }
    //   else {
    //     return `Time of Optimize \n(${startText} to ${finishText})`;
    //   }
    // }


}

export class RwOptResponse {
    complete: boolean;
    route: RwRoute;
    useSockets: boolean;
    error: boolean;
    errorMsg: string;
    jobId: string;
}


