

import {Component, Prop, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwRoute, RwRouteAssignCodes, RwSyncStatus} from "@/app/dem/RwRoute";
import {RwLog} from "@/app/dal/RwLog";
import {RwRouteCopyType} from "@/app/RwEnums";
import RwEventBus from "@/utils/RwEventBus";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwTaskRoutes} from "@/app/dal/RwTaskRoutes";

@Component
export default class RwRouteNew extends Vue {
  @Prop(Boolean) readonly initEmpty: boolean | undefined;
  @Prop(RwRoute) readonly initRoute: RwRoute | undefined;


  //#region Props

  idxTab = 0;
  idxRoute = -1;
  //route: RwRoute;
  searchFilter = "";
  isRouteSelected = false;

  get route() {
    const route = this.initRoute ? this.initRoute : this.globals.activeRoute;
    this.isRouteSelected = !!route;
    return route;
  }


  get globals(): RwGlobals {
    return theGlobals;
  }

  get space(): RwPlannerSpace {
    return thePlanSpace;
  }

  get isEmpty(): boolean {
    return this.initEmpty;
  }

  get isFirstTab(): boolean {
    return this.idxTab === 0;
  }

  get title(): string {
    let title = "New Route";
    switch (this.idxTab) {
        //@formatter:off
      case 0:
        title = this.isEmpty ? 'Get Started' : 'New Route';
        break;
      case 1:
        title = `Copy Route: ${this.route.name}`;
        break;
      case 2:
        title = 'Open Route';
        break;
        //@formatter:on
    }
    return title;
  }

  get filteredRoutes(): RwRoute[] {
    let routes: RwRoute[] = [];
    routes = this.globals.routes;

    if (this.searchFilter) {
      const srch = this.searchFilter.toLowerCase().trim();
      routes = routes.filter(rt => rt.name?.toLowerCase()?.indexOf(srch) !== -1);
    }

    return routes;
  }

  //#endregion


  //#region Navigation

  onCopyRouteClick() {
    this.idxTab = 1;
  }

  onOpenRouteClick() {
    this.idxTab = 2;
  }

  onBackClick() {
    //console.log("onBackClick");
    this.idxTab = 0;
  }

  //#endregion Navigation


  onRouteCreate() {
    this.globals.createNewRoute(this.initRoute);
    this.close();
  }


  onCopyAllStops() {
    const self = this;
    const SOURCE = "RwRouteNew.onCopyAllStops";

    RwPrefUtils.editFromCopy = true;

    //Validate input; Logs show route == null
    if (self.route) {

      //console.log("onCopyAllStops");
      self.globals
          .copyRoute(self.route, RwRouteCopyType.AllStops, [SOURCE])
          .then((copy) => {
            if (copy) {
              self.onRouteNew(copy);
            } else {
              self.globals.showSnack(`Route Copy Failed`, "error");
              RwLog.error(SOURCE, `No copy rt obj on return: \n\n orig route:${JSON.stringify(self.route.toJsonWithAllStops())}`);
            }
          })
          .catch(err => {
            self.globals.showSnack(`Route Copy Failed: ${err.description}`, "error");
            if (this.globals.checkNotHandled(err)) RwLog.error(SOURCE, `Unhandled: Error:${JSON.stringify(err)}\n\n route:${JSON.stringify(self.route.toJsonWithAllStops())}`);
          });
    } else {
      let routeText = self.route!! ? JSON.stringify(self.route.toJsonWithAllStops()) : "missing";
      RwLog.error(SOURCE, `!route, ${routeText}  `);
    }
  }

  onCopyActiveStops() {
    const self = this;
    const SOURCE = "RwRouteNew.onCopyActiveStops";
    //console.log("onCopyActiveStops", self.route.stops);

    RwPrefUtils.editFromCopy = true;

    //Validate input; Logs show route == null
    if (self.route) {


      self.globals
          .copyRoute(self.route, RwRouteCopyType.ActiveStops, [SOURCE])
          .then((copy) => {
            self.onRouteNew(copy);
          })
          .catch(err => {
            self.globals.showSnack(`Route Copy Failed: ${err.description}`, "error");
            if (this.globals.checkNotHandled(err)) RwLog.error(SOURCE, `Unhandled: ${err.description}`);
          });

    } else {
      let routeText = self.route!! ? JSON.stringify(self.route.toJsonWithAllStops()) : "missing";
      RwLog.error(SOURCE, `!route, ${routeText}  `);
    }

  }

  onCopyNoStops() {
    const self = this;
    const SOURCE = "RwRouteNew.onCopyNoStops";

    RwPrefUtils.editFromCopy = true;

    //Validate input; Logs show route == null
    if (self.route) {

      //console.log("onCopyNoStops");
      this.globals
          .copyRoute(self.route, RwRouteCopyType.NoStops, [SOURCE])
          .then((copy) => {
            self.onRouteNew(copy);
          })
          .catch(err => {
            self.globals.showSnack(`Route Copy Failed: ${err.description}`, "error");
            if (this.globals.checkNotHandled(err)) RwLog.error(SOURCE, `Unhandled: ${err.description}`);
          });

    } else {
      let routeText = self.route!! ? JSON.stringify(self.route.toJsonWithAllStops()) : "missing";
      RwLog.error(SOURCE, `!route, ${routeText}  `);
    }

  }


  onRouteNew(route: RwRoute) {
    // console.warn("RwRouteNew.onRouteNew", route ? route.name : "no route");
    //this.space.openRouteInPlanner(route);
    RwEventBus.$emit("onRouteNew", route);
    //this.$emit("onRouteNew", route);
    this.close();
    if (RwPrefUtils.editFromCopy == true) {
      if (!RwPrefUtils.editAfterAdd) {
        this.globals.showRouteEdit(route);
      }
    }
    this.space.checkHistLimitPopups(route);
  }


  escPress(event: Event) {
    //console.log("RwRouteNew.escPress")
    event.stopPropagation();

    this.globals.overrideEmptyState = true;

    //if (!this.isEmpty) {
    this.close();
    //}
  }

  close() {

    this.globals.m_showRouteNew = false;
    //console.log("RwRouteNew.close", this.globals.m_showRouteNew )
  }

  mounted() {
    //console.log("RwRouteNew.mounted initEmpty, initRoute", this.initEmpty, this.initRoute);
    this.idxTab = this.initRoute ? 1 : 0;
    //this.route = this.initRoute ? this.initRoute : this.globals.activeRoute;
  }

}
