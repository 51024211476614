import {RwPrefUtils} from "@/app/utils/RwPrefUtils";

const onboardModule = {
    state: {
        hasOnboarded: RwPrefUtils.hasOnboarded,
        showOnboardSettings: RwPrefUtils.showOnboardSettings,
        showOnboardAddStopArrow: RwPrefUtils.showOnboardAddStopArrow,
        showOnboardAddStopArrowActive: false,
        showOnboardUploadsPopupActive: false,
        showPODTutorialOnOnboard: false,
        showOnboardUploadsPopup: RwPrefUtils.showOnboardUploadsPopup,
    },
    getters: {
        showOnboardSettings(state): boolean {
            return state.showOnboardSettings;
        },

        showOnboardAddStopArrow(state): boolean {
            return state.showOnboardAddStopArrow;
        },

        showOnboardAddStopArrowActive(state): boolean {
            return state.showOnboardAddStopArrowActive;
        },

        showOnboardUploadsPopupActive(state): boolean {
            return state.showOnboardUploadsPopupActive;
        },

        showPODTutorialOnOnboard(state): boolean {
            return state.showPODTutorialOnOnboard;
        },

        showOnboardUploadsPopup(state): boolean {
            return state.showOnboardUploadsPopup;
        },

        hasOnboarded(state): boolean {
            return state.hasOnboarded;
        },
    },
    actions: {
        showOnboardSettings({state, commit}, val: boolean) {
            commit("SHOW_ONBOARD_SETTINGS", val);
        },

        showOnboardAddStopArrow({state, commit}, val: boolean) {
            commit("SHOW_ONBOARD_ADD_STOP_ARROW", val);
        },

        showOnboardAddStopArrowActive({state, commit}, val: boolean) {
            commit("SHOW_ONBOARD_ADD_STOP_ARROW_ACTIVE", val);
        },

        showOnboardUploadsPopupActive({state, commit}, val: boolean) {
            commit("SHOW_ONBOARD_UPLOADS_POPUP_ACTIVE", val);
        },

        showPODTutorialOnOnboard({state, commit}, val: boolean) {
            commit("SHOW_POD_TUTORIAL_ON_ONBOARD", val);
        },

        showOnboardUploadsPopup({state, commit}, val: boolean) {
            commit("SHOW_ONBOARD_UPLOADS_POPUP", val);
        },

        hasOnboarded({state, commit}, hasOnboarded: boolean) {
            commit("HAS_ONBOARDED", hasOnboarded);
        },
    },
    mutations: {
        HAS_ONBOARDED(state, val: boolean) {
            state.hasOnboarded = val;
        },

        SHOW_ONBOARD_SETTINGS(state, val: boolean) {
            state.showOnboardSettings = val;
        },

        SHOW_ONBOARD_ADD_STOP_ARROW(state, val: boolean) {
            state.showOnboardAddStopArrow = val;
        },

        SHOW_ONBOARD_ADD_STOP_ARROW_ACTIVE(state, val: boolean) {
            state.showOnboardAddStopArrowActive = val;
        },

        SHOW_ONBOARD_UPLOADS_POPUP_ACTIVE(state, val: boolean) {
            state.showOnboardUploadsPopupActive = val;
        },

        SHOW_POD_TUTORIAL_ON_ONBOARD(state, val: boolean) {
            state.showPODTutorialOnOnboard = val;
        },

        SHOW_ONBOARD_UPLOADS_POPUP(state, val: boolean) {
            state.showOnboardUploadsPopup = val;
        },
    }
};

export default onboardModule;
