import {RwSked} from "@/app/dem/RwSked";
import {RwTimeWindow} from "@/app/dem/RwTimeWindow";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwPortType} from "@/app/RwEnums";
import store from "@/app/RwGlobals"
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwPin} from "@/app/dem/RwPin";
import thePlanSpace from "@/app/views/planner/RwPlannerSpace";


export class RwSite {

    constructor(json?) {
        if (json != null) {
            this.fromJson(json);
        } else {
            this.siteId = RwSysUtils.guidNew();
            this.accountId = store.orgId;
            this.priority = 0.5;
            this.baseColor = "#79b72a";
        }
    }

    static fromJson(json: Object) {
        let site: RwSite;
        if (json != null) {
            site = new RwSite(json);
        }
        return site;
    }

    static fromJsonArray(jsonArray: Object[]) {
        let sites: RwSite[] = [];
        if (jsonArray != null) {
            for (let key in jsonArray) {
                let jval = jsonArray[key];
                let site = new RwSite(jval);
                sites.push(site);
            }
        }

        return sites;
    }


    //#region Props

    isDeleted = false;
    siteId: string;
    accountId: string;
    name: string;
    address: string;
    lat: number = 0;
    lng: number = 0;
    phone: string;
    priority: number = 0;
    note: string;
    visitTime: number;
    overSvcTime: boolean = false;
    email: string;
    checkDate: Date;
    nameId: number;
    portType: RwPortType;
    isAvail: boolean;
    isSelectedItem: boolean = false;
    baseColor: string;
    //isAvailCached = true;
    // availChecked: number = 0;
    //let cachedProximity = 0.0

    lastHash: number = 0;

    get hasNoteVisible(): string {
        if (this.note && this.note !== "") {
            return "";
        }

        return "display-gone";
    }

    get checkEpoc(): number {
        return this.checkDate ? this.checkDate.getTime() : 0;
    }

    // get imgUrl(): string {
    //   return RwImgUtils.calcSitePinUrl(
    //     !!this.isAvail,
    //     this.priority,
    //     this.baseColor
    //   );
    //   //let url = utils.RwImgUtils.calcPinUrl(RwPinTypes.Site, 0, 0, 0, false, this.priority);
    //   //RwLog.logConsole("Star imgUrl", url);
    //   //return url;
    // }

    _dataUrl: string = "";
    get dataUrl(): string {
        const self = this;

        let pin = RwPin.fromSite(this);
        if (pin) {
            let url = thePlanSpace.globals.idxURL[pin.urlHash];
            if (url && url.length > 0) {
                self._dataUrl = url;
            } else {
                pin.dataUrlAsync()
                    .then(dataUrl => {
                        self._dataUrl = dataUrl;
                        //console.warn("RwStop.dataUrl updated", this._dataUrl);
                    })
            }
        }

        return this._dataUrl;
    }


    // get mapImgUrl(): string {
    //   //xTODO provide availability to calc black pin if not available
    //   //let url = utils.RwImgUtils.calcPinUrl(RwPinTypes.Site, 0, 0, 0, this.isAvail ? true : false, this.priority);
    //   //RwLog.logConsole("Star imgUrl", url);
    //   //return url;
    //
    //   this.isAvail = !!this.isAvail;
    //   //this.isAvail!! = this.isAvail ? true : false;
    //   return RwImgUtils.calcSitePinUrl(this.isAvail, this.priority, this.baseColor, this.isSelectedItem);
    // }

    get lastCheckInText(): string {
        let checkInText = "";
        let lastCheck = this.checkDate;

        if (lastCheck) {

            let today = new Date().getTime();
            let numberDays = today - lastCheck.getTime();
            let timeText = Math.floor((numberDays / (24 * 3600000))).toString();

            checkInText = `${timeText}` + " Days";
            //checkInText = `🗸 ${timeText}`;
        }
        //console.warn("lastCheckInText", checkInText);
        return checkInText;
    }

    violationText(isAvailable: boolean): string {
        let text = isAvailable ? "" : "Closed";
        // if (this.isAvailCached == false) {
        //   text = "Closed";
        // }
        return text;
    }

    //#endregion Props


    clone(): RwSite {
        let newSite = new RwSite(this.toJSON());
        newSite.isSelectedItem = this.isSelectedItem;
        return newSite;
    }

    fromJson(json: Object) {

        for (let key in json) {
            //RwLog.logConsolekey, json[key]);
            switch (key) {
                case "d":
                    this.isDeleted = true;
                    break;
                case "lid":
                    this.siteId = json[key];
                    break;
                case "uid":
                    this.accountId = json[key];
                    break;
                case "nm":
                    this.name = json[key];
                    break;
                case "add":
                    this.address = json[key];
                    break;
                case "lat":
                    this.lat = json[key];
                    break;
                case "lon":
                    this.lng = json[key];
                    break;
                case "phn":
                    this.phone = json[key];
                    break;
                case "pri":
                    this.priority = json[key];
                    break;
                case "note":
                    this.note = json[key];
                    break;
                case "svct":
                    this.visitTime = json[key];
                    break;
                case "email":
                    this.email = json[key];
                    break;
                case "ost":
                    this.overSvcTime = json[key];
                    break;
                case "cdate":
                    this.checkDate = new Date(json[key]);
                    break;
                case "nid":
                    this.nameId = json[key];
                    break;
                case "pt":
                    this.portType = json[key];
                    break;
                case "bc":
                    this.baseColor = json[key];
                    break;
            }
        }


    }

    fromSite(site: RwSite) {
        this.name = site.name;
        this.address = site.address;
        this.lat = site.lat;
        this.lng = site.lng;
        this.phone = site.phone;
        this.priority = site.priority;
        this.note = site.note;
        this.visitTime = site.visitTime;
        this.email = site.email;
        this.overSvcTime = site.overSvcTime;
        this.checkDate = site.checkDate;
        this.nameId = site.nameId;
        this.portType = site.portType;
        this.baseColor = site.baseColor;

        this.lastHash = Date.now();
    }

    toJSON() {
        let json = {
            lid: this.siteId,
            uid: this.accountId,
            nm: this.name,
            add: this.address,
            lat: this.lat,
            lon: this.lng,
            phn: this.phone,
            pri: this.priority,
            bc: this.baseColor
        };

        if (this.overSvcTime) {
            json["ost"] = this.overSvcTime;
        }
        if (this.email) {
            json["email"] = this.email;
        }
        if (this.checkDate) {
            json["cdate"] = this.checkDate.getTime();
        }
        if (this.note) {
            json["note"] = this.note;
        }
        if (this.visitTime) {
            json["svct"] = this.visitTime;
        }
        if (this.nameId) {
            json["nid"] = this.nameId;
        }
        if (this.portType) {
            json["pt"] = this.portType;
        }

        return json;
    }


    isAvailable(timeStart: Date, timeEnd: Date): boolean {
        let isAvail = false;
        //recheck only if we haven't checked in the last 60 seconds
        let now = Date.now();
        let availability = this.getActiveAvailability();
        if (availability != null) {

            let windows = this.getTimeWindows(timeStart, timeEnd);
            if (windows != null && windows.length > 0) {
                isAvail = true;
            } else {
                isAvail = false;
            }
        } else {
            isAvail = true;
        }
        return isAvail;
    }

    getVisitTime(): number {
        let span = (this.visitTime >= 0) ? this.visitTime : RwPrefUtils.visitTime;
        return span;
    }

    getActiveAvailability(): RwSked[] {
        let skeds: RwSked[] = null;
        let weeklys = RwSked.getSiteSkeds();
        if (weeklys != null) {
            weeklys.forEach(weekly => {
                //if(weekly.isActive) {
                let wsid = weekly.siteId;
                if (wsid != null) {
                    if (this.siteId == wsid) {
                        if (skeds == null) {
                            skeds = [];
                        }
                        skeds.push(weekly);
                    }
                }
                //}
            });
        }
        return skeds;
    }

    getTimeWindows(timeStart: Date, timeEnd: Date): RwTimeWindow[] {
        let windows: RwTimeWindow[] = null;

        let skeds = this.getActiveAvailability();
        if (skeds != null) {

            let routeStart = timeStart.getTime();
            let routeEnd = timeEnd.getTime();

            skeds.forEach(sked => {
                let timeWindows = sked.project(timeStart);
                if (timeWindows != null) {
                    timeWindows.forEach(win => {
                        let winStart = win.start.getTime();
                        let winEnd = win.end.getTime();
                        //let winStart: number;
                        //let winEnd: number;

                        //// If end time is before start time (overnight sched)
                        //if (win.start.getTime() > win.end.getTime()) {
                        //    winStart = win.end.getTime();
                        //    winEnd = win.start.getTime();
                        //}
                        //else {
                        //    // Normal sched
                        //    winStart = win.start.getTime();
                        //    winEnd = win.end.getTime();
                        //}

                        let isFullyContained = winStart >= routeStart && winEnd <= routeEnd;
                        let isLeftClipped = winStart <= routeStart && winEnd >= routeStart;
                        let isRightClipped = winStart <= routeEnd && winEnd >= routeEnd;
                        let isHit = isFullyContained || isLeftClipped || isRightClipped;

                        if (isHit) {
                            if (windows == null) {
                                windows = [];
                            }
                            windows.push(win);
                            //RwLog.logConsole("getTimeWindows Hit", win.toString());
                        }
                    });
                }
            });
        } else {
            //RwLog.logConsole("getTimeWindows No TimeWindows");
        }

        return windows;
    }


    toString() {
        return this.toJSON.toString();
    }

}


// checkInCacheTime = 0;
// checkInCached: RwHistory = null;
// getLastCheckIn(): RwHistory {
//   let self = this;
//   let lastCheck: RwHistory = null;
//
//   if (self.checkInCached != null) {
//     let now = Date.now();
//     if (now - this.checkInCacheTime < 60 * 1000) {
//       lastCheck = this.checkInCached;
//     } else {
//       //expired
//       self.checkInCached = null;
//     }
//   }
//
//   if (lastCheck == null) {
//     //let driver = RwApp.model.driver;
//
//     let acts = store.getHistoryForSite(this.siteId);
//     if (acts != null && acts.length > 0) {
//       acts.forEach(act => {
//         if (lastCheck == null) {
//           lastCheck = act;
//         } else {
//           lastCheck = act;
//           self.checkInCacheTime = Date.now();
//         }
//       });
//     }
//   }
//   return lastCheck;
// }
