import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"max-width":"600px","persistent":"","scrollable":"","overlay-opacity":"0.65","overlay-color":"#000","content-class":"dialog-with-scroll"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onEscape.apply(null, arguments)}},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{class:_vm.globals.backColorBar},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,{staticClass:"mt-2",class:_vm.globals.theme},[_c(VTextField,{ref:"search",attrs:{"prepend-inner-icon":"search","placeholder":"Search"},model:{value:(_vm.searchFilter),callback:function ($$v) {_vm.searchFilter=$$v},expression:"searchFilter"}}),_c(VList,{staticClass:"py-0",attrs:{"flat":""}},[_c(VListItemGroup,{attrs:{"color":"primary"}},[(_vm.showNewRoute)?_c(VListItem,{staticClass:"px-3 py-0",on:{"click":_vm.newRoute}},[_c(VListItemIcon,[_c(VIcon,{staticStyle:{"padding":"12px"}},[_vm._v("mdi-plus-thick")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-left"},[_vm._v("New Route")])],1)],1):_vm._e(),_vm._l((_vm.targetRoutes),function(route,i){return _c(VListItem,{key:i,staticClass:"px-3 py-0",on:{"click":function($event){return _vm.onRoutePick(route)}}},[_c(VListItemIcon,[_c(VAvatar,{attrs:{"size":"48"}},[_c(VImg,{attrs:{"src":route.routeIcon}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-left",domProps:{"textContent":_vm._s(route.name)}})],1)],1)})],2)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"xs6":"","text":"","color":"primary"},on:{"click":_vm.onEscape}},[_vm._v(" Cancel ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }