import {RwSite} from "@/app/dem/RwSite";
import {RwModel} from "@/app/dem/RwModel";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import {RwLog} from './RwLog';
import store from "@/app/RwGlobals"
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwError} from "@/app/RwErrors";
import {RwImgUtils} from "@/app/utils/RwImgUtils";

export class RwTaskSites {


    static addSite(site: RwSite): Promise<RwModel> {

        return new Promise<RwModel>(function (resolve, reject) {
            let payload = JSON.stringify(site.toJSON());

            let url = `${RwConstants.CoreUri}/sites/sites?uid=${store.orgId}&sid=${site.siteId}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let deltas = new RwModel();
                    deltas.fromJson(json);
                    resolve(deltas);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }

    static updateSvcTimeSiteAsync(siteIds: string[], svcTime: number): Promise<boolean> {

        return new Promise<boolean>(function (resolve, reject) {
            let payload = JSON.stringify(siteIds);


            let url = `${RwConstants.CoreUri}/ops/updateSitesSvcTime?uid=${store.orgId}&svcTime=${svcTime}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }

    static updateSite(site: RwSite): Promise<RwModel> {
        const SOURCE = "RwTaskSites.updateSite";

        return new Promise<RwModel>(function (resolve, reject) {

            //xTEST: Validate color
            let validatedColor = RwImgUtils.validatedColorHex(site.baseColor);
            if (validatedColor) {
                site.baseColor = validatedColor;

                let payload = JSON.stringify(site.toJSON());
                let url = `${RwConstants.CoreUri}/sites/sites?uid=${store.orgId}&sid=${site.siteId}&ls=${store.lastSyncMS}`;
                dal.callWithToken
                    .put(url, payload, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        if (res.data) {
                            const json = res.data;
                            let deltas = new RwModel();
                            deltas.fromJson(json);
                            resolve(deltas);
                        } else {
                            let status = res.status;
                            RwLog.warn(SOURCE, `POST url:${url} -> ${status} \nAuthToken:${RwPrefUtils.token}  \n\npayload:\n${payload}`)
                            reject(new RwError(status, false));
                        }

                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                const stack = new Error().stack;

                RwLog.error(SOURCE, `Invalid BaseColor: ${JSON.stringify(site.baseColor)}\n\n Stack:${stack}`)
                reject(new RwError(400, false));
            }

        });
    }

    static deleteSites(siteIds: string[]): Promise<RwModel> {
        const SOURCE = "RwTaskSites.deleteSites"

        return new Promise<RwModel>(function (resolve, reject) {

            let payload = JSON.stringify(siteIds);
            let url = `${RwConstants.CoreUri}/sites/sites?uid=${store.orgId}&ls=${store.lastSyncMS}`;

            dal.callWithToken
                .delete(url, {data: payload, timeout: RwConstants.NetTimeout})
                .then(res => {
                    if (res) {
                        if (res.data) {
                            const json = res.data;
                            let deltas = new RwModel();
                            deltas.fromJson(json);
                            resolve(deltas);
                        } else {
                            RwLog.warn(SOURCE, `DELETE url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}  \n\npayload:\n${payload}`)
                            reject(new RwError(res.status, false));
                        }
                    } else {
                        RwLog.error(SOURCE, `DELETE url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}  \n\npayload:\n${payload}`)
                        reject(new RwError(400, false));
                    }
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }
}
