import {RwPin} from "../dem/RwPin";


export class RwGeoPoint {
    lat = 0;
    lng = 0;

    constructor(lat: number, lng: number) {
        this.lat = lat;
        this.lng = lng;
    }
}


export class RwGeoUtils {

    static getPinsInsidePoly(
        polyPoints: H.geo.Point[],
        pins: RwPin[],
        polyBoundBox: H.geo.Rect): RwPin[] {

        var pinsInsideBBox = new Array<RwPin>();
        pins.forEach(p => {
            if (polyBoundBox.containsLatLng(p.lat, p.lng)) {
                pinsInsideBBox.push(p);
            }
        });
        //console.warn("pinsInsideBBox", pinsInsideBBox.length);

        var pinsInsidePoly = new Array<RwPin>();
        pinsInsideBBox.forEach(p => {
            var isInside = RwGeoUtils.isPointInPoly(p, polyPoints);
            if (isInside) {
                pinsInsidePoly.push(p);
            }
        });
        //console.warn("pinsInsidePoly", pinsInsidePoly.length);

        return pinsInsidePoly;
    }


    static isPointInPoly(point: RwPin, poly: H.geo.Point[]) {
        // ray-casting algorithm based on
        // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

        var x = point.lng, y = point.lat;

        var inside = false;
        for (var i = 0, j = poly.length - 1; i < poly.length; j = i++) {
            var xi = poly[i].lng, yi = poly[i].lat;
            var xj = poly[j].lng, yj = poly[j].lat;

            var intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;
    };


}
