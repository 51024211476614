import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"max-width":"600px","persistent":"","scrollable":"","content-class":"dialog-with-scroll","overlay-opacity":"0.65","overlay-color":"#000"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onEscape.apply(null, arguments)}},model:{value:(_vm.globals.m_showColorPicker),callback:function ($$v) {_vm.$set(_vm.globals, "m_showColorPicker", $$v)},expression:"globals.m_showColorPicker"}},[_c(VCard,{staticStyle:{"height":"100%","width":"100%"},attrs:{"height":"100"}},[_c(VToolbar,{attrs:{"dense":"","color":_vm.globals.backColorBar}},[_c(VToolbarTitle,[_vm._v(" Color Picker")]),_c(VSpacer)],1),_c(VCardText,{staticClass:"mt-2",class:_vm.globals.theme,staticStyle:{"height":"calc(100% - 102px)","width":"100%","overflow-y":"auto"}},[_c(VContainer,{staticClass:"mt-4",attrs:{"fill-height":"","fluid":""}},[_c(VRow,{staticClass:"mt-4",attrs:{"align":"center","justify":"center","no-gutters":""}},[_c(VColorPicker,{attrs:{"swatches":_vm.globals.swatches,"flat":"","hide-sliders":"","hide-canvas":"","hide-inputs":"","show-swatches":"","swatches-max-height":"200"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)],1)],1),_c(VDivider),_c(VCardActions,{class:_vm.backColor},[_c(VSpacer),_c(VBtn,{attrs:{"xs6":"","text":""},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }