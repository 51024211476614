import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"search-panel",staticStyle:{"height":"100%","width":"100%"}},[_c(VToolbar,{attrs:{"dense":""}},[(this.showSearchOptions)?_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.onSearchSettings}},[_c(VIcon,[_vm._v("mdi-settings")])],1):_vm._e(),_c(VSpacer),_c(VToolbarTitle,[_vm._v("Search Mode")]),_c(VSpacer),_c(VBtn,{attrs:{"rounded":"","color":"primary","small":""},on:{"click":_vm.onClose}},[_vm._v(" Exit ")])],1),_c('RwSearchView',{attrs:{"enable-search-actions":_vm.enableSearchActions}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }