export class RwSkuInfo {
    active: boolean;
    expires: Date;
    quantity: number;
    sku: string;

    constructor(json?: Object) {
        if (json != null) {
            this.fromJson(json);
        }
    }

    fromJson(json: Object) {
        let skuInfo = new RwSkuInfo();

        for (let key in json) {
            switch (key) {
                case "active":
                    if (json[key] === 1) {
                        this.active = true;
                    } else {
                        this.active = false;
                    }
                    break;
                case "exp":
                case "expires":
                    this.expires = new Date(json[key]);
                    break;
                case "quantity":
                    this.quantity = json[key];
                    break;
                case "sku":
                    this.sku = json[key];
                    break;
            }
        }
    }

    static fromJsonArray(jsonArray: Object[]): RwSkuInfo[] {
        let skuArray = [];
        jsonArray.forEach(json => {
            let newSkuInfo = new RwSkuInfo(json);
            skuArray.push(newSkuInfo);
        });

        return skuArray;
    }

    static toJson(rwSkuInfo: RwSkuInfo) {
        return {
            active: rwSkuInfo.active,
            expires: rwSkuInfo.expires,
            quantity: rwSkuInfo.quantity,
            sku: rwSkuInfo.sku
        };
    }
}
