import {RwRoute, RwSyncStatus} from "@/app/dem/RwRoute";
import {RwUser} from "@/app/dem/RwUser";
import {RwSite} from "@/app/dem/RwSite";
import {RwSked} from "@/app/dem/RwSked";
import {RwHistory} from "@/app/dem/RwHistory";
import {RwStop} from "@/app/dem/RwStop";
import {RwCommit} from "@/app/dem/RwSync";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import {RwEntityTypes, RwTxnTypes} from "@/app/RwEnums";


export class RwModel {


    accountId: string;
    userId: string;
    orgId: string;
    token: string;
    teamName: string;
    teamDomain: string;
    dispatcherName: string;
    userName: string;
    commitList: RwCommit[] = [];
    deltaConflicts: number = 0;

    activeRoute: RwRoute;
    drivers: RwUser[] = [];
    sites: RwSite[] = [];
    routes: RwRoute[] = [];
    skeds: RwSked[] = [];
    history: RwHistory[] = [];
    stops: RwStop[] = [];
    tasks: RwStop[] = [];
    syncStatus: RwSyncStatus[] = [];
    lastSyncMS: number;

    stopHits: number;
    greedyTime: number;
    greedyDist: number;
    maxRouteSize: number;


    get globals(): RwGlobals {
        return theGlobals;
    }

    get lastSync(): Date {
        if (this.lastSyncMS) {
            return new Date(this.lastSyncMS);
        }
        return new Date();
    }

    //#region JSON APIs

    static fromJson(json: JSON): RwModel {
        let model = new RwModel();
        model.fromJson(json);
        return model;
    }


    fromJson(json: JSON) {


        for (let key in json) {
            switch (key) {

                case "maxRouteSize":
                    this.maxRouteSize = json[key];
                    //console.log("RwModel.fromJson", this.maxRouteSize);
                    break;

                // Legacy
                case "dname":
                    this.dispatcherName = json[key];
                    break;

                case "tname":
                    this.teamName = json[key];
                    break;
                case "domain":
                    this.teamDomain = json[key];
                    break;
                case "aid":
                    this.accountId = json[key];
                    break;
                case "TEAM":
                    this.updateTeamFromJson(json[key]);
                    break;
                case "tkn":
                    this.token = json[key];
                    break;
                case "LAST_SYNC":
                    this.lastSyncMS = json[key];
                    break;

                case "drvrs":
                case "DRVRS":
                    // console.warn("FromJsonArray setup:", RwUser.fromJsonArray(json[key]) )
                    this.drivers = RwUser.fromJsonArray(json[key]);
                    break;

                case "acts":
                case "ACTIVITIES":
                    this.history = RwHistory.fromJsonArray(json[key]);
                    this.globals.hist = this.history;
                    break;

                case "routes":
                case "ROUTES":
                    this.routes = RwRoute.fromJsonArray(json[key]);
                    break;

                case "scheds":
                case "skeds":
                case "SCHEDULES":
                    this.skeds = RwSked.fromJsonArray(json[key]);
                    //console.warn("fromJSON in Model, get Skeds:", RwSked.fromJsonArray(json[key]));
                    break;

                case "sites":
                case "LOCATIONS":
                    this.sites = RwSite.fromJsonArray(json[key]);
                    break;

                case "stops":
                case "STOPS": {
                    this.stops = RwStop.fromJsonArray(json[key]);
                    break;
                }

                case "TASKS": {
                    //console.log("RwModel.fromJSON TASKS:", json[key][0]);
                    this.tasks = RwStop.fromJsonArray(json[key]);
                    this.tasks.forEach(t => t.isTask = true);
                    break;
                }

                case "AAROUTE": {
                    this.activeRoute = new RwRoute(json[key]);
                    //self.mergeRoutes([rt]);
                    break;
                }
                case "COMMITS":
                    this.commitList = json[key] as RwCommit[];
                    break;

                case "conflicts":
                    //RwLog.consoleWarn("Merging conflicts:", json[key]);
                    this.deltaConflicts = json[key];
                    break;

                case "nhq": //node hits
                    //RwLog.consoleWarn("Merging conflicts:", json[key]);
                    this.stopHits = json[key];
                    break;

                case "gdtime": //node hits
                    this.greedyTime = json[key];
                    break;

                case "gddist": //node hits
                    this.greedyDist = json[key];
                    break;

                case "STATUS": //node hits
                    this.syncStatus = RwSyncStatus.fromJsonArray(json[key]);
                    break;

            }
        }

        // this.globals.routes.forEach(rt => {
        //   if (rt.routeId === this.globals.orgId) {
        //     this.globals.unassignedStopsRoute = rt;
        //   }
        // });
        // if (this.globals.unassignedStopsRoute) {
        //   this.globals.removeRouteById(this.globals.orgId);
        // }

        // RwLog.warnConsole("RwModel.fromJson _routes count: ", this._routes.length);
        // RwLog.warnConsole("RwModel.fromJson _sites count: ", this._sites.length);
        // RwLog.warnConsole("RwModel.fromJson _acts count: ", this._acts.length);
        // RwLog.warnConsole("RwModel.fromJson scheds count: ", this.scheds.length);
        // RwLog.warnConsole("RwModel.fromJson drivers count: ", this.drivers.length);
        // RwLog.warnConsole("RwModel.fromJson trucks count: ", this.trucks.length);
        // RwLog.warnConsole("RwModel.fromJson zones count: ", this.zones.length);
        //this.updateBadges();
    }


    updateTeamFromJson(json: Object) {
        for (let key in json) {
            switch (key) {
                case "uid":
                    this.userId = json[key];
                    break;
                case "uname":
                    this.userName = json[key];
                    break;
                case "dname":
                    this.dispatcherName = json[key];
                    break;
                case "tname":
                    this.teamName = json[key];
                    break;
                case "domain":
                    this.teamDomain = json[key];
                    break;
                case "aid":
                    this.accountId = json[key];
                    break;
                case "oid":
                    this.orgId = json[key];
                    break;
            }
        }
    }

    //#endregion JSON APIs


    isEmpty(): boolean {
        let isEmpty = true;

        if (this.history && this.history.length > 0) {
            isEmpty = false;
        } else if (this.routes && this.routes.length > 0) {
            isEmpty = false;
        } else if (this.skeds && this.skeds.length > 0) {
            isEmpty = false;
        } else if (this.sites && this.sites.length > 0) {
            isEmpty = false;
        } else if (this.drivers && this.drivers.length > 0) {
            isEmpty = false;
        } else if (this.stops && this.stops.length > 0) {
            isEmpty = false;
        } else if (this.tasks && this.tasks.length > 0) {
            isEmpty = false;
        } else if (this.commitList && this.commitList.length > 0) {
            isEmpty = false;
        }

        return isEmpty;
    }

    get gotConflicts(): boolean {
        return this.deltaConflicts > 0;
    }


    public isCommitted(eid: string, txn: RwTxnTypes) {
        let isCommitted = false;
        if (this.commitList) {
            let commit = this.commitList.find(c => c.eid === eid && c.txn === txn && c.code === 200);
            if (commit) {
                isCommitted = true;
            }
        }
        return isCommitted;
    }


    public findCommits(entityType: RwEntityTypes, txn: RwTxnTypes): RwCommit[] {
        let commits: RwCommit[] = [];
        if (this.commitList) {
            commits = this.commitList.filter(c => c.ntt === entityType && c.txn === txn && c.code === 200);
        }
        //RwLog.logConsole("DeleteRoutesV2: Commit List", result.commitList);
        return commits;
    }

}


