import {RwUser} from "@/app/dem/RwUser";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import {RwLog} from './RwLog';
import {ReplaceDriverError} from "@/app/RwEnums";

export class RwTaskDriver {


    static getSubTeamDrivers(subTeamOrgId: string): Promise<RwUser[]> {
        return new Promise<RwUser[]>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/account/GetSubTeamDrivers?subTeamOrgId=${subTeamOrgId}`;
            //RwLog.consoleLog("getDriver: ", url);

            dal.callWithToken
                .get(url, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let driverList: RwUser[] = [];
                    json.forEach(obj => {
                        driverList.push(RwUser.fromJson(obj));
                    });
                    resolve(driverList);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }


    static moveSubTeamDriver(driverUserId: string, sourceAcctId: string, targetAcctId: string): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/account/MoveSubTeamDriver?driverUserId=${driverUserId}&sourceTeamAccountId=${sourceAcctId}&targetTeamAccountId=${targetAcctId}`;
            //RwLog.consoleLog("getDriver: ", url);
            dal.callWithToken
                .post(url, null, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }


    static replaceDriver(driverUserId: string, newUserName: string, newPassword: string, newName: string): Promise<ReplaceDriverError> {
        return new Promise<ReplaceDriverError>(function (resolve, reject) {

            const passwordEnc = encodeURIComponent(newPassword);
            const uNameEnc = encodeURIComponent(newUserName);
            const newNameEnc = encodeURIComponent(newName);

            const url = `${RwConstants.CoreUri}/account/replaceDriver?driverUserId=${driverUserId}
        &newUserName=${uNameEnc}&newPassword=${passwordEnc}&newName=${newNameEnc}`;

            dal.callWithToken
                .post(url, null, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(res.data.Error as ReplaceDriverError);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }


    static changeDriverPassword(driverUserId: string, driverNewPassword: string): Promise<boolean> {
        const SOURCE = "RwTaskDriver.changeDriverPassword";
        return new Promise<boolean>(function (resolve, reject) {

            let passEnc = encodeURIComponent(driverNewPassword);
            //&subTeamOrgId=${driverOrgId}
            let url = `${RwConstants.CoreUri}/team/changeDriverPassword?userId=${driverUserId}&newPassword=${passEnc}`;
            //RwLog.consoleLog("ChangeDriverPassword: ", url);

            dal.callWithToken
                .post(url, null, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    //console.log("ChangePassword success", res);
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static changeSubTeamDriverPassword(driverUserId: string, driverOrgId: string, driverNewPassword: string): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {

            let passEnc = encodeURIComponent(driverNewPassword);
            let url = `${RwConstants.CoreUri}/team/ChangeSubTeamDriverPassword?userId=${driverUserId}&subTeamOrgId=${driverOrgId}&newPassword=${passEnc}`;
            dal.callWithToken
                .post(url, null, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }


    static changeSubTeamDispatcherPassword(dispatcherUserId: string, dispatcherOrgId: string, driverNewPassword: string): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {

            let passEnc = encodeURIComponent(driverNewPassword);
            let url = `${RwConstants.CoreUri}/team/ChangeSubTeamDispatcherPassword?userId=${dispatcherUserId}&subTeamOrgId=${dispatcherOrgId}&newPassword=${passEnc}`;
            dal.callWithToken
                .post(url, null, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }


    static getDriverAsync(driverId: string, routeId: string): Promise<RwUser> {
        return new Promise<RwUser>(function (resolve, reject) {

            let routeParm = routeId == null ? `` : "rid=" + routeId;
            let url = `${RwConstants.CoreUri}/users/drivers?uid=${driverId}&${routeParm}`;

            dal.callWithToken
                .get(url, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let driver = RwUser.fromJson(json);
                    resolve(driver);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }


    static getDriverInfo(): Promise<RwUser[]> {
        return new Promise<RwUser[]>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/team/driverPositions`;
            dal.callWithToken
                .get(url, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    if (res && res.data && res.data.length > 0) {
                        const json = res.data;
                        let drvrList = RwUser.fromJsonArray(json);
                        resolve(drvrList);
                    } else {
                        resolve([]);
                    }

                })
                .catch((err) => {
                    RwLog.consoleError("WTF: RwTaskDriver test timeout", err);
                    //REFACTOR: UI Code: Move to caller
                    reject(err);
                });
        });
    }


}
