
import moment from "moment";
import {Component, Prop, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import theRouteSpace, {RwRouteSpace} from "@/app/views/routes/RwRouteSpace";
import {RwRoute} from "@/app/dem/RwRoute";
import {RwDateUtils} from "@/app/utils/RwDateUtils";
import {RwLog} from "@/app/dal/RwLog";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwTextUtils} from "@/app/utils/RwTextUtils";
import {RwGeoPoint} from "@/app/utils/RwGeoUtils";
import RwReloPicker from "@/app/views/pickers/RwReloPicker.vue";
import theStore from "@/store";
import thePlanSpace from "@/app/views/planner/RwPlannerSpace";

@Component({
  components: {RwReloPicker}
})
export default class RwRouteEdit extends Vue {
  @Prop(RwRoute) readonly initRoute: RwRoute;


  //#region Props
  idxTab: number = 0;
  routeName = "NaN";

  isRoundTrip = false;
  isHardStart = false;
  isHardStop = false;
  initialName = "";
  initialNote = "";
  initialIsRoundTrip = false;
  initialAvoidHighways = false;
  initialAvoidTolls = false;
  initialAvoidFerries = false
  initialStartTime = null;
  initialFinishTime = null;
  initialIsHardStop = false;
  initialTravelMode = "driving";
  initialOptimizeMode = 2;
  initialIsDynLoc = false;
  initialDriverId = "";
  driverName = "";
  m_startDate = null;
  m_finishDate = null;
  driverId = "";


  // isRoundTrip: boolean = this.selectedRoute.isRoundTrip;
  // isHardStart: boolean = this.selectedRoute.isHardStart;
  // isHardStop: boolean = this.selectedRoute.isHardStop;
  // initialName = this.selectedRoute.name;
  // initialNote = this.selectedRoute.note;
  // initialIsRoundTrip = this.selectedRoute.isRoundTrip;
  // initialAvoidHighways = this.selectedRoute.avoidHighways;
  // initialAvoidTolls = this.selectedRoute.avoidTolls;
  // initialAvoidFerries = this.selectedRoute.avoidFerries;
  // initialStartTime = this.selectedRoute.startTime;
  // initialFinishTime = this.selectedRoute.finishTime;
  // initialIsHardStop = this.selectedRoute.isHardStop;
  // initialTravelMode = this.selectedRoute.travelMode;
  // initialOptimizeMode = this.selectedRoute.optType;
  // initialIsDynLoc = this.selectedRoute.isDynLoc;
  // initialDriverId = this.selectedRoute.driverId;
  // driverName = this.selectedRoute.driverName;

  m_addressEntered: string = "";
  m_addressContinuedEntered: string = "";
  m_cityEntered: string = "";
  m_stateEntered: string = "";
  m_zipEntered: string = "";
  fullAddress: string = "";
  updatedLast: string = "";

  //driverId = "NaN";
  optimizeMode = -1;
  travelMode = "NaN";
  note = "NaN";
  isDynLoc = null;
  startAddress = "";
  lat = 0;
  lng = 0;
  totalTime = 0;
  avoidFerries = null;
  avoidTolls = null;
  avoidHighways = null;
  showAvoidsMenu = false;
  times: string[] = RwDateUtils.genTimeList(24, 0, 30);

  //m_startDate = this.selectedRoute.startTime;
  menuStartTime: boolean = false;
  menuStartDate: boolean = false;
  showStartDatePicker = false;

  //m_finishDate = this.selectedRoute.finishTime;
  menuFinishTime: boolean = false;
  menuFinishDate: boolean = false;
  showFinishDatePicker = false;
  time = "";

  initRouteVals() {
    this.isRoundTrip = this.initRoute.isRoundTrip;
    this.isHardStart = this.initRoute.isHardStart;
    this.isHardStop = this.initRoute.isHardStop;
    this.initialName = this.initRoute.name;
    this.initialNote = this.initRoute.note;
    this.initialIsRoundTrip = this.initRoute.isRoundTrip;
    this.initialAvoidHighways = this.initRoute.avoidHighways;
    this.initialAvoidTolls = this.initRoute.avoidTolls;
    this.initialAvoidFerries = this.initRoute.avoidFerries;
    this.initialStartTime = this.initRoute.startTime;
    this.initialFinishTime = this.initRoute.finishTime;
    this.initialIsHardStop = this.initRoute.isHardStop;
    this.initialTravelMode = this.initRoute.travelMode;
    this.initialOptimizeMode = this.initRoute.optType;
    this.initialIsDynLoc = this.initRoute.isDynLoc;
    this.initialDriverId = this.initRoute.driverId;
    this.driverName = this.initRoute.driverName;
    this.m_startDate = this.initRoute.startTime;
    this.m_finishDate = this.initRoute.finishTime;
    this.driverId = this.initRoute.driverId;
  }

  get globals(): RwGlobals {
    return theGlobals;
  }

  get space(): RwRouteSpace {
    return theRouteSpace;
  }

  get selectedRoute(): RwRoute {
    return this.initRoute;
  }

  get is12H(): boolean {
    return RwDateUtils.is12H;
  }


  get dialogTitle(): string {
    let text: string;
    //@formatter:off
    switch (this.idxTab) {
      case 1:
        text = "Edit Route Time";
        break;
      case 2:
        text = "Edit Start Point";
        break;
      case 3:
        text = "Edit Driver: " + this.driverName;
        break;
      case 4:
        text = "Edit Start Time ";
        break;
      case 5:
        text = "Edit Finish Time ";
        break;

        // case 4: text = "Edit Start Time: " + RwDateUtils.formatDate(this.initRoute.startTime); break;
        // case 5: text = "Edit Finish Time: " + RwDateUtils.formatDate(this.initRoute.finishTime); break;
      case 6:
        text = "Edit Start Location: Address";
        break;
      case 7:
        text = "Edit Start Location: Choose Location";
        break;
      default:
        text = "Edit Route: " + this.initRoute.name;
    }
    //@formatter:on
    return text
  }

  get closeBtnTitle(): string {
    return (this.idxTab == 0) ? "Close" : "Back";
  }

  colorIcon(): string {
    return this.globals.isDarkMode ? "color: white" : "color: grey"
  }

  //#endregion


  //region DateTime Props

  get todaysDate(): Date {
    let date = new Date();
    // console.warn('todays date is: ', date );
    return date;
  }

  get tomorrowsDate(): Date {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    // console.warn('tomorrows date is: ', date );
    return date;
  }

  get startDate(): Date {
    // console.warn('start date is: ', this.m_startDate);
    return this.m_startDate;
  }

  set startDate(val: Date) {
    this.m_startDate = val;
    if (!this.isHardStop) {
      this.m_finishDate = moment(this.startDate).add(RwPrefUtils.routeSpan, "minutes").toDate();
    }
  }

  get isStartOutOfDate(): Boolean {
    let startTime = this.startDate.getTime();
    let todayTime = this.todaysDate.getTime();
    if (todayTime - startTime > 86400000) {
      return true;
    } else {
      return false;
    }
  }

  get startEpoc(): number {
    return this.startDate.getTime();
  }

  get startDateISO(): string {
    let mo = moment(this.startEpoc);
    let text = mo.format("YYYY-MM-DD");
    // console.warn('start date ISO is: ', text);
    //console.log("GET startDateISO", text);
    return text;
  }

  set startDateISO(val: string) {
    let mo = moment(val, "YYYY-MM-DD");
    this.startDate = mo.toDate();
    //console.log("SET startDateISO", val, this.startDate);
  }

  get startDisplayTime(): string {
    let timeText = moment(this.startDate).format("LT");
    //console.log("GET startDisplay", timeText);
    return timeText;
  }

  get startTimeISO(): string {
    let timeText = this.momentToTime(moment(this.startDate));
    //console.log("GET startTime", timeText);
    // console.warn('start time ISO is: ', timeText);
    return timeText;
  }

  set startTimeISO(val: string) {
    let day = moment(this.startDate).startOf('day');
    let mins = this.minsFromTime(val);
    this.startDate = day.add(mins, "minutes").toDate();
    //console.log("SET startTimeISO", val, this.startDate);
  }

  get formattedFinishDate(): string {
    return RwDateUtils.formatDate(this.finishDate);
  }

  get finishDate(): Date {
    return this.m_finishDate;
  }

  set finishDate(val: Date) {
    //this.isDirty = true;
    this.m_finishDate = val;
  }

  get finishEpoc(): number {
    return this.finishDate.getTime();
  }

  get finishDateISO(): string {
    let mo = moment(this.finishEpoc);
    let text = mo.format("YYYY-MM-DD");
    //console.log("GET startISO", text);
    return text;
  }

  set finishDateISO(val: string) {
    let mo = moment(val, "YYYY-MM-DD");
    this.finishDate = mo.toDate();
    //console.log("SET startISO", val, this.startDate);
  }

  get finishDisplayTime(): string {
    let timeText = moment(this.finishDate).format("LT");
    //console.log("GET startDisplay", timeText);
    return timeText;
  }

  get finishTimeIso(): string {
    let timeText = this.momentToTime(moment(this.finishDate));
    //console.log("GET startTime", timeText);
    return timeText;
  }


  set finishTimeIso(val: string) {
    let day = moment(this.finishDate).startOf('day');
    let mins = this.minsFromTime(val);
    this.finishDate = day.add(mins, "minutes").toDate();
    //console.log("SET finishTimeIso", val, this.finishDate);
    // let mins = this.minsFromTime(val);
    // this.skedFinishMins = mins;
  }

  updateStartTime(val: number) {
    switch (val) {
        //today's date, same time
      case 0:
        let oldStartTime = this.startTimeISO;
        let newStartDate = moment(this.todaysDate.getTime()).format("YYYY-MM-DD")
        this.startDateISO = newStartDate;
        this.startTimeISO = oldStartTime;
        break;
        //tomorrow's date, same time
      case 1:
        let oldStartTime2 = this.startTimeISO;
        let newStartDate2 = moment(this.tomorrowsDate.getTime()).format("YYYY-MM-DD")
        this.startDateISO = newStartDate2;
        this.startTimeISO = oldStartTime2;
        break;
        break;
        //set to Dynamic Start
      case 2:
        this.isDynStart = true;
        break;
    }
  }

  //endregion DateTime Props


  //region Attribute Props


  get optModeText() {
    let optMode = (this.optimizeMode == -1) ? this.initRoute.optType : this.optimizeMode;
    this.optimizeMode = optMode;
    return (this.optimizeMode === 2) ? "Fastest" : "Shortest";
  }

  get roundTripText() {
    let roundTrip = (this.isRoundTrip == null) ? this.initRoute.isRoundTrip : this.isRoundTrip;
    this.isRoundTrip = roundTrip;
    return (this.isRoundTrip) ? "Round Trip" : "One Way";
  }

  get avoidsText() {
    let avoidFerriesIsSelected = (this.avoidHighways == null) ? this.initRoute.avoidFerries : this.avoidFerries;
    let avoidTollsIsSelected = (this.avoidHighways == null) ? this.initRoute.avoidTolls : this.avoidTolls;
    let avoidHighwaysIsSelected = (this.avoidHighways == null) ? this.initRoute.avoidHighways : this.avoidHighways;

    this.avoidFerries = avoidFerriesIsSelected;
    this.avoidTolls = avoidTollsIsSelected;
    this.avoidHighways = avoidHighwaysIsSelected;
    let str = "Avoid: ";
    str += (this.avoidTolls) ? "T " : "";
    str += (this.avoidHighways) ? "H " : "";
    str += this.avoidFerries ? "F " : "";
    str += (str == "Avoid: ") ? "None" : "";
    return str.trim();
  }

  travelIcon(travelString: string) {
    let url: string;
    switch (travelString.toLowerCase()) {
        //@formatter:off
      case "driving":
        url = "mdi-car";
        break;
      case "biking":
      case "bicycling":
        url = "mdi-bike";
        break;
      case "walking":
        url = "mdi-walk";
        break;
      default:
        url = "mdi-car";
        break;
        //@formatter:on
    }
    return url
  }

  get travelModeText() {
    let travelModeIsSelected = (this.travelMode == "NaN") ? this.initRoute.travelMode : this.travelMode;
    this.travelMode = travelModeIsSelected;
    //console.warn(travelModeIsSelected)
    return RwRoute.getTravelModeText(this.travelMode);
  }


  //endregion Attributes


  //region Core Props

  get isDynStart(): boolean {
    let hardStart = (this.isHardStart == null) ? this.initRoute.isHardStart : this.isHardStart;
    this.isHardStart = hardStart;
    return !this.isHardStart;
  }

  set isDynStart(val: boolean) {
    this.isHardStart = !val;
  }

  get isDynStop(): boolean {
    let hardStop = (this.isHardStop == null) ? this.initRoute.isHardStop : this.isHardStop;
    this.isHardStop = hardStop;
    return !this.isHardStop;
  }

  set isDynStop(val: boolean) {
    this.isHardStop = !val;
  }


  get driverUrl(): string {
    return (this.idxTab == 3) ? this.globals.findDriver(this.initRoute.driverId).imageUrl : this.initRoute.routeIcon
  }


  get routeStartTimeText() {
    //RwLog.consoleLog("reCalc planned time", this.skedStart, this.skedEnd);
    return RwRoute.getRouteSpanText(
        this.startDate,
        this.finishDate,
        this.isHardStart,
        this.isHardStop,
        this.totalTime
    );
  }

  //
  // get formattedEndTime(){
  //   return RwDateUtils.formatDate(this.route.startTime);
  // }


  get driverText(): string {
    let text = "";
    if (this.initRoute) {

      if (!this.initRoute.driverId || this.initRoute.driverId.length === 0) {
        this.driverId = this.globals.dispatcher.userId;
        this.initRoute.driverId = this.driverId;
      }

      let drivers = this.globals.drivers;
      let gotDrivers = drivers && drivers.length > 0;
      if (gotDrivers) {
        let driver = this.globals.findDriver(this.driverId);
        if (driver) {
          this.driverId = driver.userId;
          this.driverName = driver.name;
          text = driver.formatDisplayName;
        } else {
          text = "Driver Not Found"
        }
      } else {
        text = "No Drivers"
      }
    }
    return text;
  }

  // get driverText(): string {
  //   let text: string;
  //   text = (!this.drivers) ? "No Driver (array empty)" : "";
  //   let driverIdSelected = (this.driverId == "NaN") ? this.selectedRoute.driverId : this.driverId;
  //   if (text == "") {
  //     this.selectedRoute.driverId = (this.selectedRoute.driverId) ? this.selectedRoute.driverId : this.globals.dispatcher.userId;
  //     this.driverId = driverIdSelected;
  //     let driver = this.globals.findDriver(this.driverId);
  //     this.driverName = driver.name;
  //     text = (driver) ? driver.formatDisplayName : "No Driver Found";
  //   }
  //   return text;
  // }


  get startPointText() {
    let text: string;
    if (this.isDynLoc === null) {
      this.isDynLoc = this.selectedRoute.isDynLoc
    }
    if (this.isDynLoc === true) {
      text = "Location of Optimize (GPS)";
    } else {
      let coordText = RwTextUtils.formatCoords(this.centerLat, this.centerLng);
      text = (this.startAddress && this.startAddress.length > 0) ? this.startAddress : coordText;
    }
    // if (this.updatedLast == "ADDRESS"){
    //   text = this.fullAddress;
    // }
    return text
  }


  get routeNameInput(): string {
    let routeNameIsSelected = (this.routeName == "NaN") ? this.selectedRoute.name : this.routeName;
    this.routeName = routeNameIsSelected;
    return this.routeName;
  }

  set routeNameInput(nameInput: string) {
    this.routeName = nameInput;
  }

  get noteInput(): string {
    let noteInput = (this.note == "NaN") ? this.selectedRoute.note : this.note;
    this.note = noteInput;
    return this.note;
  }

  set noteInput(userNote: string) {
    this.note = userNote;
  }

  //#endregion


  //region Helper Ops

  private minsFromTime(val: string): number {
    let startOfDay = moment().startOf('day');
    let time = moment(val, 'HH:mm');
    let mins = time.diff(startOfDay, "minutes");
    return mins
  }

  private momentToTime(mo: moment.Moment) {
    let timeText = mo.format("HH:mm");
    return timeText;
  }

  //endregion Helper Ops


  //#region Commit Ops

  validateTimeInterval(): boolean {
    return this.startDate < this.finishDate;
  }

  driverSelect(driverId: string) {
    this.driverId = driverId;
    this.driverName = this.globals.findDriver(this.selectedRoute.driverId).name;
    this.idxTab = 0;
  }

  onCommit(event: Event) {
    //console.log("RwRouteEdit.onCommit");
    event.stopPropagation();
    this.closeWithSave(true);
  }

  onApply() {
    if (this.idxTab != 0 && this.idxTab < 4) {
      this.idxTab = 0;
    } else if (this.idxTab == 4 || this.idxTab == 5) {
      this.idxTab = 0;
    } else if (this.idxTab == 6) {
      this.fullAddress = this.m_addressEntered + this.m_addressContinuedEntered + ", " + this.m_cityEntered + ", " + this.m_stateEntered + ", " + this.m_zipEntered;
      this.updatedLast = "ADDRESS";
      this.idxTab = 0;
    } else if (this.idxTab == 7) {
      this.updatedLast = "COORDINATES";
      this.idxTab = 0;
    }
  }

  onCancel(event: Event) {
    if (this.idxTab != 0 && this.idxTab < 4) {
      this.idxTab = 0;
    } else if (this.idxTab == 4 || this.idxTab == 5) {
      this.idxTab = 1;
    } else if (this.idxTab == 6 || this.idxTab == 7) {
      this.idxTab = 2;
    } else {
      event.stopPropagation();
      //this.globals.selectedRoute = (this.space.isNewRoute) ? null : this.globals.selectedRoute;
      //this.space.isNewRoute = false;
      //this.closeWithSave(false);
      this.close();
    }
  }


  closeWithSave(save: boolean) {
    let self = this;
    let SOURCE = "RwRouteEdit.closeWithSave";
    //console.log(SOURCE, save, this.space.isNewRoute);
    if (save) {
      let finalName = this.routeName ? this.routeName : this.selectedRoute.name;
      this.selectedRoute.name = finalName;
      this.selectedRoute.note = this.note;
      this.selectedRoute.isRoundTrip = this.isRoundTrip;
      this.selectedRoute.avoidHighways = this.avoidHighways;
      this.selectedRoute.avoidTolls = this.avoidTolls;
      this.selectedRoute.avoidFerries = this.avoidFerries;

      if (this.updatedLast == "COORDINATES") {
        this.selectedRoute.address = ""
      }

      this.selectedRoute.startTime = this.startDate;
      //this.selectedRoute.setFinishDate("closeWithSave", this.finishDate);
      this.selectedRoute.finishTime = this.finishDate;
      //console.warn("RwRouteEdit.closeWithSave startTime", this.selectedRoute.startTime, this.selectedRoute.finishTime);

      this.selectedRoute.isHardStart = this.isHardStart;
      this.selectedRoute.isHardStop = this.isHardStop;
      this.selectedRoute.travelMode = this.travelMode;
      this.selectedRoute.optType = this.optimizeMode;
      this.selectedRoute.isDynLoc = this.isDynLoc;
      this.selectedRoute.driverId = this.driverId;

      this.selectedRoute.lat = this.centerLat;
      this.selectedRoute.lng = this.centerLng;
      this.globals.selectedRoute = this.selectedRoute;

      this.globals.updateRouteDB(this.selectedRoute, false)
          .then(() => {
            // this.globals.routeToEdit = null;
            // this.space.selectedRouteSpace = null;
            // self.globals.showRouteEdit = false;
            if (thePlanSpace.openRoutes.length === 1 && thePlanSpace.openRoutes[0].routeId === this.selectedRoute.routeId) {
              thePlanSpace.openRoutes = [this.selectedRoute];
            }
            self.close()
            this.globals.targetFocusId = this.selectedRoute.routeId;
            self.globals.showSnack("Route Updated", "success");
          })
          .catch(err => {
            self.rollbackRoute();
            RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
            this.globals.showSnack("Failed to Update Route", "error");
          });


      //DEFER: Add rest of fields

    } else {
      this.close()
    }
  }

  close() {
    theStore.dispatch("showRouteEdit", false).catch();
  }

  rollbackRoute() {
    this.selectedRoute.name = this.initialName;
    this.selectedRoute.note = this.initialNote;
    this.selectedRoute.isRoundTrip = this.initialIsRoundTrip;
    this.selectedRoute.avoidHighways = this.initialAvoidHighways;
    this.selectedRoute.avoidTolls = this.initialAvoidTolls;
    this.selectedRoute.avoidFerries = this.initialAvoidFerries;
    this.selectedRoute.startTime = this.initialStartTime;
    //this.selectedRoute.setFinishDate("rollbackRoute", this.initialFinishTime);
    this.selectedRoute.finishTime = this.initialFinishTime;
    this.selectedRoute.isHardStop = this.initialIsHardStop;
    this.selectedRoute.travelMode = this.initialTravelMode;
    this.selectedRoute.optType = this.initialOptimizeMode;
    this.selectedRoute.isDynLoc = this.initialIsDynLoc;
    this.selectedRoute.driverId = this.initialDriverId;
  }

  //#endregion Commit Ops


  centerLat = (this.selectedRoute.lat) ? this.selectedRoute.lat : RwPrefUtils.defaultLat;
  centerLng = (this.selectedRoute.lng) ? this.selectedRoute.lng : RwPrefUtils.defaultLng;


  onReloPick(coord: RwGeoPoint) {
    //console.log("RwApp.onReloPick coord", coord);
    if (!(coord.lat == 0 && coord.lng == 0)) {
      this.globals.onReloPick(coord);
    } else {
      this.globals.showSnack('Default start cannot be (0,0)', 'error');
    }
  }

  showRelo() {
    let self = this;
    let SOURCE = "RwRouteEdit.showRelo";
    this.globals.routeStartLocationRelo(this.centerLat, this.centerLng)
        .then((coord) => {
          this.centerLat = coord[0];
          this.centerLng = coord[1];
          this.updatedLast = "COORDINATES";
          this.idxTab = 0;
        })
        .catch(err => {

        })

  }

  mounted() {
    if (this.initRoute) {
      //console.log("RwRouteEdit.mounted", this.initRoute.name)
      this.initRouteVals();
      if (RwPrefUtils.editFromCopy) {
        switch (this.globals.updateStartPref) {
          case 0:
            RwPrefUtils.editFromCopy = false;
            break;
          case 1: {
            RwPrefUtils.editFromCopy = false;
            if (this.isStartOutOfDate == true) {
              this.updateStartTime(0);
            }

          }
            break;
          case 2: {
            RwPrefUtils.editFromCopy = false;
            if (this.isStartOutOfDate == true) {
              this.updateStartTime(1);
            }

          }
            break;
          case 3: {
            RwPrefUtils.editFromCopy = false;
            if (this.isStartOutOfDate == true) {
              this.updateStartTime(2);
            }

          }
            break;
        }
      }

    } else {
      RwLog.consoleError("RwRouteEdit.mounted", "initRoute == null")
    }
  }

}

