import {RwPushNoteType} from "@/app/RwEnums";

export class FirebaseV1SendData {
    public title: string;
    public body: string;
    public rtid: string;
    //public routeIds: string[];
    public routeIdCsv: string;
    public icontype: string;
    public action: string;
    public msgId: string;
}

export class RwPushNote {
    IsTopicMsg: boolean;
    Topic: string;
    UserId: string;
    PushType: number;
    Title: string;
    Body: string;
    IconType: string;
    Action: string;
    Type: RwPushNoteType;

    /* Extra info */
    RouteId: string;


}


export class RwPushResp {

    static fromJsonArray(jsonArray: Object): RwPushResp[] {
        let respList: RwPushResp[] = [];
        if (jsonArray != null) {
            for (let key in jsonArray) {
                let val = jsonArray[key];
                let resp = RwPushResp.fromJson(val);
                respList.push(resp);
            }
        }
        return respList;
    }

    static fromJson(json: JSON): RwPushResp {
        let resp = new RwPushResp();
        resp.fromJson(json);
        return resp;
    }

    userId: string;
    pushType: number;
    code: number;


    fromJson(json: JSON) {
        for (let key in json) {
            switch (key) {
                //@formatter:off
                case "tarId":
                    this.userId = json[key];
                    break;
                case "noteType":
                    this.pushType = json[key];
                    break;
                case "code":
                    this.code = json[key];
                    break;
                //@formatter:on
            }
        }
    }
}


