
import {Component, Prop, Vue} from "vue-property-decorator";
import {RwTaskSkeds} from "@/app/dal/RwTaskSkeds";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import theSkedSpace, {RwSkedSpace} from "@/app/views/skeds/RwSkedSpace";
import {RwSked} from "@/app/dem/RwSked";
import {RwLog} from "@/app/dal/RwLog";
import moment from "moment";
import theStore from "@/store";


@Component
export default class RwSkedEdit extends Vue {
  @Prop(RwSked) readonly initSked!: RwSked;

  MillisInMin = 60000;

  priority = 0;

  skedStartMins = 0;//this.selectedSked.timeStart / this.MillisInMin;
  skedFinishMins = 0; //this.selectedSked.timeFinish / this.MillisInMin;
  menuStart = false;
  menuFinish = false;
  time = "";
  name = "";

  skedType = -1;
  origImgUrl = "";
  origStartTime = 0;
  origEndTime = 0
  origName = "";
  origPriority = 0;
  origDuration = 0;

  init() {
    if (this.initSked) {
      this.skedStartMins = this.selectedSked.timeStart / this.MillisInMin;
      if (!this.selectedSked.timeFinish) {
        if (this.selectedSked.duration) {
          this.skedFinishMins = (this.selectedSked.timeStart + this.selectedSked.duration) / this.MillisInMin
        }
      } else {
        this.skedFinishMins = this.selectedSked.timeFinish / this.MillisInMin;
      }

      this.name = this.selectedSked.name;
      this.menuStart = false;
      this.menuFinish = false;
      this.skedType = this.selectedSked.type;
      this.origImgUrl = this.selectedSked.imgUrl;
      this.origStartTime = this.selectedSked.timeStart;
      this.origEndTime = this.selectedSked.timeFinish;
      this.origName = this.selectedSked.name;
      this.origPriority = this.selectedSked.priority;
      this.origDuration = this.selectedSked.duration;
      this.priority = this.selectedSked.priority * 10;
    }
  }

  get skedColor(): string {
    let color: string = "";
    switch (this.priority) {
        //@formatter:off
      case 1:
        color = "#01aee3";
        break;
      case 2:
        color = "#06b29e";
        break;
      case 3:
        color = "#01de7e";
        break;
      case 4:
        color = "#01e248";
        break;
      case 5:
        color = "#05cd3a";
        break;
      case 6:
        color = "#74dd3f";
        break;
      case 7:
        color = "#d7eb40";
        break;
      case 8:
        color = "#f0a732";
        break;
      case 9:
        color = "#eb5422";
        break;
      case 10:
        color = "#d1041c";
        break;
      default:
        break;
        //@formatter:on
    }
    return color
  }

  //#region time picker variables

  get startMoment(): moment.Moment {
    return moment().startOf('day').add(this.skedStartMins, "minutes");
  }

  get finishMoment(): moment.Moment {
    return moment().startOf('day').add(this.skedFinishMins, "minutes");
  }

  get startDisplay(): string {
    return this.startMoment.format("LT");
  }

  get finishDisplay(): string {
    let timeText: string;
    if (this.skedStartMins < this.skedFinishMins) {
      timeText = this.finishMoment.format("LT");
    } else {
      timeText = this.finishMoment.format("LT") + " (next day)";
    }
    //console.log("finishDisplay", this.skedStartMins, this.skedFinishMins, timeText);
    return timeText;
  }

  get startTime(): string {
    let timeText = this.momentToTime(this.startMoment);
    //console.log("GET startTime", timeText);
    return timeText;
  }

  set startTime(val) {
    this.skedStartMins = this.minsFromTime(val);
  }

  get finishTime(): string {
    return this.finishMoment.format("HH:mm");
  }

  set finishTime(val: string) {
    this.skedFinishMins = this.minsFromTime(val);
  }

  private minsFromTime(val: string): number {
    let startOfDay = moment().startOf('day');
    let time = moment(val, 'HH:mm');
    let mins = time.diff(startOfDay, "minutes");
    return mins
  }

  private momentToTime(mo: moment.Moment) {
    let timeText = mo.format("HH:mm");
    return timeText;
  }


  //#endregion getters setters time pickers

  get skedSpace(): RwSkedSpace {
    return theSkedSpace;
  }

  get globals(): RwGlobals {
    return theGlobals;
  }

  get selectedSked(): RwSked {
    return this.initSked;
    //return this.skedSpace.selectedSked;
  }

  //#region save/close logic
  onEnter(event: Event) {
    event.stopPropagation();
    this.saveChanges();
  }

  onEscape(event: Event) {
    event.stopPropagation();
    this.close();
  }

  saveChanges() {
    let SOURCE = "RwSkedEdit.saveChanges";
    this.selectedSked.timeStart = this.skedStartMins * this.MillisInMin
    this.selectedSked.timeFinish = this.skedFinishMins * this.MillisInMin
    this.selectedSked.duration = this.calculateDuration(this.selectedSked.timeStart, this.selectedSked.timeFinish);

    this.selectedSked.name = this.name;
    this.selectedSked.priority = this.priority / 10;
    this.commitUpdate(SOURCE);
  }

  commitUpdate(SOURCE: string) {
    RwTaskSkeds.updateSked(this.selectedSked)
        .then(() => {
          this.globals.showSnack("Schedule updated", "success");
          //self.globals.showSkedEdit = false;
          this.close()

        })
        .catch(err => {
          this.rollbackSked();
          RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
          this.globals.showSnack("Schedule failed to Update", "error")
        });
  }

  close() {
    theStore.dispatch("showSkedEdit", false).catch();
  }

  //#endregion save/close logic

  //#region resetInitials

  calculateDuration(timeStartMS: number, timeEndMS: number) {
    if (timeStartMS <= timeEndMS) {
      return timeEndMS - timeStartMS;
    } else {
      const endOfDayMS = 24 * 60 * 1000;
      return endOfDayMS - timeEndMS + timeStartMS;
    }
  }

  rollbackSked() {
    this.selectedSked.timeStart = this.origStartTime;
    //console.warn("RwSked.fromSked", this.selectedSked.timeStart);
    this.selectedSked.timeFinish = this.origEndTime;
    this.selectedSked.duration = this.origDuration;
    this.selectedSked.name = this.origName;
    this.selectedSked.priority = this.origPriority;
  }

  mounted() {
    if (this.initSked) {
      this.init();
    }
  }

  //#endregion resetInitials


}

