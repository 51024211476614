import theStore from "@/store";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { PODDefaultSettings } from "@/app/dem/ProofOfDelivery/ProofOfDeliveryEnums";

export class RwSettingSpace {

    get globals(): RwGlobals {
        return theGlobals;
    }

    //
    //
    // get avType(): number {return theStore.getters.avInfo.avType;}
    // set avType(val: number) {theStore.dispatch("avInfo.avType", val);}
    //
    // get avColor(): string {return theStore.getters.avInfo.avColor;}
    // set avColor(val: string) {theStore.dispatch("avColor", val);}
    //
    // get avInits(): string {return theStore.getters.avInfo.avInits;}
    // set avInits(val: string) {theStore.dispatch("avInits", val);}


    get stopColor(): string {
        return theStore.getters.stopColor;
    }

    set stopColor(val: string) {
        theStore.dispatch("stopColor", val);
    }

    get taskColor(): string {
        return theStore.getters.taskColor;
    }

    set taskColor(val: string) {
        theStore.dispatch("taskColor", val);
    }

    get siteColor(): string {
        return theStore.getters.siteColor;
    }

    set siteColor(val: string) {
        theStore.dispatch("siteColor", val);
    }

    get optMode(): number {
        return theStore.getters.optMode;
    }

    set optMode(val: number) {
        theStore.dispatch("optMode", val);
    }

    get travelMode(): string {
        return theStore.getters.travelMode;
    }

    set travelMode(val: string) {
        theStore.dispatch("travelMode", val);
    }

    get isRoundTrip(): boolean {
        return theStore.getters.isRoundTrip;
    }

    set isRoundTrip(val: boolean) {
        theStore.dispatch("isRoundTrip", val);
    }

    get avoids(): string[] {
        return theStore.getters.avoids;
    }

    set avoids(val: string[]) {
        theStore.dispatch("avoids", val);
    }

    get distUnits(): number {
        return theStore.getters.distUnits;
    }

    set distUnits(val: number) {
        theStore.dispatch("distUnits", val);
    }

    get visitTime(): number {
        return theStore.getters.visitTime;
    }

    set visitTime(val: number) {
        theStore.dispatch("visitTime", val);
    }

    get routeTime(): number {
        return theStore.getters.routeTime;
    }

    set routeTime(val: number) {
        theStore.dispatch("routeTime", val);
    }

    get startLat(): number {
        return theStore.getters.startLat;
    }

    set startLat(val: number) {
        theStore.dispatch("startLat", val);
    }

    get startLng(): number {
        return theStore.getters.startLng;
    }

    set startLng(val: number) {
        theStore.dispatch("startLng", val);
    }

    get isDynPoint(): boolean {
        return theStore.getters.isDynPoint;
    }

    set isDynPoint(val: boolean) {
        theStore.dispatch("isDynPoint", val);
    }

    get startFriendlyName(): string {
        return theStore.getters.startFriendlyName;
    }

    set startFriendlyName(val: string) {
        theStore.dispatch("startFriendlyName", val);
    }

    get registerEmail(): string {
        return theStore.getters.registerEmail;
    }

    set registerEmail(val: string) {
        theStore.dispatch("registerEmail", val);
    }

    get proofOfDelivery(): PODDefaultSettings {
        return theStore.getters.proofOfDelivery;
    }

    set proofOfDelivery(val: PODDefaultSettings) {
        theStore.dispatch("proofOfDelivery", val);
    }

    // get showEditDefault(): boolean{return theStore.getters.showEditDefault;}
    // set showEditDefault(val: boolean){theStore.dispatch("showEditDefault", val);}
    //
    // get showStopDefault(): boolean{return theStore.getters.showStopDefault;}
    // set showStopDefault(val: boolean){theStore.dispatch("showStopDefault", val);}
    //
    // get showTaskDefault(): boolean{return theStore.getters.showTaskDefault;}
    // set showTaskDefault(val: boolean){theStore.dispatch("showTaskDefault", val);}
    //
    // get showSiteDefault(): boolean{return theStore.getters.showSiteDefault;}
    // set showSiteDefault(val: boolean){theStore.dispatch("showSiteDefault", val);}
    //
    // get showOptDefault(): boolean{return theStore.getters.showOptDefault;}
    // set showOptDefault(val: boolean){theStore.dispatch("showOptDefault", val);}
    //
    // get showTravelDefault(): boolean{return theStore.getters.showTravelDefault;}
    // set showTravelDefault(val: boolean){theStore.dispatch("showTravelDefault", val);}
    //
    // get showRoundTripDefault(): boolean{return theStore.getters.showOneWayDefault;}
    // set showRoundTripDefault(val: boolean){theStore.dispatch("showOneWayDefault", val);}
    //
    // get showAvoidsDefault(): boolean{return theStore.getters.showAvoidsDefault;}
    // set showAvoidsDefault(val: boolean){theStore.dispatch("showAvoidsDefault", val);}
    //
    // get showUnitDefault(): boolean{return theStore.getters.showUnitDefault;}
    // set showUnitDefault(val: boolean){theStore.dispatch("showUnitDefault", val);}
    //
    // get showSvcTimeDefault(): boolean{return theStore.getters.showSvcTimeDefault;}
    // set showSvcTimeDefault(val: boolean){theStore.dispatch("showSvcTimeDefault", val);}
    //
    // get showRouteTimeDefault(): boolean{return theStore.getters.showRouteTimeDefault;}
    // set showRouteTimeDefault(val: boolean){theStore.dispatch("showRouteTimeDefault", val);}
    //
    // get showCenterDefault(): boolean{return theStore.getters.showCenterDefault;}
    // set showCenterDefault(val: boolean){theStore.dispatch("showCenterDefault", val);}
    //
    // get showStartDefault(): boolean{return theStore.getters.showStartDefault;}
    // set showStartDefault(val: boolean){theStore.dispatch("showStartDefault", val);}
}

export default new RwSettingSpace();