export interface MigrateLegacyToFlexResult {
    PaymentErrorMsg: string;
    LegacyCancelled: boolean;
    Error: MigrateError;
}

export enum MigrateError {
    None = 0,
    AuthError = 1,
    NoLegacySub = 2,
    InvalidRole = 3,
    InvalidModel = 4,
    FlexExists = 5,
    ChargifySubNotFound = 6,
    PaymentIssue = 7,
    ChargifyCreateSubError = 8,
    ChargifyDriverCompError = 9,
    RWPurchCreateError = 10
}