import moment from "moment";
import router from "@/router";
import theStore from "@/store";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import {RwPin} from '@/app/dem/RwPin';
import {RwSearchResult} from '@/app/dem/RwSearchResult';
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {
    RwDistUnits,
    RwHistTypes,
    RwMultiMode,
    RwMutiModeTool,
    RwPinTypes,
    RwSearchType,
    RwStopFilterType
} from "@/app/RwEnums";
import {RwRoute} from "@/app/dem/RwRoute";
import {RwStop} from "@/app/dem/RwStop";
import {RwSite} from "@/app/dem/RwSite";
import {RwHistory} from "@/app/dem/RwHistory";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwLog} from "@/app/dal/RwLog";
import {RwStep} from "@/app/dem/RwStep";
import dal from "@/app/dal/RwDal";
import {RwTaskOptimize} from "@/app/dal/RwTaskOptimize";
import {RwDateUtils} from "@/app/utils/RwDateUtils";
import Vue from "vue";
import {RwTaskBillingProfile} from "@/app/dal/RwTaskBillingProfile";
import RwTotalStopCount from "@/app/dem/RwCountRouteStops";
import RwAddReport from "@/app/dem/RwAddReport";
import {RwError} from "@/app/RwErrors";
import PurchSpace from "@/app/views/account/purch/RwPurchSpace";
import { RwTaskHistory } from "@/app/dal/RwTaskHistory";

export class RwPlannerSpace {


    //#region Props


    isOptimizing: Boolean = false;
    maxRouteSize: number = 8;
    driverName: string = "Driver";

    polySelectOptions = {
        volatility: true,
        style: {
            fillColor: "rgba(22,22,22,0.5)",
            strokeColor: "rgba(226,0,79,1)",
            lineWidth: 2
        }
    };

    addingStop: boolean = false;
    addingSite: boolean = false;
    addingTask: boolean = false;

    //RouteSummary Props;
    routeWarningText: string;
    travelTimeText: string;
    routeTimeText: string;
    totalDistText: string;
    totalTimeText: string;


    lastRefresh: number = 0;

    lastRefreshSelects: number = 0;
    priorSelectPins: RwPin[] = [];

    lastRefreshStops: number = 0;
    lastRefreshSites: number = 0;
    lastRefreshTasks: number = 0;
    cacheStops: RwStop[] = [];
    cacheSites: RwSite[] = [];
    cacheTasks: RwStop[] = [];

    cardExpiryMonth: string = '';
    cardExpiryYear: string = '';
    cardExpiry: string = '';
    maskedCardInfo: string = '';
    updatePaymentUrl: string = '';
    billingPortalUrl: string = '';

    cardExpiryMonthNum: number = 0;
    cardExpiryYearNum: number = 0;
    currentMonthNum: number = 0;
    currentYearNum: number = 0;

    activeRouteStopCount: number = 0;
    checkedRouteStopCount: number = 0;

    m_zoomLevel: number = 0;

    reloCoordsLat = RwPrefUtils.viewCenterLat;
    reloCoordsLng = RwPrefUtils.viewCenterLng;

    get globals(): RwGlobals {
        return theGlobals;
    }


    get showRouteOrder(): boolean {
        return theStore.getters.showRouteOrder;
    }

    set showRouteOrder(val: boolean) {
        theStore.dispatch("showRouteOrder", val).catch();
    }

    get showRouteSummary(): boolean {
        return theStore.getters.showRouteSummary;
    }

    set showRouteSummary(val: boolean) {
        theStore.dispatch("showRouteSummary", val).catch();
    }

    get searchResultsWeb(): RwSearchResult[] {
        return theStore.getters.searchResultsWeb;
    }

    set searchResultsWeb(val: RwSearchResult[]) {
        theStore.dispatch("searchResultsWeb", val).catch();
    }

    get searchResultsSites(): RwSearchResult[] {
        return theStore.getters.searchResultsSites;
    }

    set searchResultsSites(val: RwSearchResult[]) {
        theStore.dispatch("searchResultsSites", val).catch();
    }

    get searchResultsTasks(): RwSearchResult[] {
        return theStore.getters.searchResultsTasks;
    }

    set searchResultsTasks(val: RwSearchResult[]) {
        theStore.dispatch("searchResultsTasks", val).catch();
    }

    get searchResultsCoords(): RwSearchResult[] {
        return theStore.getters.searchResultsCoords;
    }

    set searchResultsCoords(val: RwSearchResult[]) {
        theStore.dispatch("searchResultsCoords", val).catch();
    }

    get steps(): RwStep[] {
        return theStore.getters.steps;
    }

    set steps(val: RwStep[]) {
        theStore.dispatch("steps", val).catch();
    }

    get showMapOptions(): boolean {
        return theStore.getters.showMapOptions;
    }

    set showMapOptions(val: boolean) {
        theStore.dispatch("showMapOptions", val).catch();
    }

    get showDataLayers(): boolean {
        return theStore.getters.showDataLayers;
    }

    set showDataLayers(val: boolean) {
        theStore.dispatch("showDataLayers", val).catch();
    }


    get showPanelStatus(): boolean {
        return theStore.getters.showPanelStatus;
    }

    set showPanelStatus(val: boolean) {
        RwPrefUtils.showPanelStatus = val;
        theStore.dispatch("showPanelStatus", val).catch();
    }

    get showPanelMap(): boolean {
        return theStore.getters.showPanelMap;
    }

    set showPanelMap(val: boolean) {
        RwPrefUtils.showPanelMap = val;
        theStore.dispatch("showPanelMap", val).catch();
    }

    get showPanelList(): boolean {
        return theStore.getters.showPanelList;
    }

    set showPanelList(val: boolean) {
        RwPrefUtils.showPanelList = val;
        theStore.dispatch("showPanelList", val).catch();
    }

    get isLayerDelta() {
        return this.isLayerHidden || this.isLayerFiltered;
    }

    get isLayerHidden() {
        let showAll = this.showDataLayerRoute && this.showDataLayerSites && this.showDataLayerTasks;
        //console.log("isLayerHidden showAll: showRoute, showSites, showTasks, showDrivers", showAll, this.showDataLayerRoute, this.showDataLayerSites, this.showDataLayerTasks, this.showDataLayerDrivers);
        return !showAll;
    }

    get isLayerFiltered() {
        let isFiltered = this.isStopFiltered || this.isSiteFiltered || this.isTaskFiltered;
        //console.log("isLayersFiltered isFiltered: isStop, isSite, isTask, isDriver", isFiltered, this.isStopFiltered, this.isSiteFiltered, this.isTaskFiltered, this.isDriverFiltered);
        return isFiltered;
    }


    get isStopFiltered(): boolean {
        return theStore.getters.isStopFiltered;
    }

    set isStopFiltered(val: boolean) {
        RwPrefUtils.isStopFiltered = val;
        theStore.dispatch("isStopFiltered", val).catch();
    }

    get isSiteFiltered(): boolean {
        return theStore.getters.isSiteFiltered;
    }

    set isSiteFiltered(val: boolean) {
        RwPrefUtils.isSiteFiltered = val;
        theStore.dispatch("isSiteFiltered", val).catch();
    }

    get isTaskFiltered(): boolean {
        return theStore.getters.isTaskFiltered;
    }

    set isTaskFiltered(val: boolean) {
        RwPrefUtils.isTaskFiltered = val;
        theStore.dispatch("isTaskFiltered", val).catch();
    }

    get isDriverFiltered(): boolean {
        return theStore.getters.isDriverFiltered;
    }

    set isDriverFiltered(val: boolean) {
        RwPrefUtils.isDriverFiltered = val;
        theStore.dispatch("isDriverFiltered", val).catch();
    }

    get showDataLayerRoute(): boolean {
        return theStore.getters.showDataLayerRoute;
    }

    set showDataLayerRoute(val: boolean) {
        RwPrefUtils.showDataLayerRoute = val;
        theStore.dispatch("showDataLayerRoute", val).catch();
    }

    get showDataLayerSites(): boolean {
        return theStore.getters.showDataLayerSites;
    }

    set showDataLayerSites(val: boolean) {
        RwPrefUtils.showDataLayerSites = val;
        theStore.dispatch("showDataLayerSites", val).catch();
    }

    get showDataLayerTasks(): boolean {
        return theStore.getters.showDataLayerTasks;
    }

    set showDataLayerTasks(val: boolean) {
        RwPrefUtils.showDataLayerTasks = val;
        theStore.dispatch("showDataLayerTasks", val).catch();
    }

    // get showDataLayerDrivers(): boolean {
    //   return theStore.getters.showDataLayerDrivers;
    // }
    //
    // set showDataLayerDrivers(val: boolean) {
    //   RwPrefUtils.showDataLayerDrivers = val;
    //   theStore.dispatch("showDataLayerDrivers", val).catch();
    // }


    get isMultiMode(): boolean {
        return this.multiMode != RwMultiMode.none;
    }

    get multiMode(): RwMultiMode {
        return theStore.getters.multiMode as RwMultiMode;
    }

    set multiMode(val: RwMultiMode) {
        if (val != RwMultiMode.none) {
            if (val === RwMultiMode.tasks && !this.globals.isDisp) {
                val = RwMultiMode.stops;
            }
            RwPrefUtils.planMultiModeDefault = val;
        }
        theStore.dispatch("multiMode", val);
    }

    get multiModeTool(): RwMutiModeTool {
        return theStore.getters.multiModeTool as RwMutiModeTool;
    }

    set multiModeTool(val: RwMutiModeTool) {
        RwPrefUtils.planMultiModeTool = val;
        theStore.dispatch("multiModeTool", val).catch();
    }

    get sizeDelta(): number {
        return theStore.getters.sizeDelta;
    }

    set sizeDelta(val: number) {
        theStore.dispatch("sizeDelta", val).catch();
    }

    get timeHeight(): number {
        let height = 0;
        if (this.openRoutes && this.openRoutes.length > 0) {
            let openCount = this.openRoutes.length;
            height = Math.min(48 + (openCount * 48), 240);
        }
        return height;
    }


    get selectedPins(): RwPin[] {
        // console.log("GET selectedPins", this.SRC_PINS, theStore.getters.selectedPins.length);
        return theStore.getters.selectedPins;
    }

    set selectedPins(val: RwPin[]) {
        //console.log("GET selectedPins", this.SRC_PINS, val.length);
        let samePins = false;
        let selPins = this.selectedPins;
        if (selPins.length === 1 && selPins.length === val.length) {
            if (selPins[0].eid === val[0].eid) {
                //console.error("SET selectedPins samePins");
                samePins = true;
            }
        }

        if (!samePins) {
            theStore.dispatch("selectedPins", val).catch();
        }
    }

    get mapShowTraffic(): boolean {
        //return false;
        return theStore.getters.mapShowTraffic;
        //console.log("PlanSpace GET mapShowTraffic", RwPrefUtils.mapShowTraffic)
    }

    set mapShowTraffic(val: boolean) {
        RwPrefUtils.mapShowTraffic = val;
        theStore.dispatch("mapShowTraffic", val).catch();
        //console.log("PlanSpace SET mapShowTraffic", RwPrefUtils.mapShowTraffic, val)
    }


    get mapShowIncidents(): boolean {
        //return false;
        return theStore.getters.mapShowIncidents;
        //console.log("PlanSpace GET mapShowTraffic", RwPrefUtils.mapShowTraffic)
    }

    set mapShowIncidents(val: boolean) {
        RwPrefUtils.mapShowIncidents = val;
        theStore.dispatch("mapShowIncidents", val).catch();
        //console.log("PlanSpace SET mapShowTraffic", RwPrefUtils.mapShowTraffic, val)
    }

    get mapShowSatellite(): boolean {
        return theStore.getters.mapShowSatellite;
    }

    set mapShowSatellite(val: boolean) {
        RwPrefUtils.mapShowSatellite = val;
        theStore.dispatch("mapShowSatellite", val).catch();
    }

    get showSearchOptions(): boolean {
        return theStore.getters.showSearchOptions;
    }

    set showSearchOptions(val: boolean) {
        theStore.dispatch("showSearchOptions", val).catch();
    }

    get showPlannerToolbar(): boolean {
        return theStore.getters.showPlannerToolbar;
    }

    set showPlannerToolbar(val: boolean) {
        theStore.dispatch("showPlannerToolbar", val).catch();
    }

    get selectedSearchResult(): RwSearchResult {
        return theStore.getters.selectedSearchResult;
    }

    set selectedSearchResult(val: RwSearchResult) {
        theStore.dispatch("selectedSearchResult", val).catch();
    }

    get searchReportInfo(): Array<RwAddReport> {
        return theStore.getters.searchReportInfo;
    }

    set searchReportInfo(val: Array<RwAddReport>) {
        theStore.dispatch("searchReportInfo", val).catch();
    }

    statusText(val: number) {
        switch (val) {
            case 0:
                return "Active (Default)";
                break;
            case 1:
                return "New";
                break;

            case 10:
                return "Complete (Default)";
                break;
            case 11:
                return "Service Complete";
                break;
            case 12:
                return "Left at Front Door/on Porch";
                break;
            case 13:
                return "Left at Gate/in Lobby";
                break;
            case 14:
                return "Left in Mail Area";
                break;
            case 15:
                return "Handed to Customer";
                break;
            case 16:
                return "Signature Collected";
                break;
            case 17:
                return "Pick-Up Complete";
                break;

            case 20:
                return "Incomplete (Default)";
                break;
            case 21:
                return "Nobody There/Not Open";
                break;
            case 22:
                return "Incorrect Address";
                break;
            case 23:
                return "Address Unreachable";
                break;
            case 24:
                return "Customer Refused";
                break;
            case 25:
                return "Sent Text/Email";
                break;
            case 26:
                return "Called/Left Voicemail";
                break;
            case 27:
                return "Spoke with Customer";
                break;
            case 28:
                return "Left a Note";
                break;
            case 29:
                return "Attempt Again Later";
                break;
            case 30:
                return "Do Not Re-Attempt";
                break;
        }
    }

    statusColor(val: number) {
        if (val < 10) {
            return 'blue';
        } else if (val > 19) {
            return 'red';
        } else {
            return 'green';
        }
    }

    //#endregion Props


    //#region ActiveRoute

    setActiveRouteById(routeId: string) {
        if (routeId) {
            const route = this.globals.findRoute(routeId);
            if (route) {
                if (!this.activeRoute || this.activeRoute.routeId != routeId) {
                    this.activeRoute = route;

                }
            }
        }
    }


    get activeRoute(): RwRoute {
        let route = theStore.getters.activeRoute;
        //console.warn("RwGlobals get activeRoute", route ? route.name : 'null');
        return route;
    }

    set activeRoute(route: RwRoute) {
        //console.log("RwPlannerSpace set activeRoute", route ? route.name : 'null');
        if (route != this.activeRoute) {

            //theStore.dispatch("activeRoute", null).catch();
            //console.warn("RwPlannerSpace set activeRoute", route ? route.name : 'null');

            this.globals.lastRouteDeltaMS = 0;
            this.globals.lastStatusMS = 0;
            this.globals.driverLastSyncMS = 0;


            if (route) {
                this.startPin = RwPin.fromRoute(route);
                this.startPin.lastHash = Date.now()
                if (this.isOpenRoute(route) == false) {
                    this.openRoute(route);
                }

                //SKIP: if first load && we have route with stops
                let canSkip = !this.activeRoute && RwPrefUtils.lastRouteId === route.routeId && route.stops.length > 0;
                if (!canSkip) {
                    this.globals.getFullRoute(route);
                    this.lastRefreshStops = Date.now();
                }

                this.globals.selectedRoute = route;
                this.clearPinSelections();
                this.globals.targetFocusId = route.routeId;
                RwPrefUtils.lastRouteId = route.routeId;

                //console.log("RwPlannerSpace.activeRoute SET focusId", this.focusId);
            } else {
                RwPrefUtils.lastRouteId = "";
            }
            theStore.dispatch("activeRoute", route).catch();
        }
    }

    //
    // get routeHistCount(): number{
    //   let stopCount = this.activeRoute.stops.length;
    //   // console.warn("stopCount: ", stopCount);
    //   return stopCount;
    // }


    forceShowDataView() {
        //console.log("forceShowDataView", this.selectedPin.dataItem)
        if (this.globals.showPinPanel == true) {
            setTimeout(() => this.globals.showPinPanel = false, 0);
            setTimeout(() => this.globals.showPinPopup = true, 250);
        } else {
            setTimeout(() => this.globals.showPinPopup = true, 250);
        }
    }


    //#endregion


    //#region Selected Pin

    get selectedPin(): RwPin {
        let selPin: RwPin;
        if (this.isMultiMode == false) {
            let pins = this.selectedPins;
            if (pins && pins.length > 0) {
                selPin = pins[0];
            }
        }
        //console.warn("RwPlannerSpace.selectedPin GET");
        return selPin;
    }

    set selectedPin(pin: RwPin) {
        //console.warn("RwPlannerSpace.selectedPin SET");
        if (pin) {
            pin.lastHash = Date.now();
            this.selectedPins = [pin];
        } else {
            this.selectedPins = [];
        }
    }

    get mapCentroid(): { lat: number, lng: number, zoom: number } {
        //let val = theStore.getters.mapCentroid
        //console.log("Plan.GET.mapCentroid => ", val.lat, val.lng, val.zoom)
        return theStore.getters.mapCentroid;
    }

    set mapCentroid(val: { lat: number, lng: number, zoom: number }) {
        //console.log("Plan.SET.mapCentroid => ", val.lat, val.lng, val.zoom)
        theStore.dispatch("mapCentroid", val).catch();
    }

    get searchPinData(): { pinType: RwPinTypes, lat: number, lng: number, id: string } {
        return theStore.getters.searchPinData;
    }

    set searchPinData(val: { pinType: RwPinTypes, lat: number, lng: number, id: string }) {
        theStore.dispatch("searchPinData", val).catch();
    }

    get showInvalidPinBanner(): boolean {
        return theStore.getters.showInvalidPinBanner;
    }

    set showInvalidPinBanner(val: boolean) {
        theStore.dispatch("showInvalidPinBanner", val).catch();
    }

    get invalidPinError(): string {
        return theStore.getters.invalidPinError;
    }

    set invalidPinError(val: string) {
        theStore.dispatch("invalidPinError", val).catch();
    }

    get invalidPinIcon(): string {
        return theStore.getters.invalidPinIcon;
    }

    set invalidPinIcon(val: string) {
        theStore.dispatch("invalidPinIcon", val).catch();
    }

    get previousPin(): RwPin | null {
        return theStore.getters.previousPin;
    }

    set previousPin(val: RwPin | null) {
        theStore.dispatch("previousPin", val).catch();
    }

    get showDropSelect(): boolean {
        return theStore.getters.showDropSelect;
    }

    set showDropSelect(val: boolean) {
        theStore.dispatch("showDropSelect", val).catch()
    }

    get stopDropOrigin(): RwStop | null {
        return theStore.getters.stopDropOrigin;
    }

    set stopDropOrigin(val: RwStop | null) {
        theStore.dispatch("stopDropOrigin", val).catch();
    }

    get showDropRemove(): boolean {
        return theStore.getters.showDropRemove;
    }

    set showDropRemove(val: boolean) {
        theStore.dispatch("showDropRemove", val).catch()
    }

    get showStopsViolations(): string[] {
        return theStore.getters.showStopsViolations;
    }

    set showStopsViolations(val: string[]) {
        RwPrefUtils.showStopViolations = val;
        theStore.dispatch("showStopsViolations", val).catch();
    }

    isSelected(pin: RwPin) {
        //console.log("RwPlanerSpace.isSelected", pin.title)
        let isSelected = false;
        if (!pin.isCluster) {
            isSelected = this.selectedPins.includes(pin);
        } else {
            let clusterPins = this.getClusterPins(pin);
            if (clusterPins && clusterPins.length > 0) {
                for (let n = 0; n <= clusterPins.length; n++) {
                    let p = clusterPins[n];
                    isSelected = this.selectedPins.includes(p);
                    if (isSelected) {
                        break;
                    }
                }
            }
        }
        return isSelected;
    }


    setPinSelection(pin: RwPin, targetSelected: boolean, setFocus?: boolean) {
        const self = this;
        let selPins = this.selectedPins;

        if (pin) {
            //console.log("setPinSelection", `${pin.title}, type:${pin.type}, isSelected:${targetSelected}`);

            if (targetSelected) {

                if (self.multiMode === RwMultiMode.none) {
                    //console.log("PlanSpace.selectPin set selectedPins");

                    if (pin.isCluster) {
                        //SINGLE-MODE
                        //May be a no-op
                    } else {
                        //Use short-circuit to skip unnecessary updates
                        if (selPins && selPins.length === 1 && selPins[0].eid === pin.eid) {
                            //console.warn("PlanSpace.selectPin SKIPPING RESELECT:", pin.title);
                        } else {
                            self.globals.targetFocusId = (setFocus) ? pin.eid : "";
                            this.selectedPins = [pin];
                        }
                    }
                } else {
                    //MULTI-MODE

                    if (!pin.isCluster) {
                        pin.lastHash = Date.now();
                        const clone: RwPin[] = [...selPins];
                        clone.push(pin);
                        this.selectedPins = clone;

                        if (pin.clusterParent) {
                            this.setClusterSelect(pin.clusterParent, selPins, targetSelected);
                        }
                    } else {
                        //console.log("setPinSelection", "multi-mode", targetSelected, pin.title);
                        this.setClusterSelect(pin, selPins, targetSelected);
                    }
                }

            } else {
                //Deselect Mode
                let selPins = this.selectedPins;
                if (!pin.isCluster) {
                    const idxPin = selPins.indexOf(pin, 0);
                    if (idxPin >= 0) {
                        selPins.splice(idxPin, 1);
                    }
                } else {
                    this.setClusterSelect(pin, selPins, false);
                }

            }

        } else {
            RwLog.consoleError("RwPlannerSpace.selectPin WTF: no pin")
        }

    }


    setClusterSelect(pin: RwPin, selPins: RwPin[], targetSelect: boolean) {
        pin.invalidatePinImage()
        pin.lastHash = Date.now();

        let clustered = this.getClusterPins(pin);
        if (clustered && clustered.length > 0) {
            const clone: RwPin[] = [...selPins];

            if (targetSelect) {
                //pin.ratio = 1;
                clustered.forEach(p => {
                    let isSelectedAlready = selPins.includes(p);
                    if (!isSelectedAlready) {
                        p.lastHash = Date.now();
                        pin.lastHash = Date.now();
                        clone.push(p);
                    }
                })
            } else {
                //pin.clusterSelectRatio = 0;
                clustered.forEach(p => {
                    let isSelectedAlready = selPins.includes(p);
                    if (isSelectedAlready) {
                        p.lastHash = Date.now();
                        pin.lastHash = Date.now();
                        let idx = clone.indexOf(p)
                        if (idx >= 0) {
                            clone.splice(idx, 1);
                        }
                    }
                })
            }

            if (clone.length != selPins.length) {
                this.selectedPins = clone;
            }
        }
    }

    private getClusterPins(pin: RwPin): RwPin[] {
        if (pin.clusteredPins && pin.clusteredPins.length > 0) {
            for (let idx = pin.clusteredPins.length - 1; idx >= 0; idx--) {
                let p = pin.clusteredPins[idx];
                if (!p.clusterParent) {
                    pin.clusteredPins.splice(idx, 1);
                }
            }
        }
        return pin.clusteredPins;
    }


    deselectPin(pin: RwPin) {
        //console.warn("RwPlannerSpace.deselectPin isSelected", pin.title);
        if (this.isSelected(pin)) {
            //const clone: RwPin[] = [...this.selectedPins];
            let selPins = this.selectedPins;
            const idxPin = selPins.indexOf(pin, 0);
            if (idxPin >= 0) {
                selPins.splice(idxPin, 1);
            }
        }
    }


    clearPinSelections() {
        //console.error("clearPinSelections");
        this.selectedPins = [];
    }


    get selectedStop(): RwStop {
        let stop: RwStop;
        if (this.selectedPin && this.selectedPin.type == RwPinTypes.Stop) {
            stop = this.selectedPin.stop;
        }
        return stop;
    }


    //#endregion


    //#region OpenRoutes

    get openRoutes(): RwRoute[] {
        return theStore.getters.openRoutes;
    }

    set openRoutes(routes: RwRoute[]) {
        RwPrefUtils.openRoutes = routes.map(r => r.routeId);
        theStore.dispatch("openRoutes", routes).catch();
    }


    openRouteInPlanner(route: RwRoute) {
        this.globals.plan.activeRoute = route;
        this.globals.plan.openRoute(route);
        //console.warn("openRouteInPlanner", route.plannerUrl)

        router.push(route.plannerUrl).catch();
    }

    openRoute(route: RwRoute) {
        if (route) {
            let opens = this.openRoutes;
            if (route.finishTime == null) {
                let newFinishTime = RwDateUtils.addMinutesToDate(route.startTime, RwPrefUtils.routeSpan);
                route.finishTime = newFinishTime;
                // route.setFinishDate("openRoute", newFinishTime)
            }
            if (this.isOpenRoute(route) == false) {
                this.openRoutes = [...this.openRoutes, route];
            }
        }
    }


    multiOpenRoutes(routes: RwRoute[]) {
        if (routes.length > 0) {
            let opens = this.openRoutes;
            routes.forEach(route => {
                if (this.isOpenRoute(route) == false) {
                    if (route.finishTime == null) {
                        let newFinishTime = RwDateUtils.addMinutesToDate(route.startTime, RwPrefUtils.routeSpan);
                        route.finishTime = newFinishTime;
                        // route.setFinishDate("multiOpenRoutes", newFinishTime)
                    }
                    opens.push(route);
                }
            });
            this.openRoutes = opens;
        }
    }


    closeRoute(route: RwRoute, skipFallback?: boolean) {
        //console.log("PlanSpace.closeRoute", route.name);
        this.multiCloseRoutes([route], skipFallback);
    }


    closeRoutesNotFound(closingRouteIds: string[], skipFallback?: boolean) {
        if (closingRouteIds.length > 0) {
            let opens = this.openRoutes;
            let opensCopy = opens.slice();
            let closingRouteIdsCopy = closingRouteIds;
            let activeRoute = this.activeRoute;

            // if (this.activeRoute) {
            //   const activeRtId = this.activeRoute.routeId;
            //   const closingActiveRoute = closingRouteIds.findIndex(rtid => rtid === activeRtId) !== -1;
            //   if (closingActiveRoute) {
            //     if (opensCopy.length === 1) {
            //       this.activeRoute = null;
            //     } else {
            //       const openSelectedRtIndex = opensCopy.findIndex(rt => rt.routeId === activeRtId)
            //       if (openSelectedRtIndex === 0) {
            //         this.activeRoute = opensCopy[1];
            //       } else {
            //         this.activeRoute = opensCopy[0];
            //       }
            //     }
            //   }
            // }
            closingRouteIdsCopy.forEach(id => {
                opensCopy.forEach(route => {
                    if (route.routeId === id) {
                        if (route == activeRoute && !skipFallback) {
                            this.setFallbackRoute(route);
                        }
                        const idx = opensCopy.indexOf(route, 0);
                        if (idx > -1) {
                            opensCopy.splice(idx, 1);
                            if (route == this.globals.selectedRoute) {
                                this.globals.selectedRoute = null;
                            }
                        } else {
                            RwLog.warn("WTF: closeRoutesNotFound idx not found", id);
                        }
                    }
                })
            })
            // console.warn('dispatch launched');
            Vue.nextTick(() => {
                this.openRoutes = opensCopy;
            });

        }
    }

    multiCloseRoutes(closingRoutes: RwRoute[], skipFallback?: boolean) {
        //console.log("PlanSpace.multiCloseRoutes", closingRoutes.length);
        if (closingRoutes.length > 0) {
            let opens = this.openRoutes;

            let opensCopy = [...opens];
            let closingCopy = [...closingRoutes];

            let activeRoute = this.activeRoute;
            closingCopy.forEach(route => {
                if (this.isOpenRoute(route)) {

                    if (route == activeRoute && !skipFallback) {
                        this.setFallbackRoute(route);
                    }

                    const idx = opensCopy.findIndex(r => route.routeId == r.routeId);
                    if (idx > -1) {
                        opensCopy.splice(idx, 1);

                        if (route == this.globals.selectedRoute) {
                            this.globals.selectedRoute = null;
                        }
                    } else {
                        RwLog.consoleError("WTF: multiCloseRoutes idx not found", route.name, route.routeId);
                    }
                } else {
                    //this is normal if closed from non-Planner (e.g. Route List)
                    //RwLog.consoleError("WTF: multiCloseRoutes closingRoute not open", route.name, route.routeId);
                }
            });
            this.openRoutes = opensCopy;
        } else {
            RwLog.consoleError("WTF: multiCloseRoutes no closingRoutes");
        }
    }


    private setFallbackRoute(route: RwRoute) {
        let activeRoute = this.activeRoute;
        if (route == activeRoute) {
            let fallback: RwRoute;
            let newRoutePath = "";
            fallback = this.getFallbackRoute(route);
            //console.log("multiCloseRoutes fallback", !!fallback);
            if (fallback) {
                newRoutePath = "activeRoute.plannerUrl";
                this.activeRoute = fallback;
            } else {
                newRoutePath = "/planner";
                this.activeRoute = null;
            }

            //console.warn("setFallbackRoute", "newRoutePath")

            router.push(newRoutePath).catch(err => RwLog.consoleError("route failed", newRoutePath));
            //console.log("PlanSpace.closeRouteWithFallback", newRoutePath, router.currentRoute.path);
        }
    }


    private getFallbackRoute(route: RwRoute): RwRoute {
        let fallback: RwRoute = null;
        let idx = this.openRoutes.findIndex(r => r.routeId === route.routeId);
        if (idx >= 0) {
            let openCount = this.openRoutes.length;
            let idxLast = openCount - 1;
            if (openCount > 1) {
                let isLast = idx == idxLast;
                if (isLast) {
                    //Use prior tab
                    fallback = this.openRoutes[idx - 1];
                    //console.info("getFallbackRoute set to prior route", fallback.name);
                } else if (idx < idxLast) {
                    //Use next tab
                    fallback = this.openRoutes[idx + 1];
                    //console.info("getFallbackRoute set to next route", fallback.name);
                } else {
                    RwLog.consoleError("WTF: getFallbackRoute idx >= idxLast", idx, idxLast);
                }
            } else {
                //console.warn("getFallbackRoute open count == 1");
            }
        } else {
            RwLog.consoleError("WTF: getFallbackRoute route not found");
        }
        return fallback;
    }


    isOpenRoute(route: RwRoute): boolean {
        let isOpen = false;
        let isDone = false;
        let routeId = route.routeId;
        if (this.openRoutes && this.openRoutes.length > 0) {
            this.openRoutes.forEach(openRoute => {
                if (isDone == false) {
                    if (openRoute.routeId == routeId) {
                        isDone = true;
                        isOpen = true;
                    }
                }
            });
        }

        return isOpen;
    }


    //#endregion


    //#region Pins


    get startPin(): RwPin {
        return theStore.getters.startPin;
    }

    set startPin(val: RwPin) {
        theStore.dispatch("startPin", val).catch();
    }


    updateStopPins(adds: RwStop[], removes: RwStop[], deltas: RwStop[]) {
        const self = this;
        const SOURCE = "RwPlannerSpace.updateStopPins";
        //console.log("RwPlannerSpace.updateStopPins");

        let route: RwRoute = this.activeRoute;
        if (this.activeRoute) {

            route.getActiveStops();

            let newPins = [...self.stopPins];
            //console.log("updateStopPins newPins ", newPins.length);

            adds.forEach(stop => {
                //console.log("adding stop pin", stop.name, stop.stopId);
                if (this.stopPins.map(sp => sp.stop.stopId).indexOf(stop.stopId) > -1) return;
                let pin = RwPin.fromStop(stop);
                pin.lastHash = Date.now();
                if (self.multiMode === RwMultiMode.none) {
                    let canSelect = self.globals.targetSelectId === stop.stopId;
                    let canFocus = self.globals.targetFocusId === stop.stopId;
                    self.setPinSelection(pin, canSelect, canFocus);
                }

                newPins.push(pin);
            });
            //console.log("updateStopPins newPins POST ADDS", newPins.length);

            removes.forEach(stop => {
                //console.log("deleting stop pin", stop.name, stop.stopId);
                let idxPin = newPins.findIndex(p => p.eid === stop.stopId);
                if (idxPin >= 0) {
                    let pin = newPins[idxPin];
                    pin.lastHash = Date.now();
                    newPins.splice(idxPin, 1);
                }
            });
            //console.log("updateStopPins newPins POST DELETES", newPins.length);


            deltas.forEach(stop => {
                let pin = newPins.find(p => p.eid === stop.stopId);
                if (pin) {
                    RwPin.updateStopPin(pin, stop);
                    pin.lastHash = Date.now();
                    //console.log(SOURCE, "deltas", stop.name, stop.seq, pin.title, pin.seq, pin.lastHash);
                }
            });

            self.stopPins = newPins;
        }

    }


    get stopPins(): RwPin[] {
        //console.warn("RwPlannerSpace GET stopPins");
        return theStore.getters.stopPins;
    }

    set stopPins(pins: RwPin[]) {
        //console.error("RwPlannerSpace SET stopPins", pins);
        theStore.dispatch("stopPins", pins).catch();
    }


    genSitePins(): RwPin[] {
        const self = this;
        const SOURCE = "RwPlannerSpace.genSitePins";

        let sitePins: RwPin[] = [];
        let sites = theStore.getters.sites;
        if (sites && sites.length > 0) {
            sites.forEach(site => {

                if (site) {
                    let pin = RwPin.fromSite(site);
                    sitePins.push(pin);

                    if (self.globals.targetFocusId && self.globals.targetFocusId === site.siteId) {
                        self.setPinSelection(pin, true, true);
                    }
                } else {
                    RwLog.consoleError(SOURCE, "getSitePins");
                }

            });

        }
        this.sitePins = sitePins;
        return sitePins;
    }

    checkSitePins() {
        let gotSites = theStore.getters.sites.length > 0;
        let gotPins = theStore.getters.sitePins.length > 0;
        if (gotSites && !gotPins) {
            this.genSitePins();
        }
    }

    get sitePins(): RwPin[] {
        this.checkSitePins();
        //console.warn("GET sitePins", theStore.getters.sitePins.length)
        return theStore.getters.sitePins;
    }

    set sitePins(pins: RwPin[]) {
        //console.warn("SET sitePins", pins.length)
        theStore.dispatch("sitePins", pins);
    }


    get taskPins(): RwPin[] {
        //console.warn("GET taskPins", theStore.getters.taskPins.length)
        return this.globals.taskPins;
    }

    set taskPins(pins: RwPin[]) {
        //console.warn("SET taskPins", pins.length)
        this.globals.taskPins = pins;
    }


    get driverPins(): RwPin[] {
        return theStore.getters.driverPins;
    }

    set driverPins(pins: RwPin[]) {
        theStore.dispatch("driverPins", pins);
    }


    //#endregion Pins


    //#region Filtering

    get stopPinsFiltered(): RwPin[] {
        //console.warn("RwPlannerSpace stopPinsFiltered showDataLayerRoute, isStopFiltered", RwPrefUtils.showDataLayerRoute && RwPrefUtils.isStopFiltered);
        let pins = this.stopPins.filter(p => p.type == RwPinTypes.Stop);
        if (RwPrefUtils.showDataLayerRoute && RwPrefUtils.isStopFiltered) {
            pins = this.filterStopsPins(pins);
        }
        return pins;
    }


    get sitePinsFiltered(): RwPin[] {

        let pins = this.sitePins.filter(p => p.type == RwPinTypes.Site);
        if (RwPrefUtils.showDataLayerSites && RwPrefUtils.isSiteFiltered) {
            pins = this.filterSitePins(pins);
        }
        return pins;
    }


    get taskPinsFiltered(): RwPin[] {
        let pins = this.taskPins.filter(p => p.type == RwPinTypes.Task);
        if (RwPrefUtils.showDataLayerTasks && RwPrefUtils.isTaskFiltered) {
            pins = this.filterTaskPins(pins);
        }
        return pins;
    }


    // get driverPinsFiltered(): RwPin[] {
    //   let pins = this.driverPins.filter(p => p.type == RwPinTypes.Driver);
    //   //console.warn("RwPlannerSpace.driverPinsFiltered pins unfiltered", pins.length);
    //   if (RwPrefUtils.showDataLayerDrivers && RwPrefUtils.isDriverFiltered) {
    //     pins = this.filterDriverPins(pins);
    //     //console.warn("RwPlannerSpace.driverPinsFiltered pins filtered", pins.length);
    //   }
    //   return pins;
    // }


    filterStopsPins(pins: RwPin[]): RwPin[] {
        let filtered: RwPin[];
        if (RwPrefUtils.isStopFiltered) {
            filtered = RwPlannerSpace.applyTextFilter(pins, RwPrefUtils.stopFilterName, RwPrefUtils.stopFilterAddress);
            filtered = RwPlannerSpace.applyStopTypeFilter(filtered);
            filtered = RwPlannerSpace.applyDistFilter(filtered, this.activeRoute, RwPrefUtils.stopFilterDistance);
            filtered = RwPlannerSpace.applyHashFilter(filtered, RwPrefUtils.stopFilterHash, RwPrefUtils.stopFilterHashAll);
            filtered = RwPlannerSpace.applyColorFilter(filtered, RwPrefUtils.stopFilterColor);
        } else {
            filtered = pins;
        }
        return filtered;
    }


    filterSitePins(pins: RwPin[]): RwPin[] {
        let filtered: RwPin[];
        if (RwPrefUtils.isSiteFiltered) {
            filtered = RwPlannerSpace.applyTextFilter(pins, RwPrefUtils.siteFilterName, RwPrefUtils.siteFilterAddress);
            filtered = RwPlannerSpace.applyDistFilter(filtered, this.activeRoute, RwPrefUtils.siteFilterDistance);
            filtered = RwPlannerSpace.applyHashFilter(filtered, RwPrefUtils.siteFilterHash, RwPrefUtils.siteFilterHashAll);
            filtered = RwPlannerSpace.applyColorFilter(filtered, RwPrefUtils.siteFilterColor);
            filtered = RwPlannerSpace.applyCheckFilter(filtered);
        } else {
            //console.error("filterSitePins filter skipped");
            filtered = pins;
        }
        return filtered;
    }


    filterTaskPins(pins: RwPin[]): RwPin[] {
        let filtered: RwPin[];
        if (RwPrefUtils.isTaskFiltered) {
            filtered = RwPlannerSpace.applyTextFilter(pins, RwPrefUtils.taskFilterName, RwPrefUtils.taskFilterAddress);
            filtered = RwPlannerSpace.applyDistFilter(filtered, this.activeRoute, RwPrefUtils.taskFilterDistance);
            filtered = RwPlannerSpace.applyHashFilter(filtered, RwPrefUtils.taskFilterHash, RwPrefUtils.taskFilterHashAll);
            filtered = RwPlannerSpace.applyColorFilter(filtered, RwPrefUtils.taskFilterColor);
        } else {
            filtered = pins;
        }
        return filtered;
    }


    filterDriverPins(pins: RwPin[]): RwPin[] {
        let filtered: RwPin[];
        if (RwPrefUtils.isDriverFiltered) {
            filtered = RwPlannerSpace.applyTextFilter(pins, RwPrefUtils.driverFilterName, null);
        } else {
            filtered = pins;
        }
        return filtered;
    }


    static applyTextFilter(pins: RwPin[], nameFilter: string, addFilter: string): RwPin[] {

        let filtered: RwPin[] = [];
        let gotNameFilter = nameFilter && nameFilter !== "";
        let gotAddFilter = addFilter && addFilter !== "";

        //console.log("applyTextFilter", `gotNameFilter:${gotNameFilter}, nameFilter:${nameFilter}, gotAddFilter:${gotAddFilter}, addFilter:${addFilter}`);

        if (pins.length === 0 || gotNameFilter || gotAddFilter) {
            nameFilter = gotNameFilter ? nameFilter.toLowerCase() : null;
            addFilter = gotAddFilter ? addFilter.toLowerCase() : null;

            //console.log("applyTextFilter nameFilter", nameFilter);

            pins.forEach(pin => {

                let isNameMatch = false;
                if (gotNameFilter) {
                    //console.warn("pin.title", pin.title);
                    isNameMatch = pin.title.toLowerCase().indexOf(nameFilter) != -1;
                    //console.warn("applyTextFilter gotNameFilter, isNameMatch", gotNameFilter, isNameMatch, pin.title, nameFilter);
                }

                let isAddMatch = false;
                if (gotAddFilter) {
                    //console.log("pin address", pin.address, taskAdd);
                    let pinAdd = pin.address;
                    isAddMatch = pinAdd && pinAdd.toLowerCase().indexOf(addFilter) != -1;
                }
                //console.warn("applyTextFilter gotAddFilter, isAddMatch", gotAddFilter, isAddMatch, pin.address, addFilter);

                if ((!gotNameFilter || isNameMatch) && (!gotAddFilter || isAddMatch)) {
                    //console.warn("filtered.push(pin)", pin);
                    filtered.push(pin);
                }
            });
        } else {
            filtered = pins;
        }

        return filtered;
    }


    static applyStopTypeFilter(pins: RwPin[]): RwPin[] {
        let filtered: RwPin[] = [];
        let stopFilterType = RwPrefUtils.stopFilterType;
        if (pins.length === 0 || stopFilterType == RwStopFilterType.All) {
            filtered = pins;
        } else if (stopFilterType == RwStopFilterType.ActiveStopsOnly) {
            pins.forEach(pin => {
                //if (pin.isActive) {
                if (pin.stop && !pin.stop.isComplete) {
                    filtered.push(pin);
                }
            });
        }
        return filtered;
    }


    static applyDistFilter(pins: RwPin[], route: RwRoute, distance: number): RwPin[] {
        //console.log("applyDistFilter", distance);

        let filtered: RwPin[] = [];

        if (route) {
            let lat = route.lat;
            let lng = route.lng;

            if (distance && distance > 0 && lat && lat != 0 && lng && lng != 0) {
                let lat = route.lat;
                let lng = route.lng;
                let distanceBar = 0.0;
                if (pins.length === 0 || distance >= 100.0 || distance <= 0.0) {
                    filtered = pins;
                } else {
                    // Convert to meters
                    if (RwPrefUtils.distUnits == RwDistUnits.Miles) {
                        distanceBar = distance * 1600.0;
                    } else {
                        distanceBar = distance * 1000.0;
                    }
                    //console.log(`distanceBar: ${distanceBar}`);

                    pins.forEach(pin => {
                        let meters = pin.getProximity(lat, lng);
                        if (meters != 0) {
                            //RwLog.logConsole`pin: ${pin.title} == ${meters} meters`);
                            if (meters <= distanceBar) {
                                filtered.push(pin);
                            }
                        } else {
                            RwLog.consoleError("WTF: What does '0' distance mean?  Stop at Start | Error");
                            //distRemoved.push(pin);
                        }
                    });
                }

            } else {
                filtered = pins;
            }
        } else {
            filtered = pins;
        }

        return filtered;
    }


    static applyHashFilter(pins: RwPin[], hashTags: string, isAll: boolean): RwPin[] {
        //xREF: FILTER: applyHashFilter

        let filtered: RwPin[] = [];
        if (pins.length === 0 || !hashTags || hashTags.length == 0) {
            filtered = pins;
        } else {
            let regPattern = "";
            let searchTokens: string[] = [];
            let tokens = hashTags.split(/\b\s+/);

            tokens.forEach(token => {
                token = token.toLowerCase();
                if (token.indexOf("#") >= 0) {
                    token = token.replace("#", "");
                }
                searchTokens.push(token);
            });
            //RwLog.logConsole("searchTokens", searchTokens);

            if (isAll) {
                //(?=.*\bmeat\b)(?=.*\bpasta\b)(?=.*\bdinner\b).+
                searchTokens.forEach(token => {
                    let hashPattern = "(?=.*\\#\\b" + token + "\\b)";
                    regPattern += hashPattern;
                });
            } else {
                //\#\ba\b|\#\bb\b  means A or B
                searchTokens.forEach(token => {
                    let hashPattern = "\\#\\b" + token + "\\b";
                    regPattern =
                        regPattern == "" ? hashPattern : regPattern + "|" + hashPattern;
                });
            }

            let regEx = new RegExp(regPattern);

            pins.forEach(pin => {
                //MONITOR: fix if this is not set correctly or create Pin.get.note to pull note from backing DEM
                let note = pin.note;
                if (note != null) {
                    let noteLower = note.toLowerCase();
                    let hasMatch = regEx.test(noteLower);
                    if (hasMatch) {
                        filtered.push(pin);
                        //RwLog.logConsole`MATCH: ${noteLower} pattern: ${regPattern}`);
                    } else {
                        //RwLog.logConsole`NO MATCH: ${noteLower} pattern: ${regPattern}`);
                    }
                }
            });
        }

        return filtered;
    }


    static applyColorFilter(pins: RwPin[], colorHex: string) {
        let filtered: RwPin[] = [];

        if (colorHex) {
            if (colorHex.length === 9 && colorHex.endsWith("FF")) {
                colorHex = colorHex.substring(0, 7);
            }
            //console.log("applyColorFilter, colorHex", colorHex);

            // Color filtering
            if (colorHex && colorHex.length > 0) {
                pins.forEach(p => {
                    let pinColor;
                    if (p.baseColor) {
                        pinColor = p.baseColor.toUpperCase();
                    }
                    //console.log("applyColorFilter, pinColor", pinColor);
                    if (pinColor) {
                        if (colorHex === pinColor) {
                            filtered.push(p);
                        }
                    }
                });
            } else {
                filtered = pins;
            }
        } else {
            filtered = pins;
        }

        return filtered;
    }


    static applyCheckFilter(pins: RwPin[]) {
        let filtered: RwPin[] = [];
        let checkMin = RwPrefUtils.siteFilterCheckMin;
        let checkMax = RwPrefUtils.siteFilterCheckMax;
        let checkWithin = RwPrefUtils.siteFilterCheckWithin;
        //console.log("applyCheckFilter", checkMin, checkMax, checkWithin);

        let isMinCheck = checkMin > -100;
        let isMaxCheck = checkMax < 0;


        if (!isMinCheck && !isMaxCheck) {
            filtered = pins;
        } else {
            let now = moment();
            let minDate = now.clone().add(checkMin, "days");
            let maxDate = now.clone().add(checkMax, "days");

            pins.forEach(p => {
                if (p.site) {
                    let site = p.site;

                    if (checkWithin) {
                        if (site.checkDate) {
                            let checkMo = moment(site.checkDate);
                            if (isMinCheck && isMaxCheck) {
                                if (checkMo >= minDate && checkMo <= maxDate) {
                                    //console.log("applyCheckFilter full range", checkMo.format("L LT"));
                                    filtered.push(p);
                                }
                            } else {
                                if (isMinCheck && checkMo >= minDate) {
                                    //console.log("applyCheckFilter newer than", checkMo.format("L LT"));
                                    filtered.push(p);
                                }
                                if (isMaxCheck && checkMo <= maxDate) {
                                    //console.log("applyCheckFilter older than", checkMo.format("L LT"));
                                    filtered.push(p);
                                }
                            }
                        }
                    } else {
                        //console.log("applyCheckFilter exclude");
                        if (site.checkDate) {
                            let checkMo = moment(site.checkDate);
                            if (isMinCheck && isMaxCheck) {
                                let isWithin = checkMo >= minDate && checkMo <= maxDate;
                                if (!isWithin) {
                                    filtered.push(p);
                                }
                            } else {
                                if (isMinCheck && checkMo <= minDate) filtered.push(p);
                                if (isMaxCheck && checkMo >= maxDate) filtered.push(p);
                            }
                        } else {
                            filtered.push(p);
                        }

                    }
                }
            });
        }

        //console.log("applyCheckFilter return filtered", filtered.length);
        return filtered;
    }


    //endregion Filtering


    //#region Search APIs

    updateStartFromSearch(searchResult: RwSearchResult): Promise<boolean> {
        const SOURCE = "RwPlannerSpace.updateStartFromSearch";
        let self = this;

        return new Promise<boolean>(function (resolve, reject) {

            if (self.globals) {
                const route = self.activeRoute;
                if (route != null) {
                    if (searchResult.lat != 0 || searchResult.lng != 0) {
                        route.lat = searchResult.lat;
                        route.lng = searchResult.lng;
                        route.address = searchResult.address;
                        route.isDynLoc = false;
                        route.path = null;
                        route.lastHash = Date.now()

                        self.globals
                            .updateRouteDB(route, true)
                            .then(newRoute => {
                                //console.log("updateStartAtCoord success", newRoute);
                                resolve(true);
                            })
                            .catch(err => {
                                RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : "");
                                //console.error("updateStartAtCoord fail", err.message);
                            });
                    } else {
                        this.globals.showSnack('Route start cannot be (0,0)', 'error');
                    }

                }
            }
        });
    }


    addStopFromSearch(res: RwSearchResult): Promise<RwStop> {
        const self = this;
        const SOURCE = "RwPlannerSpace.addStopFromSearch";
        //console.log(SOURCE, "res", res.type, res.name, res.address, res.lat, res.lng);

        return new Promise<RwStop>(function (resolve, reject) {

            let stop: RwStop;
            let route = self.globals.activeRoute;

            switch (res.type) {

                case RwSearchType.Web: {
                    stop = route.createStop(res.name, res.address, res.lat, res.lng);
                    stop = self.updateStopFromSearch(res, stop);
                    stop.routeId = route.routeId;
                    self.globals
                        .addStopDB(stop, route, true, true)
                        .then(isAdded => resolve(stop))
                        .catch(err => reject(err))
                        .finally(() => self.addingStop = false);
                    break;
                }

                case RwSearchType.Sites: {
                    let siteId = res.placeId;
                    let site = self.globals.findSite(siteId);
                    if (site) {
                        stop = route.createStopFromSite(site);
                        self.globals
                            .addStopDB(stop, route, true, true)
                            .then(isAdded => resolve(stop))
                            .catch(err => reject(err))
                            .finally(() => self.addingStop = false);
                    } else {
                        RwLog.consoleError(SOURCE, "WTF: Site Result; Site not found!");
                    }
                    break;
                }

                case RwSearchType.Tasks: {
                    let task = self.globals.tasks.find(t => t.stopId == res.placeId);
                    if (task) {
                        self.globals
                            .addTaskToRoute(task, route, true)
                            .then(isAdded => resolve(stop))
                            .catch(err => reject(err))
                            .finally(() => self.addingStop = false);
                    } else {
                        RwLog.consoleError(SOURCE, "WTF: Site Result; Site not found!");
                    }
                    break;
                }

                default:
                    RwLog.consoleError(SOURCE, "Unexpected type", res.type);
                    break;

            }

        });
    }


    addSiteFromSearch(searchResult: RwSearchResult): Promise<RwSite> {
        const SOURCE = "RwPlannerSpace.addSiteFromSearch";
        let self = this;
        let lat = searchResult.lat;
        let lng = searchResult.lng;

        return new Promise<RwSite>(function (resolve, reject) {

            if (!self.addingSite) {
                self.addingSite = true;

                let site = new RwSite();
                site.name = searchResult.name;
                site.address = searchResult.address;
                site.phone = searchResult.phone;
                site.lat = lat;
                site.lng = lng;
                site.baseColor = RwPrefUtils.siteColor;
                if (searchResult.portType) site.portType = searchResult.portType;
                if (searchResult.nameId) site.nameId = searchResult.nameId;
                site.visitTime = -1; //RwPrefUtils.serviceTime;

                self.globals.addSite(site, true)
                    .then(isAdded => {
                        if (self.globals.editAfterAdd) self.globals.showSiteEdit(site);
                        resolve(isAdded ? site : null);
                    })
                    .catch(err => {
                        RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : "");
                        //console.error("addSiteFromSearch fail", err.message);
                    })
                    .finally(() => {
                        self.addingSite = false;
                    });

            } else {
                RwLog.consoleError("WTF: Add Site In Progress");
                reject("WTF: Add Site In Progress");
            }

        });
    }


    addTaskFromSearch(res: RwSearchResult): Promise<RwStop> {
        const SOURCE = "RwPlannerSpace.addTaskFromSearch";
        let self = this;

        return new Promise<RwStop>(function (resolve, reject) {

            if (!self.addingTask) {
                self.addingTask = true;

                let route = self.activeRoute;
                let stop = route.createStop(res.name, res.address, res.lat, res.lng);
                let task = self.updateStopFromSearch(res, stop);
                task.isTask = true;
                task.routeId = RwPrefUtils.accountId;

                self.globals.addTaskDB(task, true)
                    .then(isAdded => {
                        //console.log("addTaskFromSearch addTaskDB callback", isAdded);
                        resolve(task);
                    })
                    .catch(err => {
                        RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : "");
                    })
                    .finally(() => {
                        self.addingTask = false;
                    });
            } else {
                RwLog.consoleError("WTF: Add Task In Progress");
                reject("WTF: Add Task In Progress");
            }
        });
    }


    private updateStopFromSearch(res: RwSearchResult, stop: RwStop) {
        if (res.navLat !== 0 && res.navLng !== 0) {
            stop.navLat = res.navLat;
            stop.navLng = res.navLng;
            stop.portType = res.portType;
            if (res.nameId) {
                stop.nameId = res.nameId;
            }
        }
        return stop;
    }

    //#endregion Search APIs


    //#region Data Actions


    addSiteFromPin(pin: RwPin) {
        const self = this;
        const SOURCE = "RwPlannerSpace.addSiteFromPin"
        if (!self.addingSite) {

            if (pin && pin.stop) {
                let stop = pin.stop;

                let site = new RwSite();
                site.name = stop.name;
                site.address = stop.address;
                site.lat = stop.lat;
                site.lng = stop.lng;
                site.note = stop.note;
                site.baseColor = stop.baseColor;
                site.visitTime = stop.visitTime;
                site.lastHash = Date.now();
                site.phone = stop.phone;
                site.email = stop.email;

                //console.log("addSiteAtCoord addSite stopId, siteId", stop.stopId, site.siteId);
                self.globals.addSite(site, false)
                    .then(isAdded => {
                        //console.log("addSiteAtCoord addSite callback", isAdded);

                        let priorSiteId = stop.siteId;
                        stop.siteId = site.siteId;
                        self.globals.updateStopDB(stop)
                            .then(isUpdated => {
                                //console.log("updateStop isUpdated callback", isUpdated);
                                stop.lastHash = Date.now();

                            })
                            .catch(err => {
                                //Rollback on error
                                stop.siteId = priorSiteId;
                                RwLog.error(SOURCE, "updateStopDB: Unhandled: \n" + err ? err.toString() : "");
                            });

                    })
                    .catch(err => {
                        let status = err.code ? err.code : err.status;
                        let errText = JSON.stringify(err);
                        if (status === 504) {
                            RwLog.warn(SOURCE, `${status}  \n\n error:\n${errText}`);
                        } else {
                            RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : "");
                        }
                    })
                    .finally(() => {
                        self.addingSite = false;
                    });
            } else {
                RwLog.consoleWarn(SOURCE, "WTF: not stop")
            }
        } else {
            RwLog.consoleWarn(SOURCE, "WTF: already adding site?")
        }
    }


    checkInStopPin(pin: RwPin): Promise<boolean> {
        const self = this;
        let stop = pin.stop;
        const route = this.activeRoute;

        //DEFER: Make this a transactional stored proc

        return new Promise<boolean>(function (resolve, reject) {

            if (stop != null && route != null) {

                //Use Transaction CheckIn Op (Add History, Update Stop, Update Site)

                let now = new Date();
                let histId = RwSysUtils.guidNew();
                let siteId: string;
                let site = stop.getSite();
                if (site != null) {
                    siteId = site.siteId;
                }

                let checkSeq = stop.seq;
                let checkCheckDate = stop.checkDate;
                let checkCheckHistId = stop.histId;

                stop.seq = 0;
                stop.isComplete = true;
                stop.checkDate = now;
                stop.histId = histId;

                self.globals.checkInStopDB(stop, histId, siteId)
                    .then(isChecked => {
                        //console.log("RwPlannerSpace.checkInStopPin isChecked", isChecked);

                        if (isChecked) {

                            if (site != null) {
                                site.checkDate = now;
                                site.lastHash = Date.now();
                            }

                            let hist = self.createHistory(stop);
                            self.globals.addHistLocal(hist);

                            self.activeRoute.stopCountActive -= 1;
                            self.activeRoute.stopCountComplete += 1;

                            stop.lastHash = Date.now();
                            stop.isComplete = true;

                            //self.setPinSelection(pin, true, false)
                            //self.globals.commitStops();
                            resolve(true)
                        } else {
                            stop.isComplete = false;
                            stop.seq = checkSeq;
                            stop.checkDate = checkCheckDate;
                            stop.histId = checkCheckHistId;
                            resolve(false)
                        }

                    })

            } else {
                RwLog.error("RwPlannerSpace.checkInStopPin", "checkInStopPin NO Stop | Route");
                resolve(false);
            }
        });
    }


    createHistory(stop: RwStop): RwHistory {
        const self = this;
        const route = this.activeRoute;
        let now = new Date();
        let hist = new RwHistory();
        hist.histId = RwSysUtils.guidNew();
        hist.lastHash = Date.now();
        hist.accountId = self.globals.orgId;
        hist.startTime = now;
        hist.name = stop.name;
        hist.type = RwHistTypes.CheckIn;
        hist.stopId = stop.stopId;
        hist.driverId = route.driverId;
        hist.address = stop.address;
        hist.lat = stop.lat;
        hist.lng = stop.lng;
        hist.time = stop.timeToDrive;
        hist.dist = stop.distToDrive;
        hist.BaseColor = stop.baseColor;
        hist.note = stop.note;
        return hist;
    }


    restoreStopPin(pin: RwPin): Promise<boolean> {
        const SOURCE = "RwPlannerSpace.restoreStopPin";

        let stop = pin.stop;
        let route = this.activeRoute;

        return new Promise<boolean>((resolve, reject) => {

            if (stop != null && route != null) {

                pin.isComplete = false;
                stop.seq = 0;
                stop.isComplete = false;
                stop.timeOfArr = null;
                stop.timeOfDep = null;
                stop.timeToDrive = 0;
                stop.timeToIdle = 0;
                route.stopCountActive += 1;
                route.stopCountComplete -= 1;

                this.globals
                    .updateStopDB(stop)
                    .then(stop => {
                        //self.setPinSelection(pin, true, false)
                        resolve(true);
                    })
                    .catch(err => {
                        let status = !!err.Code ? err.Code : err.status;
                        const message = !(err instanceof RwError) ?
                            err.message ? err.message : JSON.stringify(err)
                            : err;
                        if (status === 403 || status === 504) {
                            RwLog.warn(SOURCE, `Error:code:${status} msg:${message}`);
                        } else {
                            //Rollback on error:
                            pin.isComplete = true;
                            stop.isComplete = true;
                            route.stopCountActive -= 1;
                            route.stopCountComplete += 1;

                            RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : "");
                            RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : "");
                            reject(err);
                        }

                    });
            } else {
                RwLog.error(SOURCE, "No Stop");
                reject();
            }
        });

    }


    deleteStopPin(pin: RwPin, stack: string[] = [], skipCommit = false): Promise<boolean> {
        const SOURCE = "RwPlannerSpace.deleteStopPin";
        const self = this;
        return new Promise<boolean>(function (resolve, reject) {

            let stop: RwStop;
            if (pin && pin.stop) stop = pin.stop;
            if (pin && pin.task) stop = pin.task;
            if (stop) {

                self.globals.confirmAndDeleteStop(stop, [...stack, SOURCE], skipCommit)
                    .then(isDeleted => {
                        if (isDeleted) {

                            if (self.isSelected(pin)) {
                                self.setPinSelection(pin, false)
                                //self.clearPinSelections();
                            }
                        }
                        resolve(isDeleted);
                    })
                    .catch(err => {
                        reject(err);
                    });

            } else {
                RwLog.consoleError("WTF: NO STOP");
                reject("NOT A STOP");
            }
        });
    }


    onSiteDelete(pin: RwPin): Promise<boolean> {
        const self = this;
        const SOURCE = "RwPlannerSpace.onSiteDelete";
        //console.log(SOURCE);

        return new Promise<boolean>(function (resolve, reject) {

            let isSite = pin && pin.site;
            if (isSite) {

                self.globals.confirmAndDeleteSite(pin.site)
                    .then(isDeleted => {
                        if (isDeleted) {
                            self.clearPinSelections();
                        }
                        resolve(isDeleted);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    }


    // editSitePin(pin: RwPin) {
    //   if (pin && pin.site) {
    //     let site = pin.site;
    //     if(site){
    //       this.
    //     }
    //     // if (this.selectedPin != pin && !suppressPopup) {
    //     //   //if (theStore.getters.selectedPins != pin && !suppressPopup) {
    //     //   this.setPinSelection(pin, true, false);
    //     // }
    //
    //     //Set global selectedSite
    //     //theStore.dispatch("selectedSite", site).catch();
    //
    //     //console.warn("onEditSiteClick plan.showSiteEdit");
    //     //this.showSiteEdit = true;
    //   }
    // }


    onTaskDelete(pin: RwPin): Promise<boolean> {
        const SOURCE = "RwPlannerSpace.deleteTaskPin";
        const self = this;
        return new Promise<boolean>(function (resolve, reject) {

            let task: RwStop;
            if (pin && pin.task) task = pin.task;
            if (task) {
                self.globals.confirmAndDeleteTask(task)
                    .then(isDeleted => {
                        if (isDeleted) {
                            self.clearPinSelections();
                        }
                        resolve(isDeleted);
                    })
                    .catch(err => {
                        reject(err);
                    });

            } else {
                RwLog.consoleError("WTF: NO TASK");
                reject("NOT A TASK");
            }
        });
    }


    //#endregion Data Actions


    //#region Route Actions

    onRouteView(route: RwRoute) {
        //console.log("planSpace.onRouteView");
        this.globals.selectedRoute = route;

        let pin = this.startPin;
        if (pin && pin.route && pin.route.routeId === route.routeId) {
            this.setPinSelection(pin, true);
        } else {
            let newPin = RwPin.fromRoute(route);
            this.setPinSelection(newPin, true, true);
        }

        if (!this.globals.showPinPanel) {
            this.forceShowDataView();
        }

    }


    onRouteEdit(route: RwRoute) {
        this.globals.showRouteEdit(route)
    }

    onRouteStopCount(route: RwRoute): Promise<RwTotalStopCount> {
        let SOURCE = "RwPlannerSpace.onRouteStopCount";
        let self = this;
        return new Promise<RwTotalStopCount>(function (resolve, reject) {
            self.globals.getTotalStopCount(route)
                .then(stopCount => {
                    if (stopCount) {
                        self.checkedRouteStopCount = stopCount.TotalCount;
                        // console.warn("active Stop Count gotten, returns:", stopCount);
                    }
                    resolve(stopCount);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    checkAndRemoveDupes(stops: RwStop[]): RwStop[] {
        const SOURCE = "RwPlannerSpace.checkAndRemoveDupes";
        if (stops && stops.length > 0) {
            //let dupeStopTest = new RwStop(stops[0].toJSON());
            //stops.push(dupeStopTest);
            let sids = stops.map(s => s.stopId);
            let sidSet = Array.from(new Set(sids));
            if (sids.length === sidSet.length) {
                return stops;
            } else {
                // Remove dupes and return array
                RwLog.warn(SOURCE, `Dupes found before opt, removing duplicates from rt ${stops[0].routeId}`);
                let uniqueStops = new Array<RwStop>();
                sidSet.forEach(sid => {
                    let stop = stops.find(s => s.stopId === sid);
                    if (stop) {
                        uniqueStops.push(stop);
                    }
                })
                return uniqueStops;
            }
        } else {
            return [];
        }
    }


    onOptimize(_stops: RwStop[] = undefined): Promise<boolean> {
        const SOURCE = "RwPlannerSpace.onOptimize";
        const self = this;

        return new Promise<boolean>(function (resolve, reject) {

            if (self.activeRoute) {
                const route = self.activeRoute;
                route.updateTimeFrame();
                const stops = self.checkAndRemoveDupes(_stops ?? route.getActiveStops());

                if (stops && stops.length > 0) {
                    if (((route.lat != 0) || (route.lng != 0)) && ((route.lng != undefined) && (route.lat != undefined))) {
                        dal.addBusy();

                        self.isOptimizing = true;
                        RwTaskOptimize.optAsync(route, stops)
                            .then(function (route: RwRoute) {
                                //console.log("onOptimize", route);

                                //xFOCUS: OPT 1: Trigger Route Update to Display Route
                                self.globals.commitStops();

                                //self.refreshRouteInfo(route);
                                //snack.ShowSnackBar("route returned");
                                //console.log("route returned", route);

                                self.totalTimeText = route.totalTimeString;
                                self.travelTimeText = route.travelTimeText;
                                self.totalDistText = route.totalDistText;

                                resolve(true);

                            })
                            .catch(function (e) {
                                self.globals.showSnack("Optimization Error", "error");
                                //snack.ShowSnackBar("error returned:" + e.toString());
                                let routeJson = JSON.stringify(route.toJSON());
                                RwLog.warn(SOURCE, `Unhandled optAsync route:\n${routeJson} \n\nerr:\n${e}`);
                                reject(e);
                            })
                            .finally(() => {
                                dal.removeBusy();
                                self.isOptimizing = false;
                            })
                    } else {
                        self.isOptimizing = false;
                        RwLog.consoleError("Invalid start [0,0] or undefined");
                        reject("No Active Route");
                    }
                } else {
                    self.isOptimizing = false;
                    RwLog.consoleError("No Stops");
                    reject("No Active Route");
                }
            } else {
                self.isOptimizing = false;
                RwLog.consoleError("No Active Route");
                reject("No Active Route");
            }
        });
    }

    //#endregion Route Actions

    // Overflow Actions Region
    stopSkedText(pin: RwPin): string {
        let text: string;
        if (pin && pin.stop) {
            let stop = pin.stop;
            text = stop.skedText;
        }
        return text;
    }

    isStopScheduled(pin: RwPin): boolean {
        let gotSked = false;
        if (pin && pin.stop) {
            let stop = pin.stop;
            gotSked = stop.isScheduled;
        }
        return gotSked;
    }

    canStopAddSite(pin: RwPin): boolean {
        let canShow = false;
        if (pin && pin.stop) {
            let stop = pin.stop;
            canShow = !stop.isSited;
        }
        return canShow;
    }

    canShowSkeds(pin: RwPin): boolean {
        let show = false;
        let route = this.activeRoute;
        if (pin && pin.stop && route) {
            let stop = pin.stop;
            show = (stop.isMarkedFirst(route) || stop.isMarkedFinal(route)) == false;
        }
        return show;
    }

    hasDropStop(pin: RwPin): boolean {
        let hasDrops = false;
        let route = this.activeRoute;
        if (pin && pin.stop && route) {
            let stop = pin.stop;
            hasDrops = stop.hasDrops();
        }
        return hasDrops;
    }

    canShowMarkFinal(pin: RwPin): boolean {
        let show = false;
        let route = this.activeRoute;
        if (pin && pin.stop && route) {
            let stop = pin.stop;
            show = !stop.isMarkedFinal(route);
        }
        return show;
    }

    canShowMarkFirst(pin: RwPin): boolean {
        let show = false;
        let route = this.activeRoute;
        if (pin && pin.stop && route) {
            let stop = pin.stop;
            show = !stop.isMarkedFirst(route);
        }
        return show;
    }

    canShowUnmarkFirst(pin: RwPin): boolean {
        let show = false;
        let route = this.activeRoute;
        if (pin && pin.stop && route) {
            let stop = pin.stop;
            show = route.isFirstStop(stop)
        }
        return show;
    }

    canShowUnmarkFinal(pin: RwPin): boolean {
        let show = false;
        let route = this.activeRoute;
        if (pin && pin.stop && route) {
            let stop = pin.stop;
            show = route.isFinalStop(stop)
        }
        return show;
    }

    onStopAddSite(pin: RwPin) {
        this.addSiteFromPin(pin);
    }

    onStopAddSked(pin: RwPin) {
        if (pin && pin.stop) {
            let stop = pin.stop;
            this.globals
                .multiSked(false, [stop])
                .then((isAdded) => {
                    if (isAdded) {
                        stop.lastHash = Date.now();
                        pin.isSked = true;
                        pin.priority = stop.priority;
                        pin.lastHash = Date.now();
                        this.globals.commitStops();
                    }
                });
        }
    }

    onStopRemoveSked(pin: RwPin) {
        if (pin && pin.stop) {
            let stop = pin.stop;

            this.globals
                .multiSkedDB(false, [stop.stopId], RwSysUtils.guidEmpty(), stop.priority, 0, 0)
                .then((isCommitted) => {
                    stop.skedId = undefined;
                    stop.openTime = 0;
                    stop.closeTime = 0;
                    stop.lastHash = Date.now();
                    pin.isSked = false;
                    this.globals.commitStops();
                });
        }
    }


    onStopMarkFirst(pin: RwPin) {
        // console.warn("Previous route.firstStopId: ", this.activeRoute.firstStopId);
        let route = this.activeRoute;
        // console.warn("Route.ismanOrder: ", route.isManOrder);
        if (route.isManOrder) {
            this.globals.showConfirmDialog("Confirm Mark First", "Marking this stop First will disable Manual Order and revert the Route settings to Optimize. If you want to keep your Manual Order, hit Back and set your Stop Order in the Route Order Window.", "Continue", "Back")
                .then(onConfirm => {
                    if (onConfirm) {
                        route.isManOrder = false;
                        this.markFirst(pin);
                    }
                })
        } else {
            this.markFirst(pin);
        }

    }

    markFirst(pin: RwPin) {
        if (pin && pin.stop) {
            let stop = pin.stop;
            let route = this.activeRoute;
            if (stop && route) {
                let priorId = route.firstStopId;
                route.firstStopId = stop.stopId;

                if (route.firstStopId == route.finalStopId) {
                    route.finalStopId = "";
                }
                this.globals.updateRouteDB(route, false, false, false, true)
                    .then(() => {
                        pin.isFirst = true;
                        if (priorId && priorId.length > 0) {
                            let priorPin = this.stopPins.find(p => p.eid === priorId);
                            if (priorPin) {
                                priorPin.isFirst = false;
                            }
                        }
                        this.globals.commitStops();
                    });
            }
        }
    }


    onStopMarkFinal(pin: RwPin) {
        let route = this.activeRoute;
        //console.error("Route.ismanOrder: ", route.isManOrder);
        if (route.isManOrder) {

            this.globals.showConfirmDialog("Confirm Mark Final", "Marking this stop Final will disable Manual Order and revert the Route settings to Optimize. If you want to keep your Manual Order, hit Back and set your Stop Order in the Route Order Window.", "Continue", "Back")
                .then(onConfirm => {
                    if (onConfirm) {
                        route.isManOrder = false;
                        this.markFinal(pin);
                    }
                })
        } else {
            this.markFinal(pin);
        }
    }

    markFinal(pin: RwPin) {
        if (pin && pin.stop) {
            let stop = pin.stop;
            let route = this.activeRoute;
            if (stop && route) {
                let priorId = route.finalStopId;
                route.finalStopId = stop.stopId;
                if (route.firstStopId == route.finalStopId) {
                    route.firstStopId = "";
                }
                this.globals
                    .updateRouteDB(route, false, false, false, true)
                    .then(() => {
                        pin.isFinal = true;
                        if (priorId && priorId.length > 0) {
                            let priorPin = this.stopPins.find(p => p.eid === priorId);
                            if (priorPin) {
                                priorPin.isFinal = false;
                            }
                        }
                        this.globals.commitStops();
                    })
            }
        }
    }

    onStopUnmarkFirst(pin: RwPin) {
        let route = this.activeRoute;
        if (route) {
            route.firstStopId = "";
            this.globals
                .updateRouteDB(route, false, false, false, true)
                .then(() => {
                    pin.isFirst = false;
                    this.globals.commitStops();
                })
        }
    }


    onStopUnmarkFinal(pin: RwPin) {
        let route = this.activeRoute;
        if (stop && route) {
            route.finalStopId = "";
            this.globals
                .updateRouteDB(route, false, false, false, true)
                .then(() => {
                    pin.isFinal = false;
                    this.globals.commitStops();
                })
        }
    }

    onStopCheckIn(pin: RwPin, fetchHistory: boolean = false, viewCheckInCallback = null) {
        if (pin && pin.stop) {
            if (!pin.stop.note || pin.stop.note.length <= 1000) {
                this.checkInStopPin(pin)
                    .then(() => {
                        this.globals.commitStops();

                        if (fetchHistory) {
                            const SOURCE = "RwPlannerSpace.onStopCheckIn";
                            RwTaskHistory.getHistoryItem(pin.stop.histId)
                            .then(histItem => {
                                if (histItem) {
                                    this.globals.selectedHistoryItem = histItem;
                                    if (viewCheckInCallback) viewCheckInCallback();
                                }
                            })
                            .catch((err) => {
                                RwLog.warn(SOURCE, `Error getting history item ${pin.stop.histId} for stop ${pin.stop.stopId} \n\n ${JSON.stringify(err)}`);
                            });
                        }
                    })
                    .catch(err => {
                        this.globals.genericDialog.title = "Error updating stop.";
                        this.globals.genericDialog.text = err.message;
                        this.globals.genericDialog.icon = "error";
                        this.globals.genericDialog.show = true;
                    });
            } else {
                this.globals.showSnack("Please shorten note to 1000 characters or less and try again.", "error");
            }
        } else {
            RwLog.error("RwPlannerMap.onCheckInClick", "no stop ");
        }
    }


    onStopRestore(pin: RwPin) {
        if (pin && pin.stop) {
            this.restoreStopPin(pin)
                .then(res => this.globals.commitStops())
                .catch(err => {
                    this.globals.genericDialog.title = "Error restoring stop.";
                    this.globals.genericDialog.text = err.message;
                    this.globals.genericDialog.icon = "error";
                    this.globals.genericDialog.show = true;
                });
        }
    }

    isStopCompleted(pin: RwPin): boolean {
        let isSited = false;
        if (pin && pin.stop) {
            let stop = pin.stop;
            isSited = stop.isComplete;
        }
        return isSited;
    }

    isSiteRouted(pin: RwPin): boolean {
        let isRouted = false;
        if (pin && pin.site) {
            let site = pin.site;
            isRouted = this.activeRoute.isRouted(site);
        }
        return isRouted;
    }

    onSiteToPickedRoute(site: RwSite, route: RwRoute) {
        const SOURCE = "RwPlannerSpace.onSiteToPickedRoute";
        if (route && site) {
            let stop = route.createStopFromSite(site);
            this.globals.targetSelectId = stop.stopId;
            this.globals.addStopDB(stop, route, false, true)
                .catch(err => RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : ""))
                .finally(() => this.addingStop = false);
        }
    }

    // deleteOnMove = false;

    onTaskToPickedRoute(task: RwStop, route: RwRoute) {
        const SOURCE = "RwPlannerSpace.onTaskToPickedRoute";
        if (route && task) {
            this.globals.targetSelectId = task.stopId;
            this.globals.addTaskToRoute(task, route, false)
                .catch(err => RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : ""))
                .finally(() => {
                        this.addingStop = false;
                        // if(this.deleteOnMove){
                        //   this.globals.deleteTasks([task.stopId])
                        //     // .then(res => {
                        //     //   if (res.length  1) {
                        //     //     self.globals.showSnack(`Task successfully moved`, "success");
                        //     //   }
                        //     // })
                        //     .catch(err => {
                        //       self.globals.showSnack(`Task added to Route, but not deleted from Tasks List`, "error");
                        //     });
                        //   this.deleteOnMove = false;
                    }
                );
        }
        // if (route && pin && pin.task) {
        //   let task = pin.task;
        //   this.globals.addTaskToRoute(task, route);
    }

    checkHistLimitPopups(route: RwRoute) {
        // console.warn("checkHistLimitPopups called in Plan Space for route: ", route.name);
        let stopCount: number = 0;
        this.onRouteStopCount(route)
            .then(count => {
                // console.warn("historyCount for route is:", this.checkedRouteStopCount);
                stopCount = this.checkedRouteStopCount;
                if (stopCount >= 500) {
                    this.globals.prompt500Popup.push(route.routeId)
                    this.globals.showLimitPopup = true;
                }
                if (stopCount >= 450 && stopCount < 500 && !this.globals.hide450Popup && !this.globals.warned450Popup.includes(route.routeId)) {
                    this.globals.prompt450Popup.push(route.routeId)
                    this.globals.showLimitPopup = true;
                    let idArray = this.globals.warned450Popup;
                    idArray.push(route.routeId);
                    this.globals.warned450Popup = idArray;
                }
            })

    }

    onSiteToRoute(pin: RwPin) {
        const SOURCE = "RwPlannerSpace.onSiteToRoute";
        let route = this.activeRoute;
        if (route && pin && pin.site) {
            let stop = route.createStopFromSite(pin.site);
            this.globals.targetSelectId = stop.stopId;
            this.globals.addStopDB(stop, route, false, true)
                .catch(err => {
                    if (this.globals.checkNotHandled(err)) RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : "");
                })
                .finally(() => this.addingStop = false);
        }
        this.checkHistLimitPopups(this.activeRoute);
    }

    onTaskToRoute(pin: RwPin) {
        let route = this.activeRoute;
        if (route && pin && pin.task) {
            let task = pin.task;
            this.globals.addTaskToRoute(task, route);
        }
        this.checkHistLimitPopups(this.activeRoute);
    }

    // DropStops
    onAddDropStop(item) {
        this.selectedPin = item;
        this.stopDropOrigin = item instanceof RwPin ? item.stop : item;
        this.showDropSelect = true;
    }

    onRemoveDropStop(item) {

        const stop = (item as RwPin)?.stop;

        if (stop) {
            const drop = stop.getDrop();
            if (drop) {
                this.globals.showConfirmDialog(`Remove Drop Stop for ${stop.name}`, `Are you sure you want to remove the drop stop for ${stop.name}?`, "Confirm", "Cancel")
                    .then(confirm => {
                        if (confirm) {
                            stop.removeDrop(drop.stopId)
                                .then(() => {
                                    this.globals.syncDelta(stop.routeId);
                                })
                        }
                    });
            }
        }


    }

    getDropStopName(item): string {
        const stop = item instanceof RwPin ? item.stop : null;
        const dropStop = stop?.getDrop();
        if (dropStop) {
            return dropStop.name;
        }
        return "";
    }

    canShowRemoveDropStop(item): boolean {
        const stop = item instanceof RwPin ? item.stop : item;
        return !!stop.getDropIds();
    }

    addTaskFromPin(pin: RwPin, resolve: any = () => null, reject: any = () => null) {
        const SOURCE = "RwPlannerSpace.addTaskFromPin"
        if (pin && pin.stop) {
            let stop = pin.stop;
            let task = new RwStop();

            task.name = stop.name;
            task.address = stop.address;
            task.lat = stop.lat;
            task.lng = stop.lng;
            task.note = stop.note;
            task.email = stop.email;
            task.phone = stop.phone;
            task.baseColor = stop.baseColor;
            task.visitTime = stop.visitTime;
            task.lastHash = Date.now();
            task.isTask = true;
            task.routeId = RwPrefUtils.userId;

            this.globals.addTaskDB(task, false)
                .then(isAdded => {
                    this.globals.updateStopDB(stop)
                        .then(isUpdated => {
                            stop.lastHash = Date.now()
                            resolve();
                        })
                        .catch(err => RwLog.error("RwPlannerSpace.checkInStopPin", "Unhandled: " + err ? err.toString() : ""));
                })
                .catch(err => {
                    let status = err.code ? err.code : err.status;
                    if (status === 504) {
                        RwLog.warn(SOURCE, "Timeout Error 504: " + err ? err.toString() : "")
                    } else {
                        RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : "")
                    }
                    reject();
                });
        } else {
            RwLog.consoleWarn(SOURCE, "WTF: not stop")
            reject();
        }
    };

    getBillingInfos() {

        let SOURCE = "RwPlanSpace.getBillingInfos";

        let isOwner = this.globals.isDriver == false;
        if (isOwner || this.globals.hasActiveFlexSku) {
            RwTaskBillingProfile.GetBillingProfile()
                .then((profile) => {
                    // console.warn("Billing Profile gotten:", profile.UpdatePaymentUrl);
                    if (profile.ProStatus) {
                        this.globals.isAutoRenewing = profile.ProStatus.IsAutoRenewing;
                        PurchSpace.pricePerBaseAmt = profile.ProStatus.BasePriceAmount;
                        PurchSpace.pricePerDriverAmt = profile.ProStatus.DriverPriceAmount;
                        PurchSpace.pricePerTeamAmt = profile.ProStatus.TeamPriceAmount;
                    }
                    this.updatePaymentUrl = profile.UpdatePaymentUrl;
                    this.billingPortalUrl = profile.BillingPortalUrl;
                    if (profile.PaymentMethod) {
                        this.maskedCardInfo = profile.PaymentMethod.MaskedCard;
                        this.cardExpiry = profile.PaymentMethod.ExpirationMonth + '/' + profile.PaymentMethod.ExpirationYear;

                        this.cardExpiryMonth = profile.PaymentMethod.ExpirationMonth;
                        this.cardExpiryYear = profile.PaymentMethod.ExpirationYear;
                        this.cardExpiryMonthNum = Number(profile.PaymentMethod.ExpirationMonth);
                        this.cardExpiryYearNum = Number(profile.PaymentMethod.ExpirationYear);
                        // DATA FOR TESTING BELOW
                        // self.cardExpiryMonth = '03';
                        // self.cardExpiryYear = '2021';
                        // self.cardExpiryMonthNum = 3;
                        // self.cardExpiryYearNum = 2021;
                        // //END TESTING DATA
                    }
                    this.currentMonthNum = new Date().getMonth() + 1;
                    this.currentYearNum = new Date().getFullYear();
                    // console.warn("cardexpiryMonthStr: ", self.cardExpiryMonth, " cardexpiryYearStr: ", self.cardExpiryYear);
                    // console.warn("cardexpiryMonthNum: ", self.cardExpiryMonthNum, " cardExpiryYearNum: ", self.cardExpiryYearNum);
                    // console.warn("currentMonthNum: ", self.currentMonthNum, " currentYearNum: ", self.currentYearNum);
                })
                .catch(err => {
                    RwLog.consoleError(SOURCE, "WTF: get billing profile error", err.toString());
                });
        }

    }

    get zoomLevel(): number {
        // console.warn("zoom level planSpace get: ", theStore.getters.zoomLevel);
        return theStore.getters.zoomLevel;
    }

    set zoomLevel(val: number) {
        // console.warn("zoom level planSpace set: ", val);
        theStore.dispatch("zoomLevel", val);
    }


    get changePin(): RwPin {
        return theStore.getters.changePin;
    }

    set changePin(val: RwPin) {
        theStore.dispatch("changePin", val);
    }

}

export default new RwPlannerSpace();