

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwUser} from "@/app/dem/RwUser";
import {RwRoute, RwRouteAssignCodes, RwSyncStatus} from "@/app/dem/RwRoute";
import {RwLog} from "@/app/dal/RwLog";
import {RwTaskRoutes} from "@/app/dal/RwTaskRoutes";


@Component
export default class RwAssignPicker extends Vue {
  @Prop(RwRoute) readonly initRoute: RwRoute | undefined;


  //#region Props
  idxTab = 0;
  idxDriver = -1;

  initAssignCode = 0;
  initDriverId = "";
  assignCode = 0;
  driverId = "";


  get globals(): RwGlobals {
    return theGlobals;
  }

  get space(): RwPlannerSpace {
    return thePlanSpace;
  }

  get backColor() {
    return this.globals.isDarkMode ? "rwBackDark" : "rwBackLight";
  }

  get loading(): boolean {
    return this.globals.isLoading;
  }

  get isDarkMode(): boolean {
    return this.globals.isDarkMode;
  }

  get isDirty(): boolean {
    let isDirty = (this.initAssignCode != this.assignCode) || (this.initDriverId != this.driverId) || (this.initDriverId == this.driverId && this.assignCode == RwRouteAssignCodes.pending);
    //console.log("RwAssignPicker.isDirty (initAssignCode, selAssignCode), (initDriverId, selDriverId)", this.initAssignCode, this.selAssignCode, this.initDriverId, this.selDriverId);
    return isDirty;
  }

  get styleAssigned() {
    return RwRoute.getAssignBackground(RwRouteAssignCodes.assigned);
  }

  get stylePending() {
    return RwRoute.getAssignBackground(RwRouteAssignCodes.pending);
  }

  get styleRejected() {
    return RwRoute.getAssignBackground(RwRouteAssignCodes.rejected);
  }

  //#endregion Props


  //#region User Events

  onBackClick() {
    this.idxTab = 0;
  }


  onPickCode() {
    this.idxTab = 1;
  }

  onPickDriver() {
    this.idxTab = 2;
  }


  onPushDeltas() {
    if (this.initRoute) {
      this.globals.pushUpdateToDriver(this.initRoute);
      this.close();
    }
  }


  onSetAssigned() {
    this.assignCode = RwRouteAssignCodes.assigned;
  }

  onSetPending() {
    this.assignCode = RwRouteAssignCodes.pending;
  }

  onSetRejected() {
    this.assignCode = RwRouteAssignCodes.rejected;
  }


  onEscape(event: Event) {
    event.stopPropagation();
    this.close();
  }

  onDriverPick(driver: RwUser) {
    //console.log("onDriverPick name, idxDriver", driver ? driver.name : "no driver", this.idxDriver);
    this.driverId = driver.userId;
    //this.close();
  }

  onCommit() {
    const SOURCE = "RwAssignPicker.onCommit";

    //console.log("onDriverPick name, idxDriver", this.driverId ? driver.name : "no driver", this.idxDriver);

    let routeId = this.initRoute.routeId;
    let driverId = this.driverId;
    let assignCode = this.assignCode;
    let statusCode = this.initRoute.statusCode;


    //console.warn(SOURCE, assignCode, this.driverId);
    let isSelfAssigned = driverId === this.globals.userId;
    let isPending = assignCode == RwRouteAssignCodes.pending;
    let isDynamic = this.initRoute.isDynLoc;
    let updateStatus = isSelfAssigned && isPending && isDynamic;
    if (updateStatus) {

      assignCode = RwRouteAssignCodes.assigned;

      const syncStatus = new RwSyncStatus();
      syncStatus.routeId = routeId;
      syncStatus.assignCode = assignCode;
      syncStatus.driverId = driverId;
      syncStatus.statusCode = statusCode;

      RwTaskRoutes.setRouteStatusInfo(syncStatus)
          .then(() => {

            this.initRoute.assignCode = assignCode;
            this.initRoute.driverId = driverId;
            this.$emit("onAssignPick", this.initRoute);
            this.close();

            // routeUpdate.statusCode = statusUpdate.statusCode;
            // routeUpdate.invalidateCache();
            //console.warn("status update to:", status.status, route.name);
          })
          .catch(err => {
            let statusText = JSON.stringify(syncStatus.toJSON());
            let errText = JSON.stringify(err);
            let status = err.code ? err.code : err.status;
            if (status == 403) {
              RwLog.warn(SOURCE, errText);
            } else {
              RwLog.error(SOURCE, `Unhandled \n${statusText} \n\n${errText}`);
            }
            this.initRoute.assignCode = this.assignCode;
          });
    } else {
      this.initRoute.assignCode = assignCode;
      this.initRoute.driverId = this.driverId;
      this.$emit("onAssignPick", this.initRoute);
      this.close();
    }

  }


  // onCommit() {
  //   //console.log("onDriverPick name, idxDriver", driver ? driver.name : "no driver", this.idxDriver);
  //   this.initRoute.assignCode = this.assignCode;
  //   this.initRoute.driverId = this.driverId;
  //   this.$emit("onAssignPick", this.initRoute);
  //   this.close();
  // }


  onCancel() {
    this.close();
  }


  close() {
    this.globals.m_showAssignPicker = false;
  }


  //#endregion UI Nav


  //#region LifeCycle

  mounted() {
    //console.log("RwAssignPicker.mounted");

    this.initDriverId = this.initRoute.driverId;
    this.initAssignCode = this.initRoute.assignCode;
    this.driverId = this.initRoute.driverId;
    this.assignCode = this.initRoute.assignCode;

    if (this.initRoute && this.driverId) {
      let driverId = this.initRoute.driverId;
      let drivers = this.globals.drivers;
      let driver = drivers.find(d => d.userId === driverId);
      if (driver) {
        let index = drivers.indexOf(driver, 0);
        if (index >= 0) {
          this.idxDriver = index;
          //console.log("RwAssignPicker.mounted idxDriver", this.idxDriver);
        } else {
          RwLog.consoleError("WTF: RwAssignPicker.mounted idxDriver < 0");
        }
      } else {
        RwLog.consoleError("WTF: RwAssignPicker.mounted selDriver == null");
      }
    }

  }

  //#endregion
  sortedDrivers: RwUser[] = [...this.globals.drivers];
  currentSort = "none";

  @Watch("globals.drivers")
  watchGlobalsDrivers(val) {
    this.currentSort = this.currentSort === "asc" ? "desc" : this.currentSort === "desc" ? "asc" : "none";
    this.sortedDrivers = [...val];
    this.sortDrivers();
  }

  sortDrivers() {
    if (this.currentSort === "asc") {
      this.sortedDrivers = this.sortedDrivers.reverse();
      this.currentSort = "desc";
    } else {
      this.sortedDrivers.sort((drvA: RwUser, drvB: RwUser): number => (drvA.formatDisplayName > drvB.formatDisplayName ? 1 : drvA.formatDisplayName === drvB.formatDisplayName ? 0 : -1));
      this.currentSort = "asc";
    }
  }

}

