import {RwRoute} from "@/app/dem/RwRoute";

export class RwOptResult {

    code: number = 500;
    route: RwRoute;
    json: JSON;

    constructor(json?: Object) {
        if (json != null) {
            this.fromJson(json);
        }
    }


    fromJson(json: Object) {
        for (let key in json) {
            switch (key) {
                // case "nm":
                //   this.name = json[key];
                //   break;
            }
        }
    }

}
