import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.globals.showOptInPostMessage),callback:function ($$v) {_vm.$set(_vm.globals, "showOptInPostMessage", $$v)},expression:"globals.showOptInPostMessage"}},[_c(VToolbar,[_c(VToolbarTitle,[_vm._v(" Migration Successful ")]),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCard,[_c(VCardText,{staticClass:"pt-5 text-left"},[_vm._v(" Ensure that the drivers update to the latest RoadWarrior app version and log out and log in to have conversion take effect. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }