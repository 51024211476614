
import { Component, Prop, Vue } from "vue-property-decorator";
import theGlobals, { RwGlobals } from "@/app/RwGlobals";
import thePlan, { RwPlannerSpace } from "@/app/views/planner/RwPlannerSpace";
import { RwHistory } from "@/app/dem/RwHistory";
import RwPODThumbnail from "@/app/views/components/RwPODThumbnail.vue";
import { RwSysUtils } from "@/app/utils/RwSysUtils";
import { RwLog } from "@/app/dal/RwLog";
import { RwConstants } from "@/app/RwConstants";
import { RwCoreMedia } from "@/app/dem/ProofOfDelivery/RwCoreMedia";
import { ConfirmationType, MediaType } from "@/app/dem/ProofOfDelivery/ProofOfDeliveryEnums";
import { RwTaskHistory } from "@/app/dal/RwTaskHistory";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Tus from "@uppy/tus";

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

@Component({
  components: { RwPODThumbnail }
})
export default class RwHistoryEdit extends Vue {
  @Prop(Boolean) readonly popup: boolean;

  valid: boolean = false;
  initNameText = "";
  initNoteText = "";

  nameText = "";
  noteText = "";

  isMounted = false;
  finalLength: string = this.calcMaxWidth();
  noteVisibility = false;
  hist: RwHistory = this.globals.m_histEditInitHistory;
  editedHist: RwHistory = new RwHistory();
  addingPOD = false;
  uploadedPODImage = false;
  uploadedImageFromPopup = false;
  ConfirmationType = ConfirmationType;
  uploadingType: ConfirmationType;
  uploadWidget: Uppy;
  changedMedia = false;
  currentPhotoIndex = 0;
  popupImageOpacity = 0;

  get stop() {
    if (this.hist && this.hist.stopId) {
      if (this.plan.selectedStop && this.plan.selectedStop.stopId === this.hist.stopId) {
        return this.plan.selectedStop;
      } else {
        const stop = this.globals.stops.find(s => s.stopId === this.hist.stopId);
        return stop ?? null;
      }
    }
    return null;
  }

  get name() {
    return this.hist?.name ?? "";
  }

  get globals(): RwGlobals {
    return theGlobals
  }

  get plan(): RwPlannerSpace {
    return thePlan;
  };

  get isDirty(): boolean {
    let gotNameDelta = RwSysUtils.isLooseDeltaText(this.initNameText, this.nameText);
    let gotNoteDelta = RwSysUtils.isLooseDeltaText(this.initNoteText, this.noteText);
    return gotNameDelta || gotNoteDelta || this.changedMedia;
  }

  get histNameRules() {
    return [v => (v && v.length <= 96 && v !== "") || 'Name is required and must be 96 characters or less.']
  }

  get histCommentsRules(): any[] {
    if (this.noteText) {
      return [v => v.length <= 1000 || 'Must not exceed 1000 characters.'];
    }
  }

  getCurrentMedia(): RwCoreMedia {
    if (this.uploadingType === ConfirmationType.Photo) {
      return this.editedHist.getPhoto(this.currentPhotoIndex);
    }
    return this.editedHist.getMedia(this.uploadingType);
  }

  isUploadingPhoto(): boolean {
    return this.uploadingType === ConfirmationType.Photo;
  }

  showNextPhotoButton(): boolean {
    return this.editedHist.getPhotos()?.length > 1 && this.currentPhotoIndex < this.editedHist.getPhotos()?.length - 1;
  }


  showNextPhoto(): void {
    if (this.currentPhotoIndex < this.editedHist.getPhotos().length - 1) {
      this.popupImageOpacity = 0;
      setTimeout(() => {
        this.currentPhotoIndex++;
        setTimeout(() => {
          this.popupImageOpacity = 1;
        }, 200);
      }, 200);
    }
  }

  showPreviousPhoto(): void {
    if (this.currentPhotoIndex > 0) {
      this.popupImageOpacity = 0;
      setTimeout(() => {
        this.currentPhotoIndex--;
        setTimeout(() => {
          this.popupImageOpacity = 1;
        }, 200);
      }, 200);
    }
  }

  getMediaLink(): string {
    return RwCoreMedia.getLink(this.getCurrentMedia());
  }

  getCurrentMediaBackgroundColor(): string {
    if (this.getCurrentMedia().confType === ConfirmationType.Signature) {
      return 'white';
    }
    return 'none';
  }

  getCurrentMediaPadding(): string {
    if (this.getCurrentMedia().confType === ConfirmationType.Signature) {
      return '20px';
    }
    return 'none';
  }

  getCurrentMediaBorderRadius(): string {
    return '8px';
  }

  deleteCurrentMedia(): void {
    this.globals.showConfirmDialog("Move to trash", "Remove image from this check-in?", 'Move to trash', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          const currentMedia = this.getCurrentMedia();
          if (currentMedia) {
            currentMedia.del = true;
            this.changedMedia = true;
            if (this.uploadingType === ConfirmationType.Photo) {
              if (this.currentPhotoIndex > 0) {
                this.currentPhotoIndex--;
              }
            }
            this.$forceUpdate();
          }
        }
      });
  }

  confirmClose() {
    if (this.isDirty) {
      this.globals.showConfirmDialog("Save changes", "You have unsaved changes. Are you sure you want to cancel?", 'Yes', 'No')
        .then((confirmed) => {
          if (confirmed) this.close();
        });
    } else {
      this.close();
    }
  }

  close(isCommit?: boolean) {
    this.$emit("onHistoryEdited", isCommit);
    this.globals.m_showHistoryEdit = false;
  }

  onCommit() {
    if (!this.isDirty) return;
    const SOURCE = "RwHistoryEdit.OnCommit";
    this.editedHist.name = this.nameText;
    this.editedHist.note = this.noteText;
    const self = this;

    RwTaskHistory.updateHistoryAsync(this.editedHist)
      .then((histResult) => {
        if (histResult) {
          this.close(true);
          this.globals.selectedHistoryItem = null;
          this.globals.showSnack("Check-in saved successfully!", "success");
        } else {
          self.editedHist.name = self.initNameText;
          self.editedHist.note = self.initNoteText;
          this.globals.showSnack("Sorry, we ran into an error saving the check-in", "error");
          RwLog.error(SOURCE, `Error saving check-in ${JSON.stringify(histResult)}`)
        }
      })
      .catch((err) => {
        this.globals.showSnack("Sorry, we ran into an error saving the check-in", "error");
        RwLog.error(SOURCE, `Error saving check-in ${JSON.stringify(err)}`)
      });
  }

  onCancel(event: Event) {
    event.stopPropagation();
    this.close();
  }

  addingPODBackgroundImage() {
    return `/images/icons/pod_no_${ConfirmationType[this.uploadingType]}${this.globals.isDarkMode ? '_dark' : ''}.png`.toLowerCase();
  }

  addPOD(type: ConfirmationType) {
    this.uploadingType = type;
    this.uploadedImageFromPopup = false;
    if (this.getCurrentMedia()) {
      this.addingPOD = true;
      this.uploadedPODImage = false;

      this.popupImageOpacity = 0;
      setTimeout(() => {
        this.popupImageOpacity = 1;
      }, 200);
    } else {
      this.openUploadWidget();
    }
  }

  closeAddPOD() {
    this.addingPOD = false;
    this.currentPhotoIndex = 0;
  }

  backToStop() {
    this.plan.selectedPin = this.plan.previousPin;
    this.globals.showHistoryPop = false;
    this.globals.selectedHistoryItem = null;
  }

  get cardElement(): Element {
    let el: Element;
    let card = this.$refs.cardHistView as any;
    if (card && card.$el && card.$el) {
      el = card.$el;
    }
    return el;
  }

  showTooltip(text: string) {
    return text.length > 72 || text.indexOf("\n") !== -1;
  }

  toggleNoteVisibility() {
    this.noteVisibility = !this.noteVisibility;
  }

  calcMaxWidth(): string {
    const SOURCE = "RwHistoryView.calcMaxWidth"

    let maxWidth = window.innerWidth;
    if (this.popup == true) {
      maxWidth = 450
    } else {

      if (this.isMounted) {

        let card = this.cardElement;
        if (card && card.clientWidth) {
          maxWidth = card.clientWidth - 150;
        } else {
          //This occurs in Empty State
          maxWidth = 450;
        }
      }

    }
    let output = "max-width: " + maxWidth.toString() + "px";
    this.finalLength = output;

    return output;
  }

  openUploadWidget() {
    this.initializeUploadWidget();
    (this.uploadWidget.getPlugin('Dashboard') as any).openModal();
  }

  initializeUploadWidget() {
    this.uploadWidget = new Uppy({
      allowMultipleUploadBatches: false,
      restrictions: {
        maxNumberOfFiles: this.uploadingType === ConfirmationType.Signature ? 1 : null,
        allowedFileTypes: ['image/*'], maxFileSize: 5 * 1024 * 1024
      },
      autoProceed: true,
      onBeforeFileAdded: (currentFile) => {
        currentFile.id = RwSysUtils.guidNew();
        return true;
      }
    }).use(Dashboard, {
      showProgressDetails: true
    }).use(Tus,
      {
        retryDelays: null,
        endpoint: RwConstants.tusUploadEndpoint,
        allowedMetaFields: ["client", "route", "stop", "filename", "filetype"], chunkSize: 5 * 1024 * 1024,
        async onBeforeRequest(req, file) {
          const { client, filename, route, stop } = file.meta;
          const signature = new URLSearchParams({ client, filename, route, stop } as any).toString();
          const authorization = Array.from(new Uint8Array(
            await crypto.subtle.digest('SHA-256', new TextEncoder().encode(signature))
          )).map(b => b.toString(16).padStart(2, '0')).join('');
          req.setHeader('Authorization', authorization);
        }
      });

    this.uploadWidget.on('file-added', (file) => {
      const fileMetaData = {
        "client": "roadwarrior",
        "route": theGlobals.activeRoute.routeId,
        "stop": this.stop.stopId,
        "filename": file.id,
        "filetype": file.type
      };
      this.uploadWidget.setFileMeta(file.id, fileMetaData);
    });

    this.uploadWidget.on('upload-success', (file, response) => {
      const fileGuid = response.uploadURL.replace(RwConstants.tusUploadEndpoint, '');
      (this.uploadWidget.getPlugin('Dashboard') as any).closeModal();

      const coreMedia = new RwCoreMedia();
      coreMedia.mediaId = fileGuid;
      coreMedia.mediaType = MediaType.Image;
      coreMedia.confType = this.uploadingType;
      coreMedia.stopId = this.stop.stopId;
      coreMedia.rid = this.stop.routeId;

      this.editedHist.media.push(coreMedia);
      this.changedMedia = true;
      this.uploadedPODImage = true;
      if (this.addingPOD) this.uploadedImageFromPopup = true;
      this.$forceUpdate();
    });

    this.uploadWidget.on('complete', (result) => {
      if (this.uploadingType === ConfirmationType.Photo) {
        this.currentPhotoIndex = this.editedHist.getPhotos().length - 1;
      }
      this.$forceUpdate();
    });

    this.uploadWidget.on('error', (error) => {
      const SOURCE = "RwHistoryEdit.UploadPOD";
      this.globals.showSnack("Sorry, we ran into an error uploading your proof of delivery", "error");
      RwLog.error(SOURCE, `Error uploading proof of delivery ${JSON.stringify(error.stack)}`);
    });
  }

  mounted() {
    this.isMounted = true;
    this.calcMaxWidth();
    window.addEventListener('resize', this.calcMaxWidth);

    this.editedHist.fromHistory(this.hist, true);
    this.initNameText = this.hist.name;
    this.initNoteText = this.hist.note;

    this.nameText = this.initNameText;
    this.noteText = this.initNoteText;
  }
}
