

import {Component, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";

@Component
export default class RwConfirm extends Vue {

  //#region Props

  showConfirm = this.globals.m_showConfirm
  confirmTitle = this.globals.m_confirmTitle
  confirmBody = this.globals.m_confirmBody
  confirmPosText = this.globals.m_confirmPosText
  confirmNegText = this.globals.m_confirmNegText

  get globals(): RwGlobals {
    return theGlobals;
  }

  //#endregion Props

  mounted() {
    // make sure component is focused when mounted so that keydown events work
    this.$nextTick(() => (this.$refs.confirmDialog as HTMLElement).focus());
  }


  //#region User Events

  onEnter(event: Event) {
    event.stopPropagation();
    this.close(true);
  }

  onEscape(event: Event) {
    event.stopPropagation();
    this.close(false);
  }


  onCancel() {
    this.close(false);
  }

  onConfirm() {
    this.close(true);
  }

  close(isConfirmed: boolean) {
    this.$emit("onConfirm", isConfirmed);
    this.globals.m_showConfirm = false;
    this.showConfirm = false;
  }
  //#endregion UI Nav

}

