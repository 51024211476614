import theGlobals from "@/app/RwGlobals";
import thePlan from "@/app/views/planner/RwPlannerSpace";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwSkuInfo} from "@/app/dem/Profile/RwSkuInfo";
import {RwAvatarInfo} from "@/app/dem/RwUser";

export interface Skufo {
    active: number;
    autoRenew: number;
    exp: any;
    oid: string;
    quantity: number;
    renew: any;
    sku: string;
}

export class RwLoginResponse {
    accessType: number;
    acttp: number;
    aid: string;
    atp: number;
    imgsrc: number;
    lang: string;
    lat: number;
    lng: number;
    //mid: string;
    nm: string;
    pro: boolean;
    skufo: Skufo[];
    skus: string[];
    tkn: string;
    uid: string;
    un: string;
    upg: boolean; // Needs Upgrade
    iid: string;
    bid: string;
    parid: string;
    OrgId: string;
    role: number;
    avInfo: RwAvatarInfo;
    rct: boolean;

    static fromJson(json: JSON): RwLoginResponse {
        let resp: RwLoginResponse = null;
        if (json) {

            resp = new RwLoginResponse();

            //console.log("RwLoginResponse", json);

            for (var key in json) {
                let val = json[key];
                switch (key) {
                    case "avInfo":
                        let avInfo = RwAvatarInfo.fromJson(val);
                        resp.avInfo = avInfo;
                        theGlobals.avInfo = avInfo;
                        break;

                    case "lat":
                        resp.lat = val;
                        break;
                    case "lng":
                        resp.lng = val;
                        break;

                    case "tkn":
                        resp.tkn = val;
                        theGlobals.token = val;
                        break;
                    case "uid":
                        resp.uid = val;
                        theGlobals.userId = val;
                        RwPrefUtils.userId = val;
                        break;
                    case "un":
                        resp.un = val;
                        theGlobals.userName = val;
                        RwPrefUtils.userName = val;
                        //console.warn("un pulled:", val);
                        break;
                    case "nm":
                        resp.nm = val;
                        theGlobals.dispName = val;
                        RwPrefUtils.dispName = val;
                    //console.warn("dispName pulled:", val);

                    case "pro":
                        resp.pro = val;
                        theGlobals.isPro = val;
                        break;
                    case "aid":
                        resp.aid = val;
                        theGlobals.accountId = val;
                        RwPrefUtils.accountId = val;
                        break;

                    case "skus":
                        resp.skus = val;
                        break;

                    case "skufo":
                        theGlobals.skuInfos = RwSkuInfo.fromJsonArray(val);
                        RwPrefUtils.skuInfosJson = JSON.stringify(val);
                        // console.warn ('skuInfos:', theGlobals.skuInfos);
                        break;

                    case "imgsrc":
                        resp.imgsrc = val;
                        theGlobals.imgSrc = val;
                        break;
                    case "iid":
                        resp.iid = val;
                        theGlobals.installId = val;
                        RwPrefUtils.installId = val;
                        break;
                    case "did":
                    case "device":
                        //resp.did = val;
                        theGlobals.deviceId = val;
                        RwPrefUtils.deviceId = val;
                        break;
                    case "eid":
                        //resp.eid = val;
                        theGlobals.extId = val;
                        break;
                    case "et":
                        //resp.et = val;
                        theGlobals.extType = val;
                        break;
                    case "extd":
                        //resp.extd = val;
                        theGlobals.extDate = val;
                        break;
                    case "subt":
                        //resp.subt = val;
                        theGlobals.isSubTeam = val;
                        break;
                    case "pd":
                        //resp.pd = val;
                        theGlobals.parentDomain = val;
                        break;
                    case "max":
                    case "maxrtsz":
                        //resp.maxrtsz = val;
                        thePlan.maxRouteSize = val;
                        RwPrefUtils.maxRouteSize = val;
                        break;
                    case "OrgId":
                        resp.OrgId = val;
                        theGlobals.orgId = val;
                        break;
                    case "subdm":
                        //resp.subdm = val;
                        theGlobals.subDomain = val;
                        break;
                    case "orgs":
                        //resp.orgs = val;
                        theGlobals.orgs = val;
                        break;
                    case "admemail":
                        //resp.admemail = val;
                        theGlobals.adminEmail = val;
                        break;

                    case "atype":
                    case "acttp":
                        resp.acttp = val;
                        theGlobals.isTeam = val == 1;
                        //RwPrefUtils.isTeam = json[key] != 0;
                        break;

                    case "accesstype":
                        resp.accessType = val;
                        RwPrefUtils.userAccessType = val;
                        break;

                    case "role":
                    case "utp":
                        resp.role = val;
                        theGlobals.role = val;
                        break;

                    case "rct":
                        resp.rct = val;
                        break;

                    default:
                        //console.warn(`unhandled json: ${key} : ${val}`)
                        break;
                }

                //Check for missing defaults
                if (!json["role"] && !json["utp"]) {
                    resp.role = 0;
                    theGlobals.role = 0;
                }

                if (!json["atype"] && !json["acttp"]) {
                    resp.acttp = 0;
                }

            }
        }
        return resp
    }

}
