import {RwConstants} from "@/app/RwConstants";
import {RwUser} from "@/app/dem/RwUser";
import {RwModel} from "@/app/dem/RwModel";
import dal from "@/app/dal/RwDal";
import theGlobals from "@/app/RwGlobals";
import globals from "@/app/RwGlobals";
import {RwLog} from "@/app/dal/RwLog";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwError, RwLoginError} from "@/app/RwErrors";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwLoginIssues} from "@/app/RwEnums";


export class RwTaskSync {


    static syncEssential(): Promise<RwModel> {
        const SOURCE = "RwTaskSync.syncEssential";


        return new Promise<RwModel>(function (resolve, reject) {

            if (dal.gotToken()) {

                let ridParm = ""
                let lastRouteId = RwPrefUtils.lastRouteId;
                //console.log(SOURCE, `lastRouteId:${lastRouteId}`, lastRouteId)
                if (lastRouteId != null && lastRouteId && lastRouteId.length > 0) {
                    ridParm = `&rid=${lastRouteId}`
                }

                let uinfo = new RwUser();
                let payload = JSON.stringify(uinfo.toJSON());
                let url = `${RwConstants.CoreUri}/sync/orgDataInit?oid=${uinfo.orgId}${ridParm}`;

                dal.callWithToken
                    .post(url, payload, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        if (res && res.data) {
                            let json = res.data;
                            //console.log(SOURCE, "json:", json);
                            let model = RwModel.fromJson(json);
                            if (model.lastSync != null) {
                                theGlobals.lastSyncMS = model.lastSync.getTime();
                            }
                            resolve(model);
                        } else {
                            reject()
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                RwLog.error(SOURCE, `Invalid token: ${RwPrefUtils.token}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }


    static syncSecondary(): Promise<RwModel> {
        const SOURCE = "RwTaskSync.syncSecondary";

        return new Promise<RwModel>(function (resolve, reject) {

            if (dal.gotToken() && theGlobals.isLoggedIn) {

                let uinfo = new RwUser();
                let payload = JSON.stringify(uinfo.toJSON());

                let gotOrdId = uinfo && RwSysUtils.isGuidReal(uinfo.orgId);
                if (gotOrdId) {
                    //if(uinfo.orgId && RwPrefUtils.token && RwPrefUtils.token !== RwConstants.EmptyGuid) {

                    let url = `${RwConstants.CoreUri}/sync/orgDataBack?oid=${uinfo.orgId}`;

                    dal.callWithToken
                        .post(url, payload, {timeout: RwConstants.NetTimeout})
                        .then(res => {
                            if (res) {
                                if (res.data) {
                                    let json = res.data;
                                    //console.log(SOURCE, "json", json);
                                    let model = RwModel.fromJson(json);
                                    //console.log(SOURCE, "model.sites.length:", model.sites.length);
                                    resolve(model);
                                } else {
                                    RwLog.warn(SOURCE, `POST url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                                    reject(new RwError(400, false));
                                }
                            } else {
                                RwLog.error(SOURCE, `POST url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                                reject(new RwError(400, false));
                                reject()
                            }
                        })
                        .catch(error => {
                            //REVIEW: Log this!
                            reject(error);
                        });
                } else {
                    RwLog.warn(SOURCE, `Missing Reqs: Token:${RwPrefUtils.token} OrgId:${uinfo.orgId}`);
                }

            } else {
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }


    static syncDelta(lastRouteId: string | null): Promise<RwModel> {
        const SOURCE = "RwTaskSync.syncDelta";

        return new Promise<RwModel>(function (resolve, reject) {

            if (dal.gotToken()) {

                let uinfo = new RwUser();
                let gotOrgId = RwSysUtils.isGuidReal(uinfo.orgId);
                if (gotOrgId) {

                    uinfo.openIds = [];
                    let lastSyncMS: number = theGlobals.lastSyncMS;
                    let payload = JSON.stringify(uinfo.toJSON());
                    let ridParm = lastRouteId ? `&rid=${lastRouteId}` : "";
                    let url = `${RwConstants.CoreUri}/sync/orgDataDelta?oid=${uinfo.orgId}${ridParm}&ts=${lastSyncMS}`;

                    dal.callWithToken
                        .post(url, payload, {timeout: RwConstants.NetTimeout})
                        .then(res => {
                            if (res) {
                                if (res.data) {
                                    let json = res.data;
                                    let model = RwModel.fromJson(json);
                                    //console.log(SOURCE, json);
                                    if (model.lastSync != null) {
                                        theGlobals.lastSyncMS = model.lastSync.getTime();
                                    }
                                    resolve(model);
                                } else {
                                    RwLog.error(SOURCE, `POST url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token} \n\nPayload:\n${payload}`);
                                    reject();
                                }
                            } else {
                                RwLog.warn(SOURCE, `POST url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}\n\nPayload:\n${payload}`);
                                reject();
                            }
                        })
                        .catch(err => {
                            if (theGlobals.checkNotHandled(err)) {
                                let status = err.status;
                                if (status != 403 && status != 406) {
                                    RwLog.warn(SOURCE, `POST url:${url} -> ${err.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload} \n\nError:\n${err}`)
                                } else {
                                    RwLog.warn(SOURCE, `POST url:${url} -> ${err.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload} \n\nError:\n${err}`)
                                }
                            }
                            reject(err);
                        });
                } else {
                    if (globals.isLoggedIn) {
                        RwLog.error(SOURCE, `Missing OrgId:${uinfo.orgId}, lastRouteId:${lastRouteId}`);
                    }
                    reject(new RwError(400, false));
                }

            } else {
                RwLog.warn(SOURCE, `Invalid token: ${RwPrefUtils.token}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }


    static syncStatus(routeId: string, routeIds: string[]): Promise<RwModel | void> {
        const SOURCE = "RwTaskRoutes.syncStatus";

        return new Promise<RwModel | void>(function (resolve, reject) {

            if (dal.gotToken()) {

                let lastSyncStatusMS: number = globals.lastStatusMS;
                let url = `${RwConstants.CoreUri}/sync/status?rid=${routeId}&ts=${lastSyncStatusMS}`;

                let gotOrgId = globals.orgId && globals.orgId.length > 0;
                let gotUserId = globals.userId && globals.userId.length > 0;
                let gotRouteIds = routeIds && routeIds.length > 0;
                if (gotUserId && gotOrgId && gotRouteIds) {
                    //console.log(SOURCE, "routeIds:", routeIds);
                    let payload = JSON.stringify(routeIds);

                    dal.callWithToken
                        .post(url, payload, {timeout: RwConstants.NetTimeout})
                        .then(res => {
                            if (res) {
                                if (res.data) {
                                    const json = res.data;
                                    //console.log(SOURCE, json);
                                    let model = RwModel.fromJson(json);
                                    if (model.lastSync != null) {
                                        globals.lastStatusMS = model.lastSync.getTime();
                                    }

                                    let actStatus = model.syncStatus.find(s => s.routeId === routeId);
                                    if (actStatus && actStatus.driverLS) {
                                        //console.log("set driverLastSync:", actStatus.driverLS);
                                        globals.driverLastSyncMS = actStatus.driverLS.getTime();
                                    }

                                    resolve(model);

                                    //console.warn(SOURCE, "callback json:", json);
                                    //let statusList = RwRouteStatusUpdate.fromJsonArray(json);
                                    //resolve(statusList);
                                } else {
                                    RwLog.error(SOURCE, `POST ${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                                    reject(new RwError(res.status, false))
                                    resolve();
                                }
                            } else {
                                RwLog.error(SOURCE, `POST ${url} -> MISSING \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                                reject(new RwError(400, false))
                            }

                        })
                        .catch((err) => {

                            if (theGlobals.checkNotHandled(err)) {
                                if (err) {
                                    let errText = JSON.stringify(err)
                                    let msg = `POST ${url} -> ${err.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload} \n\nError:\n${errText}`;
                                    if (err.error && (err.error.code === "ECONNABORTED" || err.error.message === "Network Error")) {
                                        err.isHandled = true;
                                        RwLog.warn(SOURCE, `ECONNABORTED: \n${msg}`);
                                    } else {
                                        if (err.status && err.status == 403) {
                                            err.isHandled = true;
                                            RwLog.warn(SOURCE, `Forbidden: \n${msg}`);
                                        } else {
                                            if (err.error == "Error: Network Error") {
                                                err.isHandled = true;
                                                RwLog.warn(SOURCE, `Network Error: \n${msg}`);
                                            } else {
                                                RwLog.error(SOURCE, `Unhandled Error: \n${msg}`);
                                            }
                                        }
                                    }
                                }
                            }

                            //RwLog.warn(SOURCE, `UNHANDLED \nPOST url:${url} -> ${err.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload} \n\nError:\n${err}`)
                            reject(err);
                        });
                } else {
                    RwLog.warn(SOURCE, `input: uid:${globals.userId}, rid:${routeId}, orgId:${globals.orgId} rids:${routeIds}`);
                    reject({message: "Route Input Error"});
                }

            } else {
                RwLog.warn(SOURCE, `Invalid token: ${RwPrefUtils.token}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }


}


