
import {Component, Vue, Watch} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import {RwUser} from "@/app/dem/RwUser";
import {RwTaskBillingProfile} from "@/app/dal/RwTaskBillingProfile";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {MigrateLegacyToFlexResult} from "@/app/dem/Profile/MigrateLegacyToFlexResult";
import {RwLog} from "@/app/dal/RwLog";
import moment from "moment";
import {OptInPref} from "@/app/RwEnums";

@Component
export default class RwFlexOptIn extends Vue {

  driversModel: { name: string, userId: string; selected: boolean }[] = [];
  idxTab = 0;
  nextRenewalDate: string = "";
  displayErrorMigration = false;

  get globals(): RwGlobals {
    return theGlobals;
  }

  get display(): boolean {
    const dateOk = this.nextRenewalDate !== "" ?
        moment(moment()).diff(moment((this.nextRenewalDate), "MM/DD/YYYY"), 'minutes') < 0
        :
        false;
    return this.globals.showFlexOptIn && dateOk;
  }

  set display(val: boolean) {
    this.globals.showFlexOptIn = val;
  }

  get selectedDrivers(): string[] {
    return this.driversModel.filter(drv => drv.selected).map(drv => drv.userId);
  }

  get monthlyCost(): number {
    return 10 + (10 * this.selectedDrivers.length);
  }

  get driversList(): RwUser[] {
    return this.globals.drivers.filter(drv => drv.userId !== this.globals.userId);
  }

  @Watch("driversList")
  watchDriversList(val) {
    this.driversModel = this.driversList.map(drv => ({name: drv.name, userId: drv.userId, selected: true}))
  }

  autoRenew() {
    RwTaskBillingProfile.ResumeAutoRenew().catch(err => {
      if (!this.globals.isLegacy) this.displayErrorMigration = true;
    });
  }

  async optIn() {
    const confirmBody = "We're so glad to keep you in the tribe.  Please confirm you choice to renew your subscription at the new price plan of $10 USD for each driver.";
    const doOptIn = await this.globals.showConfirmDialog("Confirm Opt-In", confirmBody, "Confirm", "Cancel")
    if (!doOptIn) return;

    RwPrefUtils.flexOptInSelected = OptInPref.OptedIn;
    //REVIEW: This was not previously saved to webPrefs!!!
    // Set auto renew true
    this.autoRenew();
    this.globals.saveWebPrefs();

    if (this.globals.isLegacy) {
      const optInOptions = {
        drivers: this.selectedDrivers
      };

      let confirmation = true;

      //REVIEW: Tommy, is this block still necessary? I thought this was removed.
      // if (this.selectedDrivers.length < this.driversList.length) {
      //   try {
      //     const removedCount = this.driversList.length - this.selectedDrivers.length;
      //     const text = `Migration will keep ${this.selectedDrivers.length} drivers and remove ${removedCount} drivers. Do you want to continue?`;
      //     confirmation = await this.globals.showConfirmDialog(`Confirm Drivers Selection`, text, `Confirm`, `Cancel`);
      //   }
      //   catch (err) {
      //     RwLog.error(`RwFlexOptIn.OptIn`, `Error on confirm dialog: ${err.message}`);
      //   }
      // }

      if (confirmation) {
        RwLog.log(`RwFlexOptIn.OptIn`, `Drivers List: ${JSON.stringify(this.selectedDrivers)}`)
        RwTaskBillingProfile.MigrateLegacyToFlex(optInOptions.drivers)
            .then(resp => {
              this.globals.showSnack(`The account was migrated successfully.`, "success");
              this.globals.isLegacy = false;
              this.globals.drivers = this.globals.drivers.filter(drv => this.selectedDrivers.indexOf(drv.userId) !== -1);
              this.globals.showOptInPostMessage = true;
            })
            .catch((err: MigrateLegacyToFlexResult) => {
              RwLog.error("RwFlexOptIn.optIn", `Migrate error: ${err.Error}`);
              this.globals.showSnack(`There was an error migrating the account.`, "error");
              this.displayErrorMigration = true;
            });
      }
    }
    this.close();
  }

  close() {
    this.idxTab = 1;
    RwPrefUtils.lastOptInDisplayed = true;
    this.globals.showFlexOptIn = false;
  }

  closeErrorMigration() {
    this.displayErrorMigration = false;
  }

  calcRenewalDate(date) {
    let momentDate = moment(date);
    if (momentDate.format('MM') == "09") momentDate.add(1, "month");
    return momentDate.format("MM/DD/YYYY");
  }

  mounted() {
    /* let isOwner = this.globals.isDriver == false;
     if (isOwner) {
       RwTaskBillingProfile.GetBillingProfile()
         .then((profile) => {
           this.nextRenewalDate = this.calcRenewalDate(profile.ProStatus.NextPaymentDate)
         });
     }*/
  }

}
