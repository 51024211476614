
/**
 * Generic Dialog
 *
 * Implementation:
 *  - Set store via globals
 *    - genericDialog.title = Title for the modal.
 *    - genericDialog.text = Text or HTML for the content
 *    - genericDialog.icon = "success" or any material icon ("warning", "error")
 *    - genericDialog.confirmCallback = callback for confirm button. After callback invocation executes reset (not close) method.
 *    - genericDialog.onCloseCallback = callback to execute before closing (via resetting global properties). This will not execute if confirm method is called.
 */
import {Component, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from '@/app/RwGlobals';

@Component({})
export default class RwGenericDialog extends Vue {

  get globals(): RwGlobals {
    return theGlobals;
  }

  get icon(): string {
    switch (this.globals.genericDialog.icon) {
      case "success":
        return "check_circle";
      default:
        return this.globals.genericDialog.icon;
    }
  };

  get iconColor(): string {
    switch (this.globals.genericDialog.icon) {
      case "success":
        return "primary";
      case "warning":
        return "warning";
      case "error":
        return "error";
      default:
        return "white";
    }
  }

  confirm() {
    this.globals.genericDialog.confirmCallback();
    this.reset();
  }

  close() {
    if (this.globals.genericDialog.onCloseCallback) this.globals.genericDialog.onCloseCallback();
    this.reset();
  }

  /**
   * Close dialog and set timeout to reset all properties to null
   * to enhance animation.
   */
  reset() {
    this.globals.genericDialog.show = false;
    setTimeout(() => this.globals.genericDialogReset(), 1000);
  }
}
