
import {Component, Prop, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import theSiteSpace, {RwSiteSpace} from "@/app/views/sites/RwSiteSpace";
import {RwSite} from "@/app/dem/RwSite";
import {RwSked} from "@/app/dem/RwSked";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwTaskSkeds} from "@/app/dal/RwTaskSkeds";
import theStore from "@/store"
import {RwLog} from "@/app/dal/RwLog";
import {RwDateUtils} from "@/app/utils/RwDateUtils";
import {RwTextUtils} from "@/app/utils/RwTextUtils";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import moment from "moment";
import {RwStop} from "@/app/dem/RwStop";

@Component
export default class RwSiteEdit extends Vue {

  //region Props

  @Prop(RwSite) readonly initSite: RwSite;

  idxTab: number = 0;
  updatedEmail: string = this.selectedSite.email;
  updatedPhone: string = this.selectedSite.phone;
  updatedNote: string = this.selectedSite.note;
  updatedAddress: string = this.selectedSite.address;
  updatedName: string = this.selectedSite.name;
  updatedScv: number = this.selectedSite.visitTime;

  hours: RwSked[] = this.selectedSite.getActiveAvailability();
  selectedSked: RwSked;
  isNewSked: boolean = false;
  skedName: string = "Restricted Hours";
  skedDays: boolean[] = [false, false, false, false, false, false, false];
  skedStartTime: number = -1;
  skedFinishTime: number = -1;
  calculatedDuration = -1;
  baseColor = this.selectedSite.baseColor;
  visitTime = this.selectedSite.visitTime;
  valid: boolean = false;
  // skedStartTimeLK: number = -1;
  // deleteSelectText = "Select a Schedule to Edit";
  // deleteActive = false;
  // deleteColor = "color: white";


  get space(): RwSiteSpace {
    return theSiteSpace;
  }

  get globals(): RwGlobals {
    return theGlobals;
  }

  get dateUtils(): RwDateUtils {
    return RwDateUtils
  }

  get title(): string {
    //Edit: {{selectedSite.name}}
    let titleText = "Edit Site"
    switch (this.idxTab) {
        //@formatter:off
      case 0:
        titleText = `Edit Favorite: ${this.selectedSite.name}`;
        break;
      case 1:
        titleText = `Manage Hours`;
        break;
      case 2:
        titleText = this.isNewSked ? "Add Hours" : `Edit Hours`;
        break;
      case 3:
        titleText = `Edit Color`;
        break;
      case 4:
        titleText = `Edit Visit Time`;
        break;
      default:
        titleText = `Edit: ${this.selectedSite.name}`
        break;
        //@formatter:on
    }
    return titleText;
  }

  get is12H(): boolean {
    return RwDateUtils.is12H;
  }

  get selectedSite(): RwSite {
    return this.initSite;
  }

  get siteUrl(): string {
    let url: string = "";
    if (this.selectedSite) {
      url = this.selectedSite.dataUrl;
    }
    return url;
  }


  get showSave(): boolean {
    return this.idxTab == 0
  }

  get gotHours(): boolean {
    let gotHours = false;
    if (this.hours && this.hours.length > 0) {
      gotHours = true;
    }
    return gotHours;
  }

  get hoursList(): string {
    let text = "Unrestricted";
    if (this.hours && this.hours.length > 0) {
      text = "";
      this.hours.forEach(h => {
        let line = h.displayFull;
        text += (text === "") ? line : "\n" + line;
      })
    }
    return text;
  }

  //endregion Props ..


  //#region Summary Props

  get selectedHoursName(): string {
    return this.skedName;
  }

  set selectedHoursName(hoursName: string) {
    this.skedName = hoursName;
  }

  get emailText(): string {
    return this.updatedEmail
  }

  set emailText(emailBoxText: string) {
    if (emailBoxText) {
      this.updatedEmail = (emailBoxText.length > 254) ? emailBoxText.substr(0, 254) : emailBoxText;
    } else {
      this.updatedEmail = ""
    }
  }

  get phoneText(): string {

    return this.updatedPhone
  }

  set phoneText(phoneBoxText: string) {
    if (phoneBoxText) {
      this.updatedPhone = (phoneBoxText.length > 25) ? phoneBoxText.substr(0, 25) : phoneBoxText;
    } else {
      this.updatedPhone = "";
    }
  }

  get noteText(): string {
    return this.updatedNote
  }

  set noteText(noteBoxText: string) {
    if (noteBoxText) {
      this.updatedNote = (noteBoxText.length > 1000) ? noteBoxText.substr(0, 1000) : this.updatedNote = noteBoxText;
    } else {
      this.updatedNote = noteBoxText;
    }
  }

  get addressText(): string {
    return this.selectedSite.address
  }

  set addressText(addressBoxText: string) {
    this.updatedAddress = addressBoxText
  }

  get nameText(): string {
    return this.selectedSite.name
  }

  set nameText(nameBoxText: string) {
    this.updatedName = nameBoxText
  }

  get scvTime(): number {
    return (this.selectedSite.visitTime == -1) ? 10 : this.selectedSite.visitTime;
  }

  set scvTime(boxNumber: number) {
    this.updatedScv = (boxNumber < 1) ? 10 : boxNumber;
  }

  //#endregion


  //#region DatePicker Props

  skedStartMins = RwPrefUtils.skedStartMins;
  skedFinishMins = RwPrefUtils.skedFinishMins;
  menuStart = false;
  menuFinish = false;
  time = "";

  //#endregion


  //#region TimePicker Props

  startMoment(): moment.Moment {
    let mins = this.skedStartMins;
    let date = moment().startOf('day');
    date = date.add(mins, "minutes");
    //console.log("startMoment", date);
    return date;
  }


  finishMoment(): moment.Moment {
    let mins = this.skedFinishMins;
    let date = moment().startOf('day');
    date = date.add(mins, "minutes");
    //console.log("finishMoment", date);
    return date;
  }

  get startDisplay(): string {
    let trigger = this.selectedSked;
    let timeText = this.startMoment().format("LT");
    //console.log("startDisplay", timeText);
    return timeText;
  }

  get finishDisplay(): string {
    let timeText: string;
    let trigger = this.selectedSked;
    let date = this.finishMoment();
    //console.log("finishDisplay", date);
    if (this.skedStartMins < this.skedFinishMins) {
      timeText = date.format("LT");
    } else {
      timeText = date.format("LT") + " (next day)";
    }
    //console.log("finishDisplay", this.skedStartMins, this.skedFinishMins, timeText);
    return timeText;
  }


  get startTime(): string {
    let trigger = this.selectedSked;
    let timeText = this.momentToTime(this.startMoment());
    //console.log("GET startTime", timeText);
    return timeText;
  }

  set startTime(val) {
    let mins = this.minsFromTime(val);
    let delta = this.skedFinishMins - this.skedStartMins;
    //console.log("SET startTime val, mins, delta", val, mins, delta);

    if (delta >= 0) {
      //Adjust the finish time keeping the duration constant
      let minsInDay = 24 * 60;
      let newFinish = mins + delta;
      newFinish = (newFinish < minsInDay) ? newFinish : (minsInDay) - (minsInDay - newFinish);
      this.skedFinishMins = newFinish;

      //console.log("SET startTime fixed", newFinish);
      this.skedStartMins = mins;
    } else {
      // If finish time before start time, set finish time to after start time
      this.skedFinishMins = mins + 60;
      this.skedStartMins = mins;
    }

  }

  get finishTime(): string {
    let trigger = this.selectedSked;
    let timeText = this.finishMoment().format("HH:mm");
    //console.log("GET finishTime", timeText);
    return timeText;
  }

  set finishTime(val: string) {
    let finishMins = this.minsFromTime(val);
    //console.log("SET finishTime", val, finishMins);
    this.skedFinishMins = finishMins;
  }

  get siteNameRules() {
    return [v => (v && v.length <= 96 && v !== "") || 'Name is required and must be 96 characters or less.']
  }

  get addressRules() {
    return [v => (v && v.length <= 200 && v !== "") || 'Address is required and must be 200 characters or less.']
  }

  private minsFromTime(val: string): number {
    let startOfDay = moment().startOf('day');
    let time = moment(val, 'HH:mm');
    let mins = time.diff(startOfDay, "minutes");
    return mins
  }

  private momentToTime(mo: moment.Moment) {
    let timeText = mo.format("HH:mm");
    return timeText;
  }


  skedTimeFrame(sked: RwSked): string {
    let text = sked.displayHours;
    return text;
  }


  //#endregion getters setters time pickers


  //region Nav Ops


  onBackClick() {
    let targetTab = 0;
    switch (this.idxTab) {
        //@formatter:off
      case 0:
        targetTab = 0;
        break;
      case 1:
        targetTab = 0;
        break;
      case 2:
        targetTab = 1;
        break;
      case 3:
        targetTab = 0;
        break;
      case 4:
        this.m_visitOver = this.tabInitVisitOver;
        this.m_visitTime = this.tabInitVisitTime;
        targetTab = 0;
        break;
        break;
      default:
        break;
        //@formatter:on
    }
    //console.log("onBackClick ", this.m_visitOver, this.m_visitTime)
    this.idxTab = targetTab
  }

  onEscape() {
    if (this.idxTab === 0) {
      this.closeConditional();
    } else {
      this.idxTab = (this.idxTab == 3 || this.idxTab == 4) ? 0 : this.idxTab - 1;
    }
  }

  enterPress() {
    this.saveTab();
  }

  onCommitClick() {
    this.saveTab();
  }

  saveTab() {

    switch (this.idxTab) {
        //@formatter:off
      case 0:
        this.saveAndExit(true);
        break;
      case 1:
        this.closeConditional();
        break;
      case 2:
        this.saveSchedule();
        break;
      case 3:
        //this.saveColor();
        this.closeConditional();
        break;
      case 4:
        this.closeConditional();
      default:
        //("NotPossible")
        //@formatter:on
    }
  }

  //HACK: using magic values (-1);
  //HACK: Just order the tabs in the desired order to get the index you want
  getTabIndex(): number {
    let finalTab = -1;
    //@formatter:off
    switch (this.idxTab) {
      case 1:
        finalTab = 0;
        break;
      case 2:
        finalTab = 1;
        break;
      case 3:
        finalTab = 0;
        break;
      case 4:
        finalTab = 0;
        break;
      default:
        finalTab = -1;
    }
    //@formatter:on
    return finalTab
  }

  closeConditional() {
    this.idxTab = this.getTabIndex();
    if (this.idxTab === -1) {
      this.close();
    }
  }

  close() {
    theStore.dispatch("showSiteEdit", false).catch();
  }


  //endregion


  //#region Sked Ops

  get headers(): any[] {
    return [
      // {text: "Name", align: "left", value: "name"},
      {text: "Time", align: "left", value: "timeStart", width: 150, sortable: true},
      {text: "Mo", align: "left", value: "Monday", width: 1, sortable: false},
      {text: "Tu", align: "left", value: "Tuesday", width: 1, sortable: false},
      {text: "We", align: "left", value: "Wednesday", width: 1, sortable: false},
      {text: "Th", align: "left", value: "Thursday", width: 1, sortable: false},
      {text: "Fr", align: "left", value: "Friday", width: 1, sortable: false},
      {text: "Sa", align: "left", value: "Saturday", width: 1, sortable: false},
      {text: "Su", align: "left", value: "Sunday", width: 1, sortable: false},
      {
        text: "",
        value: "action",
        width: 48,
        align: "center",
        sortable: false
      }
    ];
  }

  onEditHours(sked: RwSked) {
    // let SOURCE = "RwSiteEdit.onEditHours"
    // console.log(SOURCE, sked.displayHours);

    this.skedName = sked.name;
    this.skedStartTime = sked.timeStart;
    this.skedFinishTime = sked.timeFinish;
    this.calculatedDuration = this.skedFinishTime - this.skedStartTime;
    this.skedStartMins = this.skedStartTime / 60000;
    this.skedFinishMins = this.skedFinishTime / 60000;

    this.skedDays[0] = sked.Monday;
    this.skedDays[1] = sked.Tuesday;
    this.skedDays[2] = sked.Wednesday;
    this.skedDays[3] = sked.Thursday;
    this.skedDays[4] = sked.Friday;
    this.skedDays[5] = sked.Saturday;
    this.skedDays[6] = sked.Sunday;
    this.isNewSked = false;
    this.selectedSked = sked;
    this.idxTab = 2;
  }

  onDeleteHours(sked: RwSked) {
    const self = this;
    const SOURCE = "RwSiteEdit.onDeleteHours"

    this.globals.deleteSkedsDB([sked.skedId])
        .then(() => {
          //this.toggleDelete();
          //this.globals.showSnack("Delete Mode Off", "warning")
          //this.idxTab
          self.hours = self.selectedSite.getActiveAvailability();
        })
        .catch(err => {
          //this.toggleDelete()
        })
  }

  createSked() {
    let sked = new RwSked;
    sked.markedForDelete = false;
    sked.type = 1;
    sked.priority = 0;
    sked.isHovering = false;
    sked.isSelectedItem = false;
    sked.skedId = RwSysUtils.guidNew();
    sked.siteId = this.selectedSite.siteId;
    sked.accountId = this.selectedSite.accountId;
    sked.dow = 2;
    sked.timeStart = RwPrefUtils.skedStartMins * 60000;
    sked.timeFinish = RwPrefUtils.skedFinishMins * 60000;
    this.skedStartMins = RwPrefUtils.skedStartMins;
    this.skedFinishMins = RwPrefUtils.skedFinishMins;
    sked.isActive = true;
    sked.name = "Hours";

    this.selectedSked = sked;
    this.skedName = sked.name;
    this.skedStartTime = sked.timeStart;
    this.skedFinishTime = sked.timeFinish;
    this.skedDays = [true, true, true, true, true, false, false];
    this.isNewSked = true;
    this.idxTab = 2;
  }

  //endregion


  //region Visit Ops

  visitTrigger = 0;
  isVisitRangeValid = false;
  m_visitTimeCalc = 0;
  m_visitOver = false;
  m_visitTime = 0;
  tabInitVisitOver = false;
  tabInitVisitTime = 0;

  //private defaultVisitTime = 0;

  get visitText(): string {
    //Adding Observable value just for trigger;
    let vizOver = this.m_visitOver;
    let vizTime = this.m_visitTime;
    let visText = RwStop.visitTimeTextPrimitive(this.m_visitOver, this.visitTimeCalc);
    //console.warn("RwStopEdit.visitText", vizOver, vizTime, visText)
    return visText;
  }

  get canApplyVisit(): boolean {
    let isDefault = !this.m_visitOver;
    let gotRequired = !!this.m_visitTime || this.visitTime === 0;
    let gotValidRange = this.isVisitRangeValid;
    let gotOverDelta = this.tabInitVisitOver != this.m_visitOver;
    let gotTimeDelta = RwSysUtils.isLooseDeltaNum(this.tabInitVisitTime, this.m_visitTime);
    let canApply = isDefault || (gotRequired && gotValidRange && (gotOverDelta || gotTimeDelta));
    //console.log("canApplyVisit:: isDefault || (gotRequired,  gotValidRange && (gotOverDelta || gotTimeDelta))", isDefault, gotRequired, gotValidRange, (gotOverDelta || gotTimeDelta))
    return canApply;
  }


  get canApplySked(): boolean {
    let canApply = true;
    return canApply;
  }


  get isInitOverride(): boolean {
    return this.initVisitTime >= 0;
    //return this.initStop && this.initStop.visitTime >= 0
  }

  get rules() {
    const self = this;
    return {
      required: value => {

        if (!self.m_visitOver || (!!value || value === 0)) {
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return true;
        } else {
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return false;
        }
      },
      visitRange: value => {
        if (!self.m_visitOver || value >= 0 && value <= 999) {
          self.isVisitRangeValid = true;
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return true;
        } else {
          self.isVisitRangeValid = false;
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return 'Between 0 to 999'
        }
      },
    }
  }

  get isVisitOverride(): boolean {
    return this.m_visitOver;
  }

  set isVisitOverride(val: boolean) {
    this.m_visitOver = val;
    this.m_visitTime = val ? RwPrefUtils.visitTime : -1;
    //console.log("SET isVisitOverride", val, this.m_visitOver, this.m_visitTime)
  }

  get visitTimeCalc(): number {
    let trigger1 = this.visitTrigger;
    let trigger2 = this.m_visitOver;
    this.m_visitTimeCalc = this.m_visitOver ? this.m_visitTime : RwPrefUtils.visitTime;
    //console.log("GET visitTimeCalc time, m_isOverride, m_visitTime, defaultVisitTime", this.m_visitTime, RwPrefUtils.visitTime, this.m_visitTimeCalc)
    return this.m_visitTimeCalc;
  }

  set visitTimeCalc(val: number) {
    if (this.m_visitOver) {
      if (val >= 0 && val <= 999) {
        this.m_visitTime = val;
        this.m_visitTimeCalc = val;
      } else {
        if (val < 0) {
          this.m_visitTime = 0;
          this.m_visitTimeCalc = 0;
        }
        if (val > 999) {
          this.m_visitTime = 999;
          this.m_visitTimeCalc = 999;
        }
      }
    } else {
      this.m_visitTime = -1;
    }
    this.visitTrigger++;
    //console.log("SET visitTimeCalc", val, this.m_visitOver, this.m_visitTime, this.m_visitTimeCalc)
  }

  private onVisitClick() {
    this.initVisitTab()
    this.idxTab = 4;
  }

  private onCommitVisitTime(event: Event) {
    event.stopPropagation();
    if (!this.m_visitOver) {
      this.m_visitTime = -1;
    }
    //console.log("onCommitVisitTime SET", this.m_visitOver, this.m_visitTime);
    this.idxTab = 0;
  }

  private initVisitTab() {
    this.tabInitVisitOver = this.m_visitOver;
    this.tabInitVisitTime = this.m_visitTime;
    //console.log("initVisitTab ", this.tabInitVisitOver, this.tabInitVisitTime)
  }

  private setVisitTime() {
    if (this.initSite) {
      if (this.isInitOverride) {
        this.m_visitOver = true;
        this.m_visitTime = this.initSite.visitTime;
        this.tabInitVisitOver = this.m_visitOver;
        this.tabInitVisitTime = this.m_visitTime;
      } else {
        this.m_visitOver = false;
        this.m_visitTime = RwPrefUtils.visitTime;
        this.tabInitVisitOver = this.m_visitOver;
        this.tabInitVisitTime = this.m_visitTime;
      }
    }
    //console.log("setVisitTime", this.m_visitOver, this.m_visitTime)
  }


  //endregion Visit Override


  //#region Color Ops

  get siteColor(): string {
    //let sitesColor = (this.color == "NaN") ? this.selectedSite.baseColor : this.color;
    //if(this.baseColor)
    //this.color = sitesColor;
    //return this.color;

    return this.baseColor;
  }

  set siteColor(boxColorValue: string) {
    this.baseColor = boxColorValue;
    this.onColorPick(boxColorValue)
  }

  onColorPick(color: string) {
    if (color.length === 9 && color.endsWith("FF")) {
      color = color.substring(0, 7);
    }
    //console.log("RwColorPicker.onColorPick color", color);
    this.baseColor = color;
    this.idxTab = 0;
  }

  //#endregion Color


  //region Save Ops

  get canSave(): boolean {
    let canSave = true;
    return canSave;
  }


  saveSchedule() {
    const self = this;
    const SOURCE = "RwSiteEdit.saveSchedule";

    this.selectedSked.name = this.selectedHoursName;
    //let validHours = RwDateUtils.validateTimes((this.skedStartMins * 60000), (this.skedFinishMins * 60000));
    // this.selectedSked.timeStart = validHours[0];
    // this.selectedSked.timeFinish = validHours[1];

    this.selectedSked.timeStart = this.skedStartMins * 60000
    this.selectedSked.timeFinish = this.skedFinishMins * 60000

    this.selectedSked.duration = this.calculatedDuration;
    this.selectedSked.dow = (this.skedDays[0]) ? 2 : 0;
    this.selectedSked.dow += (this.skedDays[1]) ? 4 : 0;
    this.selectedSked.dow += (this.skedDays[2]) ? 8 : 0;
    this.selectedSked.dow += (this.skedDays[3]) ? 16 : 0;
    this.selectedSked.dow += (this.skedDays[4]) ? 32 : 0;
    this.selectedSked.dow += (this.skedDays[5]) ? 64 : 0;
    this.selectedSked.dow += (this.skedDays[6]) ? 128 : 0;

    RwPrefUtils.skedStartMins = this.skedStartMins;
    RwPrefUtils.skedFinishMins = this.skedFinishMins;

    if (this.isNewSked) {
      if (this.hours) {
        this.hours.push(this.selectedSked);
      } else {
        this.hours = [this.selectedSked]
      }
      this.onCommitAddSked(SOURCE);
    } else {
      this.onCommitSked(SOURCE);
    }
    this.isNewSked = false;

  }


  onCommitSked(SOURCE: string) {
    let self = this;
    RwTaskSkeds.updateSked(this.selectedSked)
        .then(() => {
          //self.globals.showSnack("Schedule Updated", "success");
          self.globals.commitSkeds()
          self.idxTab = 1;
        })
        .catch(err => {
          // self.rollbackSchedules();
          RwLog.consoleError(SOURCE + "WTF: Schedule not Updated" + err.toString());
          self.globals.showSnack("Schedule Failed to Update", "error");
        });
  }


  onCommitAddSked(SOURCE: string) {
    let self = this;
    RwTaskSkeds.addSked(this.selectedSked)
        .then(() => {
          //self.globals.showSnack("Schedule Added", "success");
          self.globals.skeds.push(this.selectedSked);
          self.idxTab = 1;
        })
        .catch(err => {
          this.globals.showSnack(("Failed to Create Schedule"), "error");
        });
  }

  isValidForm() {
    let vueForm = this.$refs.form as any;
    let isValid = vueForm.validate();
    return isValid;
  }

  saveAndExit(save: boolean) {
    const self = this;
    const SOURCE = "RwSiteEdit.onCommit";
    let isValidForm = this.isValidForm();
    if (isValidForm) {
      if (save) {
        if (this.updatedName.length > 0 && this.updatedName.length <= 96) {
          let site = this.selectedSite;
          let finalName = (this.updatedName) ? this.updatedName : site.name;
          let finalAddress = (this.updatedAddress.length > 1) ? this.updatedAddress : RwTextUtils.formatCoords(site.lat, site.lng);
          site.name = finalName;
          site.address = finalAddress;
          site.email = this.updatedEmail;
          site.phone = this.updatedPhone;
          site.visitTime = this.m_visitTime;
          site.note = this.updatedNote;
          site.baseColor = this.baseColor;

          self.globals
              .updateSiteDB(site, [SOURCE])
              .then(isCommit => {
                if (isCommit) {
                  site.lastHash = Date.now();
                  self.globals.commitSites();
                  self.globals.showSnack("Favorite saved.", "success");
                  this.onEscape()
                } else {
                  self.rollbackSite();
                  self.globals.showSnack("Favorite save failed.", "error");
                }
              })
              .catch(err => {
                self.rollbackSite();
                RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
                self.globals.showSnack("Favorite save failed.", "error");
              });
        } else {
          theGlobals.showSnack("Please enter a valid name", "error");
        }
      } else {
        this.close()
      }
    } else {
      this.globals.showSnack("Please ensure your entries meet the requirements and try again.", "error");
    }
  }


  //endregion Save Ops


  //#region LifeCycle
  initName;
  initAddress;
  initEmail;
  initPhone;
  initVisitTime;
  initNote;
  initBaseColor;

  mounted() {
    const self = this;
    Vue.nextTick(function () {
      self.initValues();
    });
    this.initName = this.initSite.name;
    this.initAddress = this.initSite.address;
    this.initEmail = this.initSite.email;
    this.initPhone = this.initSite.phone;
    this.initVisitTime = this.initSite.visitTime;
    this.initNote = this.initSite.note;
    this.initBaseColor = this.initSite.baseColor;
  }

  initValues() {
    //console.log("RwSiteEdit.mounted initSite", this.initStop.name);
    this.setVisitTime()
  }

  rollbackSite() {
    let site = this.selectedSite;
    site.name = this.initName;
    site.address = this.initAddress;
    site.email = this.initEmail;
    site.phone = this.initPhone;
    site.visitTime = this.initVisitTime;
    site.note = this.initNote;
    site.baseColor = this.initBaseColor;
  }

  //#endregion


}
