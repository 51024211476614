import store from "@/app/RwGlobals"

export class RwRegInfo {
    paymentChanged: boolean = false;

    phone: string;
    address: string;
    name: string;
    coname: string;

    isUpgrade: boolean;

    domain: string;

    userId: string;
    userName: string;
    password: string;
    token: string;
    cancelCreditString: string;
    askToCancelCredit: boolean = false;
    memberId: string;
    promptForIndi: boolean = false;
    hasIosSub: boolean;
    hasAndroidSub: boolean;
    hasWebSub: boolean;

    static fromJSON(json): RwRegInfo {
        var regInfo = new RwRegInfo();

        if (json != null) {
            for (var key in json) {
                switch (key.toLowerCase()) {
                    case "token":
                        store.token = json[key];
                        regInfo.token = json[key];
                        break;
                    case "userid":
                        store.userId = json[key];
                        regInfo.userId = json[key];
                        break;
                    case "username":
                        store.userName = json[key];
                        regInfo.userName = json[key];
                        break;
                    case "password":
                        regInfo.password = json[key];
                        break;
                    case "coname":
                        regInfo.coname = json[key];
                        break;
                    case "name":
                        regInfo.name = json[key];
                        break;
                    case "phone":
                        regInfo.phone = json[key];
                        break;
                    case "address":
                        regInfo.address = json[key];
                        break;
                    case "isupgrade":
                        regInfo.isUpgrade = json[key];
                        break;

                    case "domain":
                        regInfo.domain = json[key];
                        break;

                    case "creditcancelresult":
                        regInfo.cancelCreditString = json[key];
                        break;

                    case "asktocancelcredit":
                        regInfo.askToCancelCredit = json[key];
                        break;

                    case "hasiossub":
                        regInfo.hasIosSub = json[key];
                        break;

                    case "hasandroidsub":
                        regInfo.hasAndroidSub = json[key];
                        break;

                    case "haswebsub":
                        regInfo.hasWebSub = json[key];
                        break;

                    case "memberid":
                        regInfo.memberId = json[key];
                        break;

                    case "promptforindi":
                        regInfo.promptForIndi = json[key];
                        break;
                }
            }
        }

        return regInfo;
    }

    toJSON() {
        let json = {};

        if (this.userId != null) {
            json["uid"] = this.userId;
        }
        if (this.userName != null) {
            json["uname"] = this.userName;
        }
        if (this.password != null) {
            json["upass"] = this.password;
        }
        if (this.token != null) {
            json["tkn"] = this.token;
        }

        if (this.name != null) {
            json["name"] = this.name;
        }
        if (this.address != null) {
            json["address"] = this.address;
        }
        if (this.phone != null) {
            json["phone"] = this.phone;
        }
        if (this.coname != null) {
            json["coname"] = this.coname;
        }

        return json;
    }
}
