import {RwRoute} from "@/app/dem/RwRoute";
import {RwStop} from "@/app/dem/RwStop";
import {RwLog} from "@/app/dal/RwLog";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwConstants} from "@/app/RwConstants";
import store from "@/app/RwGlobals"
import theGlobals from "@/app/RwGlobals"
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwTextUtils} from "@/app/utils/RwTextUtils";
import {RwOptResult} from "@/app/dem/RwOptResult";
import {RwError, RwLoginError} from "@/app/RwErrors";
import {RwLoginIssues} from "@/app/RwEnums";
import dal from "@/app/dal/RwDal";


export class RwTaskOptimize {

    done = false;
    surl = "";
    payload = ""
    route: RwRoute;
    jobJSON = {};
    jobId: string = RwSysUtils.guidNew();


    static optAsync(route: RwRoute, actStops: RwStop[]): Promise<RwRoute> {
        let optDal = new RwTaskOptimize();
        return optDal.optAsync(route, actStops);
    }

    private optAsync(route: RwRoute, actStops: RwStop[]): Promise<RwRoute> {
        let self = this;
        const SOURCE = "RwTaskOptimize.optAsync";
        self.route = route;

        return new Promise<RwRoute>(function (resolve, reject) {

            let gotValidStart = route.lat && route.lng && route.lat != 0 && route.lng != 0;
            if (gotValidStart) {

                self.surl = `${RwConstants.CoreSocketUri}/opt/optimize`;
                self.jobJSON = route.toJsonOpt(self.jobId, actStops);
                self.optHandler(false)
                    .then(route => resolve(route))
                    .catch(ex => reject(ex))
            } else {
                let stack = RwSysUtils.printStackTrace();
                RwLog.error(SOURCE, `Invalid route start: ${route.lat}, ${route.lng} \nRouteId:${route.routeId} \n\n${stack}`);
                reject(new Error(`Invalid Start`));
            }

        });
    }


    private optHandler(isRetry: boolean): Promise<RwRoute> {
        const self = this;
        const SOURCE = "RwTaskOptimize.optHandler";

        return new Promise<RwRoute>(function (resolve, reject) {

            dal.getAuthToken()
                .then(function (token: string) {

                    //HACK: sometimes token == null;
                    token = RwPrefUtils.token;

                    self.jobJSON["tkn"] = token;
                    self.payload = JSON.stringify(self.jobJSON);

                    self.optSocket()
                        .then(function (result: RwOptResult) {

                            let code = result.code;
                            //console.log("opt response", code);
                            switch (code) {

                                case 200:
                                    self.route.fromJsonOpt(result.json);
                                    store.updateRoute(self.route);
                                    resolve(self.route);
                                    //xTEST
                                    // RwPrefUtils.token = RwConstants.EmptyGuid;
                                    break;

                                case 401:
                                    if (isRetry == false) {
                                        theGlobals.token = RwConstants.EmptyGuid;
                                        self.optHandler(true)
                                            .then(route => resolve(route))
                                            .catch(ex => reject(ex))
                                    } else {
                                        let errSesh = new RwLoginError(code, true, null, RwLoginIssues.SeshExpired)
                                        self.forceLogout(errSesh);
                                        reject(errSesh);
                                    }
                                    break;

                                case 403:
                                case 406:
                                    let errSesh = new RwLoginError(code, true, null, RwLoginIssues.SeshExpired)
                                    self.forceLogout(errSesh);
                                    reject(errSesh);
                                    break;

                                default:
                                    let errDefault = new RwLoginError(code, true, null, RwLoginIssues.SeshExpired);
                                    RwLog.consoleError(`Unhandled Code: ${self.surl} -> ${code} \nToken:${token}\n${self.payload}`);
                                    reject(errDefault);
                                    break;

                            }

                        })
                        .catch(function (e) {
                            if (e) {
                                let errText = e.toString();
                                if (errText.contains("Error opening socket")) {
                                    RwLog.warn(SOURCE, `SocketError: ${errText} \n${self.surl} \nAuthToken:${token},\n\n${self.payload}`);
                                } else {
                                    RwLog.error(SOURCE, `Unhandled:${errText} \n${self.surl} \nAuthToken:${token} \n\n${self.payload}`);
                                }
                            } else {
                                RwLog.error(SOURCE, `Unhandled:Missing Error \n${self.surl} \nAuthToken:${token} \n\n${self.payload}`);
                            }

                            reject(e);
                        })

                })
                .catch(function (ex) {
                    let err = new RwLoginError(500, true, ex, RwLoginIssues.SeshExpired);
                    self.forceLogout(err);
                    reject(ex)
                })

        });

    }


    private optSocket(): Promise<RwOptResult> {
        let self = this;
        const SOURCE = "RwTaskOptimize.optSocket";
        let result = new RwOptResult();

        return new Promise<RwOptResult>(function (resolve, reject) {
            let returnedResult = false;
            let optSocket = new WebSocket(self.surl);

            optSocket.onerror = ev => {
                RwLog.warn(SOURCE, `Socket Error: ${self.surl} ${self.route.name}, RouteId:${self.route.routeId}`);
                reject(new Error(`Error opening socket: URL: ${self.surl}\\n Timestamp: ${ev.timeStamp}`));
                optSocket.close();
            };

            optSocket.onopen = ev => {
                //console.log("optSocket.onopen", self.surl);
                optSocket.send(self.payload);

                //Monitor: Timeouts seem to occur for less < 3 mins; Capture actual time here
                let t0 = performance.now()
                let timeout = setTimeout(() => {
                    if (!returnedResult) {
                        RwLog.consoleError("Socket Timeout");
                        let t1 = performance.now();
                        let elapsedSeconds = (t1 - t0) / 1000.0;
                        let elapsedText = RwTextUtils.formatDuration(elapsedSeconds);

                        RwLog.error(SOURCE, `TimeOut: ${self.route.name} in ${elapsedText} \n${self.surl} \nToken:${RwPrefUtils.token}\nJobId:${self.jobId}\nPayload:\n${self.payload}`);
                        reject(new Error(`Timeout optimizing`));
                        returnedResult = true;
                        if (optSocket.readyState === 1) {
                            optSocket.close();
                        }
                    }
                }, RwConstants.OptTimeout);

                optSocket.onmessage = msg => {
                    returnedResult = true;
                    clearTimeout(timeout);

                    let msgJson = JSON.parse(msg.data);
                    if (msgJson) {
                        result.code = msgJson["code"];
                        result.json = msgJson;
                        //console.log("optSocket.onmessage", result.code);
                        resolve(result);
                    } else {
                        RwLog.consoleError("WTF: optSocket.onmessage NO DATA");
                        reject(new RwError(500, false, `No data returned from socket`));
                        //console.log("optSocket.onmessage reject", 500);
                    }

                    optSocket.close();
                };

                optSocket.onclose = ev => {
                    //console.log("optSocket.onclose");
                    clearTimeout(timeout);
                };

            };

        });
    }


    private forceLogout(error?: RwError) {
        dal.forceLogout(error);
    }

}
