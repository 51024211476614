import {RwSked} from "@/app/dem/RwSked";
import {RwModel} from "@/app/dem/RwModel";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import store from "@/app/RwGlobals"

export class RwTaskSkeds {

    static addSked(sked: RwSked): Promise<RwModel> {
        return new Promise<RwModel>(function (resolve, reject) {

            let payload = JSON.stringify(sked.toJSON());
            let url = `${RwConstants.CoreUri}/skeds/skeds?uid=${store.orgId}&sid=${sked.skedId}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let deltas = new RwModel();
                    deltas.fromJson(json);
                    resolve(deltas);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }

    static updateSked(sked: RwSked): Promise<RwModel> {
        return new Promise<RwModel>(function (resolve, reject) {
            let json = sked.toJSON();

            let payload = JSON.stringify(json);
            let url = `${RwConstants.CoreUri}/skeds/skeds?uid=${store.orgId}&sid=${sked.skedId}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .put(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let deltas = new RwModel();
                    deltas.fromJson(json);
                    resolve(deltas);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }

    static deleteSkeds(shedIds: string[]): Promise<RwModel> {
        return new Promise<RwModel>(function (resolve, reject) {

            let payload = JSON.stringify(shedIds);
            let url = `${RwConstants.CoreUri}/skeds/skeds?uid=${store.orgId}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .delete(url, {data: payload, timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let deltas = new RwModel();
                    deltas.fromJson(json);
                    resolve(deltas);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }
}
