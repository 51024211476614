export default class AttributionData {
    aid = "";
    cid = "";
    agid = "";
    kid = "";
    gclid = "";
    utmSource = "";
    utmCampaign = "";
    utmTerm = "";
    utmMedium = "";
    utmExp = "";
    referDate = "";
    rc = "";
    fvid = "";
    adid = 0;
    fbclid: string = "";

    ppb = "";

    ppd = "";

    ppt = "";
}