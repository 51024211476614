
import {Component, Prop, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import thePlan, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import RwSearchView from "@/app/views/planner/RwSearchView.vue";

@Component({
  components: {RwSearchView}
})
export default class RwSearchPanel extends Vue {
  @Prop({default: true}) ! showSearchOptions: boolean;
  @Prop({default: true}) ! enableSearchActions: boolean;

  get globals(): RwGlobals {
    return theGlobals;
  }

  get space(): RwPlannerSpace {
    return thePlan;
  }

  onClose() {
    this.globals.showSearchPanel = false;
    this.space.checkHistLimitPopups(this.space.activeRoute);
    this.space.searchReportInfo = [];
    if (this.globals.isMobileView) this.$emit('close');
  }

  onSearchSettings() {
    this.space.showSearchOptions = this.showSearchOptions;
  }


}
