import moment from "moment";
import {RwAvatarInfo, RwCreateDriver, RwUser, RwUserInfoExtra} from "@/app/dem/RwUser";
import {RwMember} from "@/app/dem/Profile/RwAcctPickerModel";
import {
    CheckMigrateReturnModel,
    CreateDriversReturnModel,
    CreateNewSubTeamModel,
    CreateNewSubTeamReturnModel,
    ProvisioningModel,
    RwLicenseCounts,
    SubTeamModel
} from "../dem/Drivers/CreateBulkDriversResponse";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import theGlobals from "@/app/RwGlobals";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwLog} from "@/app/dal/RwLog";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import GetSubTeamsResponseModel from "@/app/dem/Drivers/GetSubTeamsResponseModel";
import {RwError} from "@/app/RwErrors";
import {RwUpdateAddOnsModel, RwUpdateAddOnsResultModel} from "@/app/dem/AddOns/RwUpdateAddOnsModel";

export class RwTaskLicense {


    //region User Info

    static updateUser(driver: RwUser): Promise<boolean> {
        let self = this;

        return new Promise<boolean>(function (resolve, reject) {

            let payload = JSON.stringify(driver.toJSON());
            let url = `${RwConstants.CoreUri}/users/updateUser?accessType=${driver.accessType}`;
            //console.warn("accessType POSTed is: ", driver.accessType);
            dal.callWithToken
                .post(url, payload)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });

        });
    }


    static deleteDriver(userId: string): Promise<boolean> {
        let self = this;

        return new Promise<boolean>(function (resolve, reject) {
            if (!userId || userId === RwConstants.EmptyGuid) return reject(new RwError(200, true, "Driver ID is empty GUID on deleteDriver."));
            let url = `${RwConstants.CoreUri}/team/deleteDriver?uidForDelete=${userId}`;
            dal.callWithToken
                .delete(url)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });

        });
    }


    static setOwnerPassword(currentPassword: string, newPassword: string): Promise<boolean> {
        let self = this;

        return new Promise<boolean>(function (resolve, reject) {

            let newPwdEnc = encodeURIComponent(newPassword);
            let curPwdEnc = encodeURIComponent(currentPassword);
            let url = `${RwConstants.CoreUri}/profile/SetOwnerPassword?currentPassword=${curPwdEnc}&newPassword=${newPwdEnc}`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }


    static requestNewPass(userName: string): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {
            //let url = `${RwConstants.CoreUri}/profile/ResetPassRequest?username=${encodeURIComponent(userName)}`;
            let unameTrim = userName.trim();
            if (unameTrim.length > 0) {
                let url = `${RwConstants.CoreUri}/acct/resetReq?username=${encodeURIComponent(userName)}`;
                let payload = JSON.stringify({ap_lang: "en", uname: userName.trim()});

                dal.callSansToken
                    .post(url, payload)
                    .then(res => {
                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject();
            }
        });
    }


    static changeDriverUsername(userId: string, newUserName: string): Promise<boolean> {
        let self = this;

        return new Promise<boolean>(function (resolve, reject) {

            let tkn = theGlobals.token;
            let unameEnc = encodeURIComponent(newUserName);
            let url = `${RwConstants.CoreUri}/users/RenameDriver?userId=${userId}&newUserName=${unameEnc}`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });

        });
    }


    //endregion User Info


    //region Avatar Ops


    static updateAvatarUser(user: RwUser): Promise<boolean> {
        const SOURCE = "RwTaskLicense.updateAvatarUser";
        return new Promise<boolean>((resolve, reject) => {
            if (user) {
                RwTaskLicense.updateAvatar(user.avColor, user.avInits, user.userId, user.avType)
                    .then(res => resolve(true))
                    .catch(err => resolve(false))
            } else {
                RwLog.error(SOURCE, `Unexpected: user == null, logged in user:${JSON.stringify(new RwUser())} \n\n stack: ${new Error().stack}`);
                reject();
            }
        });
    }


    static updateAvatar(avColor: string, avInits: string, userId: string, avType: number): Promise<RwAvatarInfo> {
        const SOURCE = "RwTaskLicense.updateAvatar";

        if (!avColor || avColor.length > 7) {
            let stack = RwSysUtils.printStackTrace;
            RwLog.error("RwTaskLicense.updateAvatar", `avColor:${avColor} \n\nStack:\n${stack}`)
        }

        return new Promise<RwAvatarInfo>(function (resolve, reject) {
            const encColor = encodeURIComponent(avColor);
            const encInits = encodeURIComponent(avInits);

            const url = `${RwConstants.CoreUri}/users/avatar?uid=${userId}&type=${avType}&color=${encColor}&inits=${encInits}`;
            const payload = {userId, avType, avInits, avColor};
            return dal.callWithToken
                .post(url, payload)
                .then(res => {
                    if (res) {
                        if (res.data) {
                            const avInfo = RwAvatarInfo.fromJson(res.data);
                            if (avInfo && avInfo.avUrl) {
                                if (userId == RwPrefUtils.userId) {
                                    theGlobals.avInfo = avInfo;
                                }

                                //Update drivers pin
                                let driver = theGlobals.drivers.find(d => d.userId == userId);
                                if (driver) {
                                    driver.imageUrl = avInfo.avUrl;
                                } else {
                                    //REVIEW: Log this!
                                }

                                resolve(avInfo);

                            } else {
                                let msgMiss = avInfo ? avInfo.avUrl ? "" : "Missing avUrl" : "Missing avInfo";
                                let msg = `${msgMiss} \nPOST url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`
                                RwLog.error(SOURCE, `Unexpected: ${msg}`);
                                reject();
                            }
                        } else {
                            RwLog.warn(SOURCE, `POST url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                            reject();
                        }
                    } else {
                        RwLog.error(SOURCE, `POST url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                        reject();
                    }
                })
        });
    }


    //endregion Avatar Ops


    //region Team Ops


    static updateDomain(domain: string): Promise<Array<RwUser>> {
        let self = this;

        return new Promise<Array<RwUser>>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/team/UpdateDomain?domain=${domain}`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    resolve(res.data ?? []);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    //endregion Team Info


    //region SubTeam Ops

    static emailCreatedDrivers(orgName: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {

            let url = `${RwConstants.CoreUri}/account/emailcreateddrivers?orgName=${encodeURIComponent(orgName)}`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }

    static updateAddOns(addOnChanges: RwUpdateAddOnsModel): Promise<RwUpdateAddOnsResultModel> {
        return new Promise<RwUpdateAddOnsResultModel>( (resolve, reject) => {
            const payload = JSON.stringify(addOnChanges);
            const url = `${RwConstants.CoreUri}/account/UpdateAddOns`;
            dal.callWithToken
                .post(url, payload)
                .then(res => {
                    const json = res.data;
                    resolve(<RwUpdateAddOnsResultModel>json);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }

    static createBulkDrivers(drivers: Array<RwCreateDriver>): Promise<CreateDriversReturnModel> {
        return new Promise<CreateDriversReturnModel>(function (resolve, reject) {

            let payload = JSON.stringify(drivers);
            let url = `${RwConstants.CoreUri}/account/CreateBulkDrivers`;
            dal.callWithToken
                .post(url, payload)
                .then(res => {
                    const json = res.data;
                    resolve(<CreateDriversReturnModel>json);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static createNewSubTeam(newTeam: CreateNewSubTeamModel): Promise<CreateNewSubTeamReturnModel> {
        const payload = JSON.stringify(newTeam);
        const url = `${RwConstants.CoreUri}/account/CreateNewSubTeam`;
        return dal.callWithToken
            .post(url, payload)
            .then(res => <CreateNewSubTeamReturnModel>res.data);
    }


    static getSubTeams(): Promise<GetSubTeamsResponseModel> {
        let url = `${RwConstants.CoreUri}/account/GetSubTeams`;
        return dal.callWithToken.get(url)
            .then(res => {
                const json = res.data;
                let teams = <GetSubTeamsResponseModel>json;
                return teams;
            });
    }


    static checkMigrateTeam(teamDispUname: string, teamDispPass: string): Promise<CheckMigrateReturnModel> {
        let tkn = theGlobals.token;
        let disp = encodeURIComponent(teamDispUname);
        let dispPw = encodeURIComponent(teamDispPass);
        let url = `${RwConstants.CoreUri}/account/CheckMigrateTeamAccount?teamDispLogin=${disp}&teamDispPw=${dispPw}`;
        return dal.callWithToken
            .get(url)
            .then(res => {
                const json = res.data;
                let response = <CheckMigrateReturnModel>json;
                return response;
            });
    }


    static migrateTeam(teamDispUname: string, migrateToken: string): Promise<boolean> {
        let tokenEnc = encodeURIComponent(migrateToken);
        let teamNameEnc = encodeURIComponent(teamDispUname);
        let url = `${RwConstants.CoreUri}/account/MigrateTeamAccount?teamDispLogin=${teamNameEnc}&migrateToken=${tokenEnc}`;
        return dal.callWithToken
            .post(url)
            .then(res => true);
    }


    static getAssignedUsers(): Promise<ProvisioningModel> {
        let self = this;
        return new Promise<ProvisioningModel>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/team/GetAssignedUsers`;
            dal.callWithToken
                .get(url)
                .then(res => {
                    const json = res.data;
                    let provisionedModel = <ProvisioningModel>json;
                    provisionedModel.Drivers = RwUser.fromJsonArray(
                        provisionedModel.Drivers
                    );
                    provisionedModel.InactiveMembers = RwMember.fromJsonArray(
                        provisionedModel.InactiveMembers
                    );
                    provisionedModel.DriverExtra = <Array<RwUserInfoExtra>>json.DriverExtra;

                    if (provisionedModel.DriverExtra) {
                        provisionedModel.DriverExtra.forEach(dextra => {
                            var driverColl = provisionedModel.Drivers.filter(
                                drv => drv.userId === dextra.UserId
                            );

                            if (driverColl && driverColl.length > 0) {
                                var driver = driverColl[0];
                                driver.lastUpdated = moment.utc(dextra.Updated).toDate();
                                driver.created = moment.utc(dextra.Created).toDate();
                            }
                        });
                    }

                    resolve(provisionedModel);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });

        });
    }


    static deleteSubTeam(orgIdToDelete: string): Promise<boolean> {
        let url = `${RwConstants.CoreUri}/team/DeleteSubTeam?orgIdToDelete=${orgIdToDelete}`;
        return dal.callWithToken
            .delete(url)
            .then(res => true);
    }


    static updateSubTeam(subTeamUpdates: SubTeamModel): Promise<boolean> {
        let payload = JSON.stringify(subTeamUpdates);
        let url = `${RwConstants.CoreUri}/account/UpdateSubTeamDetails`;
        return dal.callWithToken
            .post(url, payload)
            .then(res => true);
    }


    //endregion SubTeam Ops


    //region Licence Ops

    static getLicenseCount(): Promise<RwLicenseCounts> {
        let self = this;

        return new Promise<RwLicenseCounts>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/team/GetLicenseCount`;
            dal.callWithToken
                .get(url)
                .then(res => {
                    const json = res.data;
                    var licenseCounts = new RwLicenseCounts(json);
                    if (licenseCounts) {
                        theGlobals.licenseCounts = licenseCounts;
                    }
                    resolve(licenseCounts);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


/*    static updateLicenseCount(newUserLicenseCount): Promise<boolean> {
        let self = this;

        return new Promise<boolean>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/team/UpdateLicenseCount?newVirtualDriverCount=${newUserLicenseCount}`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }


    static incrementLicenseCount(): Promise<boolean> {
        let self = this;

        return new Promise<boolean>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/team/IncrementLicenseCount`;

            dal.callWithToken
                .post(url)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }*/


    //endregion Licence Ops

}


