export default class RwPages {
    static Register = "register";
    static Login = "login";
    static ResetPassword = "resetpassword";
    static ProInfo = "pro";
    static RouteList = "routes";
    static Planner = "planner";
    static CompleteRegistration = "purchasesuccess";
    static Upgrade = "upgrade";
    static Uploads = "uploads";
    static Tasks = "tasks";
    static Favorites = "sites";
    static ForgotPassword = "forgotpassword";
    static Unsubscribe = "unsubscribe";
    static ReferralOnboard = "referonboard";
    static Account = "account";
    static Marketplace = "marketplace";
    static Drivers = "drivers";
}
