export class RwStopWithRoute {
    public StopId: string;
    public RouteId: string;
    public StopName: string;
    public RouteName: string;

    static fromArray(json: object[]): RwStopWithRoute[] {
        var array = [];
        if (json) {
            json.forEach(obj => {
                array.push(<RwStopWithRoute>obj);
            });
        }
        return array;
    }
}
