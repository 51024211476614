export class RwStopCheckDateModel {
    public CheckDate: Date;
    public StopId: string;

    constructor(stopId: string, checkDate: Date) {
        this.CheckDate = checkDate;
        this.StopId = stopId;
    }

    static fromArray(json: object[]): RwStopCheckDateModel[] {
        var array = [];
        if (json) {
            json.forEach(sc => {
                var scmodel = <any>sc;
                array.push(
                    new RwStopCheckDateModel(scmodel.StopId, new Date(scmodel.CheckDate))
                );
            });
        }

        return array;
    }
}
