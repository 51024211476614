import {RwAddReportEnum, RwAddStatusEnum, RwAddVoteEnum} from "@/app/RwEnums";

export default class RwAddReport {
    reportId: number;
    address: string;
    name: string;
    userId: string;
    userName: string;
    type: RwAddReportEnum;
    vote: RwAddVoteEnum;
    status: RwAddStatusEnum;
    lat: number;
    lng: number;
    latDelta: number;
    lngDelta: number;
    query: string;
    viewPort: string;
    lang: string;
    country: string;
    CD: number;
    LU: number;
    LU_By: string;
    message: string;
    step = 0;
    entityId: string = "";

    static fromJsonArray(jsonArray: Object[]) {
        let entries: RwAddReport[] = [];
        if (jsonArray != null && jsonArray.length > 0) {
            jsonArray.forEach(function (jval) {
                let logEntry = RwAddReport.fromJson(jval);
                entries.push(logEntry);
            });
        }
        return entries;
    }

    static fromJson(json: Object): RwAddReport {
        let report = new RwAddReport();
        for (let key in json) {
            switch (key) {
                //@formatter:off
                case "rid":
                    report.reportId = json[key];
                    break;
                case "add":
                    report.address = json[key];
                    break;
                case "uid":
                    report.userId = json[key];
                    break;
                case "unm":
                    report.userName = json[key];
                    break;
                case "name":
                    report.name = json[key];
                    break;
                case "type":
                    report.type = json[key];
                    break;
                case "vote":
                    report.vote = json[key];
                    break;
                case "status":
                    report.status = json[key];
                    break;
                case "lat":
                    report.lat = json[key];
                    break;
                case "lng":
                    report.lng = json[key];
                    break;
                case "lat∆":
                    report.latDelta = json[key];
                    break;
                case "lng∆":
                    report.lngDelta = json[key];
                    break;
                case "query":
                    report.query = json[key];
                    break;
                case "view":
                    report.viewPort = json[key];
                    break;
                case "lang":
                    report.lang = json[key];
                    break;
                case "cntry":
                    report.country = json[key];
                    break;
                case "cd":
                    report.CD = json[key];
                    break; //case "dte": this.date = new Date(json[key]); break;
                case "lu":
                    report.LU = json[key];
                    break;
                case "lu_by":
                    report.LU_By = json[key];
                    break;
                case "note":
                    report.message = json[key];
                    break;

                //@formatter:on
            }
        }
        return report;
    }


    toJSON() {
        let json = {
            rid: this.reportId,
            add: this.address,
            name: this.name,
            uid: this.userId,
            type: this.type,
            vote: this.vote,
            status: this.status,
            lat: this.lat,
            lng: this.lng,
            cd: this.CD,
            lu: this.LU,
            lu_by: this.LU_By,
        };

        json["lat∆"] = this.latDelta;
        json["lng∆"] = this.lngDelta;

        if (this.userName != null) json["unm"] = this.userName;
        if (this.query != null) json["query"] = this.query;
        if (this.viewPort != null) json["view"] = this.viewPort;
        if (this.lang != null) json["lang"] = this.lang;
        if (this.country != null) json["cntry"] = this.country;
        if (this.message != null) json["note"] = this.message;

        return json;
    }


}