

import moment from "moment";
import {Component, Prop, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwSked} from "@/app/dem/RwSked";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwSysUtils} from "@/app/utils/RwSysUtils";

@Component
export default class RwSkedPicker extends Vue {

  //#region Props

  @Prop(Number) readonly initPriority: number | undefined;
  @Prop(Number) readonly initStartMils: number | undefined;
  @Prop(Number) readonly initFinishMils: number | undefined;

  idxTab = 0;
  idxSked = -1;
  skedPriority = RwPrefUtils.skedPriority;
  skedStartMins = RwPrefUtils.skedStartMins;
  skedFinishMins = RwPrefUtils.skedFinishMins;
  selectedSked: RwSked = null;
  menuStart = false;
  menuFinish = false;
  time = "";

  get globals(): RwGlobals {
    return theGlobals;
  }

  get space(): RwPlannerSpace {
    return thePlanSpace;
  }

  get title(): string {
    let title: string;
    switch (this.idxTab) {
        //@formatter:off
      case 0:
        title = 'Pick Schedule';
        break;
      case 1:
        title = `One-Time Schedule`;
        break;
      case 2:
        title = 'Multi-Use Schedule';
        break;
        //@formatter:on
    }
    return title;
  }

  get backColor() {
    return this.globals.isDarkMode ? "rwBackDark" : "rwBackLight";
  }

  get loading(): boolean {
    return this.globals.isLoading;
  }

  get isDarkMode(): boolean {
    return this.globals.isDarkMode;
  }

  get isSameDay(): boolean {
    return this.skedStartMins <= this.skedFinishMins;
  }

  get isValid(): boolean {
    let isValid = false;
    //@formatter:off
    switch (this.idxTab) {
      case 1:
        isValid = true;
        break;
      case 2:
        isValid = !!this.selectedSked;
        break;
    }
    //@formatter:on
    return isValid;
  }

  get startMoment(): moment.Moment {
    return moment().startOf('day').add(this.skedStartMins, "minutes");
  }

  get finishMoment(): moment.Moment {
    return moment().startOf('day').add(this.skedFinishMins, "minutes");
  }

  get startDisplay(): string {
    let timeText = this.startMoment.format("LT");
    //console.log("GET startDisplay", timeText);
    return timeText;
  }

  get finishDisplay(): string {

    let timeText: string;
    if (this.skedStartMins < this.skedFinishMins) {
      timeText = this.finishMoment.format("LT");
    } else {
      timeText = this.finishMoment.format("LT") + " (next day)";
    }
    //console.log("finishDisplay", this.skedStartMins, this.skedFinishMins, timeText);
    return timeText;
  }

  get startTime(): string {
    let timeText = this.momentToTime(this.startMoment);
    //console.log("GET startTime", timeText);
    return timeText;
  }

  set startTime(val) {
    this.skedStartMins = this.minsFromTime(val);
  }

  get finishTime(): string {
    return this.finishMoment.format("HH:mm");
  }

  set finishTime(val: string) {
    this.skedFinishMins = this.minsFromTime(val);
  }

  get skedColor(): string {
    let color: string = "";
    switch (this.skedPriority) {
        //@formatter:off
      case 1:
        color = "#01aee3";
        break;
      case 2:
        color = "#06b29e";
        break;
      case 3:
        color = "#01de7e";
        break;
      case 4:
        color = "#01e248";
        break;
      case 5:
        color = "#05cd3a";
        break;
      case 6:
        color = "#74dd3f";
        break;
      case 7:
        color = "#d7eb40";
        break;
      case 8:
        color = "#f0a732";
        break;
      case 9:
        color = "#eb5422";
        break;
      case 10:
        color = "#d1041c";
        break;
      default:
        break;
        //@formatter:on
    }
    return color
  }


  get warningMessage(): string | undefined {

    const routeStart = moment(this.globals.selectedRoute.startTime)
    const routeFinish = moment(this.globals.selectedRoute.finishTime);

    //Project the minute-based sked onto the same day as the route.
    let start = routeStart.startOf('day');
    let durationMins = this.skedDuration();
    const skedStart = start.clone().add(this.skedStartMins, "minutes");
    const skedFinish = skedStart.clone().add(durationMins, "minutes");

    let isSkedStartValid = skedStart < routeFinish;
    let isSkedFinishValid = skedFinish > routeStart;
    if (!isSkedStartValid || !isSkedFinishValid) {
      return "Schedule timeframe outside of route timeframe.";
    }
    return undefined;
  }

  private minsFromTime(val: string): number {
    let startOfDay = moment().startOf('day');
    let time = moment(val, 'HH:mm');
    let mins = time.diff(startOfDay, "minutes");
    return mins
  }

  private momentToTime(mo: moment.Moment) {
    let timeText = mo.format("HH:mm");
    return timeText;
  }

  private skedDuration() {
    let durationMins = 0;
    if (this.skedStartMins < this.skedFinishMins) {
      durationMins = this.skedFinishMins - this.skedStartMins
    } else {
      durationMins = (24 * 60) - this.skedFinishMins - this.skedStartMins;
    }
    return durationMins;
  }

  //#endregion Props


  //region Helper Ops

  allowedHours(v) {
    return v % 2;
  }

  allowedMinutes(v) {
    return v >= 10 && v <= 50;
  }

  allowedStep(m) {
    return m % 10 === 0;
  }


  //endregion


  //#region UI Nav


  onUseSingle() {
    //console.log("RwSkedPicker.onUseSingle");
    this.idxTab = 1;
  }

  onUseMulti() {
    //console.log("RwSkedPicker.onSkedClick");
    this.idxTab = 2;
  }

  onBackClick() {
    //console.log("onBackClick");
    this.idxTab = 0;
  }

  onEscape(event: Event) {
    event.stopPropagation();
    this.onCancel();
  }

  onCancel() {
    //console.log("RwSkedPicker.onCancel");
    this.globals.m_showSkedPicker = false;
  }

  //#endregion UI Nav


  //#region User Selections


  onSkedPick(sked: RwSked) {
    //console.log("onSkedPick sked", sked.name, sked.priority, sked.skedId);
    this.selectedSked = sked;
    this.skedPriority = sked.priority * 10;
    this.skedStartMins = sked.timeStart / 60000;
    this.skedFinishMins = sked.timeFinish / 60000;
    this.onCommit();
  }


  onCommit() {
    const SOURCE = "RwSkedPicker.onCommit"
    //console.log("RwSkedPicker.onSkedClick selectedSked", this.skedPriority, this.skedStartMins, this.skedFinishMins, this.skedSpanMins);
    //console.warn("RwSkedPicker.onCommit skedStartMins, skedFinishMins", this.skedStartMins, this.skedFinishMins);

    let sked: RwSked;
    if (this.selectedSked) {
      sked = this.selectedSked;
      //sked.skedId = this.selectedSked ? this.selectedSked.skedId : RwSysUtils.guidEmpty();
      //console.log("selectedSked");
    } else {
      sked = new RwSked();
      sked.priority = this.skedPriority / 10.0;
      sked.timeStart = this.skedStartMins * 60000;
      sked.timeFinish = this.skedFinishMins * 60000;
      sked.duration = this.skedDuration();
      sked.skedId = RwSysUtils.guidEmpty();
      //console.log("inline sked", sked.timeStart, sked.timeFinish, sked);
    }

    //console.warn("RwSkedPicker.onCommit skedId, pri, start, finish", sked.skedId, sked.priority, sked.timeStart, sked.timeFinish);


    //console.log("RwSkedPicker.onCommit skedId, pri, start, finish", sked.skedId, sked.priority, sked.timeStart, sked.timeFinish);

    RwPrefUtils.skedPriority = this.skedPriority;
    RwPrefUtils.skedStartMins = this.skedStartMins;
    RwPrefUtils.skedFinishMins = this.skedFinishMins;

    //console.log("RwSkedPicker.onCommit RwPrefUtils.skedPriority", RwPrefUtils.skedPriority);

    this.$emit("onSkedPick", sked);
    this.globals.m_showSkedPicker = false;
  }


  //#endregion User Events


  //#region LifeCycle

  mounted() {

    this.skedPriority = this.initPriority ? this.initPriority * 10.0 : RwPrefUtils.skedPriority;
    this.skedStartMins = this.initStartMils ? this.initStartMils / 60000 : RwPrefUtils.skedStartMins;
    this.skedFinishMins = this.initFinishMils ? this.initFinishMils / 60000 : RwPrefUtils.skedFinishMins;
    //console.log("RwSkedPicker.mounted RwPrefUtils.skedPriority, this.initPriority", RwPrefUtils.skedPriority, this.initPriority);
    // console.log("mounted skedS MILLIS", this.initStartMils, this.initFinishMils);
    // console.log("mounted RwPrefUtils MINS", RwPrefUtils.skedStartMins, RwPrefUtils.skedFinishMins);
    // console.log("mounted skedStartMins", this.skedStartMins, this.initStartMils, RwPrefUtils.skedStartMins);
    // console.log("mounted skedFinishMins", this.skedFinishMins, this.initFinishMils, RwPrefUtils.skedFinishMins);
  }

  //#endregion

}

