import {RwTextUtils} from "@/app/utils/RwTextUtils";
import {RwAddressValidator, RwPortType, RwSearchType} from "@/app/RwEnums";
import {RwConstants} from "@/app/RwConstants";

export class RwSearchResult {
    id: string = "";
    name: string = "";
    address: string = "";
    placeId: string = "";
    type: number = 0;
    lat: number = 0.0;
    lng: number = 0.0;
    navLat: number = 0.0;
    navLng: number = 0.0;
    portType: RwPortType;
    tag: string = "";
    phone: string = "";
    email: string = "";
    orderNumber: number = 0;
    href: string = "";
    nameId: number;
    isGeocode: boolean;
    query: string = "";

    IsCached: boolean;
    AccessPoint: RwAccessPoint;

    isSelectedItem: boolean = false;

    constructor(json?: Object) {
        if (json != null) {
            this.fromJson(json);
        }
    }

    get vueKey() {
        if (this.id) {
            return this.id;
        } else if (this.placeId) {
            return this.placeId;
        } else if (this.href) {
            return this.href;
        } else {
            return this.lat + this.lng;
        }
    }

    description(): string {
        let des = `name: ${this.name}, add: ${this.address}, type: ${this.type}, lat: ${this.lat}, lng: ${this.lng}`;
        return des;
    }

    get icon(): string {
        let iconUrl = "maps:place";
        switch (this.type) {
            //@formatter:off
            case RwSearchType.Web:
                iconUrl = "mdi-map-marker";
                break;
            case RwSearchType.Sites:
                iconUrl = "mdi-star";
                break;
            case RwSearchType.Tasks:
                iconUrl = "mdi-clipboard-check";
                break;
            case RwSearchType.Coord:
                iconUrl = "maps:my-location";
                break;
            //@formatter:on
        }
        return iconUrl;
    }

    // get iconImage(): string {
    //   if (this.type === RwSearchType.Sites) {
    //     let site = store.findSite(this.placeId);
    //     if (site) {
    //       return site.imgUrl;
    //     }
    //     return "images/icons/favStar.png";
    //   } else if (this.type === RwSearchType.Web) {
    //     if (this.isSelectedItem) {
    //       switch (this.orderNumber) {
    //         case 0:
    //           return "images/icons/pins/PinASelect.png";
    //         case 1:
    //           return "images/icons/pins/PinBSelect.png";
    //         case 2:
    //           return "images/icons/pins/PinCSelect.png";
    //         case 3:
    //           return "images/icons/pins/PinDSelect.png";
    //         case 4:
    //           return "images/icons/pins/PinESelect.png";
    //         case 5:
    //           return "images/icons/pins/PinFSelect.png";
    //
    //         default:
    //           return "images/icons/PinBlank.png";
    //       }
    //     } else {
    //       switch (this.orderNumber) {
    //         case 0:
    //           return "images/icons/pins/PinA.png";
    //         case 1:
    //           return "images/icons/pins/PinB.png";
    //         case 2:
    //           return "images/icons/pins/PinC.png";
    //         case 3:
    //           return "images/icons/pins/PinD.png";
    //         case 4:
    //           return "images/icons/pins/PinE.png";
    //         case 5:
    //           return "images/icons/pins/PinF.png";
    //
    //         default:
    //           return "images/icons/PinBlank.png";
    //       }
    //     }
    //   }
    //   return "";
    // }

    fromJson(json: Object) {
        for (let key in json) {
            switch (key) {
                case "nm":
                    this.name = json[key];
                    break;
                case "add":
                    this.address = json[key];
                    break;
                case "phn":
                    this.phone = json[key];
                    break;
                case "lat":
                    this.lat = json[key];
                    break;
                case "lon":
                    this.lng = json[key];
                    break;
                case "lng":
                    this.lng = json[key];
                    break;

                case "name":
                    this.name = json[key];
                    break;
                case "description":
                    this.address = json[key];
                    break;
                case "place_id":
                    this.placeId = json[key];
                    break;
                case "phone":
                    this.phone = json[key];
                    break;
                // case "lat":
                //   this.lat = json[key];
                //   break;
                // case "lon":
                //   this.lng = json[key];
                //   break;
                // case "lng":
                //   this.lng = json[key];
                //   break;
                case "href":
                    this.href = json[key];
                    break;
                case "nid":
                    this.nameId = json[key];
                    break;

                case "AccessPoint":
                    this.AccessPoint = json[key] as RwAccessPoint;
                    break;

                case "IsCached":
                    this.IsCached = json[key];
            }
        }
    }

    static fromJsonArray(jsonArray: Object[]) {
        let results: RwSearchResult[] = [];
        if (jsonArray != null) {
            jsonArray.forEach(function (jval) {
                let result = new RwSearchResult(jval);
                results.push(result);
            });
        }
        return results;
    }

    static fromGoogle(json: Object) {
        let result = new RwSearchResult();
        result.type = RwSearchType.Web;
        for (let key in json) {
            switch (key) {
                case "name":
                    result.name = json[key];
                    break;
                case "place_id":
                    result.placeId = json[key];
                    break;
                case "formatted_address":
                    result.address = json[key];
                    break;
                case "geometry":
                    let geometry = json["geometry"];
                    let location = geometry["location"];
                    result.lat = location["lat"];
                    result.lng = location["lng"];
                    break;
            }
        }

        //Create a name from address
        if (result.name == null || result.name == "") {
            result.name = RwTextUtils.getPartialName(result.address, 32);
        }

        return result;
    }

    static fromJsonPredictions(json: Object) {
        let results: RwSearchResult[] = [];

        for (let key in json) {
            switch (key) {
                case "predictions":
                    let jsonArray = json[key];
                    if (jsonArray != null) {
                        jsonArray.forEach(function (jval) {
                            let result = new RwSearchResult(jval);
                            results.push(result);
                        });
                    }
                    break;
            }
        }
        return results;
    }

    static fromJsonGeocode(json: RwAddress) {
        let results: RwSearchResult[] = [];

        if (json && json.Matches) {
            json.Matches.forEach(m => {
                var result = new RwSearchResult();
                result.name = m.nm;
                result.lat = m.lat;
                result.address = m.add;
                result.lng = m.lng;
                if (m.ports && m.ports.length > 0) {
                    result.navLat = m.ports[0].lat;
                    result.navLng = m.ports[0].lng;
                    result.portType = m.ports[0].pt;
                }
                if (m.nid) {
                    result.nameId = m.nid;
                }
                result.isGeocode = true;

                results.push(result);
            });
        }
        return results;
    }

    static fromGoogleAddressSearch(json: Object) {
        let results: RwSearchResult[] = [];
        for (let key in json) {
            switch (key) {
                case "results":
                    let jsonArray = json[key];
                    if (jsonArray != null) {
                        jsonArray.forEach(function (jval) {
                            let result = RwSearchResult.fromGoogle(jval);
                            results.push(result);
                        });
                    }
                    break;
            }
        }
        return results;
    }

    static fromGoogleCoordSearch(json: Object) {
        let results: RwSearchResult[] = [];
        for (let key in json) {
            switch (key) {
                case "results":
                    let jsonArray = json[key];
                    if (jsonArray != null) {
                        jsonArray.forEach(function (jval) {
                            let result = RwSearchResult.fromGoogle(jval);
                            results.push(result);
                        });
                    }
                    break;
            }
        }
        return results;
    }
}

export class GetAccessPointsModel {
    public Address: string;
    public SearchPtLat: number;
    public SearchPtLng: number;
    public SearchPtRadius: number;
    public PlaceHref: string;
    public Lang: string;
    public Country: string;
    public NameId: number;
}

//export class RwRepoResult {
//    public Code: number;
//    public Address: string;
//    public Places: Array<RwPlace>;
//}

export interface RwAccessPoint {
    lat: number;
    lng: number;
}

export class RwPort {
    lat: number;
    lng: number;
    pt: number;
    pid: number;
}

export interface RwPlaceResult {
    ports: RwPort[];
    nm: string;
    add: string;
    lat: number;
    lng: number;
    nid: number;

    // Computed
    pinImg: string;
    selected: boolean;
}

export class RwAddress {
    public Name: string;
    public Phone: string;
    public Note: string;
    public Source: string;
    public Priority: number;
    public Lat: number;
    public Lng: number;
    public PlaceId: number;
    public NameId: number;
    public PortType: number;
    public NavLat: number;
    public NavLng: number;
    public RawData: string;
    public House: string;
    public Street: string;
    public City: string;
    public State: string;
    public PostalCode: string;
    public CountryCode: string;
    public GeocodeQuality: string;
    public IsDataIssue: boolean;
    public IsGeocodeIssue: boolean;
    public Sequence: number;
    public status: string;
    public Matches: Array<RwPlaceResult>;
    public SvcTime: number;
    public AddId: string;
    public UploadId: string;
    public RouteId: string;
    public RouteName: string;
    public GeoKey: string;
    public BaseColor: string;
    public OpenTime: number;
    public CloseTime: number;
    public Completed: boolean;
    public AddStatus: number;

    public isHovering: boolean;
    public chosenMatch: number = -1;
    public skipped: boolean = false;
}

export class RwPortModel {
    Lat: number;
    Lng: number;

    IsSelected: boolean = false;

    portType: RwPortType;

    get PortTypeImg(): string {
        switch (this.portType) {
            case RwPortType.Roof:
                if (this.IsSelected) {
                    return RwConstants.IconRoofPin;
                }
                return RwConstants.IconRoofPinFaded;

            case RwPortType.Road:
                if (this.IsSelected) {
                    return RwConstants.IconRoadPin;
                }
                return RwConstants.IconRoadPinFaded;

            default:
                return "";
        }
    }

    get PortTypeStr(): string {
        {
            switch (this.portType) {
                case RwPortType.Roof:
                    return "Rooftop";

                case RwPortType.Road:
                    return "Road Access";

                default:
                    return "Entrance";
            }
        }
    }

    constructor(port: RwPort) {
        this.Lat = port.lat;
        this.Lng = port.lng;
        this.portType = <RwPortType>port.pt;
    }
}

export class RwPlaceModel {
    NameId: number;
    Name: string;
    Address: string;
    Lat: number;
    Lng: number;
    Phone: string;
    Ports: RwPortModel[];
    Source: RwAddressValidator;
    NavLat: number;
    NavLng: number;
    PortType: number;

    constructor(place: RwPlaceResult) {
        var self = this;
        this.Name = place.nm;
        this.Address = place.add;
        this.Lat = place.lat;
        this.Lng = place.lng;
        this.Ports = [];
        this.NameId = place.nid;
        // Add roof point
        var rootPtData: RwPort = {
            lat: place.lat,
            lng: place.lng,
            pt: RwPortType.Roof,
            pid: 0
        };
        var roofPt = new RwPortModel(rootPtData);
        roofPt.IsSelected = true;
        this.Ports.push(roofPt);
        if (place.ports) {
            place.ports.forEach(p => {
                self.Ports.push(new RwPortModel(p));
                if (p.pt === <number>RwPortType.Road) {
                    self.NavLat = p.lat;
                    self.NavLng = p.lng;
                    self.PortType = p.pt;
                }
            });
        } else {
            this.NavLat = this.Lat;
            this.NavLng = this.Lng;
        }
    }
}

//export class RwPlace {

//    // Place Id
//    public pid: string;

//    // Name
//    public nm: string;

//    // Address
//    public add: string;

//    public lat: number;

//    public lon: number;

//    public lng: number;

//    // Phone
//    public phn: string;

//    // Lat Roof
//    public latRf: number;

//    // Lng Roof
//    public lngRf: number;

//    // Entrance Type
//    public entType: RwEntranceType;
//    public Source: RwAddressValidator;

//    public Confidence: number;
