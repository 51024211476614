import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {PODDefaultSettings} from "@/app/dem/ProofOfDelivery/ProofOfDeliveryEnums";

const podModule = {
    state: {
        showProofOfDelivery: false,
        proofOfDelivery: RwPrefUtils.proofOfDelivery,
        showPODTutorial: false,
        showPODSettingsPopup: false,
        showedPODCallout: RwPrefUtils.showedPODCallout,
        showPODCallout: false,
    },
    getters: {
        showProofOfDelivery(state): boolean {
            return state.showProofOfDelivery;
        },

        proofOfDelivery(state): PODDefaultSettings {
            return state.proofOfDelivery;
        },

        showPODTutorial(state): boolean {
            return state.showPODTutorial;
        },

        showPODSettingsPopup(state): boolean {
            return state.showPODSettingsPopup;
        },

        showedPODCallout(state): boolean {
            return state.showedPODCallout;
        },

        showPODCallout(state): boolean {
            return state.showPODCallout;
        },
    },
    actions: {
        showProofOfDelivery({state, commit}, showProofOfDelivery: boolean) {
            commit("SHOW_PROOF_OF_DELIVERY", showProofOfDelivery);
        },

        proofOfDelivery({state, commit}, val: PODDefaultSettings) {
            commit("PROOF_OF_DELIVERY", val);
        },

        showPODTutorial({state, commit}, val: boolean) {
            commit("SHOW_POD_TUTORIAL", val);
        },

        showPODSettingsPopup({state, commit}, val: boolean) {
            commit("SHOW_POD_SETTINGS_POPUP", val);
        },

        showedPODCallout({state, commit}, val: boolean) {
            commit("SHOWED_POD_CALLOUT", val);
        },

        showPODCallout({state, commit}, val: boolean) {
            commit("SHOW_POD_CALLOUT", val);
        },
    },
    mutations: {
        SHOW_PROOF_OF_DELIVERY(state, val: boolean) {
            state.showProofOfDelivery = val;
        },

        PROOF_OF_DELIVERY(state, val: PODDefaultSettings) {
            state.proofOfDelivery = val;
        },

        SHOW_POD_TUTORIAL(state, val: boolean) {
            state.showPODTutorial = val;
        },

        SHOW_POD_SETTINGS_POPUP(state, val: boolean) {
            state.showPODSettingsPopup = val;
        },

        SHOWED_POD_CALLOUT(state, val: boolean) {
            state.showedPODCallout = val;
        },

        SHOW_POD_CALLOUT(state, val: boolean) {
            state.showPODCallout = val;
        },
    }
};

export default podModule;
