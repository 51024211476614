import moment from "moment";
import vuetify from "@/plugins/vuetify";
import theStore from "@/store/index";
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwRoute, RwRouteAssignCodes, RwRoutePick, RwRouteStatusCodes, RwSyncStatus} from "@/app/dem/RwRoute";
import {RwStop} from "@/app/dem/RwStop";
import {RwSite} from "@/app/dem/RwSite";
import {RwAvatarInfo, RwCreateDriver, RwUser} from "@/app/dem/RwUser";
import {RwLog} from "@/app/dal/RwLog";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwTaskRoutes} from "@/app/dal/RwTaskRoutes";
import {RwTaskSync} from "@/app/dal/RwTaskSync";
import {RwModel} from "@/app/dem/RwModel";
import {RwTaskLicense} from "@/app/dal/RwTaskLicense";
import {CreateDriversReturnModel, RwLicenseCounts, SubTeamModel} from '@/app/dem/Drivers/CreateBulkDriversResponse';
import {RwAssignment, RwCommit} from '@/app/dem/RwSync';
import {RwSked} from '@/app/dem/RwSked';
import {RwHistory} from '@/app/dem/RwHistory';
import {RwSkuInfo} from '@/app/dem/Profile/RwSkuInfo';
import {RwTaskMisc} from '@/app/dal/RwTaskMisc';
import {RwWebPrefs} from '@/app/dem/Profile/RwProfileModel';
import {RwTaskStops} from '@/app/dal/RwTaskStops';
import {RwTaskDriver} from '@/app/dal/RwTaskDriver';
import {RwTaskSites} from '@/app/dal/RwTaskSites';
import {RwTaskSkeds} from '@/app/dal/RwTaskSkeds';
import {RwTaskHistory} from '@/app/dal/RwTaskHistory';
import {RwTaskAccounts} from '@/app/dal/RwTaskAccounts';
import {RwPin, RwPinArgs} from "@/app/dem/RwPin";

import {
    DriverSortOrder,
    FavSortOrder,
    HistSortOrder,
    NotifyErrorEnum,
    RwAccessTypes,
    RwApplyCouponError,
    RwDistUnits,
    RwEntityTypes,
    RwExtType,
    RwLimitType,
    RwLoginIssues,
    RwMultiMode,
    RwPinTypes,
    RwPushStatus,
    RwRoleTypes,
    RwRouteCopyType,
    RwSkedTypes,
    RwTxnTypes,
    SchedSortOrder,
    VersionOutdatedEnum
} from '@/app/RwEnums';
import {RwConstants} from "@/app/RwConstants";
import {RwTaskPush} from "@/app/dal/RwTaskPush";
import RwGenericDialog from '@/app/utils/RwGenericDialog';
import theSubTeamSpace from "@/app/views/subteams/RwSubTeamSpace";
import subTeamSpace, {RwSubTeamSpace} from "@/app/views/subteams/RwSubTeamSpace";

import {FirebaseApp, initializeApp} from "firebase/app";
import {getMessaging, getToken, isSupported, Messaging, onMessage} from "firebase/messaging";
import store from "@/store";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwGeoPoint} from "@/app/utils/RwGeoUtils";
import RwEventBus from "@/utils/RwEventBus";
import RwEvents from "@/app/consts/RwEvents";
import dal from "@/app/dal/RwDal";
import {RwSubTeam} from "@/app/dem/RwSubTeam";
import {RwRegInfo} from "@/app/dem/RwRegInfo";
import {RwError, RwLoginError} from "@/app/RwErrors";
import {RwImgUtils} from "@/app/utils/RwImgUtils";
import RwTotalStopCount from "@/app/dem/RwCountRouteStops";
import router from "@/router";
import RwPages from "@/app/consts/RwPages";
import sysend from "sysend";
import RwAddReport from "@/app/dem/RwAddReport";
import {AxiosRequestConfig} from "axios";
import GtmDataLayer from "@/app/dem/Profile/GtmDataLayer";
import Vue from "vue";
import {RwStopAddOns} from "./dem/RwStopAddOns";
import {PODDefaultSettings} from "./dem/ProofOfDelivery/ProofOfDeliveryEnums";
import {RwTaskBillingProfile} from "@/app/dal/RwTaskBillingProfile";

export class RwGlobals {

    //#region Props
    history: RwHistory[] = [];
    reassignList: RwAssignment[] = [];
    teamDomain: string;
    commitList: RwCommit[] = [];

    isFedExSubDomain: boolean = false;
    isOnTracSubDomain: boolean = false;
    isTestSubDomain: boolean = false;
    isPro = false;
    isComponentLoaded = false;
    imageUrl = "";
    imgSrc: number = 0;
    licenseCounts: RwLicenseCounts;
    _centroidLat: number = 0;
    _centroidLng: number = 0;
    // accountSetup: boolean = false;
    // avInfo: RwAvatarInfo;
    // _skuInfos: RwSkuInfo[] = [];


    browser: string;
    platform: string;
    isFirstRun: boolean = false;
    extType: RwExtType = RwExtType.None;
    extId: string;
    extDate: Date;
    isReturningFedexManifest: boolean = false;
    adminEmail: string;
    _devEnablePurchaseOps = true;
    pendingRegInfo: RwRegInfo = null;
    overrideEmptyState = false;

    // SubTeams
    //isSubTeam: boolean = false;
    //subDomain: string;
    parentDomain: string;
    orgs: SubTeamModel[] = [];

    fbApp: FirebaseApp;
    messaging: Messaging;
    pushEnabled: boolean = false;
    showArchive: boolean = false;
    showMark: boolean = false;
    static allowedGeoLoc: number = 0;
    static capturedGeoLoc: boolean = false;

    _targetFocusId: string;
    _targetSelectId: string;

    idxURL: { [key: string]: string; } = {};

    logoutMsg = "logout";

    _isTabActive = true;

    _inMemUserId = null;

    skipOnboardFirstStep = false;

    showStartingLocOnlyOnboard = false;

    Actions = {
        deleteStops: 0,
        deleteRoutes: 1,
        deleteFavorite: 2,
        deleteSked: 3
    };

    svgsDb;

    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    constructor() {
        sysend.on(this.logoutMsg, () => {
            this.logOut(true);
            router.push({name: RwPages.Login}).catch();
        });

        document.addEventListener('visibilitychange', (event => {
            this._isTabActive = !document.hidden;
        }));
    }

    isMobile() {
        let check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor);
        return check;
    };

    get IsTabActive() {
        return this._isTabActive;
    }

    get enablePurchaseOps() {
        return this._devEnablePurchaseOps;
    }

    set enablePurchaseOps(value: boolean) {
        this._devEnablePurchaseOps = value;
    }

    get isDev() {
        return process.env.NODE_ENV === "development";
    }

    get plan(): RwPlannerSpace {
        return thePlanSpace;
    }

    get subTeamSpace(): RwSubTeamSpace {
        return theSubTeamSpace;
    }

    get activeRoute(): RwRoute {
        return this.plan.activeRoute;
    }

    get targetFocusId(): string {
        return this._targetFocusId;
    }

    set targetFocusId(val: string) {
        this._targetFocusId = val;
        if (val && val.length > 0) {
            this._targetSelectId = val;
            //console.log("SET targetFocusId", "focusId, selectId", this._targetFocusId, this._targetSelectId);
        }
    }

    get targetSelectId(): string {
        return this._targetSelectId;
    }

    set targetSelectId(val: string) {
        this._targetSelectId = val;
        //this._targetFocusId = undefined;
        //console.log("SET targetSelectId", "focusId, selectId", this._targetFocusId, this._targetSelectId)
    }

    get skuInfos(): RwSkuInfo[] {
        let skufos;
        if (store.getters.skuInfosJson != undefined) {
            skufos = JSON.parse(store.getters.skuInfosJson);
            // console.warn('skufos gotten:', skufos);
        } else {
            skufos = new Array<RwSkuInfo>();
        }
        return RwSkuInfo.fromJsonArray(skufos);
    }

    set skuInfos(skuInfo: RwSkuInfo[]) {
        let skuInfosJson = JSON.stringify(skuInfo.map(e => RwSkuInfo.toJson(e)));
        // console.warn('skufos set:', skuInfosJson);
        store.dispatch("skuInfosJson", skuInfosJson).catch();
    }

    m_hasActiveFlexSku: boolean = null;

    get hasActiveFlexSku(): boolean {
        if (this.role === RwRoleTypes.Admin) {
            this.m_hasActiveFlexSku = true;
            return true;
        }

        if (this.skuInfos && this.skuInfos.length > 0) {
            this.skuInfos.forEach(info => {
                if (info.sku == "team.pro.monthly" || info.sku == RwConstants.SKU_ProGo || info.sku == RwConstants.SKU_Flex ||
                    info.sku == RwConstants.SKU_Trial) {
                    this.m_hasActiveFlexSku = true;
                }
            });
        } else {
            this.m_hasActiveFlexSku = false;
        }
        return this.m_hasActiveFlexSku;
    }

    get hasActiveFlexTrial(): boolean {
        let hasTrial = false;
        let foundNonTrialSub = false;

        if (this.skuInfos && this.skuInfos.length > 0) {
            this.skuInfos.forEach(info => {
                if (info.sku == "team.pro.monthly" || info.sku == RwConstants.SKU_ProGo || info.sku == RwConstants.SKU_Flex) {
                    foundNonTrialSub = true;
                }
            });

            if (foundNonTrialSub) {
                return false;
            }

            this.skuInfos.forEach(info => {
                if (info.sku == "flex.pro.trial") {
                    hasTrial = true;
                }
            });
        }

        return hasTrial;
    }

    m_hasActiveProSku: boolean = null;

    get hasActiveProSku(): boolean {
        if (this.role === RwRoleTypes.Admin) {
            this.m_hasActiveProSku = true;
            return true;
        }

        if (this.skuInfos && this.skuInfos.length > 0) {
            this.m_hasActiveProSku = false;
            this.skuInfos.forEach(info => {
                if (info.sku.includes('rw.pro.temp') || info.sku.includes('ios.pro.monthly') || info.sku.includes('ios.pro.yearly') || info.sku.includes('android.pro.monthly') || info.sku.includes('android.pro.yearly') || info.sku.includes('web.pro.monthly')) {
                    this.m_hasActiveProSku = true;
                }
            });
            return this.m_hasActiveProSku;
        } else {
            return false;
        }
    }


    get stops(): RwStop[] {
        let stops: RwStop[];
        if (this.plan.activeRoute) {
            stops = this.plan.activeRoute.stops;
        }
        return stops;
    }

    //Selected Objects

    get selectedDriver(): RwUser {
        return store.getters.selectedDriver;
    }

    set selectedDriver(driver: RwUser) {
        store.dispatch("selectedDriver", driver).catch();
    }

    get selectedRoute(): RwRoute {
        return theStore.getters.selectedRoute;
    }

    set selectedRoute(driver: RwRoute) {
        theStore.dispatch("selectedRoute", driver).catch();
    }

    get routeToEdit(): RwRoute {
        return theStore.getters.routeToEdit;
    }

    set routeToEdit(driver: RwRoute) {
        theStore.dispatch("routeToEdit", driver).catch();
    }

    get selectedRoutes(): RwRoute[] {
        return theStore.getters.selectedRoutes;
    }

    set selectedRoutes(routes: RwRoute[]) {
        theStore.dispatch("selectedRoute", routes).catch();
    }

    get selectedStop(): RwStop {
        //console.warn("RwGlobals.selectedStop");
        return theStore.getters.selectedStop;
    }

    set selectedStop(val: RwStop) {
        theStore.dispatch("selectedStop", val).catch();
    }

    get selectedSite(): RwSite {
        return theStore.getters.selectedSite;
    }

    set selectedSite(val: RwSite) {
        theStore.dispatch("selectedSite", val).catch();
    }


    // info

    get m_snackBarText(): string {
        return theStore.getters.snackBarText;
    }

    set m_snackBarText(val: string) {
        theStore.dispatch("snackBarText", val).catch();
    }

    get m_snackBarColor(): string {
        return theStore.getters.snackBarColor;
    }

    set m_snackBarColor(val: string) {
        theStore.dispatch("snackBarColor", val).catch();
    }

    get m_showSnackVar(): boolean {
        return theStore.getters.showSnackbar;
    }

    set m_showSnackVar(val: boolean) {
        theStore.dispatch("showSnackbar", val).catch();
    }

    get isDataLoaded(): boolean {
        return theStore.getters.isDataLoaded;
    }

    set isDataLoaded(val: boolean) {
        theStore.dispatch("isDataLoaded", val).catch();
    }

    get deviceId(): string {
        return RwPrefUtils.deviceId;
    }

    set deviceId(val: string) {
        RwPrefUtils.deviceId = val;
    }

    get installId(): string {
        return RwPrefUtils.installId;
    }

    set installId(val: string) {
        RwPrefUtils.installId = val;
    }

    get orgId(): string {
        return RwPrefUtils.orgId;
    }

    set orgId(val: string) {
        RwPrefUtils.orgId = val;
    }

    get dispName(): string {
        return RwPrefUtils.dispName;
    }

    set dispName(val: string) {
        RwPrefUtils.dispName = val;
    }

    get userName(): string {
        return RwPrefUtils.userName;
    }

    set userName(val: string) {
        RwPrefUtils.userName = val;
    }

    get avInfo(): RwAvatarInfo {
        //console.warn("GET avinfo:", store.getters.avInfo);
        return store.getters.avInfo;
    }

    set avInfo(val: RwAvatarInfo) {
        //console.warn("SET avinfo:", val);
        if (val) {
            RwPrefUtils.avInits = val.avInits;
            RwPrefUtils.avColor = val.avColor;
            RwPrefUtils.avType = val.avType;
            RwPrefUtils.avUrl = val.avUrl;
        } else {
            RwPrefUtils.avInits = "";
            RwPrefUtils.avColor = "";
            RwPrefUtils.avType = -1;
            RwPrefUtils.avUrl = "";
        }

        store.dispatch("avInfo", val).catch();
    }

    get accountId(): string {
        return RwPrefUtils.userId;
    }

    set accountId(val: string) {
        RwPrefUtils.accountId = val;
    }

    get userId(): string {
        return RwPrefUtils.userId;
    }

    set userId(val: string) {
        RwPrefUtils.userId = val;
        this._inMemUserId = val;
    }

    get isSubTeam(): boolean {
        return RwPrefUtils.isSubTeam;
    }

    set isSubTeam(val: boolean) {
        RwPrefUtils.isSubTeam = val;
    }

    get subDomain(): string {
        return RwPrefUtils.subDomain;
    }

    set subDomain(val: string) {
        RwPrefUtils.subDomain = val;
    }

    get subTeams(): RwSubTeam[] {
        return store.getters.subTeams;
    }

    set subTeams(val: RwSubTeam[]) {
        store.dispatch("subTeams", val).catch();
    }

    get stayLoggedIn(): boolean {
        return RwPrefUtils.stayLoggedIn;
    }

    set stayLoggedIn(val: boolean) {
        RwPrefUtils.stayLoggedIn = val;
    }

    get editAfterAdd(): boolean {
        return theStore.getters.editAfterAdd;
    }

    set editAfterAdd(val: boolean) {
        RwPrefUtils.editAfterAdd = val;
        theStore.dispatch("editAfterAdd", val).catch();
    }

    get userPass(): string {
        return RwPrefUtils.userPass;
    }

    set userPass(val: string) {
        RwPrefUtils.userPass = val;
    }

    get orgCentroid(): number[] {
        let self = this;
        if (this._centroidLat === 0 && this._centroidLng === 0) {
            if (this.sites.length > 0) {
                this.sites.forEach(s => {
                    self._centroidLat += s.lat;
                    self._centroidLng += s.lng;
                });

                this._centroidLat = this._centroidLat / this.sites.length;
                this._centroidLng = this._centroidLng / this.sites.length;
            }
                // else if (this.zones.length > 0) {
                //   this.zones.forEach(z => {
                //     self._centroidLat += z.lat;
                //     self._centroidLng += z.lng;
                //   });

                //   this._centroidLat = this._centroidLat / this.zones.length;
                //   this._centroidLng = this._centroidLng / this.zones.length;
            // }
            else if (this.routes.length > 0) {
                this.routes.forEach(r => {
                    self._centroidLat += r.lat;
                    self._centroidLng += r.lng;
                });

                this._centroidLat = this._centroidLat / this.routes.length;
                this._centroidLng = this._centroidLng / this.routes.length;
            }
        }
        return [this._centroidLat, this._centroidLng];
    }

    get isLoading(): boolean {
        return theStore.getters.isLoading;
    }

    set isLoading(loading: boolean) {
        if (loading != this.isLoading) {
            theStore.dispatch("isLoading", loading).catch();
        }
    }

    get isLoggedIn(): boolean {
        return theStore.getters.isLoggedIn;
    }

    set isLoggedIn(isLoggedIn: boolean) {
        RwPrefUtils.isLoggedIn = isLoggedIn;
        theStore.dispatch("isLoggedIn", isLoggedIn).catch();
    }


    get gotUser(): boolean {
        return RwPrefUtils.userId && RwPrefUtils.userId.length > 0 && RwPrefUtils.userId !== RwConstants.EmptyGuid;
    }

    get gotToken(): boolean {
        return RwPrefUtils.token && RwPrefUtils.token.length > 0;
    }

    // hide prefs

    get hidePingPopup(): boolean {
        return theStore.getters.hidePingPopup;
    }

    set hidePingPopup(val: boolean) {
        RwPrefUtils.hidePingPopup = val;
        theStore.dispatch("hidePingPopup", val).catch();
    }

    get hideDeletePopup(): boolean {
        return theStore.getters.hideDeletePopup;
    }

    set hideDeletePopup(val: boolean) {
        RwPrefUtils.hideDeletePopup = val;
        theStore.dispatch("hideDeletePopup", val).catch();
    }

    get hideCreatePopup(): boolean {
        return theStore.getters.hideCreatePopup;
    }

    set hideCreatePopup(val: boolean) {
        RwPrefUtils.hideCreatePopup = val;
        theStore.dispatch("hideCreatePopup", val).catch();
    }

    get hideCCPopup(): boolean {
        return theStore.getters.hideCCPopup;
    }

    set hideCCPopup(val: boolean) {
        RwPrefUtils.hideCCPopup = val;
        theStore.dispatch("hideCCPopup", val).catch();
    }

    get valCCPopup(): number {
        return theStore.getters.valCCPopup;
    }

    set valCCPopup(val: number) {
        RwPrefUtils.valCCPopup = val;
        theStore.dispatch("valCCPopup", val).catch();
    }

    get warnedCCPopup(): string[] {
        return theStore.getters.warnedCCPopup;
    }

    set warnedCCPopup(val: string[]) {
        RwPrefUtils.warnedCCPopup = val;
        theStore.dispatch("warnedCCPopup", val).catch();
    }

    get warnedCCPopupOverdue(): string[] {
        return theStore.getters.warnedCCPopupOverdue;
    }

    set warnedCCPopupOverdue(val: string[]) {
        RwPrefUtils.warnedCCPopupOverdue = val;
        theStore.dispatch("warnedCCPopupOverdue", val).catch();
    }

    get hide450Popup(): boolean {
        return theStore.getters.hide450Popup;
    }

    set hide450Popup(val: boolean) {
        RwPrefUtils.hide450Popup = val;
        theStore.dispatch("hide450Popup", val).catch();
    }

    get hideTasksWarn(): boolean {
        return theStore.getters.hideTasksWarn;
    }

    set hideTasksWarn(val: boolean) {
        RwPrefUtils.hideTasksWarn = val;
        theStore.dispatch("hideTasksWarn", val).catch();
    }

    get hideStopStatus(): boolean {
        return theStore.getters.hideStopStatus;
    }

    set hideStopStatus(val: boolean) {
        RwPrefUtils.hideStopStatus = val;
        theStore.dispatch("hideStopStatus", val).catch();
    }


    //misc

    get hasCurrPurch(): boolean {
        return theStore.getters.hasCurrPurch;
    }

    set hasCurrPurch(val: boolean) {
        RwPrefUtils.hasActiveSub = val;
        theStore.dispatch("hasCurrPurch", val).catch();
    }

    get showProofOfDelivery(): boolean {
        return theStore.getters.showProofOfDelivery;
    }

    set showProofOfDelivery(val: boolean) {
        theStore.dispatch("showProofOfDelivery", val).catch();
    }

    get hasOnboarded(): boolean {
        return theStore.getters.hasOnboarded;
    }

    set hasOnboarded(val: boolean) {
        RwPrefUtils.hasOnboarded = val;
        theStore.dispatch("hasOnboarded", val).catch();
    }

    get hasProBetaPrimed(): boolean {
        // console.warn('get globals.hasProBetaPrimed: ', theStore.getters.hasProBetaPrimed);
        return theStore.getters.hasProBetaPrimed;
    }

    set hasProBetaPrimed(val: boolean) {
        RwPrefUtils.hasOnboarded = val;
        // console.warn('set globals.hasProBetaPrimed: ', val);
        theStore.dispatch("hasProBetaPrimed", val).catch();
    }

    get updateStartPref(): number {
        return theStore.getters.updateStartPref;
    }

    set updateStartPref(val: number) {
        RwPrefUtils.updateStartPref = val;
        theStore.dispatch("updateStartPref", val).catch();
    }

    get pinnedStatus(): string[] {
        return theStore.getters.pinnedStatus;
    }

    set pinnedStatus(val: string[]) {
        RwPrefUtils.pinnedStatus = val;
        theStore.dispatch("pinnedStatus", val).catch();
    }

    get lastSync(): moment.Moment {
        return moment(this.lastSyncMS);
    }

    get lastSyncMS(): number {
        return theStore.getters.lastSyncMS as number;
    }

    set lastSyncMS(val: number) {
        ////console.warn("lastSync MS:", val);
        theStore.dispatch("lastSyncMS", val).catch();
    }


    get lastDelta(): moment.Moment {
        return moment(this.lastRouteDeltaMS);
    }

    get lastRouteDeltaMS(): number {
        return theStore.getters.lastDeltaMS as number;
    }

    set lastRouteDeltaMS(val: number) {
        theStore.dispatch("lastDeltaMS", val).catch();
    }


    get driverLastSync(): moment.Moment {
        return moment(this.driverLastSyncMS);
    }

    get driverLastSyncMS(): number {
        return theStore.getters.driverLastSyncMS as number;
    }

    set driverLastSyncMS(val: number) {
        theStore.dispatch("driverLastSyncMS", val).catch();
    }


    get lastStatus(): moment.Moment {
        return moment(this.lastStatusMS);
    }

    get lastStatusMS(): number {
        return theStore.getters.lastStatusMS as number;
    }

    set lastStatusMS(val: number) {
        theStore.dispatch("lastStatusMS", val).catch();
    }


    get lastToken(): moment.Moment {
        return moment(this.lastTokenMS);
    }

    get lastTokenMS(): number {
        return theStore.getters.lastTokenMS as number;
    }

    set lastTokenMS(val: number) {
        RwPrefUtils.lastTokenMS = val;
        theStore.dispatch("lastTokenMS", val).catch();
    }

    get token(): string {
        return theStore.getters.token;
    }

    set token(token: string) {
        RwPrefUtils.token = token;
        //console.log("SET RwPrefUtils.token", token)
        theStore.dispatch("token", token).catch();
    }


    get theme(): string {
        return this.isDarkMode ? "dark" : "light";
    }

    get isDarkMode(): boolean {
        return theStore.getters.isDarkMode;
    }

    set isDarkMode(val: boolean) {
        RwPrefUtils.rwTheme = val ? "rwDark" : "rwLight";
        if (val != this.isDarkMode) {
            theStore.dispatch("isDarkMode", val).catch();
            vuetify.framework.theme.dark = val;
        }
    }

    get isDisp(): boolean {
        return theStore.getters.isDisp;
    }

    set isDisp(val: boolean) {
        if (val != this.isDisp) {
            RwPrefUtils.isDisp = val;
            theStore.dispatch("isDisp", val).catch();
        }
    }

    get isSimpleMap(): boolean {
        return theStore.getters.isSimpleMap;
    }

    set isSimpleMap(val: boolean) {
        if (val != this.isSimpleMap) {
            RwPrefUtils.isSimpleMap = val;
            theStore.dispatch("isSimpleMap", val).catch();
        }
    }

    get isTeam(): boolean {
        return theStore.getters.isTeam;
    }

    set isTeam(val: boolean) {
        if (val != this.isTeam) {
            RwPrefUtils.isTeam = val;
            theStore.dispatch("isTeam", val).catch();
        }
    }

    get isAdmin(): boolean {
        if (this.role === RwRoleTypes.Admin) {
            return true;
        } else {
            return false;
        }
    }


    get isFlexOwner(): boolean {
        if (this.isTeam && (this.role === RwRoleTypes.Owner || this.role === RwRoleTypes.Admin) && !this.isSubTeam) {
            return true;
        } else {
            return false;
        }
    }

    get isAutoRenewing(): boolean {
        return theStore.getters.isAutoRenewing;
    }

    set isAutoRenewing(val: boolean) {
        if (val != this.isAutoRenewing) {
            RwPrefUtils.isAutoRenewing = val;
            theStore.dispatch("isAutoRenewing", val).catch();
        }
    }

    get isFlexSolo(): boolean {
        return theStore.getters.isFlexSolo;
    }

    set isFlexSolo(val: boolean) {
        if (val != this.isFlexSolo) {
            RwPrefUtils.isFlexSolo = val;
            theStore.dispatch("isFlexSolo", val).catch();
        }
    }

    get flexDisplay(): number {
        // console.warn("flex Display get in globals: ", theStore.getters.flexDisplay);
        let fd = theStore.getters.flexDisplay;
        if (fd === 0) {
            fd = 1;
        }
        return fd;
    }

    set flexDisplay(val: number) {
        if (val !== this.flexDisplay) {
            // console.warn("flex Display set in globals: ", val);
            RwPrefUtils.flexDisplay = val;
            theStore.dispatch("flexDisplay", val).catch();
        }
    }


    get role(): number {
        return theStore.getters.getRole;
    }

    set role(val: number) {
        if (val != this.role) {
            RwPrefUtils.role = val;
            theStore.dispatch("setRole", val).catch();
        }
    }

    get isOpenDrawer(): boolean {
        return theStore.getters.isOpenDrawer;
    }

    set isOpenDrawer(val: boolean) {
        RwPrefUtils.isOpenDrawer = val;
        theStore.dispatch("isOpenDrawer", val).catch();
    }

    //popup action shows

    get showProfileEdit(): boolean {
        return store.getters.showProfileEdit;
    }

    set showProfileEdit(val: boolean) {
        store.dispatch("showProfileEdit", val).catch();
    }

    get showLimitPopup(): boolean {
        return store.getters.showLimitPopup;
    }

    set showLimitPopup(val: boolean) {
        store.dispatch("showLimitPopup", val).catch();
    }

    get prompt450Popup(): string[] {
        return store.getters.prompt450Popup;
    }

    set prompt450Popup(val: string[]) {
        store.dispatch("prompt450Popup", val).catch();
    }

    get warned450Popup(): string[] {
        return store.getters.warned450Popup;
    }

    set warned450Popup(val: string[]) {
        store.dispatch("warned450Popup", val).catch();
    }


    get prompt500Popup(): string[] {
        return store.getters.prompt500Popup;
    }

    set prompt500Popup(val: string[]) {
        store.dispatch("prompt500Popup", val).catch();
    }

    get hasClosedUserSiteBanner(): boolean {
        // console.warn("hasClosedUserSiteBanner get: ", store.getters.hasClosedUserSiteBanner);
        // return store.getters.hasClosedUserSiteBanner;
        return true;
    }

    get lastClosedPaymentBanner(): Date {
        return store.getters.lastClosedPaymentBanner;

    }

    set lastClosedPaymentBanner(val: Date) {
        store.dispatch("lastClosedPaymentBanner", val).catch();
        RwPrefUtils.lastClosedPaymentBanner = val;
    }

    get showNewPass(): boolean {
        return store.getters.showNewPass;
    }

    set showNewPass(val: boolean) {
        store.dispatch("showNewPass", val).catch();
    }

    get showRegistrationType(): boolean {
        return store.getters.showRegistrationType;
    }

    set showRegistrationType(val: boolean) {
        store.dispatch("showRegistrationType", val).catch();
    }

    get showProToTeam(): boolean {
        return store.getters.showProToTeam;
    }

    set showProToTeam(val: boolean) {
        store.dispatch("showProToTeam", val).catch();
    }

    get showCCExpire(): boolean {
        return store.getters.showCCExpire;
    }

    set showCCExpire(val: boolean) {
        store.dispatch("showCCExpire", val).catch();
    }

    get showOnboardSettings(): boolean {
        return store.getters.showOnboardSettings;
    }

    set showOnboardSettings(val: boolean) {
        store.dispatch("showOnboardSettings", val).catch();
    }

    get showOnboardAddStopArrowActive(): boolean {
        return store.getters.showOnboardAddStopArrowActive;
    }

    set showOnboardAddStopArrowActive(val: boolean) {
        store.dispatch("showOnboardAddStopArrowActive", val).catch();
    }

    get showOnboardUploadsPopupActive(): boolean {
        return store.getters.showOnboardUploadsPopupActive;
    }

    set showOnboardUploadsPopupActive(val: boolean) {
        store.dispatch("showOnboardUploadsPopupActive", val).catch();
    }

    get showPODTutorialOnOnboard(): boolean {
        return store.getters.showPODTutorialOnOnboard;
    }

    set showPODTutorialOnOnboard(val: boolean) {
        store.dispatch("showPODTutorialOnOnboard", val).catch();
    }

    get showPODCallout(): boolean {
        return store.getters.showPODCallout;
    }

    set showPODCallout(val: boolean) {
        store.dispatch("showPODCallout", val).catch();
    }

    get showOnboardAddStopArrow(): boolean {
        return store.getters.showOnboardAddStopArrow;
    }

    set showOnboardAddStopArrow(val: boolean) {
        store.dispatch("showOnboardAddStopArrow", val).catch();
    }

    get showOnboardUploadsPopup(): boolean {
        return store.getters.showOnboardUploadsPopup;
    }

    set showOnboardUploadsPopup(val: boolean) {
        store.dispatch("showOnboardUploadsPopup", val).catch();
    }

    get showPODTutorial(): boolean {
        return store.getters.showPODTutorial;
    }

    set showPODTutorial(val: boolean) {
        store.dispatch("showPODTutorial", val).catch();
    }

    get showPODSettingsPopup(): boolean {
        return store.getters.showPODSettingsPopup;
    }

    set showPODSettingsPopup(val: boolean) {
        store.dispatch("showPODSettingsPopup", val).catch();
    }

    get showedPODCallout(): boolean {
        return store.getters.showedPODCallout;
    }

    set showedPODCallout(val: boolean) {
        RwPrefUtils.showedPODCallout = val;
        store.dispatch("showedPODCallout", val).catch();
    }

    get showProBetaPrimer(): boolean {
        // console.warn('get globals.showProBetaPrimer: ', theStore.getters.showProBetaPrimer);
        return store.getters.showProBetaPrimer;
    }

    set showProBetaPrimer(val: boolean) {
        // console.warn('set globals.showProBetaPrimer: ', val);
        store.dispatch("showProBetaPrimer", val).catch();
    }


    get showEditAvatar(): boolean {
        return store.getters.showEditAvatar;
    }

    set showEditAvatar(val: boolean) {
        store.dispatch("showEditAvatar", val).catch();
    }

    get showEditDriverPass(): boolean {
        return store.getters.showEditDriverPass;
    }

    set showEditDriverPass(val: boolean) {
        store.dispatch("showEditDriverPass", val).catch();
    }

    get showEditDriverUname(): boolean {
        return store.getters.showEditDriverUname;
    }

    set showEditDriverUname(val: boolean) {
        store.dispatch("showEditDriverUname", val).catch();
    }

    get showReplaceDriver(): boolean {
        return store.getters.showReplaceDriver;
    }

    set showReplaceDriver(val: boolean) {
        store.dispatch("showReplaceDriver", val).catch();
    }

    get showDeleteDriver(): boolean {
        return store.getters.showDeleteDriver;
    }

    set showDeleteDriver(val: boolean) {
        store.dispatch("showDeleteDriver", val).catch();
    }

    get showCreateDriver(): boolean {
        return store.getters.showCreateDriver;
    }

    set showCreateDriver(val: boolean) {
        store.dispatch("showCreateDriver", val).catch();
    }

    get showCreateBulkUser(): boolean {
        return store.getters.showCreateBulkUser;
    }

    set showCreateBulkUser(val: boolean) {
        store.dispatch("showCreateBulkUser", val).catch();
    }

    get showDomainPopup(): boolean {
        return store.getters.showDomainPopup;
    }

    set showDomainPopup(val: boolean) {
        store.dispatch("showDomainPopup", val).catch();
    }

    get showPingTest(): boolean {
        return store.getters.showPingTest;
    }

    set showPingTest(val: boolean) {
        store.dispatch("showPingTest", val).catch();
    }

    get showDeletePrompt(): boolean {
        return store.getters.showDeletePrompt;
    }

    set showDeletePrompt(val: boolean) {
        store.dispatch("showDeletePrompt", val).catch();
    }

    get showCreatePrompt(): boolean {
        return store.getters.showCreatePrompt;
    }

    set showCreatePrompt(val: boolean) {
        store.dispatch("showCreatePrompt", val).catch();
    }


    get showRouteAssign(): boolean {
        return theStore.getters.showRouteAssign;
    }

    set showRouteAssign(val: boolean) {
        theStore.dispatch("showRouteAssign", val).catch();
    }

    get showRouteCopy(): boolean {
        return theStore.getters.showRouteCopy;
    }

    set showRouteCopy(val: boolean) {
        theStore.dispatch("showRouteCopy", val).catch();
    }

    get showRouteComplete(): boolean {
        return theStore.getters.showRouteComplete;
    }

    // get showSkedDelete():boolean{ return store.getters.showSkedDelete; }
    // set showSkedDelete(val: boolean) { store.dispatch("showSkedDelete", val).catch(); }
    set showRouteComplete(val: boolean) {
        theStore.dispatch("showRouteComplete", val).catch();
    }

    get showRouteDelete(): boolean {
        return theStore.getters.showRouteDelete;
    }

    set showRouteDelete(val: boolean) {
        theStore.dispatch("showRouteDelete", val).catch();
    }


    get showSiteDelete(): boolean {
        return theStore.getters.showSiteDelete;
    }

    set showSiteDelete(val: boolean) {
        theStore.dispatch("showSiteDelete", val).catch();
    }

    get showSiteRelocate(): boolean {
        return theStore.getters.showSiteRelocate;
    }

    set showSiteRelocate(val: boolean) {
        theStore.dispatch("showSiteRelocate", val).catch();
    }

    get showSiteCreate(): boolean {
        return theStore.getters.showSiteCreate;
    }

    set showSiteCreate(val: boolean) {
        theStore.dispatch("showSiteCreate", val).catch();
    }

    get showSkedCreate(): boolean {
        return store.getters.showSkedCreate;
    }

    set showSkedCreate(val: boolean) {
        store.dispatch("showSkedCreate", val).catch();
    }

    get showSkedDelete(): boolean {
        return store.getters.showSkedDelete;
    }

    set showSkedDelete(val: boolean) {
        store.dispatch("showSkedDelete", val).catch();
    }

    get m_showAssignPicker(): boolean {
        return theStore.getters.showAssignPicker;
    }


    set m_showAssignPicker(val: boolean) {
        theStore.dispatch("showAssignPicker", val).catch();
    }

    get m_assignPickerInitRoute(): RwRoute {
        return theStore.getters.assignPickerInitRoute;
    }

    set m_assignPickerInitRoute(val: RwRoute) {
        theStore.dispatch("assignPickerInitRoute", val).catch();
    }


    get showClusterPicker(): boolean {
        return theStore.getters.showClusterPicker;
    }

    set showClusterPicker(val: boolean) {
        theStore.dispatch("showClusterPicker", val).catch();
    }

    get selectedClusterPin(): RwPin {
        return theStore.getters.selectedClusterPin;
    }

    set selectedClusterPin(val: RwPin) {
        theStore.dispatch("selectedClusterPin", val).catch();
    }


    get showPinPopup(): boolean {
        return theStore.getters.showPinPopup;
    }

    set showPinPopup(val: boolean) {
        theStore.dispatch("showPinPopup", val).catch();
    }

    get showPinPanel(): boolean {
        return theStore.getters.showPinPanel;
    }

    set showPinPanel(val: boolean) {
        RwPrefUtils.showPinPanel = val;
        theStore.dispatch("showPinPanel", val).catch();
    }

    get showStopPop(): boolean {
        return theStore.getters.showStopPop;
    }

    set showStopPop(val: boolean) {
        theStore.dispatch("showStopPop", val).catch();
    }

    get showStopDock(): boolean {
        return theStore.getters.showStopDock;
    }

    set showStopDock(val: boolean) {
        RwPrefUtils.showStopDock = val;
        theStore.dispatch("showStopDock", val).catch();
    }

    get showSitePop(): boolean {
        return theStore.getters.showSitePop;
    }

    set showSitePop(val: boolean) {
        theStore.dispatch("showSitePop", val).catch();
    }

    get showSiteDock(): boolean {
        return theStore.getters.showSiteDock;
    }

    set showSiteDock(val: boolean) {
        RwPrefUtils.showSiteDock = val;
        theStore.dispatch("showSiteDock", val).catch();
    }

    get showSkedPop(): boolean {
        return theStore.getters.showSkedPop;
    }

    set showSkedPop(val: boolean) {
        theStore.dispatch("showSkedPop", val).catch();
    }

    get showSkedDock(): boolean {
        return theStore.getters.showSkedDock;
    }

    set showSkedDock(val: boolean) {
        RwPrefUtils.showSkedDock = val;
        theStore.dispatch("showSkedDock", val).catch();
    }

    get showDriverPop(): boolean {
        return theStore.getters.showDriverPop;
    }

    set showDriverPop(val: boolean) {
        theStore.dispatch("showDriverPop", val).catch();
    }

    get showDriverDock(): boolean {
        return theStore.getters.showDriverDock;
    }

    set showDriverDock(val: boolean) {
        RwPrefUtils.showDriverDock = val;
        theStore.dispatch("showDriverDock", val).catch();
    }

    get showRoutePop(): boolean {
        return theStore.getters.showRoutePop;
    }

    set showRoutePop(val: boolean) {
        theStore.dispatch("showRoutePop", val).catch();
    }

    get showRouteDock(): boolean {
        return theStore.getters.showRouteDock;
    }

    set showRouteDock(val: boolean) {
        RwPrefUtils.showRouteDock = val;
        theStore.dispatch("showRouteDock", val).catch();
    }

    get showHistoryPop(): boolean {
        return theStore.getters.showHistoryPop;
    }

    set showHistoryPop(val: boolean) {
        theStore.dispatch("showHistoryPop", val).catch();
    }

    get showHistoryEditPop(): boolean {
        return theStore.getters.showHistoryEditPop;
    }

    set showHistoryEditPop(val: boolean) {
        theStore.dispatch("showHistoryEditPop", val).catch();
    }

    get selectedHistoryItem(): RwHistory {
        return theStore.getters.selectedHistoryItem;
    }

    set selectedHistoryItem(val: RwHistory) {
        theStore.dispatch("selectedHistoryItem", val).catch();
    }

    //
    // get showSubTeamDock(): boolean {
    //   return theStore.getters.showSubTeamDock;
    // }
    //
    // set showSubTeamDock(val: boolean) {
    //   RwPrefUtils.showSubTeamDock = val;
    //   theStore.dispatch("showSubTeamDock", val).catch();
    // }

    get m_showConfirm(): boolean {
        return theStore.getters.showConfirm;
    }

    set m_showConfirm(val: boolean) {
        theStore.dispatch("showConfirm", val).catch();
    }

    get m_confirmTitle(): string {
        return theStore.getters.confirmTitle;
    }

    set m_confirmTitle(val: string) {
        theStore.dispatch("confirmTitle", val).catch();
    }

    get m_confirmBody(): string {
        return theStore.getters.confirmBody;
    }

    set m_confirmBody(val: string) {
        theStore.dispatch("confirmBody", val).catch();
    }

    get m_confirmPosText(): string {
        return theStore.getters.confirmPosText;
    }

    set m_confirmPosText(val: string) {
        theStore.dispatch("confirmPosText", val).catch();
    }

    get m_confirmNegText(): string {
        return theStore.getters.confirmNegText;
    }

    set m_confirmNegText(val: string) {
        theStore.dispatch("confirmNegText", val).catch();
    }

    get m_confirmDontShowCheck(): boolean {
        return theStore.getters.confirmDontShowCheck;
    }

    set m_confirmDontShowCheck(val: boolean) {
        theStore.dispatch("confirmDontShowCheck", val).catch();
    }

    get m_confirmDontShowCallback(): Function | null {
        return theStore.getters.confirmDontShowCallback;
    }

    set m_confirmDontShowCallback(val: Function | null) {
        theStore.dispatch("confirmDontShowCallback", val).catch();
    }

    get backColorBar(): string {
        return this.isDarkMode ? "rwBackDarkBar" : "rwBackLightBar";
    }

    get dispatcher(): RwUser {
        let disp: RwUser;
        let drivers = this.drivers;
        if (drivers && drivers.length > 0) {
            // console.warn ("drivers loop reached");
            disp = drivers[0];
            for (let idx = 0; idx < drivers.length; idx++) {
                let driver = drivers[idx];
                if (driver.userType === 0) {
                    disp = driver;
                    break;
                }
            }
        }
        // console.warn ("dispatcher user GET:", disp);
        return disp;
    }

    get refreshRouteTrigger(): number {
        return theStore.getters.refreshRouteTrigger;
    }

    set refreshRouteTrigger(val: number) {
        theStore.dispatch("refreshRouteTrigger", val).catch();
    }

    get swatches(): any[] {
        return [
            ['#40A23F', '#7AB93B', '#C1D62D', '#FDE82F', '#FCB40E', '#FC8607'],
            ['#5321A6', '#8801A0', '#DE0051', '#E91E63', '#ED2B2E', '#FB3F1B'],
            ['#303AA5', '#1E81EE', '#1497F0', '#19ADC9', '#118675', '#4E6A79'],
            ['#191919', '#8C8C8C', '#654338'],
        ];
    }

    // get swatches(): any[] {
    //   return [
    //     ['#40A23F', '#7AB93B', '#C1D62D',
    //     '#FDE82F', '#FCB40E', '#FC8607'],
    //     ['#5321A6', '#8801A0', '#DE0051',
    //     '#E91E63', '#ED2B2E', '#FB3F1B'], //E2004F
    //     ['#303AA5', '#1E81EE', '#1497F0',
    //     '#19ADC9', '#118675', '#4E6A79'],
    //     '#191919', '#8C8C8C', '#654338',
    //   ];
    // }

    get visitTimes(): number[] {
        return [0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
            105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185, 190, 195, 200,
        ];
    }

    get routeTimes(): number[] {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 18, 24, 36, 48, 72, 96, 120, 144, 168];
    }

    get m_showReloPicker(): boolean {
        return theStore.getters.showReloPicker;
    }

    set m_showReloPicker(val: boolean) {
        theStore.dispatch("showReloPicker", val).catch();
    }

    get m_reloPickerInitTitle(): string {
        return theStore.getters.reloPickerInitTitle;
    }

    set m_reloPickerInitTitle(val: string) {
        theStore.dispatch("reloPickerInitTitle", val).catch();
    }


    get m_reloPickerInitLat(): number {
        return theStore.getters.reloPickerInitLat;
    }

    set m_reloPickerInitLat(val: number) {
        theStore.dispatch("reloPickerInitLat", val).catch();
    }

    get m_reloPickerInitLng(): number {
        return theStore.getters.reloPickerInitLng;
    }

    set m_reloPickerInitLng(val: number) {
        theStore.dispatch("reloPickerInitLng", val).catch();
    }

    get m_enableReloPickerSearch(): boolean {
        return theStore.getters.enableReloPickerSearch;
    }

    set m_enableReloPickerSearch(val: boolean) {
        theStore.dispatch("enableReloPickerSearch", val).catch();
    }

    get m_reloPickerAddReport(): boolean {
        return theStore.getters.reloPickerAddReport;
    }

    set m_reloPickerAddReport(val: boolean) {
        theStore.dispatch("reloPickerAddReport", val).catch();
    }

    get m_showColorPicker(): boolean {
        return theStore.getters.showColorPicker;
    }

    set m_showColorPicker(val: boolean) {
        theStore.dispatch("showColorPicker", val).catch();
    }

    get m_showAddRoutePicker(): boolean {
        // console.warn("RwGlobals.m_showAddRoutePicker getter")
        return theStore.getters.showAddRoutePicker;
    }

    set m_showAddRoutePicker(val: boolean) {
        // console.warn("RwGlobals.m_showAddRoutePicker setter")
        theStore.dispatch("showAddRoutePicker", val).catch(err => {
            //console.warn(err)
        });
    }


    get m_colorPickerInitColor(): string {
        return theStore.getters.colorPickerInitColor;
    }

    set m_colorPickerInitColor(val: string) {
        theStore.dispatch("colorPickerInitColor", val).catch();
    }


    get m_showVisitPicker(): boolean {
        return theStore.getters.showVisitPicker;
    }

    set m_showVisitPicker(val: boolean) {
        theStore.dispatch("showVisitPicker", val).catch();
    }

    get m_visitPickerInit(): number {
        return theStore.getters.visitPickerInit;
    }

    set m_visitPickerInit(val: number) {
        theStore.dispatch("visitPickerInit", val).catch();
    }

    get m_showSkedPicker(): boolean {
        return theStore.getters.showSkedPicker;
    }

    set m_showSkedPicker(val: boolean) {
        theStore.dispatch("showSkedPicker", val).catch();
    }


    get m_skedPickerPriority(): number {
        return theStore.getters.skedPickerPriority;
    }

    set m_skedPickerPriority(val: number) {
        theStore.dispatch("skedPickerPriority", val).catch();
    }

    get m_skedPickerStart(): number {
        return theStore.getters.skedPickerStart;
    }

    set m_skedPickerStart(val: number) {
        theStore.dispatch("skedPickerStart", val).catch();
    }

    get m_skedPickerFinish(): number {
        return theStore.getters.skedPickerFinish;
    }

    set m_skedPickerFinish(val: number) {
        theStore.dispatch("skedPickerFinish", val).catch();
    }


    get m_showRoutePicker(): boolean {
        return theStore.getters.showRoutePicker;
    }

    set m_showRoutePicker(val: boolean) {
        theStore.dispatch("showRoutePicker", val).catch();
    }

    get m_routePickerSrcId(): string {
        return theStore.getters.routePickerSrcId;
    }

    set m_routePickerSrcId(val: string) {
        theStore.dispatch("routePickerSrcId", val).catch();
    }


    get m_routeNewInitRoute(): RwRoute {
        return theStore.getters.routeNewInitRoute;
    }

    set m_routeNewInitRoute(val: RwRoute) {
        theStore.dispatch("routeNewInitRoute", val).catch();
    }

    get m_routeNewInitEmpty(): boolean {
        return theStore.getters.routeNewInitEmpty;
    }

    set m_routeNewInitEmpty(val: boolean) {
        theStore.dispatch("routeNewInitEmpty", val).catch();
    }


    get m_showStatusPicker(): boolean {
        return theStore.getters.showStatusPicker;
    }

    set m_showStatusPicker(val: boolean) {
        theStore.dispatch("showStatusPicker", val).catch();
    }

    get m_statusPickerInitName(): string {
        return theStore.getters.statusPickerInitName;
    }

    set m_statusPickerInitName(val: string) {
        theStore.dispatch("statusPickerInitName", val).catch();
    }

    get m_statusPickerInitStatus(): number {
        return theStore.getters.statusPickerInitStatus;
    }

    set m_statusPickerInitStatus(val: number) {
        theStore.dispatch("statusPickerInitStatus", val).catch();
    }

    get genericDialog(): RwGenericDialog {
        return theStore.getters.genericDialog;
    }

    set genericDialogShow(val: boolean) {
        theStore.dispatch("genericDialogShow", val).catch();
    }

    set genericDialogTitle(val: string) {
        theStore.dispatch("genericDialogTitle", val).catch();
    }

    set genericDialogText(val: string) {
        theStore.dispatch("genericDialogText", val).catch();
    }

    set genericDialogIcon(val: string) {
        theStore.dispatch("genericDialogIcon", val).catch();
    }

    set genericDialogConfirmCallback(val: Function | null) {
        theStore.dispatch("genericDialogConfirmCallback", val).catch();
    }

    set genericDialogOnCloseCallback(val: Function | null) {
        theStore.dispatch("genericDialogOnCloseCallback", val).catch();
    }

    get isDriver(): boolean {
        return this.role === 1;
    }

    get availableRoutes(): RwRoute[] {
        //console.log("RwGlobals.availableRoutes")
        let routes = this.routes;
        if (this.isDriver && RwPrefUtils.userAccessType < RwAccessTypes.SubDisp) {
            routes = this.routes.filter(route => route.driverId === this.userId);
        }
        return routes;
    }

    get centerMapData(): { lat: number, lng: number } {
        return theStore.getters.centerMapData;
    }

    set centerMapData(val: { lat: number, lng: number }) {
        theStore.dispatch("centerMapData", val).catch();
    }

    get showFlexOptIn(): boolean {
        //console.log("GET showFlexOptIn", theStore.getters.showFlexOptIn)
        return theStore.getters.showFlexOptIn;
    }

    set showFlexOptIn(newVal: boolean) {
        //console.log("SET showFlexOptIn", newVal)
        theStore.dispatch("showFlexOptIn", newVal);
    }

    get showOptInPostMessage(): boolean {
        //console.log("GET showOptInPostMessage", theStore.getters.showOptInPostMessage)
        return theStore.getters.showOptInPostMessage;
    }

    set showOptInPostMessage(newVal: boolean) {
        //console.log("SET showOptInPostMessage", newVal)
        theStore.dispatch("showOptInPostMessage", newVal);
    }

    get isLegacy(): boolean {
        return RwPrefUtils.isLegacy;
    }

    set isLegacy(newVal: boolean) {
        RwPrefUtils.isLegacy = newVal;
    }

    get isProGo(): boolean {
        return RwPrefUtils.isProGo;
    }

    set isProGo(newVal: boolean) {
        RwPrefUtils.isProGo = newVal;
    }

    get showAddReport(): boolean {
        return theStore.getters.showAddReport;
    }

    set showAddReport(newVal: boolean) {
        theStore.dispatch("showAddReport", newVal);
    }

    get showAddMissingReport(): boolean {
        return theStore.getters.showAddMissingReport;
    }

    set showAddMissingReport(newVal: boolean) {
        theStore.dispatch("showAddMissingReport", newVal);
    }


    get addReport(): RwAddReport {
        return theStore.getters.addReport;
    }

    set addReport(newVal: RwAddReport) {
        theStore.dispatch("addReport", newVal);
    }

    get greedyDist(): number {
        return theStore.getters.greedyDist;
    }

    set greedyDist(newVal: number) {
        theStore.dispatch("greedyDist", newVal);
    }

    get greedyTime(): number {
        return theStore.getters.greedyTime;
    }

    set greedyTime(newVal: number) {
        theStore.dispatch("greedyTime", newVal);
    }

    //#endregion


    //#region App Init

    initApp(): void {
        if (this.isLoggedIn) {
            this.loadData();
        }
    }

    canLoadSubteams(): boolean {
        return ((this.role === RwRoleTypes.Owner || this.role === RwRoleTypes.Admin)
            && this.hasActiveFlexSku
            && this.isTeam && !this.isSubTeam);
    }

    loadData(): void {
        const self = this;
        const SOURCE = "RwGlobals.loadData";
        //console.warn(SOURCE, `lastRouteId: ${RwPrefUtils.lastRouteId}`);
        let lastRouteId = RwPrefUtils.lastRouteId;

        if (dal.gotToken()) {
            this._inMemUserId = RwPrefUtils.userId;
            if (this.hasActiveFlexSku) {
                self.checkNotificationSetup();
                self.getWebPrefs();
                self.checkOutdatedVersion();
                self.syncDataEssential(lastRouteId)
                    .then(() => {
                        if (dal.gotToken()) {
                            self.syncDataSecondary();
                            if (this.canLoadSubteams()) {
                                subTeamSpace.loadSubTeams();
                            }
                        } else {
                            RwLog.warn(SOURCE, `syncDataEssential Invalid token: ${RwPrefUtils.token}`);
                            let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                            dal.forceLogout(logErr);
                            return Promise.reject(logErr);
                        }
                    })
                    .catch(err => {
                        if ((err && err.isHandled) == false) {
                            RwLog.error(SOURCE, `syncDataEssential Err: \n${JSON.stringify(err)}`);
                        }
                    })
                //setTimeout(() => self.syncDataSecondary(), 3500)

                this.pollDataCancelled = false;
                this.pollDriversCancelled = false;

                let orgDelay = 5 * 60 * 1000; //5 minutes
                let orgInterval = 5 * 60 * 1000; //5 minutes
                let orgTimeout = 95000;
                RwLog.consoleLog("pollOrgData interval secs:", orgInterval / 1000);
                self.pollOrgData(orgDelay, orgInterval, orgTimeout);

                if (RwPrefUtils.isTeam && RwPrefUtils.isDisp) {
                    let statusDelay = 1 * 60 * 1000; //1 minute
                    let statusInterval = 1 * 60 * 1000; //1 minute
                    RwLog.consoleLog("pollStatusInfo interval secs:", statusInterval / 1000);
                    self.pollStatusInfo(statusDelay, statusInterval);
                }

                let checkDelay = 1 * 60 * 1000; //1 minute
                let checkInterval = 1 * 60 * 1000; //1 minute
                self.checkUserIdMatch(checkDelay, checkInterval);
            } else {
                if (this.hasActiveProSku) {
                    let checkDelay = 1 * 60 * 1000; //1 minute
                    let checkInterval = 1 * 60 * 1000; //1 minute
                    self.checkUserIdMatch(checkDelay, checkInterval);
                }
                // For basic users, populate drivers with self
                const disp = new RwUser();
                disp.name = this.dispName;
                disp.userName = this.userName;
                disp.accessType = RwPrefUtils.userAccessType;
                this.drivers = [disp];
                //
                // this.showConfirmDialog("No Flex Purchase Detected",
                //   "You must have a RoadWarrior Flex subscription to access the web app.",
                //   "Upgrade to Flex", "Log out")
                //   .then(isConfirmed => {
                //     if (isConfirmed){
                //       router.push({name: RwPages.Upgrade}).catch(err => {
                //
                //       });
                //     }else{
                //       this.logOut();
                //     }
                //   })


            }
        } else {
            RwLog.warn(SOURCE, `Invalid Token: ${RwPrefUtils.token}`);
            let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
            dal.forceLogout(logErr);
        }
    }

    checkNotificationOnboard(): string {
        let permission = Notification.permission;
        if (permission === 'default') {
            return 'default';
        } else if (permission === 'granted') {
            return 'granted';
        } else if (permission === 'denied') {
            return 'denied';
        } else {
            return 'no permissions registered';
        }
    }


    checkNotificationSetup() {
        const SOURCE = "RwGlobals.checkNotificationSetup";
        try {

            let notificationsSupported = "Notification" in window && isSupported();
            if (notificationsSupported) {
                let permission = Notification.permission;
                //console.log(SOURCE, "Notification.permission", permission);
                if (permission !== 'denied') {
                    if (permission === 'granted') {
                        this.configNotes();
                    }
                    if (permission === 'default' && this.hasOnboarded === true && !this.showOnboardSettings) {
                        this.showConfirmDialog('Allow Push Notifications', 'RoadWarrior can send updates to your device via push notification. To authorize notifications, hit Continue, then approve the request from your browser. To reject notifications, hit Continue, then reject the request from your browser.', 'Continue', 'Ask Later')
                            .then(isConfirmed => {
                                if (isConfirmed) {
                                    this.browserNotesRequest();
                                }
                            })
                    }
                } else {
                    RwLog.warn(SOURCE, "Web Notifications denied");
                    //this.showSnack('Browser notifications disabled', 'warning');
                }
            } else {
                RwLog.warn(SOURCE, "Browser does not support desktop notification");
                //this.showSnack('Browser does not support desktop notification', 'error');
            }
        } catch (err) {
            let msg = `Unhandled: ${err}`;
            if (msg.includes("This browser doesn't support") || msg.includes("already exists")) {
                RwLog.warn(SOURCE, msg);
            } else {
                RwLog.error(SOURCE, msg);
            }
        }
    }

    browserNotesRequest() {
        const self = this;
        const SOURCE = "RwGlobals.browserNotesRequest";
        Notification.requestPermission()
            .then(res => {
                self.onMessagePermission(res);
            })
            .catch(err => {
                let errText = err.toString();
                if (errText.contains("messaging/unsupported-browser")) {
                    RwLog.warn(SOURCE, `requestPermission error: ${err}`);
                    //this.showSnack("This browser does not support notifications.", "error");
                } else if (errText.contains("messaging/token-subscribe-failed")) {
                    RwLog.warn(SOURCE, `token subscribe failed error: ${err}`);
                    //this.showSnack("This browser does not support notifications.", "error");
                } else {
                    RwLog.error(SOURCE, `requestPermission error: ${err}`);
                }
            });
    }


    onMessagePermission(permission: NotificationPermission) {
        const SOURCE = "RwGlobals.onMessagePermission";
        //console.log(SOURCE, "granted: ", permission);

        if (permission === "granted") {
            this.configNotes();

            if (this.messaging) {
                getToken(this.messaging, {vapidKey: RwConstants.FirebaseKey})
                    .then(currentToken => {
                        if (currentToken) {
                            //console.log("Got token:", currentToken);
                            RwTaskPush.updatePushToken(currentToken)
                                .then(currentToken => {
                                    if (currentToken) {
                                        this.pushEnabled = true;
                                    } else {
                                        RwLog.error(SOURCE, 'WTF: No Instance ID token available. Request permission to generate one.');
                                        // Show permission UI.
                                        // updateUIForPushPermissionRequired();
                                        // setTokenSentToServer(false);
                                    }
                                })
                                .catch(err => {
                                    if (this.checkNotHandled(err) && err?.status !== 201) {
                                        RwLog.error(SOURCE, `updatePushToken error: ${err}`);
                                    }
                                });
                            //sendTokenToServer(currentToken);
                            //updateUIForPushEnabled(currentToken);
                        } else {
                            // Show permission request.
                            RwLog.warn(SOURCE, "No Instance ID token available. Request permission to generate one.");
                        }
                    })
                    .catch((err) => {
                        if (this.checkNotHandled(err)) {
                            let errString = JSON.stringify(err);
                            if (errString == "{}") {
                                RwLog.warn(SOURCE, `Internal firebase error, ${errString}`);
                            } else if (errString && errString.indexOf('no active Service Worker') !== -1) {
                                RwLog.warn(SOURCE, `Firebase error, no active service worker found: ${errString}`);
                            } else if (err && err.code === 'messaging/failed-service-worker-registration') {
                                RwLog.warn(SOURCE, `Firebase error, service worker registration failed: ${errString}`);
                            } else if (err && err.code === 'messaging/token-subscribe-failed') {
                                RwLog.warn(SOURCE, `Firebase error, token subscribe failed: ${errString}`);
                            } else if (err && err.code === 'messaging/permission-blocked') {
                                RwLog.warn(SOURCE, `Firebase error, permission failed: ${errString}`);
                                this.showSnack("It looks like you may not have push notifications allowed - please enable them if prompted.", "warning");
                            } else {
                                RwLog.error(SOURCE, `Unhandled \n\n${JSON.stringify(err)}`);
                            }
                        }
                    });
            } else {
                RwLog.warn(SOURCE, "messaging == null, firebase not setup");
            }
        }
    }


    configNotes() {
        const SOURCE = "RwGlobals.configNotes"
        //console.log(SOURCE);
        try {
            this.fbApp = initializeApp(RwConstants.FirebaseConfig);
            this.messaging = getMessaging(this.fbApp);
            onMessage(this.messaging, (payload) => {
                this.onMessage(payload);
            });

            this.messageTest();
        } catch (err) {
            RwLog.warn(SOURCE, `Error setting up notifications ${JSON.stringify(err)}`);
        }
    }


    messageTest() {
        const SOURCE = "RwGlobals.messageTest";

        getToken(this.messaging, {vapidKey: RwConstants.FirebaseKey})
            .then((currentToken) => {
                if (currentToken) {
                    //console.log('messageTest', currentToken);
                    this.pushEnabled = true;
                    this.updatePushToken(currentToken);
                } else {
                    // Show permission request.
                    RwLog.consoleWarn('No Instance ID token available. Request permission to generate one.');
                    // Show permission UI.
                    // updateUIForPushPermissionRequired();
                    // setTokenSentToServer(false);
                }
            })
            .catch((err) => {
                RwLog.warn(SOURCE, `Unhandled: ${err}`);
                // DEFER: clear server token??
                // showToken('Error retrieving Instance ID token. ', err);
                // setTokenSentToServer(false);
            });
    }


    onMessageTokenRefresh() {
        const SOURCE = "RwGlobals.onTokenRefresh";
        if (this.messaging) {

            getToken(this.messaging, {vapidKey: RwConstants.FirebaseKey})
                .then((refreshedToken) => {
                    //console.log("Token refreshed.", refreshedToken);
                    this.updatePushToken(refreshedToken);
                })
                .catch(function (err) {
                    RwLog.error(SOURCE, `Unhandled: \n\n${JSON.stringify(err)}`);
                });
        } else {
            RwLog.warn(SOURCE, "messaging == null, firebase not setup");
        }
    }


    updatePushToken(refreshedToken: string) {
        const SOURCE = "RwGlobals.updatePushToken";
        //console.log(SOURCE, `refreshedToken: ${refreshedToken}`);
        if (RwPrefUtils.userId) {
            RwTaskPush.updatePushToken(refreshedToken)
                .then(isUpdated => {
                    if (isUpdated) {
                        this.pushEnabled = true;
                    } else {
                        RwLog.consoleError(SOURCE, `WTF: Token refresh not updated`);
                    }
                })
                .catch(err => {
                    RwLog.warn(SOURCE, `Token refresh fail: ${err}`);
                });
        }
    }


    onMessage(payload) {
        const SOURCE = "RwGlobals.onMessage";

        try {
            if (payload && payload.data) {
                const data = payload.data;
                const msg = JSON.stringify(data);
                const route = this.activeRoute;
                const routeId = data.rtid;
                const routeIdList = data.routeIdCsv;

                RwLog.log(SOURCE, msg);
                //RwLog.consoleLog(SOURCE, `${msg.action}`);
                // RwLog.consoleWarn(SOURCE, `${msg.action}, routeId:${routeId}, routeIdList:${routeIdList}`);
                // RwLog.consoleLog(SOURCE, `${msg.action}, routeId:${routeId}, routeIdCsv:${routeIdCsv}`);
                //console.log(SOURCE, `action:${msg.action}`);

                switch (data.action) {
                    case "routeDeltas":
                        // console.log(SOURCE, `routeDeltas msg.routeId: ${msg.rtid}`);
                        // console.log(SOURCE, `routeDeltas msg.routeIdCsv: ${msg.routeIdCsv}`);
                        let isActive = false;
                        if (route) {
                            isActive = routeId && route.routeId === routeId;
                            if (isActive) {
                                //console.log(SOURCE, `${msg.action}, routeName:${route.name} routeId: ${routeId}, isActive:${isActive}`);
                            } else if (routeIdList && routeIdList.length > 0) {
                                const routeIds = routeIdList.split(',');
                                isActive = routeIds.find(id => id === route.routeId);
                                // console.log(SOURCE, `${msg.action}, routeIdList:${routeIdList}, isActive:${isActive}`);
                            }
                        }

                        //Do the minimum viable refresh [ none, status, full]
                        if (isActive) {
                            //console.log(SOURCE, `routeDeltas getFullRoute: ${route.routeId}`);
                            this.getFullRoute(route)
                        } else {
                            //console.log(SOURCE, `routeDeltas NOT ACTIVE: ${route.routeId}`);
                        }
                        break;

                    // case "routeupdated":
                    //   if (isActive) {
                    //     console.log(SOURCE, `getFullRoute: ${route.routeId}`);
                    //     self.getFullRoute(route);
                    //   }
                    //   break;

                    case "driverdelta":
                        //console.log(SOURCE, `getFullRoute: ${route.routeId}`);
                        //self.getFullRoute(route);
                        if (this.isRouteDirty) {
                            this.getStatusInfo();
                        }
                        break;

                    default:
                        //RwLog.error(SOURCE, "WTF: Unknown Action:" + msg.action);
                        break;
                }

                //Delay due to timing issues
                setTimeout(() => {
                    //xTEST: added to help diagnose why some messages are not found;
                    RwLog.log(SOURCE, `Delivered ${data.action}, msgId:${data.msgId}, msg:${msg}`)
                    RwTaskPush.setPushMsgStatus(data.msgId, RwPushStatus.Delivered)
                }, 250);

            } else {
                RwLog.error(SOURCE, "WTF: Missing Payload" + JSON.stringify(payload));
            }
        } catch (err) {
            RwLog.error(SOURCE, `Unhandled: ${err} \n\n${JSON.stringify(payload)}`);
        }
    }


    get isRouteDirty(): boolean {
        //console.log("lastDelta vs driverLastSync", this.globals.lastStatusMS, this.globals.driverLastSyncMS)
        let isUpdated = this.lastRouteDeltaMS > 0 && this.lastRouteDeltaMS > this.driverLastSyncMS;
        return isUpdated;
    }


    getWebPrefs() {
        const SOURCE = "RwGlobals.getWebPrefs";
        RwTaskMisc.getWebPrefs()
            .then(prefs => {
                //console.log("RwGlobals.getWebPrefs", prefs.SvcTime)
                // console.warn('getWebPrefs called');
                if (prefs.SvcTime) RwPrefUtils.visitTime = prefs.SvcTime;
                if (prefs.RouteSpan) RwPrefUtils.routeSpan = prefs.RouteSpan;
                RwPrefUtils.distUnits = prefs.UseMiles === true ? RwDistUnits.Miles : RwDistUnits.Kilometers;
                RwPrefUtils.isDynPoint = prefs.DynStart;
                RwPrefUtils.travelMode = prefs.TravelMode;
                RwPrefUtils.optimizeType = prefs.OptMode;
                RwPrefUtils.isRoundTrip = prefs.IsRndTrip;
                RwPrefUtils.avoids = prefs.Avoids;
                RwPrefUtils.siteColor = prefs.DefFavColor;
                RwPrefUtils.stopColor = prefs.DefStopColor;
                RwPrefUtils.taskColor = prefs.TaskColor;
                RwPrefUtils.proofOfDelivery = prefs.podDefault ?? PODDefaultSettings.None;

                if (prefs.ShowFedexUpload) RwPrefUtils.showFdxUpload = prefs.ShowFedexUpload;
                if (prefs.FedExPromptStatus) RwPrefUtils.fdxPromptStatus = prefs.FedExPromptStatus;
                if (prefs.ManiSetSequence) RwPrefUtils.maniSetSeq = prefs.ManiSetSequence;
                if (prefs.StartGPS) {
                    try {
                        let split = prefs.StartGPS.split(",");
                        if (split && split.length == 2) {
                            RwPrefUtils.startLat = parseFloat(split[0]);
                            RwPrefUtils.startLng = parseFloat(split[1]);
                        }
                    } catch (err) {
                        if (this.checkNotHandled(err)) {
                            RwLog.error(SOURCE, `Unhandled Error: ${err}`);
                        }
                    }
                }
            });
    }

    cleanVisitTime(val: number) {
        let cleantime: number = 0;
        if (val >= 0 && val <= 999) {
            cleantime = Math.round(val);
        } else {
            if (val < 0) {
                cleantime = 0
            }

            if (val > 999) {
                cleantime = 999
            }

        }
        return cleantime;
    }


    saveWebPrefs(): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobals.saveWebPrefs";

        return new Promise<boolean>(function (resolve) {

            let prefs = <RwWebPrefs>{};
            prefs.SvcTime = RwPrefUtils.visitTime;
            prefs.RouteSpan = RwPrefUtils.routeSpan;
            prefs.UseMiles = RwPrefUtils.distUnits === RwDistUnits.Miles;
            prefs.DynStart = RwPrefUtils.isDynPoint;
            prefs.IsRndTrip = RwPrefUtils.isRoundTrip;
            prefs.Avoids = RwPrefUtils.avoids;
            prefs.OptMode = RwPrefUtils.optimizeType;
            prefs.TravelMode = RwPrefUtils.travelMode;
            prefs.ErrorRes = RwPrefUtils.uploadErrorRes;
            prefs.DefStopColor = RwPrefUtils.stopColor;
            prefs.TaskColor = RwPrefUtils.taskColor;
            prefs.DefFavColor = RwPrefUtils.siteColor;
            prefs.ShowFedexUpload = RwPrefUtils.showFdxUpload;
            prefs.FedExPromptStatus = RwPrefUtils.fdxPromptStatus;
            prefs.ManiSetSequence = RwPrefUtils.maniSetSeq;
            prefs.ShowOnTracUpload = RwPrefUtils.showOnTracUpload;
            prefs.FlexOptIn = RwPrefUtils.flexOptInSelected;
            prefs.AllowExpressOpt = RwPrefUtils.allowExpressOpt;

            //RwLog.consoleLog("Global prefs save", prefs);

            //DEFER: seems clunky to string concatenate rather than just save the values [defLat, defLng]
            let dynLat = RwPrefUtils.startLat;
            let dynLng = RwPrefUtils.startLng;
            if (dynLat !== 0 && dynLng !== 0) {
                prefs.StartGPS = `${dynLat},${dynLng}`;
            }

            // console.warn("prefs being saved:", prefs);
            //console.log("saveGlobalPrefs");
            RwTaskMisc
                .setWebPrefs(prefs)
                .then(isSaved => resolve(isSaved))
                .catch(err => {
                    if (self.checkNotHandled(err)) {
                        let prefsText = JSON.stringify(prefs);
                        RwLog.error(SOURCE, `Unhandled Error: \n\nPrefs:\n${prefsText} \n\nError:\n${err} \n\nAuthToken:\n${RwPrefUtils.token}`);
                    }
                    resolve(false);
                });
        });

    }

    saveProofOfDeliveryDefault(): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobals.saveProofOfDeliveryDefault";

        return new Promise<boolean>(function (resolve) {

            RwTaskMisc
                .setProofOfDeliveryDefault(RwPrefUtils.proofOfDelivery)
                .then(isSaved => resolve(isSaved))
                .catch(err => {
                    if (self.checkNotHandled(err)) {
                        RwLog.error(SOURCE, `Unhandled Error: \n\ProofOfDeliveryDefault:\n${RwPrefUtils.proofOfDelivery} \n\nError:\n${err} \n\nAuthToken:\n${RwPrefUtils.token}`);
                    }
                    resolve(false);
                });
        });

    }

    updateGtmDataLayer(data?: GtmDataLayer) {
        try {
            let dataToPush: GtmDataLayer = data;
            if (!dataToPush) {
                dataToPush = {

                    isLoggedIn: this.isLoggedIn,
                    isTrialFlex: this.hasActiveFlexTrial,
                    isActiveFlex: this.hasActiveFlexSku,
                    isMobilePro: this.hasActiveProSku && !this.hasActiveFlexSku

                }
            }
            const dataLayer = Vue.gtm.dataLayer();
            if (dataLayer) {
                dataLayer.push(dataToPush);
            }
        } catch (gtmErr) {
            RwLog.warn("updateGtmDataLayer", `Exception updating gtm data layer, ${JSON.stringify(data)}`);
        }
    }

    logoutOtherTabs(): void {
        sysend.broadcast(this.logoutMsg);
    }

    async logOut(fromOtherTab: boolean = false): Promise<void> {
        //`RwLog.consoleLog("logout called");
        //dal.cancelSource.cancel("Forced log out");
        //dal.generateNewCancelToken();
        this.isLoggedIn = false;

        this.pollDataCancelled = true;
        this.pollDriversCancelled = true;

        //Pass token on URL; otherwise if wil be null by the time axios makes async call
        if (!fromOtherTab) {
            RwTaskAccounts.DeactivateSession(this.token).catch();
            this.updateGtmDataLayer({isActiveFlex: false, isTrialFlex: false, isLoggedIn: false, isMobilePro: false});
        }

        // Close Dialogs
        this.m_showAddRoutePicker = false;
        this.m_showAssignPicker = false;
        this.m_showColorPicker = false;
        this.m_showConfirm = false;
        this.m_showReloPicker = false;
        this.m_showRouteNew = false;
        this.m_showRoutePicker = false;
        this.m_showSkedEdit = false;
        this.m_showSkedPicker = false;
        this.m_showStatusPicker = false;
        this.m_showVisitPicker = false;

        this.m_showRouteEdit = false;
        this.m_showStopEdit = false;
        this.m_showSiteEdit = false;
        this.m_showDriverEdit = false;
        this.genericDialogShow = false;
        this.subTeamSpace.showSubTeamEdit = false;

        //this.token = undefined;
        this.token = RwConstants.EmptyGuid;
        this.userId = undefined;
        this.userName = undefined;
        this.dispName = undefined;
        this.accountId = undefined;
        this.isLoggedIn = false;
        this.accountId = undefined;
        this.userId = undefined;
        this.stayLoggedIn = false;
        this.userPass = undefined;
        this.skuInfos = new Array<RwSkuInfo>();
        // this.skuInfos = undefined;
        this.isComponentLoaded = true;
        this.extId = undefined;
        this.extType = RwExtType.None;
        this.parentDomain = undefined;
        // this.isFedExSubDomain = false;
        // this.isReturningFedexManifest = false;
        // this.isOnTracSubDomain = false;
        this.orgId = undefined;
        this.orgs = [];
        this.adminEmail = "";
        if (this.avInfo) {
            this.avInfo.avUrl = undefined;
            this.avInfo.avType = undefined;
            this.avInfo.avInits = undefined;
            this.avInfo.avColor = undefined;
        }
        this.isDisp = false;
        this.isTeam = false;
        this.isSubTeam = false;
        this.m_hasActiveFlexSku = null;
        this.subDomain = null;
        this.isDataLoaded = false;
        this.isLoadedSites = false;
        this.isPro = false;

        this.isLoading = false;

        this.routes = new Array<RwRoute>();
        this.sites = new Array<RwSite>();
        this.drivers = new Array<RwUser>();
        this.skeds = new Array<RwSked>();

        if (this.subTeamSpace) {
            this.subTeamSpace.subTeams = [] as RwSubTeam[];
        }

        if (this.plan) {
            this.plan.activeRoute = null;
            this.plan.openRoutes = new Array<RwRoute>();
            this.plan.selectedPins = new Array<RwPin>();
        }

        RwPrefUtils.isLoggedIn = false;
        RwPrefUtils.highlightedTemplateThisSession = false;
        RwPrefUtils.lastRouteId = "";
        RwPrefUtils.flexOptInSelected = 0;

        this.lastSyncMS = 0;
        this.selectedRoute = null;

        thePlanSpace.startPin = null;
        thePlanSpace.clearPinSelections();
        thePlanSpace.multiMode = RwMultiMode.none;
        theStore.dispatch("selectedStop", null).catch();

        RwEventBus.$emit(RwEvents.Logout);

    }


    //#endregion


    //#region Sync APIs

    pollDataCancelled = false;
    pollDriversCancelled = false;
    isSyncInProgress = false;

    syncDataEssential(lastRouteId: string): Promise<void> {
        const SOURCE = "RwGlobals.syncDataEssential";
        const self = this;

        return RwTaskSync
            .syncEssential()
            .then(model => {

                this.routes = model.routes;
                this.drivers = model.drivers;
                this.skeds = model.skeds;
                this.teamDomain = model.teamDomain;
                this.plan.maxRouteSize = model.maxRouteSize;
                RwPrefUtils.maxRouteSize = model.maxRouteSize;
                //console.log("maxRouteSize", this.plan.maxRouteSize);

                self.isDataLoaded = true;
                if (lastRouteId && !this.isReturningFedexManifest) {
                    const route = this.findRoute(lastRouteId);
                    if (route) {
                        this.plan.openRoutes = this.routes.filter(route => RwPrefUtils.openRoutes.indexOf(route.routeId) > -1);
                        if (RwPrefUtils.openRoutes.indexOf(route.routeId) === -1) RwPrefUtils.openRoutes = [route.routeId, ...RwPrefUtils.openRoutes];
                        this.plan.openRoutes.forEach(r => this.plan.openRoute(r));
                        self.plan.activeRoute = route;
                        //self.isDataLoaded = true;
                    } else {
                        let gotRoutes = self.routes && self.routes.length > 0;
                        if (gotRoutes) {
                            this.routes.sort((r1, r2) => r2.finishTime.getTime() - r1.finishTime.getTime());
                            let firstRoute = this.routes[0];
                            if (firstRoute) {
                                this.plan.activeRoute = firstRoute;
                                self.getFullRoute(firstRoute)
                            }
                        }
                    }
                }

                if (this.isReturningFedexManifest) {
                    this.isReturningFedexManifest = false;
                }

            })
            .catch(err => {
                if (this.checkNotHandled(err)) {
                    let errStr = "undefined";
                    if (err.toString) {
                        errStr = err.toString();
                    } else if (err.message) {
                        errStr = err.message;
                    }
                    if (errStr === "Error: Request failed with status code 401") {
                        RwLog.warn(SOURCE, "401 Error: " + errStr);
                    } else {
                        RwLog.error(SOURCE, "Unhandled Error: " + errStr);
                    }
                }
            });
    }


    syncDataSecondary(): void {
        const SOURCE = "RwGlobals.syncDataSecondary";
        const self = this;


        RwTaskSync
            .syncSecondary()
            .then(model => {
                //console.log(SOURCE, "sites.length", this.sites.length);
                setTimeout(() => this.sites = model.sites, 0)
                setTimeout(() => this.tasks = model.tasks, 10)
            })
            .catch(err => {
                //if (err && err.IsHandled == false) {
                if (self.checkNotHandled(err)) {
                    let errStr = err.toString();
                    if (errStr === "Error: Request failed with status code 401") {
                        RwLog.warn(SOURCE, "401 Error: " + errStr);
                    } else {
                        RwLog.error(SOURCE, "Unhandled Error: " + errStr);
                    }
                }
            });
    }

    // Backup check to make sure local storage userid === in mem userid (to prevent multiple login tabs)
    checkUserIdMatch(delay: number, interval: number) {
        let self = this;

        interval = interval || 60000;
        let firstRun = true;
        let runCount = 0;

        (function checkUserIdMatch() {
            if (self.isLoggedIn) {
                if (self.userId === self._inMemUserId || firstRun) {
                    firstRun = false;
                    setTimeout(checkUserIdMatch, interval);
                } else {
                    self.logOut(true);
                    router.push({name: RwPages.Login}).catch();
                }
            }

        })();
    }

    pollStatusInfo(delay: number, interval: number) {
        let self = this;

        interval = interval || 60000;
        let firstRun = true;
        let runCount = 0;

        (function getStatusLoop() {
            if (self.isLoggedIn && self.isDisp) {
                if (firstRun && delay != 0) {
                    //RwLog.logConsole("team drv loc poll delay", new Date().toTimeString());
                    firstRun = false;
                    setTimeout(getStatusLoop, delay);
                } else {
                    if (self.pollDriversCancelled == false) {
                        const timeSinceLastStatus = moment().diff(self.lastStatus, "milliseconds");
                        //RwLog.consoleLog("pollStatusInfo", `Secs since last status: ${timeSinceLastStatus/1000}`);
                        if (self.IsTabActive || timeSinceLastStatus >= interval * 10) {
                            //RwLog.consoleLog("pollStatusInfo", `Performing org sync: ${self.IsTabActive ? 'Tab active' : timeSinceLastStatus/1000 + ' secs passed'}`);
                            runCount++;
                            //RwLog.warnConsole("team drv loc poll run", runCount, new Date().toTimeString());
                            self.getStatusInfo();
                        }

                        setTimeout(getStatusLoop, interval);
                    } else {
                        RwLog.consoleWarn("team drv loc poll job timeout", new Date().toTimeString()
                        );
                    }
                }
            }
        })();
    }

    getStatusInfo() {

        const self = this;
        const SOURCE = "RwGlobals.getStatusInfo";
        const openIds = self.plan.openRoutes.map(r => r.routeId);

        if (openIds.length > 0) {
            //console.log(SOURCE, `openMatch = getRouteStatus: ${openIds}`);
            self.getSyncStatus(openIds);
        } else {
            //console.warn(SOURCE, "SKIP: openIds empty");
        }
    }


    getDriverInfo() {
        const self = this;
        // if (RwPrefUtils.showDataLayerDrivers) {
        RwTaskDriver.getDriverInfo()
            .then(drvrs => {
                self.mergeDrivers(drvrs);
            })
            .catch(err => {
                RwLog.consoleError("WTF: getDriverInfo Error: " + err.toString());
            });
    }


    pollOrgData(delay: number, interval: number, timeout: number) {
        let self = this;
        let firstRun = true;
        let runCount = 0;
        interval = interval || 100;
        // RwLog.warnConsole("delay", delay);
        // RwLog.warnConsole("timeout", timeout);
        //RwLog.consoleWarn("pollOrgData interval",  interval);

        (function pollOrgRun() {
            if (!self.isLoggedIn) {
                return;
            }

            let routeId;
            let route = self.plan.activeRoute;
            if (route) {
                routeId = route.routeId;
            }

            if (firstRun && delay != 0) {
                //RwLog.logConsole("team poll delay", new Date().toTimeString());
                firstRun = false;
                setTimeout(pollOrgRun, delay);
            } else {
                if (self.pollDataCancelled == false) {
                    const timeSinceLastSync = moment().diff(self.lastSync, "milliseconds");
                    //RwLog.consoleLog("pollOrgData", `Secs since last org sync: ${timeSinceLastSync/1000}`);
                    if (self.IsTabActive || timeSinceLastSync >= interval * 10) {
                        //RwLog.consoleLog("pollOrgData", `Performing org sync: ${self.IsTabActive ? 'Tab active' : timeSinceLastSync/1000 + ' secs passed'}`);
                        runCount++;
                        //RwLog.warnConsole("team poll run", runCount, new Date().toTimeString());
                        self.syncDelta(routeId);
                    }

                    setTimeout(pollOrgRun, interval);
                } else {
                    //RwLog.warnConsole("poll job timeout", new Date().toTimeString());
                }
            }
        })();
    }


    syncDelta(lastRouteId?: string): Promise<void> {
        const self = this;
        const SOURCE = "RwGlobals.syncDelta";

        //console.log(SOURCE, "syncDelta");

        if (this.gotUser && this.gotToken) {

            return new Promise<void>(resolve => {

                let routeId: string;
                if (lastRouteId) {
                    routeId = lastRouteId
                } else {
                    if (this.plan.activeRoute) {
                        routeId = this.plan.activeRoute.routeId;
                    }
                }

                if (self.isSyncInProgress == false) {
                    self.isSyncInProgress = true;

                    RwTaskSync.syncDelta(routeId)
                        .then(deltas => {
                            //console.warn(SOURCE, "syncDelta callback", deltas);
                            self.mergeDeltas(deltas);
                        })
                        .catch(err => {
                            if ((err && err.isHandled) == false) {
                                RwLog.error(SOURCE, `syncDataEssential Err: \n${JSON.stringify(err)}`);
                            }
                        })
                        .finally(function () {
                            self.isSyncInProgress = false;
                            resolve();
                        });
                } else {
                    resolve();
                }


            });

        }

    }


    mergeDeltas(deltas: RwModel) {
        const self = this;
        //console.log("RwGlobals.mergeDeltas", deltas);

        if (deltas && deltas.isEmpty() == false) {

            self.mergeModel(deltas);
            //RwLog.consoleLog("getTeamDelta merged: ");

            let gotRouteDeltas = (deltas.routes && deltas.routes.length > 0) || (deltas.stops && deltas.stops.length > 0);
            if (gotRouteDeltas) {
                if (this.plan.activeRoute && this.plan.activeRoute.stops) {
                    this.commitRoute();
                    //console.error("mergeDeltas, commitRoute")
                }
            }

            //let gotStopDeltas = (deltas.stops && deltas.stops.length > 0);
            // console.error("mergeDeltas gotStopDeltas, gotRouteDeltas", gotStopDeltas, gotRouteDeltas)
            // //if (gotStopDeltas && !gotRouteDeltas) {
            // xFOCUS: This will cause a pin issue; uncomment to debug concurrent issue
            // if (gotStopDeltas) {
            //   console.error("mergeDeltas commitStops")
            //   this.commitStops();
            // }

            //Consider Trigger for update Stops

            let gotSiteDeltas = (deltas.sites && deltas.sites.length > 0);
            if (gotSiteDeltas) {
                this.commitSites();
            }

            let gotTaskDeltas = (deltas.tasks && deltas.tasks.length > 0);
            if (gotTaskDeltas) {
                this.commitTasks();
            }

        }

    }


    mergeDeleteCommits(commitList: RwCommit[], deltaModel: RwModel) {
        let self = this;
        if (commitList) {
            commitList.forEach(t => {
                if (t && t.txn === RwTxnTypes.DELETE && t.code === 200) {
                    switch (t.ntt) {

                        case RwEntityTypes.Route:
                            self.removeRouteById(t.eid);
                            break;

                        case RwEntityTypes.Stop:
                            self.removeStopById(t.eid);
                            break;

                        case RwEntityTypes.History:
                            self.removeHistById(t.eid);
                            break;

                        case RwEntityTypes.Sked:
                            self.removeSkedById(t.eid);
                            break;

                        case RwEntityTypes.Site:
                            self.removeSiteById(t.eid);
                            break;

                        case RwEntityTypes.Task:
                            self.removeTaskById(t.eid);
                            break;
                    }
                }
            });
        }
    }


    mergeReassignment(reassignList: RwAssignment[]) {
        let self = this;
        if (reassignList) {
            reassignList.forEach(r => {
                if (r) {
                    let rt = self.findRoute(r.rid);
                    if (rt) {
                        //xTODO: Assigning to itself???
                        //rt.driverId = rt.driverId;
                    }
                }
            });
        }
    }


    mergeModel(model: RwModel) {
        //Often this is used without being an actual sync API
        //this.lastSyncMS = model.lastSyncMS;
        this.mergeDeleteCommits(model.commitList, model);
        this.mergeHistory(model.history);
        this.mergeRoutes(model.routes);
        this.mergeSkeds(model.skeds);
        this.mergeSites(model.sites);
        this.mergeDrivers(model.drivers);
        this.mergeStops(model.stops);
        this.mergeTasks(model.tasks);
        this.mergeReassignment(this.reassignList);
        //this.updateBadges();
    }


    mergeHistory(deltas: RwHistory[]) {
        let self = this;
        if (deltas != null && deltas.length > 0) {
            deltas.forEach(function (hist) {
                let existing = self.findHistory(hist.histId);
                let isMarkedForDelete = hist.isDeleted;
                if (isMarkedForDelete) {
                    // RwLog.logConsole("remove activity: " + act.name)
                    self.removeHist(existing);
                } else {
                    if (existing != null) {
                        // RwLog.logConsole("update existing act: " + act.name)
                        existing.fromHistory(hist);
                    } else {
                        // RwLog.logConsole("create new act: " + act.name)
                        hist.lastHash = Date.now();
                        self.history.push(hist);
                    }
                }
            });
        }
    }


    mergeRoutes(deltas: RwRoute[]) {
        let self = this;
        if (deltas != null && deltas.length > 0) {
            deltas.forEach(function (route) {
                let existing = self.findRoute(route.routeId);
                let isMarkedForDelete = route.isDeleted;
                if (isMarkedForDelete) {
                    //RwLog.logConsole("remove route: " + route.name)
                    self.removeRoute(existing);
                } else {
                    if (existing != null) {
                        // RwLog.logConsole("update existing route: " + route.name)
                        existing.fromRoute(route);
                        if (self.activeRoute && self.activeRoute.routeId === route.routeId) {
                            self.activeRoute.fromRoute(route);
                        }
                    } else {
                        route.lastHash = Date.now();
                        self.routes.push(route);
                    }
                }
            });
        }
    }


    mergeSites(deltas: RwSite[]) {
        let self = this;
        if (deltas != null && deltas.length > 0) {
            deltas.forEach(function (site) {
                let existing = self.findSite(site.siteId);
                let isMarkedForDelete = site.isDeleted;
                if (isMarkedForDelete) {
                    //RwLog.logConsole("remove site: " + site.name)
                    self.removeSite(existing);
                } else {
                    if (existing != null) {
                        // RwLog.logConsole("update existing site: " + site.name)
                        existing.fromSite(site);
                    } else {
                        //RwLog.logConsole("create new site: " + site.name)
                        site.lastHash = Date.now();
                        self.sites.push(site);
                    }
                }
            });
        }
    }


    mergeSkeds(deltas: RwSked[]) {
        let self = this;
        if (deltas != null && deltas.length > 0) {
            deltas.forEach(function (sked) {
                let existing = self.findSked(sked.skedId);
                if (sked.markedForDelete) {
                    //RwLog.logConsole("remove sked: " + sked.name)
                    self.removeSked(existing);
                } else {
                    if (existing != null) {
                        //RwLog.logConsole("update existing sked: " + sked.name)
                        existing.fromSked(sked);
                    } else {
                        //RwLog.logConsole("create new sked: " + sked.name)
                        sked.lastHash = Date.now();
                        self.skeds.push(sked);
                    }
                }
            });
        }
    }


    mergeDrivers(deltas: RwUser[]) {
        let self = this;
        if (deltas != null && deltas.length > 0) {
            deltas.forEach(function (driver) {
                let existing = self.findDriver(driver.userId);
                if (driver.markedForDelete) {
                    //RwLog.logConsole("remove driver: " + driver.name)
                    self.removeDriver(existing.userId);
                } else {
                    if (existing != null) {
                        //RwLog.logConsole("update existing driver: " + driver.userName)
                        existing.fromDriver(driver);
                        existing.lastHash = Date.now();
                    } else {
                        //RwLog.logConsole("create new driver: " + driver.userName)
                        driver.lastHash = Date.now();
                        self.drivers.push(driver);
                    }
                }
            });
        }
    }


    mergeStops(deltas: RwStop[]) {

        let route = this.plan.activeRoute;
        if (route != null && deltas != null && deltas.length > 0) {
            const adds = [] as RwStop[];
            const removes = [] as RwStop[];
            const dls = [] as RwStop[];
            deltas.filter(s => s.routeId === route.routeId).forEach(function (stop) {
                let existing = route.findStop(stop.stopId);
                let isMarkedForDelete = stop.markedForDelete;
                if (isMarkedForDelete) {
                    if (existing) {
                        //console.log("mergeStops removing stop: " + stop.name);
                        removes.push(existing)
                        route.removeStopById(existing.stopId);
                    }
                } else {
                    if (existing != null) {
                        //console.log("mergeStops updating stop: " + stop.name);
                        existing.fromStop(stop);
                        dls.push(stop);
                    } else {
                        //console.log("mergeStops creating stop: " + stop.name);
                        route.addStop(stop);
                        //route.stops.push(stop);
                        adds.push(stop);
                    }
                }
            });
            thePlanSpace.updateStopPins(adds, removes, dls);
        }
    }


    mergeTasks(deltas: RwStop[]) {
        //console.log("mergeTasks");

        deltas.forEach(task => {
            let existing = this.tasks.find(t => t.stopId == task.stopId);
            let isMarkedForDelete = task.markedForDelete;
            if (isMarkedForDelete) {
                //console.log("removing task: " + task.name);
                if (existing) {
                    this.removeTask(task);
                }
            } else {
                if (existing != null) {
                    //console.log("updating task: " + task.name);
                    existing.fromStop(task);
                } else {
                    //console.log("creating task: " + task.name);
                    task.lastHash = Date.now();
                    this.tasks.push(task);
                }
            }
        });

    }


    //#endregion Sync APIs


    //#region Sort APIs


    sortSites() {
        let self = this;

        switch (RwPrefUtils.siteSort) {
            case FavSortOrder.Color:
                this.sites.sort((siteA, siteB) => {
                    if (siteA.priority === siteB.priority) {
                        return 0;
                    } else if (siteA.priority > siteB.priority) {
                        return -1;
                    }
                    return 1;
                });
                break;

            case FavSortOrder.Name:
                this.sites.sort((siteA, siteB) => {
                    return siteA.name.toLowerCase().localeCompare(siteB.name);
                });
                break;

            case FavSortOrder.LastCheckIn:
                this.sites.sort((siteA, siteB) => {
                    if (siteA.checkDate > siteB.checkDate) {
                        return -1;
                    } else if (siteA.checkDate === siteB.checkDate) {
                        return 0;
                    }
                    return 1;
                });
                break;
        }
    }


    sortHistory() {
        switch (RwPrefUtils.historySort) {
            case HistSortOrder.Date:
                this.history.sort((actA, actB) => {
                    if (actA.startTime > actB.startTime) {
                        return -1;
                    } else if (actA.startTime === actB.startTime) {
                        return 0;
                    }
                    return 1;
                });
                break;

            case HistSortOrder.Name:
                this.history.sort((actA, actB) => {
                    return actA.name.localeCompare(actB.name, "en", {
                        sensitivity: "base"
                    });
                });
                break;

            case HistSortOrder.Driver:
                this.history.sort((actA, actB) => {
                    if (actA.driverShortString === "" && actB.driverShortString !== "") {
                        return 1;
                    } else if (
                        actA.driverShortString !== "" &&
                        actB.driverShortString === ""
                    ) {
                        return -1;
                    } else if (
                        actA.driverShortString === "" &&
                        actB.driverShortString === ""
                    ) {
                        return 0;
                    }

                    if (actA.driverShortString !== "" && actB.driverShortString !== "") {
                        let dNameComp = actA.driverShortString.localeCompare(
                            actB.driverShortString,
                            "en",
                            {sensitivity: "base"}
                        );

                        if (dNameComp === 0) {
                            if (actA.startTime > actB.startTime) {
                                return -1;
                            } else if (actA.startTime === actB.startTime) {
                                return 0;
                            }
                            return 1;
                        } else {
                            return dNameComp;
                        }
                    }
                });
                break;
        }
    }


    sortDrivers() {
        const self = this;

        switch (RwPrefUtils.driversSort) {
            case DriverSortOrder.Name:
                this.drivers.sort((driverA, driverB) => {
                    let drvALegacy = driverA.userName.indexOf("@") !== -1;
                    let drvBLegacy = driverB.userName.indexOf("@") !== -1;

                    if (drvALegacy && !drvBLegacy) {
                        return 1;
                    } else if (!drvALegacy && drvBLegacy) {
                        return -1;
                    }

                    if (!driverA.name && driverB.name) {
                        return 1;
                    } else if (driverA.name && !driverB.name) {
                        return -1;
                    } else if (!driverA.name && !driverB.name) {
                        return 0;
                    }
                    return driverA.name.toLowerCase().localeCompare(driverB.name.toLowerCase());
                });
                break;

            case DriverSortOrder.LastUpdated:
                this.drivers.sort((driverA, driverB) => {
                    if (driverA.lastUpdated > driverB.lastUpdated) {
                        return -1;
                    }
                    return 1;
                });
                break;
        }
    }


    sortSkeds() {
        let self = this;

        switch (RwPrefUtils.skedsSort) {
            case SchedSortOrder.Name:
                this.skeds.sort((schedA, schedB) => {
                    return schedA.name.toLowerCase().localeCompare(schedB.name.toLowerCase());
                });
                break;

            case SchedSortOrder.Priority:
                this.skeds.sort((schedA, schedB) => {
                    if (schedA.priority > schedB.priority) {
                        return -1;
                    } else if (schedA.priority === schedB.priority) {
                        return 0;
                    }
                    return 1;
                });
                break;
        }
    }


    //#endregion Sort APIs


    //#region Find APIs


    findRoute(routeId: string): RwRoute {
        let found: RwRoute;
        if (routeId && routeId != "" && this.routes && this.routes.length > 0) {
            found = this.routes.find(r => r.routeId == routeId);
        }
        return found;
    }


    findDriver(driverId: string): RwUser {
        let found: RwUser;
        if (driverId && driverId != "" && this.drivers && this.drivers.length > 0) {
            found = this.drivers.find(d => d.userId === driverId);
        }
        if (!found && driverId == this.userId) {
            found = RwUser.DispatcherDriver;
        }
        return found;
    }


    findStop(stopId: string): RwStop {
        let found: RwStop;
        if (stopId && stopId != "") {
            if (this.activeRoute) {
                let stops = this.activeRoute.stops;
                if (stops && stops.length > 0) {
                    found = stops.find(s => s.stopId === stopId);
                }
            }
        }
        return found;
    }


    // findStop(stopId: string): RwStop {
    //   let found: RwStop;
    //   if (stopId && stopId != "") {
    //     if (this.stops && this.stops.length > 0) {
    //       found = this.stops.find(s => s.stopId === stopId);
    //     }
    //   }
    //   return found;
    // }


    findHistory(histId: string): RwHistory {
        let found: RwHistory;
        if (histId && histId != "") {
            if (this.history && this.history.length > 0) {
                found = this.history.find(h => h.histId == histId)
            }
        }
        return found;
    }


    findSite(siteId: String): RwSite {
        let found: RwSite;
        if (this.sites && this.sites.length > 0) {
            found = this.sites.find(s => s.siteId == siteId)
        }
        return found;
    }


    findSked(skedId: String): RwSked {
        let found: RwSked;
        if (this.skeds && this.skeds.length > 0) {
            found = this.skeds.find(s => skedId == s.skedId);
        }
        return found;
    }


    //#endregion FIND APIs


    //#region Route APIs

    getFullRoute(route: RwRoute, skipCommit = false) {
        const self = this;
        const SOURCE = "RwGlobals.getFullRoute";
        if (this.token && this.token != RwConstants.EmptyGuid) {
            RwTaskRoutes.getRoute(route)
                .then(updatedRoute => {
                    //console.warn(SOURCE, "callback success skipCommit", skipCommit);

                    if (!updatedRoute.isDeleted) {
                        if (!skipCommit) {
                            route.stops = [...updatedRoute.stops];
                        }
                    } else {
                        //xFOCUS: Handle deleted route
                        //RwLog.logConsole("remove route: " + route.name)
                        self.removeRoute(updatedRoute);
                        self.commitRoutes();
                    }

                    //self.commitRoute();
                });
        } else {
            RwLog.warn(SOURCE, `Invalid token:${this.token}`);
            let logErr = new RwError(400, true, "Invalid reset link");
            return Promise.reject(logErr);
        }

    }

    getFullRouteCallback(route: RwRoute, skipCommit = false) {
        const self = this;
        const SOURCE = "RwGlobals.getFullRouteCallback";
        return RwTaskRoutes.getRoute(route)
            .then(updatedRoute => {
                //console.warn(SOURCE, "callback success skipCommit", skipCommit);

                if (!updatedRoute.isDeleted) {
                    if (!skipCommit) {
                        route.stops = [...updatedRoute.stops];
                    }

                    return updatedRoute;
                } else {
                    //xFOCUS: Handle deleted route
                    //RwLog.logConsole("remove route: " + route.name)
                    self.removeRoute(updatedRoute);
                    self.commitRoutes();
                    return null;
                }

                //self.commitRoute();
            })
            .catch(err => {
                return null;
            });

    }


    getSyncStatus(routeIds: string[]) {
        const self = this;
        const SOURCE = "RwGlobals.getSyncStatus";
        const now = Date.now();
        const activeRouteId = this.activeRoute ? this.activeRoute.routeId : "";
        const activeDriverId = this.activeRoute ? this.activeRoute.driverId : "";

        let gotUserId = RwPrefUtils.userId && RwPrefUtils.userId.length > 0;
        let gotActiveRoute = activeRouteId && activeRouteId.length > 0;
        let gotRoutes = routeIds && routeIds.length > 0;
        let routeIdsNotFound: string[] = [];
        let routesNotFound: RwRoute[] = [];
        if (gotUserId && gotActiveRoute && gotRoutes) {

            //console.log(SOURCE);

            RwTaskSync
                .syncStatus(activeRouteId, routeIds)
                .then(deltas => {
                    //console.log(SOURCE, "callback", deltas);
                    if (deltas) {
                        self.mergeDeltas(deltas);

                        let syncStatus = deltas.syncStatus;
                        if (syncStatus && syncStatus.length > 0) {
                            syncStatus.forEach(status => {
                                let routeId = status.routeId;
                                let route = self.routes.find(sr => sr.routeId == routeId);
                                if (route) {
                                    //console.log(SOURCE, "got route:", route.name);
                                    route.stopCountActive = status.activeCount;
                                    route.stopCountComplete = status.completeCount;
                                    route.statusCode = status.statusCode;
                                    route.assignCode = status.assignCode;
                                    //route.name = status.routeName;

                                    //console.log(SOURCE, "routeName", status.routeName);
                                    // console.log(SOURCE, "driverLU", status.driverLU);
                                    // console.log(SOURCE, "driverLat", status.driverLat);
                                    // console.log(SOURCE, "driverLng", status.driverLng);

                                    let driverId = status.driverId;
                                    let driver = self.drivers.find(d => d.userId === driverId);
                                    if (driver) {
                                        //console.log(SOURCE, "got driver:", driver.name);
                                        if (status.driverLU.getTime() > driver.lastHash) {
                                            driver.lat = status.driverLat;
                                            driver.lng = status.driverLng;
                                            driver.coordLU = status.driverLU;
                                            driver.lastHash = now;

                                            if (activeDriverId === driverId) {
                                                self.commitRoute();
                                            }

                                        } else {
                                            //console.log(SOURCE, "driver up to date");
                                        }
                                    }
                                } else {
                                    RwLog.consoleLog(SOURCE, `route not found ${status.routeId}`);
                                    routeIdsNotFound.push(routeId);
                                    // self.deleteRoutes([route.routeId]);
                                    // syncStatus.splice(syncStatus.indexOf(status), 1);
                                }
                            });
                            // routeIdsNotFound.forEach(id => {
                            //   const route = (this.routes.find(route => route.routeId == id))
                            //   if (route){
                            //     routesNotFound.push(route);
                            //   }else{
                            //     //log
                            //   }
                            // });
                            if (routeIdsNotFound.length > 0) {
                                thePlanSpace.closeRoutesNotFound(routeIdsNotFound);
                            }
                            self.commitRoutes();
                        }
                    }

                    //this.activeRoute.stops = fullRoute.stops;
                    //console.warn("openClick complete", statusList);
                })
                .catch(err => {

                    if (this.checkNotHandled(err)) {
                        const message = err.message ? err.message : JSON.stringify(err)
                        RwLog.error(SOURCE, `Unhandled: ${message}`);
                    }
                });
        }
    }


    get routes(): RwRoute[] {
        return theStore.getters.routes;
    }

    set routes(routes: RwRoute[]) {
        theStore.dispatch("routes", routes).catch();
    }


    addRoute(route: RwRoute) {
        const index = this.routes.findIndex(r => r.routeId == route.routeId);
        if (route && index == -1) {
            this.routes.push(route);
        }
    }

    routeStartLocationRelo(lat: number, lng: number): Promise<number[]> {
        let self = this;
        let SOURCE = "RwGlobals.routeStartLocationRelo"
        return new Promise<number[]>(function (resolve, reject) {
            self.showReloPicker("Choose Start Location", lat, lng, true)
                .then((coord) => {
                    resolve([coord.lat, coord.lng])
                })
                .catch(err => {
                    reject([null, null])

                })
        });

    }

    defaultStartRelo(lat: number, lng: number, enableSearch = false): Promise<number[]> {
        let self = this;
        let SOURCE = "RwGlobals.defaultStartRelo"
        return new Promise<number[]>(function (resolve, reject) {
            self.showReloPicker("Edit Default Start Location", lat, lng, enableSearch)
                .then((coord) => {
                    resolve([coord.lat, coord.lng])
                })
                .catch(err => {
                    reject([null, null])

                })
        });

    }


    addRouteDB(route: RwRoute, stack: string[] = [], withStops?: boolean): Promise<RwRoute> {
        const SOURCE = "RwGlobals.addRouteDB";
        let self = this;

        return new Promise<RwRoute>(function (resolve, reject) {
            RwTaskRoutes
                .addRoute(route, withStops)
                .then(deltas => {
                    //console.log("RwGlobals.addRouteDB callback");
                    self.mergeDeltas(deltas);
                    let isCommitted = deltas.isCommitted(route.routeId, RwTxnTypes.POST);
                    if (isCommitted) {
                        resolve(route);
                    } else if (deltas.gotConflicts) {
                        RwLog.error(SOURCE, `Got Conflicts: Not Committed; \n\nRoute:\n${JSON.stringify(route)}\n\nStack:\n${JSON.stringify(stack)}`);
                        reject(new Error("Route not added due to conflict, please try again"));
                    } else {
                        RwLog.error(SOURCE, `Not Committed; \n\nRoute:\n${JSON.stringify(route)}\n\nStack:\n${JSON.stringify(stack)}`);
                        reject(new Error("Error adding route"));
                    }
                })
                .catch(err => {
                    if (self.checkNotHandled(err)) {
                        let status = !!err.Code ? err.Code : err.status;
                        // If error is RwError leave as it is, if it's Error, get message, if it's other object, stringify
                        const message = !(err instanceof RwError) ?
                            err.message ? err.message : JSON.stringify(err)
                            : err;
                        if (status === 403) {
                            RwLog.warn(SOURCE, `Forbidden: ${message}`);
                        } else {
                            try {
                                RwLog.error(SOURCE, `Unhandled: ${message}

                Route: ${route.name}
                Route ID: ${route.routeId}
                WithStops: ${withStops ? 'true' : 'false'}
                Stack:\n${JSON.stringify(stack)}`);
                            } catch (e) {
                                RwLog.error(SOURCE, `Unhandled: ${message}\n\nStack:\n${JSON.stringify(stack)}`);
                            }
                        }
                    }
                    reject(err);
                });
        });
    }


    updateRoute(route: RwRoute) {
        theStore.dispatch("setRoute", route).catch();
    }


    updateRouteDB(route: RwRoute, setFocus: boolean, withStops = false, touchStops = false, skipCommit = false): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobals.updateRouteDB"

        //console.log(SOURCE);

        return new Promise<boolean>(function (resolve, reject) {
            RwTaskRoutes
                .updateRoute(route, withStops, touchStops)
                .then(deltas => {

                    //console.log(SOURCE, "updateRoute callback", route.routeId, self.activeRoute.routeId);

                    if (self.activeRoute && self.activeRoute.routeId === route.routeId) {
                        self.lastRouteDeltaMS = Date.now();
                        //console.log("updateRouteDB", self.lastDelta);
                    }

                    // console.log("updateRouteDB", "mergeDeltas");
                    // self.mergeDeltas(deltas);

                    let isCommitted = true; //we can assume on single ops that 200 == success
                    //let isCommitted = deltas.isCommitted(route.routeId, RwTxnTypes.PUT);
                    //console.log("updateRouteDB, isCommitted", isCommitted);
                    if (isCommitted) {

                        //save this as our last position
                        RwPrefUtils.lastLat = route.lat;
                        RwPrefUtils.lastLng = route.lng;

                        if (self.plan.activeRoute) {
                            self.plan.activeRoute.lastHash = Date.now();
                        } else {
                            //REVIEW: Can't assume route is found
                            //self.findRoute(route.routeId).lastHash = Date.now();
                            route.lastHash = Date.now();
                            let routeFound = self.findRoute(route.routeId);
                            if (routeFound) {
                                routeFound.lastHash = Date.now();
                            } else {
                                RwLog.warn(SOURCE, `Route NOT FOUND! routeId${route.routeId}`);
                            }
                        }
                        if (setFocus) self.targetFocusId = route.routeId;
                        if (!skipCommit) {
                            // console.log("RwGlobals.updateRouteDB", "commitRoute")
                            self.commitRoute();
                        }
                        resolve(isCommitted);
                    } else {
                        if (deltas.gotConflicts) {
                            reject(new Error("Route not updated due to conflict, please try again."));
                        } else {
                            reject(new Error("Error updating route"));
                        }
                    }

                })
                .catch(err => {
                    if (self.checkNotHandled(err)) {
                        let status = !!err.Code ? err.Code : err.status;
                        let msg = JSON.stringify(err);
                        if ((err.error && (err.error.code === "ECONNABORTED" || err.error.message === "Network Error")) ||
                            err.error === "Network Error") {
                            RwLog.warn(SOURCE, `Network Error: ${msg}`);
                        } else if (status === 403) {
                            RwLog.warn(SOURCE, `Forbidden: ${msg}`);
                        } else {
                            RwLog.error(SOURCE, `Unhandled: ${msg}`);
                        }
                    }
                    reject(err);
                });
        });
    }


    copyRoute(route: RwRoute, copyType: RwRouteCopyType, stack: string[] = [], newName?: string): Promise<RwRoute> {
        const self = this;
        const SOURCE = 'RwGlobals.copyRoute';
        return new Promise<RwRoute>(function (resolve, reject) {

            //Validate input; Logs show route == null
            if (route) {
                RwTaskRoutes
                    .getRouteStandalone(route)
                    .then(fullRoute => {
                        let clone = fullRoute.copy(copyType);

                        for (let i = 0; i < clone.stops.length; i++) {
                            let stop = clone.stops[i];
                            stop.routeId = clone.routeId;
                            stop.stopId = RwSysUtils.guidNew();
                            if (self.showProofOfDelivery)
                            {
                                stop.addOns.stopId = stop.stopId;
                                stop.addOns.addOnsId = null;
                            }
                        }

                        clone.name = (!newName) ? route.name + " - Copy" : newName;

                        let withStops = false;
                        switch (copyType) {
                            case 0:
                                withStops = true;
                                break;

                            case 1: {
                                withStops = true;
                                clone.stops = clone.stops.filter(s => s.isComplete === false);
                                break;
                            }
                            case 2: {
                                withStops = false;
                                break;
                            }
                        }
                        //Moved CopyRoute >> RwGlobals
                        if (clone.name) {
                            self.addRouteDB(clone, [...stack, SOURCE], withStops)
                                .then(savedClone => {
                                    self.addRoute(savedClone);
                                    self.showSnack(`Route Copied to ${savedClone.name}`, "success");
                                    resolve(savedClone);
                                })
                                .catch(err => {
                                    if (self.checkNotHandled(err)) {
                                        const errText = JSON.stringify(err);
                                        const cloneJson = JSON.stringify(clone.toJsonWithAllStops());
                                        self.showSnack(`Route Copy Failed: ${err.description}`, "error");
                                        RwLog.error(SOURCE, `addRouteDB withStops:${withStops} \n\nClone:\n${cloneJson} \n\nError:\n${errText}\n\nStack:\n${JSON.stringify(stack)}`);
                                    }
                                    reject(err);
                                });
                        } else {
                            self.showSnack(`Route Name Undefined, please refresh and try again.`, "error");
                        }
                    })
                    .catch(err => {
                        if (self.checkNotHandled(err)) {
                            const errDetails = JSON.stringify(err);
                            const routeJson = JSON.stringify(route.toJsonWithAllStops());
                            RwLog.error(SOURCE, `Unhandled getRoute: copyType:${copyType}, newName:${newName} \n\nRoute:\n${routeJson}
             \n\nError:\n${errDetails}\n\nStack:\n${JSON.stringify(stack)}`);

                        }
                        reject(err);
                    });

            } else {
                const routeText = route!! ? JSON.stringify(route.toJsonWithAllStops()) : "missing";
                RwLog.error(SOURCE, `Unhandled: copyType:${copyType}, newName:${newName} \n\nRoute:\n${routeText}  `);
                reject(new RwError(400, false));
            }

        });
    }


    removeRouteById(routeId: string) {
        if (routeId && routeId !== "") {
            let route = this.routes.find(r => r.routeId === routeId);
            if (route) {
                this.removeRoute(route);
            }
        }
    }


    removeRoute(route: RwRoute) {
        if (route != null) {
            let index = this.routes.indexOf(route, 0);
            if (index > -1) {
                this.routes.splice(index, 1);
            }
        } else {
            RwLog.consoleError("route == null");
        }
    }


    assignRoute(route: RwRoute): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobals.assignRoute";

        return new Promise<boolean>(function (resolve) {

            //console.log(SOURCE);

            let initDriverId = route.driverId;
            let initAssignCode = route.assignCode;
            self.selectedRoute = route;

            //console.log(SOURCE, "showAssignPicker");

            self
                .showAssignPicker(route)
                .then(selectedRoute => {
                    //console.log(SOURCE, "Callback: showAssignPicker", selectedRoute);
                    if (selectedRoute) {

                        //Check if the route has changed
                        let gotDriverDelta = initDriverId != selectedRoute.driverId;
                        let gotAssignDelta = initAssignCode != selectedRoute.assignCode;
                        let gotDeltas = gotDriverDelta || gotAssignDelta;
                        if (gotDeltas) {
                            //console.warn("onAssignPick assignCode, driverId", selectedRoute.assignCode, selectedRoute.driverId);
                            self
                                .updateRouteAssignment(selectedRoute, initAssignCode, initDriverId)
                                .then(isUpdated => {
                                    route.driverId = selectedRoute.driverId;
                                    route.assignCode = selectedRoute.assignCode;
                                    //console.warn("onAssignPick assignCode, driverId", route.assignCode, route.driverId);
                                    resolve(isUpdated)
                                })
                        } else {
                            resolve(false)
                        }
                    } else {
                        resolve(false)
                    }
                })
        });
    }


    private updateRouteAssignment(route: RwRoute, oldAssignCode: RwRouteAssignCodes, oldDriverId: string): Promise<boolean> {
        const self = this;
        const newDriverId = route.driverId;
        const newAssignCode = route.assignCode;

        return new Promise<boolean>(function (resolve) {

            let driver = self.drivers.find(u => u.userId === newDriverId);
            if (route && route.routeId && driver) {
                let gotDriverDelta = newDriverId != oldDriverId;
                let gotAssignDelta = newAssignCode != oldAssignCode;
                if (gotDriverDelta || gotAssignDelta) {

                    let isSelfy = newDriverId === self.userId;
                    if (gotDriverDelta && !gotAssignDelta) {
                        route.assignCode = isSelfy ? RwRouteAssignCodes.assigned : RwRouteAssignCodes.pending;
                    }

                    //console.log("assignRoute: isSelfy, driver.userId, self.userId", isSelfy, driver.userId, self.userId);
                    let status = new RwSyncStatus();
                    status.routeId = route.routeId;
                    status.statusCode = route.statusCode;
                    status.assignCode = route.assignCode;
                    status.driverId = oldDriverId;
                    status.targetDriverId = route.driverId;
                    //console.log("assignRoute status: driverId, assign", status.assignCode, status.targetDriverId);

                    RwTaskRoutes.setRouteAssignment(status)
                        .then(respList => {

                            //console.log("setRouteAssignment SUCCESS", respList);
                            route.assignCode = status.assignCode;
                            route.driverId = status.targetDriverId;
                            route.lastHash = Date.now();
                            self.commitRoute();
                            resolve(true)

                            //Check if the drivers received their messages
                            if (respList) {
                                if (respList && respList.length > 0) {
                                    let undelivered = respList.filter(m => m.code !== 200);
                                    if (undelivered.length > 0) {

                                        // this.drivers.forEach(d => {
                                        //   console.log("\t driverTarget", d.userId, d.name, d.userName, d);
                                        // })

                                        undelivered.forEach(m => {
                                            //console.log("not delivered", m);
                                            let driverTarget = self.drivers.find(d => d.userId === m.userId);
                                            if (driverTarget) {
                                                //console.log("driverTarget", m.userId, driverTarget.name, driverTarget.userName, driverTarget);
                                                let msgDriver = `${driverTarget.userName} (${driverTarget.name})`;
                                                let msgAssign = m.pushType == 0 ? "assigned" : "reassigned";
                                                let msg = `Push message not delivered to ${msgAssign} driver: ${msgDriver}.`
                                                self.showSnack(msg, "warning")
                                            }
                                        })
                                    }
                                }
                            } else {
                                RwLog.consoleError("assignRoute not updated");
                            }

                        })
                        .catch(err => {
                            //console.error("setRouteAssignment", err)
                            route.driverId = oldDriverId;
                            route.assignCode = oldAssignCode;
                            resolve(false)
                            self.showSnack("Update Route Status Failed", "error");
                        })
                } else {
                    resolve(false)
                    //console.log("picked driver already assigned.  no action needed");
                }
            } else {
                resolve(false)
                RwLog.consoleError("WTF: route | driver == null", route, driver);
            }

        });

    }


    onRouteStatus(routeUpdate: RwRoute) {
        const self = this;

        self
            .showStatusPicker(routeUpdate.name, routeUpdate.statusCode)
            .then(newStatus => {
                //console.log("onRouteStatus showStatusPicker callback", newStatus);
                let statusUpdate = new RwSyncStatus();
                statusUpdate.routeId = routeUpdate.routeId;
                statusUpdate.statusCode = newStatus;
                statusUpdate.assignCode = routeUpdate.assignCode;
                statusUpdate.driverId = routeUpdate.driverId;
                RwTaskRoutes.setRouteStatusInfo(statusUpdate)
                    .then(() => {
                        routeUpdate.statusCode = statusUpdate.statusCode;
                        routeUpdate.invalidateCache();
                        //console.warn("status update to:", status.status, route.name);
                    });

            });
    }


    confirmAndDeleteRoute(routeUpdate: RwRoute): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobals.deleteRoute";

        //console.warn("onRouteDelete", route.name);

        return new Promise<boolean>(function (resolve, reject) {
            if (routeUpdate) {

                self
                    .showConfirmDialog(`Delete Route: ${routeUpdate.name}`, `Are you sure you want to delete this route?`)
                    .then(isConfirmed => {
                        //console.warn("onRouteDelete isConfirmed", isConfirmed);
                        if (isConfirmed) {
                            let ids = [routeUpdate.routeId];
                            self
                                .deleteRoutes(ids)
                                .then(() => {
                                    self.removeRoute(routeUpdate);
                                    self.commitRoutes();

                                    if (self.isDisp) {
                                        let isSelfy = routeUpdate.driverId === self.userId;
                                        if (!isSelfy) {
                                            self.pushUpdateToDriver(routeUpdate)
                                        }
                                    }

                                    self.showSnack("Route Deleted", "success");
                                    resolve(true);
                                })
                                .catch(err => {
                                    if (self.checkNotHandled(err)) {
                                        RwLog.error(SOURCE, `Unhandled: ${err}`);
                                        self.showSnack(`Route Delete Failed: ${err}`, "error");
                                    }
                                    reject(err);
                                });

                        }
                    })
                    .catch(err => {
                        RwLog.consoleError(SOURCE, "Unhandled err: ", err.toString());
                    });
            } else {
                RwLog.consoleError(SOURCE, "WTF: NO ROUTE");
                reject("NO ROUTE");
            }
        });
    }


    confirmAndDeleteRoutes(routeIds: string[]): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobals.confirmAndDeleteRoutes";

        return new Promise<boolean>(function (resolve, reject) {
            if (routeIds) {
                routeIds = [...new Set(routeIds)];
                let count = routeIds.length;
                let targetName = count == 1 ? "Route" : "Routes";
                let title = `Delete: ${count} ${targetName}`;
                let body = (count == 1) ? "Are you sure you want to delete this route? " : "Are you sure you want to delete these routes? ";
                self.showConfirmDialog(title, body)
                    .then(onConfirm => {
                        if (onConfirm) {
                            self.deleteRoutes(routeIds)
                                .then(res => {
                                    let isCommitted = res.length == count;
                                    if (isCommitted) {
                                        self.showSnack(`${count} ${targetName} deleted`, "success");
                                    } else {
                                        self.showSnack(`Not all routes could be deleted due to conflict.`, "warning");
                                    }
                                    resolve(isCommitted);
                                })
                                .catch(err => {
                                    reject(err);
                                    //RwLog.error(SOURCE, `Unhandled: ${err}`);
                                    self.showSnack(`Delete Failed. Please try again later`, "error");
                                });
                        } else {
                            resolve(false);
                        }
                    })

            } else {
                reject(new Error("routeIds == null"));
            }
        });
    }


    deleteRoutes(routeIds: string[]): Promise<string[]> {
        let self = this;

        return new Promise<string[]>(function (resolve, reject) {

            RwTaskRoutes
                .deleteRoutes(routeIds)
                .then(result => {

                    self.mergeDeltas(result);
                    let commits = result.findCommits(RwEntityTypes.Route, RwTxnTypes.DELETE);
                    let commitIds = commits.map(c => c.eid);
                    resolve(commitIds);

                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    confirmAndArchiveRoute(routeUpdate: RwRoute) {
        const self = this;
        const SOURCE = "RwGlobals.onRouteArchive";
        this.selectedRoute = routeUpdate;
        //console.log(SOURCE, route.name);

        return new Promise<boolean>(function (resolve, reject) {
            if (routeUpdate) {
                self
                    .showConfirmDialog(`Archive Route: ${routeUpdate.name}`, `Are you sure you want to archive this route?`)
                    .then(isConfirmed => {
                        //console.log(SOURCE, "showConfirmDialog isConfirmed", isConfirmed);
                        if (isConfirmed) {
                            let ids = [routeUpdate.routeId];
                            self.archiveRoutes(ids)
                                .then((isArchived) => {
                                    if (isArchived) {

                                        if (self.isDisp) {
                                            let isSelfy = routeUpdate.driverId === self.userId;
                                            if (!isSelfy) {
                                                self.pushUpdateToDriver(routeUpdate)
                                            }
                                        }

                                        self.showSnack("Route Archived", "success");
                                    } else {
                                        self.showSnack("Route Archive Failed.  Please try again later.", "error");
                                    }
                                    resolve(true);
                                })
                        }
                    })
                    .catch(err => {
                        RwLog.consoleError(SOURCE, "Unhandled err: ", err.toString());
                    });
            } else {
                RwLog.consoleError(SOURCE, "WTF: NO ROUTE");
                reject("NO ROUTE");
            }
        });

    }

    copyRouteDB(route: RwRoute): Promise<boolean> {
        let self = this;
        let SOURCE = "RwGlobals.copyRouteDB";
        return new Promise<boolean>(function (resolve, reject) {
            // self.show

        });
    }


    confirmAndChangeRouteStatus(route: RwRoute): Promise<boolean> {
        const self = this;
        let SOURCE = "RwGlobals.confirmAndChangeRouteStatus";

        return new Promise<boolean>(function (resolve, reject) {
            self.showStatusPicker(route.name, route.statusCode)
                .then(status => {
                    route.statusCode = status;
                    self.updateRouteDB(route, false, false, false,)
                        .then(() => {
                            self.showSnack("Status updated", "success")
                        })
                        .catch(err => {
                            SOURCE += ": updateRouteDB";
                            RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
                            this.globals.showSnack("Failed to Update Status", "error");
                        });
                    resolve(true);
                })
                .catch(err => {
                    SOURCE += ": showStatusPicker";
                    RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
                    reject(false);
                })
        });
    }

    confirmAndArchiveRoutes(routeIds: string[]): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobals.confirmAndArchiveRoutes";

        return new Promise<boolean>(function (resolve, reject) {
            if (routeIds) {
                routeIds = [...new Set(routeIds)];
                let count = routeIds.length;
                let targetName = count == 1 ? "Route" : "Routes";
                let title = `Archive: ${count} ${targetName}`;
                let body = (count == 1) ? "Are you sure you want to archive this route? " : "Are you sure you want to archive these routes? ";
                self.showConfirmDialog(title, body)
                    .then(onConfirm => {
                        if (onConfirm) {
                            self.archiveRoutes(routeIds)
                                .then((isArchived) => {
                                    if (isArchived) {
                                        self.showSnack(`${count} ${targetName} archived`, "success");
                                    } else {
                                        self.showSnack(`Route Archive Failed.  Please try again later.`, "error");
                                    }
                                    resolve(isArchived);
                                })
                        } else {
                            resolve(false);
                        }
                    })

            } else {
                reject(new Error("routeIds == null"));
            }
        });
    }


    archiveRoutes(routeIds: string[]): Promise<boolean> {
        let self = this;
        return new Promise<boolean>(function (resolve) {

            RwTaskRoutes.archiveRoutes(routeIds)
                .then(() => {
                    routeIds.forEach(id => {
                        self.routes.splice(self.routes.indexOf(self.findRoute(id)), 1);
                    });
                    //console.warn("archiveRoutes commitRoutes");
                    self.commitRoutes();
                    resolve(true);
                })
                .catch(err => {
                    resolve(false);
                })
        });
    }


    mergeRoute(routeIds: string[]): Promise<string> {
        let self = this;

        return new Promise<string>(function (resolve, reject) {
            if (routeIds.length > 0) {
                self.showConfirmDialog("Merge " + routeIds.length.toString() + " Routes", 'Create a new route with all non-checked in stops.')
                    .then(isConfirmed => {
                        if (isConfirmed) {
                            RwTaskRoutes.mergeRoutes(routeIds)
                                .then(stops => {
                                    let newRT = new RwRoute();
                                    newRT.accountId = self.accountId;
                                    newRT.routeId = stops[0].routeId;
                                    newRT.stops = stops;
                                    newRT.isDynLoc = true;
                                    newRT.isHardStart = false;
                                    newRT.name = "Merged Route " + new Date().toISOString().substr(0, 10);
                                    newRT.address = "";
                                    newRT.lat = 0;
                                    newRT.lng = 0;
                                    newRT.driverId = self.dispatcher.userId;
                                    newRT.note = "";
                                    newRT.travelMode = "driving";
                                    RwTaskRoutes.addRoute(newRT, true)
                                        .then(() => {
                                            self.routes.push(newRT);
                                            self.showSnack("Routes Merged Successfully", "success");
                                        })
                                        .catch(() => {
                                            self.showSnack("Failed to add merged route.", "error");
                                        })

                                })
                                .catch(() => {
                                    self.showSnack("Failed to merge routes", "error");
                                })
                        }
                    })

            } else {
                reject(new Error("No route found"))
            }

        })

    }


    //#endregion


    //#region Stop APIs

    multiCopy(srcRoute: RwRoute, stops: RwStop[]): Promise<boolean> {
        const self = this;
        //console.log("globals.multiCopy srcId", srcId);

        return new Promise<boolean>(resolve => {
            let srcId = srcRoute.routeId;
            self
                .showRoutePicker(srcId)
                .then(res => {
                    if (res) {

                        let ids = stops.map(s => s.stopId);
                        let tarId = res.tarRouteId;
                        let isMove = res.isMoveOp;
                        //console.log("RwGlobals.multiCopy callback srcId, tarId, isMove", srcId, tarId, isMove);

                        this
                            .multiStopCopyDB(ids, srcId, tarId, isMove)
                            .then(isCommitted => {
                                if (isCommitted) {
                                    self.showSnack(`Stops ${isMove ? "Moved" : "Copied"} Successfully`, "success");
                                    stops.forEach(stop => {
                                        if (isMove) {
                                            //stop.routeId = tarId;
                                            //stop.lastHash = Date.now();
                                            srcRoute.removeStop(stop);
                                        }
                                        //stop.lastHash = Date.now();
                                        //console.log("RwGlobals.multiSked stop", stop.name, stop.priority, stop.openTime, stop.closeTime);
                                    });

                                    self.commitRoute();
                                    resolve(true);

                                    let tarRoute = self.findRoute(tarId);
                                    if (tarRoute) {
                                        if (res.isNew) {
                                            tarRoute.lat = srcRoute.lat;
                                            tarRoute.lng = srcRoute.lng;
                                            self.updateRouteDB(tarRoute, true)
                                                .then(commited => {
                                                    if (commited) self.plan.openRouteInPlanner(tarRoute)
                                                });
                                        } else {
                                            self.plan.openRouteInPlanner(tarRoute);
                                        }
                                    }
                                } else {
                                    resolve(false);
                                    self.showSnack("Move/Copy Update failed", "error");
                                }
                            })
                            .catch(err => {
                                resolve(false);
                                self.showSnack("Move/Copy Update failed", "error");
                            });
                    }
                });


        });


    }


    multiSked(isTasks: boolean, stops: RwStop[]): Promise<boolean> {
        const self = this;

        //Set initVals if all all stops have value in common.
        let firstStop = stops[0];
        let firstPri = firstStop.priority;
        let firstStart = firstStop.openTime;
        let firstFinish = firstStop.closeTime;
        let samePri = stops.every(s => s.priority === firstPri);
        let sameStart = stops.every(s => s.openTime === firstStart);
        let sameFinish = stops.every(s => s.closeTime === firstFinish);
        let defPri = samePri ? firstPri : undefined;
        let defStart = sameStart ? firstStart : undefined;
        let defFinish = sameFinish ? firstFinish : undefined;

        if (stops.length === 1 && defPri && !defStart && !defStart) {
            defPri = undefined;
        }

        return new Promise<boolean>(resolve => {

            let ids = stops.map(s => s.stopId);

            self
                .showSkedPicker(defPri, defStart * 60000, defFinish * 60000)
                .then(skedPick => {
                    if (skedPick) {
                        //console.log("RwGlobals.multiSked skedPick", skedPick.priority, skedPick.timeStart, skedPick.timeFinish);
                        let openMins = skedPick.timeStart / 60000;
                        let closeMins = skedPick.timeFinish / 60000;
                        let skedId = skedPick.skedId;
                        //console.log("RwGlobals.multiSked skedPick", skedPick.priority, openMins, closeMins);

                        this
                            .multiSkedDB(isTasks, ids, skedPick.skedId, skedPick.priority, openMins, closeMins)
                            .then(isCommitted => {
                                if (isCommitted) {
                                    self.showSnack("Scheduled Time Updated", "success");
                                    stops.forEach(stop => {
                                        stop.priority = skedPick.priority;
                                        stop.openTime = openMins;
                                        stop.closeTime = closeMins;
                                        stop.skedId = skedId ? skedId : undefined;
                                        stop.lastHash = Date.now();
                                        //console.log("RwGlobals.multiSked stop", stop.name, stop.priority, stop.openTime, stop.closeTime);
                                    });
                                    //self.commitRoute();
                                    resolve(true);
                                } else {
                                    resolve(false);
                                    self.showSnack("Schedule Update Failed", "error");
                                }
                            })
                            .catch(err => {
                                resolve(false);
                                self.showSnack("Schedule Update Failed", "error");
                            });
                    }
                });


        });


    }


    multiColor(ntt: RwEntityTypes, ids: string[], colorHash: string): Promise<boolean> {
        ids = ids.filter(id => !!id);
        return new Promise<boolean>(resolve => {
            RwTaskStops
                .multiColor(ntt, ids, colorHash)
                .then(isCommitted => {
                    if (ntt === RwEntityTypes.Stop) {
                        this.lastRouteDeltaMS = Date.now();
                        //console.log("multiColor", self.lastDelta);
                    }

                    resolve(isCommitted);
                })
                .catch(err => {
                    resolve(false);
                });
        });
    }


    multiVisit(ntt: RwEntityTypes, ids: string[], visitTime: number): Promise<boolean> {
        ids = ids.filter(id => !!id);
        return new Promise<boolean>(resolve => {
            RwTaskStops
                .multiVisit(ntt, ids, visitTime)
                .then(isCommitted => {

                    if (ntt === RwEntityTypes.Stop) {
                        this.lastRouteDeltaMS = Date.now();
                        //console.log("multiVisit", self.lastDelta);
                    }

                    resolve(isCommitted);
                })
                .catch(err => {
                    resolve(false);
                });
        });
    }


    multiSkedDB(isTask: boolean, ids: string[], skedId: string, priority: number, openTime: number, closeTime: number): Promise<boolean> {
        const self = this;
        if ((!isNaN(openTime)) && !isNaN(closeTime)) {
            return new Promise<boolean>(resolve => {
                RwTaskStops
                    .multiSked(ids, skedId, priority, openTime, closeTime)
                    .then(isCommitted => {

                        if (!isTask) {
                            self.lastRouteDeltaMS = Date.now();
                            //console.log("multiSkedDB", self.lastDelta);
                        }

                        resolve(isCommitted);
                    })
                    .catch(err => {
                        resolve(false);
                    });
            });
        } else {
            self.showSnack("Please confirm that schedule contains valid start and end times and try again.", "warning");
        }

    }


    multiStopCopyDB(ids: string[], srcId: string, tarId: string, isMove?: boolean): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            RwTaskStops
                .multiCopy(ids, srcId, tarId, !!isMove)
                .then(isCommitted => {
                    resolve(isCommitted);
                })
                .catch(err => {
                    resolve(false);
                });
        });
    }

    getTotalStopCount(route: RwRoute): Promise<RwTotalStopCount> {
        return new Promise<RwTotalStopCount>(function (resolve, reject) {
            RwTaskRoutes.countRouteStops(route)
                .then(stopCount => {
                    resolve(stopCount);
                })
                .catch(err => {
                    resolve(err);
                });
        })
    }

    addStopDB(stop: RwStop, routeUpdate: RwRoute, setFocus: boolean, setSelect: boolean): Promise<boolean> {
        let self = this;
        //console.log("addStopDB", stop.name, setFocus, setSelect);

        return new Promise<boolean>(function (resolve, reject) {

            routeUpdate.canAddStop(stop.address, 1)
                .then(canAdd => {
                    if (canAdd) {
                        //console.log("addStopDB canAdd", stop.name);

                        if (self.showProofOfDelivery) {
                            const defaultPOD = RwPrefUtils.proofOfDelivery;
                            const stopAddons: RwStopAddOns = {
                                podPhoto: defaultPOD === PODDefaultSettings.Photo ||
                                    defaultPOD === PODDefaultSettings.PhotoAndSignature,
                                podSig: defaultPOD === PODDefaultSettings.Signature ||
                                    defaultPOD === PODDefaultSettings.PhotoAndSignature,
                            }
                            stop.addOns = stopAddons;
                        }

                        RwTaskStops
                            .addStop(stop, routeUpdate)
                            .then(deltas => {
                                //console.log("addStopDB RwTaskStops.addStop callback", deltas);

                                if (deltas.stops?.length > 0) {
                                    const addedStopUpdateIndex = deltas.stops.findIndex(s => s.stopId === stop.stopId);
                                    if (addedStopUpdateIndex !== -1) {
                                        const addedStopUpdate = deltas.stops[addedStopUpdateIndex];
                                        stop.addOns = addedStopUpdate.addOns;
                                        deltas.stops.splice(addedStopUpdateIndex, 1);
                                    }
                                }
                                if (!stop.isTask) {
                                    self.lastRouteDeltaMS = Date.now();
                                }
                                //console.log("addStopDB lastDeltaMS", self.lastDelta);

                                self.mergeDeltas(deltas);

                                let isCommitted = deltas.isCommitted(stop.stopId, RwTxnTypes.POST);
                                if (isCommitted) {

                                    self.targetFocusId = setFocus ? stop.stopId : "";
                                    self.targetSelectId = setSelect ? stop.stopId : "";
                                    //console.error(`addStopDB targetFocusId:${self.targetFocusId}, targetSelectId:${self.targetSelectId}`)

                                    routeUpdate.addStop(stop);
                                    self.commitStops();

                                    if (RwPrefUtils.editAfterAdd) {
                                        setTimeout(() => {
                                            self.selectedStop = stop;
                                            self.recentStopAdd = true;
                                            self.showStopEdit(stop).catch();
                                        }, 750);
                                    }

                                    resolve(true);

                                } else if (deltas.gotConflicts) {
                                    reject(new Error("Stop not added due to conflict, please try again."));
                                } else {
                                    reject(new Error("Error adding stop"));
                                }
                            })
                            .catch(err => {
                                reject(err);
                            });
                    } else {
                        resolve(false);
                    }
                });
        });
    }


    addStopsToRoute(stops: RwStop[], routeUpdate: RwRoute): Promise<boolean> {
        let self = this;
        //console.log("addStopsToRoute", stops.length);

        return new Promise<boolean>(resolve => {
            routeUpdate.canAddStop(null, stops.length)
                .then(canAdd => {
                    if (canAdd) {

                        RwTaskStops
                            .addStops(stops, routeUpdate)
                            .then(isCommitted => {
                                if (isCommitted) {

                                    self.lastRouteDeltaMS = Date.now();
                                    //console.log("addStopsToRoute lastDeltaMS", self.lastDelta);

                                    stops.forEach(stop => {
                                        routeUpdate.addStop(stop);
                                    });
                                    self.commitStops();
                                }
                                resolve(isCommitted);
                            })
                            .catch(err => {
                                resolve(false);
                            });


                    } else {
                        resolve(false);
                        self.showSnack("Over route size limit", "warning");
                    }
                });

        });

    }


    confirmAndDeleteStop(stop: RwStop, stack: string[] = [], skipCommit = false): Promise<boolean> {
        const SOURCE = "RwGlobals.confirmAndDeleteStop";
        const self = this;
        return new Promise<boolean>(function (resolve, reject) {

            if (stop) {

                self
                    .showConfirmDialog(`Delete Stop: ${stop.name}`, `Are you sure you want to delete this stop?`)
                    .then(isConfirmed => {
                        if (isConfirmed) {

                            self.deleteStops([stop], [...stack, SOURCE], skipCommit)
                                .then(res => {
                                    self.showSnack("Stop Deleted", "success");
                                    resolve(true);
                                })
                                .catch(err => {
                                    self.showSnack(`Stop Delete Failed: ${err}`, "error");
                                    let stopJson = JSON.stringify(stop.toJSON());
                                    let rwErr = err as RwError;
                                    if (self.checkNotHandled(err)) {
                                        RwLog.error(SOURCE, `deleteStops: skipCommit:${skipCommit} \nstop:\n${stopJson} \n\nerr:\n${err}\n\nStack:\n${JSON.stringify(stack)}`);
                                    } else {
                                        RwLog.warn(SOURCE, `deleteStops: skipCommit:${skipCommit} \nstop:\n${stopJson} \n\nerr:\n${err}\n\nStack:\n${JSON.stringify(stack)}`);
                                    }
                                    // const errorData = RwLog.getErrorFormatted(err, {title: "Stop", data: stop}, {title: "Skip Commit", data: skipCommit});
                                    // RwLog.error(SOURCE, errorData);
                                    reject(err);
                                });
                        } else {
                            resolve(false)
                        }

                    });

            } else {
                RwLog.consoleError("WTF: NO STOP");
                reject("NO STOP");
            }
        });
    }


    confirmAndDeleteStops(stops: RwStop[], stack: string[] = [], skipCommit = true): Promise<string[]> {
        const self = this;
        const SOURCE = "RwGlobal.confirmAndDeleteStops";

        //console.log(SOURCE, stops.length);

        return new Promise<string[]>(function (resolve, reject) {
            stops = stops.filter(s => s !== undefined && s !== null);
            let count = stops.length;
            if (stops && count > 0) {
                let targetName = count == 1 ? 'stop' : 'stops';
                self.showConfirmDialog(`Delete Stops`, `Are you sure you want to delete ${count} ${targetName}?`)
                    .then(res => {
                        if (res) {
                            self
                                .deleteStops(stops, [...stack, SOURCE], skipCommit)
                                .then(res => {

                                    if (res.length == stops.length) {
                                        self.showSnack(`${count} ${targetName} deleted`, "success");
                                        //self.onMultiExit();
                                    } else {
                                        self.showSnack(`Not all stops could be deleted due to conflict.`, "warning");
                                    }
                                    resolve(res);
                                })
                                .catch(err => {
                                    if (self.checkNotHandled(err)) {
                                        RwLog.error(SOURCE, `deleteStops: skipCommit:${skipCommit}, count:${count} \nstops:\n${stops}  \n\nerr:\n${JSON.stringify(err)}\n\nStack:\n${JSON.stringify(stack)}`);
                                        // const errorData = RwLog.getErrorFormatted(err, {title:"Stops", data: stops}, {title: "Skip Commit", data: skipCommit});
                                        // RwLog.error(SOURCE, errorData);
                                    }
                                    self.showSnack(`Delete Failed. Please try again later`, "error");
                                    reject(err);
                                });
                        }
                    });
            } else {
                reject("no stops to delete");
            }
        });
    }


    deleteStops(stops: RwStop[], stack: string[] = [], skipCommit = false): Promise<string[]> {
        const SOURCE = "RwGlobals.deleteStops";
        let self = this;
        let route = self.activeRoute;

        return new Promise<string[]>(function (resolve, reject) {
            let stopIds = stops.filter(s => s !== undefined && s !== null).map(s => s.stopId);
            stopIds = [...new Set(stopIds)];

            //console.log("stopIds", stopIds)
            if (stopIds && stopIds.length > 0) {
                if (stopIds.length !== stops.length) {
                    //const stack = new Error().stack;
                    RwLog.error(SOURCE, `Stops.Count != StopIds.Count \n\nstops:\n${JSON.stringify(stops)} \n\nstopIds:${JSON.stringify(stopIds)} \n\nstack:\n${JSON.stringify(stack)}`);
                }

                let gotAnyRouted = stops.find(s => s.isRouted);

                let gotAnyStops = stops.find(s => !s.isTask);

                RwTaskStops
                    .deleteStops(stopIds)
                    .then(deltas => {

                        if (gotAnyStops) {
                            self.lastRouteDeltaMS = Date.now();
                        }
                        // self.mergeDeltas(deltas);
                        //
                        // let commits = deltas.findCommits(RwEntityTypes.Stop, RwTxnTypes.DELETE);
                        // let commitIds = commits.map(c => c.eid);

                        stopIds.forEach(sid => {
                            let stop = route.findStop(sid);
                            if (stop) {
                                route.removeStop(stop);
                                stop.lastHash = Date.now();
                            } else {
                                //NOTE: this is normal as is should be removed by mergeDeltas.
                            }
                        });

                        if (gotAnyRouted) {
                            route.path = null;
                        }

                        if (!skipCommit) {
                            self.commitStops();
                        }

                        resolve(stopIds);
                        ///resolve(commitIds);

                    })
                    .catch(err => {
                        reject(err);
                    });
            } else {
                reject();
            }
        });

    }


    updateStopDB(stop: RwStop, commit = false): Promise<boolean> {
        const self = this;

        return new Promise<boolean>(function (resolve, reject) {
            RwTaskStops
                .updateStop(stop)
                .then(deltas => {

                    if (!stop.isTask) {
                        self.lastRouteDeltaMS = Date.now();
                    }
                    //console.log("updateStopDB", self.lastDelta);

                    self.mergeDeltas(deltas);

                    let isCommitted = deltas.isCommitted(stop.stopId, RwTxnTypes.PUT);
                    //console.log('RwGlobals.updateStopDB isCommitted', isCommitted);
                    if (isCommitted) {

                        if (!stop.isTask) {
                            self.lastRouteDeltaMS = Date.now();
                        }
                        //console.log("updateStopDB", self.lastDelta);

                        stop.lastHash = Date.now();
                        if (commit) {
                            self.commitStops();
                        }
                        resolve(true);
                    } else if (deltas.gotConflicts) {
                        reject(new Error("Stop not updated due to conflict, please try again."));
                    } else {
                        reject(new Error("Error updating stop"));
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    checkInStopDB(stop: RwStop, histId: string, siteId?: string): Promise<boolean> {
        const self = this;
        if (stop.address) {
            return new Promise<boolean>(function (resolve) {
                RwTaskStops
                    .checkInStop(stop, histId, siteId)
                    .then((isChecked) => {

                        if (!stop.isTask) {
                            self.lastRouteDeltaMS = Date.now();
                        }
                        //console.log("checkInStopDB", self.lastDelta);

                        resolve(isChecked)
                    })
                    .catch(() => resolve(false));
            });
        } else {
            // console.warn ("Check-In skipped, stop details: ", stop);
            RwLog.error("RwGlobals.checkInStopDB", `Check-in called for Stop with missing address: ` + stop + 'for user: ' + this.userId);
        }
    }


    removeStopById(stopId: string) {
        if (stopId) {
            let stop = this.findStop(stopId);
            if (stop) {
                this.removeStop(stop);
            }
        }
    }


    removeStop(stop: RwStop) {
        const SOURCE = "RwGlobals.removeStop";
        if (stop) {
            let index = this.stops.indexOf(stop, 0);
            if (index > -1) {
                this.stops.splice(index, 1);
            } else {
                RwLog.error(SOURCE, "WTF: stop not found: " + stop.name);
            }
        } else {
            RwLog.error(SOURCE, "stop == null");
        }
    }


    commitStops() {
        //console.log("RwGlobals.commitStops");
        if (this.activeRoute && this.activeRoute.stops) {
            let newStops: RwStop[] = [...this.activeRoute.stops];
            //console.log("RwGlobals.commitStops newStops", newStops.length);
            this.activeRoute.stops = newStops;
        }
    }


    commitRoute() {
        //console.log("commitRoute");
        let trigger = this.refreshRouteTrigger + 1;
        this.refreshRouteTrigger = trigger;
    }


    commitRoutes() {
        const clone = Object.assign([], this.routes);
        this.routes = clone;
    }


    //#endregion


    //#region Site APIs

    isLoadedSites = false;

    get sites(): RwSite[] {
        return theStore.getters.sites;
    }

    set sites(sites: RwSite[]) {
        const self = this;
        //console.log("SET sites", `isLoadedSites:${this.isLoadedSites}, sites.Count:${sites.length}`);

        //xTEST: Call this always
        this.refreshSitesImages();

        //HACK: This should called after refreshImages is complete callback
        setTimeout(() => {
            theStore.dispatch("sites", sites).catch();
        }, 5);


        // if (this.isLoadedSites) {
        //   self.isLoadedSites = true;
        //   this.refreshSitesImages();
        //   setTimeout(() => {
        //     theStore.dispatch("sites", sites).catch();
        //   }, 5);
        // }
        // else {
        //   //PRIOR
        //   theStore.dispatch("sites", sites).catch();
        // }
    }

    refreshSitesImages() {
        const self = this;
        const SOURCE = "RwPlannerMap.refreshSites"

        if (RwPrefUtils.showDataLayerSites) {

            // this.clearSitePins();
            let pins = this.plan.sitePinsFiltered;

            let pinsLookup: Record<string, RwPin[]> = {}
            let argsLookup: Record<string, RwPinArgs> = {}

            //Add Sites
            if (pins) {
                pins.forEach(sitePin => {
                    if (sitePin) {
                        let args = sitePin.imgArgs;
                        let hash = RwPin.getPinHash(args)
                        let list = pinsLookup[hash];
                        if (!list) {
                            list = [];
                            pinsLookup[hash] = list;
                            argsLookup[hash] = args;
                        }
                        list.push(sitePin);
                    }
                })

                let lenKeys = Object.keys(pinsLookup).length
                if (lenKeys > 0) {
                    for (let hash in pinsLookup) {
                        let pins = pinsLookup[hash];
                        let args = argsLookup[hash];

                        //TODO: Consider Singleton Promise vs. timeout
                        setTimeout(() => {
                            let cachedUrl = self.idxURL[hash];
                            if (!cachedUrl) {

                                RwPin
                                    .calcSitePinUrlNew(hash, args)
                                    .then(dataUrl => {
                                        //console.log("refreshSitesNew addSitePins", hash)
                                    });
                            }
                        }, 2)


                    }
                }
            }
        }
    }


    addSiteLocal(site: RwSite) {
        if (site) {
            this.sites.push(site);
            //RwApp.model.updateBadges();
        } else {
            RwLog.consoleError("WTF: site == null");
        }
    }


    removeSiteById(siteId: string) {
        const SOURCE = "RwGlobals.removeSiteById";
        if (siteId) {
            let site = this.sites.find(s => s.siteId === siteId);
            if (site) {
                this.removeSite(site);
            } else {
                RwLog.consoleError(SOURCE, "WTF: not found by siteId", siteId);
            }
        } else {
            RwLog.consoleError(SOURCE, "WTF: siteId undefined");
        }
    }


    removeSite(site: RwSite) {
        const SOURCE = "RwGlobals.removeSite";
        if (site != null) {
            let index = this.sites.indexOf(site, 0);
            if (index > -1) {
                this.sites.splice(index, 1);
            }
        } else {
            RwLog.consoleError(SOURCE, "WTF: site notFound");
        }
    }


    addSite(site: RwSite, setFocus?: boolean): Promise<boolean> {
        const SOURCE = "RwGlobals.addsite";
        let self = this;
        return new Promise<boolean>(function (resolve, reject) {

            self.checkSiteAddConflict(site.address)
                .then(isOK => {
                    if (isOK) {
                        RwTaskSites
                            .addSite(site)
                            .then(deltas => {

                                self.mergeDeltas(deltas);

                                let isCommitted = deltas.isCommitted(site.siteId, RwTxnTypes.POST);
                                if (isCommitted) {

                                    self.targetFocusId = (setFocus) ? site.siteId : "";
                                    //if(!setFocus) console.warn("addSiteDb focusId cleared");
                                    self.addSiteLocal(site);
                                    self.commitSites();

                                    if (RwPrefUtils.editAfterAdd) {
                                        setTimeout(() => {

                                            self.showSiteEdit(site)

                                            // self.selectedSite = site;
                                            // self.showSiteEdit = true;
                                        }, 750);
                                    }

                                    resolve(isCommitted);
                                } else if (deltas.gotConflicts) {
                                    RwLog.warn(SOURCE, `Error adding favorite, conflict. deltas: ${JSON.stringify(deltas)}`)
                                    reject(new Error("Favorite not added due to conflict, please try again."));
                                } else {
                                    RwLog.error(SOURCE, `Error adding favorite, deltas: ${JSON.stringify(deltas)}`)
                                    reject(new Error("Error adding favorite"));
                                }
                            })
                            .catch(err => {
                                reject(err);
                            });
                    } else {
                        resolve(false);
                    }
                });

        });
    }


    checkSiteAddConflict(address: string): Promise<boolean> {
        const self = this;
        return new Promise<boolean>(function (resolve) {
            let addExists = address && self.sites.find(s => s.address === address);
            if (addExists) {

                let title = "Add Favorite";
                let body = "Address already exists as a Favorite.  Do you want to add it anyway?";
                self
                    .showConfirmDialog(title, body)
                    .then(isConfirmed => {
                        resolve(isConfirmed)
                    });
            } else {
                resolve(true);
            }
        });
    }


    updateSiteDB(site: RwSite, stack: string[] = []): Promise<RwSite> {
        const self = this;
        const SOURCE = "RwGlobals.updateSiteDB";
        //console.log("RwGlobals.updateSiteDB site", site.name);
        if ((site.baseColor as any).hex) site.baseColor = (site.baseColor as any).hex;
        return new Promise<RwSite>(function (resolve, reject) {
            RwTaskSites
                .updateSite(site)
                .then(deltas => {
                    //console.log("RwGlobals.updateSiteDB updateSite callback", deltas);
                    self.mergeDeltas(deltas);

                    let isCommitted = deltas.isCommitted(site.siteId, RwTxnTypes.PUT);
                    if (isCommitted) {
                        resolve(site);
                    } else if (deltas.gotConflicts) {
                        reject(new Error("Favorite not updated due to conflict, please try again."));
                    } else {
                        reject(new Error("Error adding favorite"));
                    }
                })
                .catch(err => {
                    if (self.checkNotHandled(err)) {
                        try {
                            let siteData;
                            try {
                                siteData = JSON.stringify(site.toJSON());
                            } catch (e) {
                                RwLog.error(SOURCE, `Site data to JSON error: ${JSON.stringify(err)}\n\nStack:\n${JSON.stringify(stack)}`);
                                siteData = `${site.siteId} - ${site.name}`;
                            }
                            RwLog.error(SOURCE, `Unhandled: ${JSON.stringify(err)} -
                ${siteData}\n\nStack:\n${JSON.stringify(stack)}`);
                        } catch (e) {
                            RwLog.error(SOURCE, `Unhandled: ${JSON.stringify(err)}\n\nStack:\n${JSON.stringify(stack)}`);
                        }
                    }
                    reject(err);
                });
        });
    }


    confirmAndDeleteSite(site: RwSite): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobals.confirmAndDeleteSite";
        //console.log(SOURCE);

        return new Promise<boolean>(function (resolve, reject) {

            self
                .showConfirmDialog(`Delete Favorite: ${site.name}`, `Are you sure you want to delete this favorite?`)
                .then(isConfirmed => {
                    //console.log(SOURCE, "showConfirmDialog callback", isConfirmed);
                    if (isConfirmed) {
                        let siteId = site.siteId;
                        let siteIds = [siteId];

                        self
                            .deleteSitesDB(siteIds)
                            .then(() => {
                                //console.log(SOURCE, "deleteSitesDB callback");
                                // let deletedId = res.find(delId => delId === siteId);
                                // resolve(!!deletedId);
                                self.showSnack(`Favorite deleted`, "success");
                            })
                            .catch(err => {
                                if (err) {
                                    if (err && err.isHandled) {
                                        RwLog.warn(SOURCE, `Unhandled: ${err}`);
                                    } else {
                                        RwLog.error(SOURCE, `Unhandled: ${err}`);
                                    }
                                } else {
                                    RwLog.error(SOURCE, `Missing Error`);
                                }
                                self.showSnack(`Delete Failed. Please try again later`, "error");
                                reject(err);
                            });

                    } else {
                        resolve(false);
                    }
                });

        });
    }


    confirmAndDeleteSites(siteIds: string[]): Promise<string[]> {
        const self = this;
        const SOURCE = "RwGlobal.confirmAndDeleteSites";
        //console.warn(SOURCE, siteIds.length);

        return new Promise<string[]>(function (resolve, reject) {
            if (siteIds && siteIds.length > 0) {
                siteIds = [...new Set(siteIds)];
                siteIds = siteIds.filter(s => s !== null);
                let count = siteIds.length;
                let targetPlural = count == 1 ? 'favorite' : 'favorites';
                self
                    .showConfirmDialog(`Delete Favorites`, `Are you sure you want to delete ${count} ${targetPlural}?`)
                    .then(doIt => {
                        //console.log(SOURCE, "showConfirmDialog callback", doIt);
                        if (doIt) {
                            self
                                .deleteSitesDB(siteIds)
                                .then(res => {
                                    if (res.length == siteIds.length) {
                                        resolve(res)
                                        self.showSnack(`${count} ${targetPlural} deleted`, "success");
                                    } else {
                                        self.showSnack(`Not all favorites could be deleted due to conflict.`, "warning");
                                    }

                                })
                                .catch(err => {
                                    if (self.checkNotHandled(err)) {
                                        RwLog.error(SOURCE, `Unhandled: ${err}`);
                                    }
                                    self.showSnack(`Delete Failed. Please try again later`, "error");
                                });
                        }
                    });
            } else {
                reject("no sites to delete");
            }
        });
    }

    onSiteRelocate(site: RwSite, stack: string[] = []): Promise<string> {
        const self = this;
        const SOURCE = "RwGlobals.onSiteRelocate";
        return new Promise<string>(function (resolve, reject) {
            if (site) {
                //console.log("onSiteRelocate lat, lng", site.lat, site.lng);

                self.showReloPicker(site.name, site.lat, site.lng)
                    .then(coord => {
                        if (coord) {
                            //console.log(SOURCE, "showReloPicker callback", coord.lat, coord.lng);
                            let priLat = site.lat;
                            let priLng = site.lng;

                            site.lat = coord.lat;
                            site.lng = coord.lng;

                            self.updateSiteDB(site, [...stack, SOURCE])
                                .then(isCommit => {
                                    //console.log("updateSiteDB isCommit", isCommit);
                                    if (isCommit) {
                                        //self.globals.showSnack("Favorite location updated.", "success");
                                        site.lastHash = Date.now();
                                        self.targetFocusId = site.siteId;
                                        self.commitSites();
                                        resolve("success");
                                    } else {
                                        //rollback update
                                        site.lat = priLat;
                                        site.lng = priLng;
                                        self.showSnack("Favorite relocation failed.", "error");
                                    }
                                })
                                .catch(err => {
                                    //rollback update
                                    site.lat = priLat;
                                    site.lng = priLng;
                                    RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
                                    self.showSnack("Favorite relocation failed.", "error");
                                });

                        }
                    });
            } else {
                reject("No Site");
            }
        });

    }

    copyRoutePicker(route: RwRoute): Promise<string> {
        let SOURCE = "RwGlobals.copyRoutePicker";
        let self = this;
        return new Promise<string>(function (resolve, reject) {
            self.showRouteCopy = true;

            self.showRouteEdit(route)
                .then(() => {
                    resolve("completed")
                })

            // self.showRouteNew(false, route)
            //   .then(() => {
            //     resolve("completed")
            //   })
        });
    }

    deleteSitesDB(siteIds: string[]): Promise<string[]> {
        const self = this;
        return new Promise<string[]>(function (resolve, reject) {

            if (siteIds) {
                RwTaskSites
                    .deleteSites(siteIds)
                    .then(deltas => {
                        self.mergeDeltas(deltas);
                        let commits = deltas.findCommits(RwEntityTypes.Site, RwTxnTypes.DELETE);
                        let commitIds = commits.map(c => c.eid);
                        resolve(commitIds);
                    })
                    .catch(err => {
                        reject(err);
                        self.showSnack("Failed to delete favorites", "error");
                    });

            } else {

                reject("no siteIds");
            }
        });
    }


    commitSites() {
        const cloneSites = Object.assign([], this.sites);
        this.sites = cloneSites;
    }

    colorSite(sites: RwSite[]): Promise<string> {
        let self = this;
        return new Promise<string>((function (resolve, reject) {
            self.showColorPicker()
                .then((color) => {
                    //console.error("colorSite colorPicker .then")
                    if (color) {
                        sites.forEach(site => {
                            site.baseColor = color;
                            if (RwImgUtils.validatedColorHex(color)) {
                                // console.warn("colorHex validated: ", RwColorPicker.validatedColorHex(color) );
                                RwTaskSites.updateSite(site)
                                    .then(() => {
                                        self.showSnack("Color Updated Successfully", "success")
                                        resolve(color);
                                    })
                                    .catch(err => {
                                        reject(err);
                                        self.showSnack("Color Failed to Update", "error")

                                    })
                            } else {
                                self.showSnack("Invalid Color: Please Refresh and Try Again", "error");
                            }

                        })

                    }
                })

        }))

    }

    colorTask(tasks: RwStop[]): Promise<string> {
        let self = this;
        return new Promise<string>((function (resolve, reject) {
            self.showColorPicker()
                .then((color) => {
                    //console.error("colorSite colorPicker .then")
                    if (color) {
                        tasks.forEach(task => {
                            task.baseColor = color;
                            if (RwImgUtils.validatedColorHex(color)) {
                                // console.warn("colorHex validated: ", RwColorPicker.validatedColorHex(color) );
                                RwTaskStops.updateStop(task)
                                    .then(() => {
                                        self.showSnack("Color Updated Successfully", "success")
                                        resolve(color);
                                    })
                                    .catch(err => {
                                        reject(err);
                                        self.showSnack("Color Failed to Update", "error")

                                    })
                            } else {
                                self.showSnack("Invalid Color: Please Refresh and Try Again", "error");
                            }

                        })

                    }
                })

        }))

    }

    siteSvcSet(sites: RwSite[]): Promise<string> {
        let self = this;
        return new Promise<string>(function (resolve, reject) {
            if (sites) {
                sites = [...new Set(sites)];
                self.showVisitPicker()
                    .then(time => {
                        sites.forEach(site => {
                            site.visitTime = time;
                            RwTaskSites.updateSite(site)
                                .then(() => {
                                    resolve("true");
                                    self.showSnack("Visit Time Updated", "success")
                                })
                                .catch(() => {
                                    self.showSnack("Updating Visit Time Failed", "error")
                                })
                        });
                    })
                    .catch(() => {
                        RwLog.consoleError("Visit picker not responding in siteSvcSet")
                    })
            } else {
                reject("Wtf: no Sites to change the time of")
            }

        });
    }


    //#endregion


    //#region Task APIs

    isLoadedTasks = false;

    get tasks(): RwStop[] {
        //console.log("RwGlobals get tasks", theStore.getters.tasks);
        return theStore.getters.tasks;
    }

    set tasks(val: RwStop[]) {
        const self = this;
        //console.log("RwGlobals set tasks", val ? val.length : 0);

        if (this.isLoadedTasks) {
            self.isLoadedTasks = true;

            //this.refreshSitesImages();
            setTimeout(() => {
                theStore.dispatch("tasks", val).catch();
            }, 10 * val.length)


        } else {
            theStore.dispatch("tasks", val).catch();
        }
    }


    get taskPins(): RwPin[] {
        this.checkTaskPins();
        return theStore.getters.taskPins;
    }

    set taskPins(pins: RwPin[]) {
        theStore.dispatch("taskPins", pins).catch();
    }


  addTaskDB(task: RwStop, setFocus?: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const podDefaults = RwPrefUtils.proofOfDelivery;
      if (this.showProofOfDelivery && podDefaults > PODDefaultSettings.None) {
        task.addOns = new RwStopAddOns();
        task.addOns.podPhoto = podDefaults === PODDefaultSettings.Photo || podDefaults === PODDefaultSettings.PhotoAndSignature;
        task.addOns.podSig = podDefaults === PODDefaultSettings.Signature || podDefaults === PODDefaultSettings.PhotoAndSignature;
        task.addOns.stopId = task.stopId;
      }
      RwTaskStops
        .addTasks([task])
        .then(deltas => {
          this.mergeDeltas(deltas);
          let isCommitted = deltas.isCommitted(task.stopId, RwTxnTypes.POST);
          if (isCommitted) {
            this.targetFocusId = (setFocus) ? task.stopId : "";
            this.addTaskLocal(task);
            this.commitTasks();

            if (RwPrefUtils.editAfterAdd) {
              setTimeout(() => {
                this.selectedStop = task;
                this.showStopEdit(task).catch();
                //this.m_showStopEdit = true;
              }, 750);
            }

            resolve(isCommitted);
          } else if (deltas.gotConflicts) {
            reject(new Error("Stop not added due to conflict, please try again."));
          } else {
            reject(new Error("Error adding task"));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

    addTaskToRoute(task: RwStop, routeUpdate: RwRoute, setFocus?: boolean): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobals.addTaskToRoute";
        //console.log("addTaskToRoute");

        return new Promise<boolean>((resolve, reject) => {

            routeUpdate.canAddStop(task.address, 1, routeUpdate)
                .then(canAdd => {
                    //console.log("addTaskToRoute canAddStop callback", canAdd);

                    if (canAdd) {
                        //self.taskToStopDB(task, route)


                        let nextSeq = routeUpdate.nextSeq;
                        self.addTasksToRoute([task], routeUpdate, nextSeq)
                            .then(isAdded => {
                                if (isAdded) {

                                    if (nextSeq) {
                                        task.seq = nextSeq;
                                    }

                                    self.targetFocusId = setFocus ? task.stopId : "";

                                    if (RwPrefUtils.editAfterAdd) {
                                        setTimeout(() => {
                                            self.selectedStop = task;
                                            self.showStopEdit(task).catch();
                                        }, 750);
                                    }
                                }
                                resolve(isAdded);
                            })
                            .catch(err => {
                                self.showSnack("Unexpected error adding task to route");
                                //RwLog.error(SOURCE, `Unhandled: ${err}`);
                                reject(err);
                            })
                    } else {
                        self.showSnack("Over route size limit", "warning");
                    }
                });


        });

    }


    addTasksToRoute(tasks: RwStop[], route: RwRoute, nextSeq: number): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobals.addTasksToRoute";
        //console.log("addTasksToRoute");

        return new Promise<boolean>((resolve, reject) => {
            route.canAddStop(null, tasks.length, route)
                .then(canAdd => {
                    //console.log("addTaskToRoute canAddStop callback", canAdd);
                    if (canAdd) {

                        RwTaskStops
                            .addTasksToRoute(tasks, route.routeId, nextSeq)
                            .then(isCommit => {
                                //console.log("addTasksToRoute success");
                                tasks.forEach(task => {
                                    task.isTask = false;
                                    task.routeId = route.routeId;

                                    if (nextSeq) {
                                        task.seq = nextSeq;
                                        nextSeq++;
                                    }

                                    self.removeTask(task);
                                    route.addStop(task);
                                });

                                self.commitTasks();
                                self.commitStops();
                                resolve(true);
                                this.showSnack("Task(s) added successfully to route " + route.name, "success");
                                //console.log("addTasksToRoute callback Yeah!!")
                            })
                            .catch(err => {
                                self.showSnack("Unexpected error adding task to route");
                                if (this.checkNotHandled(err)) {
                                    RwLog.error(SOURCE, `Unhandled: ${err}`);
                                }
                                reject(err);
                            });

                    } else {
                        resolve(false);
                        self.showSnack("Over route size limit", "warning");
                    }
                });
        });
    }


    confirmAndDeleteTask(task: RwStop): Promise<boolean> {
        const SOURCE = "RwPlannerSpace.confirmAndDeleteTask";
        const self = this;
        return new Promise<boolean>(function (resolve, reject) {
            if (task) {

                self
                    .showConfirmDialog(`Delete Task: ${task.name}`, `Are you sure you want to delete this task?`)
                    .then(isConfirmed => {
                        if (isConfirmed) {

                            let stopId = task.stopId;
                            let stopIds = [stopId];

                            self.deleteTasks(stopIds)
                                .then(res => {
                                    self.showSnack("Task Deleted", "success");
                                    resolve(true);
                                })
                                .catch(err => {
                                    self.showSnack(`Task Delete Failed: ${err}`, "error");
                                    if (self.checkNotHandled(err)) {
                                        RwLog.error(SOURCE, "Unhandled: " + err ? err.toString() : "");
                                    }
                                    reject(err);
                                });
                        } else {
                            resolve(false);
                        }
                    });


            } else {
                RwLog.consoleError("WTF: NO TASK");
                reject("NO TASK");
            }
        });
    }


    confirmAndDeleteTasks(taskIds: string[]): Promise<boolean> {
        const self = this;
        const SOURCE = "RwGlobal.confirmAndDeleteTasks";

        return new Promise<boolean>(function (resolve, reject) {
            if (taskIds && taskIds.length > 0) {
                taskIds = [...new Set(taskIds)];
                taskIds = taskIds.filter(t => t !== null);
                let count = taskIds.length;
                let targetPlural = count == 1 ? 'task' : 'tasks';
                self
                    .showConfirmDialog(`Delete Tasks`, `Are you sure you want to delete ${count} ${targetPlural}?`)
                    .then(onConfirm => {
                        if (onConfirm) {
                            self.deleteTasks(taskIds)
                                .then(res => {
                                    let isCommitted = res.length == count;
                                    if (isCommitted) {
                                        self.showSnack(`${count} ${targetPlural} deleted`, "success");
                                        //self.onMultiExit();
                                    } else {
                                        self.showSnack(`Not all Tasks could be deleted due to conflict.`, "warning");
                                    }
                                    resolve(isCommitted);
                                })
                                .catch(err => {
                                    if (self.checkNotHandled(err)) {
                                        RwLog.error(SOURCE, `Unhandled: ${err}`);
                                    }
                                    self.showSnack(`Delete Failed. Please try again later`, "error");
                                });
                        }
                    });
            } else {
                reject("no sites to delete");
            }
        });
    }


    deleteTasks(taskIds: string[]): Promise<string[]> {
        const SOURCE = "RwGlobals.deleteTasks";
        let self = this;

        return new Promise<string[]>(function (resolve, reject) {
            if (taskIds) {
                taskIds = [...new Set(taskIds)];

                RwTaskStops
                    .deleteTasks(taskIds)
                    .then(deltas => {

                        self.mergeDeltas(deltas);

                        let commits = deltas.findCommits(RwEntityTypes.Task, RwTxnTypes.DELETE);
                        let commitIds = commits.map(c => c.eid);
                        resolve(commitIds);

                    })
                    .catch(err => {
                        reject(err);
                    });
            } else {
                reject();
            }
        });
    }


    removeTask(task: RwStop) {
        const SOURCE = "RwGlobals.removeTask";
        if (task) {
            let index = this.tasks.indexOf(task, 0);
            if (index > -1) {
                this.tasks.splice(index, 1);
            }
        } else {
            RwLog.error(SOURCE, "task == null");
        }
    }


    addTaskLocal(task: RwStop) {
        if (task) {
            let gotTask = this.tasks.find(t => t.stopId === task.stopId);
            //console.log("addTaskLocal gotTask", gotTask, task.name);
            if (!gotTask) {
                this.tasks.push(task);
                //RwApp.model.updateBadges();
            }
        } else {
            RwLog.consoleWarn("task == null");
        }
    }


    removeTaskById(taskId: string) {
        //console.log("removeTaskById", taskId);
        if (taskId) {
            let task = this.tasks.find(t => t.stopId === taskId);
            if (task) {
                this.removeTask(task);
            }
        }
    }


    genTaskPins(): RwPin[] {
        //console.log("RwGlobals.genTaskPins");

        let taskPins: RwPin[] = [];
        let tasks = theStore.getters.tasks;
        if (tasks && tasks.length > 0) {

            //let pins: { [key: string]: RwPin } = {};
            //console.log("getAllPins stops", route.stops.length);
            this.tasks.forEach(task => {
                let pin = RwPin.fromTask(task);
                //console.log("adding task pin", pin.title, pin.id);
                //pins[pin.id] = pin;
                taskPins.push(pin);
            });
        }

        this.taskPins = taskPins;
        return taskPins;
    }


    checkTaskPins() {
        let gotTasks = theStore.getters.tasks.length > 0;
        let gotPins = theStore.getters.taskPins.length > 0;
        if (gotTasks && !gotPins) {
            this.genTaskPins();
        }
    }


    commitTasks() {
        const cloneTasks = Object.assign([], this.tasks);
        this.tasks = cloneTasks;
    }


    //#endregion Task APIs


    //#region Sked APIs

    get skeds(): RwSked[] {
        return theStore.getters.skeds;
    }

    set skeds(val: RwSked[]) {
        theStore.dispatch("skeds", val);
    }

    get skedsMultiUse(): RwSked[] {
        let multiUse = this.skeds.filter(s => s.type === RwSkedTypes.MultiUse);
        return multiUse;
    }


    addSkedLocal(sked: RwSked) {
        if (sked) {
            this.skeds.push(sked);
            //RwApp.model.updateBadges();
        } else {
            RwLog.consoleWarn("sked == null");
        }
    }


    replaceSkedLocal(sked: RwSked) {
        if (sked) {
            this.removeSkedById(sked.skedId);

            this.skeds.push(sked);
            //RwApp.model.updateBadges();
        } else {
            RwLog.consoleWarn("sked == null");
        }
    }


    removeSked(sked: RwSked) {
        if (sked != null) {
            let index = this.skeds.indexOf(sked, 0);
            if (index > -1) {
                this.skeds.splice(index, 1);
            }
        } else {
            RwLog.consoleWarn("sked == null");
        }
        //RwApp.model.updateBadges();
    }


    removeSkedById(skedId: string) {
        if (skedId && skedId !== "") {
            let sked = this.skeds.find(s => s.skedId === skedId);
            if (sked) {
                this.removeSked(sked);
            }
            // let matchingSkedColl = this.skeds.filter(s => s.skedId === skedId);
            // if (matchingSkedColl && matchingSkedColl.length > 0) {
            //   this.removeSked(matchingSkedColl[0]);
            // }
        }
    }


    addSkedDB(sked: RwSked): Promise<RwSked> {
        let self = this;

        return new Promise<RwSked>(function (resolve, reject) {
            RwTaskSkeds
                .addSked(sked)
                .then(deltas => {

                    self.mergeDeltas(deltas);

                    let isCommitted = deltas.isCommitted(sked.skedId, RwTxnTypes.POST);
                    if (isCommitted) {
                        resolve(sked);
                    } else if (deltas.gotConflicts) {
                        reject(new Error("Schedule not added due to conflict, please try again."));
                    } else {
                        reject(new Error("Error adding Schedule"));
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    updateSkedDB(sked: RwSked): Promise<RwSked> {
        let self = this;

        return new Promise<RwSked>(function (resolve, reject) {
            RwTaskSkeds
                .updateSked(sked)
                .then(deltas => {

                    self.mergeDeltas(deltas);

                    let isCommitted = deltas.isCommitted(sked.skedId, RwTxnTypes.PUT);
                    if (isCommitted) {
                        resolve(sked);
                    } else if (deltas.gotConflicts) {
                        reject(new Error("Schedule not updated due to conflict, please try again."));
                    } else {
                        reject(new Error("Error updating schedule"));
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    deleteSkedsDB(skedIds: string[]): Promise<string[]> {
        const self = this;
        const SOURCE = "RwGlobals.deleteSkedsDB";

        return new Promise<string[]>(function (resolve, reject) {
            if (skedIds) {
                skedIds = [...new Set(skedIds)];
                let name: string = "";
                self.skeds.forEach(sked => name = (sked.skedId == skedIds[0]) ? sked.name : name);
                let title = (skedIds.length == 1) ? "Confirm Delete: " + name : "Confirm Delete: " + skedIds.length + " Schedules";
                let body = (skedIds.length == 1)
                    ? "Are you sure you want to delete this schedule?"
                    : "Are you sure you want to delete these schedules?";
                self.showConfirmDialog(title, body)
                    .then(isConfirmed => {
                        if (isConfirmed) {
                            RwTaskSkeds
                                .deleteSkeds(skedIds)
                                .then(deltas => {
                                    let text = (skedIds.length == 1) ? "Successfully deleted 1 schedule" : "Successfully deleted " + skedIds.length.toString() + " schedules";
                                    self.showSnack(text, "success");

                                    self.mergeDeltas(deltas);

                                    let commits = deltas.findCommits(RwEntityTypes.Sked, RwTxnTypes.DELETE);
                                    let commitIds = commits.map(c => c.eid);
                                    resolve(commitIds);

                                })
                                .catch(err => {
                                    reject(err);
                                    //RwLog.error(SOURCE, `Unhandled: ${err}`)
                                    self.showSnack("Failed to delete schedules " + err, "error");
                                });
                        }
                    })

            } else {
                reject(new Error("No Data"));
            }
        });
    }

    // skedIsOvernight(): Promise<boolean> {
    //   let self = this;
    //   let SOURCE = "RwGlobals.skedPassesMidnight";
    //   return new Promise<boolean>(function (resolve, reject) {
    //     self.showConfirmDialog("This Schedule Crosses Midnight", "This schedule crosses midnight, would you like to save?")
    //       .then(isConfirmed => {
    //         resolve(isConfirmed);
    //       })
    //       .catch(err => {
    //         reject(err)
    //       });
    //   });
    // }

    commitSkeds() {
        const clone = Object.assign([], this.skeds);
        this.skeds = clone;
    }


    //#endregion


    //#region History APIs


    get hist(): RwHistory[] {
        return theStore.getters.hist;
    }

    set hist(hist: RwHistory[]) {
        theStore.dispatch("hist", hist);
    }


    addHistLocal(hist: RwHistory) {
        if (hist) {
            this.history.push(hist);
        } else {
            RwLog.consoleError("hist == null");
        }
    }


    insertHist(hist: RwHistory, position: number) {
        if (
            hist &&
            (position < this.history.length ||
                (position === 0 && this.history.length === 0))
        ) {
            this.history.splice(position, 0, hist);
            //RwApp.model.updateBadges();
        } else {
            RwLog.consoleWarn("hist == null or invalid position");
        }
    }


    removeHist(hist: RwHistory) {
        if (hist != null) {
            let index = this.history.indexOf(hist, 0);
            if (index > -1) {
                this.history.splice(index, 1);
            }
        } else {
            RwLog.consoleWarn("hist == null");
        }
        //RwApp.model.updateBadges();
    }


    removeHistById(actId: string) {
        if (!actId) {
            return;
        }
        let actColl = this.history.filter(a => a.histId === actId);
        if (actColl && actColl.length > 0) {
            this.removeHist(actColl[0]);
        }
    }


    getHistoryForSite(siteId: String): RwHistory[] {
        let siteActs: RwHistory[] = [];
        let findId = siteId.toLowerCase();
        this.history.forEach(act => {
            let siteId = act.siteId;
            if (siteId != null) {
                if (siteId.toLowerCase() == findId) {
                    siteActs.push(act);
                }
            }
        });
        return siteActs;
    }

    getHistoryForStop(stopId: String): RwHistory[] {
        let stopActs: RwHistory[] = [];
        let findId = stopId.toLowerCase();
        this.history.forEach(act => {
            let stopId = act.stopId;
            if (stopId != null) {
                if (stopId.toLowerCase() == findId) {
                    stopActs.push(act);
                }
            }
        });
        return stopActs;
    }

    addHistDB(hist: RwHistory): Promise<RwHistory> {
        let self = this;

        return new Promise<RwHistory>(function (resolve, reject) {
            RwTaskHistory
                .addHistory(hist)
                .then(deltas => {

                    self.mergeDeltas(deltas);

                    let isCommitted = deltas.isCommitted(hist.histId, RwTxnTypes.POST);
                    if (isCommitted) {
                        resolve(hist);
                    } else if (deltas.gotConflicts) {
                        reject(new Error("Check-in not added due to conflict, please try again."));
                    } else {
                        reject(new Error("Error adding check-in"));
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    updateHistAsyncV2(hist: RwHistory): Promise<RwHistory> {
        return new Promise<RwHistory>(function (resolve, reject) {
            let self = this;

            RwTaskHistory
                .updateHistoryAsync(hist)
                .then(result => {
                    self.handleTeamDeltas(result);

                    let updatedHist = false;

                    if (result.commitList) {
                        let updateHistTx = result.commitList.filter(
                            c =>
                                c.ntt === RwEntityTypes.History &&
                                c.code === 200 &&
                                c.txn === RwTxnTypes.PUT &&
                                c.eid === hist.histId
                        ).map(c => c.eid);
                        if (updateHistTx && updateHistTx.length === 1) {
                            updatedHist = true;
                        }
                    }

                    if (updatedHist) {
                        resolve(hist);
                    } else if (result.deltaConflicts > 0) {
                        reject(
                            new Error(
                                "Check-in not updated due to conflict, please try again."
                            )
                        );
                        // reject(<Rejection>{
                        //   message: "Check-in not updated due to conflict, please try again."
                        // });
                    } else {
                        reject(new Error("Error updating check-in"));
                        //reject(<Rejection>{ message: "Error updating check-in" });
                    }
                })
                .catch(err => {
                    RwLog.consoleError(err);
                    reject(err);
                });
        });
    }


    deleteHistAsyncV2(histIds: string[]): Promise<string[]> {
        return new Promise<string[]>(function (resolve, reject) {
            let self = this;

            if (histIds) {
                histIds = [...new Set(histIds)];
            }

            RwTaskHistory
                .deleteHistoryAsync(histIds)
                .then(result => {
                    self.handleTeamDeltas(result);

                    let successfulDeletes: string[] = [];

                    if (result.commitList) {
                        //RwLog.logConsole("DeleteRoutesV2: Commit List", result.commitList);
                        successfulDeletes = result.commitList.filter(
                            c =>
                                c.ntt === RwEntityTypes.History &&
                                c.code === 200 &&
                                c.txn === RwTxnTypes.DELETE
                        ).map(t => t.eid);
                    }
                    //RwLog.logConsole("DeleteRoutesV2: successful deletes", successfulDeletes);

                    resolve(successfulDeletes);
                })
                .catch(err => {
                    RwLog.consoleError(err);
                    reject(err);
                });
        });
    }


    //#endregion


    //#region Driver APIs

    get drivers(): RwUser[] {
        // console.warn("globals.drivers gotten:", theStore.getters.drivers);
        return theStore.getters.drivers;
    }

    set drivers(drivers: RwUser[]) {
        theStore.dispatch("drivers", drivers);
    }


    saveDriver(driver: RwUser): Promise<void> {
        let self = this;
        return new Promise<void>((resolve, reject) => {
            RwTaskLicense.updateUser(driver).then(success => {
                if (success) {
                    self.mergeDrivers([driver]);
                    resolve();
                } else {
                    reject(new Error("update Driver call failed"))
                }
            }).catch(err => {
                reject(err);
            });
        })
    }

    onFileUpload(ulFile: File, uid: string) {
        const SOURCE = "Globals.onFileUpload";
        const self = this;
        if (ulFile && ulFile.size > 0) {
            let formData = new FormData();
            formData.append('newFile', ulFile);
            let url = `${RwConstants.CoreUri}/users/uimage?uid=${uid}`;
            return dal.callWithToken
                .post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(res => {
                    if (res) {
                        if (res.data) {
                            let avInfo = RwAvatarInfo.fromJson(res.data);
                            if (avInfo && avInfo.avUrl) {
                                //Update Dispatcher if Match
                                if (uid == RwPrefUtils.userId) {
                                    // Add slight delay to allow blob storage to get hosting set before request
                                    setTimeout(() => {
                                        this.avInfo = avInfo;
                                    }, 300);

                                }
                                //Update Driver if Match
                                setTimeout(() => {
                                    let driver = this.drivers.find(d => d.userId === uid);
                                    if (driver) {
                                        driver.imageUrl = avInfo.avUrl;
                                    } else {
                                        //Driver Not Found
                                        RwLog.error(SOURCE, `POST url:${url} -> USER NOT FOUND \nAuthToken:${RwPrefUtils.token}`)
                                    }
                                }, 300);
                            }
                        } else {
                            RwLog.error(SOURCE, `POST url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}`)
                        }
                    } else {
                        RwLog.error(SOURCE, `POST url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}`)
                    }
                })
                .catch(function (err) {
                    RwLog.consoleError(SOURCE, "WTF: update error", err.toString());
                    self.showSnack("Failed to Update Avatar", "error");
                    if (err && err.status === 403) {
                        RwLog.error(SOURCE, `403 while uploading ${err.toString()}`);
                    } else {
                        RwLog.error(SOURCE, 'UNHANDLED: ' + err ? err.toString() : "");
                    }
                });
        }
    }

    updateAvatar(avColor: string, avInits: string, userId: string, avType: number): Promise<RwAvatarInfo> {
        return RwTaskLicense.updateAvatar(avColor, avInits, userId, avType);
    }

    saveAvatar(driver: RwUser): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            RwTaskLicense.updateAvatarUser(driver).then(success => {
                if (success) {
                    this.mergeDrivers([driver]);
                    // console.warn("success reached in globals.saveAvatar; success is:", success);
                    resolve();
                } else {
                    reject(new Error("update Driver call failed"))
                }
            }).catch(err => {
                reject(err);
            });
        })
    }


    createDriver(driverModel: RwCreateDriver): Promise<CreateDriversReturnModel> {
        const self = this;

        return new Promise<CreateDriversReturnModel>((resolve, reject) => {
            RwTaskLicense.createBulkDrivers([driverModel]).then(model => {
                if (model) {
                    this.syncDelta().then(() => {
                        resolve(model);
                    }).catch(err => {
                        resolve(model);
                    });
                } else {
                    //console.warn("CreateBulkDrivers", model);
                    reject(new Error("create Driver call failed"))
                }

            }).catch(err => {
                reject(err);
            });
        })
    }

    createBulkUsers(users: Array<RwCreateDriver>): Promise<CreateDriversReturnModel> {
        const self = this;

        return new Promise<CreateDriversReturnModel>((resolve, reject) => {
            RwTaskLicense.createBulkDrivers(users).then(model => {
                if (model) {
                    this.syncDelta().then(() => {
                        resolve(model);
                    }).catch(err => {
                        resolve(model);
                    });
                } else {
                    //console.warn("CreateBulkDrivers", model);
                    reject(new Error("create Driver call failed"))
                }

            }).catch(err => {
                reject(err);
            });
        })
    }


    addDriver(driver: RwUser) {
        RwLog.consoleError("WTF: addDriver", this.drivers.length);
        this.drivers.push(driver);
        //this.updateBadges();
    }


    removeDriver(driverId: string): boolean {
        let success = false;
        if (driverId) {
            let driver = this.drivers.find(d => d.userId === driverId);
            if (driver) {
                let idxDriver = this.drivers.indexOf(driver);
                if (idxDriver >= 0) {
                    this.drivers.splice(idxDriver, 1);
                    success = true;
                }
                //this.updateBadges();
            }
            // let driverMatchColl = this.drivers.filter(d => d.userId === driverId);
            // if (driverMatchColl && driverMatchColl.length > 0) {
            //   let driverIndex = this.drivers.indexOf(driverMatchColl[0]);
            //   this.drivers.splice(driverIndex, 1);
            //   success = true;
            //   //this.updateBadges();
            // }
        }
        return success;
    }


    updateDrivers(driverList: RwUser[]) {
        this.drivers = driverList;
        //this.updateBadges();
    }


    pushUpdateToDriver(route: RwRoute): Promise<number> {
        const SOURCE = "RwGlobals.pushUpdateToDriver";

        return new Promise<number>((resolve, reject) => {
            if (this.isLoggedIn && this.gotToken) {
                RwTaskPush.sendPushNotify(route.routeId)
                    .then(res => {
                        if (res.ErrorCode) {
                            switch (res.ErrorCode) {
                                case NotifyErrorEnum.NoPushTargetsFound:
                                    //console.log(SOURCE, "showSnack", res as number);
                                    this.showSnack("Push Notifications not enabled for this driver", "warning");
                                    break;

                                default:
                                    RwLog.consoleError(SOURCE, "Unexpected resp code", res);
                                    this.showSnack("There was an issue sending the push notification. Please check the device " +
                                        "has push enabled and try again.", "warning");
                                    break;
                            }
                            resolve(res.ErrorCode);
                        } else {
                            this.showSnack("Message Sent to Device", "success");
                            resolve(0);
                        }
                        //console.log(SOURCE, "sendPushNote SUCCESS", res);
                    })
                    .catch(err => {
                        if (this.checkNotHandled(err)) {
                            let status = !!err.Code ? err.Code : err.status;
                            let errText = JSON.stringify(err);
                            if ((err.error && (err.error.code === "ECONNABORTED" || err.error.message === "Network Error")) ||
                                err.error === "Network Error") {
                                RwLog.warn(SOURCE, `Network Error: ${JSON.stringify(err)}`);
                            } else if (status === 403) {
                                RwLog.warn(SOURCE, `Forbidden: \n\n${errText}`);
                            } else if (status == 404 || err.message === "Request failed with status code 404") {
                                //console.log(SOURCE, "showSnack", res as number);
                                RwLog.warn(SOURCE, `Driver not found: \n\n${errText}`);
                                this.showSnack("Push Notifications expired for this driver", "warning");
                            } else {
                                RwLog.error(SOURCE, `sendPushNotify -> ${status}: \n\n${errText}`);
                                this.showSnack("Push Note failed. ");
                            }
                        }

                        reject(err);
                    });
            } else {
                reject();
            }
        });
    }


    commitDrivers() {
        //console.warn("triggerUpdateDrivers");
        const clone = Object.assign([], this.drivers);
        this.drivers = clone;
    }


    //#endregion Driver APIs


    //#region Misc Ops

    showResumeSubscriptionPopup = false;
    couponDescription = "";
    couponCodeName = "";

    showApplyCouponError(errorCode: number) {
        let errorMessage: string;
        switch(errorCode) {
            case 0:
                errorMessage = RwApplyCouponError.None;
                break;
            case 2:
                errorMessage = RwApplyCouponError.InvalidCoupon;
                break;
            case 3:
                errorMessage = RwApplyCouponError.AlreadyApplied;
                break;
            case 4:
                errorMessage = RwApplyCouponError.Expired;
                break;
            case 5:
                errorMessage = RwApplyCouponError.SubscriptionNotFound;
                break;
            default:
                errorMessage = RwApplyCouponError.Unknown;
                break;
        }
        this.showSnack(errorMessage, "warning");
    }

    applyCouponCode(resumeSubscription = false) {
        this.couponCodeName = router.currentRoute.query.coupon as string;
        RwTaskBillingProfile.ApplyCoupon(this.couponCodeName as string)
            .then(result => {
                if (result.Success) {
                    if (resumeSubscription) {
                        const errorMessage = "There was a problem resuming the subscription, please try again";
                        RwTaskBillingProfile.ResumeAutoRenew()
                            .then(succeed => {
                                if (succeed) {
                                    router.replace({'query': null});
                                    this.showSnack(`Subscription resumed successfully and applied coupon ${this.couponCodeName}`, "success");
                                    this.showResumeSubscriptionPopup = false;
                                } else {
                                    this.showSnack(errorMessage, "error");
                                }
                            })
                            .catch(err => {
                                RwLog.error("RwAccount.ResumeSubscription", JSON.stringify(err));
                                this.showSnack(errorMessage, "error");
                            })
                    } else {
                        router.replace({'query': null});
                        this.showSnack(`Coupon code ${this.couponCodeName} successfully applied`, "success");
                    }
                } else {
                    this.showApplyCouponError(Number(result.Error));
                }
            })
            .catch(err => {
                RwLog.error("RwAccount.applyCouponCode", JSON.stringify(err));
                this.showSnack("Sorry we had a problem applying the coupon code", "error");
            })
    }

    checkForCouponCode() {
            if (!!router.currentRoute.query.coupon) {
                if (!this.hasCurrPurch && !this.isSubTeam) {
                    router.push({name: RwPages.Upgrade, query: router.currentRoute.query}).catch(err => {
                    });
                }
                else {
                    RwTaskBillingProfile.ValidateCoupon(router.currentRoute.query.coupon as string)
                        .then(result => {
                            if (result.Success) {
                                if (this.hasActiveFlexTrial || this.isAutoRenewing) {
                                    this.applyCouponCode();
                                }
                                else if (!this.isAutoRenewing) {
                                    this.couponDescription = result.CouponDesc;
                                    this.showResumeSubscriptionPopup = true;
                                }
                            } else {
                                this.showSnack(result.Error, "warning");
                            }
                        })
                        .catch(err => {
                            RwLog.error("RwAccount.validateCouponCode", JSON.stringify(err));
                            this.showSnack("Sorry we had a problem applying the coupon code", "error");
                        })
                }
            }
    }


    get outdatedVersion() {
        return theStore.getters.outdatedVersion;
    }

    checkOutdatedVersion() {
        try {

            const currentVersion = RwConstants.flexVersion === "FLEX_DEBUG" ?
                moment().format("YYYY.MM.DD0") :
                RwConstants.flexVersion;

            RwTaskMisc.getLatestFlexVersion()
                .then(latestVersion => {
                    const currentVersionDate = moment(currentVersion, "YYYY.MM.DD0");
                    const latestVersionDate = moment(latestVersion, "YYYY.MM.DD0");
                    const diffDays = latestVersionDate.diff(currentVersionDate, "days");
                    if (diffDays > 4) {
                        theStore.dispatch("outdatedVersion", VersionOutdatedEnum.NeedsUpdateSevere);
                    } else if (diffDays > 1) {
                        theStore.dispatch("outdatedVersion", VersionOutdatedEnum.NeedsUpdate);
                    } else {
                        theStore.dispatch("outdatedVersion", VersionOutdatedEnum.Current);
                    }
                })
                .catch(err => {
                    RwLog.warn("RwGlobals.hasOutdatedVersion", JSON.stringify(err));
                });
        } catch (err) {
            RwLog.warn("RwGlobals.hasOutdatedVersion", JSON.stringify(err));
        }
    }


    validateFedexReturn() {
        let isValid = false;
        let dateDiff = moment.duration(moment().diff(moment(RwPrefUtils.fdxManifestAuthTime)));
        if (RwPrefUtils.token && dateDiff.asHours() < 2) {
            this.isReturningFedexManifest = true;
            isValid = true;
        }
        return isValid;
    }


    swapOrgs(newOrg: SubTeamModel) {
        //let newModel = new RwModel(false);
        // newModel.skuInfos = RwApp.model.skuInfos;
        // newModel.isPro = RwApp.model.isPro;
        // newModel.imgSrc = RwApp.model.imgSrc;
        // newModel.isComponentLoaded = true;
        this.orgId = newOrg.OrgId;
        if (newOrg.OrgId != newOrg.AccountId) {
            this.subDomain = newOrg.Domain;
            this.isSubTeam = true;
        } else {
            this.parentDomain = newOrg.Domain;
            this.isSubTeam = false;
        }

        //this.model = newModel;
        //this.model.loadData();
    }


    static getGeolocation(): Promise<GeolocationPosition> {
        //console.log("getGeolocation RwGlobals.allowedGeoLoc", RwGlobals.allowedGeoLoc)

        return new Promise<GeolocationPosition>((resolve) => {
            if (RwGlobals.allowedGeoLoc) {
                navigator.geolocation.getCurrentPosition(
                    position => resolve(position),
                    error => resolve(null),
                    {maximumAge: 1000 * 60 * 60, timeout: 5000}
                );
            } else {
                resolve(null)
            }
        });
    }


    checkGeoOnboard(): string {
        if (RwGlobals.allowedGeoLoc === 1) {
            return 'granted';
        } else if (RwGlobals.allowedGeoLoc === 0) {
            return 'prompt';
        } else if (RwGlobals.allowedGeoLoc === -1) {
            return 'denied';
        }
    }


    checkGeoLocationPerms(): Promise<PermissionStatus> {
        const SOURCE = "RwGlobals.checkGeoLocationPerms";
        const self = this;
        return new Promise<PermissionStatus>((resolve, reject) => {
            //console.log("checkGeoLocationPerms");
            if (this.token !== RwConstants.EmptyGuid && this.userId !== RwConstants.EmptyGuid) {
                if (!navigator || !navigator.permissions) {
                    RwLog.warn(SOURCE, `Cannot access permissions - navigator || navigator.permissions not available.`);
                    return reject(new RwError(200, true, `Cannot access permissions - navigator || navigator.permissions not available.`));
                }
                navigator.permissions
                    .query({name: "geolocation"})
                    .then((result) => {
                        // .then(function (result) {
                        // Will return ['granted', 'prompt', 'denied']
                        //console.log("navigator.permissions query(geolocation) => ", result.state);
                        if (result.state === "granted") {
                            RwGlobals.allowedGeoLoc = 1;
                            resolve(result);
                        } else if (result.state === "prompt") {
                            resolve(result);
                        } else { // denied
                            RwGlobals.allowedGeoLoc = -1;
                            resolve(result);
                        }

                        result.onchange = () => {
                            self.locPermChanged(result.state);
                        }
                    })
                    .catch(err => {
                        if (self.checkNotHandled(err)) {
                            const errText = JSON.stringify(err)?.trim();
                            if (errText === '{}') {
                                RwLog.warn(SOURCE, `Unhandled: '${errText}'`);
                            } else {
                                RwLog.error(SOURCE, `Unhandled: '${errText}'`);
                            }
                        }
                    });
            } else {
                RwLog.warn(SOURCE, `Force logout, Invalid token: ${RwPrefUtils.token} or userID: ${this.userId}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
            }
        });
    }


    promptForLocationPermission(): Promise<boolean> {
        //console.log("promptForLocationPermission")
        return new Promise<boolean>((resolve) => {
            navigator.geolocation.getCurrentPosition(
                () => {
                    RwGlobals.allowedGeoLoc = 1;
                    resolve(true)
                },
                () => {
                    RwGlobals.allowedGeoLoc = -1;
                    resolve(false)
                });
        })
    }

    locPermChanged(state) {
        const self = this;
        //console.log('Location Permissions changed ' + state);
        if (state === "granted") {
            RwGlobals.allowedGeoLoc = 1;
            //RwGlobals.getGeolocation();
            RwGlobals
                .getGeolocation()
                .then((pos) => {
                    if (pos && pos.coords) {
                        let lat = pos.coords.latitude;
                        let lng = pos.coords.longitude
                        //console.log("getGeolocation => ", lat, lng)
                        self.plan.mapCentroid = {lat: lat, lng: lng, zoom: 12.0};
                    }
                });
        }
    }


    popLimitWarning(limitType: RwLimitType) {
        let title = "Account Limit";
        let message = "The max route size has been exceeded.";

        if (this.isPro) {
            switch (limitType) {
                case RwLimitType.RouteSize:
                    message = "The max route size has been exceeded.";
                    break;
                case RwLimitType.TotalStops:
                    message =
                        "The daily quota of total optimized stops has been exceeded.";
                    break;
                case RwLimitType.TotalSearches:
                    message =
                        "The daily quota of total auto-complete searches has been exceeded.";
                    break;
                default:
                    message = "The limit has been exceeded.";
                    break;
            }
            RwGlobals.popWarning(title, message);
        } else {
            //DEFER: Re-evaluate:  We may not need to do this if we require PRO && NO EVALS

            // switch (limitType) {
            //   case RwLimitType.RouteSize:
            //     message = "The max route size for an eval account has been exceeded. \n\n Would you like to view the upgrade options for Road Warrior Pro?";
            //     break;
            //   case RwLimitType.TotalStops:
            //     message = "The daily quota of total optimized stops has been exceeded. \n\n Would you like to view the upgrade options for Road Warrior Pro?";
            //     break;
            //   case RwLimitType.TotalSearches:
            //     message = "The daily quota of total auto-complete searches for an eval account has been exceeded. \n\n Would you like to view the upgrade options for Road Warrior Pro?";
            //     break;
            //   default:
            //     message = "The limit for an eval account has been exceeded.  \n\n Would you like to view the upgrade options for Road Warrior Pro?";
            //     break;
            // }
        }
    }


    static popWarning(title: string, body: string) {
        //RwPlanner.self = this;
        // let dialog = self.$.popWarning;
        // let txtTitle = self.$.popWarningTitle;
        // let txtBody = self.$.popWarningBody;

        let dialog = <any>document.querySelector("#popWarning");
        let txtTitle = <any>document.querySelector("#popWarningTitle");
        let txtBody = <any>document.querySelector("#popWarningBody");

        txtTitle.innerHTML = title;
        txtBody.innerHTML = body;
        dialog.toggle();
    }


    //#endregion


    //#region Popups


    //Colors can HEX or ['success', 'info', 'warning', 'error', 'primary']
    showSnack(text: string, color?: string) {
        this.m_snackBarText = text;
        this.m_snackBarColor = color ? color : "primary";
        this.m_showSnackVar = true;
    }


    pendConfirmResolve;

    showConfirmDialog(title: string, body: string, posText?: string, negText?: string, dontShowCheck: boolean = false, dontShowCallback: Function | null = null): Promise<boolean> {

        const self = this;

        return new Promise<boolean>((resolve) => {
            this.m_confirmTitle = title;
            this.m_confirmBody = body;
            this.m_confirmPosText = posText || "Yes";
            this.m_confirmNegText = negText || "No";
            this.m_showConfirm = true;
            this.m_confirmDontShowCheck = dontShowCheck;
            this.m_confirmDontShowCallback = dontShowCallback;
            self.pendConfirmResolve = resolve;
            //self.pendConfirmReject = reject;
        });

    }

    onConfirm(isConfirmed: boolean, dontShow: boolean = false) {
        //xREF: ACTIONS: RwGlobals.onConfirm
        this.pendConfirmResolve(isConfirmed);
        if (isConfirmed && dontShow) this.m_confirmDontShowCallback();
    }


    pendingColorPick;

    showColorPicker(initColor?: string): Promise<string> {
        const self = this;
        self.m_colorPickerInitColor = initColor;
        self.m_showColorPicker = true;
        return new Promise<string>((resolve) => {
            self.pendingColorPick = resolve;

        });

    }


    onColorPick(color: string) {
        //console.log("RwGlobals.onColorPick color", color);
        this.pendingColorPick(color);
    }

    pendingAddRouteSelection;

    showAddRoutePicker(): Promise<RwRoute> {
        const self = this;
        self.m_showAddRoutePicker = true;
        return new Promise<RwRoute>((resolve) => {
            self.pendingAddRouteSelection = resolve;
            // resolve(self.pendingAddRouteSelection)
        });

    }

    // newRouteCreated(route: RwRoute) {
    //   this.pendingAddRouteSelection(route);
    // }
    //
    // addRoutePlanner(): Promise<boolean> {
    //   let self = this;
    //   return new Promise<boolean>((resolve) => {
    //     self.showAddRoutePicker()
    //       .then(route => {
    //         resolve(route != null);
    //       })
    //       .catch(() => {
    //         console.error("in in showAddRoutePicker  .catch")
    //       });
    //   });
    // }

    pendingVisitPick;

    showVisitPicker(initVisit?: number): Promise<number> {
        //console.log("showVisitPicker");
        const self = this;
        self.m_visitPickerInit = initVisit;
        self.m_showVisitPicker = true;
        return new Promise<number>((resolve) => {
            self.pendingVisitPick = resolve;
        });

    }

    onVisitPick(visitTimeMins: number) {
        //console.log("RwGlobals.onVisitPick visitTimeMins", visitTimeMins);
        this.pendingVisitPick(visitTimeMins);
    }


    pendingSkedPick;

    showSkedPicker(pri?: number, startMils?: number, finishMils?: number): Promise<RwSked> {
        const self = this;
        //console.log("showSkedPicker", pri, start, finish);

        self.m_skedPickerPriority = pri;
        self.m_skedPickerStart = startMils;
        self.m_skedPickerFinish = finishMils;
        self.m_showSkedPicker = true;

        return new Promise<RwSked>((resolve) => {
            self.pendingSkedPick = resolve;
        });
    }

    onSkedPick(sked: RwSked) {
        //console.log("RwGlobals.onSkedPick pri, start, finish", sked);
        this.pendingSkedPick(sked);
    }


    pendingRoutePick;

    showRoutePicker(srcId: string): Promise<RwRoutePick> {
        const self = this;
        self.m_routePickerSrcId = srcId;
        self.m_showRoutePicker = true;
        //console.log("showRoutePicker srcId m_routePickerSrcId", srcId, self.m_routePickerSrcId);
        return new Promise<RwRoutePick>((resolve) => {
            self.pendingRoutePick = resolve;
        });
    }

    get showGenericRoutePicker(): boolean {
        return theStore.getters.showGenericRoutePicker;
    }

    set showGenericRoutePicker(val: boolean) {
        theStore.dispatch("showGenericRoutePicker", val);
    }

    get genericRoutePickerFilter(): any {
        return theStore.getters.genericRoutePickerFilter;
    }

    set genericRoutePickerFilter(val: any) {
        theStore.dispatch("genericRoutePickerFilter", val);
    }

    openGenericRoutePicker(srcId: string, rfilter: any = () => true): Promise<RwRoutePick> {
        const self = this;
        self.m_routePickerSrcId = srcId;
        self.genericRoutePickerFilter = rfilter
        self.showGenericRoutePicker = true;
        //console.log("showRoutePicker srcId m_routePickerSrcId", srcId, self.m_routePickerSrcId);
        return new Promise<RwRoutePick>((resolve) => {
            self.pendingRoutePick = resolve;
        }).finally(() => self.genericRoutePickerFilter = () => true);
    }

    onRoutePick(pick: RwRoutePick) {
        //console.log("RwGlobals.onSkedPick pri, start, finish", pick);
        this.pendingRoutePick(pick);
    }


    pendingReloPick;

    showReloPicker(title: string, lat: number, lng: number, enableSearch: boolean = false, isAddReport: boolean = false): Promise<RwGeoPoint> {
        const self = this;
        self.m_reloPickerInitTitle = title;
        self.m_reloPickerInitLat = lat;
        self.m_reloPickerInitLng = lng;
        self.m_showReloPicker = true;
        self.m_enableReloPickerSearch = enableSearch;
        self.m_reloPickerAddReport = isAddReport;
        //console.log("showReloPicker lat, lng", lat, lng);

        return new Promise<RwGeoPoint>((resolve) => {
            self.pendingReloPick = resolve;
        });
    }

    onReloPick(pick: RwGeoPoint) {
        //console.log("RwGlobals.onReloPick pick", pick.lat, pick.lng );
        this.pendingReloPick(pick);
    }


    pendingStatusPick;

    showStatusPicker(name: string, status: RwRouteStatusCodes): Promise<RwRouteStatusCodes> {
        const self = this;
        self.m_statusPickerInitName = name;
        self.m_statusPickerInitStatus = status;
        self.m_showStatusPicker = true;
        //console.log("showReloPicker lat, lng", lat, lng);
        return new Promise<RwRouteStatusCodes>((resolve) => {
            self.pendingStatusPick = resolve;
        });
    }

    onStatusPick(pick: RwRouteStatusCodes) {
        //console.log("RwGlobals.onReloPick pick", pick.lat, pick.lng );
        this.pendingStatusPick(pick);
    }


    pendingAssignPick;

    showAssignPicker(routeUpdate: RwRoute): Promise<RwRoute> {
        const self = this;
        self.m_assignPickerInitRoute = routeUpdate;
        self.m_showAssignPicker = true;
        //console.log("showAssignPicker route.name", routeUpdate.name);
        return new Promise<RwRoute>((resolve) => {
            self.pendingAssignPick = resolve;
        });
    }

    onAssignPick(routeUpdate: RwRoute) {
        //console.log("RwGlobals.onAssignPick route", routeUpdate.driverId, routeUpdate.assignCode);
        this.pendingAssignPick(routeUpdate);
    }


    //xREF: RwGlobals.showXXXXEdit

    pendingRouteEdit;

    get m_showRouteEdit(): boolean {
        return theStore.getters.showRouteEdit;
    }

    set m_showRouteEdit(val: boolean) {
        theStore.dispatch("showRouteEdit", val).catch();
    }

    get m_routeEditInitRoute(): boolean {
        return theStore.getters.routeEditInitRoute;
    }

    showRouteEdit(route: RwRoute): Promise<boolean> {
        const self = this;
        theStore.dispatch("routeEditInitRoute", route).catch();
        theStore.dispatch("showRouteEdit", true).catch();
        return new Promise<boolean>((resolve) => {
            self.pendingRouteEdit = resolve;
        });
    }

    onRouteEdited(isCommit: boolean) {
        this.pendingRouteEdit(isCommit);
    }


    pendingStopEdit;

    get m_showStopEdit(): boolean {
        return theStore.getters.showStopEdit;
    }

    set m_showStopEdit(val: boolean) {
        theStore.dispatch("showStopEdit", val).catch();
    }

    get m_stopEditInitStop(): RwStop {
        return theStore.getters.stopEditInitStop;
    }

    set m_stopEditInitStop(val: RwStop) {
        theStore.dispatch("stopEditInitStop", val).catch();
    }

    showStopEdit(stop: RwStop): Promise<boolean> {
        const self = this;
        self.m_stopEditInitStop = stop;
        self.m_showStopEdit = true;
        return new Promise<boolean>((resolve) => {
            self.pendingStopEdit = resolve;
        });
    }

    onStopEdited(isCommit: boolean) {
        this.pendingStopEdit(isCommit);
    }

    pendingHistoryEdit;

    get m_showHistoryEdit(): boolean {
        return theStore.getters.showHistoryEdit;
    }

    set m_showHistoryEdit(val: boolean) {
        theStore.dispatch("showHistoryEdit", val).catch();
    }

    get m_histEditInitHistory(): RwHistory {
        return theStore.getters.histEditInitHistory;
    }

    set m_histEditInitHistory(val: RwHistory) {
        theStore.dispatch("histEditInitHistory", val).catch();
    }

    showHistoryEdit(history: RwHistory): Promise<boolean> {
        const self = this;
        self.m_histEditInitHistory = history;
        self.m_showHistoryEdit = true;
        return new Promise<boolean>((resolve) => {
            self.pendingHistoryEdit = resolve;
        });
    }

    onHistoryEdited(isCommit: boolean) {
        this.pendingHistoryEdit(isCommit);
    }

    pendingSiteEdit;

    get m_showSiteEdit(): boolean {
        return theStore.getters.showSiteEdit;
    }

    set m_showSiteEdit(val: boolean) {
        theStore.dispatch("showSiteEdit", val).catch();
    }

    get m_siteEditInitSite(): boolean {
        return theStore.getters.siteEditInitSite;
    }

    showSiteEdit(site: RwSite): Promise<boolean> {
        const self = this;
        theStore.dispatch("siteEditInitSite", site).catch();
        theStore.dispatch("showSiteEdit", true).catch();
        return new Promise<boolean>((resolve) => {
            self.pendingSiteEdit = resolve;
        });
    }

    onSiteEdited(isCommit: boolean) {
        this.pendingStopEdit(isCommit);
    }


    pendingSkedEdit;

    get m_showSkedEdit(): boolean {
        return store.getters.showSkedEdit;
    }

    set m_showSkedEdit(val: boolean) {
        theStore.dispatch("showSkedEdit", val).catch();
    }

    get m_skedEditInitSked(): RwSked {
        return store.getters.skedEditInitSked;
    }

    set m_skedEditInitSked(val: RwSked) {
        theStore.dispatch("skedEditInitSked", val).catch();
    }

    showSkedEdit(sked: RwSked): Promise<boolean> {
        const self = this;
        self.m_skedEditInitSked = sked;
        self.m_showSkedEdit = true;
        return new Promise<boolean>((resolve) => {
            self.pendingSkedEdit = resolve;
        });
    }

    onSkedEdited(isCommit: boolean) {
        this.pendingSkedEdit(isCommit);
    }


    get m_showDriverEdit(): boolean {
        return store.getters.showDriverEdit;
    }

    set m_showDriverEdit(val: boolean) {
        store.dispatch("showDriverEdit", val).catch();
    }

    get m_driverEditInitDriver(): boolean {
        return store.getters.driverEditInitDriver;
    }

    pendingDriverEdit;

    showDriverEdit(driver: RwUser): Promise<boolean> {
        const self = this;
        theStore.dispatch("driverEditInitDriver", driver).catch();
        theStore.dispatch("showDriverEdit", true).catch();
        return new Promise<boolean>((resolve) => {
            self.pendingDriverEdit = resolve;
        });
    }

    onDriverEdited(isCommit: boolean) {
        this.pendingDriverEdit(isCommit);
    }


    pendingRouteNew;

    get m_showRouteNew(): boolean {
        return theStore.getters.showRouteNew;
    }

    set m_showRouteNew(val: boolean) {
        theStore.dispatch("showRouteNew", val).catch();
    }

    get showSearchPanel(): boolean {
        return theStore.getters.showSearchPanel;
    }

    set showSearchPanel(val: boolean) {
        theStore.dispatch("showSearchPanel", val).catch();
    }


    showRouteNew(initEmpty: boolean, initRoute?: RwRoute): Promise<RwRoute> {
        const self = this;
        self.m_routeNewInitEmpty = initEmpty;
        self.m_routeNewInitRoute = initRoute;
        if (self.hasActiveFlexSku) {
            self.m_showRouteNew = true;
        }
        //console.log("showRouteNew route.name", initRoute ? initRoute.name: "no route", `showNewRoute:${self.m_showRouteNew}`);
        return new Promise<RwRoute>((resolve) => {
            //self.showRouteEdit(route)
            self.pendingRouteNew = resolve;
        });
    }

    onRouteNew(newRoute: RwRoute) {
        //console.log("RwGlobals.onRouteNew newRoute", newRoute);
        this.pendingRouteNew(newRoute);
    }

    createNewRoute(initRoute?) {
        const self = this;
        const SOURCE = "RwGlobals.createNewRoute";

        if (this.gotUser) {
            let route = RwRoute.blankRoute();

            this.addRouteDB(route, [SOURCE], false)
                .then(routeNew => {
                    //console.log("onRouteCreate addRouteDB callback");

                    let assignCodeInit = route.assignCode;

                    let isSelfAssigned = route.driverId === this.userId;
                    //let isPending = route.assignCode == RwRouteAssignCodes.pending;
                    let isDynamic = route.isDynLoc;
                    let gotDrivers = this.drivers.length > 1;
                    let updateStatus = isSelfAssigned && gotDrivers && isDynamic;
                    if (updateStatus) {

                        //if (RwPrefUtils.isDynPoint) {
                        // On route creation if GPS based change assignCode.
                        const syncStatus = new RwSyncStatus();
                        syncStatus.routeId = route.routeId;
                        syncStatus.assignCode = RwRouteAssignCodes.pending;
                        syncStatus.driverId = route.driverId;
                        syncStatus.statusCode = route.statusCode;

                        RwTaskRoutes.setRouteStatusInfo(syncStatus)
                            .then(() => {
                                route.assignCode = RwRouteAssignCodes.pending
                            })
                            .catch(err => {
                                //RwLog.error(SOURCE, `Dyn Loc Status update failed: ${err}`);
                                let errText = JSON.stringify(err);
                                let statusText = JSON.stringify(syncStatus.toJSON());
                                RwLog.error(SOURCE, `Unhandled setRouteStatusInfo \n${statusText} \n\n${errText}`);
                                if (initRoute) initRoute.assignCode = assignCodeInit;
                            })
                            .finally(() => {
                                this.addRoute(route);
                                self.onRouteNew(routeNew);
                            })
                    } else {
                        this.addRoute(route);
                        self.onRouteNew(routeNew);
                    }
                })
                .catch(err => {
                    if (this.checkNotHandled(err)) {
                        let status = !!err.Code ? err.Code : err.status;
                        let errDesc = err && err.description ? err.description : "no description";
                        this.showSnack(`Route Creation Failed: ${errDesc}`, "error");
                        if (status === 403) {
                            RwLog.warn(SOURCE, `Forbidden: ${err}`);
                        } else {
                            RwLog.error(SOURCE, `Unhandled: ${err}`);
                        }
                    }
                });
        } else {
            RwLog.error(SOURCE, `Missing User`);
            this.logOut();
            this.showSnack('Session Invalid', 'error');
        }
        // this.close();
    }

    //#endregion Popups


    //region Common Map Ops

    hidpi: boolean = true;
    stopPinSize: H.math.ISize = {w: 32, h: 48};
    basePinSize: H.math.ISize = {w: 48, h: 48};
    sitePinSize: H.math.ISize = {w: 24, h: 24};
    drvrPinSize: H.math.ISize = {w: 32, h: 32};
    iconDict: { [color: string]: H.map.Icon } = {};

    getIcon(imgUri: string, iconOpts: H.map.Icon.Options): Promise<H.map.Icon> {
        //const self = this;

        return new Promise<H.map.Icon>((resolve, reject) => {

            let icon: H.map.Icon;
            //let cachedUrl = self.globals.idxURL
            let cachedIcon = this.iconDict[imgUri];
            if (cachedIcon) {
                icon = cachedIcon;
                //console.log("CACHE HIT:", imgUri);
                resolve(icon);
            } else {
                //console.warn("CACHE MISS:", imgUri);

                let left5 = imgUri.substring(0, 5);
                if (left5 === "data:") {
                    //console.log("getIcon re-used data:")
                    icon = new H.map.Icon(imgUri, iconOpts);
                    this.iconDict[imgUri] = icon;
                    resolve(icon);
                } else {
                    //console.warn("getIcon NETWORK HIT!", imgUri)
                    // dal.axiosCache
                    //   .get(imgUri, {timeout: RwConstants.NetTimeout, responseType: "blob"})
                    //   .then(res => res.data)
                    //   .then(blob => URL.createObjectURL(blob))
                    //   .then(src => {
                    //     //console.log("getIcon imgUri >> src", imgUri, src);
                    //     icon = new H.map.Icon(src, iconOpts);
                    //     self.iconDict[imgUri] = icon;
                    //     resolve(icon);
                    //   })
                    //   .catch((err) => {
                    //RwLog.consoleWarn("WTF: getIcon", err.toString());
                    const config = {
                        timeout: RwConstants.NetTimeout,
                        responseType: "blob",
                        headers: {}
                    } as AxiosRequestConfig;
                    config.headers.Accept = "application/json,image/png";

                    dal.callSansToken
                        .get(imgUri, config)
                        .then(res => res.data)
                        .then(blob => URL.createObjectURL(blob))
                        .then(src => {
                            icon = new H.map.Icon(src, iconOpts);
                            this.iconDict[imgUri] = icon;
                            //console.log("getIcon CacheByPass:", imgUri);
                            resolve(icon);
                        })
                        .catch(err => {
                            if (this.checkNotHandled(err)) {
                                RwLog.consoleError("WTF: getIcon", err.toString());
                            }
                            reject(err);
                        });

                    // });
                }
            }


            // MONITOR: CORS: Options:
            // * Try using iconDict for all URLs; Check effect on CORS
            // * Consider loading image yourself; then pass image vs url
            //    ** https://stackoverflow.com/a/27284736
            //    ** https://morioh.com/p/f4d331b62cda
            //    ** image-downloader https://www.npmjs.com/package/image-downloader
            //    ** explicitly add headers to PIN api


            //return icon;


        });

    }

    getIconSize(pinType: RwPinTypes): H.math.ISize {
        let size: H.math.ISize = this.basePinSize;
        switch (pinType) {
            case RwPinTypes.Stop:
                //xREF: SVG: getIconSize
                size = this.stopPinSize;
                //size = this.space.stopPinSize;
                break;
            case RwPinTypes.Task:
            case RwPinTypes.Site:
            case RwPinTypes.Target:
                size = this.sitePinSize;
                break;
            case RwPinTypes.Driver:
                size = this.drvrPinSize;
                break;
        }
        return size;
    }

    getAnchorPoint(pinType: RwPinTypes, custAnchor?: H.math.Point): H.math.Point {
        //let self = this;
        let anchor: H.math.Point;
        if (custAnchor) {
            anchor = custAnchor;
        } else {

            switch (pinType) {

                //xREF: SVG: getAnchorPoint
                case RwPinTypes.Stop:
                    anchor = this.hidpi
                        ? new H.math.Point(16, 48)
                        : new H.math.Point(16, 48);
                    // anchor = RwPlannerMap.hidpi
                    //   ? new H.math.Point(22, 48)
                    //   : new H.math.Point(22, 48);
                    break;

                case RwPinTypes.Task:
                case RwPinTypes.Site:
                    anchor = this.hidpi
                        ? new H.math.Point(12, 12)
                        : new H.math.Point(12, 12);
                    break;

                case RwPinTypes.Driver:
                    anchor = this.hidpi
                        ? new H.math.Point(16, 16)
                        : new H.math.Point(16, 16);
                    break;

                case RwPinTypes.Target:
                    anchor = this.hidpi
                        ? new H.math.Point(12, 12)
                        : new H.math.Point(12, 12);
                    break;

                default:
                    //Leave blank to use default anchor
                    break;
            }

        }
        //console.warn("getAnchorPoint", pin.title, pin.type, anchor);
        return anchor;
    }

    genericDialogReset(): void {
        theStore.dispatch("genericDialogReset");
    }

    //endregion

    isActionAvailable(action: number): boolean {
        if (!this.isDriver) return true;
        const uinfo = new RwUser();
        // console.warn('Access Type Read: ', uinfo.formatAccessType);
        if (uinfo.formatAccessType === "Restricted") {
            return false;
        } else if (uinfo.formatAccessType === "Normal") {
            if (action === this.Actions.deleteStops) return true;
            return false;
        } else if (uinfo.formatAccessType === "Dispatcher (Restricted)") {
            // const actions: number[] = [
            //     this.Actions.deleteStops,
            //     this.Actions.deleteRoutes,
            //     this.Actions.deleteSked,
            //     this.Actions.deleteFavorite
            // ];
            // if (actions.indexOf(action) === -1) return false;
            return true;
        } else {
            const actions: number[] = [this.Actions.deleteStops, this.Actions.deleteRoutes];
            if (actions.indexOf(action) === -1) return false;
            return true;
        }
    }

    //
    loadImgUrls(items) {
        items.forEach(item => this.idxURL[item.hash] = item.svg);
    }

    isValidName(v: string): boolean {
        const reg = /^[a-zA-Z0-9 ]+$/i;
        return reg.test(v) && v.length <= 50;
    }

    isAlpha(v: string): boolean {
        const alphRegex = /^([0-9a-z]+)$/i;
        return alphRegex.test(v);
    }

    isValidDomain(v: string): boolean {
        const regx = /^([a-zA-Z0-9_]+)$/i;
        // return regx.test(v) && (typeof v !== "undefined" && v.length >= 3 && v.length <= 13);
        return regx.test(v) && (typeof v !== "undefined" && v.length >= 3 && v.length <= 13);
    }

    validateAndSaveColor(color) {
        color = color.substr(0, 7);
        let retColor: string;
        const primaryColor = "#E2004F";
        const reg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/g;
        if (typeof color === "object") {
            // @ts-ignore
            if (color.hex && reg.test(color.hex)) {
                // @ts-ignore
                retColor = color.hex;
            } else {
                retColor = primaryColor;
                this.showSnack("There was an error updating the color", "error");
            }
        } else {
            if (!reg.test(color)) {
                retColor = primaryColor;
                this.showSnack("There was an error updating the color", "error");
            }
            retColor = color.substr(0, 7);
        }
        return retColor;
    }


    checkNotHandled(err) {
        return (err && !err.isHandled) || !err;
    }

    get isMobileView(): boolean {
        return theStore.getters.isMobileView;
    }

    set isMobileView(val: boolean) {
        theStore.dispatch("isMobileView", val);
    }

    get isTabletView(): boolean {
        return theStore.getters.isTabletView;
    }

    set isTabletView(val: boolean) {
        theStore.dispatch("isTabletView", val);
    }

    get recentStopAdd(): boolean {
        return store.getters.recentStopAdd;
    }

    set recentStopAdd(val: boolean) {
        store.dispatch("recentStopAdd", val).catch();
    }

    setShowOnboardTipsTimer() {
        setTimeout(() => {
            this.showOnboardAddStopArrowActive = true;
            setTimeout(() => {
                this.showOnboardAddStopArrowActive = false;
                this.showOnboardAddStopArrow = false;
                setTimeout(() => {
                    this.showOnboardUploadsPopupActive = true;
                    setTimeout(() => {
                        this.showOnboardUploadsPopup = false;
                        setTimeout(() => {
                            this.showOnboardUploadsPopupActive = false;
                        }, RwConstants.OnboardUploadsActiveTimer);
                    }, RwConstants.OnboardUploadsPopupDuration);
                }, RwConstants.OnboardUploadsPopupDelay);
            }, RwConstants.OnboardArrowDuration);
        }, RwConstants.OnboardTipsDelay);
    }

    setShowPODCallout(): void {
        if (!this.showedPODCallout && !this.showOnboardUploadsPopup) {
            setTimeout(() => this.showPODCallout = true, 10000);
        }
    }

}

export default new RwGlobals();




