import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import {RwReportInfo} from "@/app/dal/RwLog";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";

export class RwSysUtils {

    static IsValueIsDefaultOrEmpty(value: any) {
        return value === 0 || value === "" || value === null || value === undefined;
    }

    static guidNew() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static guidEmpty() {
        return RwConstants.EmptyGuid;
    }


    static arrayBufferToBase64(buffer): string {
        let binary = "";
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }


    static printStackTrace(): string {
        let err = new Error();
        let stack =
            err.stack || /*IE11*/ (console.trace ? console.trace() : "no stack info");
        if (!stack) {
            return "no stack info";
        }
        return stack;
    }

    static GUID_PATTERN_RICO = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    // static GUID_PATTERN_V1 = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    // static GUID_PATTERN_V2 = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    // static GUID_PATTERN_V3 = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    static isGuid(guid: string) {
        return this.GUID_PATTERN_RICO.test(guid);
    }

    static isGuidReal(guid: string) {
        return guid && RwSysUtils.isGuid(guid) && guid !== RwConstants.EmptyGuid;
    }


    static getModel(): string {
        const SOURCE = "RwSysUtils.getModel";

        let info = RwSysUtils.getDeviceInfo();
        let os = info.os;
        if (os) {
            os = os
                .replace("Windows", "Win")
                .replace("MacOS", "Mac")
                .replace("Unknown OS", "");
        }

        let model = `${RwConstants.flexVersion}\n${info.browser} ${info.version} ${os}`;

        // If version over 32 char, truncate and report
        if (model.length > 32) {
            let url = `reports/errors`;
            let report = new RwReportInfo();
            report.source = SOURCE;
            report.sdk = "flex";
            report.userId = RwPrefUtils.userId ?? this.guidEmpty();
            report.message = `Version over 32 chars: ${model}`;
            report.severity = 1;
            let payload = JSON.stringify(report.toJSON());

            dal.callSansToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .catch();

            model = model.substr(0, 32);
        }
        return model;
    }

    static getSDK(): string {
        return "flex";
        //return `flex ${RwConstants.flexVersion}`;
    }

    static getTZO(): number {
        let now = new Date();
        let tzo = now.getTimezoneOffset();
        //RwLog.logConsole("tzo", tzo);
        return tzo;
    }

    static getDeviceInfo() {
        let info = {
            os: "",
            browser: "",
            version: ""
        };

        let nVer = navigator.appVersion;
        let nAgt = navigator.userAgent;
        let browserName = navigator.appName;
        let fullVersion = "" + parseFloat(navigator.appVersion);
        let majorVersion = parseInt(navigator.appVersion, 10);
        let nameOffset, verOffset, ix;

        // In Opera, the true version is after "Opera" or after "Version"
        if ((verOffset = nAgt.indexOf("Opera")) != -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf("Version")) != -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome"
        else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version"
        else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) != -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox"
        else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent
        else if (
            (nameOffset = nAgt.lastIndexOf(" ") + 1) <
            (verOffset = nAgt.lastIndexOf("/"))
        ) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() == browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) != -1)
            fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) != -1)
            fullVersion = fullVersion.substring(0, ix);

        majorVersion = parseInt("" + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = "" + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }

        let OSName = "Unknown OS";
        if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
        if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
        if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
        if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

        info.browser = browserName;
        info.version = majorVersion.toString();
        info.os = OSName;

        return info;

    }


    static isLooseDeltaText(s1: string, s2: string) {
        //Treat undefined same as empty string
        let text1 = s1 == undefined ? "" : s1;
        let text2 = s2 == undefined ? "" : s2;
        let isDelta = text1 != text2;
        return isDelta;
    }


    static isLooseDeltaNum(n1: number, n2: number) {
        //Treat undefined same as empty string
        let num1 = n1 == undefined ? 0 : n1;
        let num2 = n2 == undefined ? 0 : n2;
        let isDelta = num1 != num2;
        return isDelta;
    }


}
