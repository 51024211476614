import { render, staticRenderFns } from "./RwGenericDialog.vue?vue&type=template&id=6a259dc3&"
import script from "./RwGenericDialog.vue?vue&type=script&lang=ts&"
export * from "./RwGenericDialog.vue?vue&type=script&lang=ts&"
import style0 from "./RwGenericDialog.vue?vue&type=style&index=0&id=6a259dc3&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports