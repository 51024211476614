

import {Component, Prop, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwStop} from "@/app/dem/RwStop";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";


@Component
export default class RwVisitPicker extends Vue {

  //#region Props
  @Prop(Number) readonly initVisit: number | undefined;

  //visitSpan = 0;

  get globals(): RwGlobals {
    return theGlobals;
  }

  get space(): RwPlannerSpace {
    return thePlanSpace;
  }


  get backColor() {
    return this.globals.isDarkMode ? "rwBackDark" : "rwBackLight";
  }

  get loading(): boolean {
    return this.globals.isLoading;
  }

  get isDarkMode(): boolean {
    return this.globals.isDarkMode;
  }


  //#endregion Props


  //#region User Events


  onEscape(event: Event) {
    event.stopPropagation();
    this.close();
  }


  onVisitClick() {
    let visitSpan: number = this.m_visitTime;
    //let visitSpan: number = this.visitSpan;
    //console.log("RwVisitPicker.onVisitClick visitSpan", visitSpan);
    this.close(visitSpan);
  }


  onCancel() {
    this.close();
  }


  close(visitSpan?: number) {
    if (visitSpan || visitSpan === 0) {
      //console.log("RwVisitPicker.close visitSpan", visitSpan);
      this.$emit("onVisitPick", visitSpan);
    }
    this.globals.m_showVisitPicker = false;
  }


  //#endregion UI Nav


  //region Visit Ops

  visitTrigger = 0;
  isVisitRangeValid = false;
  m_visitTimeCalc = 0;
  m_visitOver = false;
  m_visitTime = 0;
  // tabInitVisitOver = false;
  // tabInitVisitTime = 0;
  //private defaultVisitTime = 0;

  get visitText(): string {
    //Adding Observable value just for trigger;
    let vizOver = this.m_visitOver;
    let vizTime = this.m_visitTime;
    let visText = RwStop.visitTimeTextPrimitive(this.m_visitOver, this.visitTimeCalc);
    //console.warn("RwStopEdit.visitText", vizOver, vizTime, visText)
    return visText;
  }

  get canApplyVisit(): boolean {
    let isDefault = !this.m_visitOver;
    let gotRequired = !!this.m_visitTime || this.m_visitTime === 0;
    let gotValidRange = this.isVisitRangeValid;
    let gotTimeDelta = RwSysUtils.isLooseDeltaNum(this.initVisit, this.m_visitTime);
    let canApply = isDefault || (gotRequired && gotValidRange && gotTimeDelta);
    //console.log("canApplyVisit:: isDefault || (gotRequired,  gotValidRange && (gotOverDelta || gotTimeDelta))", isDefault, gotRequired, gotValidRange, (gotOverDelta || gotTimeDelta))
    return canApply;
  }


  get rules() {
    const self = this;
    return {
      required: value => {

        if (!self.m_visitOver || (!!value || value === 0)) {
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return true;
        } else {
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return false;
        }
      },
      visitRange: value => {
        if (!self.m_visitOver || value >= 0 && value <= 999) {
          self.isVisitRangeValid = true;
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return true;
        } else {
          self.isVisitRangeValid = false;
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return 'Between 0 to 999'
        }
      },
    }
  }

  get isVisitOverride(): boolean {
    return this.m_visitOver;
  }

  set isVisitOverride(val: boolean) {
    this.m_visitOver = val;
    this.m_visitTime = val ? RwPrefUtils.visitTime : -1;
    //console.log("SET isVisitOverride", val, this.m_visitOver, this.m_visitTime)
  }

  get visitTimeCalc(): number {
    let trigger1 = this.visitTrigger;
    let trigger2 = this.m_visitOver;
    this.m_visitTimeCalc = this.m_visitOver ? this.m_visitTime : RwPrefUtils.visitTime;
    //console.log("GET visitTimeCalc time, m_isOverride, m_visitTime, defaultVisitTime", this.m_visitTime, RwPrefUtils.visitTime, this.m_visitTimeCalc)
    return this.m_visitTimeCalc;
  }

  set visitTimeCalc(val: number) {
    if (this.m_visitOver) {
      if (val >= 0 && val <= 999) {
        this.m_visitTime = val;
        this.m_visitTimeCalc = val;
      } else {
        if (val < 0) {
          this.m_visitTime = 0;
          this.m_visitTimeCalc = 0;
        }
        if (val > 999) {
          this.m_visitTime = 999;
          this.m_visitTimeCalc = 999;
        }
      }
    } else {
      this.m_visitTime = -1;
    }
    this.visitTrigger++;
    //console.log("SET visitTimeCalc", val, this.m_visitOver, this.m_visitTime, this.m_visitTimeCalc)
  }

  // private onVisitClick() {
  //   this.initVisitTab()
  //   this.idxTab = 4;
  // }

  // private onCommitVisitTime(event: Event) {
  //   event.stopPropagation();
  //   if (!this.m_visitOver) {
  //     this.m_visitTime = -1;
  //   }
  //   //console.log("onCommitVisitTime SET", this.m_visitOver, this.m_visitTime);
  //   this.idxTab = 0;
  // }

  // private initVisitTab() {
  //   this.tabInitVisitOver = this.m_visitOver;
  //   this.tabInitVisitTime = this.m_visitTime;
  //   console.log("initVisitTab ", this.tabInitVisitOver, this.tabInitVisitTime)
  // }

  // private setVisitTime() {
  //   if (this.initSite) {
  //     if (this.isInitOverride) {
  //       this.m_visitOver = true;
  //       this.m_visitTime = this.initSite.visitTime;
  //       this.tabInitVisitOver = this.m_visitOver;
  //       this.tabInitVisitTime = this.m_visitTime;
  //     }
  //     else {
  //       this.m_visitOver = false;
  //       this.m_visitTime = RwPrefUtils.visitTime;
  //       this.tabInitVisitOver = this.m_visitOver;
  //       this.tabInitVisitTime = this.m_visitTime;
  //     }
  //   }
  //   console.log("setVisitTime", this.m_visitOver, this.m_visitTime)
  // }


  //endregion Visit Override


  //#region LifeCycle

  mounted() {
    //console.log('RwVisitPicker.mounted visitInit', this.visitInit);
    this.m_visitTime = this.initVisit;
    this.m_visitOver = this.initVisit >= 0;

    // if (this.initVisit && this.initVisit >= 0) {
    //   this.m_visitTime = this.initVisit;
    // }
  }

  //#endregion


}

