import {RwLog} from "@/app/dal/RwLog";

export class RwDeltas {

    adds = [];
    removes = [];
    deltas = [];

    get gotAdds(): boolean {
        return this.adds.length > 0;
    }

    get gotRemoves(): boolean {
        return this.removes.length > 0;
    }

    get gotDeltas(): boolean {
        return this.deltas.length > 0;
    }

    static getDeltas(newVals: any[], oldVals: any[], lastCheckPoint: number): RwDeltas {

        let deltas = new RwDeltas();
        let newLen = newVals ? newVals.length : 0;
        let oldLen = oldVals ? oldVals.length : 0;
        let gotNew = newLen > 0;
        let gotOld = oldLen > 0;
        //console.log("watchStops (new, old)", newLen, oldLen);

        if (gotNew && gotOld) {
            let common: any[] = newVals.filter(item => oldVals.indexOf(item) >= 0);
            deltas.adds = newVals.filter(item => oldVals.indexOf(item) === -1);
            deltas.removes = oldVals.filter(item => newVals.indexOf(item) === -1);
            deltas.deltas = common.filter(item => item.lastHash > lastCheckPoint);

            // deltas.deltas.forEach(d => {
            //   console.warn("getDeltas", `${d.title} lastHash:${d.lastHash} > ts:${lastCheckPoint} == ${d.lastHash > lastCheckPoint}`);
            // })
            //deltas.deltas = common.filter(item => item.lastUpdate > lastCheckPoint );
        } else if (gotNew) {
            deltas.adds = newVals;
        } else if (gotOld) {
            deltas.removes = oldVals;
        }
        return deltas;
    }


    static getStopDeltas(newVals: any[], oldVals: any[], lastCheckPoint: number): RwDeltas {

        let deltas = new RwDeltas();
        let newLen = newVals ? newVals.length : 0;
        let oldLen = oldVals ? oldVals.length : 0;
        let gotNew = newLen > 0;
        let gotOld = oldLen > 0;
        //console.log("watchStops (new, old)", newLen, oldLen);

        if (gotNew && gotOld) {
            let common: any[] = newVals.filter(item => oldVals.indexOf(item) >= 0);
            deltas.adds = newVals.filter(item => oldVals.indexOf(item) === -1);
            deltas.removes = oldVals.filter(item => newVals.indexOf(item) === -1);
            deltas.deltas = common.filter(item => item.lastHash > lastCheckPoint);
        } else if (gotNew) {
            deltas.adds = newVals;
        } else if (gotOld) {
            deltas.removes = oldVals;
        }
        return deltas;
    }


    print(source?: string) {
        let message = `adds:${this.adds.length}, removes:${this.removes.length}, deltas:${this.deltas.length}`;
        RwLog.consoleWarn(source, message);
    }

}

//export default new RwDeltas();
