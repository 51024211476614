import {RwUser} from "@/app/dem/RwUser";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import {RwLogLevels} from "@/app/RwEnums";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwSysUtils} from "@/app/utils/RwSysUtils";

export class RwLog {
    static get isLoggingInfos() {
        return RwConstants.LogLevel >= RwLogLevels.Info;
    }

    static get isLoggingWarns() {
        return RwConstants.LogLevel >= RwLogLevels.Warn;
    }

    static get isLoggingErrs() {
        return RwConstants.LogLevel >= RwLogLevels.Error;
    }

    static consoleLog(...optionalParams: any[]): void {
        if (RwConstants.isDevMode && RwLog.isLoggingInfos) {
            console.log(...optionalParams);
        }
    }

    static consoleWarn(...optionalParams: any[]): void {
        if (RwConstants.isDevMode && RwLog.isLoggingWarns) {
            console.warn(...optionalParams);
        }
    }

    static consoleError(...optionalParams: any[]): void {
        if (RwConstants.isDevMode && RwLog.isLoggingErrs) {
            console.error(...optionalParams);
        }
    }

    static log(source: string, message: string) {
        if (RwConstants.isDevMode) RwLog.consoleLog(source, message);
        return RwLog.report(source, message, 2);
    }

    static warn(source: string, message: string) {
        if (RwConstants.isDevMode) RwLog.consoleWarn(source, message);
        return RwLog.report(source, message, 1);
    }

    static error(source: string, message: string) {
        if (RwConstants.isDevMode) RwLog.consoleError(source, message);
        return RwLog.report(source, message, 0);
    }

    //REVIEW: Not sure if this is helping; Unexplained type errors occurring.
    //Eliminating for now; to see if type errors go away
    static getErrorFormattedXXX(err: Error, ...parseableObjects): string {

        let errorData = `Unhandled: ${err.message}
    ============
    Stack trace:
    ${err.stack || ""}`;

        for (let obj of parseableObjects) {
            let str = "";
            try {
                if (!obj.title) obj.title = "Title Missing";
                if (Object.keys(obj).indexOf("data") === -1) throw new Error("Data missing.");
                str = JSON.stringify(obj.data);
            } catch (e) {
                str = e.message;
            }
            errorData += `
      ============
      ${obj.title || ""}:
      ${str}`;
        }
        return errorData;
    }

    private static report(source: string, message: string, severity: number) {

        let uinfo = new RwUser();
        let report = new RwReportInfo();

        let userId = uinfo.userId;
        if (!RwSysUtils.isGuid(userId)) {
            userId = RwConstants.EmptyGuid;
        }

        report.source = source;
        report.message = message;
        report.severity = severity;
        report.userId = userId;
        report.deviceId = uinfo.deviceId;
        report.sdk = uinfo.platform;
        report.version = RwConstants.flexVersion;
        let payload = report.toJSON();

        let url = `${RwConstants.CoreUri}/reports/errors`;

        dal.callSansToken
            .post(url, payload, {timeout: RwConstants.NetTimeout})
            .catch(error => {
                if ((error && !error.isHandled) || !error) {
                    RwLog.consoleError("RwLog.report", `Unhandled POST ${url} \nAuthToken:${RwPrefUtils.token} \nPayload:${payload} \n\n${error.message} \n\n`);
                }
            });

    }
}


export class RwReportInfo {
    userId: string;
    version: string;
    source: string;
    sdk: string;
    severity: number;
    message: string;
    memberId: string;
    deviceId: string;

    toJSON() {
        let json = {
            userId: this.userId ?? RwConstants.EmptyGuid,
            source: this.source,
            stacktrace: this.message,
            sev: this.severity,
            sdk: this.sdk,
            version: this.version,
            memberId: this.memberId,
            device: this.deviceId
        };
        return json;
    }
}
