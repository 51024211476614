import {RwUser} from "@/app/dem/RwUser";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwLoginResponse} from "@/app/dem/Profile/RwLoginResponseModel";
import {RwLog} from "@/app/dal/RwLog";
import {RwRegInfo} from "@/app/dem/RwRegInfo";
import {RwLoginIssues} from "@/app/RwEnums";
import {RwConstants} from "@/app/RwConstants";
import {RwError, RwLoginError} from "@/app/RwErrors";
import globals from "@/app/RwGlobals";
import UnsubModel from "@/app/dem/Profile/UnsubModel";
import moment from "moment";
import dal from "@/app/dal/RwDal";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import RwHasPendingPurchaseResponse from "@/app/dem/Profile/RwHasPendingPurchaseResponse";
import {RwSkuInfo} from "@/app/dem/Profile/RwSkuInfo";
import RwTrialUpgradeResponseModel, {TrialUpgradeErrorType} from "@/app/dem/Profile/RwTrialUpgradeResponseModel";


export class RwTaskAccounts {


    static checkUserName(username: string): Promise<number> {
        //console.log(username);
        return new Promise<number>((resolve, reject) => {
            let encName = encodeURIComponent(username);
            let baseUrl = RwConstants.CoreUri;
            let url = `${baseUrl}/account/checkName?uname=${encName}`;
            //console.log(url);

            dal.addBusy();
            dal.callSansToken
                .get(url, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    dal.removeBusy();
                    resolve(res.status);
                })
                .catch(error => {
                    dal.removeBusy();
                    reject(error);
                });
        });
    }

    static login(userName: string, password: string): Promise<RwLoginResponse> {
        let self = this;
        return new Promise<RwLoginResponse>(function (resolve, reject) {
            let uinfo = new RwUser();
            uinfo.userName = userName;
            uinfo.userPass = password;
            let payload = uinfo.toJSON();
            const iid = RwPrefUtils.installId
                ? RwPrefUtils.installId
                : RwConstants.EmptyGuid;

            let url = `${RwConstants.CoreUri}/account/login?iid=${iid}`;

            dal.addBusy();
            dal.callSansToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {

                    dal.removeBusy();
                    const json = res && res.data;
                    let responseModel: RwLoginResponse = null;
                    if (json != null) {
                        responseModel = RwLoginResponse.fromJson(json);
                    }
                    resolve(responseModel);
                })
                .catch(error => {
                    dal.removeBusy();
                    let logData = error && error.response && (error.response.data as RwLoginError);
                    if (logData) {
                        reject(logData);
                    } else {
                        reject(error);
                    }
                })
                .finally(() => dal.removeBusy())

        });
    }

    static CompletePendingPurch(): Promise<RwRegInfo> {
        const SOURCE = "RwTaskAccounts.CompletePendingPurch";

        return new Promise<RwRegInfo>(function (resolve, reject) {
            RwLog.log(SOURCE, "Completing unfulfilled pending purchases");
            let url = `${RwConstants.CoreUri}/profile/completePendingPurchase`;
            dal.callWithToken
                .post(url, null, {timeout: RwConstants.ExtendedTimeout})
                .then(res => {
                    if (res.status === 200) {
                        const json = res.data;
                        let regInfo = RwRegInfo.fromJSON(json);
                        globals.isPro = true;
                        resolve(regInfo);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => {
                    if (error instanceof RwError) {
                        RwLog.consoleError("handled error");
                    } else {
                        const status = error.response ? error.response.status : null;
                        RwLog.warn(
                            SOURCE,
                            `Error:${status}, ${error.request}`
                        );
                        reject(error);
                    }
                });

        });
    }

    static HasPendingPurch(): Promise<RwHasPendingPurchaseResponse> {
        const SOURCE = "RwTaskAccounts.HasPendingPurch";
        return new Promise<RwHasPendingPurchaseResponse>(function (resolve, reject) {
            RwLog.log(SOURCE, "Checking for unfulfilled pending purchase");
            let url = `${RwConstants.CoreUri}/profile/haspendingpurchase`;
            dal.callWithToken
                .post(url, null, {timeout: RwConstants.ShortTimeout})
                .then(res => {
                    const json = res.data;
                    let hasPendingPurchResponse = json as RwHasPendingPurchaseResponse;
                    resolve(hasPendingPurchResponse);
                })
                .catch((error) => {
                    if (error instanceof RwError) {
                        RwLog.consoleError("handled error");
                    } else {
                        const status = error.response ? error.response.status : null;
                        RwLog.warn(
                            SOURCE,
                            `Error:${status}, ${error.request}`
                        );
                        reject(error);
                    }
                });

        });
    }

    static ChargifyCreateTeam(sub_id: string, cust_ref: string, tx_id: string) {
        const SOURCE = "RwTaskAccounts.ChargifyCreateTeam";

        return new Promise<RwRegInfo>((resolve, reject) => {

            if (dal.gotToken()) {

                let data = {
                    sub_id: sub_id,
                    cust_ref: cust_ref,
                    tx_id: tx_id,
                    attrib: RwPrefUtils.attribData
                };
                let payload = JSON.stringify(data);
                RwLog.log(SOURCE, "Creating account with:" + payload);
                let url = `${RwConstants.CoreUri}/profile/ChargifyCreateTeam?`;
                this.CreateTeamCallback(url, payload, resolve, reject);
                /*dal.callWithToken
                    .post(url, payload, {timeout: RwConstants.ExtendedTimeout})
                    .then(res => {
                        const json = res.data;
                        //RwLog.consoleLog("CreateTeamAccountV2", json);
                        let regInfo = RwRegInfo.fromJSON(json);
                        globals.isPro = true;
                        resolve(regInfo);
                    })
                    .catch((error) => {
                        if (error instanceof RwError) {
                            RwLog.consoleError("handled error");
                        } else {
                            //const status = error.status;
                            const status = error.response ? error.response.status : null;
                            if (status === 300) {
                                let regInfo = new RwRegInfo();
                                regInfo.paymentChanged = true;
                                resolve(regInfo);
                            } else {
                                RwLog.warn(
                                    SOURCE,
                                    `Error:${status}, ${error.request}, ${payload}`
                                );
                                reject(error);
                            }
                        }
                    });*/
            } else {
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }
        });

    }

    static ChargifyJsCreateTeam(cToken: string, firstName: string, lastName: string, phoneNumber: string, billingEmail: string, promoCode: string,
                                basePPCode: string, driverPPCode: string, teamPPCode: string) {
        const SOURCE = "RwTaskAccounts.ChargifyJsCreateTeam";

        return new Promise<RwRegInfo>((resolve, reject) => {

            if (dal.gotToken()) {

                let data = {
                    cjsToken: cToken,
                    firstName,
                    lastName,
                    phoneNumber,
                    billing_email: billingEmail,
                    couponcode: promoCode,
                    basePPCode,
                    driverPPCode,
                    teamPPCode
                };
                const payload = JSON.stringify(data);
                RwLog.log(SOURCE, "Creating account with:" + payload);
                let url = `${RwConstants.CoreUri}/profile/ChargifyJSCreateTeam?`;

                this.CreateTeamCallback(url, payload, resolve, reject);

            } else {
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }
        });

    }

    static CreateTeamCallback(url: string, payload: string, resolve, reject) {
        const SOURCE = "RwTaskAccts.CreateTeamCallback"
        dal.callWithToken
            .post(url, payload, {timeout: RwConstants.ExtendedTimeout})
            .then(res => {
                const json = res.data;
                //RwLog.consoleLog("CreateTeamAccountV2", json);
                let regInfo = RwRegInfo.fromJSON(json);
                globals.isPro = true;
                resolve(regInfo);
            })
            .catch((error) => {
                if (error instanceof RwError) {
                    RwLog.consoleError("handled error");
                    reject(error);
                } else {
                    //const status = error.status;
                    const status = error.response ? error.response.status : null;
                    if (status === 300) {
                        let regInfo = new RwRegInfo();
                        regInfo.paymentChanged = true;
                        resolve(regInfo);
                    } else {
                        RwLog.warn(
                            SOURCE,
                            `Error:${status}, ${error.request}, ${payload}`
                        );
                        reject(error);
                    }
                }
            });
    }

    static CompleteDispatcherUpgrade(
        currentPassword: string,
        name: string,
        coname: string,
        phone: string,
        address: string,
        domain: string,
        cancelCredit: boolean,
        createSoloAcct: boolean,
        soloEmail: string = ""
    ): Promise<boolean> {

        return new Promise<boolean>(function (resolve, reject) {

            let data = {
                userName: globals.userName,
                userId: globals.userId,
                currentPassword: currentPassword,
                Name: name,
                CoName: coname,
                Phone: phone,
                Address: address,
                Domain: domain,
                isUpgrade: true,
                cancelCredit: cancelCredit,
                separateSoloAccount: createSoloAcct,
                soloAccountEmail: soloEmail
            };

            // Domain can be empty for solo users
            if (data.userName && data.userId && data.currentPassword && data.Name && data.CoName &&
                data.Phone && data.Address) {
                let payload = JSON.stringify(data);

                let url = `${RwConstants.CoreUri}/profile/CompleteRegistration`;

                dal.callWithToken
                    .post(url, payload, {timeout: RwConstants.ExtendedTimeout})
                    .then(res => {
                        const json = res.data;
                        resolve(json.message);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                const err = new RwError(400, false, `Missing registration data: ${JSON.stringify(data)}`);
                reject(err);
            }
        });
    }

    static UpgradeTrialToFlex() {
        const SOURCE = "RwTaskAccounts.UpgradeTrialToFlex";

        return new Promise<TrialUpgradeErrorType>((resolve, reject) => {
            if (dal.gotToken()) {
                RwLog.log(SOURCE, "Upgrading flex trial account");
                let url = `${RwConstants.CoreUri}/profile/UpgradeTrialToFlex`;
                dal.callWithToken
                    .post(url, null, {timeout: RwConstants.ExtendedTimeout})
                    .then(res => {
                        const json = res.data;
                        const resp = <RwTrialUpgradeResponseModel>json
                        if (!resp.ErrorType) {
                            let newSkufo = new RwSkuInfo(resp.NewPurchase);
                            globals.skuInfos = [newSkufo, ...globals.skuInfos];
                            resolve(TrialUpgradeErrorType.None);
                        } else {
                            resolve(resp.ErrorType);
                        }

                    })
                    .catch((error) => {
                        if (error instanceof RwError) {
                            RwLog.consoleError("handled error");
                        } else {
                            //const status = error.status;
                            const status = error.response ? error.response.status : null;
                            RwLog.warn(
                                SOURCE,
                                `Error:${status}, ${error.request}`
                            );

                        }
                        reject(error);
                    });
            } else {
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }
        });
    }

    static CreateAccountOwner(username: string, userId: string, password: string, forceTrial: boolean = false): Promise<boolean> {
        let self = this;
        return new Promise<boolean>(function (resolve, reject) {

            let baseUrl = RwConstants.CoreUri;
            let encName = encodeURIComponent(username);
            let encPass = encodeURIComponent(password);
            let url = `${baseUrl}/profile/CreateAccountOwner?username=${encName}&userId=${userId}&password=${encPass}${forceTrial ? '&ft=true' : ''}`;

            const attrib = RwPrefUtils.attribData;

            dal.callSansToken
                .post(url, attrib, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    //console.log(`CreateAccountOwner axiosSilent`, res)
                    resolve(true);
                })
                .catch((error) => {
                    //console.warn(`CreateAccountOwner axiosSilent fail`, error)
                    const status = error.response ? error.response.status : null;
                    const respText = error.response ? error.response.data : "";
                    switch (status) {
                        case 409: {
                            resolve(false);
                            break;
                        }
                        case 400: {
                            globals.genericDialog.title = "Error registering email";
                            globals.genericDialog.text = "Error: " + respText;
                            globals.genericDialog.icon = "error";
                            globals.genericDialog.show = true;
                            break;
                        }
                        default: {
                            globals.genericDialog.title = "Problem getting validation status.";
                            globals.genericDialog.text = "Please contact Road Warrior support at <a href='mailto:support@roadwarrior.app'>support@roadwarrior.app</a>";
                            globals.genericDialog.icon = "error";
                            globals.genericDialog.show = true;
                            break;
                        }
                    }

                    reject(error);
                });

        });
    }

    static DeactivateSession(token: string): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {

            if (token && RwSysUtils.isGuidReal(token)) {
                //if (token && token.length > 0) {
                //console.warn("DeactivateSession token", token, RwPrefUtils.token);
                let url = `${RwConstants.CoreUri}/account/deactivateSession?seshId=${token}`;

                //Using callSansToken; Token may be null in PrefUtils; Sending old token within URL
                dal.callSansToken
                    .post(url, null, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        resolve(true);
                    })
                    .catch(error => {
                        //REVIEW: Log this!!!
                        reject(error);
                    });
            } else {
                RwLog.consoleWarn("DeactivateSession token", token);
            }

        });
    }

    static RenewSesh(): Promise<string> {
        const SOURCE = "RwTaskAccounts.RenewSesh";

        return new Promise<string>(function (resolve, reject) {

            let uinfo = new RwUser();
            uinfo.userName = globals.userName;
            uinfo.userPass = RwPrefUtils.userPass;
            uinfo.installId = globals.installId;

            //Short-Circuit is password is "undefined"
            if (uinfo.userPass && uinfo.userPass !== "undefined") {

                let payload = uinfo.toJSON();
                let url = `${RwConstants.CoreUri}/acct/getToken`;

                dal.callSansToken
                    .post(url, payload)
                    .then(res => {
                        RwLog.consoleLog("RenewSesh PASS", res.data);
                        let newToken = res.data as string;
                        globals.token = newToken;
                        RwPrefUtils.token = newToken;
                        globals.lastTokenMS = moment().valueOf();
                        RwLog.log(SOURCE, `newToken:${newToken}`)

                        //console.log(SOURCE, `NEW TOKEN:${RwPrefUtils.token}`)
                        //console.log(`NEW TOKEN:${RwPrefUtils.token}, tokenDOB:${globals.lastToken}, lastTokenMS:${globals.lastTokenMS}, lastTokenDOB:${globals.lastToken}`)
                        resolve(newToken);
                    })
                    .catch(err => {
                        let payloadText = JSON.stringify(payload);
                        let errText = JSON.stringify(err);
                        if (err?.message === "Request failed with status code 403") {
                            RwLog.warn(SOURCE, `Unhandled: ${url} -> ${err.status} \nPayload:${payloadText} \n\nError:\n${errText}`);
                        } else {
                            RwLog.warn(SOURCE, `Unhandled: ${url} -> ${err.status} \nPayload:${payloadText} \n\nError:\n${errText}`);
                        }
                        reject(err);
                    });
            } else {
                RwLog.warn(SOURCE, `Password == undefined`);
                reject(new RwError(403, false));
            }
        });
    }

    static SubmitPasswordReset(uid: string, resetToken: string, newPassword: string) {
        const SOURCE = "RwTaskAccounts.SubmitPasswordReset";
        const self = this;

        return new Promise((resolve, reject) => {
            let payload = {uid, resetToken, newPassword};
            dal.callSansToken
                .post(`${RwConstants.CoreUri}/account/SubmitResetPw`, payload)
                .then(res => {
                    resolve(true);
                })
                .catch(err => {
                    self.handleBasicError(err, SOURCE);
                    resolve(false);
                })
        });
    }

    static ValidateResetRequest(uid: string, token: string) {
        const SOURCE = "RwTaskAccounts.ValidateResetRequest";
        const self = this;

        let url = `${RwConstants.CoreUri}/account/ValidateResetReq?uid=${uid}&resetToken=${token}`;
        return new Promise((resolve, reject) => {
            dal.callSansToken
                .get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    self.handleBasicError(err, SOURCE);
                    resolve(false);
                })
        })
    }

    static handleBasicError(err, SOURCE: string) {
        if (err) {
            let errText = JSON.stringify(err);
            if (err.isHandled == false) {
                if (err.message === "Request failed with status code 404") {
                    RwLog.warn(SOURCE, errText);
                } else {
                    RwLog.error(SOURCE, errText);
                }
            }
        } else {
            RwLog.error(SOURCE, "Missing Error");
        }
    }

    static Unsubscribe(uid: string, group: number): Promise<void> {
        const SOURCE = "RwTaskAccounts.Unsubscribe";

        let model = {} as UnsubModel;
        model.userId = uid;
        model.group = group;
        return new Promise((resolve, reject) => {

            if (uid && uid !== RwConstants.EmptyGuid) {
                //Unsubscribe page is independent of login, uid/group should be embedded in the unsub url
                dal.callSansToken
                    .post(`${RwConstants.CoreUri}/account/Unsubscribe`, model)
                    .then(res => {
                        resolve();
                    })
                    .catch(err => {
                        if (err && !err.isHandled) {
                            let errText = JSON.stringify(err);
                            RwLog.error(SOURCE, `Unhandled: uid:${uid}, group:${group} \n\n${errText}`);
                        }
                        reject();
                    })

            } else {
                RwLog.warn(SOURCE, `Invalid uid:${uid}`);
                let logErr = new RwError(400, true, "Invalid reset link");
                return Promise.reject(logErr);
            }
        })
    }
}
