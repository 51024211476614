import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"content-class":"generic-dialog"},model:{value:(_vm.globals.genericDialog.show),callback:function ($$v) {_vm.$set(_vm.globals.genericDialog, "show", $$v)},expression:"globals.genericDialog.show"}},[_c(VCard,{staticClass:"pa-5"},[_c(VCardTitle,{staticClass:"d-flex justify-space-between mb-1 pa-2"},[_c('div',{staticStyle:{"flex":"1"}},[(_vm.globals.genericDialog.icon)?_c(VIcon,{staticStyle:{"padding-right":"10px"},attrs:{"color":_vm.iconColor,"icon":_vm.icon,"size":"35"}},[_vm._v(_vm._s(_vm.icon)+" ")]):_vm._e(),_c('span',{staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.globals.genericDialog.title))])],1),_c(VBtn,{staticClass:"px-0",attrs:{"min-width":"36px","text":""}},[_c(VIcon,{attrs:{"icon":"close"},on:{"click":_vm.close}},[_vm._v("close")])],1)],1),_c(VDivider),_c(VCardText,{staticClass:"text-left pa-1 pt-4 pa-md-4"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.globals.genericDialog.text)}},[_vm._v(" "+_vm._s(_vm.globals.genericDialog.text)+" ")])]),(!!_vm.globals.genericDialog.confirmCallback)?_c('footer',{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.confirm}},[_vm._v("Confirm")]),_c(VBtn,{attrs:{"color":"error"},on:{"click":_vm.close}},[_vm._v("Cancel")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }