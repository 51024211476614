import theStore from "@/store";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwTaskLicense} from "@/app/dal/RwTaskLicense";
import {RwSubTeam} from "@/app/dem/RwSubTeam";
import theGlobals from "@/app/RwGlobals";
import {
    CheckMigrateReturnModel,
    CreateNewSubTeamModel,
    CreateNewSubTeamReturnModel,
    SubTeamModel
} from "@/app/dem/Drivers/CreateBulkDriversResponse";
import {RwLog} from "@/app/dal/RwLog";
import GetSubTeamsResponseModel, {GetSubTeamsError} from "@/app/dem/Drivers/GetSubTeamsResponseModel";

export class RwSubTeamSpace {
    /* store */

    get subTeams(): RwSubTeam[] {
        return theStore.getters.subTeams;
    }

    set subTeams(val: RwSubTeam[]) {
        theStore.dispatch("subTeams", val).catch();
    }

    get showSubTeamDock(): boolean {
        return theStore.getters.showSubTeamDock;
    }

    set showSubTeamDock(val: boolean) {
        RwPrefUtils.showSubTeamDock = val;
        theStore.dispatch("showSubTeamDock", val).catch();
    }

    get showSubTeamPop(): boolean {
        return theStore.getters.showSubTeamPop;
    }

    set showSubTeamPop(val: boolean) {
        theStore.dispatch("showSubTeamPop", val).catch();
    }

    get selectedSubTeam(): RwSubTeam {
        return theStore.getters.selectedSubTeam;
    }

    set selectedSubTeam(val: RwSubTeam) {
        theStore.dispatch("selectedSubTeam", val).catch();
    }

    get showSubTeamEdit(): boolean {
        return theStore.getters.showSubTeamEdit;
    }

    set showSubTeamEdit(val: boolean) {
        theStore.dispatch("showSubTeamEdit", val);
    }

    get popupView(): boolean {
        return theStore.getters.popupView;
    }

    set popupView(val: boolean) {
        RwPrefUtils.subTeamPopupView = val;
        theStore.dispatch("popupView", val);
    }

    loadSubTeams() {
        this.getSubTeams()
            .then(data => this.subTeams = data as RwSubTeam[])
            .catch(err => {
                RwLog.error("RwSubTeamsList.refreshSubTeams", JSON.stringify(err));
            });
    }

    getSubTeams(): Promise<RwSubTeam[]> {
        const SOURCE = "RwSubTeamSpace.RwGetSubTeams";

        return RwTaskLicense.getSubTeams()
            .then((data: GetSubTeamsResponseModel) => {
                if (data && !data.ErrorCode && data.SubTeams) {
                    const subTeams = data.SubTeams.map((item: SubTeamModel) => {
                        const subTeam = new RwSubTeam();
                        subTeam.imageUrl = item.AvatarUrl;
                        subTeam.name = item.AccountName;
                        subTeam.dispatcher = item.LoginName;
                        subTeam.dispatcherEmail = item.UserName;
                        subTeam.domain = item.Domain;
                        subTeam.driverCount = item.DrvCount;
                        subTeam.lastUpdated = item.LastUpdated;
                        subTeam.checkins = [];
                        subTeam.routes = [];

                        subTeam.userId = item.UserId;
                        subTeam.orgId = item.OrgId;
                        subTeam.accountId = item.AccountId;
                        subTeam.avColor = item.AvColor ? item.AvColor : '#ff5963';
                        subTeam.avInits = item.AvInits ?? "";
                        subTeam.avType = item.AvType ? item.AvType : 0;

                        return subTeam;
                    });
                    return subTeams;
                } else if (data.ErrorCode === GetSubTeamsError.NoFlex) {
                    theGlobals.showSnack("Sorry, only Flex accounts can use sub teams.", "error");
                    return [];
                } else {
                    return [];
                }
            })
            .catch(err => {
                if (theGlobals.checkNotHandled(err)) {
                    const msg = `Unhandled: \n${err}`;
                    if ((err.error && (err.error.code === "ECONNABORTED" || err.error.message === "Network Error"))
                        || err.error === "Network Error") {
                        RwLog.warn(SOURCE, `Network Error: ${msg}`);
                    } else {
                        RwLog.error(SOURCE, msg);
                    }
                }
                return [];
            });
    }

    createSubTeam(subTeamData: { dispatcherName: string, name: string, domain: string, dispatcherEmail: string, dispatcherPassword: string }): Promise<CreateNewSubTeamReturnModel> {
        const createSubTeamModel = {
            DispatcherName: subTeamData.dispatcherName,
            TeamName: subTeamData.name,
            TeamDomain: subTeamData.domain,
            DispatcherEmail: subTeamData.dispatcherEmail,
            DispatcherPassword: subTeamData.dispatcherPassword
        } as CreateNewSubTeamModel;
        return RwTaskLicense.createNewSubTeam(createSubTeamModel)
            .then((model: CreateNewSubTeamReturnModel) => model);
    }

    saveSubTeam(subTeam: RwSubTeam): Promise<boolean> {
        const model = {
            UserId: subTeam.userId,
            AccountId: subTeam.accountId,
            OrgId: subTeam.orgId,
            UserName: subTeam.dispatcherEmail,
            LoginName: subTeam.dispatcher,
            AccountName: subTeam.name,
            DrvCount: subTeam.driverCount,
            Domain: subTeam.domain,
            LastUpdated: new Date(),
            AvatarUrl: subTeam.imageUrl
        } as SubTeamModel;
        return RwTaskLicense.updateSubTeam(model);
    }

    deleteSubTeam(orgId: string) {
        return RwTaskLicense.deleteSubTeam(orgId);
    }

    checkMigrate(dispatcher: string, password: string): Promise<CheckMigrateReturnModel> {
        return RwTaskLicense.checkMigrateTeam(dispatcher, password);
    }

    migrate(dispatcher: string, token: string): Promise<boolean> {
        return RwTaskLicense.migrateTeam(dispatcher, token);
    }
}

export default new RwSubTeamSpace();