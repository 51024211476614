import {SubTeamModel} from "@/app/dem/Drivers/CreateBulkDriversResponse";

export default interface GetSubTeamsResponseModel {
    SubTeams: Array<SubTeamModel>;
    ErrorCode: GetSubTeamsError;
}

export enum GetSubTeamsError {
    None = 0,
    NoFlex
}