import {RwRoute} from "@/app/dem/RwRoute";
import {SubTeamModel} from "@/app/dem/Drivers/CreateBulkDriversResponse";

export class RwSubTeam {
    imageUrl: string;
    name: string;
    dispatcher: string;
    dispatcherEmail: string;
    domain: string;
    driverCount: number;
    lastUpdated: Date;
    checkins: [];
    routes: RwRoute[];
    userId: string;
    orgId: string;
    accountId: string;
    avInits: string;
    avColor: string;
    avType: number;

    static fromSubTeamModel(model: SubTeamModel) {
        const subTeam = new RwSubTeam();
        // console.warn("fromSubTeamModel name: ", subTeam.name);
        // console.warn("fromSubTeamModel driverCount: ", subTeam.driverCount);
        subTeam.userId = model.UserId;
        subTeam.accountId = model.AccountId;
        subTeam.orgId = model.OrgId;
        subTeam.dispatcherEmail = model.UserName;
        subTeam.dispatcher = model.LoginName;
        subTeam.name = model.AccountName;
        subTeam.driverCount = model.DrvCount;
        subTeam.domain = model.Domain;
        subTeam.lastUpdated = model.LastUpdated;
        subTeam.imageUrl = model.AvatarUrl;
        subTeam.avColor = model.AvColor ? model.AvColor : '#ff5963';
        subTeam.avInits = model.AvInits ?? "";
        subTeam.avType = model.AvType ? model.AvType : 0;
        return subTeam;
    }

}
