import moment from "moment";
import {RwTimeWindow} from "@/app/dem/RwTimeWindow";
import {RwSite} from "@/app/dem/RwSite";
import {RwRoute} from "@/app/dem/RwRoute";
import {RwSked} from "@/app/dem/RwSked";
import {RwHistory} from "@/app/dem/RwHistory";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwImgUtils} from "@/app/utils/RwImgUtils";
import {RwDistUnits, RwPortType, RwSkedTypes, RwStopTypes, RwStopVios, StopStatus} from "@/app/RwEnums";
import {RwLog} from "../dal/RwLog";
import theGlobals, {RwGlobals} from "@/app/RwGlobals"
import thePlan from "@/app/views/planner/RwPlannerSpace"
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace"
import {RwTextUtils} from '../utils/RwTextUtils';
import {RwPin} from "@/app/dem/RwPin";
import {RwTaskStops} from "@/app/dal/RwTaskStops";
import {statusNames} from "@/app/utils/StopStatusStrings";
import {RwStopAddOns} from "@/app/dem/RwStopAddOns";

export class RwStop {

    //#region Constructors

    static fromJsonArray(jsonArray: Object) {
        let stops: RwStop[] = [];
        if (jsonArray != null) {
            for (let key in jsonArray) {
                let val = jsonArray[key];
                let stop = new RwStop(val);
                stops.push(stop);
            }
        }
        return stops;
    }

    static fromJsonArrayMerge(jsonArray: object) {
        //this is being added so that the stops received in the array from route merge can be properly retrieved
        let stops: RwStop[] = [];
        if (jsonArray != null) {
            for (let key in jsonArray) {
                if (key == "Stops") {
                    let val = jsonArray[key];
                    val.forEach(stop => stops.push(new RwStop(stop)));
                }
            }
        }
        return stops
    }

    static fromJson(json: JSON) {
        let stop: RwStop;
        if (json) {
            stop = new RwStop(json);
        }
        return stop;
    }

    static fromSite(site: RwSite) {
        let stop: RwStop = null;
        if (site != null) {
            stop = new RwStop();
            stop.stopId = RwSysUtils.guidNew();
            stop.name = site.name;
            stop.lat = site.lat;
            stop.lng = site.lng;
            stop.address = site.address;
            stop.phone = site.phone;
            stop.visitTime = site.visitTime;
            stop.note = site.note;
            stop.siteId = site.siteId;
            stop.overSvcTime = site.overSvcTime;
            stop.email = site.email;
            //stop.site = site;
            // = site.navLng;
            stop.nameId = site.nameId;
            if (site.baseColor) {
                stop.baseColor = site.baseColor;
            } else {
                stop.baseColor = RwImgUtils.getHexColorFromPriority(site.priority);
            }
        }
        return stop;
    }

    constructor(json?) {
        if (json != null) {
            this.fromJson(json);
        } else {
            this.stopId = RwSysUtils.guidNew();
        }
    }

    //#endregion Constructors


    //#region Props

    markedForDelete = false;
    stopId: string;
    routeId: string;
    siteId: string;
    histId: string;
    skedId: string;
    name: string;
    type: RwStopTypes;
    priority: number = 0.5;
    visitTime: number = 0;
    status: number = 0;
    lat: number = 0;
    lng: number = 0;
    seq: number = 0;
    timeOfArr: Date;
    timeOfDep: Date;
    timeToDrive: number = 0;
    timeToIdle: number = 0;
    distToDrive: number = 0;
    violations: number = 0;
    isComplete: boolean = false;
    note: string;
    phone: string;
    address: string;
    dropStopId: string;
    drops: string;
    overSvcTime: boolean = false;
    email: string;
    checkDate: Date;
    navLat: number;
    navLng: number;
    portType: RwPortType;
    nameId: number;
    openTime: number;
    closeTime: number;
    baseColor: string = "#E91E63";
    isTask: boolean = false;
    timeWindows: RwTimeWindow[];
    isActive = false;
    colorGroup: number = -1;
    isSelectedItem: boolean = false;

    //isAvailCached = true;
    //availCacheTime: number = 0;
    checkInCacheTime = 0;
    checkInCached: RwHistory = null;

    addOns: RwStopAddOns = {};

    //isSelected: boolean = false;
    _lastHash: number = 0;

    get lastHash(): number {
        return this._lastHash
    }

    set lastHash(val: number) {
        //console.warn("SET lastHash")
        this._lastHash = val
    }


    get globals(): RwGlobals {
        return theGlobals;
    }

    get plan(): RwPlannerSpace {
        return thePlan;
    }

    get stopStatus(): StopStatus {
        return this.status ? this.status as StopStatus : StopStatus.NotCompleted;
    }

    get stopStatusString(): string {
        return statusNames[this.stopStatus];
    }

    get timeText(): string {
        let displayText = "";
        if (this.isActive && this.timeOfArr != null && this.timeOfDep != null) {
            let arrText = moment(this.timeOfArr).format("LT");
            let depText = moment(this.timeOfDep).format("LT");
            displayText = `${arrText} - ${depText}`;
        }
        return displayText;
    }

    _dataUrl: string = undefined;
    get dataUrl(): string {
        const self = this;
        let pin = this.isTask ? RwPin.fromTask(this) : RwPin.fromStop(this);
        if (pin) {
            let url = thePlanSpace.globals.idxURL[pin.urlHash];
            if (url && url.length > 0) {
                self._dataUrl = url;
            } else {
                pin.dataUrlAsync()
                    .then(dataUrl => {
                        self._dataUrl = dataUrl;
                        //console.warn("RwStop.dataUrl updated", this._dataUrl);
                    })
            }
        }
        return this._dataUrl;
    }


    get skedText(): string {
        //console.log("skedText", this.isActive, this.isScheduled, this.openTime, this.closeTime);
        let text = "";
        if (this.isScheduled) {
            if (this.isActive) {
                text = RwTextUtils.scheduledTextMins(this.openTime, this.closeTime);
            } else {
                text = "time frame: " + RwTextUtils.scheduledTextMins(this.openTime, this.closeTime);
            }
        }
        return text;
    }

    get visitTimeText(): string {
        //console.log("visitTimeText", this.visitTime);
        return RwStop.visitTimeText(this, this.visitTime);
    }


    static visitTimeText(stop: RwStop, visitTime: number): string {
        let displayText = "";
        //console.log("visitTimeText", stop.visitTime);
        let isOverride = false;
        let effVisitTime = visitTime;

        if (visitTime >= 0) {
            isOverride = true;
        } else {
            if (stop && stop.siteId) {
                let site = stop.getSite();
                effVisitTime = site ? site.getVisitTime() : RwPrefUtils.visitTime;
            } else {
                effVisitTime = RwPrefUtils.visitTime;
            }
        }
        displayText = RwStop.visitTimeTextPrimitive(isOverride, effVisitTime)
        return displayText;
    }


    static visitTimeTextPrimitive(isOverride: boolean, visitTime: number): string {
        let displayText = (isOverride) ? `override: ${visitTime}m` : `default: ${visitTime}m`;
        return displayText;
    }


    // //REVIEW: this should not be displayed as Visit Time;

    //   else {
    //     if (stop.isComplete) {
    //       if (stop.checkDate) {
    //         displayText = moment(stop.checkDate).format("L LT");
    //       }
    //       let checkIn = stop.getLastCheckIn();
    //       if (checkIn != null) {
    //         displayText = moment(checkIn.startTime).format("L LT");
    //       }
    //     }
    //   }
    //   return displayText;
    // }


    get violationText(): string {
        let text = "";

        //RwLog.warnConsole('violationText', this.name, this.violations);

        if (this.violations != 0 && this.isComplete == false) {
            let absIdle: number = Math.abs(Math.round(this.timeToIdle / 60));
            let minsIdle = absIdle.toFixed(0).toString();
            switch (this.violations) {
                case RwStopVios.Early:
                    text = `Early: ${minsIdle} mins`;
                    break;

                case RwStopVios.Late:
                    text = `Late: ${minsIdle} mins`;
                    break;

                case RwStopVios.Closed:
                    text = this.closedText;
                    break;

                case RwStopVios.Unreachable:
                    text = "Unreachable";
                    break;

                case RwStopVios.Overtime:
                    text = "Overtime";
                    break;
            }
        }

        return text;
    }

    get closedText(): string {
        let text = "";
        if (this.violations == 4) {
            let hasSchedule = this.getSked() != null;
            text = hasSchedule ? "Conflict" : "Closed";
        }
        return text;
    }

    get travelText(): string {
        let displayText = "";
        if (this.isActive) {
            let unit = RwPrefUtils.distUnits;
            let distText = "";
            if (unit == RwDistUnits.Kilometers) {
                let dist = (this.distToDrive / 1000).toFixed(1);
                distText = `${dist} km`;
                //RwLog.logConsole("metaText ", unit, dist, distText);
            } else {
                let dist = (this.distToDrive / 1609).toFixed(1);
                distText = `${dist} mi`;
                //RwLog.logConsole("metaText ", unit, dist, distText);
            }

            //var durationSecs = <any>moment.duration(this.timeToDrive, "seconds");
            //let timeText = durationSecs.format("d[days] h [hrs], m [min]");

            let timeText = RwTextUtils.formatDuration(this.timeToDrive);

            //return duration.format( "d[days] h [hrs], m [min]" );
            //let timeText = this.timeToDrive;

            //displayText = `Drive ${distText} in ${timeText}`;

            displayText = `drive ${distText} in ${timeText}`;
        }
        return displayText;
    }

    get pinId(): string {
        let pinId = this.stopId;
        return pinId;
    }

    get isRouted() {
        let isRouted = this.seq > 0 && this.seq <= 240 && this.isActive;
        //console.log("isRouted", this.name, this.seq, this.isActive, isRouted);
        return isRouted;
    }

    //#endregion Props


    //#region Calc Prop Candidates

    getSite(): RwSite {
        let foundSite = null;
        if (this.siteId != null) {
            foundSite = theGlobals.findSite(this.siteId);
        }
        return foundSite;
    }


    get isScheduled(): boolean {

        let isScheduled = false;
        let gotInlineSked = this.gotInlineSked();
        if (gotInlineSked) {
            isScheduled = true;
            //console.log("RwStop.isScheduled openTime, closeTime", this.openTime, this.closeTime);
        } else {
            if (this.skedId) {
                let sked = this.globals.findSked(this.skedId);
                if (sked) {
                    //Update to inline-sked
                    isScheduled = true;
                    this.openTime = sked.timeStart / 60000;
                    this.closeTime = sked.timeFinish / 60000;
                    //console.log("RwStop.isScheduled SKED openTime, closeTime", sked.timeStart, sked.timeFinish, this.openTime, this.closeTime);
                }
            }
        }

        //console.log("isScheduled", isScheduled, this.openTime, this.closeTime);
        return isScheduled;
    }


    get isSited(): boolean {
        let isSited = false;
        //console.log("RwStop.isSited", stop.name, this.siteId);
        if (this.siteId && this.siteId.length > 0) {
            let site = this.globals.sites.find(site => site.siteId === this.siteId);
            if (site) {
                isSited = true;
            } else {
                //This naturally occurs when a favorite is deleted; Just gracefully treat as if there is no siteId
                //RwLog.consoleError("RwStop.isStopSited", `WTF: did not find site by id: ${this.siteId}`);
            }
        }
        return isSited;
    }

    getSked(): RwSked {
        let sked: RwSked;
        let skedId = this.skedId;
        if (skedId) {
            sked = this.globals.findSked(skedId);
        }

        if (!sked && this.gotInlineSked()) {
            sked = new RwSked();
            sked.priority = this.priority;
            sked.timeStart = this.openTime * 60000;
            sked.timeFinish = this.closeTime * 60000;
            // console.warn("RwStop.getSked inline", this.openTime, this.closeTime, sked.timeStart, sked.timeFinish);
            //console.log("RwStop.getSked sked INLINE", this.name, this.priority, this.openTime, this.closeTime);
        }
        return sked;
    }

    getIsAvailable(sked: RwSked): boolean {
        let isAvail = false;
        if (sked) {
            //console.log("getIsAvailable");
            let route = this.plan.activeRoute;
            if (route != null) {
                let start = route.startTime;
                let finish = route.finishTimeAdjDur();
                isAvail = this.isAvailable(sked, start, finish);
            }
        } else {
            let site = this.getSite();
            if (site == null) {
                isAvail = true;
            } else {
                let route = this.plan.activeRoute;
                if (route != null) {
                    let startTime = route.startTime;
                    let finishTime = route.finishTimeAdjDur();
                    isAvail = site.isAvailable(startTime, finishTime);
                }
            }

        }
        return isAvail;
    }


    getDrop(): RwStop {
        let dropStop: RwStop;
        if (this.dropStopId != null) {
            let route = this.plan.activeRoute;
            if (route != null) {
                dropStop = route.findStop(this.dropStopId);
            }
        }
        return dropStop;
    }

    getLastCheckIn(): RwHistory {
        let self = this;
        let lastCheck: RwHistory = null;

        if (self.checkInCached != null) {
            let now = Date.now();
            if (now - this.checkInCacheTime < 60 * 1000) {
                lastCheck = this.checkInCached;
            } else {
                //expired
                self.checkInCached = null;
            }
        }

        if (lastCheck == null) {
            //let driver = RwApp.model.driver;
            let acts = theGlobals.getHistoryForStop(this.stopId);
            if (acts != null && acts.length > 0) {
                acts.forEach(act => {
                    if (lastCheck == null) {
                        lastCheck = act;
                    } else {
                        lastCheck = act;
                        self.checkInCacheTime = Date.now();
                    }
                });
            }
        }
        return lastCheck;
    }

    gotInlineSked(): boolean {
        //DEFER: consider if presence of SkedId should be interpreted as non inline
        //return this.openTime && this.openTime !== this.closeTime;

        let gotSked = (this.openTime || this.closeTime) && (this.openTime > 0 || this.closeTime > 0);
        if (gotSked) {
            let isValid = this.openTime <= 1440 && this.closeTime <= 1440;
            if (isValid) {
                gotSked = (this.openTime > 0 || this.closeTime > 0);
            } else {
                this.openTime = 0;
                this.closeTime = 0;
                RwLog.warn("RwStop.gotInlineSked", `TIME OOB: openTime:${this.openTime}, closeTime:${this.closeTime}`);
            }
        }
        return gotSked;
    }


    gotViolations(isAvailable: boolean): boolean {
        let gotVios = false;
        let vios = this.violations;
        if (vios === 0) {
            if (!isAvailable) {
                gotVios = true
            }
        } else {
            gotVios = true;
        }
        return gotVios;
    }


    getViolations(isAvailable: boolean): number {
        let vios = this.violations;
        if (vios === 0) {
            if (!isAvailable) {
                vios = 4;
                // if (this.isAvailCached) {
                //   this.isAvailCached = false;
                // }
            }
        }
        return vios;
    }

    //#endregion Calc Prop Candidates


    copy(newRouteId: string): RwStop {
        let newStop = new RwStop(this.toJSON());
        newStop.routeId = newRouteId;
        newStop.stopId = RwSysUtils.guidNew();
        newStop.isComplete = false;
        newStop.timeOfArr = null;
        newStop.timeOfDep = null;
        newStop.timeToDrive = 0;
        newStop.timeToIdle = 0;
        newStop.distToDrive = 0;
        newStop.histId = null;
        newStop.addOns = newStop.addOns ?? {};

        // Check if schedule is one time, remove if true
        if (newStop.skedId) {
            let sched = newStop.getSked();
            if (sched && sched.type === RwSkedTypes.AdHoc) {
                newStop.skedId = null;
            }
        }

        return newStop;
    }

    fromStop(otherStop: RwStop) {

        this.stopId = otherStop.stopId;
        this.routeId = otherStop.routeId;
        this.siteId = otherStop.siteId;
        this.histId = otherStop.histId;
        this.skedId = otherStop.skedId;
        this.name = otherStop.name;
        this.lat = otherStop.lat;
        this.lng = otherStop.lng;
        this.seq = otherStop.seq;
        this.status = otherStop.status;
        this.type = otherStop.type;
        this.visitTime = otherStop.visitTime;
        this.timeOfArr = otherStop.timeOfArr;
        this.timeOfDep = otherStop.timeOfDep;
        this.timeToDrive = otherStop.timeToDrive;
        this.timeToIdle = otherStop.timeToIdle;
        this.distToDrive = otherStop.distToDrive;
        this.violations = otherStop.violations;
        //console.log("RwStop.fromStop", "isComplete", this.isComplete, otherStop.isComplete);
        this.isComplete = otherStop.isComplete;
        this.note = otherStop.note;
        this.phone = otherStop.phone;
        this.address = otherStop.address;
        this.dropStopId = otherStop.dropStopId;
        this.drops = otherStop.drops;
        this.email = otherStop.email;
        this.overSvcTime = otherStop.overSvcTime;
        this.checkDate = otherStop.checkDate;
        this.navLat = otherStop.navLat;
        this.navLng = otherStop.navLng;
        this.portType = otherStop.portType;
        this.nameId = otherStop.nameId;
        this.baseColor = otherStop.baseColor;
        this.closeTime = otherStop.closeTime;
        this.openTime = otherStop.openTime;
        this.addOns = {};
        if (otherStop.addOns && Object.keys(otherStop.addOns).length) {
            Object.keys(otherStop.addOns).forEach(key => this.addOns[key] = otherStop.addOns[key]);
        }

        this.syncDropProps();
        this.lastHash = Date.now();
        //this.dropCheck("clone");
    }

    fromJson(json: JSON) {


        for (let key in json) {
            switch (key) {
                case "d":
                    this.markedForDelete = true;
                    break;
                case "sid":
                    this.stopId = json[key];
                    break;
                case "rid":
                    this.routeId = json[key];
                    break;
                case "lid":
                    this.siteId = json[key];
                    break;
                case "aid":
                    this.histId = json[key];
                    break;
                case "prid":
                    this.skedId = json[key];
                    break;
                case "sub":
                    this.name = json[key];
                    break;
                case "lat":
                    this.lat = json[key];
                    break;
                case "lon":
                    this.lng = json[key];
                    break;
                case "seq":
                    this.seq = json[key];
                    break;
                case "sts":
                    this.status = json[key];
                    break;
                case "stt":
                    this.type = json[key];
                    break;
                case "dur":
                    this.visitTime = json[key];
                    break;
                case "toa":
                    this.timeOfArr = new Date(json[key]);
                    break;
                case "tod":
                    this.timeOfDep = new Date(json[key]);
                    break;
                case "t2d":
                    this.timeToDrive = json[key];
                    break;
                case "t2i":
                    this.timeToIdle = json[key];
                    break;
                case "d2d":
                    this.distToDrive = json[key];
                    break;
                case "vio":
                    this.violations = json[key];
                    break;
                case "cmplt":
                    this.isComplete = json[key];
                    break;
                case "note":
                    this.note = json[key];
                    break;
                case "phn":
                    this.phone = json[key];
                    break;
                case "add":
                    this.address = json[key];
                    break;
                case "drp":
                    this.dropStopId = json[key];
                    break;
                case "drops":
                    this.drops = json[key];
                    break;
                case "email":
                    this.email = json[key];
                    break;
                case "ost":
                    this.overSvcTime = json[key];
                    break;
                case "cdate":
                    this.checkDate = new Date(json[key]);
                    break;
                case "pri":
                    this.priority = json[key];
                    break;
                case "navLat":
                    this.navLat = json[key];
                    break;
                case "navLng":
                    this.navLng = json[key];
                    break;
                case "pt":
                    this.portType = json[key];
                    break;
                case "nid":
                    this.nameId = json[key];
                    break;
                case "str":
                    this.openTime = json[key];
                    break;
                case "stc":
                    this.closeTime = json[key];
                    break;
                case "bc":
                    this.baseColor = json[key];
                    break;
                case "addOns":
                    this.addOns = json[key];
                    break;
            }
        }

        // if(this.openTime > 0 || this.closeTime > 0){
        //   console.log("Stop.fromJson openTime, closeTime", this.openTime, this.closeTime)
        // }


        this.syncDropProps();
        //this.dropCheck("fromJSON");
    }

    toJSON(isOptimize: boolean = false, forCheckIn: boolean = false) {

        //xFOCUS: toJSON:  JSON.stringify(stop);???
        //console.log("toJSON called");

        let json = {
            rid: this.routeId,
            sid: this.stopId,
            lat: this.lat,
            lon: this.lng,
            sub: this.name,
            stt: this.type,
            sts: this.status,
            pri: this.priority,
            dur: this.visitTime,
            seq: this.seq,
            t2d: this.timeToDrive,
            t2i: this.timeToIdle,
            d2d: this.distToDrive,
            vio: this.violations,
            cmplt: this.isComplete,
            active: this.isActive,
            bc: this.baseColor
        };

        if (isOptimize) {
            let isMagical = this.visitTime === -1;
            // If default
            if (isMagical) {
                // If stop backed by site, try site svc time
                if (this.siteId) {
                    let site = this.getSite();
                    if (site && site.visitTime !== -1) {
                        (<any>json).est = site.visitTime;
                    } else {
                        (<any>json).est = RwPrefUtils.visitTime;
                    }
                } else {
                    (<any>json).est = RwPrefUtils.visitTime;
                }
            } else {
                (<any>json).est = this.visitTime;
            }
        }

        //add optional values
        if (this.overSvcTime) json["ost"] = this.overSvcTime;
        if (this.timeOfArr) json["toa"] = this.timeOfArr.getTime();
        if (this.timeOfDep) json["tod"] = this.timeOfDep.getTime();
        if (this.checkDate) json["cdate"] = this.checkDate.getTime();
        if (this.email && this.email.length > 0) json["email"] = this.email;
        if (this.siteId && this.siteId.length > 0) json["lid"] = this.siteId;
        if (this.histId && this.histId.length > 0) json["aid"] = this.histId;
        if (this.skedId && this.skedId.length > 0) json["prid"] = this.skedId;
        if (this.dropStopId && this.dropStopId.length > 0) json["drp"] = this.dropStopId;
        if (this.dropStopId && this.dropStopId.length > 0) json["drops"] = this.drops;
        if (this.note && this.note.length > 0 && !forCheckIn) json["note"] = this.note;
        if (this.phone && this.phone.length > 0) json["phn"] = this.phone;
        if (this.address && this.address.length > 0) json["add"] = this.address;
        if (this.addOns && Object.keys(this.addOns).length) json["addOns"] = this.addOns;

        if (this.timeWindows != null) {
            let jwindows = [];
            for (let key in this.timeWindows) {
                let tr = this.timeWindows[key];
                let jwindow = tr.toJSON();
                jwindows.push(jwindow);
                //println("Restriction: \(interval.description)")
            }
            json["twr"] = jwindows;

            if (!this.skedId && !this.gotInlineSked() && this.siteId) {
                json["pri"] = 1;
            }
        } else if (this.gotInlineSked() || this.skedId) {
            json["pri"] = this.priority;
        }

        this.syncDropProps();
        //this.dropCheck("toJSON");


        if (json.stt === null) this.type = RwStopTypes.Unappointed;

        // RCK: This seem invalid.  Not sure we should ever set to "undefined" in JSON?
        if (json["toa"] === null) json["toa"] = undefined;
        if (json["tod"] === null) json["tod"] = undefined;

        if (this.portType) json["pt"] = this.portType;
        if (this.navLat) json["navLat"] = this.navLat;
        if (this.navLng) json["navLng"] = this.navLng;
        if (this.nameId) json["nid"] = this.nameId;
        if (this.portType) json["pt"] = this.portType;
        if (this.baseColor) json["bc"] = this.baseColor;
        if (this.openTime && this.openTime >= 0) json["str"] = this.openTime;
        if (this.closeTime && this.closeTime >= 0) json["stc"] = this.closeTime;

        return json;
    }

    isMarkedFirst(route: RwRoute): boolean {
        let isFirst = false;
        let id = route.firstStopId;
        if (id != null) {
            if (this.stopId == id) {
                isFirst = true;
            }
        }
        return isFirst;
    }

    isMarkedFinal(route: RwRoute): boolean {
        let isFinal = false;
        let id = route.finalStopId;
        if (id != null) {
            if (this.stopId == id) {
                isFinal = true;
            }
        }
        return isFinal;
    }


    isAvailable(sked: RwSked, timeStart: Date, timeEnd: Date): boolean {
        let isAvail = true;
        if (sked != null) {
            //console.log("sked.name", sked.name, "sked.type", sked.type, sked);
            let wins = this.getTimeWindows(sked, timeStart, timeEnd);
            if (wins != null && wins.length > 0) {
                //console.log("windows", windows);
                isAvail = true;
            } else {
                isAvail = false;
                //console.warn("NO Match windows", windows);
            }
        }
        //console.log("isAvail", isAvail);
        return isAvail;
    }


    getIsActivePin(isAvailable: boolean, isComplete: boolean, violations: number): boolean {
        let isActive = true;
        if (violations == 8 || isComplete || !isAvailable) {
            isActive = false;
        }
        this.isActive = isActive;

        return isActive;
    }

    getTimeWindows(sked: RwSked, timeStart: Date, timeFinish: Date): RwTimeWindow[] {
        let windows: RwTimeWindow[] = null;
        if (sked != null) {
            let routeStart = timeStart.getTime();
            let routeFinish = timeFinish.getTime();
            //console.log("route start, end", timeStart, timeFinish);
            //console.log(sked.name, sked.dowSet, sked.type);

            let timeWindows = sked.project(timeStart);
            if (timeWindows != null) {
                timeWindows.forEach(win => {
                    let winStart = win.start.getTime();
                    let winFinish = win.end.getTime();
                    //console.log("Route.[start, finish], WIN [start, finish]", routeStart, routeFinish, win.start, win.end);

                    let isFullyContained = winStart >= routeStart && winFinish <= routeFinish;
                    let isLeftClipped = winStart <= routeStart && winFinish >= routeStart;
                    let isRightClipped = winStart <= routeFinish && winFinish >= routeFinish;

                    if (isFullyContained || isLeftClipped || isRightClipped) {
                        // if (isFullyContained) console.log("isFullyContained");
                        // if (isLeftClipped) console.log("isLeftClipped");
                        // if (isRightClipped) console.log("isRightClipped");
                        // console.log("getTimeWindows HIT", win.toString());
                        // console.log("getTimeWindows timeStart", timeStart, "timeEnd", timeEnd);
                        if (windows == null) {
                            windows = [];
                        }
                        windows.push(win);
                    } else {
                        //console.log("getTimeWindows MISS", win.toString());
                    }
                });
            }
        } else {
            //console.warn("getTimeWindows No TimeWindows");
        }

        return windows;
    }

    clearSchedule() {
        this.skedId = undefined;
        this.openTime = 0;
        this.closeTime = 0;
    }

    isIntersection(timeStart: Date, timeFinish: Date, openDT: Date, closeDT: Date): boolean {
        // For later sked updates
        let isIntersect = false;
        let openMS = openDT.getTime();
        let closeMS = closeDT.getTime();
        let startMS = timeStart.getTime();
        let finishMS = timeFinish.getTime();

        let isFullyContained = openMS >= startMS && closeMS <= finishMS;
        let isLeftClipped = openMS <= startMS && closeMS >= startMS;
        let isRightClipped = openMS <= finishMS && closeMS >= finishMS;

        if (isFullyContained || isLeftClipped || isRightClipped) {
            isIntersect = true;
        }
        return isIntersect;
    }

    removeSchedule() {
        this.skedId = undefined;
        this.openTime = undefined;
        this.closeTime = undefined;
    }


    //#region Drops Rework

    syncDropProps() {
        let isUnconverted = !this.drops && this.dropStopId;
        if (isUnconverted) {
            this.drops = this.dropStopId;
        }
    }

    addDrop(dropId: string) {
        if (!dropId) {
            return;
        }

        //Legacy Id
        this.dropStopId = dropId;

        //TODO: Uncomment to allow for multiple drops, for now only use single drops to avoid issues
        this.drops = dropId;
        /*if (!this.drops) {
          this.drops = dropId;
        }
        else {
          let dropIds = this.getDropIds();
          if (dropIds && dropIds.length > 0) {
            if (this.containsDrop(dropIds, dropId) === false) {
              var dropString = dropId;
              dropIds.forEach(did => {
                dropString += ";" + did;
              });
              this.drops = dropString;
            }
          }
          else {
            this.drops = dropId;
          }
        }*/
        const SOURCE = "RwStop.addDrop";
        RwTaskStops.updateStop(this)
            .then(deltas => {
                this.globals.mergeDeltas(deltas);
            }).catch(err => {
            if (this.globals.checkNotHandled(err)) {
                RwLog.error(SOURCE, JSON.stringify(err));
            }
        })
    }

    removeDrop(dropId: string) {
        if (!dropId) {
            return;
        }

        //Legacy Id
        this.dropStopId = null;

        this.drops = null;
        //TODO: Uncomment to allow for multiple drops, for now only use single drops to avoid issues
        /* if (this.drops == dropId) {
           this.drops = null;
         }
         else {
           let dropIds = this.getDropIds();
           if (dropIds && dropIds.length > 0) {
             if (this.containsDrop(dropIds, dropId)) {
               var dropString = "";

               dropIds.forEach(priorDropId => {
                 if (priorDropId !== dropId) {
                   if (dropString !== "") {
                     dropString += ";" + priorDropId;
                   }
                   else {
                     dropString = priorDropId;
                   }
                 }
               });

               this.drops = dropString;
             }
           }
           else {
             this.drops = null;
           }
         }*/
        const SOURCE = "RwStop.removeDrop";
        return RwTaskStops.updateStop(this)
            .then(deltas => {
                this.globals.mergeDeltas(deltas);
            })
            .catch(err => {
                if (this.globals.checkNotHandled(err)) {
                    RwLog.error(SOURCE, JSON.stringify(err));
                }
            })
    }

    containsDrop(dropIds: string[], dropId: string): boolean {
        let isContained = false;
        if (dropIds) {
            dropIds.forEach(did => {
                if (did == dropId) {
                    isContained = true;
                }
            });
        }
        return isContained;
    }

    clearDrops() {
        this.drops = null;
        this.dropStopId = null;
    }

    getDropIds(): string[] {
        let odropStops: string[];
        let drps = this.drops;

        if (drps) {
            let trimmedString = drps.replace(" ", "");
            let parts = trimmedString.split(";");
            if (parts.length > 0) {
                odropStops = parts;
            }
        }

        return odropStops;
    }

    getDrops(): RwStop[] {
        let returnDrops: RwStop[] = [];
        let parsed = this.getDropIds();
        if (parsed && parsed.length > 0) {
            let activeRt = this.plan.activeRoute;
            if (activeRt) {
                let dropStops: RwStop[] = new Array<RwStop>();

                parsed.forEach(dropId => {
                    let dropStop = activeRt.findStop(dropId);
                    if (dropStop) {
                        dropStops.push(dropStop);
                    }
                });

                returnDrops = dropStops;
            }
        }
        return returnDrops;
    }

    hasDrops(): boolean {
        let hasDrop = false;
        let gottenDrops = this.getDrops();
        if (gottenDrops && gottenDrops.length > 0) {
            hasDrop = true;
        }
        return hasDrop;
    }

    dropCheck(source: string) {
        //check that these are in sync
        let dropsText = this.drops;
        let dropStopText = this.dropStopId;
        if (dropsText && dropStopText) {
            let inSync = dropsText.toLowerCase() === dropStopText.toLowerCase();
            if (inSync == false) {
                RwLog.consoleWarn(
                    `dropCheck(${source}): OUT OF SYNC, drops:${dropsText}, dropStopId:${dropStopText} `
                );
            }
        }
    }

    //#endregion Drops Rework


    toString() {
        return this.toJSON.toString();
    }


}