import {RwHistory} from "@/app/dem/RwHistory";

const dialogModule = {
    state: {
        showHistoryPop: false,
        showHistoryEditPop: false,
        selectedHistoryItem: null
    },
    getters: {
        showHistoryPop(state): boolean {
            return state.showHistoryPop;
        },

        showHistoryEditPop(state): boolean {
            return state.showHistoryEditPop;
        },
        selectedHistoryItem(state): RwHistory {
            return state.selectedHistoryItem;
        },
    },
    actions: {
        showHistoryPop({state, commit}, val: boolean) {
            commit("SHOW_HISTORY_POP", val);
        },

        showHistoryEditPop({state, commit}, val: boolean) {
            commit("SHOW_HISTORY_EDIT_POP", val);
        },
        selectedHistoryItem({state, commit}, val: boolean) {
            commit("SELECTED_HISTORY_ITEM", val);
        },
    },
    mutations: {
        SHOW_HISTORY_POP(state, val: boolean) {
            state.showHistoryPop = val;
        },

        SHOW_HISTORY_EDIT_POP(state, val: boolean) {
            state.showHistoryEditPop = val;
        },

        SELECTED_HISTORY_ITEM(state, val: boolean) {
            state.selectedHistoryItem = val;
        },
    }
};

export default dialogModule;