//module utils {
export class RwWebUtils {
    static GetHeaders(): Headers {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        //myHeaders.append("charset", "utf-8");
        //myHeaders.append("platform", "Web");

        return myHeaders;
    }

    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    static debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }


    static encode(parmText: string) {
        let parmEncoded: string = "";
        if (parmText) {
            parmEncoded = encodeURIComponent(parmText)
            //let baseEnc = baseColor ? encodeURIComponent(baseColor) : "";
        }

        return parmEncoded;
    }


}
