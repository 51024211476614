import {RwConstants} from "@/app/RwConstants";

const purchModule = {
    state: {
        pricePerBaseAmt: RwConstants.PPB,
        pricePerDriverAmt: RwConstants.PPD,
        pricePerTeamAmt: RwConstants.PPT
    },
    getters: {
        pricePerBaseAmt(state): number {
            return state.pricePerBaseAmt;
        },
        pricePerDriverAmt(state): number {
            return state.pricePerDriverAmt;
        },
        pricePerTeamAmt(state): number {
            return state.pricePerTeamAmt;
        },
    },
    actions: {
        pricePerBaseAmt({state, commit}, val: number) {
            commit("PRICE_PER_BASE_AMT", val);
        },
        pricePerDriverAmt({state, commit}, val: number) {
            commit("PRICE_PER_DRIVER_AMT", val);
        },
        pricePerTeamAmt({state, commit}, val: number) {
            commit("PRICE_PER_TEAM_AMT", val);
        },
    },
    mutations: {
        PRICE_PER_BASE_AMT(state, val: number) {
            state.pricePerBaseAmt = val;
        },
        PRICE_PER_DRIVER_AMT(state, val: number) {
            state.pricePerDriverAmt = val;
        },
        PRICE_PER_TEAM_AMT(state, val: number) {
            state.pricePerTeamAmt = val;
        },
    }
};

export default purchModule;