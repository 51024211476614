import moment from "moment";
import theGlobals from "@/app/RwGlobals"
import {RwLog} from "@/app/dal/RwLog";
import {RwUser} from "@/app/dem/RwUser";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwDistUnits, RwHistTypes} from "@/app/RwEnums";
import {RwConstants} from "@/app/RwConstants";
import {RwDateUtils} from "@/app/utils/RwDateUtils";
import {RwCoreMedia} from "@/app/dem/ProofOfDelivery/RwCoreMedia";
import {ConfirmationType} from "@/app/dem/ProofOfDelivery/ProofOfDeliveryEnums";

export class RwHistory {
    isDeleted = false;

    histId: string;
    siteId: string;
    stopId: string;
    accountId: string;
    driverId: string;
    name: string;
    priority: number;
    status: number;
    startTime: Date;
    type: number;
    note: string;
    address: string;
    lat: number;
    lng: number;
    time: number;
    dist: number;
    isArchived: boolean;
    //photo: string; // Base64? Url?
    photoLink: string;
    BaseColor: string;
    routeName: string;
    isSelectedItem: boolean = false;
    lastHash: number = 0;
    media: RwCoreMedia[];

    clone(): RwHistory {
        let clonedAct = new RwHistory(this.toJSON());
        clonedAct.isSelectedItem = this.isSelectedItem;
        return clonedAct;
    }

    get startEpoc(): number {
        return this.startTime.getTime();
    }

    get formattedDist(): string {
        if (this.dist) {
            return RwPrefUtils.distUnits === RwDistUnits.Kilometers
                ? Math.round((this.dist / 1000) * 10) / 10 + " km."
                : Math.round(this.dist * 0.000621371 * 10) / 10 + " mi.";
        }
        return "";
    }

    get formattedTime(): string {
        if (this.time) {

            // let duration = moment.duration(this.time, "seconds");
            // console.log("formattedTime duration", duration);
            // let humanizeText = duration.humanize();
            //console.log("formattedTime humanizeText", humanizeText);
            let dateText = this.formattedStartTime;
            //console.log("formattedTime dateText", dateText);

            return dateText;


            // return `${this.formattedStartTime} (${moment
            //   .duration(this.time, "seconds")
            //   .humanize()})`;
        }

        return this.formattedStartTime;
    }

    get driverShortString(): string {
        if (this.driverId && this.driverId !== RwUser.NoDriverId) {
            let driver = theGlobals.findDriver(this.driverId);
            if (driver) {
                return driver.userName;
            }
        }

        return RwConstants.EmptyString;
    }

    get formattedDuration(): string {
        if (this.time) {
            return `${moment.duration(this.time, "seconds").humanize()}`;
        }
        return "";
    }

    get formattedStartTime(): string {
        return RwDateUtils.formatDate(this.startTime)
        //return RwDateUtils.formatDateToLocal(this.startTime)
    }

    get formattedType(): string {
        switch (this.type) {
            case RwHistTypes.CheckIn:
                return "Check-In";

            case RwHistTypes.CheckOut:
                return "Check-Out";

            case RwHistTypes.Nav:
                return "Navigation";

            case RwHistTypes.Phone:
                return "Phone";

            case RwHistTypes.Text:
                return "Text";
        }

        return "";
    }

    get typeColor(): string {
        if (this.type === RwHistTypes.CheckIn) {
            return "checkin";
        }
        return "";
    }

    private _driverName: string = null;
    private _driverId: string = null;

    public get driverName(): string {
        let driverText: string = "";
        if (this._driverName && this._driverId === this.driverId) {
            driverText = this._driverName;
            //console.log("driverName cached", this.driverId, driverText);
        } else {
            const gotDriverId = this.driverId && this.driverId !== RwUser.NoDriverId;
            const gotDrivers = theGlobals.drivers && theGlobals.drivers.length > 0;
            if (gotDriverId && gotDrivers) {
                let driver = theGlobals.findDriver(this.driverId);
                if (driver) {
                    driverText = driver.userName;
                    this._driverName = driver.userName;
                    this._driverId = driver.userId;
                    //console.log("driverName", this.driverId, driverText);
                }
            }

        }
        return driverText;
    }

    public getMedia(confirmationType: ConfirmationType): RwCoreMedia {
        return this.media?.find(media => media.confType === confirmationType && !media.del);
    }

    public getPhotos(): RwCoreMedia[] {
        return this.media?.filter(media => media.confType === ConfirmationType.Photo && !media.del);
    }

    public getPhoto(index: number): RwCoreMedia {
        if (this.getPhotos() && this.getPhotos().length > index) {
            return this.getPhotos()[index];
        } else {
            return undefined;
        }
    }

    public getFirstPhoto(): RwCoreMedia | undefined {
        if (this.getPhotos() && this.getPhotos().length) {
            return this.getPhotos()[0];
        } else {
            return undefined;
        }
    }

    // get typeIcon(): string {
    //   switch (this.type) {
    //     case RwHistTypes.CheckIn:
    //     case RwHistTypes.CheckOut:
    //       if (this.BaseColor) {
    //         let args = { typ: RwPinTypes.Stop, sub:RwPinSubTypesStop.CheckIn, color: this.BaseColor };
    //         //xTODO: Convert to use SvgUrl
    //         return RwPin.getPinRemoteUrl(args);
    //       }
    //       else {
    //         let args = { typ: RwPinTypes.Stop, sub:RwPinSubTypesStop.CheckIn, color: this.BaseColor };
    //         //xTODO: Convert to use SvgUrl
    //         return RwPin.getPinRemoteUrl(args);
    //       }
    //
    //     case RwHistTypes.Nav:
    //       return "/images/icons/navIcon.png";
    //
    //     case RwHistTypes.Phone:
    //       return "/images/icons/phoneIcon.png";
    //
    //     case RwHistTypes.Text:
    //       return "/images/icons/msgIcon.png";
    //   }
    //
    //   return "";
    // }

    // get hasNoteVisible(): string {
    //   if (this.note && this.note !== "") {
    //     return "";
    //   }
    //   return "display-gone";
    // }

    constructor(json?) {
        if (json != null) {
            this.fromJson(json);
        }
    }

    static fromJsonArray(jsonArray: Object[]) {
        let users: RwHistory[] = [];
        if (jsonArray != null) {
            jsonArray.forEach(function (jval) {
                let user = new RwHistory(jval);
                users.push(user);
            });
        }
        return users;
    }

    fromJson(json: Object) {
        for (let key in json) {
            switch (key) {
                //@formatter:off
                case "d":
                    this.isDeleted = true;
                    break;
                case "aid":
                    this.histId = json[key];
                    break;
                case "uid":
                    this.accountId = json[key];
                    break;
                case "lid":
                    this.siteId = json[key];
                    break;
                case "sid":
                    this.stopId = json[key];
                    break;
                case "sub":
                    this.name = json[key];
                    break;
                case "pri":
                    this.priority = json[key];
                    break;
                case "as":
                    this.status = json[key];
                    break;
                case "tos":
                    this.startTime = new Date(json[key]);
                    break;
                case "atyp":
                    this.type = json[key];
                    break;
                case "note":
                    this.note = json[key];
                    break;
                case "did":
                    this.driverId = json[key];
                    break;
                case "add":
                    this.address = json[key];
                    break;
                case "lat":
                    this.lat = json[key];
                    break;
                case "lng":
                    this.lng = json[key];
                    break;
                case "time":
                    this.time = json[key];
                    break;
                case "dist":
                    this.dist = json[key];
                    break;
                case "plink":
                    this.photoLink = json[key];
                    break;
                case "isArch":
                    this.isArchived = json[key];
                    break;
                case "rnm":
                    this.routeName = json[key];
                    break;
                case "bc":
                    this.BaseColor = json[key];
                    break;
                case "media":
                    this.media = json[key];
                    break;
                //@formatter:on
            }
        }
    }

    fromHistory(hist: RwHistory, copyUids: boolean = false) {
        if (copyUids) {
            // Copy unique ids: helpful for copying and editing an item
            this.histId = hist.histId;
            this.accountId = hist.accountId;
        }
        this.siteId = hist.siteId;
        this.stopId = hist.stopId;
        this.name = hist.name;
        this.priority = hist.priority;
        this.status = hist.status;
        this.startTime = hist.startTime;
        this.type = hist.type;
        this.note = hist.note;
        this.driverId = hist.driverId;
        this.address = hist.address;
        this.lat = hist.lat;
        this.lng = hist.lng;
        this.time = hist.time;
        this.dist = hist.dist;
        this.photoLink = hist.photoLink;
        this.isArchived = hist.isArchived;
        this.BaseColor = hist.BaseColor;
        this.lastHash = Date.now();
        this.media = [];
        if (theGlobals.showProofOfDelivery) {
            hist.media.forEach(media => this.media.push({...media}));
        }
    }

    toJSON() {
        let json = {
            aid: this.histId,
            uid: this.accountId,
            sub: this.name,
            pri: this.priority,
            as: this.status,
            atyp: this.type,
            tos: this.startTime.getTime(),
            add: this.address,
            lat: this.lat,
            lng: this.lng,
            time: this.time,
            dist: this.dist,
            plink: this.photoLink,
            isArch: this.isArchived,
            media: this.media
        };


        if (this.note != null) json["note"] = this.note;
        if (this.siteId != null) json["lid"] = this.siteId;
        if (this.stopId != null) json["sid"] = this.stopId;
        if (this.driverId && this.driverId !== RwUser.NoDriverId) json["did"] = this.driverId;
        if (this.BaseColor) json["bc"] = this.BaseColor;

        if (!this.accountId || this.accountId.length === 0) {
            let acctId = RwPrefUtils.accountId;
            RwLog.error("RwHistory.toJSON", `missing accountId (uid) ${json}, Prefs.accountId:${acctId}`);
        }

        return json;
    }

    toString() {
        return this.toJSON().toString();
    }
}
