import {RwLogEntry} from "@/app/dem/RwLogEntry";
import {RwUserInstalls, RwUserSessions} from "@/app/dem/RwUserEntry";

const uploadModule = {
    state: {
        errorLogEntries: [],
        agentLogEntries: [],
        diagLogEntries: [],
        userInstalls: [],
        userSessions: [],

    },
    getters: {

        errorLogEntries(state): RwLogEntry[] {
            return state.errorLogEntries;
        },
        diagLogEntries(state): RwLogEntry[] {
            return state.diagLogEntries;
        },
        agentLogEntries(state): RwLogEntry[] {
            return state.agentLogEntries;
        },
        userInstalls(state): RwUserInstalls[] {
            return state.userInstalls;
        },
        userSessions(state): RwUserSessions[] {
            return state.userSessions;
        },

    },
    actions: {

        errorLogEntries({state, commit}, val: RwLogEntry[]) {
            commit("ERROR_LOG_ENTRIES", val);
        },
        diagLogEntries({state, commit}, val: RwLogEntry[]) {
            commit("DIAG_LOG_ENTRIES", val);
        },
        agentLogEntries({state, commit}, val: RwLogEntry[]) {
            commit("AGENT_LOG_ENTRIES", val);
        },
        userInstalls({state, commit}, val: RwUserInstalls[]) {
            commit("USER_INSTALLS", val);
        },
        userSessions({state, commit}, val: RwUserInstalls[]) {
            commit("USER_SESSIONS", val);
        },


    },
    mutations: {

        ERROR_LOG_ENTRIES(state, val: RwLogEntry[]) {
            state.errorLogEntries = val;
        },
        DIAG_LOG_ENTRIES(state, val: RwLogEntry[]) {
            state.diagLogEntries = val;
        },
        AGENT_LOG_ENTRIES(state, val: RwLogEntry[]) {
            state.agentLogEntries = val;
        },
        USER_INSTALLS(state, val: RwUserInstalls[]) {
            state.userInstalls = val;
        },
        USER_SESSIONS(state, val: RwUserSessions[]) {
            state.userSessions = val;
        },

    }
};

export default uploadModule;