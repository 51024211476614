
import {Component, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";

@Component({})
export default class RwFlexOptInPostMessage extends Vue {

  get globals(): RwGlobals {
    return theGlobals;
  }

  close() {
    this.globals.showOptInPostMessage = false;
  }
}
