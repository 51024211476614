export class RwTimeWindow {
    start: Date;
    end: Date;

    constructor(startTime: Date, endTime: Date) {
        this.start = startTime;
        this.end = endTime;
    }

    fromJson(json: Object) {
        for (var key in json) {
            switch (key) {
                case "tos":
                    this.start = new Date(json[key]);
                    break;
                case "toe":
                    this.end = new json[key]();
                    break;
            }
        }
    }

    toJSON() {
        let json = {
            tos: this.start.getTime(),
            toe: this.end.getTime()
        };
        return json;
    }

    toString() {
        return `RwTimeWindow ${this.start} - ${this.end}`;
    }
}

// func toTimeInterval() -> NSTimeInterval {
//     let startSecs = start.timeIntervalSince1970
//     let endSecs = end.timeIntervalSince1970
//     let duration = endSecs - startSecs
//     return duration
// }
