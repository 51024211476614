import store from "@/app/RwGlobals";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwConstants} from "@/app/RwConstants";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwDateUtils} from "@/app/utils/RwDateUtils";
import {RwLog} from "@/app/dal/RwLog";


export class RwUser {


    //region Constructors

    constructor() {
        if (store.isLoggedIn) {
            this.userId = RwPrefUtils.userId;
            this.userName = RwPrefUtils.userName;
            this.name = RwPrefUtils.dispName;
            this.orgId = RwPrefUtils.orgId;
            this.token = RwPrefUtils.token;
            this.accountId = RwPrefUtils.accountId;
            this.deviceId = RwPrefUtils.deviceId;
            this.installId = RwPrefUtils.installId;
            this.accessType = RwPrefUtils.userAccessType;
        }

        this.langCode = navigator.language;
        this.platform = "flex";
        this.Model = RwSysUtils.getModel();
        this.sdk = RwSysUtils.getSDK();
        this.tzo = RwSysUtils.getTZO();
        this.lat = 0;
        this.lng = 0;
        const currDate = new Date();
        this.version = RwConstants.flexVersion === "FLEX_DEBUG" ?
            `${currDate.getFullYear()}.${(currDate.getMonth() + 1).toString().padStart(2, '0')}.${(currDate.getDay() + 1).toString().padStart(2, '0')}0`
            : RwConstants.flexVersion;
    }

    static get DispatcherDriver(): RwUser {
        let dispDriver = new RwUser();
        dispDriver.name = "Dispatcher";
        dispDriver.userName = store.userName;
        dispDriver.userId = store.userId;
        return dispDriver;
    }

    static fromJsonArray(jsonArray: Object[]) {
        let drivers: RwUser[] = [];
        if (jsonArray != null) {
            jsonArray.forEach(function (jval) {
                let driver = RwUser.fromJson(jval);
                drivers.push(driver);
            });
        }
        return drivers;
    }

    static fromJson(json: Object): RwUser {
        let user: RwUser;
        if (json != null) {
            user = new RwUser();
            user.accessType = 0;
            user.fromJson(json)
        }
        return user;
    }

    clone(): RwUser {
        //return new RwDriver(this.toJSON());
        return RwUser.fromJson(this.toJSON());
    }


    //endregion Constructors


    //region Props

    static NoDriverId = RwConstants.EmptyGuid;

    userId: string;
    userName: string;
    userPass: string;
    token: string;
    lat: number;
    lng: number;
    langCode: string;
    platform: string;
    userType: number = 0;
    Model: string;
    sdk: string;
    version: string;
    installId: string;
    deviceId: string;
    name: string;
    orgId: string;
    accountId: string;
    tzo: number;
    markedForDelete = false;
    accessType: number = 0;
    email: string;
    lastUpdated: Date;
    created: Date;
    coordLU: Date;
    status: number = 0;
    imageUrl: string;
    loginType: number;
    isSelectedItem: boolean = false;
    avType: number = 0;
    avInits: string;
    avColor: string;
    avUrl: string;
    lastHash: number = 0;
    openIds: string[];


    get formatDisplayName(): string {
        if (this.name) {
            if (this.name === this.userName) {
                return this.userName;
            } else {
                return `${this.name} (${this.userName})`;
            }
        } else {
            return this.userName;
        }
    }

    get formatListName(): string {
        if (this.name && this.name !== "") {
            if (this.name === this.userName) {
                return this.userName;
            } else {
                return `${this.name}`;
            }
        } else {
            return this.userName;
        }
    }

    get formatAccessType(): string {
        //console.warn ('Format AccessType sees: ', this.accessType);
        let accessText = "Restricted";
        switch (this.accessType) {
            //@formatter:off
            case 0:
                accessText = "Restricted";
                break;
            case 1:
                accessText = "Normal";
                break;
            case 2:
                accessText = "Elevated";
                break;
            case 3:
                accessText = "Dispatcher (Restricted)";
                break;
            //@formatter:on
        }
        return accessText
    }

    get formatLastUpdated(): string {
        return RwDateUtils.formatDate(this.lastUpdated);
    }

    //endregion Props


    toJSON() {
        let json = {};

        if (this.lastUpdated) {
            json["lu"] = this.lastUpdated;
        }
        if (this.lat) {
            json["lat"] = this.lat;
        }
        if (this.lng) {
            json["lng"] = this.lng;
        }
        if (this.userType) {
            json["utp"] = this.userType;
        }

        json["atp"] = this.accessType ?? 0;

        if (this.email) {
            json["em"] = this.email;
        }
        if (this.loginType) {
            json["ltp"] = this.loginType;
        }
        if (this.userId) {
            json["uid"] = this.userId;
        }
        if (this.userName) {
            json["un"] = this.userName;
        }
        if (this.userName) {
            json["uname"] = this.userName;
        }
        if (this.userName) {
            json["userName"] = this.userName;
        }
        if (this.userPass) {
            json["upass"] = this.userPass;
        }
        if (this.langCode) {
            json["ap_lang"] = this.langCode;
        }
        if (this.token) {
            json["tkn"] = this.token;
        }
        if (this.platform) {
            json["platform"] = this.platform;
        }
        if (this.Model) {
            json["model"] = this.Model;
        }
        if (this.sdk) {
            json["sdk"] = this.sdk;
        }
        if (this.deviceId) {
            json["device"] = this.deviceId;
        }
        if (this.installId) {
            json["installId"] = this.installId;
        }
        if (this.name) {
            json["nm"] = this.name;
        }
        if (this.orgId) {
            json["oid"] = this.orgId;
        }
        if (this.accountId) {
            json["aid"] = this.accountId;
        }
        if (this.avType) {
            json["avType"] = this.avType;
        }
        if (this.avUrl) {
            json["avUrl"] = this.avUrl;
        }
        if (this.avColor) {
            json["avColor"] = this.avColor;
        }
        if (this.avInits) {
            json["avInits"] = this.avInits;
        }
        if (this.openIds) {
            json["openIds"] = this.openIds;
        }

        //TODO: Re-add once we properly handle ConfirmSwaps on server
        /*if (this.version) {
          json["cver"] = this.version;
        }*/

        //if (this.imgSrc) {json["imgSrc"] = this.imgSrc;}
        //json["name"] = this.name;

        return json;
    }


    fromJson(json: Object) {
        const SOURCE = "RwUser.fromJson"
        for (let key in json) {
            switch (key) {
                //@formatter:off
                case "d":
                    this.markedForDelete = true;
                    break;
                case "uid":
                case "userId":
                    this.userId = json[key];
                    break;
                case "aid":
                case "acctId":
                    this.accountId = json[key];
                    break;
                case "oid":
                    this.orgId = json[key];
                    break;
                case "un":
                case "uname":
                case "userName":
                    this.userName = json[key];
                    break;
                case "ULU":
                    this.lastUpdated = json[key];
                    break;
                case "nm":
                case "name":
                    this.name = json[key];
                    break;
                case "lat":
                    this.lat = json[key];
                    break;
                case "lng":
                case "lon":
                    this.lng = json[key];
                    break;
                case "utp":
                case "roleType":
                    this.userType = json[key];
                    break;
                case "atp":
                case "accessType":
                    this.accessType = json[key];
                    break;
                case "em":
                    this.email = json[key];
                    break;
                case "avUrl":
                    this.imageUrl = json[key];
                    break;
                case "avColor":
                    this.avColor = json[key];
                    break;
                case "avInits":
                    this.avInits = json[key];
                    break;
                case "avType":
                    this.avType = json[key];
                    break;
                case "ltp":
                case "loginType":
                    this.loginType = json[key];
                    break;
                case "clu":
                    if (json[key] !== 0) this.coordLU = json[key];
                    break;
                case "cver":
                    this.version = json[key];
                    break;
                default:
                    //console.warn(`json prop not found: ${key}:${json[key]}`);
                    break;
                //@formatter:on
            }

            // if(!this.orgId){
            //   RwLog.error(SOURCE, `orgId:${this.orgId}, userId:${this.userId}, userName:${this.userName},
            //     accessType:${this.accessType}, \n\n json:${JSON.stringify(json)}\n\n stack:${(new Error()).stack}`)
            // }

            //console.log(SOURCE, `name:${this.name}, userName:${this.userName}, accessType:${this.accessType}`)
        }


        if (!this.orgId) {
            RwLog.error(SOURCE, `orgId:${this.orgId}, userId:${this.userId}, userName:${this.userName}, 
          accessType:${this.accessType}, \n\n json:${JSON.stringify(json)}\n\n stack:${(new Error()).stack}`)
        }

        this.lastHash = Date.now();
    }


    fromDriver(driver: RwUser) {
        this.fromJson(driver.toJSON());
    }

    toString() {
        return this.toJSON.toString();
    }
}

export class RwCreateDriver {
    userType: number = 1;
    access: number = 1;
    userName: string;
    email: string;
    name: string;
    password: string;
    key: number;
}


export class RwUserInfoExtra {
    UserId: string;
    Created: Date;
    Updated: Date;
}


export class RwAvatarInfo {
    userId: string;
    avType: number = 0;
    avInits: string;
    avColor: string;
    avUrl: string;

    static fromJson(json: JSON) {
        let avInfo = new RwAvatarInfo();
        for (let key in json) {
            let val = json[key];
            switch (key) {
                //@formatter:off
                case "uid":
                    avInfo.userId = val;
                    break;
                case "avUrl":
                    avInfo.avUrl = val;
                    break;
                case "avColor":
                    avInfo.avColor = val;
                    break;
                case "avInits":
                    avInfo.avInits = val;
                    break;
                case "avType":
                    avInfo.avType = val;
                    break;
                default:
                    break;
                //@formatter:on
            }
        }

        return avInfo;
    }

}


//region Unused

// isDefined<T>(value: T | undefined | null): value is T {
//   return <T>value !== undefined && <T>value !== null;
// }

// get deletedClass(): string {
//   if (this.status === 1) {
//     return "deleted";
//   }
//   return "";
// }
//
// get formatStatus(): string {
//   switch (this.status) {
//     case 0:
//       return "Active";
//     case 1:
//       return "Deleted";
//     default:
//       return "Unknown";
//   }
// }

// get formatAccessType(): string {
//   //console.warn ('Format AccessType sees: ', this.accessType);
//   switch (this.accessType) {
//     case DriverPermissionType.RestrictedDriver:
//       return "Restricted Driver";
//
//     case DriverPermissionType.Driver:
//       return "Driver";
//
//     case DriverPermissionType.ElevatedDriver:
//       return "Elevated Driver";
//
//     case DriverPermissionType.MasterDriver:
//       return "Master Driver";
//   }
//   return "Unknown";
// }


// get formatCoordLastUpdated(): string {
//   //console.warn(this.coordLU);
//   return RwDateUtils.formatDate(this.coordLU);
//   //return RwDateUtils.formatDateToLocal(this.coordLU);
//   // let utcTime = moment.utc(this.coordLU);
//   // let offset = moment().utcOffset();
//   // let localTime = moment.utc(utcTime).utcOffset(offset);
//   // if (localTime.isSame(moment(), "d")) {
//   //   return localTime.format("LT");
//   // }
//   // return localTime.format("L LT");
// }
//
//

//
//
// get formatLastUpdatedCoord(): string {
//   if (!this.coordLU) {
//     return "";
//   }
//   return RwDateUtils.formatDate(this.coordLU);
//   //return  RwDateUtils.formatDateToLocal(this.coordLU);
//   // var localTime = moment(this.coordLU);
//   // if (localTime.isSame(moment(), "d")) {
//   //   return localTime.format("LT");
//   // }
//   // return localTime.format("L LT");
// }

//endregion Unused
