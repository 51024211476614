import {RwLog} from "@/app/dal/RwLog";


export class RwImgUtils {
    static validatedColorHex(color: string): string {
        let validatedColor: string = undefined;
        //console.log("RwColorPicker.onColorPick color", color);

        if (color.length > 9 && color.includes("\"hex\":")) {
            RwLog.error("RwImgUtils.validatedColorHex", color);
            let colorObject = JSON.parse(color);
            if (colorObject.hex) {
                validatedColor = colorObject.hex;
            }
        }

        if (color.length === 9 && color.endsWith("FF")) {
            validatedColor = color.substring(0, 7);
        } else if (color.length === 7) {
            validatedColor = color;
        }

        return validatedColor;
    }

    static map: { [hexColor: string]: string };

    static getHexColorFromPriority(priority: number): string {
        let hue: number = parseFloat((200 - 200 * priority).toString());
        hue = Math.min(hue, 200);
        hue = Math.max(hue, 0);
        let hex = this.rgbFromHSV(hue, 0.75, 0.8);
        return hex;
    }

    static getGreyScaleHex(greyScale: number): string {
        let byteVal = Math.round(greyScale * 255);
        let hex = byteVal.toString(16);
        if (hex.length % 2) {
            hex = "0" + hex;
        }
        return `#${hex}${hex}${hex}`;
    }


    static lightenDarkenColor(hexColor: string, amt: number): string {
        let usePound = false;
        if (hexColor[0] == "#") {
            hexColor = hexColor.slice(1);
            usePound = true;
        }

        let num = parseInt(hexColor, 16);
        let r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        let b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        let g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        //let hex = (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16)
        //let hex = "000000" + (g | (b << 8) | (r << 16)).toString(16); return (usePound?"#":"") + string.substr(string.length-6);

        let rx = r.toString(16).padStart(2, '0');
        let gx = b.toString(16).padStart(2, '0');
        let bx = g.toString(16).padStart(2, '0');
        let hex = (usePound ? "#" : "") + rx + gx + bx;

        //console.log("rbg", hex);
        return hex;
    }


    static rgbFromHSV(h, s, v) {
        /**
         * I: An array of three elements hue (h) ∈ [0, 360], and saturation (s) and value (v) which are ∈ [0, 1]
         * O: An array of red (r), green (g), blue (b), all ∈ [0, 255]
         * Derived from https://en.wikipedia.org/wiki/HSL_and_HSV
         * This stackexchange was the clearest derivation I found to reimplement https://cs.stackexchange.com/questions/64549/convert-hsv-to-rgb-colors
         */

        let hprime = h / 60;
        const c = v * s;
        const x = c * (1 - Math.abs((hprime % 2) - 1));
        const m = v - c;
        let r, g, b;
        if (!hprime) {
            r = 0;
            g = 0;
            b = 0;
        }
        if (hprime >= 0 && hprime < 1) {
            r = c;
            g = x;
            b = 0;
        }
        if (hprime >= 1 && hprime < 2) {
            r = x;
            g = c;
            b = 0;
        }
        if (hprime >= 2 && hprime < 3) {
            r = 0;
            g = c;
            b = x;
        }
        if (hprime >= 3 && hprime < 4) {
            r = 0;
            g = x;
            b = c;
        }
        if (hprime >= 4 && hprime < 5) {
            r = x;
            g = 0;
            b = c;
        }
        if (hprime >= 5 && hprime < 6) {
            r = c;
            g = 0;
            b = x;
        }

        r = Math.round((r + m) * 255);
        g = Math.round((g + m) * 255);
        b = Math.round((b + m) * 255);

        var hexR = r.toString(16);
        if (hexR.length % 2) {
            hexR = "0" + hexR;
        }
        var hexG = g.toString(16);
        if (hexG.length % 2) {
            hexG = "0" + hexG;
        }
        var hexB = b.toString(16);
        if (hexB.length % 2) {
            hexB = "0" + hexB;
        }
        return `#${hexR}${hexG}${hexB}`;
    }


    static HSVtoRGB(h, s, v) {
        let r, g, b, i, f, p, q, t;
        if (arguments.length === 1) {
            (s = h.s), (v = h.v), (h = h.h);
        }
        i = Math.floor(h * 6);
        f = h * 6 - i;
        p = v * (1 - s);
        q = v * (1 - f * s);
        t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0:
                (r = v), (g = t), (b = p);
                break;
            case 1:
                (r = q), (g = v), (b = p);
                break;
            case 2:
                (r = p), (g = v), (b = t);
                break;
            case 3:
                (r = p), (g = q), (b = v);
                break;
            case 4:
                (r = t), (g = p), (b = v);
                break;
            case 5:
                (r = v), (g = p), (b = q);
                break;
        }

        var newR = Math.round(r * 255);
        var newG = Math.round(g * 255);
        var newB = Math.round(b * 255);

        var hexR = newR.toString(16);
        if (hexR.length % 2) {
            hexR = "0" + hexR;
        }
        var hexG = newG.toString(16);
        if (hexG.length % 2) {
            hexG = "0" + hexG;
        }
        var hexB = newB.toString(16);
        if (hexB.length % 2) {
            hexB = "0" + hexB;
        }
        return `#${hexR}${hexG}${hexB}`;
    }


    static getNamedColorFromHex(hexColor: string): string {
        if (!hexColor) {
            return null;
        }

        if (!RwImgUtils.map) {
            RwImgUtils.map = {};
            RwImgUtils.map["#E2004F"] = "Red";
            RwImgUtils.map["#ED2B2A"] = "Red";
            RwImgUtils.map["#ED2B2E"] = "Red";
            RwImgUtils.map["#DE0051"] = "Pink";
            RwImgUtils.map["#F012BE"] = "Fuschia";
            RwImgUtils.map["#8801A0"] = "Purple";
            RwImgUtils.map["#5321A6"] = "Deep Purple";

            RwImgUtils.map["#303AA5"] = "Indigo";
            RwImgUtils.map["#1E81EE"] = "Blue";
            RwImgUtils.map["#1497F0"] = "Light Blue";
            RwImgUtils.map["#19ADC9"] = "Cyan";
            RwImgUtils.map["#118675"] = "Teal";

            RwImgUtils.map["#40A23F"] = "Green";
            RwImgUtils.map["#7AB93B"] = "Light Green";
            RwImgUtils.map["#C1D62D"] = "Lime";
            RwImgUtils.map["#FDE82F"] = "Yellow";
            RwImgUtils.map["#FCB40E"] = "Amber";

            RwImgUtils.map["#FC8607"] = "Orange";
            RwImgUtils.map["#FB3F1B"] = "Deep Orange";
            RwImgUtils.map["#654338"] = "Brown";
            RwImgUtils.map["#8C8C8C"] = "Grey";
            RwImgUtils.map["#4E6A79"] = "Blue Grey";
            RwImgUtils.map["#191919"] = "Black-ish";
        }

        hexColor = hexColor.toUpperCase();

        return RwImgUtils.map[hexColor];
    }


    static isDark(color): boolean {

        let isDark = false;

        // Variables for red, green, blue values
        let r, g, b, hsp: number

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            // If HEX --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            r = color[1];
            g = color[2];
            b = color[3];
        } else {
            // If RGB --> Convert it to HEX: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

        // Using the HSP value, determine whether the color is light or dark
        //isDark = hsp < 127.5;
        isDark = hsp < 160.5;
        return isDark;

    }


}

