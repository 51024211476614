import {
    HistSortOrder,
    ManifestSetSeq,
    RouteSortOrder,
    RwDistUnits,
    RwLogsSortOrder,
    SiteSortOrder,
    SortDirection
} from "@/app/RwEnums";
import {RwConstants} from "@/app/RwConstants";
import RwDeferredLog from "@/app/utils/RwDeferredLog";
import moment from 'moment';
import {PODDefaultSettings} from "@/app/dem/ProofOfDelivery/ProofOfDeliveryEnums";

export class RwPrefUtils {
    static defaultLat: number = 38.9;
    static defaultLng: number = -77.04;

    //#region User Prefs

    static get hidePingPopup(): boolean {
        return this.readBool("hidePingPopup", false);
    }

    static set hidePingPopup(newVal: boolean) {
        this.writeBool("hidePingPopup", newVal);
    }

    static get hideDeletePopup(): boolean {
        return this.readBool("hideDeletePopup", false);
    }

    static set hideDeletePopup(newVal: boolean) {
        this.writeBool("hideDeletePopup", newVal);
    }

    static get hideCreatePopup(): boolean {
        return this.readBool("hideCreatePopup", false);
    }

    static set hideCreatePopup(newVal: boolean) {
        this.writeBool("hideCreatePopup", newVal);
    }

    static get hideCCPopup(): boolean {
        return this.readBool("hideCCPopup", false);
    }

    static set hideCCPopup(newVal: boolean) {
        this.writeBool("hideCCPopup", newVal);
    }

    static get valCCPopup(): number {
        return this.readNumber("valCCPopup", 0);
    }

    static set valCCPopup(newVal: number) {
        this.writeNumber("valCCPopup", newVal);
    }

    static get warnedCCPopup(): string[] {
        return this.readStringArray("warnedCCPopup", []);
    }

    static set warnedCCPopup(newVal: string[]) {
        this.writeStringArray("warnedCCPopup", newVal);
    }

    static get warnedCCPopupOverdue(): string[] {
        return this.readStringArray("warnedCCPopupOverdue", []);
    }

    static set warnedCCPopupOverdue(newVal: string[]) {
        this.writeStringArray("warnedCCPopupOverdue", newVal);
    }

    static get hide450Popup(): boolean {
        return this.readBool("hide450Popup", false);
    }

    static set hide450Popup(newVal: boolean) {
        this.writeBool("hide450Popup", newVal);
    }

    static get prompt450Popup(): string[] {
        return this.readStringArray("prompt450Popup", []);
    }

    static set prompt450Popup(newVal: string[]) {
        newVal = newVal ? newVal : [];
        this.writeStringArray("prompt450Popup", newVal);
    }

    static get warned450Popup(): string[] {
        return this.readStringArray("warned450Popup", []);
    }

    static set warned450Popup(newVal: string[]) {
        newVal = newVal ? newVal : [];
        this.writeStringArray("warned450Popup", newVal);
    }

    static get prompt500Popup(): string[] {
        return this.readStringArray("prompt500Popup", []);
    }

    static set prompt500Popup(newVal: string[]) {
        newVal = newVal ? newVal : [];
        this.writeStringArray("prompt500Popup", newVal);
    }

    static get hideTasksWarn(): boolean {
        return this.readBool("hideTasksWarn", false);
    }

    static set hideTasksWarn(newVal: boolean) {
        this.writeBool("hideTasksWarn", newVal);
    }

    static get hideStopStatus(): boolean {
        return this.readBool("hideStopStatus", true);
    }

    static set hideStopStatus(newVal: boolean) {
        this.writeBool("hideStopStatus", newVal);
    }


    // static get showEditDriver(): boolean { return this.readBool("showEditDriver", false); }
    // static set showEditDriver(newVal: boolean) { this.writeBool("showEditDriver", newVal);}

    static get showEditAvatar(): boolean {
        return this.readBool("showEditAvatar", false);
    }

    static set showEditAvatar(newVal: boolean) {
        this.writeBool("showEditAvatar", newVal);
    }

    static get showEditDriverPass(): boolean {
        return this.readBool("showEditDriverPass", false);
    }

    static set showEditDriverPass(newVal: boolean) {
        this.writeBool("showEditDriverPass", newVal);
    }

    static get showEditDriverUname(): boolean {
        return this.readBool("showEditDriverUname", false);
    }

    static set showEditDriverUname(newVal: boolean) {
        this.writeBool("showEditDriverUname", newVal);
    }

    static get showEditDefault(): boolean {
        return this.readBool("showEditDefault", false);
    }

    static set showEditDefault(newVal: boolean) {
        this.writeBool("showEditDefault", newVal);
    }

    static get showStopDefault(): boolean {
        return this.readBool("showStopDefault", false);
    }

    static set showStopDefault(newVal: boolean) {
        this.writeBool("showStopDefault", newVal);
    }

    static get showTaskDefault(): boolean {
        return this.readBool("showTaskDefault", false);
    }

    static set showTaskDefault(newVal: boolean) {
        this.writeBool("showTaskDefault", newVal);
    }

    static get showSiteDefault(): boolean {
        return this.readBool("showSiteDefault", false);
    }

    static set showSiteDefault(newVal: boolean) {
        this.writeBool("showSiteDefault", newVal);
    }

    static get showOptDefault(): boolean {
        return this.readBool("showOptDefault", false);
    }

    static set showOptDefault(newVal: boolean) {
        this.writeBool("showOptDefault", newVal);
    }

    static get showTravelDefault(): boolean {
        return this.readBool("showTravelDefault", false);
    }

    static set showTravelDefault(newVal: boolean) {
        this.writeBool("showTravelDefault", newVal);
    }

    static get showOneWayDefault(): boolean {
        return this.readBool("showOneWayDefault", false);
    }

    static set showOneWayDefault(newVal: boolean) {
        this.writeBool("showOneWayDefault", newVal);
    }

    static get showAvoidsDefault(): boolean {
        return this.readBool("showAvoidsDefault", false);
    }

    static set showAvoidsDefault(newVal: boolean) {
        this.writeBool("showAvoidsDefault", newVal);
    }

    static get showUnitDefault(): boolean {
        return this.readBool("showUnitDefault", false);
    }

    static set showUnitDefault(newVal: boolean) {
        this.writeBool("showUnitDefault", newVal);
    }

    static get showSvcTimeDefault(): boolean {
        return this.readBool("showSvcTimeDefault", false);
    }

    static set showSvcTimeDefault(newVal: boolean) {
        this.writeBool("showSvcTimeDefault", newVal);
    }

    static get showRouteTimeDefault(): boolean {
        return this.readBool("showRouteTimeDefault", false);
    }

    static set showRouteTimeDefault(newVal: boolean) {
        this.writeBool("showRouteTimeDefault", newVal);
    }

    static get showCenterDefault(): boolean {
        return this.readBool("showCenterDefault", false);
    }

    static set showCenterDefault(newVal: boolean) {
        this.writeBool("showCenterDefault", newVal);
    }

    static get showStartDefault(): boolean {
        return this.readBool("showStartDefault", false);
    }

    static set showStartDefault(newVal: boolean) {
        this.writeBool("showStartDefault", newVal);
    }

    static get showReplaceDriver(): boolean {
        return this.readBool("showReplaceDriver", false);
    }

    static set showReplaceDriver(newVal: boolean) {
        this.writeBool("showReplaceDriver", newVal);
    }

    static get showDeleteDriver(): boolean {
        return this.readBool("showDeleteDriver", false);
    }

    static set showDeleteDriver(newVal: boolean) {
        this.writeBool("showDeleteDriver", newVal);
    }

    static get showCreateDriver(): boolean {
        return this.readBool("showCreateDriver", false);
    }

    static set showCreateDriver(newVal: boolean) {
        this.writeBool("showCreateDriver", newVal);
    }

    static get showCreateBulkUser(): boolean {
        return this.readBool("showCreateBulkUser", false);
    }

    static set showCreateBulkUser(newVal: boolean) {
        this.writeBool("showCreateBulkUser", newVal);
    }

    static get showDomainPopup(): boolean {
        return this.readBool("showDomainPopup", false);
    }

    static set showDomainPopup(newVal: boolean) {
        this.writeBool("showDomainPopup", newVal);
    }

    static get showPingTest(): boolean {
        return this.readBool("showPingTest", false);
    }

    static set showPingTest(newVal: boolean) {
        this.writeBool("showPingTest", newVal);
    }

    static get showDeletePrompt(): boolean {
        return this.readBool("showDeletePrompt", false);
    }

    static set showDeletePrompt(newVal: boolean) {
        this.writeBool("showDeletePrompt", newVal);
    }

    static get showCreatePrompt(): boolean {
        return this.readBool("showCreatePrompt", false);
    }

    static set showCreatePrompt(newVal: boolean) {
        this.writeBool("showCreatePrompt", newVal);
    }

    static get isLoggedIn(): boolean {
        return this.readBool("isLoggedIn", false);
    }

    static set isLoggedIn(newVal: boolean) {
        this.writeBool("isLoggedIn", newVal);
    }

    static get stayLoggedIn(): boolean {
        return this.readBool("stayLoggedIn", false);
    }

    static set stayLoggedIn(newVal: boolean) {
        this.writeBool("stayLoggedIn", newVal);
    }

    static get showSkedEdit(): boolean {
        return this.readBool("showSkedEdit", false);
    }

    static set showSkedEdit(newVal: boolean) {
        this.writeBool("showSkedEdit", newVal);
    }

    static get showSkedCreate(): boolean {
        return this.readBool("showSkedCreate", false);
    }

    static set showSkedCreate(newVal: boolean) {
        this.writeBool("showSkedCreate", newVal);
    }

    static get showSkedDelete(): boolean {
        return this.readBool("showSkedDelete", false);
    }

    static set showSkedDelete(newVal: boolean) {
        this.writeBool("showSkedDelete", newVal);
    }

    static get showOnboardSettings(): boolean {
        return this.readBool("showOnboardSettings", false);
    }

    static set showOnboardSettings(newVal: boolean) {
        this.writeBool("showOnboardSettings", newVal);
    }

    static get showOnboardAddStopArrow(): boolean {
        return this.readBool("showOnboardAddStopArrow", false);
    }

    static set showOnboardAddStopArrow(newVal: boolean) {
        this.writeBool("showOnboardAddStopArrow", newVal);
    }

    static get showOnboardUploadsPopup(): boolean {
        return this.readBool("showOnboardUploadsPopup", false);
    }

    static set showOnboardUploadsPopup(newVal: boolean) {
        this.writeBool("showOnboardUploadsPopup", newVal);
    }

    static get showedPODCallout(): boolean {
        return this.readBool("showedPODCallout", false);
    }

    static set showedPODCallout(newVal: boolean) {
        this.writeBool("showedPODCallout", newVal);
    }

    static get showProBetaPrimer(): boolean {
        return this.readBool("showProBetaPrimer", false);
    }

    static set showProBetaPrimer(newVal: boolean) {
        this.writeBool("showProBetaPrimer", newVal);
    }

    static get showProfileEdit(): boolean {
        return this.readBool("showProfileEdit", false);
    }

    static set showProfileEdit(newVal: boolean) {
        this.writeBool("showProfileEdit", newVal);
    }

    static get showLimitPopup(): boolean {
        return this.readBool("showLimitPopup", false);
    }

    static set showLimitPopup(newVal: boolean) {
        this.writeBool("showLimitPopup", newVal);
    }

    //
    // static get showUserSiteBanner(): boolean { return this.readBool ("showUserSiteBanner",true);}
    // static set showUserSiteBanner(newVal: boolean) { this.writeBool ("showUserSiteBanner", newVal);}

    static get hasClosedUserSiteBanner(): boolean {
        return this.readBool("hasClosedUserSiteBanner", false);
    }

    static set hasClosedUserSiteBanner(newVal: boolean) {
        this.writeBool("hasClosedUserSiteBanner", newVal);
    }

    static get lastClosedPaymentBanner(): Date {
        return this.readDate("lastClosedPaymentBanner", new Date(0));
    }

    static set lastClosedPaymentBanner(newVal: Date) {
        this.writeDate("lastClosedPaymentBanner", newVal);
    }


    static get showNewPass(): boolean {
        return this.readBool("showNewPass", false);
    }

    static set showNewPass(newVal: boolean) {
        this.writeBool("showNewPass", newVal);
    }

    static get showRegistrationType(): boolean {
        return this.readBool("showRegistrationType", false);
    }

    static set showRegistrationType(newVal: boolean) {
        this.writeBool("showRegistrationType", newVal);
    }

    static get showProToTeam(): boolean {
        return this.readBool("showProToTeam", false);
    }

    static set showProToTeam(newVal: boolean) {
        this.writeBool("showProToTeam", newVal);
    }

    static get showCCExpire(): boolean {
        return this.readBool("showCCExpire", false);
    }

    static set showCCExpire(newVal: boolean) {
        this.writeBool("showCCExpire", newVal);
    }

    static get token(): string {
        return this.readString("token", null);
    }

    static set token(newVal: string) {
        this.writeString("token", newVal);
    }

    // static get tokenDOB(): Date { return this.readDate("tokenDOB", new Date(0)); }
    // static set tokenDOB(newVal: Date) { this.writeDate("tokenDOB", newVal);}

    static get deviceId(): string {
        return this.readString("deviceId", null);
    }

    static set deviceId(newVal: string) {
        this.writeString("deviceId", newVal);
    }

    static get accountId(): string {
        return this.readString("accountId", null);
    }

    static set accountId(newVal: string) {
        this.writeString("accountId", newVal);
    }

    static get orgId(): string {
        return this.readString("orgId", null);
    }

    static set orgId(newVal: string) {
        this.writeString("orgId", newVal);
    }

    static get installId(): string {
        return this.readString("installId", null);
    }

    static set installId(newVal: string) {
        this.writeString("installId", newVal);
    }

    static get userId(): string {
        return this.readString("userId", null);
    }

    static set userId(newVal: string) {
        this.writeString("userId", newVal);
    }

    static get dispName(): string {
        return this.readString("dispName", null);
    }

    static set dispName(newVal: string) {
        this.writeString("dispName", newVal);
    }

    static get userName(): string {
        return this.readString("userName", null);
    }

    static set userName(newVal: string) {
        this.writeString("userName", newVal);
    }

    static get skuInfosJson(): string {
        return this.readString("skuInfosJson", null);
    }

    static set skuInfosJson(newVal: string) {
        this.writeString("skuInfosJson", newVal);
    }

    static get lastGoodUserName(): string {
        return this.readString("lastGoodUserName", null);
    }

    static set lastGoodUserName(newVal: string) {
        this.writeString("lastGoodUserName", newVal);
    }

    static get userPass(): string {
        //console.log("GET userPass", this.readString("userPass", null));
        return this.readString("userPass", null);
    }

    static set userPass(newVal: string) {
        // if(!newVal){
        //   console.warn("SET userPass", newVal);
        // }
        // else {
        //   console.log("SET userPass", newVal);
        // }

        this.writeString("userPass", newVal);
    }

    static get isSubTeam(): boolean {
        return this.readBool("isSubTeam", false);
    }

    static set isSubTeam(newVal: boolean) {
        this.writeBool("isSubTeam", newVal);
    }

    static get subDomain(): string {
        return this.readString("subDomain", null);
    }

    static set subDomain(newVal: string) {
        this.writeString("subDomain", newVal);
    }

    static get userAccessType(): number {
        return this.readNumber("userAccessType", 0);
    }

    static set userAccessType(val: number) {
        this.writeNumber("userAccessType", val);
    }

    static get lastRouteId(): string {
        return this.readString("lrid", null);
    }

    static set lastRouteId(newVal: string) {
        this.writeString("lrid", newVal);
    }

    static get lastSync(): Date {
        return this.readDate("lastSync", new Date(0));
    }

    static set lastSync(newVal: Date) {
        this.writeDate("lastSync", newVal);
    }

    static get lastTokenMS(): number {
        return this.readNumber("lastTokenMS", 0);
    }

    static set lastTokenMS(newVal: number) {
        this.writeNumber("lastTokenMS", newVal);
    }


    static get fdxManifestAuthTime(): Date {
        return this.readDate("fdxManifestAuthTime", new Date(0));
    }

    static set fdxManifestAuthTime(newVal: Date) {
        this.writeDate("fdxManifestAuthTime", newVal);
    }

    //#region Price Points
    static get ppb(): string {
        return this.readString("ppb", "");
    }

    static set ppb(newVal: string) {
        this.writeString("ppb", newVal);
    }

    static get ppd(): string {
        return this.readString("ppd", "");
    }

    static set ppd(newVal: string) {
        this.writeString("ppd", newVal);
    }

    static get ppt(): string {
        return this.readString("ppt", "");
    }

    static set ppt(newVal: string) {
        this.writeString("ppt", newVal);
    }

    //#endregion

    static get avUrl(): string {
        return this.readString("avUrl", null);
    }

    static set avUrl(newVal: string) {
        this.writeString("avUrl", newVal);
    }

    static get avType(): number {
        return this.readNumber("avType", 1);
    }

    static set avType(newVal: number) {
        this.writeNumber("avType", newVal);
    }

    static get avInits(): string {
        return this.readString("avInits", null);
    }

    static set avInits(newVal: string) {
        this.writeString("avInits", newVal);
    }

    static get avColor(): string {
        return this.readString("avColor", '#E2004F');
    }

    static set avColor(newVal: string) {
        this.writeString("avColor", newVal);
    }

    static get pendingPurch(): boolean {
        return this.readBool("pendingPurch", false);
    }

    static set pendingPurch(newVal: boolean) {
        this.writeBool("pendingPurch", newVal);
    }

    static get pendingPurchTS(): Date {
        return this.readDate("pendingPurchTS", new Date(0));
    }

    static set pendingPurchTS(newVal: Date) {
        this.writeDate("pendingPurchTS", newVal);
    }

    static get attribData(): string {
        return this.readString("attribData", null);
    }

    static set attribData(newVal: string) {
        this.writeString("attribData", newVal);
    }

    //#endregion


    //#region Routes Prefs

    static get maxRouteSize(): number {
        return this.readNumber("maxRouteSize", 200);
    }

    static set maxRouteSize(val: number) {
        this.writeNumber("maxRouteSize", val);
    }

    static get lastUploadType(): number {
        return this.readNumber("lastUploadType", 1);
    }

    static set lastUploadType(newVal: number) {
        this.writeNumber("lastUploadType", newVal)
    };

    static get uploadErrorRes(): number {
        return this.readNumber("uploadResolution", 1);
    }

    static set uploadErrorRes(newVal: number) {
        this.writeNumber("uploadResolution", newVal)
    };

    static get showPushMsgTutorial(): boolean {
        return this.readBool("showPushMsgTutorial", true);
    }

    static set showPushMsgTutorial(newVal: boolean) {
        this.writeBool("showPushMsgTutorial", newVal);
    }

    static get hideDeletedReport(): boolean {
        return this.readBool("hideDeletedReport", true);
    }

    static set hideDeletedReport(newVal: boolean) {
        this.writeBool("hideDeletedReport", newVal);
    }

    static get hideCompleted(): boolean {
        return this.readBool("hideCompleted", false);
    }

    static set hideCompleted(newVal: boolean) {
        this.writeBool("hideCompleted", newVal);
    }

    static get siteSort(): number {
        return this.readNumber("siteSort", 0);
    }

    static set siteSort(newVal: number) {
        this.writeNumber("siteSort", newVal)
    };

    static get driversSort(): number {
        return this.readNumber("driversSort", 0);
    }

    static set driversSort(newVal: number) {
        this.writeNumber("driversSort", newVal)
    };

    static get logStartDate(): Date {
        return this.readDate("logStartDate", moment().subtract(24, 'hours').toDate());
    }

    static set logStartDate(newVal: Date) {
        this.writeDate("logStartDate", newVal);
    }

    static get logFinishDate(): Date {
        return this.readDate("logFinishDate", new Date());
    }

    static set logFinishDate(newVal: Date) {
        this.writeDate("logFinishDate", newVal);
    }

    static get reportStartDate(): Date {
        return this.readDate("reportStartDate", moment().subtract(7, 'days').toDate());
    }

    static set reportStartDate(newVal: Date) {
        this.writeDate("reportStartDate", newVal);
    }

    static get reportFinishDate(): Date {
        return this.readDate("reportFinishDate", new Date());
    }

    static set reportFinishDate(newVal: Date) {
        this.writeDate("reportFinishDate", newVal);
    }

    static get reportShowDeleted(): boolean {
        return this.readBool("reportShowDeleted", false);
    }

    static set reportShowDeleted(newVal: boolean) {
        this.writeBool("reportShowDeleted", newVal);
    }

    static get reportShowArchived(): boolean {
        return this.readBool("reportShowArchived", false);
    }

    static set reportShowArchived(newVal: boolean) {
        this.writeBool("reportShowArchived", newVal);
    }

    static get routeListSortBy(): number {
        return this.readNumber("routeListSortBy", 0);
    }

    static set routeListSortBy(newVal: number) {
        this.writeNumber("routeListSortBy", newVal);
    }

    static get routesReportSortBy(): number {
        return this.readNumber("routesReportSort", RouteSortOrder.StartDate);
    }

    static set routesReportSortBy(newVal: number) {
        this.writeNumber("routesReportSort", newVal);
    }

    static get routeReportSortDir(): number {
        return this.readNumber("routeReportSortDir", SortDirection.Desc);
    }

    static set routeReportSortDir(newVal: number) {
        this.writeNumber("routeReportSortDir", newVal);
    }

    static get favoritesReportSortBy(): number {
        return this.readNumber("favoritesReportSort", SiteSortOrder.Name);
    }

    static set favoritesReportSortBy(newVal: number) {
        this.writeNumber("favoritesReportSort", newVal);
    }

    static get favoritesReportSortDir(): number {
        return this.readNumber("favoritesReportSortDir", SortDirection.Desc);
    }

    static set favoritesReportSortDir(newVal: number) {
        this.writeNumber("favoritesReportSortDir", newVal);
    }

    static get historyReportSortBy(): number {
        return this.readNumber("historyReportSort", HistSortOrder.Date);
    }

    static set historyReportSortBy(newVal: number) {
        this.writeNumber("historyReportSort", newVal);
    }

    static get historyReportSortDir(): number {
        return this.readNumber("historyReportSortDir", SortDirection.Desc);
    }

    static set historyReportSortDir(newVal: number) {
        this.writeNumber("historyReportSortDir", newVal);
    }

    static get siteListSortBy(): number {
        return this.readNumber("siteListSortBy", 0);
    }

    static set siteListSortBy(newVal: number) {
        this.writeNumber("siteListSortBy", newVal);
    }

    static get skedListSortBy(): number {
        return this.readNumber("skedListSortBy", 0);
    }

    static set skedListSortBy(newVal: number) {
        this.writeNumber("skedListSortBy", newVal);
    }

    static get driverListSortBy(): number {
        return this.readNumber("driverListSortBy", 0);
    }

    static set driverListSortBy(newVal: number) {
        this.writeNumber("driverListSortBy", newVal);
    }

    static get taskListSortBy(): number {
        return this.readNumber("taskListSortBy", 0);
    }

    static set taskListSortBy(newVal: number) {
        this.writeNumber("taskListSortBy", newVal);
    }

    static get subTeamListSortBy(): number {
        return this.readNumber("subTeamListSortBy", 0);
    }

    static set subTeamListSortBy(newVal: number) {
        this.writeNumber("subTeamListSortBy", newVal);
    }

    static get routeListSortDesc(): number {
        return this.readNumber("routeListSortDesc", 0);
    }

    static set routeListSortDesc(newVal: number) {
        this.writeNumber("routeListSortDesc", newVal);
    }

    static get driverListSortDesc(): number {
        return this.readNumber("driverListSortDesc", 0);
    }

    static set driverListSortDesc(newVal: number) {
        this.writeNumber("driverListSortDesc", newVal);
    }

    static get siteListSortDesc(): number {
        return this.readNumber("siteListSortDesc", 0);
    }

    static set siteListSortDesc(newVal: number) {
        this.writeNumber("siteListSortDesc", newVal);
    }

    static get skedListSortDesc(): number {
        return this.readNumber("skedListSortDesc", 0);
    }

    static set skedListSortDesc(newVal: number) {
        this.writeNumber("skedListSortDesc", newVal);
    }

    static get taskListSortDesc(): number {
        return this.readNumber("taskListSortDesc", 0);
    }

    static set taskListSortDesc(newVal: number) {
        this.writeNumber("taskListSortDesc", newVal);
    }

    static get subTeamListSortDesc(): number {
        return this.readNumber("subTeamListSortDesc", 0);
    }

    static set subTeamListSortDesc(newVal: number) {
        this.writeNumber("subTeamListSortDesc", newVal);
    }

    static get skedsSort(): number {
        return this.readNumber("skedsSort", 0);
    }

    static set skedsSort(newVal: number) {
        this.writeNumber("skedsSort", newVal)
    };

    static get historySort(): number {
        return this.readNumber("historySort", 0);
    }

    static set historySort(newVal: number) {
        this.writeNumber("historySort", newVal)
    };

    static get distUnits(): RwDistUnits {
        return this.readNumber("distUnits", 1);
    }

    static set distUnits(newVal: RwDistUnits) {
        this.writeNumber("distUnits", newVal)
    };

    static get routeShiftHours(): RwDistUnits {
        return this.readNumber("routeShiftHours", 12);
    }

    static set routeShiftHours(newVal: RwDistUnits) {
        this.writeNumber("routeShiftHours", newVal)
    };

    static get hideGetStarted(): boolean {
        return this.readBool("hideGetStarted", true);
    }

    static set hideGetStarted(newVal: boolean) {
        this.writeBool("hideGetStarted", newVal);
    }

    static get routeStatusSortBy(): string {
        return this.readString("routeStatusSortBy", "sortStartDate");
    }

    static set routeStatusSortBy(newVal: string) {
        this.writeString("routeStatusSortBy", newVal);
    }

    static get routeStatusSortDesc(): boolean {
        return this.readBool("routeStatusSortDesc", true);
    }

    static set routeStatusSortDesc(newVal: boolean) {
        this.writeBool("routeStatusSortDesc", newVal);
    }

    static get logsSortBy(): number {
        return this.readNumber("logsSortBy", RwLogsSortOrder.Sev);
    }

    static set logsSortBy(newVal: number) {
        this.writeNumber("logsSortBy", newVal);
    }

    static get logsSortDir(): number {
        return this.readNumber("logsSortDir", SortDirection.Desc);
    }

    static set logsSortDir(newVal: number) {
        this.writeNumber("logsSortDir", newVal);
    }


    //#endregion

    //#region Upload

    //#region Settings Dialog

    static get optimizeType(): RwDistUnits {
        return this.readNumber("optimizeType", 2);
    }

    static set optimizeType(newVal: RwDistUnits) {
        this.writeNumber("optimizeType", newVal)
    };

    static get travelMode(): string {
        return this.readString("travelMode", "driving");
    }

    static set travelMode(newVal: string) {
        this.writeString("travelMode", newVal);
    }

    static get avoids(): string[] {
        return this.readStringArray("avoids", []);
    }

    static set avoids(newVal: string[]) {
        newVal = newVal ? newVal : [];
        this.writeStringArray("avoids", newVal);
    }

    static get visitTime(): number {
        return this.readNumber("visitTime", 0);
    }

    static set visitTime(newVal: number) {
        this.writeNumber("visitTime", newVal)
    };

    static get routeSpan(): number {
        return this.readNumber("routeSpan", 720);
    } //12 hours
    static set routeSpan(newVal: number) {
        this.writeNumber("routeSpan", newVal)
    };

    static get isRoundTrip(): boolean {
        return this.readBool("isRoundTrip", false);
    }

    static set isRoundTrip(newVal: boolean) {
        this.writeBool("isRoundTrip", newVal);
    }

    static get searchWeb(): boolean {
        return this.readBool("searchWeb", true);
    }

    static set searchWeb(newVal: boolean) {
        this.writeBool("searchWeb", newVal);
    }

    static get searchSites(): boolean {
        return this.readBool("searchSites", true);
    }

    static set searchSites(newVal: boolean) {
        this.writeBool("searchSites", newVal);
    }

    static get searchTasks(): boolean {
        return this.readBool("searchTasks", true);
    }

    static set searchTasks(newVal: boolean) {
        this.writeBool("searchTasks", newVal);
    }

    static get searchSetSeq(): boolean {
        return this.readBool("searchSetSeq", false);
    }

    static set searchSetSeq(newVal: boolean) {
        this.writeBool("searchSetSeq", newVal);
    }

    static get showOnTracUpload(): boolean {
        return this.readBool("showOnTracUpload", false);
    }

    static set showOnTracUpload(newVal: boolean) {
        this.writeBool("showOnTracUpload", newVal);
    }

    static get showFdxUpload(): boolean {
        return this.readBool("showFdxUpload", false);
    }

    static set showFdxUpload(newVal: boolean) {
        this.writeBool("showFdxUpload", newVal);
    }

    static get allowExpressOpt(): boolean {
        return this.readBool("allowExpressOpt", false);
    }

    static set allowExpressOpt(newVal: boolean) {
        this.writeBool("allowExpressOpt", newVal);
    }

    static get skipUploadArea(): boolean {
        return this.readBool("skipUploadArea", false);
    }

    static set skipUploadArea(newVal: boolean) {
        this.writeBool("skipUploadArea", newVal);
    }

    static get highlightedTemplateThisSession(): boolean {
        return this.readBool("highlightedTemplateThisSession", false);
    }

    static set highlightedTemplateThisSession(newVal: boolean) {
        this.writeBool("highlightedTemplateThisSession", newVal);
    }

    static get maniSetSeq(): ManifestSetSeq {
        return this.readNumber("maniSetSeq", ManifestSetSeq.None);
    }

    static set maniSetSeq(newVal: ManifestSetSeq) {
        this.writeNumber("maniSetSeq", newVal);
    }

    static get fdxPromptStatus(): number {
        return this.readNumber("fdxPromptStatus", 0);
    }

    static set fdxPromptStatus(newVal: number) {
        this.writeNumber("fdxPromptStatus", newVal)
    };

    static get showDriverPanel(): boolean {
        return this.readBool("showDriverPanel", true);
    }

    static set showDriverPanel(newVal: boolean) {
        this.writeBool("showDriverPanel", newVal);
    }

    static get showPlannerStartup(): boolean {
        return this.readBool("showPlannerStartup", false);
    }

    static set showPlannerStartup(newVal: boolean) {
        this.writeBool("showPlannerStartup", newVal);
    }

    static get hideIntroUpload(): boolean {
        return this.readBool("hideIntroUpload", false);
    }

    static set hideIntroUpload(newVal: boolean) {
        this.writeBool("hideIntroUpload", newVal);
    }

    static get downloadReportsLocalTz(): boolean {
        return this.readBool("hideIntroUpload", true);
    }

    static set downloadReportsLocalTz(newVal: boolean) {
        this.writeBool("hideIntroUpload", newVal);
    }

    static get filterFedExAreas(): boolean {
        return this.readBool("filterFedExAreas", false);
    }

    static set filterFedExAreas(newVal: boolean) {
        this.writeBool("filterFedExAreas", newVal);
    }

    static get rapidEntry(): boolean {
        return this.readBool("rapidEntry", false);
    }

    static set rapidEntry(newVal: boolean) {
        this.writeBool("rapidEntry", newVal);
    }

    static get editAfterAdd(): boolean {
        return this.readBool("editAfterAdd", false);
    }

    static set editAfterAdd(newVal: boolean) {
        this.writeBool("editAfterAdd", newVal);
    }

    static get editFromCopy(): boolean {
        return this.readBool("editFromCopy", false);
    }

    static set editFromCopy(newVal: boolean) {
        this.writeBool("editFromCopy", newVal);
    }

    static get mapHoverHide(): boolean {
        return this.readBool("mapHoverHide", false);
    }

    static set mapHoverHide(newVal: boolean) {
        this.writeBool("mapHoverHide", newVal);
    }

    static get confirmOptimizations(): boolean {
        return this.readBool("confirmOptimizations", false);
    }

    static set confirmOptimizations(newVal: boolean) {
        this.writeBool("confirmOptimizations", newVal);
    }

    static get confirmStopRemoval(): boolean {
        return this.readBool("confirmStopRemoval", true);
    }

    static set confirmStopRemoval(newVal: boolean) {
        this.writeBool("confirmStopRemoval", newVal);
    }

    static get confirmSearchResult(): boolean {
        return this.readBool("confirmSearchResult", false);
    }

    static set confirmSearchResult(newVal: boolean) {
        this.writeBool("confirmSearchResult", newVal);
    }

    static get promptCheckins(): boolean {
        return this.readBool("promptCheckins", false);
    }

    static set promptCheckins(newVal: boolean) {
        this.writeBool("promptCheckins", newVal);
    }

    static get isDynPoint(): boolean {
        return this.readBool("isDynPoint", false);
    }

    static set isDynPoint(newVal: boolean) {
        this.writeBool("isDynPoint", newVal);
    }

    static get startFriendlyName(): string {
        return this.readString("startFriendlyName", "");
    }

    static set startFriendlyName(newVal: string) {
        this.writeString("startFriendlyName", newVal);
    }

    static get registerEmail(): string {
        return this.readString("registerEmail", "");
    }

    static set registerEmail(newVal: string) {
        this.writeString("registerEmail", newVal);
    }

    static get isHardStart(): boolean {
        return this.readBool("isHardStart", false);
    }

    static set isHardStart(newVal: boolean) {
        this.writeBool("isHardStart", newVal);
    }

    static get isHardStop(): boolean {
        return this.readBool("isHardStop", false);
    }

    static set isHardStop(newVal: boolean) {
        this.writeBool("isHardStop", newVal);
    }


    //#endregion


    //#region Planner Settings

    static get showPanelMap(): boolean {
        return this.readBool("showPanelMap", true);
    }

    static set showPanelMap(newVal: boolean) {
        this.writeBool("showPanelMap", newVal);
    }

    static get showPanelList(): boolean {
        return this.readBool("showPanelList", true);
    }

    static set showPanelList(newVal: boolean) {
        this.writeBool("showPanelList", newVal);
    }

    static get showPinPanel(): boolean {
        return this.readBool("showPinPanel", false);
    }

    static set showPinPanel(newVal: boolean) {
        this.writeBool("showPinPanel", newVal);
    }

    static get showPanelStatus(): boolean {
        return this.readBool("showPanelStatus", true);
    }

    static set showPanelStatus(newVal: boolean) {
        this.writeBool("showPanelStatus", newVal);
    }

    // static get showDriverPanel(): boolean { return this.readBool("showDriverPanel", true); }
    // static set showDriverPanel(newVal: boolean) { this.writeBool("showDriverPanel", newVal);}

    static get listViewOpen(): boolean {
        return this.readBool("listViewOpen", false);
    }

    static set listViewOpen(newVal: boolean) {
        this.writeBool("listViewOpen", newVal);
    }

    static get mapShowTraffic(): boolean {
        return this.readBool("mapShowTraffic", false);
    }

    static set mapShowTraffic(newVal: boolean) {
        this.writeBool("mapShowTraffic", newVal);
    }

    static get mapShowIncidents(): boolean {
        return this.readBool("mapShowIncidents", false);
    }

    static set mapShowIncidents(newVal: boolean) {
        this.writeBool("mapShowIncidents", newVal);
    }

    static get mapShowNormal(): boolean {
        return this.readBool("mapShowNormal", false);
    }

    static set mapShowNormal(newVal: boolean) {
        this.writeBool("mapShowNormal", newVal);
    }

    static get mapShowSatellite(): boolean {
        return this.readBool("mapShowSatellite", false);
    }

    static set mapShowSatellite(newVal: boolean) {
        this.writeBool("mapShowSatellite", newVal);
    }

    static get planMultiModeTool(): number {
        return this.readNumber("planMultiModeTool", 0);
    }

    static set planMultiModeTool(newVal: number) {
        this.writeNumber("planMultiModeTool", newVal);
    }

    static get planMultiModeDefault(): number {
        return this.readNumber("planMultiModeDefault", 1);
    }

    static set planMultiModeDefault(newVal: number) {
        this.writeNumber("planMultiModeDefault", newVal);
    }

    static get plannerSortByStops(): string[] {
        return this.readStringArray("plannerSortByStops", ["baseOrder"]);
    }

    static set plannerSortByStops(newVal: string[]) {
        this.writeStringArray("plannerSortByStops", newVal);
    }

    static get plannerSortDescStops(): boolean[] {
        return this.readBoolArray("plannerSortDescStops", false);
    }

    static set plannerSortDescStops(newVal: boolean[]) {
        this.writeBoolArray("plannerSortDescStops", newVal);
    }

    static get plannerSortBySites(): string[] {
        return this.readStringArray("plannerSortBySites", ["baseOrder"]);
    }

    static set plannerSortBySites(newVal: string[]) {
        this.writeStringArray("plannerSortBySites", newVal);
    }

    static get plannerSortDescSites(): boolean[] {
        return this.readBoolArray("plannerSortDescSites", false);
    }

    static set plannerSortDescSites(newVal: boolean[]) {
        this.writeBoolArray("plannerSortDescSites", newVal);
    }

    static get plannerSortByTasks(): string[] {
        return this.readStringArray("plannerSortByTasks", ["baseOrder"]);
    }

    static set plannerSortByTasks(newVal: string[]) {
        this.writeStringArray("plannerSortByTasks", newVal);
    }

    static get plannerSortDescTasks(): boolean[] {
        return this.readBoolArray("plannerSortDescTasks", false);
    }

    static set plannerSortDescTasks(newVal: boolean[]) {
        this.writeBoolArray("plannerSortDescTasks", newVal);
    }

    static get showDataLayerRoute(): boolean {
        return this.readBool("showDataLayerRoute", true);
    }

    static set showDataLayerRoute(newVal: boolean) {
        this.writeBool("showDataLayerRoute", newVal);
    }

    static get showDataLayerSites(): boolean {
        return this.readBool("showDataLayerSites", true);
    }

    static set showDataLayerSites(newVal: boolean) {
        this.writeBool("showDataLayerSites", newVal);
    }

    static get showDataLayerTasks(): boolean {
        return this.readBool("showDataLayerTasks", true);
    }

    static set showDataLayerTasks(newVal: boolean) {
        this.writeBool("showDataLayerTasks", newVal);
    }


    static get showStopDock(): boolean {
        return this.readBool("showStopDock", false);
    }

    static set showStopDock(newVal: boolean) {
        this.writeBool("showStopDock", newVal);
    }

    static get showDriverDock(): boolean {
        return this.readBool("showDriverDock", false);
    }

    static set showDriverDock(newVal: boolean) {
        this.writeBool("showDriverDock", newVal);
    }

    static get showRouteDock(): boolean {
        return this.readBool("showRouteDock", false);
    }

    static set showRouteDock(newVal: boolean) {
        this.writeBool("showRouteDock", newVal);
    }

    static get showSiteDock(): boolean {
        return this.readBool("showSiteDock", false);
    }

    static set showSiteDock(newVal: boolean) {
        this.writeBool("showSiteDock", newVal);
    }

    static get showSkedDock(): boolean {
        return this.readBool("showSkedDock", false);
    }

    static set showSkedDock(newVal: boolean) {
        this.writeBool("showSkedDock", newVal);
    }

    static get showSubTeamDock(): boolean {
        return this.readBool("showSubTeamDock", false);
    }

    static set showSubTeamDock(newVal: boolean) {
        this.writeBool("showSubTeamDock", newVal);
    }

    static get subTeamPopupView(): boolean {
        return this.readBool("subTeamPopupView", false);
    }

    static set subTeamPopupView(newVal: boolean) {
        this.writeBool("subTeamPopupView", newVal);
    }


    static get isOpenDrawer(): boolean {
        return this.readBool("isOpenDrawer", true);
    }

    static set isOpenDrawer(newVal: boolean) {
        this.writeBool("isOpenDrawer", newVal);
    }


    //#endregion


    //#region Filter Settings

    static get isStopFiltered(): boolean {
        return this.readBool("isStopFiltered", false);
    }

    static set isStopFiltered(newVal: boolean) {
        this.writeBool("isStopFiltered", newVal);
    }

    static get isSiteFiltered(): boolean {
        return this.readBool("isSiteFiltered", false);
    }

    static set isSiteFiltered(newVal: boolean) {
        this.writeBool("isSiteFiltered", newVal);
    }

    static get isTaskFiltered(): boolean {
        return this.readBool("isTaskFiltered", false);
    }

    static set isTaskFiltered(newVal: boolean) {
        this.writeBool("isTaskFiltered", newVal);
    }

    static get isDriverFiltered(): boolean {
        return this.readBool("isDriverFiltered", false);
    }

    static set isDriverFiltered(newVal: boolean) {
        this.writeBool("isDriverFiltered", newVal);
    }


    static get stopFilterName(): string {
        return this.readString("stopFilterName", "");
    }

    static set stopFilterName(newVal: string) {
        this.writeString("stopFilterName", newVal);
    }

    static get stopFilterAddress(): string {
        return this.readString("stopFilterAddress", "");
    }

    static set stopFilterAddress(newVal: string) {
        this.writeString("stopFilterAddress", newVal);
    }

    static get stopFilterType(): number {
        return this.readNumber("stopFilterType", 0);
    }

    static set stopFilterType(newVal: number) {
        this.writeNumber("stopFilterType", newVal)
    };

    static get stopFilterDistance(): number {
        return this.readNumber("stopFilterDistance", 0);
    }

    static set stopFilterDistance(val: number) {
        this.writeNumber("stopFilterDistance", val)
    };

    static get stopFilterHash(): string {
        return this.readString("stopFilterHash", "");
    }

    static set stopFilterHash(newVal: string) {
        this.writeString("stopFilterHash", newVal);
    }

    static get stopFilterHashAll(): boolean {
        return this.readBool("stopFilterHashAll", false);
    }

    static set stopFilterHashAll(newVal: boolean) {
        this.writeBool("stopFilterHashAll", newVal);
    }

    static get stopFilterColor(): string {
        return this.readString("stopFilterColor", "");
    }

    static set stopFilterColor(newVal: string) {
        this.writeString("stopFilterColor", newVal);
    }

    // static get stopFilterColors(): string[]  { return this.readStringArray("stopFilterColors", null); }
    // static set stopFilterColors(newVal: string[] ) { this.writeStringArray("stopFilterColors", newVal);}

    static get siteFilterName(): string {
        return this.readString("siteFilterName", "");
    }

    static set siteFilterName(newVal: string) {
        this.writeString("siteFilterName", newVal);
    }

    static get siteFilterAddress(): string {
        return this.readString("siteFilterAddress", "");
    }

    static set siteFilterAddress(newVal: string) {
        this.writeString("siteFilterAddress", newVal);
    }

    static get siteFilterType(): number {
        return this.readNumber("siteFilterType", 0);
    }

    static set siteFilterType(newVal: number) {
        this.writeNumber("siteFilterType", newVal)
    };

    static get siteFilterDistance(): number {
        return this.readNumber("siteFilterDistance", 0);
    }

    static set siteFilterDistance(newVal: number) {
        this.writeNumber("siteFilterDistance", newVal)
    };

    static get siteFilterHash(): string {
        return this.readString("siteFilterHash", "");
    }

    static set siteFilterHash(newVal: string) {
        this.writeString("siteFilterHash", newVal);
    }

    static get siteFilterHashAll(): boolean {
        return this.readBool("siteFilterHashAll", false);
    }

    static set siteFilterHashAll(newVal: boolean) {
        this.writeBool("siteFilterHashAll", newVal);
    }

    static get siteFilterColor(): string {
        return this.readString("siteFilterColor", "");
    }

    static set siteFilterColor(newVal: string) {
        this.writeString("siteFilterColor", newVal);
    }

    // static get siteFilterColors(): string[]  { return this.readStringArray("siteFilterColors", null); }
    // static set siteFilterColors(newVal: string[] ) { this.writeStringArray("siteFilterColors", newVal);}

    // static get siteFilterCheckType(): number { return this.readNumber("siteFilterCheckType", 0);}
    // static set siteFilterCheckType(newVal: number) { this.writeNumber("siteFilterCheckType", newVal) };
    //
    // static get siteFilterCheckDate(): Date { return this.readDate("siteFilterCheckDate", new Date(0)); }
    // static set siteFilterCheckDate(val: Date) {
    //   if(!val) val = new Date(0);
    //   this.writeDate("siteFilterCheckDate", val);
    // }
    //
    // static get siteFilterCheckCount(): number { return this.readNumber("siteFilterCheckCount", 0);}
    // static set siteFilterCheckCount(newVal: number) { this.writeNumber("siteFilterCheckCount", newVal) };

    static get siteFilterCheckMin(): number {
        return this.readNumber("siteFilterCheckMin", -100);
    }

    static set siteFilterCheckMin(newVal: number) {
        this.writeNumber("siteFilterCheckMin", newVal)
    };

    static get siteFilterCheckMax(): number {
        return this.readNumber("siteFilterCheckMax", 0);
    }

    static set siteFilterCheckMax(newVal: number) {
        this.writeNumber("siteFilterCheckMax", newVal)
    };

    static get siteFilterCheckWithin(): boolean {
        return this.readBool("siteFilterCheckWithin", true);
    }

    static set siteFilterCheckWithin(newVal: boolean) {
        this.writeBool("siteFilterCheckWithin", newVal);
    }

    static get taskFilterName(): string {
        return this.readString("taskFilterName", "");
    }

    static set taskFilterName(newVal: string) {
        this.writeString("taskFilterName", newVal);
    }

    static get taskFilterAddress(): string {
        return this.readString("taskFilterAddress", "");
    }

    static set taskFilterAddress(newVal: string) {
        this.writeString("taskFilterAddress", newVal);
    }

    static get taskFilterDistance(): number {
        return this.readNumber("taskFilterDistance", 0);
    }

    static set taskFilterDistance(newVal: number) {
        this.writeNumber("taskFilterDistance", newVal)
    };

    static get taskFilterHash(): string {
        return this.readString("taskFilterHash", "");
    }

    static set taskFilterHash(newVal: string) {
        this.writeString("taskFilterHash", newVal);
    }

    static get taskFilterHashAll(): boolean {
        return this.readBool("taskFilterHashAll", false);
    }

    static set taskFilterHashAll(newVal: boolean) {
        this.writeBool("taskFilterHashAll", newVal);
    }

    static get taskFilterColor(): string {
        return this.readString("taskFilterColor", "");
    }

    static set taskFilterColor(newVal: string) {
        this.writeString("taskFilterColor", newVal);
    }

    // static get taskFilterColors(): string[]  { return this.readStringArray("taskFilterColors", null); }
    // static set taskFilterColors(newVal: string[] ) { this.writeStringArray("taskFilterColors", newVal);}


    static get driverFilterName(): string {
        return this.readString("driverFilterName", "");
    }

    static set driverFilterName(newVal: string) {
        this.writeString("driverFilterName", newVal);
    }


    //#endregion


    //#region ViewPort

    static get lastLat(): number {
        return this.readNumber("lastLat", RwPrefUtils.defaultLat);
    }

    static set lastLat(newVal: number) {
        this.writeNumber("lastLat", newVal)
    };

    static get lastLng(): number {
        return this.readNumber("lastLng", RwPrefUtils.defaultLng);
    }

    static set lastLng(newVal: number) {
        this.writeNumber("lastLng", newVal)
    };

    static get viewRadius(): number {
        return this.readNumber("viewRadius", 10000);
    }

    static set viewRadius(newVal: number) {
        this.writeNumber("viewRadius", newVal)
    };

    static get viewWest(): number {
        return this.readNumber("viewWest", 0);
    }

    static set viewWest(newVal: number) {
        this.writeNumber("viewWest", newVal)
    };

    static get viewSouth(): number {
        return this.readNumber("viewSouth", 0);
    }

    static set viewSouth(newVal: number) {
        this.writeNumber("viewSouth", newVal)
    };

    static get viewEast(): number {
        return this.readNumber("viewEast", 0);
    }

    static set viewEast(newVal: number) {
        this.writeNumber("viewEast", newVal)
    };

    static get viewNorth(): number {
        return this.readNumber("viewNorth", 0);
    }

    static set viewNorth(newVal: number) {
        this.writeNumber("viewNorth", newVal)
    };

    static get viewZoom(): number {
        return this.readNumber("viewZoom", 8);
    }

    static set viewZoom(newVal: number) {
        this.writeNumber("viewZoom", newVal)
    };

    static get viewCenterLat(): number {
        return this.readNumber("viewCenterLat", RwPrefUtils.defaultLat);
    }

    static set viewCenterLat(newVal: number) {
        this.writeNumber("viewCenterLat", newVal)
    };

    static get viewCenterLng(): number {
        return this.readNumber("viewCenterLng", RwPrefUtils.defaultLng);
    }

    static set viewCenterLng(newVal: number) {
        this.writeNumber("viewCenterLng", newVal)
    };

    static get startLat(): number {
        return this.readNumber("defaultDynStartLat", 40.013249);
    }

    static set startLat(newVal: number) {
        this.writeNumber("defaultDynStartLat", newVal)
    };

    static get startLng(): number {
        return this.readNumber("defaultDynStartLng", -105.287532);
    }

    static set startLng(newVal: number) {
        this.writeNumber("defaultDynStartLng", newVal)
    };

    static get lastFixedStartLat(): number {
        return this.readNumber("lastFixedStartLat", 40.013249);
    }

    static set lastFixedStartLat(newVal: number) {
        this.writeNumber("lastFixedStartLat", newVal)
    };

    static get lastFixedStartLng(): number {
        return this.readNumber("lastFixedStartLng", -105.287532);
    }

    static set lastFixedStartLng(newVal: number) {
        this.writeNumber("lastFixedStartLng", newVal)
    };


    // static get defaultFixedStartLat(): number { return this.readNumber("defaultDynStartLat", 40.013249);}
    // static set defaultFixedStartLat(newVal: number) { this.writeNumber("defaultDynStartLat", newVal) };
    //
    // static get defaultFixedStartLng(): number { return this.readNumber("defaultFixedStartLng", -105.287532);}
    // static set defaultFixedStartLng(newVal: number) { this.writeNumber("defaultFixedStartLng", newVal) };

    static get stopColor(): string {
        return this.readString("stopColor", RwConstants.DefaultStopColor);
    }

    static set stopColor(newVal: string) {
        this.writeString("stopColor", newVal);
    }

    static get siteColor(): string {
        return this.readString("siteColor", RwConstants.DefaultSiteColor);
    }

    static set siteColor(newVal: string) {
        this.writeString("siteColor", newVal);
    }

    static get taskColor(): string {
        return this.readString("taskColor", RwConstants.DefaultTaskColor);
    }

    static set taskColor(newVal: string) {
        this.writeString("taskColor", newVal);
    }

    static get proofOfDelivery(): PODDefaultSettings {
        return this.readNumber("proofOfDelivery", RwConstants.DefaultProofOfDelivery);
    }

    static set proofOfDelivery(newVal: PODDefaultSettings) {
        this.writeNumber("proofOfDelivery", newVal);
    }

    static get rwTheme(): string {
        return this.readString("rwTheme", "rwDark");
    }

    static set rwTheme(newVal: string) {
        this.writeString("rwTheme", newVal);
    }

    static get hasActiveSub(): boolean {
        return this.readBool("hasActiveSub", false);
    }

    static set hasActiveSub(newVal: boolean) {
        this.writeBool("hasActiveSub", newVal);
    }

    static get hasOnboarded(): boolean {
        return this.readBool("hasOnboarded", false);
    }

    static set hasOnboarded(newVal: boolean) {
        this.writeBool("hasOnboarded", newVal);
    }

    static get updateStartPref(): number {
        return this.readNumber("updateStartPref", 1);
    }

    static set updateStartPref(newVal: number) {
        this.writeNumber("updateStartPref", newVal)
    };

    static get checkInStyle(): number {
        return this.readNumber("checkInStyle", 0);
    }

    static set checkInStyle(newVal: number) {
        this.writeNumber("checkInStyle", newVal)
    };

    static get pinnedStatus(): string[] {
        return this.readStringArray("pinnedStatus", []);
    }

    static set pinnedStatus(newVal: string[]) {
        this.writeStringArray("pinnedStatus", newVal);
    }

    static get defaultCheckInStatus(): number {
        return this.readNumber("defaultCheckInStatus", 10);
    }

    static set defaultCheckInStatus(newVal: number) {
        this.writeNumber("defaultCheckInStatus", newVal)
    };

    static get hasProBetaPrimed(): boolean {
        return this.readBool("hasProBetaPrimed", false);
    }

    static set hasProBetaPrimed(newVal: boolean) {
        this.writeBool("hasProBetaPrimed", newVal);
    }

    static get isTeam(): boolean {
        return this.readBool("isTeam", false);
    }

    static set isTeam(newVal: boolean) {
        this.writeBool("isTeam", newVal);
    }

    static get isAutoRenewing(): boolean {
        return this.readBool("isAutoRenewing", false);
    }

    static set isAutoRenewing(newVal: boolean) {
        this.writeBool("isAutoRenewing", newVal);
    }

    static get isProMonthly(): boolean {
        return this.readBool("isProMonthly", false);
    }

    static set isProMonthly(newVal: boolean) {
        this.writeBool("isProMonthly", newVal);
    }

    static get isDisp(): boolean {
        return this.readBool("isDisp", false);
    }

    static set isDisp(newVal: boolean) {
        this.writeBool("isDisp", newVal);
    }

    static get isFlexSolo(): boolean {
        return this.readBool("isFlexSolo", false);
    }

    static set isFlexSolo(newVal: boolean) {
        this.writeBool("isFlexSolo", newVal);
    }

    static get flexDisplay(): number {
        return this.readNumber("flexDisplay", 1);
    }

    static set flexDisplay(newVal: number) {
        this.writeNumber("flexDisplay", newVal);
    }

    static get isSimpleMap(): boolean {
        return this.readBool("isSimpleMap", false);
    }

    static set isSimpleMap(newVal: boolean) {
        this.writeBool("isSimpleMap", newVal);
    }

    static get showRouteListDetail(): boolean {
        return this.readBool("showRouteListDetail", true);
    }

    static set showRouteListDetail(newVal: boolean) {
        this.writeBool("showRouteListDetail", newVal);
    }

    static get role(): number {
        return this.readNumber("role", 0);
    }

    static set role(newVal: number) {
        this.writeNumber("role", newVal)
    };


    static get skedPriority(): number {
        return this.readNumber("skedPriority", 5);
    }

    static set skedPriority(newVal: number) {
        this.writeNumber("skedPriority", newVal)
    };

    static get skedStartMins(): number {
        let start = this.readNumber("skedStartMins", 8 * 60);
        if (start > 1440) {
            RwDeferredLog.error("RwPrefUtils.skedStartMins.GET", `WTF: SUSPECTED millis start:${start}`);
            start = 8 * 60;
            this.skedStartMins = start;
        }
        return start;
    }

    static set skedStartMins(newVal: number) {
        if (newVal > 1440) {
            RwDeferredLog.error("RwPrefUtils.skedStartMins.SET", `WTF: SUSPECTED millis: newVal:${newVal}`);
        }
        this.writeNumber("skedStartMins", newVal)
    };

    static get skedFinishMins(): number {
        let finish = this.readNumber("skedFinishMins", 17 * 60);
        if (finish > 1440) {
            RwDeferredLog.error("RwPrefUtils.skedFinishMins.GET", `WTF: SUSPECTED finish:${finish}`);
            finish = 17 * 60;
            this.skedFinishMins = finish;
        }
        return finish;
    }

    static set skedFinishMins(newVal: number) {
        if (newVal > 1440) {
            RwDeferredLog.error("RwPrefUtils.skedFinishMins.SET", `SUSPECTED millis: newVal:${newVal}`);
        }
        this.writeNumber("skedFinishMins", newVal)
    }


    //#endregion ViewPort

    //#region Reports
    static get reportsCustomPeriod(): number {
        return this.readNumber("reportsCustomPeriod", 0);
    }

    static set reportsCustomPeriod(newVal: number) {
        this.writeNumber("reportsCustomPeriod", newVal);
    }

    //#endregion Reports

    //#region SubTeams
    static get subTeamsListSortBy(): string {
        return this.readString("subTeamsListSortBy", "sortStartDate");
    }

    static set subTeamsListSortBy(newVal: string) {
        this.writeString("subTeamsListSortBy", newVal);
    }

    static get subTeamsListSortDesc(): boolean {
        return this.readBool("subTeamsListSortDesc", true);
    }

    static set subTeamsListSortDesc(newVal: boolean) {
        this.writeBool("subTeamsListSortDesc", newVal);
    }

    //#endregion SubTeams

    static get flexOptInSelected(): number {
        return this.readNumber("flexOptInSelected", 0);
    }

    static set flexOptInSelected(newVal: number) {
        this.writeNumber("flexOptInSelected", newVal);
    }

    static get lastOptInDisplayed(): boolean {
        return this.readBool("lastOptInDisplayed", false);
    }

    static set lastOptInDisplayed(newVal: boolean) {
        this.writeBool("lastOptInDisplayed", newVal);
    }

    static get isLegacy(): boolean {
        return this.readBool("isLegacy", false);
    }

    static set isLegacy(newVal: boolean) {
        this.writeBool("isLegacy", newVal);
    }

    static get isProGo(): boolean {
        return this.readBool("isProGo", false);
    }

    static set isProGo(newVal: boolean) {
        this.writeBool("isProGo", newVal);
    }

    static get lastDeferredOptIn(): string {
        return this.readString("lastDeferredOptIn", moment().subtract(1441, "minutes").format("MM-DD-YYYY hh:mm:ss"));
    }

    static set lastDeferredOptIn(newVal: string) {
        this.writeString("lastDeferredOptIn", newVal);
    }

    static get displayRoutesLimitDialog(): boolean {
        return this.readBool("displayRoutesLimitDialog", true);
    }

    static set displayRoutesLimitDialog(newVal: boolean) {
        this.writeBool("displayRoutesLimitDialog", newVal);
    }


    static get showConfirmMultiSiteToStop(): string[] {
        return this.readStringArray("showConfirmMultiSiteToStop", []);
    }

    static set showConfirmMultiSiteToStop(newVal: string[]) {
        this.writeStringArray("showConfirmMultiSiteToStop", newVal);
    }

    static get showStopViolations(): string[] {
        return this.readStringArray("showStopViolations", []);
    }

    static set showStopViolations(newVal: string[]) {
        this.writeStringArray("showStopViolations", newVal);
    }

    static get openRoutes(): string[] {
        return this.readStringArray("openRoutes", []);
    }

    static set openRoutes(newVal: string[]) {
        this.writeStringArray("openRoutes", newVal);
    }

    //#region Helper APIs

    static readBool(key: string, defaultVal: boolean): boolean {
        let val = defaultVal;
        let stored = window.localStorage.getItem(key);
        if (stored) {
            val = stored === "true";
        }
        return val;
    }

    static writeBool(key: string, newVal: boolean) {
        window.localStorage.setItem(key, newVal ? "true" : "false");
    }


    static readNumber(key: string, def: number): number {
        let value = def;
        let valueString = window.localStorage.getItem(key);
        if (valueString) {
            value = +valueString;
        }
        return value;
    }

    static writeNumber(key: string, newVal: number) {
        window.localStorage.setItem(key, newVal.toString());
    }


    static readString(key: string, def: string): string {
        let value = def;
        let valueString = window.localStorage.getItem(key);
        if (valueString) {
            value = valueString;
        }
        return value;
    }

    static writeString(key: string, newVal: string) {
        window.localStorage.setItem(key, newVal);
    }

    // static readStringDate(key: string, def:Date, dayBefore: boolean): string {
    //   let value = def;
    //   if (dayBefore) {
    //     value.setDate(value.getDate() - 1);
    //   }
    //   let date = value.toISOString().substr(0, 10);
    //   let valueString = window.localStorage.getItem(key);
    //   if (valueString) {
    //     date = valueString;
    //   }
    //   return date;
    // }
    // static writeStringDate(key:string, newVal: string) {
    //   window.localStorage.setItem(key, newVal);
    // }

    static readDate(key: string, def: Date): Date {
        let value = def;
        let valueString = window.localStorage.getItem(key);
        if (valueString) {
            value = new Date(+valueString);
        }
        return value;
    }

    static writeDate(key: string, newVal: Date) {
        window.localStorage.setItem(key, newVal.getTime().toString());
    }

    static readStringArray(key: string, def: string[]): string[] {
        let array: string[];
        let arrText = this.readString(key, null);
        if (arrText) {
            array = arrText ? arrText.split(",") : [];
        } else {
            if (def) {
                array = def;
            }
        }
        return array;
    }

    static writeStringArray(key: string, newVal: string[]) {
        this.writeString(key, newVal.toString());
    }


    static readBoolArray(key: string, def: boolean): boolean[] {
        let arrBools: boolean[];
        let arrText = this.readString(key, null);
        if (arrText) {
            let arr = arrText ? arrText.split(",") : [];
            if (arr.length > 0) {
                arr.forEach(val => {
                    let b = (val === "true");
                    if (!arrBools) {
                        arrBools = [];
                    }
                    arrBools.push(b);
                });
            }
        } else {
            if (def != null) {
                arrBools = [def];
            }
        }
        return arrBools;
    }

    static writeBoolArray(key: string, newVal: boolean[]) {
        this.writeString(key, newVal.toString());
    }


    //#endregion Helper APIs


}

// static get showPlannerList(): boolean { return this.readBool("showPlannerList", true); }
// static set showPlannerList(newVal: boolean) { this.writeBool("showPlannerList", newVal);}
//
