import {LocationCreationModel, UploadResponseModel} from "@/app/dem/RwUploadResponse";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwApiAuth} from "@/app/dem/Profile/RwApiAuth";
import {RwWebPrefs} from "@/app/dem/Profile/RwProfileModel";
import {RwLoginIssues} from "@/app/RwEnums";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import {RwLog} from '@/app/dal/RwLog';
import theGlobals from "@/app/RwGlobals";
import {RwLoginError} from "@/app/RwErrors";
import {PODDefaultSettings} from "../dem/ProofOfDelivery/ProofOfDeliveryEnums";
import {AxiosRequestConfig} from "axios";

export class RwTaskMisc {


    static submitLocations(uploadData: LocationCreationModel): Promise<UploadResponseModel> {
        const source = "RwTaskMisc.submitLocations";

        return new Promise<UploadResponseModel>(function (resolve, reject) {
            try {
                //BUG: the toJSON is not working; not tested
                //let payload = JSON.stringify(uploadData.toJSON());
                let payload = JSON.stringify(uploadData);
                let url = `${RwConstants.CoreUri}/upload/submitLocations`;
                dal.callWithToken
                    .post(url, payload, {timeout: RwConstants.UploadTimeout})
                    .then(res => {
                        const json = res.data;
                        var model = <UploadResponseModel>json;
                        resolve(model);
                    })
                    .catch((error) => {
                        //REFACTOR: UI Code: Move to caller
                        reject(error);
                    });
            } catch (err) {
                RwLog.error(source, `Exception while serializing/sending upload submit data: \n\n ${JSON.stringify(uploadData)}`);
                reject(err);
            }

        });
    }

    static uploadFreeText(uploadFormData: FormData): Promise<UploadResponseModel> {
        return new Promise<UploadResponseModel>((resolve, reject) => {
            const config: AxiosRequestConfig<FormData> = {timeout: RwConstants.NetTimeout};
            config.headers = {'Content-Type': 'multipart/form-data'};

            let url = `${RwConstants.CoreUri}/upload/UploadLocations`;
            dal.callWithToken
                .post(url, uploadFormData, config)
                .then(res => {
                    const respData = res.data as UploadResponseModel;
                    if (!!respData) {
                        resolve(respData);
                    } else {
                        reject(new Error(`Error processing text, http code:${res.status}`))
                    }
                    return res;
                })
                .catch((error) => {
                    debugger;
                    console.error(error);
                    //REFACTOR: UI Code: Move to caller

                    reject(error);
                });
        });
    }

    static clearUploadCache(): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/upload/clearUploadCache`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }


    static getApiKey(): Promise<RwApiAuth> {
        return new Promise<RwApiAuth>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/team/GetCurrentApiInfo?accountId=${theGlobals.accountId}`;
            dal.callWithToken
                .get(url)
                .then(res => {
                    const json = res.data;
                    const apiAuth = new RwApiAuth(json);
                    resolve(apiAuth);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static generateNewApiKey(): Promise<RwApiAuth> {
        let self = this;

        return new Promise<RwApiAuth>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/team/GenerateNewApiInfo?accountId=${theGlobals.accountId}`;
            dal.callWithToken
                .post(url)
                .then(res => {
                    const json = res.data;
                    var apiAuth = new RwApiAuth(json);
                    resolve(apiAuth);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });

        });
    }


    static clusterStops(stops: string[], numClusters: number): Promise<string[][]> {
        let self = this;

        return new Promise<string[][]>(function (resolve, reject) {

            let payload = JSON.stringify(stops);
            let url = `${RwConstants.CoreUri}/ops/ClusterStops?accountId=${theGlobals.accountId}&numClusters=${numClusters}`;

            dal.callWithToken
                .post(url, payload)
                .then(res => {
                    const json = res.data;
                    let clusteredStops = <Array<Array<string>>>json;
                    resolve(clusteredStops);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });

        });
    }


    static colorizeStops(stopIds: string[], routeId: string, baseColor: string): Promise<boolean> {

        //REVIEW: "this" is not what you think it is in static method
        //let self = this;

        return new Promise<boolean>(function (resolve, reject) {

            let payload = JSON.stringify(stopIds);
            const colorEnc = encodeURIComponent(baseColor);
            let url = `${RwConstants.CoreUri}/ops/UpdateStopsBaseColor?baseColor=${colorEnc}&routeId=${routeId}`;
            dal.callWithToken
                .post(url, payload)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static getLatestFlexVersion(): Promise<string> {
        return new Promise<string>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/team/LatestFlexVersion`;
            dal.callSansToken
                .get(url)
                .then(res => {
                    resolve(res.data as string);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static getWebPrefs(): Promise<RwWebPrefs> {
        const SOURCE = "RwTaskMisc.getWebPrefs";

        return new Promise<RwWebPrefs>(function (resolve, reject) {

            let token = RwPrefUtils.token;
            if (dal.gotToken() && theGlobals.isLoggedIn) {

                let url = `${RwConstants.CoreUri}/profile/webPrefs`;

                dal.callWithToken
                    .get(url)
                    .then(res => {
                        if (res) {
                            if (res.data) {
                                const json = res.data;
                                //console.warn("getWebPrefs", json);
                                let webPrefs: RwWebPrefs = JSON.parse(json);
                                resolve(webPrefs);
                            } else {
                                RwLog.error(SOURCE, `GET ${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}`);
                                reject();
                            }
                        } else {
                            RwLog.error(SOURCE, `GET ${url} -> MISSING \nAuthToken:${RwPrefUtils.token}`);
                            reject();
                        }
                    })
                    .catch(err => {
                        if (theGlobals.checkNotHandled(err)) {
                            let errText = JSON.stringify(err);
                            let msg = `Unhandled: GET ${url} -> ${err.status} \nAuthToken:${token} \n\nError:\n${errText}`;
                            if ((err.error && (err.error.code === "ECONNABORTED" || err.error.message === "Network Error")) ||
                                err.error === "Network Error") {
                                RwLog.warn(SOURCE, msg);
                            } else {
                                RwLog.error(SOURCE, msg);
                            }
                        }
                        reject(err);
                    });

            } else {
                RwLog.warn(SOURCE, `Invalid token: ${RwPrefUtils.token}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }


    static setWebPrefs(prefs: RwWebPrefs): Promise<boolean> {
        //console.warn("RwTaskMisc.setWebPrefs");

        return new Promise<boolean>(function (resolve, reject) {

            let payload = JSON.stringify(prefs);
            //console.warn("setWebPrefs", payload);
            let url = `${RwConstants.CoreUri}/profile/webPrefs`;
            dal.callWithToken
                .post(url, payload)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }

    static setProofOfDeliveryDefault(type: PODDefaultSettings): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {
            let payload = type;
            let url = `${RwConstants.CoreUri}/profile/PoDDefault`;
            dal.callWithToken
                .post(url, payload)
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}


