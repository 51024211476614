import moment from "moment";

export class RwTextUtils {
    static truncate(
        truncateStr: string,
        n: number,
        useWordBoundary: boolean
    ): string {
        if (truncateStr.length <= n) {
            return truncateStr;
        }
        var subString = truncateStr.substr(0, n - 1);
        return (
            (useWordBoundary
                ? subString.substr(0, subString.lastIndexOf(" "))
                : subString) + "..."
        );
    }

    static regExCoord: RegExp = new RegExp(
        "^(\\-?\\d+(\\.\\d+)?),\\s*(\\-?\\d+(\\.\\d+)?)$"
    );

    static getTimeStamp(date: Date): string {
        let x = moment(date);
        let ts = x.format();
        //RwLog.logConsole("timestamp:", ts);
        return ts;
    }

    static formatCoords(lat: number, lng: number): string {
        if (lat !== 0 && lng !== 0) {
            return `${Math.round(10000 * lat) / 10000}, ${Math.round(10000 * lng) /
            10000}`;
        }
        return "";
    }

    static getPartialName(longName: string, charCount: number): string {
        let partName = longName;
        if (longName.length <= charCount) {
            partName = longName;
        } else {
            let rawTokens = longName.split(",");
            if (rawTokens.length >= 1) {
                let rawToken = rawTokens[0];
                let token = rawToken.trim();
                if (token.length > 32) {
                    partName = token.substr(0, 32);
                } else {
                    partName = token;
                }
            }
        }
        return partName;
    }

    static isCoordinate(someText: String): boolean {
        let cleanText = someText.replace(" ", "")
            .replace("\t", "");
        let isCoord = RwTextUtils.regExCoord.test(cleanText);
        //RwLog.logConsole("isCoord", cleanText, isCoord);
        return isCoord;
    }

    static coordFromText(coordText: String): number[] {
        let coords: number[] = null;
        let cleanText = coordText.replace(" ", "").replace("\t", "");
        let isCoord = RwTextUtils.regExCoord.test(cleanText);
        if (isCoord) {
            let rawTokens = cleanText.split(",");
            if (rawTokens.length == 2) {
                let lat = rawTokens[0].trim();
                let lng = rawTokens[1].trim();
                coords = [];
                coords.push(+lat);
                coords.push(+lng);
            }
        }
        return coords;
    }


    static formatDuration(seconds: number): string {

        let durText = "";
        let duration = moment.duration(seconds, "seconds");

        if (duration.days() > 0) {
            durText = `${duration.days()}d ${duration.hours()}h ${duration.minutes().toString()}m`;
        } else if (duration.hours() > 0) {
            durText = `${duration.hours()}h ${duration.minutes().toString()}m`;
        } else if (duration.minutes() > 0) {
            durText = `${duration.minutes().toString()}m`;
        } else {
            durText = `${duration.seconds().toString()}s`;
        }

        // if (duration.days() > 0) {
        //   durText = `${duration.days()}d ${duration.hours()}:${duration.minutes().toString().padStart(2, '0')}`;
        // }
        // else {
        //   durText = `${duration.hours()}:${duration.minutes().toString().padStart(2, '0')}`;
        // }
        return durText;
    }

    static round(num: number, places: number): number {
        let factor = Math.pow(10, places);
        return Math.round(num * factor) / factor;
    }


    static round4(num: number): number {
        return Math.round(num * 10000) / 10000;
    }


    // static formatDurationFromDuration(duration:moment.Duration):string {

    //   let durText = "";
    //   var duration = moment.duration(seconds, "seconds");

    //   if (duration.days() > 0) {
    //     durText = `${duration.days()} days ${duration.hours()}:${duration.minutes().toString().padStart(2, '0')}`;
    //   }
    //   else {
    //     durText = `${duration.hours()}:${duration.minutes().toString().padStart(2, '0')}`;
    //   }
    //   return durText;
    // }

    // static scheduledTextMils(startMils:number, finishMils:number): string {
    //   let start = moment().startOf('day').add(startMils, "milliseconds");
    //   let finish = moment().startOf('day').add(finishMils, "milliseconds");
    //   let startText = start.format("LT");
    //   let finishText = (startMils < finishMils) ? finish.format("LT") : finish.format("LT") + " (next day)";
    //   let text = `${startText} to ${finishText}`;
    //   return text;
    // }


    static scheduledTextMils(startMils: number, finishMils: number): string {
        //console.log("scheduledTextMils", startMils, finishMils);
        return RwTextUtils.scheduledTextMins(startMils / 60000, finishMils / 60000);
    }

    static scheduledTextMins(startMins: number, finishMins: number): string {
        //console.log("scheduledTextMins", startMins, finishMins);
        let day = moment().startOf('day')
        let start = day.clone().add(startMins, "minutes");
        let finish = day.clone().add(finishMins, "minutes");
        let startText = start.format("LT");
        let finishText = (startMins < finishMins) ? finish.format("LT") : finish.format("LT") + " (next day)";
        let text = `${startText} to ${finishText}`;
        //console.log("scheduledTextMins", text);
        return text;
    }

}
