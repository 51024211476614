import {RwLog} from "@/app/dal/RwLog";

export class RwLocalDB {

    schema = {
        stores: [
            {
                name: "svgs",
                keyPath: "hash"
            }
        ]
    };
    options = {
        Encryption: {
            encryptKey: false, // optional encrypt primary key
            method: 'rc4', // default is 'acs-cbc'
            secrets: [{
                name: 'aaaa',
                key: 'aYHF6vfuGHpfWS*eRLrPQxZjSó~É5c6HjCscqDqRtZasp¡JWSMGaW'
            }]
        }
    };
    // @ts-ignore
    db = new ydn.db.Storage('mainData', this.schema, this.options);

    delete(store, key) {
        const SOURCE = "RwLocalDB.delete";
        this.db
            .remove(store, key)
            .fail(ex => this.handleException(SOURCE, ex, key));
    };

    getAll(store): Promise<any> {
        const SOURCE = "RwLocalDB.getAll";
        const df = this.db.values(store);
        return df.fail(ex => this.handleException(SOURCE, ex))
    };


    add(store, item) {
        const SOURCE = "RwLocalDB.add";
        this.db.put(store, item)
            .fail(ex => this.handleException(SOURCE, ex));
    };

    init() {
        const SOURCE = "RwLocalDB.init";
        const svgs = this.getAll("svgs");
        return Promise.all([svgs]).catch(ex => this.handleException(SOURCE, ex))
    }

    handleException(SOURCE, ex, key = null) {
        let name = null;
        let msg = null;
        if (ex.name) name = ex.name;
        if (ex.message) name = ex.message;
        if (name || msg) RwLog.error(SOURCE, `Unhandled: key:${key} \n\nError: ${name} \nMessage: ${msg}`);
        else RwLog.error(SOURCE, `Unhandled: key:${key} \n\n${JSON.stringify(ex)}`);
    }
}

export default new RwLocalDB();
