import moment from "moment";
import {RwStop} from "@/app/dem/RwStop";
import {RwSite} from "@/app/dem/RwSite";
import {RwUser} from "@/app/dem/RwUser";
import {RwRoute} from "@/app/dem/RwRoute";
import {RwImgUtils} from "@/app/utils/RwImgUtils";
import {RwPinSubTypesStop, RwPinTypes, RwStopVios} from "@/app/RwEnums";
import {RwConstants} from "@/app/RwConstants";
import {RwLog} from '@/app/dal/RwLog';
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace"
import {RwAddress} from "@/app/dem/RwSearchResult";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwTextUtils} from "@/app/utils/RwTextUtils";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwWebUtils} from "@/app/utils/RwWebUtils";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import {md5} from "md5js";
import localDB from "@/app/RwLocalDB";

export class RwPinArgs {
    typ: RwPinTypes;
    sub?: number;
    seq?: number;
    drp?: number;
    pri?: number;
    vio?: number;
    color?: string;
    isFirst?: boolean;
    isFinal?: boolean;
    isSelected?: boolean;
    isSked?: boolean;
    hasDrp?: boolean;
    src?: string;
    selectRatio? = 0;
    isComplete? = false;

    static isColorNonDefault(args: RwPinArgs): boolean {
        let isNonDefault = false;
        if (args.color && args.color.length > 0) {
            switch (args.typ) {
                case RwPinTypes.Stop:
                    if (args.color.toUpperCase() !== "#E91E63") isNonDefault = true;
                    break;
                case RwPinTypes.Task:
                    if (args.color.toUpperCase() !== "#E91E63") isNonDefault = true;
                    break;
                case RwPinTypes.Site:
                    if (args.color.toUpperCase() !== "#40A23F") isNonDefault = true;
                    break;
            }
        }
        return isNonDefault;
    }

}


export class RwPin {

    //#region Constructors

    static fromRoute(route: RwRoute): RwPin {
        let pin: RwPin = null;
        if (route != null) {
            pin = new RwPin();
            pin.type = RwPinTypes.Route;
            pin.id = route.routeId;
            pin.title = route.name;
            pin.lat = route.lat;
            pin.lng = route.lng;
            pin.route = route;
        }
        return pin;
    }

    static fromStop(stop: RwStop): RwPin {
        let pin = new RwPin();
        this.updateStopPin(pin, stop);
        return pin;
    }

    static updateStopPin(pin: RwPin, stop: RwStop) {
        let route = thePlanSpace.activeRoute;
        if (stop != null && route != null) {
            pin.invalidatePinImage();
            pin.type = RwPinTypes.Stop;
            pin.id = stop.pinId;
            pin.title = stop.name;
            pin.lat = stop.lat;
            pin.lng = stop.lng;
            pin.seq = stop.seq;
            pin.stop = stop;
            pin.isComplete = stop.isComplete;
            pin.isFirst = route.isFirstStop(stop);
            pin.isFinal = route.isFinalStop(stop);
            pin.note = stop.note;
            pin.isTask = stop.isTask;
            pin.colorGroup = stop.colorGroup;
            pin.stopSeq = stop.seq;
            //console.warn("updateStopPin", pin.title, pin.stopSeq)


            let sked = stop.getSked();
            if (sked != null) {
                pin.isSked = true;
                pin.priority = sked.priority;
                stop.priority = sked.priority;

                // if(stop.openTime > 0 || stop.closeTime > 0){
                //   console.log("Stop.updateStopPin openTime, closeTime", stop.openTime, stop.closeTime)
                // }

                pin.isAvail = stop.getIsAvailable(sked);
                pin.isVio = stop.gotViolations(pin.isAvail);
                let vios = stop.getViolations(pin.isAvail);
                let isActive = stop.getIsActivePin(pin.isAvail, pin.isComplete, vios);
                pin.isActive = isActive;
                stop.isActive = isActive;
            } else {
                pin.isAvail = stop.getIsAvailable(null);
                pin.isActive = true;
                stop.isActive = true;
                pin.isVio = stop.gotViolations(pin.isAvail);
            }

        }
    }

    static fromStopPins(pins: RwPin[]): RwPin {
        let pin: RwPin = null;
        let route = thePlanSpace.activeRoute;
        if (route && pins && pins.length > 1) {
            pin = new RwPin();
            pin.type = RwPinTypes.Stop;
            //pin.subType = RwPinSubTypesStop.Cluster;
            pin.id = RwSysUtils.guidNew();
            pin.clusteredPins = pins;
            pins.forEach(p => p.clusterParent = pin);

            //For data purposes sort by "seq desc" and take the 1st stop pin
            let firstStop: RwPin;
            let sequencedStops = pins.filter(s => s.seq > 0 && s.seq < thePlanSpace.maxRouteSize);
            if (sequencedStops.length > 0) {
                if (sequencedStops.length == 1) {
                    firstStop = sequencedStops[0];
                } else {
                    let orderedStops = pins
                        .filter(s => s.seq > 0 && s.seq < thePlanSpace.maxRouteSize)
                        .sort((s1, s2) => {
                            if (s1.seq < s2.seq) return -1;
                            if (s1.seq > s2.seq) return 1;
                            return 0;
                        })
                    firstStop = orderedStops[0];
                }
            } else {
                firstStop = pins[0];
            }


            if (firstStop) {
                let coordText = RwTextUtils.formatCoords(firstStop.lat, firstStop.lng);
                pin.title = `${pins.length} Stops @ ${coordText}`;
                pin.lat = firstStop.lat;
                pin.lng = firstStop.lng;
                pin.seq = firstStop.seq;
                pin.stopSeq = firstStop.seq;
            } else {
                RwLog.consoleError("WTF: Cluster: no first stop", pins, firstStop);
            }
        }
        return pin;
    }

    static fromTask(task: RwStop): RwPin {
        let pin: RwPin = null;
        if (task) {
            pin = new RwPin();
            pin.type = RwPinTypes.Task;
            pin.id = task.pinId;
            pin.title = task.name;
            //pin.address = task.address;
            pin.lat = task.lat;
            pin.lng = task.lng;
            pin.seq = task.seq;
            pin.task = task;
            pin.note = task.note;
            pin.isTask = true;
            pin.colorGroup = task.colorGroup;
            pin.stopSeq = task.seq;
        }
        return pin;
    }

    static fromSite(site: RwSite): RwPin {
        let pin: RwPin = null;
        if (site != null) {
            pin = new RwPin();
            pin.type = RwPinTypes.Site;
            pin.id = site.siteId;
            pin.title = site.name;
            pin.lat = site.lat;
            pin.lng = site.lng;
            pin.site = site;
            pin.note = site.note;


            let route = thePlanSpace.activeRoute;
            if (route) {
                pin.isAvail = site.isAvailable(route.startTime, route.finishTime);
            }

        }
        return pin;
    }

    static fromDriver(driver: RwUser): RwPin {
        let pin: RwPin = null;
        if (driver != null) {
            pin = new RwPin();
            pin.type = RwPinTypes.Driver;
            pin.id = driver.userId;
            pin.title = driver.userName;

            //console.warn("RwPin.fromDriver", driver.userName, driver.name, driver);
            //console.warn("RwPin.fromDriver", driver.userName, pin.title);

            pin.lat = driver.lat || 0;
            pin.lng = driver.lng || 0;
            pin.driver = driver;

            // let route = RwApp.model.driver.activeRoute;
            // pin.isAvail = site.isAvailable(route.schedStart, route.schedEnd);
        }
        return pin;
    }


    //#endregion


    //#region Props

    id: string;
    title: string;
    lat: number = 0;
    lng: number = 0;
    seq: number = 0;
    type: RwPinTypes = RwPinTypes.Stop;
    stop: RwStop;
    site: RwSite;
    task: RwStop;
    driver: RwUser;
    route: RwRoute;

    isAvail: boolean = true;
    isActive: boolean = true;
    isFirst: boolean = false;
    isFinal: boolean = false;
    isComplete: boolean = false;
    isSked: boolean = false;
    priority: number = 0;
    stopSeq: number = 0;
    isVio: boolean = false;

    isTask: boolean = false;
    colorGroup: number = 0;

    start: number = 0;
    end: number = 0;
    note: string = "";

    private cachedProximity: number = 0;
    private cachedProximityLat: number = 0;
    private cachedProximityLng: number = 0;

    marker?: RwMarker;
    clusterParent: RwPin;
    clusteredPins: Array<RwPin>;


    lastHash: number = 0;
    lastArgsUpdate: number = 0;
    isHovering: boolean = false;
    isFocused: boolean = false;

    get plan(): RwPlannerSpace {
        return thePlanSpace;
    }


    get hasNote(): boolean {
        if (this.note && this.note !== "") {
            return true;
        }
        return false;
    }


    get hasDrop(): boolean {
        if (this.type == RwPinTypes.Stop && this.stop) {
            return this.stop.hasDrops();
        }
        return false;
    }


    get showCheckStopTime(): boolean {
        if (this.type === RwPinTypes.Stop && this.stop && this.stop.checkDate) {
            return true;
        }
        return false;
    }


    get showSvcTimePin(): boolean {
        if (this.type === RwPinTypes.Stop && this.stop && !this.stop.checkDate) {
            return this.stop.visitTime !== -1;
        }
        return false;
    }


    get showSvcTimeStar(): boolean {
        if (
            this.type === RwPinTypes.Stop &&
            this.stop &&
            this.stop.visitTime === -1 &&
            this.stop.siteId &&
            !this.stop.checkDate
        ) {
            let site = this.stop.getSite();
            if (site && site.visitTime !== -1) {
                return true;
            }
        }
        return false;
    }


    get dropSeq(): number {
        if (this.type == RwPinTypes.Stop && this.stop) {
            let drops = this.stop.getDrops();

            if (drops && drops.length > 0) {
                return drops[0].seq;
            }

            return 0;
            //return this.stop.drop
        }
        return 0;
    }


    get displayStop(): string {
        if (this.type === RwPinTypes.Stop) {
            return "";
        }
        return "display-gone";
    }


    get travelText(): string {
        switch (this.type) {
            case RwPinTypes.Stop:
                if (this.stop) {
                    return this.stop.travelText;
                }
                break;

            case RwPinTypes.Site:
                if (this.site) {
                    return this.site.lastCheckInText;
                }
                break;

            case RwPinTypes.Route:
                if (this.route) {
                    return `Travel Time: ${this.route.travelTimeText}`;
                }
                break;
        }
        return "";
    }


    get durationText(): string {
        switch (this.type) {
            case RwPinTypes.Stop:
                if (this.stop) {
                    return this.stop.visitTimeText;
                }
                break;

            case RwPinTypes.Route:
                if (this.route) {
                    return `Total Time: ${this.route.totalTimeString}`;
                }
                break;
        }
        return "";
    }


    get timeText(): string {
        let timeText = "";
        switch (this.type) {
            case RwPinTypes.Stop:
                if (this.stop) {
                    timeText = this.stop.timeText;
                }
                break;

            case RwPinTypes.Route:

                if (this.route) {
                    timeText = `Start Time: ${this.route.startTimeString}`;
                    //MONITOR: if there is need for an abstract finish location pin
                }

                break;

        }
        return timeText;
    }


    get timeFinishText(): string {
        const finishTime = moment(this.route.startTime).add(this.route.totalTime, "seconds");
        const timeText = `Finish Time: ${finishTime.format("L LT")}`;
        return timeText;
    }


    get baseOrder(): number {
        let order = -1;
        switch (this.type) {

            case RwPinTypes.Route:
                if (this.route) {
                    order = 0;
                }
                break;

            case RwPinTypes.Stop:
                if (this.stop) {
                    if (this.stop.isActive) {
                        order = this.stop.seq + 1;
                    } else {
                        order = 251 + this.stop.seq;
                    }
                }
                break;

            case RwPinTypes.Site:
                if (this.site) {
                    order = this.stringHash(this.baseColor, true);
                }
                break;

            case RwPinTypes.Task:
                if (this.task) {
                    order = this.stringHash(this.baseColor, true);
                }
                break;
        }
        return order;
    }

    stringHash(string, noType) {
        let hashString = string;
        if (!noType) {
            hashString = `string${string}`;
        }
        let hash = 0;
        for (let i = 0; i < hashString.length; i++) {
            const character = hashString.charCodeAt(i);
            hash = ((hash << 5) - hash) + character;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }


    get violationText(): string {
        let vioText = ""
        switch (this.type) {
            case RwPinTypes.Stop:
                if (this.stop) {
                    vioText = this.stop.violationText;
                }
                break;

            case RwPinTypes.Site:
                if (this.site) {
                    vioText = this.site.violationText(this.isAvail);
                }
                break;
        }
        return vioText;
    }


    get address(): string {
        switch (this.type) {
            case RwPinTypes.Stop:
                if (this.stop) {
                    return this.stop.address;
                }
                break;

            case RwPinTypes.Task:
                if (this.task) {
                    return this.task.address;
                }
                break;

            case RwPinTypes.Site:
                if (this.site) {
                    return this.site.address;
                }
                break;


            case RwPinTypes.Route:
                //HACK: Seems odd to not show address here
                if (this.route) {
                    return this.route.address;
                    //return `Travel Dist: ${this.route.totalDistText}`;
                }
                break;
            // case RwPinTypes.End:
            //   break;
        }
        return "";
    }


    get subType(): number {
        let sub = 0;
        if (this.type === RwPinTypes.Stop) {
            let stop = this.stop;
            let route = this.plan.activeRoute;

            if (this.isCluster) {
                sub = RwPinSubTypesStop.Cluster;
            } else {
                if (stop && route) {
                    sub = this.calcSubTypeStop(stop, route);
                }
            }
        } else {
            sub = -1;
        }
        return sub;
    }


    get isRoutePin() {
        let isRoutePin = false;
        //let pinTypes = [RwPinTypes.Stop, RwPinTypes.Cluster, RwPinTypes.Start, RwPinTypes.End];
        let pinTypes = [RwPinTypes.Stop, RwPinTypes.Route];
        if (pinTypes.find(pt => pt == this.type)) {
            isRoutePin = true;
        }
        return isRoutePin;
    }


    get eid(): string {
        let eid: string;
        switch (this.type) {
            //@formatter:off
            case RwPinTypes.Route:
                eid = this.route.routeId;
                break;
            case RwPinTypes.Site:
                eid = this.site.siteId;
                break;
            case RwPinTypes.Task:
                eid = this.task.stopId;
                break;
            case RwPinTypes.Stop: {
                if (this.stop) {
                    eid = this.stop.stopId;
                }
                //NOTE: Clusters will not have an eid
                break;
            }
            //@formatter:on
        }
        return eid;
    }

    get baseColor(): string {
        let colorHex: string;
        switch (this.type) {
            //@formatter:off
            case RwPinTypes.Stop:
                colorHex = this.stop.baseColor;
                break;
            case RwPinTypes.Site:
                colorHex = this.site.baseColor;
                break;
            case RwPinTypes.Task:
                colorHex = this.task.baseColor;
                break;
            //@formatter:on
        }
        return colorHex;
    }

    set baseColor(colorHex: string) {
        switch (this.type) {
            //@formatter:off
            case RwPinTypes.Stop:
                this.stop.baseColor = colorHex;
                break;
            case RwPinTypes.Site:
                this.site.baseColor = colorHex;
                break;
            case RwPinTypes.Task:
                this.task.baseColor = colorHex;
                break;
            //@formatter:on
        }
    }


    get visitTime(): number {
        let visitTime: number;
        switch (this.type) {
            //@formatter:off
            case RwPinTypes.Stop:
                visitTime = this.stop.visitTime;
                break;
            case RwPinTypes.Site:
                visitTime = this.site.visitTime;
                break;
            case RwPinTypes.Task:
                visitTime = this.task.visitTime;
                break;
            //@formatter:on
        }
        return visitTime;
    }

    set visitTime(visitTime: number) {
        switch (this.type) {
            //@formatter:off
            case RwPinTypes.Stop:
                this.stop.visitTime = visitTime;
                break;
            case RwPinTypes.Site:
                this.site.visitTime = visitTime;
                break;
            case RwPinTypes.Task:
                this.task.visitTime = visitTime;
                break;
            //@formatter:on
        }
    }

    static get searchPinSVG() {
        const el = document.createElement("div");
        const wrapper = document.createElement("div");
        const img = document.createElement("img");
        img.src = "/images/icons/targetPrimary.png";
        img.style.maxWidth = "100%";
        wrapper.id = "search-marker-wrapper"

        wrapper.style.width = "48px";
        wrapper.style.height = "48px";
        wrapper.appendChild(img);
        el.appendChild(wrapper);
        return el;
    }

    invalidatePinImage() {
        this._imgArgs = null;
        this._dataUrl = null;
        this._urlHash = null;
    }


    _imgArgs: RwPinArgs;
    get imgArgs(): RwPinArgs {
        if (!this._imgArgs) {
            this._imgArgs = this.createImgArgs()
        }
        return this._imgArgs;
    }

    _urlHash: string;
    get urlHash(): string {
        if (!this._urlHash) {
            this._urlHash = RwPin.getPinHash(this.imgArgs)
        }
        return this._urlHash;
    }


    _dataUrl: string = undefined;
    get dataUrl(): string {
        const self = this;
        let url = thePlanSpace.globals.idxURL[this.urlHash];
        if (url && url.length > 0) {
            return url;
        } else {
            self
                .dataUrlAsync()
                .then(newUrl => {
                    this._dataUrl = newUrl;
                });
        }
        //console.log("RwPin.dataUrl return this._dataUrl", this._dataUrl)
        return this._dataUrl;
    }


    dataUrlAsync(): Promise<string> {
        const self = this;
        const SOURCE = "RwPin.dataUrlAsync";
        let url: string;

        return new Promise<string>((resolve) => {

            switch (this.type) {

                case RwPinTypes.Route: {
                    url = this.getRouteUrl();
                    resolve(url);
                    break;
                }

                case RwPinTypes.Stop: {
                    let stop = self.stop;
                    if (stop || this.isCluster) {
                        let url = thePlanSpace.globals.idxURL[this.urlHash];
                        if (url && url.length > 0) {
                            //console.log("dataUrlAsync stop url", url);
                            resolve(url);
                        } else {
                            RwPin
                                .calcStopPinUrl(this.urlHash, this.imgArgs)
                                .then(dataUrl => {
                                    //console.log("dataUrlAsync stop dataUrl", dataUrl);
                                    resolve(dataUrl);
                                });
                        }

                    }
                    break;
                }

                case RwPinTypes.Site: {
                    let site = this.site;
                    if (site) {
                        let url = thePlanSpace.globals.idxURL[this.urlHash];
                        if (url && url.length > 0) {
                            resolve(url);
                        } else {
                            RwPin
                                .calcSitePinUrlNew(this.urlHash, this.imgArgs)
                                .then(dataUrl => {
                                    url = dataUrl;
                                    resolve(url);
                                });
                        }
                        //url = RwImgUtils.calcSitePinUrl(site.isAvail, site.priority, site.baseColor, isSelected);
                    }
                    //resolve(url);
                    break;
                }

                case RwPinTypes.Task: {
                    let task = this.task;
                    if (task) {

                        let url = thePlanSpace.globals.idxURL[this.urlHash];
                        if (url && url.length > 0) {
                            resolve(url);
                        } else {
                            RwPin
                                .calcTaskPinUrl(this.urlHash, this.imgArgs)
                                .then(dataUrl => {
                                    resolve(dataUrl);
                                });
                            //url = RwImgUtils.calcTaskPinUrl(task.baseColor, isSelected);
                        }
                    }
                    //resolve(url);
                    break;
                }

                case RwPinTypes.Driver: {
                    //xREF: DriverPin Update Pin Url;
                    let driver = this.driver;
                    if (driver) {
                        if (driver.imageUrl) {
                            url = driver.imageUrl;
                            //console.log("RwPin.imgUrl Driver", url);
                        }
                    } else {
                        RwLog.consoleError("WTF: driver == null");
                    }
                    resolve(url);
                    break;
                }
            }

        });

    }


    get dataItem() {
        let dataItem;
        if (this.stop) dataItem = this.stop;
        else if (this.task) dataItem = this.task;
        else if (this.driver) dataItem = this.driver;
        else if (this.route) dataItem = this.route;
        else if (this.site) dataItem = this.site;
        return dataItem;
    }


    private getRouteUrl() {
        let url = "";
        let route = this.route;
        if (route != null) {
            const baseDomain = window.location.origin;
            switch (route.subType) {
                case 0:
                    url = `${baseDomain}/images/icons/icc_pin_flag_green.png`;
                    break;
                case 1:
                    url = `${baseDomain}/images/icons/start_ring.png`;
                    break;
                case 2:
                    url = `${baseDomain}/images/icons/icc_pin_flag_rt.png`;
                    break;
                case 3:
                    url = `${baseDomain}/images/icons/icc_pin_flag_grey.png`;
                    break;
                case 4:
                    url = `${baseDomain}/images/icons/start_ring_x.png`;
                    break;
                case 5:
                    url = `${baseDomain}/images/icons/icc_pin_flag_rt_inactive.png`;
                    break;

                default:
                    url = "/images/icons/icc_pin_flag_green.png";
                    RwLog.error("RwPin.getRouteUrl", `Invalid subtype for icon: ${route.subType}, routeid: ${route.routeId}`);
                    break;
            }
        }
        return url;
    }


    get zIndex(): number {
        //make the lower the sequence, the higher the z-order
        let z = 0;

        //console.log("RwPin.zIndex isSelected", this.title)
        let isSelected = this.plan.isSelected(this);

        switch (this.type) {

            case RwPinTypes.Stop:
            case RwPinTypes.Task:
                if (!this.isComplete) {
                    z = 3;
                    let stopCount = this.plan.maxRouteSize;
                    if (this.plan.activeRoute && this.plan.activeRoute.stops) {
                        stopCount = this.plan.activeRoute.stops.length
                    }

                    if (this.seq >= 0 && this.seq <= this.plan.maxRouteSize) {
                        z = stopCount + 5 - this.seq;
                    }
                }
                break;

            case RwPinTypes.Site:
                z = 2;
                break;
            case RwPinTypes.Driver:
                z = 1;
                break;

        }

        //New Map Appears to use ZIndex of lower is Top
        let finalZ = 1000 - (z + (isSelected ? 240 : 0));
        //console.warn(`${this.title}, isSelected:${isSelected}, zIndex:${finalZ}`)
        return finalZ;
    }


    get scale(): number {
        //make the lower the sequence, the higher the z-order
        let scale = 1;
        switch (this.type) {
            case RwPinTypes.Stop:
                scale = 1;
                break;
            case RwPinTypes.Site:
                scale = 40 / 48;
                break;
        }
        return scale;
    }


    get isSite(): boolean {
        return this.type === RwPinTypes.Site;
    }


    get isStop(): boolean {
        return this.type === RwPinTypes.Stop;
    }


    get isCluster(): boolean {
        return this.clusteredPins && this.clusteredPins.length > 0;
    }

    //#endregion


    private calcSubTypeStop(stop: RwStop, route: RwRoute) {

        let sub = 0;
        if (stop.isComplete) {
            sub = RwPinSubTypesStop.CheckIn;
        } else {

            if (this.isCluster) {
                sub = RwPinSubTypesStop.Cluster;
            } else {

                if (this.isAvail) {
                    sub = RwPinSubTypesStop.Stop;

                    if (this.isVio && stop.violations == RwStopVios.Unreachable) {
                        sub = RwPinSubTypesStop.Infeasible;
                    } else if (this.isVio && stop.violations == RwStopVios.Closed) {
                        sub = RwPinSubTypesStop.DoNotEnter;
                    }
                } else {
                    //console.warn("not available", stop);
                    sub = RwPinSubTypesStop.DoNotEnter;
                }
            }
        }

        return sub;
    }


    createImgArgs(): RwPinArgs {

        let args = new RwPinArgs();
        const route = this.plan.activeRoute;
        //console.log("RwPin.createImgArgs title, type ", this.title, this.type)
        let isSelected = this.plan.isSelected(this);
        switch (this.type) {

            case RwPinTypes.Route: {
                args = {typ: RwPinTypes.Route, sub: route.subType};
                break;
            }

            case RwPinTypes.Stop: {
                if (!this.isCluster) {
                    args = this.createStopImgArgs(route, args, isSelected);
                } else {
                    args = this.createClusterImgArgs(args, isSelected);
                }
                break;
            }

            case RwPinTypes.Site: {
                args = this.createSiteImgArgs(args, isSelected);
                break;
            }

            case RwPinTypes.Task: {
                args = this.createTaskImgArgs(args, isSelected);
                break;
            }

            case RwPinTypes.Driver: {
                //args = this.createTaskImgArgs(args, isSelected);
                break;
            }

            default:
                RwLog.consoleError("WTF: Unexpected type", this.type)
                break;

        }
        return args;
    }

    private createTaskImgArgs(args: RwPinArgs, isSelected: boolean) {
        let task = this.task;
        if (task) {
            args = {
                typ: RwPinTypes.Task,
                isSelected: isSelected,
                color: task.baseColor,
                src: this.title,
            };
        }
        return args;
    }

    private createSiteImgArgs(args: RwPinArgs, isSelected: boolean) {
        let site = this.site;
        if (site) {
            //url = RwImgUtils.calcSitePinUrl(site.isAvail, site.priority, site.baseColor, isSelected);
            let baseColor = site.baseColor;
            let pri = site.priority;
            if (!baseColor) baseColor = baseColor = RwImgUtils.getHexColorFromPriority(pri);
            args = {
                typ: RwPinTypes.Site,
                isSelected: isSelected,
                color: baseColor,
                src: this.title,
            };
        }
        return args;
    }

    private createStopImgArgs(route: RwRoute, args: RwPinArgs, isSelected: boolean) {
        let stop = this.stop;
        if (stop) {
            let sub = this.calcSubTypeStop(stop, route);
            let color = sub === 1 ? RwConstants.CheckinPinGrey : stop.baseColor;
            args = {
                typ: this.type,
                sub: sub,
                color: color,
                isSelected: isSelected,
                drp: this.dropSeq, //Calculated from Drops
                seq: stop.seq,
                isSked: stop.isScheduled,
                pri: this.isSked ? stop.priority : undefined,
                vio: stop.violations,
                src: this.title,
                hasDrp: stop.hasDrops()
            };

            // if (stop.name === "SkedTest") {
            //   console.log("RwPin.imgArgs", "SkedTest isScheduled, subType, pri", stop.isScheduled, subType, stop.priority);
            // }

            if (route) {
                args.isFirst = this.stop.isMarkedFirst(route);
                args.isFinal = this.stop.isMarkedFinal(route);
            }
            //console.warn("logs 1", args);
        }
        return args;
    }

    private createClusterImgArgs(args: RwPinArgs, isSelected: boolean) {
        const self = this;
        //console.log("createClusterImgArgs");
        let sub = RwPinSubTypesStop.Cluster;
        let color = RwPrefUtils.stopColor;
        let allChecked = this.clusteredPins.every(p => p.stop && p.stop.isComplete);
        //console.warn("********* createClusterImgArgs allChecked", allChecked)
        if (allChecked) {
            color = RwConstants.CheckinPinGrey;
        } else {
            let colorSet = [...new Set(this.clusteredPins.map(p => p.baseColor))];
            if (colorSet.length === 1) {
                color = colorSet[0];
            }
        }

        let minSeq = 0;
        let sequences = this.clusteredPins.filter(p => p.seq > 0 && p.seq <= 240).map(p => p.seq);
        if (sequences.length > 0) {
            minSeq = Math.min(...sequences);
        }

        let selectRatio = this.selectRatio();

        args = {
            typ: this.type,
            sub: sub,
            color: color,
            isSelected: isSelected,
            seq: minSeq,
            src: this.title,
            selectRatio: selectRatio,
            isComplete: allChecked,
        };

        //console.warn("********* createClusterImgArgs", args)

        return args;
    }


    selectRatio() {
        let selectRatio = 0.0
        let clusterCount = this.clusteredPins ? this.clusteredPins.length : 0;
        if (clusterCount > 0) {
            let selectCount = this.clusteredPins.filter(p => thePlanSpace.isSelected(p)).length;
            selectRatio = selectCount / clusterCount;
        }
        return selectRatio;
    }


    //region Sorting APIs

    isEquivalent(pin: RwPin): boolean {
        let isEqual = true;

        if (this.type != pin.type) {
            RwLog.consoleWarn("this.type != ", this.type, pin.type);
            return false;
        }
        if (this.priority != pin.priority) {
            RwLog.consoleWarn("this.priority != ", this.priority, pin.priority);
            return false;
        }
        if (this.isVio != pin.isVio) {
            RwLog.consoleWarn("this.isVio != ", this.isVio, pin.isVio);
            return false;
        }
        if (this.isSked != pin.isSked) {
            RwLog.consoleWarn("this.isSched != ", this.isSked, pin.isSked);
            return false;
        }
        if (this.isAvail != pin.isAvail) {
            RwLog.consoleWarn("this.isAvail != ", this.isAvail, pin.isAvail);
            return false;
        }
        if (this.isFirst != pin.isFirst) {
            RwLog.consoleWarn("this.isFirst != ", this.isFirst, pin.isFirst);
            return false;
        }
        if (this.isFinal != pin.isFinal) {
            RwLog.consoleWarn("this.isFinal != ", this.isFinal, pin.isFinal);
            return false;
        }
        if (this.isComplete != pin.isComplete) {
            RwLog.consoleWarn("this.isComplete != ", this.isComplete, pin.isComplete);
            return false;
        }
        if (this.seq != pin.seq) {
            RwLog.consoleWarn("this.seq != ", this.seq, pin.seq);
            return false;
        }
        if (this.lat != pin.lat) {
            RwLog.consoleWarn("this.lat != ", this.lat, pin.lat);
            return false;
        }
        if (this.lng != pin.lng) {
            RwLog.consoleWarn("this.lng != ", this.lng, pin.lng);
            return false;
        }
        if (this.hasDrop != pin.hasDrop) {
            RwLog.consoleWarn("this.hasDrop != ", this.hasDrop, pin.hasDrop);
            return false;
        }
        if (this.stopSeq != pin.stopSeq) {
            RwLog.consoleWarn("this.stopSeq != ", this.stopSeq, pin.stopSeq);
            return false;
        }
        if (this.dropSeq != pin.dropSeq) {
            RwLog.consoleWarn("this.dropSeq != ", this.dropSeq, pin.dropSeq);
            return false;
        }

        return isEqual;
    }

    getProximity(lat: number, lng: number): number {
        let distMeters: number;
        if (lat !== 0 && lng !== 0 && this.lat != 0 && this.lng != 0) {
            if (lat === this.cachedProximityLat && lng === this.cachedProximityLng) {
                distMeters = this.cachedProximity;
                //console.log("getProximity cached", distMeters);
            } else {
                let startPoint = new H.geo.Point(lat, lng);
                let pinPoint = new H.geo.Point(this.lat, this.lng);
                distMeters = startPoint.distance(pinPoint);
                this.cachedProximity = distMeters;
                //console.warn("getProximity generated", distMeters);
            }
            return distMeters;
        }
    }


    // static sortStops(rawPins: RwPin[]): RwPin[] {
    //   let sorted: RwPin[];
    //   switch (RwPrefUtils.sortListView) {
    //     case "seqThenProx":
    //       sorted = rawPins.sort(RwPin.seqCompare);
    //       break;
    //     case "seqThenName":
    //       sorted = rawPins.sort(RwPin.seqCompare);
    //       break;
    //     case "proxOnly":
    //       sorted = rawPins.sort(RwPin.distCompare);
    //       break;
    //     case "nameOnly":
    //       sorted = rawPins.sort(RwPin.nameCompare);
    //       break;
    //     default:
    //       sorted = rawPins;
    //       break;
    //   }
    //   return sorted;
    // }

    // static sortSites(rawPins: RwPin[]): RwPin[] {
    //   let sorted: RwPin[];
    //   switch (RwPrefUtils.sortListView) {
    //     case "seqThenProx":
    //       sorted = rawPins.sort(RwPin.distCompare);
    //       break;
    //     case "seqThenName":
    //       sorted = rawPins.sort(RwPin.nameCompare);
    //       break;
    //     case "proxOnly":
    //       sorted = rawPins.sort(RwPin.distCompare);
    //       break;
    //     case "nameOnly":
    //       sorted = rawPins.sort(RwPin.nameCompare);
    //       break;
    //     default:
    //       sorted = rawPins;
    //       break;
    //   }
    //   return sorted;
    // }

    // static updateProximity(start: H.geo.Point, pins: RwPin[]) {
    //   pins.forEach(stop => {
    //     let coord = new H.geo.Point(stop.lat, stop.lng);
    //
    //     let distMeters = start.distance(coord);
    //     stop.cachedProximity = distMeters;
    //   });
    // }

    static seqCompare(p1: RwPin, p2: RwPin): number {
        if (p1 && p2) {
            let seqComp = p1.seq - p2.seq;
            if (
                seqComp === 0 &&
                p1.type === RwPinTypes.Stop &&
                p2.type === RwPinTypes.Stop
            ) {
                // Go to check date
                if (p1.stop.checkDate && p2.stop.checkDate) {
                    let p1Date = moment(p1.stop.checkDate);
                    let p2Date = moment(p2.stop.checkDate);

                    if (p1Date > p2Date) {
                        return -1;
                    } else if (p1Date === p2Date) {
                        return 0;
                    }
                    return 1;
                }
            }
            return seqComp;
        }

        return 0;
    }

    static distCompare(p1: RwPin, p2: RwPin): number {
        if (p1 && p2) {
            return p1.cachedProximity - p2.cachedProximity;
        }
        return 0;
    }

    static nameCompare(p1: RwPin, p2: RwPin): number {
        if (p1 && p2) {
            let a: string = p1.title;
            let b: string = p2.title;
            return a.toLowerCase().localeCompare(b.toLowerCase());
        }
        return 0;
    }

    //endregion


    //region SVG Pin Creation


    static getRoutePinUrl(args: RwPinArgs): string {
        let url = "";
        if (args.typ === 0) {
            const baseDomain = window.location.origin;
            switch (args.sub) {
                case 0:
                    return `${baseDomain}/images/icons/icc_pin_flag_green.png`;
                case 1:
                    return `${baseDomain}/images/icons/start_ring.png`;
                case 2:
                    return `${baseDomain}/images/icons/icc_pin_flag_rt.png`;
                case 3:
                    return `${baseDomain}/images/icons/icc_pin_flag_grey.png`;
                case 4:
                    return `${baseDomain}/images/icons/start_ring_x.png`;
                case 5:
                    return `${baseDomain}/images/icons/icc_pin_flag_rt_inactive.png`;
                default:
                    RwLog.error("RwPin.getPinRemoteUrl", `Invalid subtype for icon: ${args.sub}`);
                    return "/images/icons/icc_pin_flag_green.png";
            }
        }

        //console.log("calcPinUrl:", url, args.typ);
        return url;
    }

    static getPinHash(args: RwPinArgs): string {
        //let url = `${RwConstants.CoreUri}/img/pin?typ=${args.typ}`;
        let data = `typ=${args.typ}`;
        if (args.sub && args.sub != 0) data += `&sub=${args.sub}`;
        if (args.seq && args.seq != 0) data += `&seq=${args.seq}`;
        if (args.drp && args.drp != 0) data += `&drp=${args.drp}`;
        if (args.pri && args.pri != 0) data += `&pri=${args.pri}`;
        if (args.vio && args.vio != 0) data += `&vio=${args.vio}`;
        if (RwPinArgs.isColorNonDefault(args)) data += `&clr=${RwWebUtils.encode(args.color)}`;
        if (args.isFirst && args.isFirst === true) data += `&1st=${args.isFirst}`;
        if (args.isFinal && args.isFinal === true) data += `&fnl=${args.isFinal}`;
        if (args.isSelected && args.isSelected === true) data += `&sel=${args.isSelected}`;
        if (args.isSked && args.isSked === true) data += `&skd=${args.isSked}`;
        if (args.hasDrp && args.hasDrp === true) data += `&hdrp=${args.hasDrp}`;
        let hash = md5(data, 8);
        //console.log("getPinHash:", hash, data);
        return hash;
    }


    static checkCode = String.fromCharCode(0xE2, 0x9C, 0x93); //"✓"️
    static dneCode = String.fromCharCode(0xF0, 0x9F, 0x9A, 0xAB); //"🚫"
    static arrowCode = String.fromCharCode(0xE2, 0x80, 0xA3); //"‣️"
    static warnCode = '\u00E2\u009D\u0097'; //"❗️"
    //static warnCode = '\u00E2\u009A\u00A0'; //"⚠️"
    //static boxCode = String.fromCodePoint(0xF0, 0x9F, 0x93, 0xA6); //"📦"
    private static pendingUrls: { [key: string]: any[] } = {};

    static subscribeToUrl(url: string, callback) {
        let callbacks = RwPin.pendingUrls[url];
        if (!callbacks) {
            callbacks = [];
            RwPin.pendingUrls[url] = callbacks;
        }
        callbacks.push(callback);
    }


    static onFulfilledUrl(hash: string, url: string) {
        let callbacks = RwPin.pendingUrls[hash];
        if (callbacks) {
            setTimeout(() => {
                //console.log("deferred callback", key, callbacks.length)
                callbacks.forEach(callback => {
                    callback(url);
                })
            }, 0)
        }
        delete RwPin.pendingUrls[hash];
    }

    static calcSkedPinUrl(hash: string, args: RwPinArgs): string {
        const SOURCE = "RwImgUtils.calcSkedPinUrl";
        const globals: RwGlobals = theGlobals;

        let url = globals.idxURL[hash];
        if (url && url.length > 0) {
            return url;
        } else {
            globals.idxURL[hash] = "";
            let skedColor = RwImgUtils.getHexColorFromPriority(args.pri);
            let svg = RwPin.createSkedPinSvg(skedColor);
            const url = this.createDataUrl(svg, hash);
            globals.idxURL[hash] = url;
            return url;
        }
    }

    static createSkedPinSvg(skedColor: string) {
        let svgText = `<?xml version="1.0"?><svg fill="#fill" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="60px" height="60px">    <path d="M 15 3 C 8.3844276 3 3 8.3844276 3 15 C 3 21.615572 8.3844276 27 15 27 C 21.615572 27 27 21.615572 27 15 C 27 8.3844276 21.615572 3 15 3 z M 15.998047 5.0488281 C 20.73255 5.5157016 24.484298 9.2674502 24.951172 14.001953 A 1 1 0 0 0 24 15 A 1 1 0 0 0 24.951172 15.998047 C 24.484298 20.73255 20.73255 24.484298 15.998047 24.951172 A 1 1 0 0 0 15 24 A 1 1 0 0 0 14.001953 24.951172 C 9.2674502 24.484298 5.5157016 20.73255 5.0488281 15.998047 A 1 1 0 0 0 6 15 A 1 1 0 0 0 5.0488281 14.001953 C 5.3813779 10.62961 7.3814425 7.7576039 10.214844 6.2148438 L 15 11 L 15 6 A 1 1 0 0 0 15.998047 5.0488281 z M 9.9902344 8.9902344 A 1.0001 1.0001 0 0 0 9.2929688 10.707031 L 14.292969 15.707031 A 1.0001 1.0001 0 0 0 15.195312 15.980469 L 20.195312 14.980469 A 1.0001 1.0001 0 1 0 19.804688 13.019531 L 15.328125 13.914062 L 10.707031 9.2929688 A 1.0001 1.0001 0 0 0 9.9902344 8.9902344 z"/></svg>`;
        svgText = svgText.replace("#fill", skedColor)
        return svgText;
    }


    static calcStopPinUrl(hash: string, args: RwPinArgs): Promise<string> {
        const SOURCE = "RwImgUtils.calcStopPinUrl";

        return new Promise<string>(async (resolve, reject) => {

            const globals: RwGlobals = theGlobals;

            let textLen = 1
            let url = globals.idxURL[hash];
            if (url) {
                if (url.length > 0) {
                    resolve(url);
                } else {
                    RwPin.subscribeToUrl(hash, resolve);
                }
            } else {

                globals.idxURL[hash] = "";

                let fontSize: number;
                let pinTextColor = "#fff"
                let pinRingColor = "#000"
                let pinCenterColor = "#000"
                let pinBaseColor = args.color ? args.color : "#E91E63";
                let pinText = args.seq > 0 && args.seq <= 240 ? args.seq.toString() : "";
                //console.log("createPinSvg args", pinText, pinBaseColor, pinCenterColor, pinRingColor);

                if (args.isSelected) {
                    pinTextColor = "#000";
                    pinCenterColor = "#FFF";
                }

                if (args.selectRatio > 0) {
                    pinTextColor = "#000";
                    pinCenterColor = "#FFF";
                    // pinCenterColor = RwImgUtils.getGreyScaleHex(args.selectRatio)
                    // console.log(`GREYSCALE ratio:${args.selectRatio}, color:${pinCenterColor} `)
                }

                switch (args.sub) {

                    case RwPinSubTypesStop.Stop:

                        //Calc Center Color
                        if (args.isSelected) {
                            if (args.isFirst) {
                                pinTextColor = "#296218";
                            }
                            if (args.isFinal) {
                                pinTextColor = "#75140c";
                            }
                        } else {
                            if (args.isFirst) {
                                pinCenterColor = "#296218";
                                pinRingColor = "#296218";
                            }
                            if (args.isFinal) {
                                pinCenterColor = "#75140c";
                                pinRingColor = "#75140c";
                            }
                        }

                        if ((args.isFirst || args.isFinal) === false) {
                            if (args.isSked && args.pri && args.pri > 0) {
                                let hex = RwImgUtils.getHexColorFromPriority(args.pri);
                                //console.log("hex", hex);
                                pinRingColor = hex;
                            }
                        }
                        textLen = pinText.length;
                        break;

                    case RwPinSubTypesStop.CheckIn:
                        pinBaseColor = "#777";
                        pinText = RwPin.checkCode; //"✓"
                        fontSize = 180;
                        pinTextColor = "#777";
                        textLen = 1;
                        break;

                    case RwPinSubTypesStop.DoNotEnter:
                        pinBaseColor = "#777";
                        pinText = RwPin.dneCode; //"🚫"
                        fontSize = 200;
                        textLen = 1;
                        break;

                    case RwPinSubTypesStop.Infeasible:
                        pinBaseColor = "#777";
                        pinText = RwPin.warnCode; //"⚠️";
                        fontSize = 180;
                        textLen = 1;
                        break;

                    case RwPinSubTypesStop.Cluster:
                        if (args.seq > 0) {
                            pinText = `${args.seq}+`;
                            textLen = pinText.length;
                        } else {
                            if (args.isComplete) {
                                pinText = `${RwPin.checkCode}+`;
                                textLen = 2;
                            } else {
                                pinText = `+`;
                                textLen = 1;
                            }
                        }
                        break;
                }

                if (args.drp && args.drp > 0) {
                    let seqText = args.seq.toString() !== "0" ? args.seq.toString() : "";
                    let textLen = seqText.length + 1;
                    pinText = `${seqText}${RwPin.arrowCode}`;
                    fontSize = 250;
                    if (textLen > 1) {
                        fontSize = (textLen > 2) ? 100 : 160;
                    }
                } else if (args.hasDrp === true) {
                    pinText = RwPin.arrowCode;
                    fontSize = 250;
                }

                if ((args.isFirst || args.isFinal) === false) {
                    if (args.isSked && args.vio && args.vio > 0) {
                        switch (args.vio) {
                            case RwStopVios.Closed:
                            case RwStopVios.Late:
                            case RwStopVios.Overtime:
                            case RwStopVios.Unreachable:
                                pinTextColor = args.isSelected ? "#ed5565" : "#ff6347";
                                break;
                            case RwStopVios.Early:
                                pinTextColor = args.isSelected ? "#428bca" : "#5BC0DE";
                                break;
                        }
                    }
                }

                let finalFontSize = 180
                if (fontSize) {
                    finalFontSize = fontSize;
                } else {
                    if (textLen > 1) {
                        finalFontSize = (textLen > 2) ? 100 : 160;
                    }
                }

                // if(args.isSked){
                //   console.log(`createStopPinSvg args "${pinText}"`, pinBaseColor, pinCenterColor, pinRingColor, pinTextColor, finalFontSize, textLen, args)
                // }

                let svg = RwPin.createStopPinSvg(pinText, pinBaseColor, pinCenterColor, pinRingColor, pinTextColor, finalFontSize, textLen);

                let width = 66;
                let height = 99;
                const url = this.createDataUrl(svg, hash);
                globals.idxURL[hash] = url;
                resolve(url);
                RwPin.onFulfilledUrl(hash, url);
            }
        });

    }

    static createStopPinSvg(text: string, colorBase: string, colorCenter: string, colorRing: string, colorText: string, fontSize: number, textLen: number) {
        let svgText = "";

        //console.log("text.len", text.length)
        let txtPosY = 238
        if (textLen > 1) {
            txtPosY = 228;
            if (textLen > 2) {
                txtPosY = 208;
            }
        }

        let colorEdge = RwImgUtils.lightenDarkenColor(colorBase, -30);
        if (colorRing === "#000") {
            svgText = "<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" width=\"64\" height=\"98\" viewBox=\"0 0 352 528\"><path d=\"M176.00000762939453 8.000000000001819C81.89600762939455 8.000000000001819 5.33300762939453 84.56300000000182 5.33300762939453 178.66700000000182C5.33300762939453 206.91700000000182 12.396007629394532 234.9270000000018 25.823007629394525 259.77100000000183L166.6670076293945 514.5000000000018C168.5420076293945 517.8960000000018 172.1150076293945 520.0000000000018 176.0000076293945 520.0000000000018S183.4580076293945 517.8960000000018 185.3330076293945 514.5000000000018L326.22900762939446 259.68700000000183C339.60400762939446 234.92700000000184 346.66700762939445 206.91600000000182 346.66700762939445 178.66600000000182C346.66700762939445 84.56300000000182 270.10400762939446 8.000000000001819 176.00000762939445 8.000000000001819ZM176.00000762939453 264.0000000000018C128.94800762939454 264.0000000000018 90.66700762939453 225.7190000000018 90.66700762939453 178.66700000000182S128.94800762939454 93.33400000000182 176.00000762939453 93.33400000000182S261.3330076293945 131.61500000000183 261.3330076293945 178.66700000000182S223.0520076293945 264.0000000000018 176.0000076293945 264.0000000000018Z \" fill=\"#colorBase\" stroke-width=\"16\" stroke=\"#colorEdge\"></path><circle r=\"124\" cx=\"176\" cy=\"176\" fill=\"#colorCenter\"></circle><text fill=\"#colorText\" font-family=\"Helvetica\" font-size=\"#fontSize\" text-anchor=\"middle\" x=\"176\" y=\"#txtPosY\" ><tspan>#text</tspan></text></svg>"
        } else {
            svgText = "<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" width=\"64\" height=\"98\" viewBox=\"0 0 352 528\"><path d=\"M176.00000762939453 8.000000000001819C81.89600762939455 8.000000000001819 5.33300762939453 84.56300000000182 5.33300762939453 178.66700000000182C5.33300762939453 206.91700000000182 12.396007629394532 234.9270000000018 25.823007629394525 259.77100000000183L166.6670076293945 514.5000000000018C168.5420076293945 517.8960000000018 172.1150076293945 520.0000000000018 176.0000076293945 520.0000000000018S183.4580076293945 517.8960000000018 185.3330076293945 514.5000000000018L326.22900762939446 259.68700000000183C339.60400762939446 234.92700000000184 346.66700762939445 206.91600000000182 346.66700762939445 178.66600000000182C346.66700762939445 84.56300000000182 270.10400762939446 8.000000000001819 176.00000762939445 8.000000000001819ZM176.00000762939453 264.0000000000018C128.94800762939454 264.0000000000018 90.66700762939453 225.7190000000018 90.66700762939453 178.66700000000182S128.94800762939454 93.33400000000182 176.00000762939453 93.33400000000182S261.3330076293945 131.61500000000183 261.3330076293945 178.66700000000182S223.0520076293945 264.0000000000018 176.0000076293945 264.0000000000018Z \" fill=\"#colorBase\" stroke-width=\"16\" stroke=\"#colorEdge\"></path><circle r=\"156\" cx=\"176\" cy=\"176\" fill=\"#colorCenter\"></circle><circle r=\"124\" cx=\"176\" cy=\"176\" fill=\"#colorCenter\" stroke-opacity=\"1\" stroke-width=\"48\" stroke=\"#colorRing\"></circle><text fill=\"#colorText\" font-family=\"Helvetica\" font-size=\"#fontSize\" text-anchor=\"middle\" x=\"176\" y=\"#txtPosY\" ><tspan >#text</tspan></text></svg>"
        }
        svgText = svgText.replace("#text", text)
        svgText = svgText.replace("#colorBase", colorBase)
        svgText = svgText.replace("#colorEdge", colorEdge)
        svgText = svgText.replace(/#colorCenter/g, colorCenter)
        svgText = svgText.replace("#colorRing", colorRing)
        svgText = svgText.replace("#colorText", colorText)
        svgText = svgText.replace("#txtPosY", txtPosY.toString())
        svgText = svgText.replace("#fontSize", fontSize.toString())
        //console.log("svgText", svgText)
        return svgText;
    }


    // static createStopPinSvgV1(text: string, colorBase: string, colorCenter: string, colorRing: string, colorText: string, fontSize?: number) {
    //   let draw = SvgLib.SVG();
    //   draw.size(64, 98)
    //   draw.viewbox(0, 0, 352, 528)
    //
    //   let pinBase = draw.path('m256,0c-94.104,0 -170.667,76.563 -170.667,170.667c0,28.25 7.063,56.26 20.49,81.104l140.844,254.729c1.875,3.396 5.448,5.5 9.333,5.5s7.458,-2.104 9.333,-5.5l140.896,-254.813c13.375,-24.76 20.438,-52.771 20.438,-81.021c0,-94.103 -76.563,-170.666 -170.667,-170.666zm0,256c-47.052,0 -85.333,-38.281 -85.333,-85.333s38.281,-85.333 85.333,-85.333s85.333,38.281 85.333,85.333s-38.281,85.333 -85.333,85.333z');
    //   pinBase.fill(colorBase);
    //   let colorEdge = RwImgUtils.lightenDarkenColor(colorBase, -30);
    //   pinBase.stroke({color: colorEdge, width: 16});
    //   pinBase.center(176, 264);
    //
    //   if (colorRing === "#000") {
    //     let pinCenter = draw.circle(248)
    //     pinCenter.fill(colorCenter);
    //     pinCenter.center(176, 176);
    //   }
    //   else {
    //     let pinCenterEdge = draw.circle(312)
    //     pinCenterEdge.fill('#000');
    //     pinCenterEdge.center(176, 176);
    //
    //     let pinCenter = draw.circle(248)
    //     pinCenter.fill(colorCenter);
    //     pinCenter.stroke({color: colorRing, opacity: 1.0, width: 48})
    //     pinCenter.center(176, 176);
    //   }
    //
    //   if (fontSize) {
    //     fontSize = fontSize;
    //   }
    //   else {
    //     fontSize = 180;
    //     if (text.length > 1) {
    //       fontSize = (text.length > 2) ? 100 : 160;
    //     }
    //   }
    //
    //   let pinText = draw.text(text);
    //   pinText.font({fill: colorText, family: 'Helvetica', size: fontSize, anchor: 'middle'});
    //   pinText.center(176, 176);
    //
    //   let svgText = draw.svg();
    //   //console.log("svgText", svgText)
    //   return svgText;
    //
    // }


    static calcSitePinUrlNew(hash: string, args: RwPinArgs): Promise<string> {
        const SOURCE = "RwImgUtils.calcSitePinUrl";

        return new Promise<string>(async (resolve, reject) => {

            const globals: RwGlobals = theGlobals;
            let pinCenterColor = "#ddd"
            let pinBaseColor = args.color ? args.color : RwPrefUtils.stopColor;  // RwConstants.DefaultStopColor;
            let pinEdgeColor = RwImgUtils.lightenDarkenColor(pinBaseColor, -30);
            //console.log("createPinSvg args", pinText, pinBaseColor, pinCenterColor, pinRingColor);

            if (args.isSelected) {
                pinCenterColor = "#fff"
                pinEdgeColor = "#FFF";
            }

            let svgML = RwPin.createSitePinSvg(pinBaseColor, pinEdgeColor, pinCenterColor);

            let width = 192;
            let height = 192;
            const url = this.createDataUrl(svgML, hash)
            globals.idxURL[hash] = url;
            resolve(url);
        });

    }


    // static calcSitePinUrlOLD(hash: string, args: RwPinArgs): Promise<string> {
    //   const SOURCE = "RwImgUtils.calcSitePinUrl";
    //
    //   return new Promise<string>(async (resolve, reject) => {
    //
    //     const globals: RwGlobals = theGlobals;
    //
    //     globals.idxURL[hash] = "";
    //     let pinCenterColor = "#ddd"
    //     let pinBaseColor = args.color ? args.color : "#E91E63";
    //     let pinEdgeColor = RwImgUtils.lightenDarkenColor(pinBaseColor, -30);
    //     //console.log("createPinSvg args", pinText, pinBaseColor, pinCenterColor, pinRingColor);
    //
    //     if (args.isSelected) {
    //       pinCenterColor = "#FFF"
    //       pinEdgeColor = "#FFF";
    //     }
    //
    //     let svgML = RwPin.createSitePinSvg(pinBaseColor, pinEdgeColor, pinCenterColor);
    //
    //     // let width = 98;
    //     // let height = 98;
    //     let width = 192;
    //     let height = 192;
    //     this.createDataUrl(width, height, svgML)
    //       .then(pngUrl => {
    //         globals.idxURL[hash] = pngUrl;
    //         resolve(pngUrl);
    //         RwPin.onFulfilledUrl(hash, pngUrl);
    //       })
    //       .catch(err => {
    //         RwLog.error(SOURCE, `Unhandled: width:${width}, height:${height}, \nsvgML:${svgML}\n\n${err}`);
    //         resolve();
    //         RwPin.onFulfilledUrl(hash, undefined);
    //       });
    //
    //   });
    //
    // }


    static createSitePinSvg(colorBase: string, colorEdge: string, colorCenter: string) {
        let svgText = "<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" viewBox=\"0 0 48 48\" width=\"192\" height=\"192\"><circle r=\"23\" cx=\"24\" cy=\"24\" fill=\"#colorEdge\"></circle><circle r=\"20\" cx=\"24\" cy=\"24\" fill=\"#colorBase\"></circle><path d=\"M24.765501155853272 10.74375902080536L27.640501155853272 19.25575902080536L36.623501155853276 19.35975902080536C37.39650115585327 19.36875902080536 37.71650115585327 20.35375902080536 37.096501155853275 20.81475902080536L29.88950115585327 26.17875902080536L32.566501155853274 34.75475902080536C32.79650115585327 35.49275902080536 31.95950115585327 36.100759020805356 31.32850115585327 35.65375902080536L24.00050115585327 30.456759020805357L16.67150115585327 35.652759020805355C16.041501155853272 36.09975902080535 15.20350115585327 35.490759020805356 15.433501155853271 34.753759020805354L18.11050115585327 26.177759020805354L10.90350115585327 20.813759020805353C10.283501155853271 20.35275902080535 10.603501155853271 19.36775902080535 11.376501155853271 19.35875902080535L20.359501155853273 19.254759020805352L23.234501155853273 10.742759020805353C23.48250115585327 10.01075902080536 24.518501155853272 10.01075902080536 24.765501155853272 10.74375902080536Z \" stroke-width=\"1\" stroke=\"#colorEdge\" fill=\"#colorCenter\"></path></svg>"
        svgText = svgText.replace(/#colorBase/g, colorBase)
        svgText = svgText.replace(/#colorEdge/g, colorEdge)
        svgText = svgText.replace("#colorCenter", colorCenter)
        //console.log("svgText", svgText)
        return svgText;
    }


    // static createSitePinSvgV1(colorBase: string, colorEdge: string, colorCenter: string) {
    //   let draw = SvgLib.SVG();
    //   draw.viewbox(0, 0, 48, 48)
    //   draw.size(192, 192)
    //
    //   let circleEdge = draw.circle(46)
    //   circleEdge.fill(colorEdge)
    //   circleEdge.center(24, 24);
    //
    //   let circleFill = draw.circle(40)
    //   circleFill.fill(colorBase)
    //   circleFill.center(24, 24);
    //
    //   let pinStar = draw.path('M15.765,2.434l2.875,8.512l8.983,0.104c0.773,0.009,1.093,0.994,0.473,1.455l-7.207,5.364l2.677,8.576 c0.23,0.738-0.607,1.346-1.238,0.899L15,22.147l-7.329,5.196c-0.63,0.447-1.468-0.162-1.238-0.899l2.677-8.576l-7.207-5.364 c-0.62-0.461-0.3-1.446,0.473-1.455l8.983-0.104l2.875-8.512C14.482,1.701,15.518,1.701,15.765,2.434z');
    //   pinStar.stroke({color: colorEdge, width: 1});
    //   pinStar.fill(colorCenter);
    //   pinStar.center(24, 23);
    //
    //   let svgText = draw.svg();
    //   //console.log("svgText", svgText)
    //   return svgText;
    //
    // }


    static calcTaskPinUrl(hash: string, args: RwPinArgs): Promise<string> {
        const SOURCE = "RwPin.calcTaskPinUrl";

        return new Promise<string>(async (resolve, reject) => {

            const globals: RwGlobals = theGlobals;

            globals.idxURL[hash] = "";
            let pinCenterColor = "#ddd"
            let pinBaseColor = args.color ? args.color : "#E91E63";
            let pinEdgeColor = RwImgUtils.lightenDarkenColor(pinBaseColor, -30);

            if (args.isSelected) {
                pinCenterColor = "#fff"
                pinEdgeColor = "#fff";
            }

            let svg = RwPin.createTaskPinSvg(pinBaseColor, pinEdgeColor, pinCenterColor);

            let width = 192;
            let height = 192;
            const url = this.createDataUrl(svg, hash);
            globals.idxURL[hash] = url;
            resolve(url);
        });

    }


    static createTaskPinSvg(colorBase: string, colorEdge: string, colorCenter: string) {
        let svgText = "<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" viewBox=\"0 0 48 48\" width=\"192\" height=\"192\"><circle r=\"23\" cx=\"24\" cy=\"24\" fill=\"#colorEdge\"></circle><circle r=\"20\" cx=\"24\" cy=\"24\" fill=\"#colorBase\"></circle><path d=\"M33.5183533115387 14.759848575592041L31.723353311538695 13.54084857559204C31.228353311538694 13.20684857559204 30.550353311538693 13.335848575592042 30.216353311538693 13.827848575592041L21.42935331153869 26.78684857559204L17.390353311538693 22.747848575592037C16.968353311538692 22.32784857559204 16.280353311538693 22.32784857559204 15.857353311538693 22.747848575592037L14.324353311538694 24.28384857559204C13.900353311538694 24.70684857559204 13.900353311538694 25.39384857559204 14.324353311538694 25.817848575592038L20.533353311538697 32.02984857559204C20.882353311538697 32.37684857559204 21.428353311538697 32.644848575592036 21.920353311538697 32.644848575592036S22.908353311538697 32.33484857559204 23.2273533115387 31.87084857559204L33.8073533115387 16.26484857559204C34.1423533115387 15.772848575592041 34.013353311538694 15.09584857559204 33.5183533115387 14.759848575592041Z \" stroke-width=\"1\" stroke=\"#colorEdge\" fill=\"#colorCenter\"></path></svg>"
        svgText = svgText.replace(/#colorBase/g, colorBase)
        svgText = svgText.replace(/#colorEdge/g, colorEdge)
        svgText = svgText.replace("#colorCenter", colorCenter)
        return svgText;
    }


    // static createTaskPinSvgV1(colorBase: string, colorEdge: string, colorCenter: string) {
    //   //xREF: SVG: createTaskPinSvg
    //   let draw = SvgLib.SVG();
    //   draw.viewbox(0, 0, 48, 48)
    //   draw.size(192, 192)
    //
    //   let circleEdge = draw.circle(46)
    //   circleEdge.fill(colorEdge)
    //   circleEdge.center(24, 24);
    //
    //   let circleFill = draw.circle(40)
    //   circleFill.fill(colorBase)
    //   circleFill.center(24, 24);
    //
    //   let pinCheck = draw.path('M22.567,4.73l-1.795-1.219c-0.495-0.334-1.173-0.205-1.507,0.287l-8.787,12.959l-4.039-4.039 c-0.422-0.42-1.11-0.42-1.533,0l-1.533,1.536c-0.424,0.423-0.424,1.11,0,1.534L9.582,22c0.349,0.347,0.895,0.615,1.387,0.615 s0.988-0.31,1.307-0.774L22.856,6.235C23.191,5.743,23.062,5.066,22.567,4.73z');
    //   pinCheck.stroke({color: colorEdge, width: 1});
    //   pinCheck.fill(colorCenter);
    //   pinCheck.center(24, 23);
    //
    //   let svgText = draw.svg();
    //   //console.log("svgText", svgText)
    //   return svgText;
    //
    // }

    private static createDataUrl(svgML: string, hash: string): string {
        const SOURCE = "RwPin.createDataUrl";
        const url = 'data:image/svg+xml;base64,' + window.btoa(svgML);
        localDB.add("svgs", {hash, svg: url})
        return url;
    }


    //endregion

    static toJSON(pin: RwPin) {
        return {
            id: pin.id,
            title: pin.title,
            lat: pin.lat,
            lng: pin.lng,
            seq: pin.seq,
            type: pin.type,
            stop: pin.stop?.toJSON() || null,
            site: pin.site?.toJSON() || null,
            task: pin.task?.toJSON() || null,
            driver: pin.driver?.toJSON() || null,
            route: pin.route?.toJSON() || null,
            isAvail: pin.isAvail,
            isActive: pin.isActive,
            isFirst: pin.isFirst,
            isFinal: pin.isFinal,
            isComplete: pin.isComplete,
            isSked: pin.isSked,
            priority: pin.priority,
            stopSeq: pin.stopSeq,
            isVio: pin.isVio,
            isTask: pin.isTask,
            colorGroup: pin.colorGroup,
            start: pin.start,
            end: pin.end,
            note: pin.note,
            //lastHash: pin.lastHash,
            //lastArgsUpdate: pin.lastArgsUpdate
        }
    }

}


export class RwMarker extends H.map.Marker {
    pin: RwPin;
    pinType: RwPinTypes;
    searchId: string;

    // _eid: string;
    // get eid(): string {
    //   let eid: string;
    //   if (this._eid) {
    //     eid = this._eid;
    //   }
    //   else {
    //     if (this.pin) {
    //       eid = this.pin.eid;
    //     }
    //   }
    //   return eid
    // }
}


export class MapUpload extends H.map.Object {
    address: RwAddress;
}


// static calcStopPinUrlBlob(args: RwPinArgs): string {
//   const SOURCE = "RwPin.calcStopPinUrl";
//
//   let pngUrl = "";
//
//   const globals: RwGlobals = theGlobals;
//   let key = RwPin.getPinSvrUrl(args);
//   let gotKey = globals.idxURL[key] !== undefined;
//   if (gotKey) {
//     pngUrl = globals.idxURL[key];
//   }
//   else {
//
//     //globals.svgLookup[key] = "";
//
//     let fontSize: number;
//     let pinTextColor = "#fff"
//     let pinRingColor = "#000"
//     let pinCenterColor = "#000"
//     let pinBaseColor = args.color ? args.color : "#E91E63";
//     let pinText = args.seq > 0 && args.seq <= 240 ? args.seq.toString() : "";
//     //console.log("createPinSvg args", pinText, pinBaseColor, pinCenterColor, pinRingColor);
//
//     if (args.isSelected) {
//       pinCenterColor = "#FFF";
//       pinTextColor = "#000";
//     }
//
//     switch (args.sub) {
//
//       case RwPinSubTypesStop.Stop:
//
//         //Calc Center Color
//         if (args.isSelected) {
//           if (args.isFirst) {
//             pinTextColor = "#296218";
//           }
//           if (args.isFinal) {
//             pinTextColor = "#75140c";
//           }
//         }
//         else {
//           if (args.isFirst) {
//             pinCenterColor = "#296218";
//             pinRingColor = "#296218";
//           }
//           if (args.isFinal) {
//             pinCenterColor = "#75140c";
//             pinRingColor = "#75140c";
//           }
//         }
//
//         if ((args.isFirst || args.isFinal) === false) {
//           if (args.isSked && args.pri && args.pri > 0) {
//             let hex = RwImgUtils.getHexColorFromPriority(args.pri);
//             //console.log("hex", hex);
//             pinRingColor = hex;
//           }
//         }
//         break;
//
//       case RwPinSubTypesStop.CheckIn:
//         pinBaseColor = "#777";
//         pinText = RwPin.checkCode; //"✓"
//         fontSize = 180;
//         pinTextColor = "#777";
//         break;
//
//       case RwPinSubTypesStop.DoNotEnter:
//         pinBaseColor = "#777";
//         pinText = RwPin.dneCode; //"🚫"
//         fontSize = 200;
//         break;
//
//       case RwPinSubTypesStop.Infeasible:
//         pinBaseColor = "#777";
//         pinText = RwPin.warnCode; //"⚠️";
//         fontSize = 180;
//         break;
//
//       case RwPinSubTypesStop.Cluster:
//         if (args.seq > 0) {
//           pinText = `${args.seq}+`;
//         }
//         break;
//     }
//
//     if (args.drp && args.drp > 0) {
//       let seqText = args.seq.toString();
//       let textLen = seqText.length + 1;
//       pinText = `${args.seq}${RwPin.arrowCode}`;
//       fontSize = 180;
//       if (textLen > 1) {
//         fontSize = (textLen > 2) ? 100 : 160;
//       }
//     }
//
//     if ((args.isFirst || args.isFinal) === false) {
//       if (args.isSked && args.vio && args.vio > 0) {
//         switch (args.vio) {
//           case RwStopVios.Closed:
//           case RwStopVios.Late:
//           case RwStopVios.Overtime:
//           case RwStopVios.Unreachable:
//             pinTextColor = args.isSelected ? "#ed5565" : "#ff6347";
//             break;
//           case RwStopVios.Early:
//             pinTextColor = args.isSelected ? "#428bca" : "#5BC0DE";
//             break;
//         }
//       }
//     }
//
//     let svg = RwPin.createStopPinSvg(pinText, pinBaseColor, pinCenterColor, pinRingColor, pinTextColor, fontSize);
//     const blob = new Blob([svg], {type: 'image/svg+xml'});
//     pngUrl = URL.createObjectURL(blob);
//     //console.warn(SOURCE, "createDataUrl:", key)
//     globals.idxURL[key] = pngUrl;
//
//   }
//
//   return pngUrl;
//
// }
