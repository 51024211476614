
import {Component, Vue} from "vue-property-decorator";
import theGlobals from "@/app/RwGlobals";
import store, {RwGlobals} from "@/app/RwGlobals";
import RwPages from "@/app/consts/RwPages";
import {RwProfileModel} from "@/app/dem/Profile/RwProfileModel";
import {RwTaskBillingProfile} from "@/app/dal/RwTaskBillingProfile";
import {SubTeamViewModel} from "@/app/dem/Drivers/CreateBulkDriversResponse";
import {RwLog} from "@/app/dal/RwLog";
import {RwUser} from "@/app/dem/RwUser";
import {RwConstants} from "@/app/RwConstants";
import {RwRoleTypes} from "@/app/RwEnums";


@Component
export default class RwProToTeam extends Vue {

  profileLoaded: boolean = false;
  hasChargifyAccount: boolean = false;
  hasValidCC: boolean = false;
  _billingProfile: RwProfileModel;
  updatePaymentUrl: string = "";
  hasDrivers: boolean = false;
  hasSubTeams: boolean = false;
  subTeamList: Array<SubTeamViewModel> = [];
  driverList: Array<RwUser> = [];


  get globals(): RwGlobals {
    return theGlobals;
  }

  escPress() {
    this.globals.showProToTeam = false;
  }

  mounted() {
    // console.warn('activated, getting resub');
    if (theGlobals.role === RwRoleTypes.Owner || theGlobals.role === RwRoleTypes.Admin) {
      this.getReSubPurchProfile();
    } else {
      theGlobals.showSnack("Only the owner of the account is allowed to subscribe to Flex", "warning");
    }

  }

  get billingProfile(): RwProfileModel {
    // console.warn('Billing Profile is:', this._billingProfile);
    return this._billingProfile;
  }

  set billingProfile(value: RwProfileModel) {
    // console.warn('billingProfile being set');
    this._billingProfile = value;

    if (this._billingProfile) {
      let bp = this._billingProfile;
      if (bp.ProStatus) {
        this.updatePaymentUrl = bp.UpdatePaymentUrl;
      }

      // if (bp.HasChargifyAccount) {
      //   this.hasChargifyAccount = true;
      //   if (bp.ProStatus.HasCurrentTeamPurch) {
      //     this.globals.genericDialog.title = "No Purchase Required";
      //     this.globals.genericDialog.text = "You currently have an active subscription, please log back in to use RoadWarrior.";
      //     this.globals.genericDialog.icon = "info";
      //     this.globals.genericDialog.onCloseCallback = store.logOut;
      //     this.globals.genericDialog.show = true;
      //     return;
      //     //REVIEW: spaghetti code;
      //     //if this occurs, the code below will not execute; which is not obvious as it would be with "return"
      //   }
      //   if (bp.PaymentMethod && bp.PaymentMethod.MaskedCard) {
      //     this.hasValidCC = true;
      //   } else {
      //     this.hasValidCC = false;
      //   }
      // }


      if (bp.HasChargifyAccount) {
        this.hasChargifyAccount = true;
        if (bp.ProStatus.HasCurrentTeamPurch) {
          this.globals.genericDialog.title = "No Purchase Required";
          this.globals.genericDialog.text = "You currently have an active subscription, please log back in to use RoadWarrior.";
          this.globals.genericDialog.icon = "info";
          this.globals.genericDialog.onCloseCallback = store.logOut;
          this.globals.genericDialog.show = true;
        } else {
          if (bp.PaymentMethod && bp.PaymentMethod.MaskedCard) {
            this.hasValidCC = true;
          } else {
            this.hasValidCC = false;
          }
        }
      }


    }
  }

  upgradeNoChargifyClick() {
    this.globals.hasProBetaPrimed = true;
    this.createNewChargifySub();
  }

  upgradeClick() {
    this.globals.hasProBetaPrimed = true;
    // If user has chargify account, then get the update card url and have them update a previous card
    if (this.hasChargifyAccount) {
      // console.warn("updatePayment activated");
      this.$router.push({name: RwPages.Upgrade}).catch(err => {
      });
    }
    // Else if this user does not have a chargify acct (regular pro user) then have them sign up through chargify
    else {
      // console.warn("createNewChargifySub activated");
      this.createNewChargifySub();
    }
  }

  createNewChargifySub() {
    let self = this;
    RwLog.log(
        "Team.NewSubscription",
        "Navigating to chargify:" + RwConstants.SignupUrl + self.billingProfile.CreateChargifyUrl
    );

    setTimeout(function () {
      window.location.href = RwConstants.SignupUrl + self.billingProfile.CreateChargifyUrl;
    }, 250);
  }

  updatePayment() {
    this.globals.genericDialog.title = "Continue when done updating payment method";
    this.globals.genericDialog.text = "Please continue the payment update process in the new window opened. Push the OK button below when finished.";
    this.globals.genericDialog.icon = "info";
    this.globals.genericDialog.confirmCallback = this.getReSubPurchProfile;
    this.globals.genericDialog.show = true;


    window.open(this.billingProfile.BillingPortalUrl, "_blank");
  }

  getReSubPurchProfile() {
    const SOURCE = "RwProToTeam.getReSubPurchProfile";
    const self = this;

    RwTaskBillingProfile.GetReSubPurchProfile()
        .then(data => {

          // For non team or rw.pro members
          if (data === null) {
            this.globals.genericDialog.title = "Drivers Accounts cannot create a new Team";
            this.globals.genericDialog.text = "Please register a new account if you would like to create a new Team.";
            this.globals.genericDialog.icon = "error";
            this.globals.genericDialog.onCloseCallback = () => {
              store.logOut();
              this.$router.push({name: RwPages.Login}).catch(err => {
              });
            };
            this.globals.genericDialog.show = true;
          } else {
            self.profileLoaded = true;
            self.billingProfile = data;
            if (data.Drivers && data.Drivers.length > 0) {
              self.hasDrivers = true;
              self.driverList = data.Drivers;
              self.driverList.forEach(d => (d.isSelectedItem = true));
            }

            if (data.SubTeams && data.SubTeams.length > 0) {
              self.hasSubTeams = true;
              self.subTeamList = [];
              data.SubTeams.forEach(st => {
                let stvm = new SubTeamViewModel(st);
                stvm.Selected = true;
                self.subTeamList.push(stvm);
              });
            }
          }
        })
        .catch(err => {
          if ((err && !err.isHandled) || !err) {
            RwLog.error(SOURCE, `Unhandled: ${err}`)
          }
        });
  }


}
