

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import thePlan, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwTaskSearch} from "@/app/dal/RwTaskSearch";
import {RwAddReportEnum, RwAddStatusEnum, RwAddVoteEnum, RwLoginIssues, RwPinTypes, RwSearchType} from "@/app/RwEnums";
import {RwSearchResult} from "@/app/dem/RwSearchResult";
import {RwLog} from "@/app/dal/RwLog";
import {RwSite} from "@/app/dem/RwSite";
import {RwStop} from "@/app/dem/RwStop";
import {RwTextUtils} from "@/app/utils/RwTextUtils";
import RwAddReport from "@/app/dem/RwAddReport";
import {RwConstants} from "@/app/RwConstants";
import {RwLoginError} from "@/app/RwErrors";
import dal from "@/app/dal/RwDal";

@Component
export default class RwSearchView extends Vue {

  ///region Props

  @Prop({default: true}) ! enableSearchActions: boolean;

  idxSearch = -1;
  idxTab = 0;
  searchText = "";
  queryInProg: string;
  queryOnDeck: string;
  queryInProgTS = 0;
  overlay = true;
  absolute = true;
  opacity = 0.65;
  isGpsButtonDisabled = false;
  activeListItem = null;
  activeAction = null;
  showMobileActions = false;
  searchMenuVisible = false;
  menuX = 0;
  menuY = 0;

  // m_zoomCheck: boolean = false;


  get globals(): RwGlobals {
    return theGlobals
  };

  get space(): RwPlannerSpace {
    return thePlan;
  };

  get searchResults(): RwSearchResult[] {
    return [
      ...this.space.searchResultsCoords,
      ...this.space.searchResultsTasks,
      ...this.space.searchResultsSites,
      ...this.space.searchResultsWeb
    ];
  }

  get gotSearchResults() {
    return this.space.searchResultsTasks.length > 0 || this.space.searchResultsWeb.length > 0 || this.space.searchResultsSites.length > 0;
    //console.log("gotSearchResults", gotResults);
    //return gotResults;
  }

  get isDev() {
    return this.globals.isDev;
  }

  get showActions(): boolean {
    //console.log("showActions", !!this.selectedResult);
    return !!this.selectedResult;
  }

  get canAddStop() {
    return !!this.selectedResult && this.space.activeRoute.canAddToRoute(1);
  }

  get canAddSite() {
    return !!this.selectedResult && this.selectedResult.type != RwSearchType.Sites;
  }

  get canAddTask() {
    return !!this.selectedResult && this.selectedResult.type != RwSearchType.Tasks;
  }

  get canUpdateStart() {
    return !!this.selectedResult;
  }

  get isSearchMode(): boolean {
    //console.log("SearchView.GET.isSearchMode ")
    return this.globals.showSearchPanel;
  }

  taskColor = RwPrefUtils.taskColor;

  get defaultTaskColor() {
    return this.taskColor;
  }

  siteColor = RwPrefUtils.siteColor;

  get defaultFavoriteColor() {
    return this.siteColor;
  }

  stopColor = RwPrefUtils.stopColor;

  get defaultStopColor() {
    return this.stopColor;
  }

  // get showZoomSnack(): boolean {
  //   let showZoom = false;
  //   if (this.globals.showSearchPanel && this.space.zoomLevel <= 9) {
  //     showZoom = true;
  //   }
  //   return showZoom;
  // }


  get idxSites(): { [id: string]: RwSite } {
    const self = this;
    let idxSites: { [id: string]: RwSite } = {};

    //build the index
    let sites = self.globals.sites;
    if (sites && sites.length > 0) {
      let counter = 0;
      sites.forEach(site => {
        counter++;
        let nameLower = site.name.toLowerCase();
        let addressLower = site.address ? site.address.toLowerCase() : "";
        let key = `${nameLower}:${addressLower}:${counter}`;
        idxSites[key] = site;
      });
    }
    //console.log("idxSites rebuilding index");

    return idxSites;
  }


  get idxTasks(): { [id: string]: RwStop } {
    const self = this;
    let idxTasks: { [id: string]: RwStop } = {};

    //build the index
    let tasks = self.globals.tasks;
    //console.log("get idxTasks len", tasks.length);
    if (tasks && tasks.length > 0) {
      let counter = 0;
      tasks.forEach(task => {
        counter++;
        let nameLower = task.name.toLowerCase();
        let addressLower = task.address ? task.address.toLowerCase() : "";
        let key = `${nameLower}:${addressLower}:${counter}`;
        idxTasks[key] = task;
      });
    }
    //console.log("idxSites rebuilding index");

    return idxTasks;
  }

  get selectedAddress() {
    if (this.selectedResult) {
      return this.selectedResult.address;
    }
    return "";
  }

  get selectedResult(): RwSearchResult {
    return this.space.selectedSearchResult;
  }

  set selectedResult(value: RwSearchResult) {
    this.space.selectedSearchResult = value;
  }

  get searchTextField(): string {
    if (this.space.zoomLevel > 7) {
      return "Address / Coordinates";
    } else {
      return "Zoom in on map to activate Search"
    }
  }

  // get zoomCheck(): boolean {
  //   return this.m_zoomCheck;
  // }
  //
  // set zoomCheck(value: boolean){
  //   console.warn("zoomCheck set to: ", value);
  //   this.m_zoomCheck = value;
  // }

  //#endregion


  //#region Search APIs

  onAddMissingReportClick() {
    const SOURCE = "RwSearchView.onAddMissingReportClick";
    let report = new RwAddReport();
    report.status = RwAddStatusEnum.Pending;
    report.type = RwAddReportEnum.Missing;
    report.vote = RwAddVoteEnum.Indeterminate;
    report.name = "";
    report.lat = 0;
    report.lng = 0;
    report.latDelta = RwPrefUtils.viewCenterLat;
    report.lngDelta = RwPrefUtils.viewCenterLng;
    report.address = "";
    report.userId = this.globals.userId;
    report.viewPort = `${RwPrefUtils.viewWest.toFixed(6)},${RwPrefUtils.viewSouth.toFixed(6)},
      ${RwPrefUtils.viewEast.toFixed(6)},${RwPrefUtils.viewNorth.toFixed(6)}`; // W S E N
    report.query = this.searchText;
    report.message = "";
    this.globals.showAddReport = false;
    this.globals.showAddMissingReport = true;
    this.globals.addReport = report;
    this.globals.showSearchPanel = false;
    this.searchMenuVisible = false;
    this.globals.showReloPicker("", report.latDelta, report.lngDelta, false, true)
        .then(geoPoint => {
          this.globals.addReport.lngDelta = geoPoint.lng;
          this.globals.addReport.latDelta = geoPoint.lat;
          this.globals.addReport.step = 1;
        })
        .catch(() => {
          RwLog.error(SOURCE, `Error with relo picker choosing loc for new address \n\t Report:${JSON.stringify(report)}`);
        });
  }

  reportAddress() {
    const SOURCE = "RwSearchView.ReportAddress";
    //RwLog.consoleLog("Reporting ", this.selectedResult);
    let report = new RwAddReport();
    report.status = RwAddStatusEnum.Pending;
    report.type = RwAddReportEnum.Correction;
    report.vote = RwAddVoteEnum.Indeterminate;
    report.name = this.selectedResult.name;
    report.lat = this.selectedResult.lat;
    report.lng = this.selectedResult.lng;
    report.address = this.selectedResult.address;
    report.userId = this.globals.userId;
    report.viewPort = `${RwPrefUtils.viewWest.toFixed(6)},${RwPrefUtils.viewSouth.toFixed(6)},
      ${RwPrefUtils.viewEast.toFixed(6)},${RwPrefUtils.viewNorth.toFixed(6)}`; // W S E N
    report.query = this.searchText;
    report.message = `Correction for ${report.name}`;
    this.globals.showAddMissingReport = false;
    this.globals.showAddReport = true;
    this.globals.addReport = report;
    this.globals.showSearchPanel = false;
    this.searchMenuVisible = false;
    this.globals.showReloPicker("", report.lat, report.lng, false, true)
        .then(geoPoint => {
          this.globals.addReport.lngDelta = geoPoint.lng;
          this.globals.addReport.latDelta = geoPoint.lat;
          this.globals.addReport.step = 1;
        })
        .catch(() => {
          RwLog.error(SOURCE, `Error with relo picker correcting address \n\t Report:${JSON.stringify(report)}`);

        });
  }

  showSearchMenu(e, result: RwSearchResult) {
    this.onSearchPick(result);
    e.preventDefault()
    this.searchMenuVisible = false;
    this.menuX = e.clientX;
    this.menuY = e.clientY;
    this.$nextTick(() => {
      this.searchMenuVisible = true
    })
  }

  onSearchPick(result: RwSearchResult) {
    this.setFocusNav();
    this.selectedResult = result;
    this.selectedResult.query = this.searchText;
    this.globals.centerMapData = {lat: result.lat, lng: result.lng};
    // let navLat = result.AccessPoint?.lat ?? result.lat;
    // let navLng = result.AccessPoint?.lng ?? result.lng;
    //RwTaskSearch.getAddOverride(result.name, result.address, result.lat, result.lng, navLat, navLng)

    // Skip get add override if geo coords
    if (result.address) {
      RwTaskSearch.getAddOverride(result.address)
          .then(override => {

            if (override && override.add === this.selectedResult.address) {
              this.selectedResult.lat = override.lat;
              this.selectedResult.lng = override.lng;

              this.selectedResult.navLat = override.navLat;
              this.selectedResult.navLng = override.navLng;

              // if (override.ports && override.ports.length > 0) {
              //   this.selectedResult.navLat = override.ports[0].lat;
              //   this.selectedResult.navLng = override.ports[0].lng;
              // }
              this.globals.centerMapData = {lat: this.selectedResult.lat, lng: this.selectedResult.lng};
              //const stopToUpdate = theGlobals.activeRoute.stops.find(s => s.stopId === addedStop.stopId);
              //const routeStopToUpdate = theGlobals.routes.find(rt => rt.routeId === stopToUpdate.routeId).stops.find(s => s.stopId === addedStop.stopId);

            }
          })
          .catch(err => {
            RwLog.warn("Get override failed", err.toString());
          });
    }

  }


  onActionPick(pinType: RwPinTypes, result: RwSearchResult, entityId: string, message: string) {
    //console.log("onActionPick", pinType, message)
    this.resetSearchInput();
    this.globals.showSnack(message, "success");
    this.space.searchPinData = {pinType: pinType, lat: result.lat, lng: result.lng, id: entityId};
  }


  onSearchInput() {
    // console.log("searchText:", this.searchText);
    //this.resetLayers();
    // console.warn("onSearchInputReached");
    let query = this.searchInputClean(this.searchText);
    // console.log("cleaned text:", query);
    if (this.isSearchReady(query)) {
      // console.warn("isSearchReady success");
      this.search(query);
    }
  }

  searchInputClean(searchText: string) {
    let clean = searchText?.replace(/[$%^;*"{}|]/g, '') ?? "";
    // eventually replace with:       let clean = searchText.replace(/[!@$%^&*(),?":{}|<>]/g,'');

    return clean;

  }

  // @Watch("space.zoomLevel")
  // watchZoomLevel(level: number){
  //   // console.warn("watchZoomLevel triggered");
  //   // console.warn("zoomCheck: ", this.zoomCheck);
  //   if(level>=7){
  //     this.onSearchInput();
  //     // console.warn("search input reached with search text:", this.searchText);
  //   }
  // }

  private isSearchReady(query: string): boolean {
    const self = this;
    let isReady = false;
    let now = Date.now();
    let minTimeMS = 1000;

    // if(this.space.zoomLevel>7){
    // console.warn("we have made it inside the zoom loop");
    if (query && query.trim().length >= 4) {
      query = query.trim();
      if (!this.queryInProg) {
        isReady = true; //1st query
      } else {
        let isQueryDelta = query != this.queryInProg && query != this.queryOnDeck;
        if (isQueryDelta) {

          let elapsed = now - this.queryInProgTS;
          if (elapsed > minTimeMS) {
            //console.log("isSearchReady: elapsed > 1 second", query);
            this.queryOnDeck = undefined;
            isReady = true;
          } else {
            //console.log("isSearchReady: queryOnDeck", query);
            //Queue up: One time retry
            this.queryOnDeck = query;
            setTimeout(() => {
              if (query === this.queryOnDeck) {
                //console.log("isSearchReady: Retry", query);
                this.queryOnDeck = undefined;
                self.search(query);
              } else {
                //console.warn("isSearchReady Retry: obviated", query, this.queryOnDeck);
              }
            }, minTimeMS - elapsed);

          }
        }
      }
    }
    // }else{
    //   // console.warn("still outside the zoom loop");
    //   // this.zoomCheck = true;
    //   isReady=false;
    // }

    return isReady;
  }


  private search(query: string) {
    let now = Date.now();
    query = query.trim();
    this.queryInProg = query;
    this.queryInProgTS = now;
    this.activeListItem = this.selectedResult = null;
    let SOURCE = 'RwSearchView.search';

    if (/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(query)) {
      this.searchCoords(query)
          .then(res => this.updateSearchResults(res, RwSearchType.Coord))
          .catch(err => RwLog.consoleError("Error getting search coords", err));
    } else {
      this.clearSearchCoords();
    }

    if (RwPrefUtils.searchWeb && query.length >= 6) {
      if (this.globals.token !== RwConstants.EmptyGuid && this.globals.userId !== RwConstants.EmptyGuid) {
        RwTaskSearch.getWebSuggestions(query, theGlobals.m_showReloPicker)
            .then(res => this.updateSearchResults(res, RwSearchType.Web))
            .catch(err => RwLog.consoleError("WTF: getSuggestions web error", err))
      } else {
        RwLog.warn(SOURCE, `Force logout, Invalid token: ${RwPrefUtils.token} or userID: ${this.globals.userId}`);
        let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
        dal.forceLogout(logErr);
      }
    }

    if (RwPrefUtils.searchSites) {
      RwTaskSearch.getSiteSuggestions(query, this.idxSites)
          .then(res => this.updateSearchResults(res, RwSearchType.Sites))
          .catch(err => RwLog.consoleError("WTF: getSuggestions sites error", err))
    }

    if (RwPrefUtils.searchTasks && query.length >= 6) {
      RwTaskSearch.getTaskSuggestions(query, this.idxTasks)
          .then(res => this.updateSearchResults(res, RwSearchType.Tasks))
          .catch(err => RwLog.consoleError("WTF: getSuggestions tasks error", err));
    }

  }


  onSearchEnter() {
    const source = "RwSearchView.onSearchEnter";
    let gotSelection = !!this.selectedResult;
    //console.log(`onInputEnter gotSelection: ${gotSelection}`)
    if (!gotSelection) {
      if (this.searchText != null && this.searchText.length > 3) {
        let isCoord = RwTextUtils.isCoordinate(this.searchText);
        //console.log("isCoord", isCoord);
        if (isCoord) {
          let split = this.searchText.split(',');
          if (split.length === 2) {
            let lat = parseFloat(split[0]);
            let lng = parseFloat(split[1]);
            if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
              RwTaskSearch.geocode(this.searchText)
                  .then(res => this.updateSearchResults(res, RwSearchType.Web))
                  .catch(err => RwLog.consoleError("WTF: geocode web error", err))
            } else {
              RwLog.warn(source, `Entered invalid gps coord to geocode, input: ${this.searchText}`);
              theGlobals.showSnack("Please enter a valid gps coordinate.", "error");
            }
          } else {
            RwLog.error(source, `IsCoord true, but string.split length != 2, input: ${this.searchText}`);
            theGlobals.showSnack("Please enter a valid gps coordinate.", "error");
          }
        } else {
          RwTaskSearch.geocode(this.searchText)
              .then(res => this.updateSearchResults(res, RwSearchType.Web))
              .catch(err => RwLog.consoleError("WTF: geocode web error", err))
        }
      }
    }
  }


  private updateSearchResults(results: RwSearchResult[], searchType: RwSearchType) {
    //console.log("updateSearchResults", searchType, results.length);

    //MONITOR: SEARCH: Make sure results are matched up for current query (use TS or counter)
    switch (searchType) {
        //@formatter:off
      case RwSearchType.Coord:
        this.space.searchResultsCoords = results;
        break;
      case RwSearchType.Web:
        this.space.searchResultsWeb = results;
        break;
      case RwSearchType.Sites:
        this.space.searchResultsSites = results;
        break;
      case RwSearchType.Tasks:
        this.space.searchResultsTasks = results;
        break;
        //@formatter:on
    }
  }


  onSearchChange() {
    //NOTE: Occurs when (text has changed && search looses focus)
    //console.log("onSearchChange", this.searchText);
  }

  storeResultReport(result: RwSearchResult, entityId: string) {
    if (result) {
      let report = new RwAddReport();
      report.status = RwAddStatusEnum.Pending;
      report.type = RwAddReportEnum.Correction;
      report.vote = RwAddVoteEnum.Indeterminate;
      report.name = result.name;
      report.lat = result.lat;
      report.lng = result.lng;
      report.address = result.address;
      report.userId = this.globals.userId;
      report.viewPort = `${RwPrefUtils.viewWest.toFixed(6)},${RwPrefUtils.viewSouth.toFixed(6)},
      ${RwPrefUtils.viewEast.toFixed(6)},${RwPrefUtils.viewNorth.toFixed(6)}`; // W S E N
      report.query = this.searchText;
      report.message = `Correction for ${report.name}`;
      report.entityId = entityId;

      this.space.searchReportInfo.push(report);
    }

  }


  onSearchClear() {
    this.clearResults();
  }


  clearResults() {
    this.idxSearch = -1;
    this.searchText = "";
    this.selectedResult = null;
    this.space.searchResultsWeb = [];
    this.space.searchResultsSites = [];
    this.space.searchResultsTasks = [];
  }


  onSearchLocationClick() {
    const self = this;

    if (!self.isGpsButtonDisabled) {
      self.isGpsButtonDisabled = true;
      //console.log("onSearchLocationClick self.isGpsEnabled", self.isGpsButtonDisabled);

      this.globals
          .checkGeoLocationPerms()
          .then((status) => {
            //console.log("onSearchLocationClick self.isGpsEnabled", self.isGpsButtonDisabled);
            if (status && status.state) {
              switch (status.state) {
                case "granted":
                  self.updateMapFromGpsFix();
                  break;
                case "denied":
                  self.isGpsButtonDisabled = false;
                  self.globals.showSnack("GPS Permission was previously denied. To enable automatic map centering on your location, you can update your preference in Browser >> Settings >> Permissions >> Location ", "warning")
                  break;
                case "prompt":
                  //Permission Priming Goes Here
                  self.isGpsButtonDisabled = false;
                  self.globals
                      .showConfirmDialog(
                          "Grant Location Permission",
                          "Allowing location permission will enable the RoadWarrior app to automatically center the map on your position.  Do you want to grant this permission now?",
                          "Yes",
                          "No")
                      .then((isYes) => {
                        if (isYes) {
                          self.globals.promptForLocationPermission()
                              .then((isGranted => {
                                if (isGranted) {
                                  self.updateMapFromGpsFix();
                                }
                              }))
                        }
                      });
              }
            }
          });
    }
  }


  updateMapFromGpsFix() {
    const self = this;
    RwGlobals
        .getGeolocation()
        .then((pos) => {
          self.isGpsButtonDisabled = false;
          if (pos && pos.coords) {
            let lat = pos.coords.latitude;
            let lng = pos.coords.longitude
            //console.log("getGeolocation => ", lat, lng)
            self.space.mapCentroid = {lat: lat, lng: lng, zoom: 12.0};
          }
        });
  }


  // onSearchSelect() {
  //   //console.log("onSearchSelect");
  //   if (this.selectedResult) {
  //     let result = this.selectedResult;
  //     //console.log("onSearchSelect selected: ", result.name, result.address);
  //   }
  // }


  //#endregion Search APIs


  //#region Search Result Actions

  onAddStopClick() {
    const self = this;
    let selResult = self.selectedResult;
    if (selResult) {
      this.globals.centerMapData = {lat: 0, lng: 0};
      // this.space.showDataLayerRoute = true;
      if (selResult.address) {
        this.space
            .addStopFromSearch(selResult)
            .then(addedStop => {
              this.storeResultReport(selResult, addedStop.stopId);
              if (this.globals.editAfterAdd) this.globals.recentStopAdd = true;
              //self.resetSearchInput();
              //self.globals.showSnack("Stop added to route ", "success")
              self.onActionPick(RwPinTypes.Stop, selResult, addedStop.stopId, "Stop added to route");
            })
            .catch(err => {
              self.globals.showSnack("Add Stop Failed", "error")
              RwLog.consoleError("WTF: addStopFromSearch FAIL", err.toString());
            });
      } else {
        this.space
            .addStopFromSearch(selResult)
            .then(addedStop => {
              if (this.globals.editAfterAdd) this.globals.recentStopAdd = true;

              //self.resetSearchInput();
              //self.globals.showSnack("Stop added to route ", "success")
              self.onActionPick(RwPinTypes.Stop, selResult, addedStop.stopId, "Stop added to route")
            })
            .catch(err => {
              self.globals.showSnack("Add Stop Failed", "error")
              RwLog.consoleError("WTF: addStopFromSearch FAIL", err.toString());
            });
      }
    }
  }


  onAddSiteClick() {
    const self = this;
    if (this.selectedResult) {
      this.globals.centerMapData = {lat: 0, lng: 0};
      // this.space.showDataLayerSites = true;
      this.space
          .addSiteFromSearch(self.selectedResult)
          .then(siteAdded => {
            if (siteAdded) {
              this.storeResultReport(this.selectedResult, siteAdded.siteId);
              self.onActionPick(RwPinTypes.Site, self.selectedResult, siteAdded.siteId, "Favorite Created")
            } else {
              self.globals.showSnack("Create Favorite Failed", "error")
              RwLog.warn("onAddSiteClick", `Create favorite failed, user selected no to adding duplicate
               selected result:${JSON.stringify(this.selectedResult)}`);
            }

            // self.resetSearchInput();
            // self.globals.showSnack("Favorite Created", "success")
          })
          .catch(err => {
            self.globals.showSnack("Create Favorite Failed", "error")
            RwLog.consoleError("WTF: onAddSiteClick FAIL", err.toString());
          });
    }
  }


  onAddTaskClick() {
    const self = this;
    if (this.selectedResult) {
      this.globals.centerMapData = {lat: 0, lng: 0};
      // this.space.showDataLayerTasks = true;

      this.space
          .addTaskFromSearch(self.selectedResult)
          .then(task => {
            this.storeResultReport(this.selectedResult, task.stopId);
            self.onActionPick(RwPinTypes.Task, self.selectedResult, task.stopId, "Task Created")
            if (self.globals.editAfterAdd) {
              this.globals.recentStopAdd = true;
              self.globals.showStopEdit(task);
            }
            // self.resetSearchInput();
            // self.globals.showSnack("Task Created", "success")
          })
          .catch(err => {
            self.globals.showSnack("Add Task Failed", "error")
            RwLog.consoleError("WTF: onAddTaskClick FAIL", err.toString());
          });
    }
  }


  onUpdateStartClick() {
    const self = this;
    //console.log("onUpdateStartClick");
    if (this.selectedResult) {
      this.globals.centerMapData = {lat: 0, lng: 0};
      this.space.showDataLayerRoute = true;
      this.space.updateStartFromSearch(self.selectedResult)
          .then(res => {
            self.onActionPick(RwPinTypes.Route, self.selectedResult, "", "Route Start Updated")
            // self.resetSearchInput();
            // self.globals.showSnack("Route Start Updated", "success")
            //console.log("updateStartFromSearch success");
          })
          .catch(err => {
            self.globals.showSnack("Route Start Failed", "error")
            RwLog.consoleError("WTF: updateStartFromSearch FAIL", err.toString());
          });
    }

  }


  resetSearchInput() {
    this.clearResults()
    let txtSearch = (this.$refs.search as HTMLInputElement);
    this.$nextTick(() => txtSearch.focus());
  }


  //#endregion Search Actions


  //#region LifeCycle


  @Watch("isSearchMode")
  watchShowSearchPanel(isSearchMode: boolean) {
    const self = this;
    this.clearResults();
    //console.log("RwSearchView.watchShowSearchPanel", isSearchMode)
    if (isSearchMode) {
      setTimeout(function () {
        (self.$refs.search as HTMLInputElement).focus();
      }, 100);
    }
  }


  mounted() {
    //console.log("mounted");
    this.clearResults();
  }

  //#endregion LifeCycle


  //region Keyboard Navigation

  selectItem() {
    this.onSearchPick(this.searchResults[this.activeListItem]);
    if (this.activeAction === null) this.nextAction();
    this.idxSearch = this.activeListItem;
  }

  previousItem() {
    if (this.activeListItem === null) {
      this.activeListItem = this.searchResults.length - 1;
    } else if (this.activeListItem >= 0) {
      this.activeListItem--;
    }
    this.selectItem();
    if (this.activeListItem === -1) this.setFocusSearch();
  }

  nextItem() {
    if (this.activeListItem === null) {
      this.activeListItem = 0;
    } else {
      if (this.activeListItem < this.searchResults.length - 1) this.activeListItem++;
    }
    this.selectItem();
  }

  previousAction() {
    if (!this.selectedResult) return;
    if (this.activeAction === null) {
      this.activeAction = 3;
    } else {
      this.activeAction--;
    }
    switch (this.activeAction) {
      case -1:
        this.nextAction();
        break;
      case 0:
        if (!this.canAddStop) this.nextAction();
        break;
      case 1:
        if (!this.canAddSite) this.previousAction();
        break;
      case 2:
        if (!this.globals.isDisp || !this.canAddTask) this.previousAction();
        break;
      case 3:
        if (!this.canUpdateStart) this.previousAction();
        break;
    }
  }

  nextAction() {
    if (!this.selectedResult) return;
    if (this.activeAction === null) {
      this.activeAction = 0;
    } else {
      this.activeAction++;
    }
    switch (this.activeAction) {
      case 0:
        if (!this.canAddStop) this.nextAction();
        break;
      case 1:
        if (!this.canAddSite) this.nextAction();
        break;
      case 2:
        if (!this.globals.isDisp || !this.canAddTask) this.nextAction();
        break;
      case 3:
        if (!this.canUpdateStart) this.previousAction();
        break;
      case 4:
        this.previousAction();
        break;
    }
  }

  setActiveListItem(idx, active) {
    this.activeListItem = active ? idx : null;
  }

  setFocusNav(position: string = null) {
    (this.$refs.hiddenNav as HTMLInputElement).focus();
    if (position === "first") {
      this.nextItem();
    } else if (position === "last") {
      this.previousItem();
    }
  }

  setFocusSearch() {
    this.$nextTick(() => (this.$refs.search as HTMLInputElement).focus());
    this.activeListItem = null;
    this.activeAction = null;
  }

  onSelectionEnter() {

    switch (this.activeAction) {
      case 0:
        this.onAddStopClick();
        break;
      case 1:
        this.onAddSiteClick();
        break;
      case 2:
        this.onAddTaskClick();
        break;
      case 3:
        this.onUpdateStartClick();
        break;
    }
    this.activeListItem = null;
    this.activeAction = null;
  }

  clearResultsOrClose(event) {
    if (event.target.value) {
      this.clearResults();
    } else {
      this.globals.showSearchPanel = false;
    }
  }

  //endregion Keyboard Navigation
  async searchCoords(query: string) {
    let [lat, lng]: any[] = query.split(",");
    lat = Number(lat);
    lng = Number(lng);
    if (lat <= 90 && lat >= -90 && lng <= 180 && lng >= -180) {
      const result = new RwSearchResult({
        name: `Latitude: ${lat}, Longitude: ${lng}`,
        lat,
        lng,
        type: RwSearchType.Coord
      });
      return [result];
    } else {
      throw new Error("Lat and Lng malformed.");
    }
  }

  async clearSearchCoords() {
    this.updateSearchResults([], RwSearchType.Coord);
  }

  activated() {
    this.stopColor = RwPrefUtils.stopColor;
    this.taskColor = RwPrefUtils.taskColor;
    this.siteColor = RwPrefUtils.siteColor;
  }
}
