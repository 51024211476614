import {RwSite} from "@/app/dem/RwSite";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwUser} from "@/app/dem/RwUser";
import {RwTextUtils} from "@/app/utils/RwTextUtils";
import {RwSearchResult} from "@/app/dem/RwSearchResult";
import {RwLoginIssues, RwSearchType} from "@/app/RwEnums";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import {RwLog} from './RwLog';
import theGlobals, {RwGlobals} from "@/app/RwGlobals"
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwStop} from "@/app/dem/RwStop";
import {RwPlace} from "@/app/dem/RwPlace";
import {RwLoginError} from "@/app/RwErrors";
import planSpace from "@/app/views/planner/RwPlannerSpace";

export class RwTaskSearch {

    //#region Props

    // static idxSitesCacheTime = 0;
    // static idxSitesCached: { [id: string]: RwSite } = {};
    // searchWebWip = false;
    // searchWebDone = false;
    // searchSitesDone = false;
    // searchSitesWip = false;
    // webResults: RwSearchResult[] = null;

    searchWeb = RwPrefUtils.searchWeb;
    searchSites = RwPrefUtils.searchSites;
    siteResults: RwSearchResult[] = null;
    searchQuery: string;

    get globals(): RwGlobals {
        return theGlobals;
    }

    //#endregion Props


    //#region AutoComplete

    static getWebSuggestions(query: string, fromRelo = false): Promise<RwSearchResult[]> {
        let search = new RwTaskSearch();
        return search.getWebSuggestions(query, fromRelo);
    }

    static getSiteSuggestions(query: string, idxSites: { [id: string]: RwSite }): Promise<RwSearchResult[]> {
        let search = new RwTaskSearch();
        return search.getSiteSuggestions(query, idxSites);
    }

    static getTaskSuggestions(query: string, idxTasks: { [id: string]: RwStop }): Promise<RwSearchResult[]> {
        let search = new RwTaskSearch();
        return search.getTaskSuggestions(query, idxTasks);
    }


    static getAddOverride(addText: string): Promise<RwPlace> {
        const SOURCE = "RwTaskSearch.getAddOverride";

        return new Promise<RwPlace>(function (resolve, reject) {
            let payload = JSON.stringify(stop);
            const encAdd = encodeURIComponent(addText);

            if (dal.gotToken()) {

                let url = `${RwConstants.CoreUri}/search/getAddOverride?addText=${encAdd}`;
                dal.callWithToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        if (res) {
                            if (res.data) {
                                //const json = res.data;
                                let suggest = res.data as RwPlace;
                                resolve(suggest);
                            } else {
                                // If 200 but no data, there just is no override
                                //RwLog.error(SOURCE, `POST url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                                resolve(null);
                            }
                        } else {
                            RwLog.error(SOURCE, `POST url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                            reject();
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }


    // static getLocationOverride(nameText: string, addText: string, posLat: number, posLng: number, accLat: number, accLng:number): Promise<RwPlace> {
    //   const SOURCE = "RwTaskSearch.getLocationOverride";
    //
    //   return new Promise<RwPlace>(function(resolve, reject) {
    //     let payload = JSON.stringify(stop);
    //     const encName = encodeURIComponent(nameText);
    //     const encAdd = encodeURIComponent(addText);
    //
    //     if (dal.gotToken()) {
    //
    //       let url = `${RwConstants.CoreUri}/search/selectSuggest?nameText=${encName}&addText=${encAdd}&posLat=${posLat}&posLng=${posLng}&accLat=${accLat}&accLng=${accLng}`;
    //       dal.callWithToken
    //         .get(url, {timeout: RwConstants.NetTimeout})
    //         .then(res => {
    //           if (res) {
    //             if (res.data) {
    //               const json = res.data;
    //               let suggest = res.data as RwPlace;
    //               resolve(suggest);
    //             }
    //             else {
    //               // If 200 but no data, there just is no override
    //               //RwLog.error(SOURCE, `POST url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
    //               resolve(null);
    //             }
    //           }
    //           else {
    //             RwLog.error(SOURCE, `POST url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
    //             reject();
    //           }
    //         })
    //         .catch((error) => {
    //           reject(error);
    //         });
    //     }
    //     else {
    //       RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
    //       let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
    //       dal.forceLogout(logErr);
    //       return Promise.reject(logErr);
    //     }
    //
    //   });
    // }

    private getWebSuggestions(query: string, fromRelo = false): Promise<RwSearchResult[]> {
        const SOURCE = "RwTaskSearch.getWebSuggestions";

        return new Promise<RwSearchResult[]>(function (resolve, reject) {

            if (dal.gotToken()) {

                let queryEnc = encodeURIComponent(query);
                let centerLat: number = fromRelo ? planSpace.reloCoordsLat : RwPrefUtils.viewCenterLat;
                let centerLng: number = fromRelo ? planSpace.reloCoordsLng : RwPrefUtils.viewCenterLng;
                let lat = centerLat.toFixed(4);
                let lng = centerLng.toFixed(4);
                let lang = "en"; // navigator.userLanguage; // RwSysUtils.langCode;
                let cntryCode = ""; //navigator. RwSysUtils.cntryCode;
                let radius = RwPrefUtils.viewRadius.toFixed(4);

                //SERVER expects WSEN order for within
                let w = RwPrefUtils.viewWest;
                let s = RwPrefUtils.viewSouth;
                let e = RwPrefUtils.viewEast;
                let n = RwPrefUtils.viewNorth;
                let withInWSEN = RwTaskSearch.createWithinWSEN(w, s, e, n);
                //let withInSWNE = self.createWithinSWNE(s, w, n, e);
                let url = `${RwConstants.CoreUri}/search/getSuggest?query=${queryEnc}&at=${lat},${lng}&radius=${radius}&lang=${lang}&cntry=${cntryCode}&within=${withInWSEN}`;

                dal.callWithToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        //console.log("getWebSuggestions JSON", res.data.predictions[0]);
                        const json = res.data;
                        let results = RwSearchResult.fromJsonPredictions(json);
                        //console.log("getWebSuggestions RESULT", results[0]);
                        if (results != null && results.length > 0) {
                            results.forEach(result => {
                                result.type = RwSearchType.Web;
                                if (!result.placeId || result.placeId === "") {
                                    result.placeId = RwSysUtils.guidNew();
                                }
                            });
                        }
                        resolve(results);
                    })
                    .catch((err) => {
                        RwLog.consoleError("WTF: getWebSuggestions", err);
                        reject(err);
                    });
            } else {
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }
        });
    }


    // private createWithinSWNE(s: number, w: number, n: number, e: number): string {
    //   const SOURCE = "RwTaskSearch.createWithinSWNE";
    //
    //   //Place in expected S,W,N,E format
    //   let withIn = `${s.toFixed(4)},${w.toFixed(4)},${n.toFixed(4)},${e.toFixed(4)}`;
    //
    //   let isOutOfBounds = false;
    //   if (s < -90) {
    //     isOutOfBounds = true;
    //     s = -90;
    //   }
    //   else if (s > 90) {
    //     isOutOfBounds = true;
    //     s = 0
    //   }
    //
    //   if (n > 90) {
    //     isOutOfBounds = true;
    //     n = 90;
    //   }
    //   else if (n < -90) {
    //     isOutOfBounds = true;
    //     n = 0;
    //   }
    //
    //   if(isOutOfBounds) {
    //     RwLog.error(SOURCE, `isOutOfBounds == true \n bounds:${withIn}`);
    //     withIn = `${s.toFixed(4)},${w.toFixed(4)},${n.toFixed(4)},${e.toFixed(4)}`;
    //   }
    //
    //   return withIn;
    // }


    static createWithinWSEN(w: number, s: number, e: number, n: number): string {
        const SOURCE = "RwTaskSearch.createWithinWSEN";

        //Place in expected S,W,N,E format
        let withIn = `${w.toFixed(4)},${s.toFixed(4)},${e.toFixed(4)},${n.toFixed(4)}`;

        let isOutOfBounds = false;
        if (s < -90) {
            isOutOfBounds = true;
            s = -90;
        } else if (s > 90) {
            isOutOfBounds = true;
            s = 0
        }

        if (n > 90) {
            isOutOfBounds = true;
            n = 90;
        } else if (n < -90) {
            isOutOfBounds = true;
            n = 0;
        }

        if (isOutOfBounds) {
            RwLog.error(SOURCE, `isOutOfBounds == true \n bounds:${withIn}`);
            withIn = `${w.toFixed(4)},${s.toFixed(4)},${e.toFixed(4)},${n.toFixed(4)}`;
        }

        return withIn;
    }


    private getSiteSuggestions(query: string, idxSites: { [id: string]: RwSite }): Promise<RwSearchResult[]> {

        return new Promise<RwSearchResult[]>(function (resolve) {

            let results: RwSearchResult[] = [];
            let queryLow = query.toLowerCase();
            let idxSitesLen = Object.keys(idxSites).length;

            if (idxSites && idxSitesLen > 0) {
                for (let key in idxSites) {
                    let isMatch = key.indexOf(queryLow) != -1;
                    if (isMatch) {
                        let site = idxSites[key];
                        let pr = new RwSearchResult();
                        pr.type = RwSearchType.Sites;
                        pr.name = site.name;
                        pr.address = site.address;
                        pr.lat = site.lat;
                        pr.lng = site.lng;
                        pr.placeId = site.siteId;

                        //xFOCUS: Add Email | Phone
                        // pr.phone = site.phone ? site.phone : "";
                        // pr.email = site.email ? site.email : "";


                        results.push(pr);
                    }
                }
            }

            resolve(results);
        });
    }


    private getTaskSuggestions(query: string, idxTasks: { [id: string]: RwStop }): Promise<RwSearchResult[]> {

        //console.log("getSiteSuggestions query", query);

        return new Promise<RwSearchResult[]>(function (resolve) {

            let results: RwSearchResult[] = [];
            let queryLow = query.toLowerCase();
            let idxLen = Object.keys(idxTasks).length;
            //console.log("idxLen.len, queryLow", idxLen, queryLow);

            if (idxTasks && idxLen > 0) {
                for (let key in idxTasks) {
                    let isMatch = key.indexOf(queryLow) != -1;
                    if (isMatch) {
                        let task = idxTasks[key];
                        let pr = new RwSearchResult();
                        pr.type = RwSearchType.Tasks;
                        pr.name = task.name;
                        pr.address = task.address;
                        pr.lat = task.lat;
                        pr.lng = task.lng;
                        pr.placeId = task.stopId;
                        results.push(pr);
                        //console.log("isMatch: ", key, queryLow);
                    }
                }
            }

            resolve(results);
        });
    }


    // private onSubSearchDone(onlyShowWeb: boolean = false): RwSearchResult[] {
    //   //DEFER: Check all callers to see that this is semantically correct
    //   let webDone = false;
    //   if (this.searchWeb && this.searchQuery.length > 5) {
    //     if (this.searchWebDone) {
    //       webDone = true;
    //     }
    //   } else {
    //     webDone = true;
    //   }
    //
    //   let sitesDone = false;
    //   if (this.searchSites && !onlyShowWeb) {
    //     if (this.searchSitesDone) {
    //       sitesDone = true;
    //     }
    //   } else {
    //     sitesDone = true;
    //   }
    //
    //   let allTasksDone = webDone && sitesDone;
    //   if (allTasksDone) {
    //     let results: RwSearchResult[] = [];
    //
    //     if (this.siteResults != null && this.siteResults.length > 0) {
    //       results = results.concat(this.siteResults);
    //     }
    //
    //     if (this.webResults != null && this.webResults.length > 0) {
    //       results = results.concat(this.webResults);
    //     }
    //
    //     // RwLog.warnConsole("resolving webResults", this.webResults);
    //     // RwLog.warnConsole("resolving siteResults", this.siteResults);
    //     // RwLog.warnConsole("resolving results", results);
    //
    //     return results;
    //     //resolve(results);
    //   } else {
    //     return null;
    //   }
    // }


    //#endregion AutoComplete


    //#region Geocoding

    static geocode(searchText: string): Promise<RwSearchResult[]> {
        let finalSearch = searchText;
        if (searchText != null && searchText.length > 3) {
            if (finalSearch.length >= 128) {
                finalSearch = finalSearch.substr(0, 128);
            }

            let isCoord = RwTextUtils.isCoordinate(searchText);
            //console.log("isCoord", isCoord);
            if (isCoord) {
                return this.geocodeV2(finalSearch, true);
                //this.geocodeCoord(finalSearch)
            } else {
                return this.geocodeV2(finalSearch, false);
                //this.geocodeAddress(finalSearch)
            }
        }
    }


    static geocodeCoord(searchText: string): Promise<RwSearchResult[]> {

        return new Promise<RwSearchResult[]>(function (resolve, reject) {

            let uinfo = new RwUser();
            let token = uinfo.token;
            if (token != null) {
                let coord = searchText;
                let region = "";
                let lang = "en";
                let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coord}&language=${lang}&region=${region}`;
                //RwLog.consoleLog(`geocodeCoord url`, url);

                dal.callWithToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        const json = res.data;
                        let results: RwSearchResult[] = [];
                        let coords = RwTextUtils.coordFromText(searchText);
                        if (coords != null && coords.length == 2) {
                            let pr = new RwSearchResult();
                            pr.type = RwSearchType.Coord;
                            pr.name = searchText;
                            pr.address = searchText;
                            pr.lat = coords[0];
                            pr.lng = coords[1];
                            pr.placeId = searchText;
                            results.push(pr);
                        }

                        let gresults = RwSearchResult.fromGoogleCoordSearch(json);
                        if (gresults != null && results.length > 0) {
                            gresults.forEach(result => {
                                result.type = RwSearchType.Coord;
                            });
                            results = results.concat(gresults);
                        }

                        resolve(results);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject({message: "unauthorized command."});
            }
        });
    }


    // geocodeAddress(searchText: string): Promise<RwSearchResult[]> {
    //
    //   return new Promise<RwSearchResult[]>(function(resolve, reject) {
    //
    //     let uinfo = new RwUser();
    //     let token = uinfo.token;
    //     if (token != null) {
    //       let addEnc = encodeURI(searchText);
    //       let region = "";
    //       let lang = "en";
    //
    //       let west: number = RwPrefUtils.viewWest;
    //       let south: number = RwPrefUtils.viewSouth;
    //       let east: number = RwPrefUtils.viewEast;
    //       let north: number = RwPrefUtils.viewNorth;
    //       let bounds = `${south.toFixed(4)},${west.toFixed(4)}|${north.toFixed(4)},${east.toFixed(4)}`;
    //
    //       let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${addEnc}&bounds=${bounds}&language=${lang}&region=${region}`;
    //       //RwLog.consoleLog(`geocodeAddress url`, url);
    //
    //       dal.callWithToken
    //         .get(url, { timeout: RwConstants.NetTimeout })
    //         .then(res => {
    //           const json = res.data;
    //           let results = RwSearchResult.fromGoogleAddressSearch(json);
    //           if (results != null && results.length > 0) {
    //             results.forEach(result => {
    //               result.type = RwSearchType.Web;
    //             });
    //           }
    //           resolve(results);
    //         })
    //         .catch((error) => {
    //           reject(error);
    //         });
    //
    //     } else {
    //       reject({ message: "unauthorized command." });
    //     }
    //   });
    // }


    static geocodeV2(query: string, isCoords: boolean): Promise<RwSearchResult[]> {
        const source = "RwTaskSearch.geocodeV2";
        return new Promise<RwSearchResult[]>(function (resolve, reject) {

            let uinfo = new RwUser();
            let token = uinfo.token;
            if (token != null && token !== RwConstants.EmptyGuid) {
                let queryEnc = encodeURIComponent(query);
                let centerLat: number = RwPrefUtils.viewCenterLat;
                let centerLng: number = RwPrefUtils.viewCenterLng;
                let lat = centerLat.toFixed(4);
                let lng = centerLng.toFixed(4);
                let at = encodeURIComponent(`${lat},${lng}`);

                //console.log(`geocodeV2 lat:${lat}, lng:${lng}`)

                let lang = "en"; // navigator.userLanguage; // RwSysUtils.langCode;
                //let cntryCode = ""; //navigator. RwSysUtils.cntryCode;

                let s = RwPrefUtils.viewSouth;
                let w = RwPrefUtils.viewWest;
                let n = RwPrefUtils.viewNorth;
                let e = RwPrefUtils.viewEast;

                //Place in expected S,W,N,E format
                let withIn = `${s.toFixed(4)},${w.toFixed(4)},${n.toFixed(4)},${e.toFixed(4)}`;

                if (s < -90) {
                    RwLog.error(source, `map bounds error south too low \n bounds:${withIn}`);
                    s = -90;
                } else if (s > 90) {
                    RwLog.error(source, `map bounds error south too high \n bounds:${withIn}`);
                    s = 0
                }
                if (n > 90) {
                    RwLog.error(source, `map bounds error north too high \n bounds:${withIn}`);
                    n = 90;
                } else if (n < -90) {
                    RwLog.error(source, `map bounds error north too low \n bounds:${withIn}`);
                    n = 0;
                }

                // Update with bounded coords
                withIn = `${s.toFixed(4)},${w.toFixed(4)},${n.toFixed(4)},${e.toFixed(4)}`;

                let withinEnc = encodeURIComponent(withIn);

                //let withIn = `${w.toFixed(4)},${s.toFixed(4)},${e.toFixed(4)},${n.toFixed(4)}`;
                let radius = RwPrefUtils.viewRadius;
                let radiusFixed = radius.toFixed(0);

                let baseUrl = `${RwConstants.CoreUri}/search/geocoder`;
                let url: string;
                if (isCoords) {
                    url = `${baseUrl}?latLng=${queryEnc}&at=${at}&radius=${radiusFixed}&lang=${lang}&within=${withinEnc}`;
                } else {
                    url = `${baseUrl}?address=${queryEnc}&at=${at}&radius=${radiusFixed}&lang=${lang}&within=${withinEnc}`;
                }

                dal.callWithToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        const json = res.data;
                        let results = RwSearchResult.fromJsonGeocode(json);
                        if (results != null && results.length > 0) {
                            results.forEach(result => {
                                result.type = RwSearchType.Web;
                            });
                        }
                        resolve(results);
                    })
                    .catch((error) => {
                        reject(error);
                    });

            } else {
                reject({message: "unauthorized command."});
            }
        });
    }


    getCountryCode(lat: number, lng: number): Promise<string> {
        return new Promise<string>(function (resolve, reject) {

            let uinfo = new RwUser();
            let token = uinfo.token;
            if (token != null) {

                let baseUrl = `${RwConstants.CoreUri}/search/getCountry`;
                let url = `${baseUrl}?lat=${lat}&lng=${lng}`;

                dal.callWithToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });

            } else {
                reject({message: "unauthorized command."});
            }
        });
    }

    //#endregion Geocoding


}

