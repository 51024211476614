import moment from "moment";
import {RwTimeWindow} from "@/app/dem/RwTimeWindow";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import {RwPinTypes, RwSkedTypes} from "@/app/RwEnums";
import theGlobals from "@/app/RwGlobals";
import {RwPin} from "@/app/dem/RwPin";
import {RwTextUtils} from "@/app/utils/RwTextUtils";

export class RwSked {
    markedForDelete = false;

    skedId: string;
    accountId: string;
    name: string;
    type: RwSkedTypes = RwSkedTypes.None;
    dow: number;
    timeStart: number;
    timeFinish: number;
    isActive: boolean;
    priority: number = 0;
    siteId: string;
    stopId: string;
    duration: number;

    windowsCached: RwTimeWindow[];

    isHovering: boolean = false;
    isSelectedItem: boolean = false;

    lastHash: number = 0;


    clone() {
        let cloneSked = new RwSked();
        cloneSked.accountId = this.accountId;
        cloneSked.skedId = this.skedId;
        cloneSked.name = this.name;
        cloneSked.type = this.type;
        cloneSked.dow = this.dow;
        cloneSked.timeStart = this.timeStart;
        cloneSked.timeFinish = this.timeFinish;
        cloneSked.isActive = this.isActive;
        cloneSked.priority = this.priority;
        cloneSked.siteId = this.siteId;
        cloneSked.stopId = this.stopId;
        cloneSked.isSelectedItem = this.isSelectedItem;
        cloneSked.duration = this.duration;

        //intentionally excluded from clone
        //cloneSked.windowsCached = this.windowsCached;

        return cloneSked;
    }

    constructor(json?) {
        if (json != null) {
            this.fromJson(json);
        } else {
            this.accountId = theGlobals.orgId;
            this.skedId = RwSysUtils.guidNew();
        }
    }


    get dowSet(): number[] {
        let dowSet: number[] = [];

        if (this.type != RwSkedTypes.Weekly) {
            dowSet = [1, 2, 3, 4, 5, 6, 7];
        } else {
            let dow16 = this.dow;
            let mon = (dow16 & 0b00000010) >> 1;
            let tue = (dow16 & 0b00000100) >> 2;
            let wed = (dow16 & 0b00001000) >> 3;
            let thu = (dow16 & 0b00010000) >> 4;
            let fri = (dow16 & 0b00100000) >> 5;
            let sat = (dow16 & 0b01000000) >> 6;
            let sun = (dow16 & 0b10000000) >> 7;

            if (mon != 0) dowSet.push(1);
            if (tue != 0) dowSet.push(2);
            if (wed != 0) dowSet.push(3);
            if (thu != 0) dowSet.push(4);
            if (fri != 0) dowSet.push(5);
            if (sat != 0) dowSet.push(6);
            if (sun != 0) dowSet.push(7);

        }
        //console.log("GET dowSet",  dowSet)
        return dowSet;
    }

    set dowSet(newValue: number[]) {
        let dow16: number = 0;
        newValue.forEach(newDow => {
            //@formatter:off
            switch (newDow) {
                case 1:
                    dow16 = dow16 | 0b00000010;
                    break;
                case 2:
                    dow16 = dow16 | 0b00000100;
                    break;
                case 3:
                    dow16 = dow16 | 0b00001000;
                    break;
                case 4:
                    dow16 = dow16 | 0b00010000;
                    break;
                case 5:
                    dow16 = dow16 | 0b00100000;
                    break;
                case 6:
                    dow16 = dow16 | 0b01000000;
                    break;
                case 7:
                    dow16 = dow16 | 0b10000000;
                    break;
                default:
                    break;
            }
            //@formatter:on
        });
        this.dow = dow16;
    }

    get Monday(): boolean {
        return this.isIsoDayHit(0)
    }

    get Tuesday(): boolean {
        return this.isIsoDayHit(1)
    }

    get Wednesday(): boolean {
        return this.isIsoDayHit(2)
    }

    get Thursday(): boolean {
        return this.isIsoDayHit(3)
    }

    get Friday(): boolean {
        return this.isIsoDayHit(4)
    }

    get Saturday(): boolean {
        return this.isIsoDayHit(5)
    }

    get Sunday(): boolean {
        return this.isIsoDayHit(6)
    }


    get TimeSpan(): string {
        let availStartTime = moment()
            .startOf("day")
            .add(this.timeStart, "millisecond")
            .format("h:mma");
        let availEndTime = moment()
            .startOf("day")
            .add(this.timeFinish, "millisecond")
            .format("h:mma");
        return `${availStartTime} - ${availEndTime}`;
    }

    static fromJsonArray(jsonArray: Object[]) {
        //console.log("RWSked jsonArray", jsonArray);
        let skeds: RwSked[] = [];
        if (jsonArray != null) {
            jsonArray.forEach(function (jval) {
                let sked = new RwSked(jval);
                skeds.push(sked);
            });
        }
        return skeds;
    }

    fromJson(json: Object) {
        for (let key in json) {
            //@formatter:off
            switch (key) {
                case "sid":
                    this.skedId = json[key];
                    break;
                case "uid":
                    this.accountId = json[key];
                    break;
                case "lid":
                    this.siteId = json[key];
                    break;
                case "act":
                    this.isActive = json[key];
                    break;
                case "nm":
                    this.name = json[key];
                    break;
                case "stp":
                    this.type = json[key];
                    break;
                case "dow":
                    this.dow = json[key];
                    break;
                case "tos": {
                    this.timeStart = json[key];
                    //console.warn("RwSked.fromJson", this.timeStart);
                    break;
                }
                case "toe":
                    this.timeFinish = json[key];
                    break;
                case "pri":
                    this.priority = json[key];
                    break;
                case "stpid":
                    this.stopId = json[key];
                    break;
                case "dur":
                    this.duration = json[key];
                    break;
            }
            //@formatter:on
        }
    }

    fromSked(sked: RwSked) {
        this.siteId = sked.siteId;
        this.isActive = sked.isActive;
        this.name = sked.name;
        this.type = sked.type;
        this.dow = sked.dow;
        this.timeStart = sked.timeStart;
        //console.warn("RwSked.fromSked", sked.timeStart);
        this.timeFinish = sked.timeFinish;
        this.priority = sked.priority;
        this.stopId = sked.stopId;
        this.duration = sked.duration;

        this.lastHash = Date.now();
    }

    toJSON() {
        let json = {
            sid: this.skedId,
            uid: this.accountId,
            stp: this.type,
            dow: this.dow,
            tos: this.timeStart,
            toe: this.timeFinish,
            act: this.isActive,
            pri: this.priority,
            dur: this.duration,
        };

        if (this.name != null) {
            json["nm"] = this.name;
        }
        if (this.siteId != null) {
            json["lid"] = this.siteId;
        }
        if (this.stopId != null) {
            json["stpid"] = this.stopId;
        }

        return json;
    }


    //DEFER: Move to Globals; use filter vs. foreach

    static getSiteSkeds(): RwSked[] {
        let weeklies: RwSked[];
        let skeds = theGlobals.skeds;
        if (skeds.length > 0) {
            weeklies = [];
            skeds.forEach(sked => {
                if (sked.type == RwSkedTypes.Weekly) {
                    weeklies.push(sked);
                }
            });
        }
        return weeklies;
    }

    toString() {
        return this.toJSON.toString();
    }

    project(pit: Date): RwTimeWindow[] {
        const self = this;
        const millisInDay = 1000 * 60 * 60 * 24;

        let windows: RwTimeWindow[] = null;
        //console.log("RwSked.project", pit);

        if (self.windowsCached != null) {
            windows = self.windowsCached;
        } else {
            let sow = moment(pit)
                .startOf("isoWeek")
                .valueOf();
            let sod = sow;

            //console.log("Route.[start, finish], WIN [start, finish]", routeStart, routeFinish, win.start, win.end);
            let sowDate = new Date(sow);
            let skedStartAdj = self.timeStart;
            let skedFinishAdj = self.timeFinish;

            //If Overnighter: Adjust StartTime to start yesterday;
            if (self.timeStart >= self.timeFinish) {
                skedStartAdj = self.timeStart - millisInDay;
            }

            let tzo = sowDate.getTimezoneOffset() * 1000; //millis
            let dowOrdStart = self.getIsoDayByFlag(pit.getDay());
            let dowOrdStop = dowOrdStart + 1;
            //console.log("startOfDay, dowStart dowStop", pit, dowOrdStart, dowOrdStop);

            //let dayCounts: number[] = [0,1,2,3,4,5,6];
            //dayCounts.forEach(dayCount => {
            //console.log("dowOrdStart", dowOrdStart, "dowOrdStop", dowOrdStop);


            for (let dayOrd = dowOrdStart; dayOrd <= dowOrdStop; dayOrd++) {
                sod = sow + dayOrd * millisInDay;
                //console.log("startOfDay", new Date(sod));

                let dowHit = self.isIsoDayHit(dayOrd);
                if (dowHit) {
                    // console.log("self.timeStart", self.timeStart);
                    // console.log("self.timeFinish", self.timeFinish);
                    let skedStartDOW = skedStartAdj + sod;
                    let skedFinishDOW = skedFinishAdj + sod;

                    //DST adjustment...
                    let startDateTime = new Date(skedStartDOW);
                    let tzoNow = startDateTime.getTimezoneOffset() * 1000; //millis
                    if (tzo != tzoNow) {
                        //RwLog.errConsole("adjust for DST", tzo, tzoNow);
                        let tzoDelta = tzoNow - tzo;
                        skedStartDOW += tzoDelta;
                        skedFinishDOW += tzoDelta;
                        tzo = tzoNow;
                    }

                    let win = new RwTimeWindow(new Date(skedStartDOW), new Date(skedFinishDOW));
                    if (windows == null) {
                        windows = [];
                    }
                    windows.push(win);

                }
            }
        }

        //self.windowsCached = windows;

        return windows;
    }

    isIsoDayHit(dayOrd: number): boolean {
        let self = this;
        let dowHit = false;
        if (self.type == RwSkedTypes.Weekly) {
            let isoDayOrd = self.getIsoDayByOrd(dayOrd);
            let dowSet = self.dowSet;
            dowSet.forEach(flagDay => {
                let isoDayFlag = self.getIsoDayByFlag(flagDay);
                if (isoDayOrd == isoDayFlag) {
                    dowHit = true;
                }
            });
        } else {
            dowHit = true;
        }
        return dowHit;
    }

    getIsoDayByFlag(dayOfWeekNum: number): number {
        let isoDay = 0;
        switch (dayOfWeekNum) {
            //@formatter:off
            case 0:
                isoDay = 6;
                break; //Sun
            case 1:
                isoDay = 0;
                break; //Mon
            case 2:
                isoDay = 1;
                break; //Tue
            case 3:
                isoDay = 2;
                break; //Wed
            case 4:
                isoDay = 3;
                break; //Thu
            case 5:
                isoDay = 4;
                break; //Fri
            case 6:
                isoDay = 5;
                break; //Sat
            case 7:
                isoDay = 6;
                break; //Sun
            //@formatter:on
        }
        return isoDay;
    }

    getIsoDayByOrd(dowOrd: number): number {
        let isoDay = 0;
        //@formatter:off
        switch (dowOrd) {
            case 0:
                isoDay = 0;
                break; //Mon
            case 1:
                isoDay = 1;
                break; //Tue
            case 2:
                isoDay = 2;
                break; //Wed
            case 3:
                isoDay = 3;
                break; //Thu
            case 4:
                isoDay = 4;
                break; //Fri
            case 5:
                isoDay = 5;
                break; //Sat
            case 6:
                isoDay = 6;
                break; //Sun
            case 7:
                isoDay = 0;
                break; //Sun
            default:
                break;
        }
        //@formatter:on
        return isoDay;
    }

    get imgUrl(): string {
        if (this.skedId === null) {
            return "";
        }
        let args = {typ: RwPinTypes.Sked, pri: this.priority};
        let hash = RwPin.getPinHash(args)
        let url = RwPin.calcSkedPinUrl(hash, args);
        return url;
    }


    get displayHours(): string {
        let text = RwTextUtils.scheduledTextMins(this.timeStart / 60000, this.timeFinish / 60000);
        //text += ` ${this.dowSet}, ${this.timeStart / 60000}, ${this.timeFinish / 60000}`;
        return text;
    }

    get displayFull(): string {
        let text = RwTextUtils.scheduledTextMins(this.timeStart / 60000, this.timeFinish / 60000);
        if (text && text.length > 0) {
            text += ":"
            if (this.Monday) text += " Mo"
            if (this.Tuesday) text += " Tu"
            if (this.Wednesday) text += " We"
            if (this.Thursday) text += " Th"
            if (this.Friday) text += " Fr"
            if (this.Saturday) text += " Sa"
            if (this.Sunday) text += " Su"
        } else {
            text = "Unrestricted";
        }
        return text;
    }


}
