import moment from "moment";
import {RwUser, RwUserInfoExtra} from "@/app/dem/RwUser";
import {RwMember} from "@/app/dem/Profile/RwAcctPickerModel";
import {CreateSubTeamError, RwCheckSubTeamMigrateError, RwCreateDriversError} from "@/app/RwEnums";

export interface CreateNewSubTeamModel {
    DispatcherName: string;

    TeamName: string;

    TeamDomain: string;

    DispatcherEmail: string;

    DispatcherPassword: string;
}

export interface CreateNewSubTeamReturnModel {
    Error: CreateSubTeamError;
    SubTeam: SubTeamModel;
}

export interface CheckMigrateReturnModel {
    UserId: string;

    AccountId: string;

    MigrateToken: string;

    DispatcherEmail: string;

    AccountName: string;

    DispatcherName: string;

    Domain: string;

    Drivers: RwUser[];

    Error: RwCheckSubTeamMigrateError;
}

export interface SubTeamModel {
    UserId: string;

    AccountId: string;

    OrgId: string;

    UserName: string;

    LoginName: string;

    AccountName: string;

    DrvCount: number;

    Domain: string;

    LastUpdated: Date;

    AvatarUrl: string;

    AvInits: string;

    AvType: number;

    AvColor: string;
}

export class SubTeamViewModel {
    Selected: boolean = false;

    ImageUrl: string;

    Data: SubTeamModel;

    get OrgId(): string {
        return this.Data.OrgId;
    }

    get ProfileImage(): string {
        return this.ImageUrl;
    }

    get formatListName() {
        return `${this.Data.AccountName} (${this.Data.UserName})`;
    }

    get formatLastUpdated() {
        return moment(this.Data.LastUpdated).format("L LT");
    }

    constructor(data: SubTeamModel) {
        this.Data = data;
    }
}

export interface CreateDriversReturnModel {
    Successful: Array<string>;
    Errored: Array<string>;
    Existed: Array<string>;
    Error: RwCreateDriversError;
}

export class ProvisioningModel {
    public VirtualDriverCount: number;
    public Drivers: Array<RwUser>;
    public Domain: string;
    public InactiveMembers: Array<RwMember>;
    public DriverExtra: Array<RwUserInfoExtra>;
}

export class RwLicenseCounts {
    LicenseCount: number;
    UsedOptQuota: number;
    UsedUploadQuota: number;
    UsedSearchQuota: number;
    TotalOptQuota: number;
    TotalSearchQuota: number;

    constructor(json?: Object) {
        if (json != null) {
            this.fromJson(json);
        }
    }

    fromJson(json: Object) {
        for (var key in json) {
            switch (key) {
                case "LicenseCount":
                    this.LicenseCount = json[key];
                    break;
                case "UsedOptQuota":
                    this.UsedOptQuota = json[key];
                    break;
                case "UsedSearchQuota":
                    this.UsedSearchQuota = json[key];
                    break;
                case "UsedUploadQuota":
                    this.UsedUploadQuota = json[key];
                    break;
                case "TotalOptQuota":
                    this.TotalOptQuota = json[key];
                    break;
                case "TotalSearchQuota":
                    this.TotalSearchQuota = json[key];
                    break;
            }
        }
    }
}
