import Vue from "vue";
import App from "./app/views/App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import RwStrings from "./app/consts/RwStrings";

import {RwLog} from "./app/dal/RwLog";
import globals from "@/app/RwGlobals";
import {RwConstants} from "@/app/RwConstants";
import localDB from "@/app/RwLocalDB";
import VueGtag from "vue-gtag";
import VueGtm from '@gtm-support/vue2-gtm';

import Cookies from 'js-cookie';
import { RwPrefUtils } from "./app/utils/RwPrefUtils";
import { RwTaskAccounts } from "./app/dal/RwTaskAccounts";
import moment from "moment";
import {flexDisplay} from "@/app/RwEnums";

Vue.component("vuetify", vuetify);
Vue.config.productionTip = false;
if (RwConstants.isDevMode) Vue.config.performance = true;

Vue.use(VueGtag, {
    config: {id: "G-BBG2EK6PW3"},
    appName: 'Flex'
}, router);

Vue.use(VueGtm, {
    id: 'GTM-M2VV333', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    /*  queryParams: {
        // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
        gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
        gtm_preview: 'env-4',
        gtm_cookies_win: 'x'
      },*/
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    //nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: RwConstants.isDevMode, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

router.beforeEach((to, from, next) => {
    //RwLog.consoleLog("router navTo:", to.fullPath);

    if (!!to.query['one-pager-signup'] && to.query['one-pager-signup'] === 'true') {
        loginFromOnePagerSignup();
        return;
    }

    let isLoggedIn: boolean = globals.isLoggedIn;
    let target = to.path.toLowerCase();

    if (isLoggedIn) {

        let isAdminPage = target.startsWith("/adds") || target.startsWith("/logs") || target.startsWith("/users");
        if (isAdminPage) {
            if (globals.isAdmin) {
                next();
            }
        } else {

            if (target.startsWith("/fedex")) {
                globals.isReturningFedexManifest = true;
            }
            if (target.startsWith("/login") || target.startsWith("/register") || target.startsWith("/pro")) {
                next("/planner");
            } else if (target.startsWith("/purchasesuccess") || target.startsWith("/unsubscribe")) {
                next();
            } else {
                /*      setTimeout(() => {

                        //xTEST:
                        //if (RwPrefUtils.flexOptInSelected === 0) {
                        if((globals.isLegacy || globals.isProGo) && !RwPrefUtils.lastOptInDisplayed) {
                          RwTaskMisc.getWebPrefs()
                            .then(prefs => {
                              //REVIEW: This logic does not account for mismatch
                              // @ts-ignore
                              if (!prefs.FlexOptIn || prefs.FlexOptIn === 0) {
                                globals.showFlexOptIn = true;
                              }
                            });
                        }
                      }, 500);*/

                const hasCurrPurch = globals.hasActiveProSku || globals.hasActiveFlexSku;
                const hasFlexPurch = globals.hasActiveFlexSku;
                const hasNoSkus = globals.skuInfos.length === 0;
                if (target.startsWith("/upgrade")) {
                    next();
                } else if (hasCurrPurch) {
                    if (hasFlexPurch) {
                        next();
                    } else if (target.startsWith("/uploads") ||
                        target.startsWith("/reports") ||
                        target.startsWith("/account") ||
                        target.startsWith("/settings") ||
                        target.startsWith("/help")) {
                        next();
                    } else if (target === "/" || target.startsWith("/planner")) {
                        next("/reports");
                    } else {
                        RwLog.consoleWarn("No current flex purch");
                        next("/upgrade");
                    }
                } else if (target.startsWith("/account") || target.startsWith("/referonboard")) {
                    next();
                } else {
                    RwLog.consoleWarn("No current purch");
                    next("/upgrade");
                    //next("/referonboard");
                }
            }
        }
    } else {
        let isAccountPage =
            target.startsWith("/pro") ||
            target.startsWith("/login") ||
            target.startsWith("/register") ||
            target.startsWith("/purchasesuccess") ||
            target.startsWith("/resetpassword") ||
            target.startsWith("/forgotpassword") ||
            target.startsWith("/unsubscribe") ||
            target.startsWith("/referonboard");
        if (isAccountPage) {
            next();
        } else {
            if ((target.startsWith("/account") || target.startsWith("/marketplace") || target.startsWith("/drivers")) && !!to.query.coupon) {
                next({path: "/login", query: {...to.query, redirect: target.replace("/", "")}})
            } else {
                next("/login");
            }
        }
    }


});

router.afterEach((to, from) => {
    document.title = to.meta.title || RwStrings.BaseTitle;
});

const loginFromOnePagerSignup = () => {
    const loginCookie = Cookies.get("rw_login");
    if (loginCookie) {
        globals.logOut();
        localStorage.clear();
        try {
            const loginCookieValues = JSON.parse(loginCookie);
            for (const [key, value] of Object.entries(loginCookieValues)) {
                RwPrefUtils.writeString(key, String(value));
            }
            Cookies.remove("rw_login", {domain: 'roadwarrior.app', path: '/'});
            const SOURCE = "main.onLoginFromOnePagerSignup";

            if (!RwConstants.useDevChargify) {
                Vue.gtm.trackEvent({
                event: 'flex-subscription-add',
                conversionValue: 0
              });
            }

            RwTaskAccounts.login(RwPrefUtils.userName, RwPrefUtils.userPass).then(response => {
                if (response) {
                    globals.isFirstRun = true;
                    RwLog.log(SOURCE, "Successful Login");
                    globals.isLoggedIn = true;
                    const role = response.role;
                    const isOwner = role == 0;
                    const isTeam = response.acttp == 1;
                    const isDisp = isTeam && isOwner;
                    const activeSubs = globals.skuInfos.filter(sub => moment(sub.expires).isAfter(moment()));
                    const isPro = activeSubs.length > 0;
                    globals.role = role;
                    globals.isDisp = isDisp;
                    globals.hasCurrPurch = isPro;
                    globals.isTeam = isTeam;
                    globals.isLegacy = false;
                    globals.flexDisplay = flexDisplay.Team;
                    RwPrefUtils.flexDisplay = flexDisplay.Team;
                    RwPrefUtils.lastGoodUserName = RwPrefUtils.userName;
                    globals.loadData();
                    globals.showOnboardSettings = false;
                    globals.updateGtmDataLayer();

                    router.push("/planner").then(() => {
                        globals.showStartingLocOnlyOnboard = true;
                        globals.showOnboardSettings = true;
                        globals.showOnboardAddStopArrow = true;
                        globals.showOnboardUploadsPopup = true;
                    });
                }
            })
            .catch(err => {
                RwLog.error(SOURCE, 'Chargify.js login after creation FAILURE - err: ' + JSON.stringify(err));
                globals.genericDialog.title = "Error upgrading to Flex";
                globals.genericDialog.text = "Sorry, we had an issue upgrading to Flex. Please refresh the page and try again.";
                globals.genericDialog.icon = "error";
                globals.genericDialog.show = true;
            });
        } catch(e) {}
    }
}

//const v:any = vuetify

const vue = new Vue({
    router,
    vuetify,
    beforeCreate() {
        localDB.getAll("svgs").then(items => globals.loadImgUrls(items));
    },
    render: h => h(App)
}).$mount("#app");

globals.initApp();
