import {RwLoginIssues} from "@/app/RwEnums";

export class RwError {
    status: number;
    isHandled?: boolean;
    error?: any;

    constructor(status: number, isHandled: boolean, error?: any) {
        this.status = status;
        this.isHandled = isHandled;
        this.error = error;
    }

    public toString = (): string => {
        let errDesc = this.error ? this.error.toString() : ""
        return `RwError: (status:${this.status}, error:${errDesc})`;
    }
}

export class RwLoginError extends RwError {
    failCode: RwLoginIssues;

    constructor(
        status: number,
        isHandled: boolean,
        error?: any,
        code?: RwLoginIssues
    ) {
        super(status, isHandled, error);
        this.failCode = code;
    }
}
