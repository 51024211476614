import moment from "moment";

export class RwMember {
    public code: string;
    public memberId: string;
    public acctId: string;
    public userId: string;
    public roleType: number;
    public permission: number;
    public userName: string;
    public acctName: string;
    public sku: string;
    public inviteCode: string;
    public inviteDateMS: number;
    public inviteStatus: number;
    public isDeleted: boolean;
    public isActive: boolean;
    public inviteDate: Date;
    public inviteEmail: string;
    public accessType: number;
    public loginType: number;

    constructor(json: Object) {
        var jsonTyped = <RwMember>json;

        this.code = jsonTyped.code;
        this.memberId = jsonTyped.memberId;
        this.acctId = jsonTyped.acctId;
        this.userId = jsonTyped.userId;
        this.roleType = jsonTyped.roleType;
        this.permission = jsonTyped.permission;
        this.userName = jsonTyped.userName;
        this.acctName = jsonTyped.acctName;
        this.sku = jsonTyped.sku;
        this.inviteCode = jsonTyped.inviteCode;
        this.inviteDateMS = jsonTyped.inviteDateMS;
        this.inviteStatus = jsonTyped.inviteStatus;
        this.isDeleted = jsonTyped.isDeleted;
        this.isActive = jsonTyped.isActive;
        this.inviteDate = jsonTyped.inviteDate;
        this.inviteEmail = jsonTyped.inviteEmail;
        this.accessType = jsonTyped.accessType;
        this.loginType = jsonTyped.loginType;
    }

    get FormatUserName(): string {
        if (this.userName) {
            return this.userName;
        }
        return this.inviteEmail;
    }

    get FormatInviteStatus(): string {
        switch (this.inviteStatus) {
            case -1:
                return "Revoked/Expired";
            case 0:
                return "N/A";
            case 1:
                return "Pending";
            case 2:
                return "Accepted";

            default:
                return "Unknown";
        }
    }

    get FormatExpireDate(): string {
        if (this.inviteDate) {
            var momentInvite = moment(this.inviteDate).add(7, "days");
            if (momentInvite.year() === 1) {
                return "";
            }
            return momentInvite.format("L LT");
        } else {
            return "";
        }
    }

    static fromJsonArray(jsonArray: Object[]): Array<RwMember> {
        var members: RwMember[] = [];
        if (jsonArray != null) {
            jsonArray.forEach(function (jval) {
                let user = new RwMember(jval);
                members.push(user);
            });
        }
        return members;
    }
}

export class MemberLoginResponse {
    public IsError: boolean;
    public ErrorMsg: string;
    public IsSingleMember: boolean;
    public Memberships: Array<RwMember>;

    constructor(json: Object) {
        var typedJson = <MemberLoginResponse>json;
        this.IsError = typedJson.IsError;
        this.ErrorMsg = typedJson.ErrorMsg;
        this.IsSingleMember = typedJson.IsSingleMember;
        this.Memberships = RwMember.fromJsonArray(typedJson.Memberships);
    }
}
