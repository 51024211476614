
import {Component, Prop, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import {RwStop} from "@/app/dem/RwStop";
import {RwPin} from "@/app/dem/RwPin";
import {RwProofOfDeliveryNeeded} from "@/app/dem/RwProofOfDelivery";
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwSysUtils} from "@/app/utils/RwSysUtils";
import RwPODThumbnail from "@/app/views/components/RwPODThumbnail.vue";

@Component({
    components: {RwPODThumbnail}
})
export default class RwStopEdit extends Vue {


  //#region Props

  @Prop(RwStop) readonly initStop: RwStop;

  idxTab = 0;
  statusTab = 0;
  valid: boolean = false;
  initNameText = "";
  initAddressText = "";
  initPhoneText = "";
  initEmailText = "";
  initVisitTime = 0;
  initBaseColor = "";
  initNoteText = "";
  initStatus = 0;

  nameText = "";
  addressText = "";
  phoneText = "";
  emailText = "";
  // m_statusText = "";
  stopStatus = 0;
  baseColor = "";
  statusColor = "";
  noteText = "";
  stopUrl = "";

  initItemToDeliverText = "";
  itemToDeliverText = "";
  
  initProofOfDeliveryNeeded: RwProofOfDeliveryNeeded = {none: true, photo: false, barcode: false, signature: false};
  proofOfDeliveryNeeded: RwProofOfDeliveryNeeded = {none: true, photo: false, barcode: false, signature: false};

  get address(): string {
    return this.addressText;
  }

  set address(val: string) {
    this.addressText = val;
  }

  get globals(): RwGlobals {
    return theGlobals;
  }

  get plan(): RwPlannerSpace {
    return thePlanSpace;
  };

  get isTask(): boolean {
    return this.initStop.isTask;
  }

  get color(): string {
    return this.baseColor;
  }

  set color(val: string) {
    //console.log("set color:", val);
    this.baseColor = val;
    this.onColorPick(val);
  }

  get iconName(): string {
    return this.isTask ? 'mdi-clipboard-check' : 'mdi-map-marker';
  }

  get addressRules(): any[] {
    return [v => v != ('' || undefined) || 'Address field cannot be blank.',
      v => /^(?!\s*$).+/.test(v) || 'Address field cannot be blank.',
      v => v.length <= 200 || 'Address must be 200 characters or less.',
      v => /^[^*|{};@$%]+$/.test(v) || 'Address cannot contain special characters.',
    ];
    // v => /^[^*|\":<>[\]{}`\\()';@&$%]+$/.test(v) || 'Address cannot contain special characters.'
  }

  get stopNameRules() {
    return [v => (v && v.length <= 96 && v !== "") || 'Name is required and must be 96 characters or less.']
  }

  get notesRules(): any[] {
    if (this.noteText) {
      return [v => v.length <= 1000 || 'Must not exceed 1000 characters.'];
    }
  }

  get itemToDeliverTextRules(): any[] {
    if (this.itemToDeliverText) {
      return [v => v.length <= 100 || 'Must not exceed 100 characters.'];
    }
  }

  clickedOnPOD(mode: string) {
    if (mode === "none") {
      if (!this.proofOfDeliveryNeeded.none) {
        Object.keys(this.proofOfDeliveryNeeded).forEach(key => {
          if (key === "none") {
            this.proofOfDeliveryNeeded.none = true;
          } else {
            this.proofOfDeliveryNeeded[key] = false;
          }
        });
      }
    } else {
      this.proofOfDeliveryNeeded[mode] = !this.proofOfDeliveryNeeded[mode];
      let oneSelected = false;
      Object.keys(this.proofOfDeliveryNeeded).forEach(key => {
        if (key !== "none") {
          if (this.proofOfDeliveryNeeded[key]) {
            oneSelected = true;
          }
        }
      });
      this.proofOfDeliveryNeeded.none = !oneSelected;
    }
  }

  getStopUrl() {
    const self = this;
    let pin = this.isTask ? RwPin.fromTask(this.initStop) : RwPin.fromStop(this.initStop);
    if (pin) {
      let url = thePlanSpace.globals.idxURL[pin.urlHash];
      if (url && url.length > 0) {
        self.stopUrl = url;
      } else {
        pin.dataUrlAsync()
            .then(dataUrl => {
              this.stopUrl = dataUrl;
              // console.warn("RwStopView.stopUrl updated", this.stopUrl);
            })
      }
    }
  }

  autoReturn() {
    this.idxTab = 0;
  }

  onStatusRadio(val) {
    this.stopStatus = val;
  }


  get isFirstTab(): boolean {
    return this.idxTab === 0;
  }


  get title(): string {
    let title = "Route Edit";
    switch (this.idxTab) {
        //@formatter:off
      case 0:
        title = `Edit Stop: ${this.initStop ? this.initStop.name : ''}`;
        break;
      case 1:
        title = `Edit Color`;
        break;
      case 2:
        title = 'Edit Visit Time';
        break;
      case 3:
        title = 'Edit Status';
        break;
        //@formatter:on
    }
    return title;
  }


  get isDirty(): boolean {
    let gotNameDelta = RwSysUtils.isLooseDeltaText(this.initNameText, this.nameText);
    let gotAddDelta = RwSysUtils.isLooseDeltaText(this.initAddressText, this.addressText);
    let gotPhoneDelta = RwSysUtils.isLooseDeltaText(this.initPhoneText, this.phoneText);
    let gotEmailDelta = RwSysUtils.isLooseDeltaText(this.initEmailText, this.emailText);
    let gotVisitDelta = RwSysUtils.isLooseDeltaNum(this.initVisitTime, this.m_visitTime);
    let gotColorDelta = RwSysUtils.isLooseDeltaText(this.initBaseColor, this.baseColor);
    let gotNoteDelta = RwSysUtils.isLooseDeltaText(this.initNoteText, this.noteText);
    let gotStatusDelta = RwSysUtils.isLooseDeltaNum(this.initStatus, this.stopStatus)
    let isDirty = this.globals.recentStopAdd || gotNameDelta || gotAddDelta || gotPhoneDelta || gotEmailDelta || gotVisitDelta || gotColorDelta || gotNoteDelta || gotStatusDelta;
    let proofOfDeliveryIsDirty = false;
    if (this.globals.showProofOfDelivery) {
      const gotItemToDeliverDelta = RwSysUtils.isLooseDeltaText(this.initItemToDeliverText, this.itemToDeliverText);
      const PODDelta = Object.keys(this.initProofOfDeliveryNeeded).some(key => this.initProofOfDeliveryNeeded[key] !== this.proofOfDeliveryNeeded[key]);
      proofOfDeliveryIsDirty = gotItemToDeliverDelta || PODDelta;
    }
    return isDirty || proofOfDeliveryIsDirty;
  }


  get isDirtyColor(): boolean {
    return RwSysUtils.isLooseDeltaText(this.initBaseColor, this.baseColor);
  }


  //#endregion


  //#region Navigation

  onColorClick() {
    this.idxTab = 1;
  }

  onStatusClick() {
    this.idxTab = 3;
    if (this.stopStatus < 3) {
      this.statusTab = 0;
    } else if (this.stopStatus < 20 && this.stopStatus > 9) {
      this.statusTab = 1;
    } else {
      this.statusTab = 2;
    }
  }

  statusTabClick(val) {
    switch (val) {
      case 1:
        this.stopStatus = 10;
        break;
      case 2:
        this.stopStatus = 20;
        break;
      case 0:
      default:
        this.stopStatus = 0;
        break;
    }
  }

  onBackClick() {
    //console.log("onBackClick");
    switch (this.idxTab) {
        //@formatter:off
      case 0:
        break;
      case 1:
        break;
      case 2:
        this.m_visitOver = this.tabInitOver;
        this.m_visitTime = this.tabInitVisitTime;
        break;

      case 3:
        break;
      default:
        break;
        //@formatter:on
    }
    //console.log("onBackClick ", this.m_visitOver, this.m_visitTime)
    this.idxTab = 0;
  }

  onColorPick(color: string) {
    if (color.length === 9 && color.endsWith("FF")) {
      color = color.substring(0, 7);
    }
    //console.log("RwColorPicker.onColorPick color", color);
    this.baseColor = color;
    this.idxTab = 0;
  }


  //#endregion Navigation


  //#region Methods


  onCommit(event: Event) {
    // event.stopPropagation();

    const self = this;
    //console.log("RwStopEdit.onCommit");
    let isValidForm = this.isValidForm();
    if (isValidForm) {
      // if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

      this.initStop.name = this.nameText;
      this.initStop.address = this.addressText;
      this.initStop.phone = this.phoneText;
      this.initStop.email = this.emailText;
      this.initStop.visitTime = this.m_visitTime;
      this.initStop.baseColor = this.baseColor;
      this.initStop.note = this.noteText;
      this.initStop.status = this.stopStatus;

      if (this.globals.showProofOfDelivery) {
        if (this.itemToDeliverText === null) {
          this.itemToDeliverText = "";
        }
        this.initStop.addOns.itemToDeliver = this.itemToDeliverText.trim();
        this.initStop.addOns.podPhoto = this.proofOfDeliveryNeeded.photo ? true : false;
        this.initStop.addOns.podBar = this.proofOfDeliveryNeeded.barcode ? true : false;
        this.initStop.addOns.podSig = this.proofOfDeliveryNeeded.signature ? true : false;
      }

      this.globals
          .updateStopDB(self.initStop)
          .then(() => {
            //console.log("RwStopEdit.onCommit", `updateStopDB PASS`);
            self.globals.commitStops();
            event.stopPropagation();
            self.close(true);
          })
          .catch(err => {
            //console.error("RwStopEdit.onCommit", `updateStopDB FAIL: ${err}`);
            //rollback:
            self.initStop.name = self.initNameText;
            self.initStop.address = self.initAddressText;
            self.initStop.phone = self.initPhoneText;
            self.initStop.email = self.initEmailText;
            self.initStop.visitTime = self.initVisitTime;
            self.initStop.baseColor = self.initBaseColor;
            self.initStop.note = self.initNoteText;
            self.initStop.status = self.initStatus;

            if (this.globals.showProofOfDelivery) {
              if (this.initItemToDeliverText) this.initStop.addOns.itemToDeliver = this.initItemToDeliverText;
              if (this.initProofOfDeliveryNeeded.photo) this.initStop.addOns.podPhoto = true;
              if (this.initProofOfDeliveryNeeded.barcode) this.initStop.addOns.podBar = true;
              if (this.initProofOfDeliveryNeeded.signature) this.initStop.addOns.podSig = true;
            }
          })
    } else {
      this.globals.showSnack("Please ensure your entries meet the requirements and try again.", "error");
    }
  }

  isValidForm() {
    let vueForm = this.$refs.form as any;
    let isValid = vueForm.validate();
    return isValid;
  }


  onCancel(event: Event) {
    event.stopPropagation();
    this.close();
  }

  close(isCommit?: boolean) {
    this.$emit("onStopEdited", isCommit);
    this.globals.m_showStopEdit = false;
    this.globals.recentStopAdd = false;
  }

  setProofOfDelivery() {
    if (this.globals.showProofOfDelivery) {
      this.initItemToDeliverText = this.initStop.addOns.itemToDeliver || "";
      this.itemToDeliverText = this.initStop.addOns.itemToDeliver || "";

      this.initProofOfDeliveryNeeded =  {none: true, photo: false, barcode: false, signature: false};
      if (this.initStop.addOns.podPhoto) {
        this.initProofOfDeliveryNeeded.photo = true;
        this.initProofOfDeliveryNeeded.none = false;
      }
      if (this.initStop.addOns.podBar) {
        this.initProofOfDeliveryNeeded.barcode = true;
        this.initProofOfDeliveryNeeded.none = false;
      }
      if (this.initStop.addOns.podSig) {
        this.initProofOfDeliveryNeeded.signature = true;
        this.initProofOfDeliveryNeeded.none = false;
      }
      this.proofOfDeliveryNeeded = {...this.initProofOfDeliveryNeeded};
    }
  }

  //#endregion


  //#region LifeCycle


  mounted() {
    const self = this;
    Vue.nextTick(function () {
      self.initValues();
    });
    // console.warn("this.initStop.status", this.initStop.status);
  }

  initValues() {
    //console.log("RwStopEdit.mounted initStop", this.initStop.name);
    // console.warn("initStatus set to:", this.initStop.status);
    this.initNameText = this.initStop.name;
    this.initAddressText = this.initStop.address;
    this.initPhoneText = this.initStop.phone;
    this.initEmailText = this.initStop.email;
    this.initVisitTime = this.initStop.visitTime;
    this.initBaseColor = this.initStop.baseColor;
    this.initNoteText = this.initStop.note;
    this.initStatus = this.initStop.status;

    this.nameText = this.initStop.name;
    this.addressText = this.initStop.address;
    this.phoneText = this.initStop.phone;
    this.emailText = this.initStop.email;
    this.baseColor = this.initStop.baseColor;
    this.noteText = this.initStop.note;
    this.stopStatus = this.initStop.status;

    this.setProofOfDelivery();

    this.initDefaultMins();
    this.setVisitTime()

    this.getStopUrl();
  }

  //#endregion


  //region Visit Override

  visitTrigger = 0;
  isVisitRangeValid = false;
  m_visitTimeCalc = 0;
  m_visitOver = false;
  m_visitTime = 0;
  tabInitOver = false;
  tabInitVisitTime = 0;
  private defaultVisitTime = 0;

  get visitText(): string {
    //Adding Observable value just for trigger;
    let vizOver = this.m_visitOver;
    let vizTime = this.m_visitTime;
    let visText = RwStop.visitTimeTextPrimitive(this.m_visitOver, this.visitTimeCalc);
    //console.warn("RwStopEdit.visitText", vizOver, vizTime, visText)
    return visText;
  }

  get canApplyVisit(): boolean {
    let isDefault = !this.m_visitOver;
    let gotRequired = !!this.m_visitTime || this.m_visitTime === 0;
    let gotValidRange = this.isVisitRangeValid;
    let gotOverDelta = this.tabInitOver != this.m_visitOver;
    let gotTimeDelta = RwSysUtils.isLooseDeltaNum(this.tabInitVisitTime, this.m_visitTime);
    let canApply = isDefault || (gotRequired && gotValidRange && (gotOverDelta || gotTimeDelta));
    //console.log("canApplyVisit:: isDefault || (gotRequired,  gotValidRange && (gotOverDelta || gotTimeDelta))", isDefault, gotRequired, gotValidRange, (gotOverDelta || gotTimeDelta))
    return canApply;
  }

  get initVisitOver(): boolean {
    return this.initVisitTime >= 0;
    //return this.initStop && this.initStop.visitTime >= 0
  }

  get rules() {
    const self = this;
    return {
      required: value => {

        if (!self.m_visitOver || (!!value || value === 0)) {
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return true;
        } else {
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return false;
        }
      },
      visitRange: value => {
        if (!self.m_visitOver || value >= 0 && value <= 999) {
          self.isVisitRangeValid = true;
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return true;
        } else {
          self.isVisitRangeValid = false;
          //console.log("isVisitRangeValid", self.isVisitRangeValid)
          return 'Between 0 to 999'
        }
      },
    }
  }

  get isVisitOverride(): boolean {
    return this.m_visitOver;
  }

  set isVisitOverride(val: boolean) {
    this.m_visitOver = val;
    this.m_visitTime = val ? this.defaultVisitTime : -1;
    //console.log("SET isOverride", val, this.m_isOverride, this.m_visitTime)
  }

  get visitTimeCalc(): number {
    let trigger1 = this.visitTrigger;
    let trigger2 = this.m_visitOver;
    this.m_visitTimeCalc = this.m_visitOver ? this.m_visitTime : this.defaultVisitTime;
    //console.log("GET visitTimeCalc time, m_isOverride, m_visitTime, defaultVisitTime", this.m_visitTime, this.defaultVisitTime, this.m_visitTimeCalc)
    return this.m_visitTimeCalc;
  }

  set visitTimeCalc(val: number) {
    if (this.m_visitOver) {
      if (val >= 0 && val <= 999) {
        this.m_visitTime = val;
        this.m_visitTimeCalc = val;
      } else {
        if (val < 0) {
          this.m_visitTime = 0;
          this.m_visitTimeCalc = 0;
        }
        if (val > 999) {
          this.m_visitTime = 999;
          this.m_visitTimeCalc = 999;
        }
      }
    } else {
      this.m_visitTime = -1;
    }
    this.visitTrigger++;
    //console.log("SET visitTimeCalc", val, this.m_visitOver, this.m_visitTime)
  }

  private onVisitClick() {
    this.initVisitTab()
    this.idxTab = 2;
  }

  private onCommitVisitTime(event: Event) {
    event.stopPropagation();
    if (!this.m_visitOver) {
      this.m_visitTime = -1;
    }
    //console.log("onCommitVisitTime SET", this.m_isOverride, this.m_visitTime);
    this.idxTab = 0;
  }

  private initVisitTab() {
    this.tabInitOver = this.m_visitOver;
    this.tabInitVisitTime = this.m_visitTime;
    this.visitTrigger++;
    //console.log("initVisitTab ", this.tabInitOver, this.tabInitVisitTime)
  }

  private setVisitTime() {
    if (this.initStop) {
      if (this.initVisitOver) {
        this.m_visitOver = true;
        this.m_visitTime = this.initStop.visitTime;
        this.tabInitOver = this.m_visitOver;
        this.tabInitVisitTime = this.m_visitTime;
      } else {
        this.m_visitOver = false;
        this.m_visitTime = RwPrefUtils.visitTime;
        this.tabInitOver = this.m_visitOver;
        this.tabInitVisitTime = this.m_visitTime;
      }
    }
    //console.log("setVisitTime", this.m_isOverride, this.m_visitTime)
  }

  private initDefaultMins() {
    if (this.initStop && this.initStop.isSited) {
      let site = this.initStop.getSite();
      if (site) {
        this.defaultVisitTime = site.getVisitTime()
      } else {
        this.defaultVisitTime = RwPrefUtils.visitTime;
      }
    } else {
      this.defaultVisitTime = RwPrefUtils.visitTime;
    }
    //console.log("initDefaultMins", this.defaultVisitTime)
  }

  //endregion Visit Override

}
