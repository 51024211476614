

import { Component, Vue } from "vue-property-decorator";
import theGlobals, { RwGlobals } from "@/app/RwGlobals";
import RwPODThumbnail from "@/app/views/components/RwPODThumbnail.vue";
import { PODDefaultSettings } from "@/app/dem/ProofOfDelivery/ProofOfDeliveryEnums";
import theSettingSpace, { RwSettingSpace } from "@/app/views/account/RwSettingSpace";
import thePlanSpace, { RwPlannerSpace } from "@/app/views/planner/RwPlannerSpace";
import { RwPrefUtils } from "@/app/utils/RwPrefUtils";
import { RwConstants } from "@/app/RwConstants";

@Component({ components: { RwPODThumbnail } })
export default class RwPODTutorial extends Vue {

  idxTab = 0;

  PODDefaultSettings = PODDefaultSettings;
  m_proofOfDelivery: PODDefaultSettings = null;
  selectedProofOfDelivery: PODDefaultSettings = null;

  get globals(): RwGlobals {
    return theGlobals;
  }

  get settingSpace(): RwSettingSpace {
    return theSettingSpace;
  }

  get plannerSpace(): RwPlannerSpace {
    return thePlanSpace;
  }

  get proofOfDelivery(): PODDefaultSettings {
    if (this.m_proofOfDelivery == null) {
      this.m_proofOfDelivery = this.settingSpace.proofOfDelivery;
    }
    return this.m_proofOfDelivery;
  }

  set proofOfDelivery(val: PODDefaultSettings) {
    this.m_proofOfDelivery = val;
  }

  selectProofOfDelivery(type: PODDefaultSettings) {
    if (type === PODDefaultSettings.None) {
      this.selectedProofOfDelivery = PODDefaultSettings.None;
    }
    else if (this.selectedProofOfDelivery === PODDefaultSettings.None) {
      this.selectedProofOfDelivery = type === PODDefaultSettings.Photo ? PODDefaultSettings.Photo : PODDefaultSettings.Signature;
    }
    else if (this.selectedProofOfDelivery === PODDefaultSettings.Photo) {
      this.selectedProofOfDelivery = type === PODDefaultSettings.Photo ? PODDefaultSettings.None : PODDefaultSettings.PhotoAndSignature;
    }
    else if (this.selectedProofOfDelivery === PODDefaultSettings.Signature) {
      this.selectedProofOfDelivery = type === PODDefaultSettings.Signature ? PODDefaultSettings.None : PODDefaultSettings.PhotoAndSignature;
    }
    else if (this.selectedProofOfDelivery === PODDefaultSettings.PhotoAndSignature) {
      this.selectedProofOfDelivery = type === PODDefaultSettings.Photo ? PODDefaultSettings.Signature : PODDefaultSettings.Photo;
    }
  }

  saveAndExit() {
    // Save settings
    this.m_proofOfDelivery = this.selectedProofOfDelivery;
    this.settingSpace.proofOfDelivery = this.m_proofOfDelivery;
    RwPrefUtils.proofOfDelivery = this.proofOfDelivery;

    const afterSave = () => {
      if (this.globals.showPODTutorialOnOnboard) {
        this.globals.showPODTutorial = false;
        this.globals.showPODTutorialOnOnboard = false;
        this.globals.setShowOnboardTipsTimer();
      } else {
        this.globals.showPODTutorial = false;
        setTimeout(() => {
          this.globals.showPODSettingsPopup = true;
          setTimeout(() => {
            this.globals.showPODSettingsPopup = false;
          }, RwConstants.PODSettingsPopupDuration);
        }, RwConstants.PODSettingsPopupDelay);
      }
    }

    this.globals.saveProofOfDeliveryDefault().then(() => {
      afterSave();
    }).catch(err => {
      afterSave();
    });
  }

  mounted() {
    this.m_proofOfDelivery = this.settingSpace.proofOfDelivery;
    this.selectedProofOfDelivery = this.m_proofOfDelivery;
  }

}
