import theStore from "@/store";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {RwTaskPush} from "@/app/dal/RwTaskPush";
import {AxiosError} from "axios";

import {RwUser} from "@/app/dem/RwUser";
import {RwPushStatus} from "@/app/RwEnums";


export class RwDriverSpace {

    DriverComponentPrice = 10;

    get globals(): RwGlobals {
        return theGlobals;
    }

    get selectedDriver(): RwUser {
        return this.globals.selectedDriver;
    }

    set selectedDriver(driver: RwUser) {
        theStore.dispatch("selectedDriver", driver);
    }


    get showEditAvatar(): boolean {
        return theStore.getters.showEditAvatar;
    }

    set showEditAvatar(val: boolean) {
        theStore.dispatch("showEditAvatar", val);
    }

    get showEditPassword(): boolean {
        return theStore.getters.showEditPassword;
    }

    set showEditPassword(val: boolean) {
        theStore.dispatch("showEditPassword", val);
    }

    get showReplaceDriver(): boolean {
        return theStore.getters.showReplaceDriver;
    }

    set showReplaceDriver(val: boolean) {
        theStore.dispatch("showReplaceDriver", val);
    }

    get showDeleteDriver(): boolean {
        return theStore.getters.showDeleteDriver;
    }

    set showDeleteDriver(val: boolean) {
        theStore.dispatch("showDeleteDriver", val);
    }

    get showCreateDriver(): boolean {
        return theStore.getters.showCreateDriver;
    }

    set showCreateDriver(val: boolean) {
        theStore.dispatch("showCreateDriver", val);
    }

    get showPingTest(): boolean {
        return theStore.getters.showPingTest;
    }

    set showPingTest(val: boolean) {
        theStore.dispatch("showPingTest", val);
    }

    get showDeletePrompt(): boolean {
        return theStore.getters.showDeletePrompt;
    }

    set showDeletePrompt(val: boolean) {
        theStore.dispatch("showDeletePrompt", val);
    }

    get showCreatePrompt(): boolean {
        return theStore.getters.showCreatePrompt;
    }

    set showCreatePrompt(val: boolean) {
        theStore.dispatch("showCreatePrompt", val);
    }


    pingDriver() {
        const self = this;
        const SOURCE = "RwDriverSpace.pingDriver";

        this.showPingTest = false;
        let driverId = this.globals.selectedDriver.userId;

        RwTaskPush.sendPushDriverPing(driverId)
            .then(res => {
                //console.log(SOURCE, "sendPushDriverPing callback", res, res.status);
                let statusCode = res.status;
                let driverName = this.globals.selectedDriver.formatDisplayName;
                //console.log(SOURCE, "sendPushDriverPing driverName", driverName);
                if (statusCode === 200 && res.data) {
                    let json = res.data;
                    let code = json.Code;
                    let msgId = json.Message;
                    self.globals.showSnack(`Notification to ${driverName} was sent.`, "success");
                    //console.log(SOURCE, code, msgId, driverId);
                    setTimeout(() => self.checkPingStatusLoop(msgId, driverId), 5000);

                } else if (statusCode === 202) {
                    this.globals.showSnack(`${driverName} has no devices registered.`, "warning");
                } else {
                    this.globals.showSnack(`Notification was not delivered to ${driverName}.`, "warning");
                }

            })
            .catch(error => {
                if (error.status === 400) {
                    let axiosError = error as AxiosError;
                    if (axiosError) {
                        let response = axiosError.response;
                        if (response) {
                            this.globals.genericDialog.title = "Unable to send notification.";
                            this.globals.genericDialog.text = "We had an issue sending a push notification to your driver. " +
                                "They may need to refresh their device's push token. This can by done by " +
                                "opening the side menu of the mobile app, tap Help, then tap 'Refresh Push Notifications'. Then, please try to ping again.";
                            this.globals.genericDialog.icon = "warning";
                            this.globals.genericDialog.show = true;
                        } else if (response.status === 404) {
                            this.globals.genericDialog.title = "Driver has no devices registered to send notification.";
                            this.globals.genericDialog.text = "Please make sure your driver is currently signed into the mobile app, and has push notifications enabled.";
                            this.globals.genericDialog.icon = "warning";
                            this.globals.genericDialog.show = true;
                        } else {
                            this.globals.genericDialog.title = "Problem sending push notification.";
                            this.globals.genericDialog.text = "We had an issue sending a push notification to your driver. " +
                                "Please ensure that your driver has push notifications enabled, and try pinging again in several minutes.";
                            this.globals.genericDialog.icon = "warning";
                            this.globals.genericDialog.show = true;
                        }
                    }
                }
            });
    }


    checkPingStatusLoop(msgId: string, driverId: string) {
        const self = this;
        //console.log("checkPingStatusLoop", msgId, driverId);
        let driverName = this.globals.selectedDriver.formatDisplayName;

        let done = false;
        let tryCount = 0;
        let tryLimit = 3;
        (function checkPing() {
            if (!done && tryCount < tryLimit) {
                tryCount++;
                //console.log("checkPing", `try:${tryCount}`);

                RwTaskPush.getPushStatus(msgId, driverId)
                    .then(res => {
                        switch (res) {
                            case RwPushStatus.Delivered:
                                self.globals.showSnack(`Notification to ${driverName} was delivered.`, "success");
                                done = true;
                                break;
                            case RwPushStatus.Opened:
                                done = true;
                                self.globals.showSnack(`Notification to ${driverName} was opened.`, "success");
                                break;
                            case RwPushStatus.Sent:
                                if (tryCount < tryLimit) {
                                    //Retry for up to 1 minute.
                                    setTimeout(checkPing, 10 * 1000);
                                } else {
                                    done = true;
                                    //Do not display message as this is only a false negative.
                                    //self.globals.showSnack(`Notification to ${driverName} was not delivered.`, "warning");
                                }
                                break;
                            case RwPushStatus.Gone:
                                self.globals.showSnack(`Notification to ${driverName} was not delivered.`, "warning");
                                done = true;
                                break;
                            case RwPushStatus.Error:
                                self.globals.showSnack(`Notification to ${driverName} was not delivered.`, "error");
                                done = true;
                                break;
                        }

                    })
                    .catch(err => {
                        done = true;
                        self.globals.showSnack(`Notification to ${driverName} was not delivered.`, "error");
                    })
            }
        })();

    }


}

export default new RwDriverSpace();

