import {RwRoute, RwSyncStatus} from "@/app/dem/RwRoute";
import {RwUser} from "@/app/dem/RwUser";
import {RwLog} from "@/app/dal/RwLog";
import {RwModel} from "@/app/dem/RwModel";
import {RwStop} from "@/app/dem/RwStop";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import globals from "@/app/RwGlobals";
import {RwPushResp} from "@/app/dem/Push/RwPushMessage";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwError, RwLoginError} from "@/app/RwErrors";
import RwTotalStopCount from "@/app/dem/RwCountRouteStops";
import {RwLoginIssues} from "@/app/RwEnums";

export class RwTaskRoutes {


    static getRouteId: string;


    static getRoutesStatusDeprecated(routeIds: string[]): Promise<RwSyncStatus[] | void> {
        const SOURCE = "RwTaskRoutes.getRoutesStatus";
        return new Promise<RwSyncStatus[] | void>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/routes/status`;
            let uinfo = new RwUser();
            if (uinfo.token && globals.orgId && routeIds && routeIds.length > 0) {

                //console.log(SOURCE, "routeIds:", routeIds);
                let payload = JSON.stringify(routeIds);

                dal.callWithToken
                    .post(url, payload, {timeout: RwConstants.NetTimeout})
                    .then(res => {

                        if (res.data) {
                            const json = res.data;
                            //console.warn(SOURCE, "callback json:", json);
                            let statusList = RwSyncStatus.fromJsonArray(json);
                            resolve(statusList);
                        } else {
                            resolve();
                        }

                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                RwLog.warn(SOURCE, `input: orgId:${globals.orgId} rids:${routeIds} tkn:${uinfo.token}`);
                reject({message: "Error getting route"});
            }
        });
    }


    static setRouteStatusInfo(status: RwSyncStatus): Promise<boolean> {
        const SOURCE = "RwTaskRoutes.setRouteStatusInfo";

        return new Promise<boolean>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/routes/status`;

            let uinfo = new RwUser();
            if (uinfo.token && status && status.routeId, status.driverId) {

                let payload = status.toJSON();
                //let payload = JSON.stringify(routeId);

                dal.callWithToken
                    .put(url, payload, {timeout: RwConstants.NetTimeout})
                    .then(() => resolve(true))
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                RwLog.error(SOURCE, `input: orgId:${globals.orgId}, driverId:${status.driverId}, rids:${status.routeId}, status:${status.statusCode} tkn:${uinfo.token}`);
                //RwLog.error(SOURCE, `input: orgId:${globals.orgId}, driverId:${status.driverId}, rids:${status.routeId}, status:${status.statusCode} tkn:${uinfo.token}`);
                reject({message: "Missing required inputs"});
            }
        });
    }


    static setRouteAssignment(status: RwSyncStatus): Promise<RwPushResp[] | void> {
        const SOURCE = "RwTaskRoutes.setRouteAssignment";

        return new Promise<RwPushResp[] | void>(function (resolve, reject) {

            if (dal.gotToken()) {

                let url = `${RwConstants.CoreUri}/routes/assign`;
                let payload = status.toJSON();
                //let payload = JSON.stringify(routeId);
                if (status && status.routeId && status.driverId) {
                    dal.callWithToken
                        .put(url, payload, {timeout: RwConstants.NetTimeout})
                        .then((assignResp) => {
                            if (assignResp && assignResp.data) {
                                let data = assignResp.data;
                                //console.log("AssignResp: data: ", data);
                                let results = RwPushResp.fromJsonArray(data);
                                resolve(results);
                            } else {
                                resolve()
                            }

                        })
                        .catch((error) => {
                            reject(error);
                        });

                } else {
                    RwLog.warn(SOURCE, `input: orgId:${globals.orgId}, driverId:${status.driverId}, rids:${status.routeId}, status:${status.statusCode}`);
                    reject({message: "Error updating status"});
                }
            } else {
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }


    static getRoute(route: RwRoute): Promise<RwRoute> {
        const SOURCE = "RwTaskRoutes.getRoute";
        return new Promise<RwRoute>(function (resolve, reject) {
            const routeId = route.routeId;
            if (RwTaskRoutes.getRouteId != routeId) {
                const orgId = globals.orgId;
                const url = `${RwConstants.CoreUri}/routes/routes?uid=${orgId}&rid=${routeId}`;
                RwTaskRoutes.getRouteId = routeId;
                if (orgId && routeId && dal.gotToken()) {
                    dal.callWithToken
                        .get(url, {timeout: RwConstants.NetTimeout})
                        .then(res => {
                            RwTaskRoutes.getRouteId = undefined;
                            const json = res.data;
                            //console.log(SOURCE, "getRoute", json);
                            route.fromJson(json);
                            if (route.loadTimeStops === 0) {
                                route.loadTimeStops = Date.now();
                            }
                            globals.updateRoute(route);
                            resolve(route);
                        })
                        .catch((error) => {
                            RwTaskRoutes.getRouteId = undefined;
                            reject(error);
                        });
                } else {
                    RwTaskRoutes.getRouteId = undefined;
                    const msg = `Missing variable orgId:${orgId} rid:${routeId} tkn:${RwPrefUtils.token}, loggedin: ${globals.isLoggedIn}`;
                    // Org is set to undefined when logging out, only error if called while logged in
                    if (globals.isLoggedIn) {
                        RwLog.error(SOURCE, msg);
                    } else {
                        RwLog.warn(SOURCE, msg);
                    }

                    reject({message: `Error getting route \n\n ${msg}`});
                }
            } else {
                //const msg = `Missing variable orgId:${store.orgId} rid:${routeId} tkn:${uinfo.token}`;
                //RwLog.err(SOURCE, msg);
                //console.warn("getRoute In Process");
                reject({message: "GetRoute already in progress"});
            }
        });
    }

    // Only gets full route, no global side effects
    static getRouteStandalone(route: RwRoute): Promise<RwRoute> {
        const SOURCE = "RwTaskRoutes.getRouteStandalone";
        return new Promise<RwRoute>(function (resolve, reject) {
            const routeId = route.routeId;
            let uinfo = new RwUser();
            const url = `${RwConstants.CoreUri}/routes/routes?uid=${globals.orgId}&rid=${routeId}`;
            if (!uinfo.token || uinfo.token === RwConstants.EmptyGuid) {
                return reject(new RwError(200, true, `Invalid Session - Token is ${typeof uinfo?.token || "undefined"}`));
            }
            if (!globals.orgId || globals.orgId === RwConstants.EmptyGuid) {
                return reject(new RwError(200, true, `Invalid Org Id - Org Id is ${typeof uinfo?.token || "undefined"}`));
            }

            if (globals.orgId && routeId && uinfo.token) {
                dal.callWithToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        const json = res.data;
                        route.fromJson(json);
                        if (route.loadTimeStops === 0) {
                            route.loadTimeStops = Date.now();
                        }
                        resolve(route);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                const msg = `Missing variable orgId:${globals.orgId} rid:${routeId} tkn:${uinfo.token}`;
                RwLog.error(SOURCE, msg);
                reject({message: "Error getting route"});
            }
        });
    }

    static addRoute(route: RwRoute, withStops?: boolean): Promise<RwModel> {
        const SOURCE = "RwTaskRoutes.addRoute";

        return new Promise<RwModel>(function (resolve, reject) {

            if (!withStops) withStops = false;
            if (!route.accountId) route.accountId = globals.orgId;

            let json = withStops ? route.toJsonWithAllStops() : route.toJSON();
            let payload = JSON.stringify(json);
            let uinfo = new RwUser();
            let url = `${RwConstants.CoreUri}/routes/routes?uid=${globals.orgId}&rid=${route.routeId}&ls=${globals.lastSyncMS}`;
            if (!uinfo.token || uinfo.token === RwConstants.EmptyGuid) return reject(new RwError(200, true, `Invalid Session - Token is ${typeof uinfo?.token || "undefined"}`));
            if (globals.orgId && route.routeId) {
                dal.callWithToken
                    .post(url, payload, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        if (res) {
                            if (res.data) {
                                const json = res.data;
                                let deltas = new RwModel();
                                deltas.fromJson(json);
                                resolve(deltas);
                            } else {
                                RwLog.warn(SOURCE, `POST url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}  \n\npayload:\n${payload}`)
                                reject(new RwError(400, false));
                            }
                        } else {
                            RwLog.error(SOURCE, `POST url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}  \n\npayload:\n${payload}`)
                            reject(new RwError(400, false));
                        }

                    })
                    .catch((error) => {
                        reject(error);
                    });

            } else {
                let message = `Missing variable orgId:${globals.orgId} rid:${route.routeId} tkn:${uinfo.token}`;
                RwLog.error("RwTaskRoutes.addRouteAsync", message);
                reject({message: "Error adding new route"});
            }
        });
    }


    static updateRoute(route: RwRoute, withStops?: boolean, touchStops?: boolean): Promise<RwModel> {
        let SOURCE = "RwTaskRoutes.updateRoute";

        return new Promise<RwModel>(function (resolve, reject) {
            if (touchStops == null) {
                touchStops = false;
            }
            if (withStops == null) {
                withStops = false;
            }

            let touchValue = touchStops ? 1 : 0;

            if (!route.accountId) {
                route.accountId = globals.orgId;
            }

            let payload = null;
            if (withStops) {
                let fullJson = route.toJsonWithAllStops();
                payload = JSON.stringify(fullJson);
            } else {
                payload = JSON.stringify(route.toJSON());
            }

            let uinfo = new RwUser();
            if (!uinfo.token || uinfo.token === RwConstants.EmptyGuid) return reject(new RwError(200, true, `Invalid Session - Token is ${typeof uinfo?.token || "undefined"}`));

            let lastSync = globals.lastSyncMS;
            let url = `${RwConstants.CoreUri}/routes/routes?uid=${globals.orgId}&rid=${route.routeId}&ls=${lastSync}&touchStops=${touchValue}`;
            //console.warn(payload)
            //console.warn(url)
            if (globals.orgId && route.routeId && uinfo.token && uinfo.token) {
                dal.callWithToken
                    .put(url, payload, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        if (res) {
                            if (res.data) {
                                const json = res.data;
                                let deltas = new RwModel();
                                deltas.fromJson(json);
                                resolve(deltas);
                            } else {
                                RwLog.warn(SOURCE, `PUT url:${url} -> ${res.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                                reject(new RwError(400, false));
                            }
                        } else {
                            RwLog.error(SOURCE, `PUT url:${url} -> MISSING \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload}`)
                            reject(new RwError(400, false));
                        }

                    })
                    .catch((err) => {
                        if (globals.checkNotHandled(err)) {
                            let status = !!err.Code ? err.Code : err.status;

                            let errText = JSON.stringify(err);
                            let msg = `PUT url:${url} -> ${err.status} \nAuthToken:${RwPrefUtils.token} \n\nPayload:\n${payload} \n\nError:\n${errText}`;
                            if (err.error && err.error.code === "ECONNABORTED" || err.message === "Network Error") {
                                RwLog.warn(SOURCE, `ECONNABORTED: ${msg}`);
                            } else if (status === 403) {
                                RwLog.error(SOURCE, `Forbidden: ${msg}`);
                            } else {
                                RwLog.error(SOURCE, `Unhandled: ${msg}`);
                            }
                            //RwLog.error(SOURCE, `PUT url:${url} -> ${err.status} \nAuthToken:${RwPrefUtils.token}  \n\nPayload:\n${payload} \n\nError:\n${err}`)
                        }
                        reject(err);
                    });

            } else {
                RwLog.warn(
                    "updateRouteAsync",
                    `Missing variable aid:${globals.orgId} rid:${route.routeId} tkn:${uinfo.token}`
                );
                reject({message: "Error updating route"});
            }
        });
    }


    static deleteRoutes(routeIds: string[]): Promise<RwModel> {
        return new Promise<RwModel>(function (resolve, reject) {
            const uinfo = new RwUser();
            if (!uinfo.token || uinfo.token === RwConstants.EmptyGuid) return reject(new RwError(200, true, `Invalid Session - Token is ${typeof uinfo?.token || "undefined"}`));

            if (routeIds) {
                routeIds = routeIds.filter(id => id && id !== "");
                if (routeIds.length > 0) {

                    let payload = JSON.stringify(routeIds);
                    let url = `${RwConstants.CoreUri}/routes/routes?uid=${globals.orgId}&ls=${globals.lastSyncMS}`;
                    dal.callWithToken
                        .delete(url, {data: payload, timeout: RwConstants.NetTimeout})
                        .then(res => {
                            const json = res.data;
                            let deltas = RwModel.fromJson(json);
                            resolve(deltas);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    reject({message: "No routes found to delete"});
                }
            } else {
                reject({message: "No routes found to delete"});
            }
        });
    }


    static countRouteStops(route: RwRoute): Promise<RwTotalStopCount> {
        const SOURCE = "RwTaskRoutes.countRouteStops";

        return new Promise<RwTotalStopCount>(function (resolve, reject) {

            if (dal.gotToken()) {

                if (!route || route.routeId === RwConstants.EmptyGuid) {
                    return reject(new RwError(200, true, `Invalid routeid - routeid is ${route?.routeId}`));
                }
                // let payload = JSON.stringify(stop);
                //console.log('RwTaskStops.updateStop payload', payload);
                let url = `${RwConstants.CoreUri}/routes/stopcount?routeid=${route.routeId}`;
                dal.callWithToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        const data = res.data as RwTotalStopCount;
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    })

            } else {
                RwLog.warn(SOURCE, `Invalid token:${RwPrefUtils.token}, userName:${RwPrefUtils.userName}`);
                let logErr = new RwLoginError(403, true, null, RwLoginIssues.SeshExpired);
                dal.forceLogout(logErr);
                return Promise.reject(logErr);
            }

        });
    }

    static mergeRoutes(routeIds: string[]): Promise<RwStop[]> {
        return new Promise<RwStop[]>(function (resolve, reject) {
            let payload = JSON.stringify(routeIds);

            let url = `${RwConstants.CoreUri}/ops/mergeRoutes`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let stops = RwStop.fromJsonArrayMerge(json);
                    resolve(stops);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static archiveRoutes(routeIds: string[]): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {

            let payload = JSON.stringify(routeIds);
            let url = `${RwConstants.CoreUri}/ops/archiveRoutes`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }


    static copyStops(sourceRtId: string, targetRtId: string, stopIds: string[], isNewRt: boolean = false, rtName: string = ""): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {

            let payload = JSON.stringify(stopIds);
            let url = `${RwConstants.CoreUri}/ops/copyStops?targetRtId=${targetRtId}&sourceRtId=${sourceRtId}&isNewRt=${isNewRt}&rtName=${rtName}`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }


    static moveStops(sourceRtId: string, targetRtId: string, stopIds: string[], isNewRt: boolean = false, rtName: string = ""): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {
            let payload = JSON.stringify(stopIds);
            let uinfo = new RwUser();
            let url = `${RwConstants.CoreUri}/ops/moveStops?&targetRtId=${targetRtId}&sourceRtId=${sourceRtId}&isNewRt=${isNewRt}&rtName=${rtName}`;
            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(true);
                    this.globals.syncDelta();
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });

        });
    }


    static hereOptRoute(route: RwRoute): Promise<RwRoute> {
        return new Promise<RwRoute>(function (resolve, reject) {

            let url = `${RwConstants.CoreUri}/ops/HereOptimize?routeId=${route.routeId}`;
            dal.callWithToken
                .post(url, null, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(route);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    }

}


