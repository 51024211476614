

import {Component, Prop, Vue} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwRoute, RwRouteStatusCodes} from "@/app/dem/RwRoute";


@Component
export default class RwStatusPicker extends Vue {
  @Prop(String) readonly initName: string;
  @Prop(Number) readonly initStatus: number;


  //#region Props

  status: number;

  get globals(): RwGlobals {
    return theGlobals;
  }

  get space(): RwPlannerSpace {
    return thePlanSpace;
  }


  get loading(): boolean {
    return this.globals.isLoading;
  }

  get isDarkMode(): boolean {
    return this.globals.isDarkMode;
  }

  get styleCreated() {
    return RwRoute.getStatusBackground(RwRouteStatusCodes.created);
  }

  get styleActive() {
    return RwRoute.getStatusBackground(RwRouteStatusCodes.active);
  }

  get styleProblem() {
    return RwRoute.getStatusBackground(RwRouteStatusCodes.problem);
  }

  get styleComplete() {
    return RwRoute.getStatusBackground(RwRouteStatusCodes.complete);
  }


  //#endregion Props


  //#region User Events


  onSetCreated() {
    this.status = RwRouteStatusCodes.created;
    this.onStatusPick();
  }

  onSetActive() {
    this.status = RwRouteStatusCodes.active;
    this.onStatusPick();
  }

  onSetProblem() {
    this.status = RwRouteStatusCodes.problem;
    this.onStatusPick();
  }

  onSetComplete() {
    this.status = RwRouteStatusCodes.complete;
    this.onStatusPick();
  }


  onEscape(event: Event) {
    event.stopPropagation();
    this.close();
  }


  onStatusPick() {
    //console.log("RwStatusPicker.onStatusPick status", this.status);
    this.$emit("onStatusPick", this.status);
    this.close();
  }


  onCancel() {
    this.close();
  }


  close() {
    //console.log("RwStatusPicker.close")
    this.globals.m_showStatusPicker = false;
  }


  //#endregion UI Nav


  //#region LifeCycle

  mounted() {
    this.status = this.initStatus;
  }

  //#endregion


}

