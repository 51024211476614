import Vue from "vue";
import VueRouter from "vue-router";
import RwStrings from "@/app/consts/RwStrings";
import RwPages from "@/app/consts/RwPages";

Vue.use(VueRouter);

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
/* webpackChunkName: "routeList" */

const compsOnDemand = [
    {
        path: "/login",
        name: RwPages.Login,
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwLogin.vue"),
        meta: {
            title: "Login - " + RwStrings.BaseTitle
        }
    },
    {
        path: "/referonboard",
        name: RwPages.ReferralOnboard,
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwReferralOnboard.vue"),
        meta: {
            title: "Welcome to RoadWarrior Flex!"
        }
    },
    {
        path: "/resetpassword",
        name: RwPages.ResetPassword,
        props: true,
        component: () => import("@/app/views/account/RwResetPassword.vue"),
        meta: {
            title: `Reset Password - ${RwStrings.BaseTitle}`
        }
    },
    {
        path: "/forgotpassword",
        name: RwPages.ForgotPassword,
        props: true,
        component: () => import("@/app/views/account/RwForgotPassword.vue"),
        meta: {
            title: `Forgot Password - ${RwStrings.BaseTitle}`
        }
    },
    {
        path: "/unsubscribe",
        name: RwPages.Unsubscribe,
        props: true,
        component: () => import("@/app/views/account/RwUnsubscribe.vue"),
        meta: {
            title: `Unsubscribe - ${RwStrings.BaseTitle}`
        }
    },
    {
        path: "/login/:reason",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwLogin.vue"),
        meta: {
            title: "Login - " + RwStrings.BaseTitle
        }
    },
    {
        path: "/register",
        name: RwPages.Register,
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwRegistration.vue"),
        meta: {
            title: "Registration - " + RwStrings.BaseTitle
        }
    },
    {
        path: "/purchaseSuccess",
        name: RwPages.CompleteRegistration,
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwPurchaseSuccess.vue"),
        meta: {
            title: "Complete Registration - " + RwStrings.BaseTitle
        }
    },
    {
        path: "/upgrade",
        name: RwPages.Upgrade,
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwUpgrade.vue"),
        meta: {
            title: "Upgrade - " + RwStrings.BaseTitle
        }
    },
    {
        path: "/help",
        name: "help",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwHelp.vue")
    },
    {
        path: "/account",
        name: "account",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwAccount.vue")
    },
    {
        path: "/pro",
        name: "pro",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwProInfo.vue")
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwProfile.vue")
    },
    {
        path: "/",
        redirect: '/planner', //Required to avoid double mounting planner
        name: "home",
        component: () => import(/* webpackChunkName: "planner" */ "@/app/views/planner/RwPlanner.vue")
    },
    {
        path: "/planner",
        name: "planner",
        component: () => import(/* webpackChunkName: "planner" */ "@/app/views/planner/RwPlanner.vue")
    },
    {
        path: "/planner/:routeId",
        component: () => import(/* webpackChunkName: "planner" */ "@/app/views/planner/RwPlanner.vue")
    },
    {
        path: "/routes",
        name: "routes",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/routes/RwRouteList.vue")
    },
    {
        path: "/sites",
        name: "sites",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/sites/RwSiteList.vue")
    },
    {
        path: "/tasks",
        name: "tasks",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/tasks/RwTaskList.vue")
    },
    {
        path: "/skeds",
        name: "skeds",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/skeds/RwSkedList.vue")
    },
    {
        path: "/reports",
        name: "reports",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/reports/RwReporting.vue")
    },
    {
        path: "/drivers",
        name: "drivers",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/drivers/RwDriverList.vue")
    },
    {
        path: "/subteams",
        name: "subteams",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/subteams/RwSubTeamList.vue")
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/account/RwSettings.vue")
    },
    {
        path: "/uploads",
        name: RwPages.Uploads,
        component: () => import(/* webpackChunkName: "upload" */ "@/app/views/uploads/RwUploads.vue"),
        meta: {
            title: "Uploads - " + RwStrings.BaseTitle
        },
        alias: '/fedex'
    },

    {
        path: "/logs",
        name: "logs",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/admin/RwLogReports.vue")
    },
    {
        path: "/logs/:logId",
        name: "logEntry",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/admin/RwLogView.vue")
    },
    {
        path: "/users/:userId",
        name: "userView",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/admin/RwUserView.vue")
    },
    {
        path: "/adds",
        name: "adds",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/admin/RwAddReports.vue")
    },
    {
        path: "/adds/:reportId",
        name: "addReport",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/admin/RwAddReview.vue")
    },
    // {
    //   path: "/adds/:address",
    //   name: "addReport",
    //   component: () => import(/* webpackChunkName: "other" */ "@/app/views/admin/RwAddReview.vue")
    // },
    {
        path: "/marketplace",
        name: "marketplace",
        component: () => import(/* webpackChunkName: "other" */ "@/app/views/marketplace/RwMarketplace.vue")
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: compsOnDemand
});

export default router;
