
import { Component, Prop, Vue } from "vue-property-decorator";
import { RwCoreMedia } from "@/app/dem/ProofOfDelivery/RwCoreMedia";
import { ConfirmationType } from "@/app/dem/ProofOfDelivery/ProofOfDeliveryEnums";
import theGlobals, { RwGlobals } from "@/app/RwGlobals";

@Component({
    components: {}
})
export default class RwPODThumbnail extends Vue {
    @Prop() mode: "checkbox" | "requested" | "fulfill" | "edit";
    @Prop() type: "none" | "photo" | "barcode" | "signature";
    @Prop({ required: false, default: false }) checked: boolean;
    @Prop({ required: false, default: undefined }) media: RwCoreMedia;
    @Prop({ required: false, default: undefined }) quantity: number;
    @Prop({ required: false, default: undefined }) customWidth: string | undefined;
    @Prop({ required: false, default: undefined }) size: 'small' | undefined;

    readonly WIDTH = "124px";
    readonly WIDTH_LARGE = "168px";
    readonly HEIGHT = "124px";

    viewingPOD = false;

    ConfirmationType = ConfirmationType;

    get globals(): RwGlobals {
        return theGlobals;
    }

    get width(): string {
        return this.customWidth ? this.customWidth : this.size === 'small' ? '110px' : this.mode === "checkbox" ? this.WIDTH : this.WIDTH_LARGE;
    }

    get height(): string {
        return this.size === 'small' ? '82px' : this.HEIGHT;
    }

    get checkSize(): string {
        return this.size === 'small' ? '14px' : '20px';
    }

    get checkFontSize(): string {
        return this.size === 'small' ? 'small' : 'large';
    }

    get border(): string {
        return this.mode === "checkbox" ? this.checked && this.globals.isDarkMode ? "solid 2px #A3C5FF" : this.checked ? "solid 2px #4285F4" : "none" : "none";
    }

    get checkboxBackColor(): string {
        return this.checked ? "#4285F4" : "#B7B7B7";
    }

    get backgroundColor(): string {
        if (this.globals.isDarkMode) {
            return !this.media ? '#4E4C4C' : 'white';
        }
        return this.mode === "checkbox" && this.checked || this.mode === "requested" ? "#FAFAFA" : "#ECECEC";
    }

    get backgroundImage(): string {
        if (this.mode === "checkbox") {
            return `/images/icons/pod_icon_${this.type}${this.checked ? "_selected" : "_select"}${this.globals.isDarkMode ? "_dark" : ""}.svg`;
        }
        else if (this.mode === "requested") {
            return `/images/icons/pod_icon_${this.type}${this.checked ? "_selected" : ""}${this.globals.isDarkMode ? "_dark" : ""}.svg`;
        } else if (this.mode === "fulfill" || this.mode === "edit") {
            return this.media ? RwCoreMedia.getLink(this.media) : `/images/icons/pod_icon_${this.type}${this.checked ? "_selected" : ""}${this.globals.isDarkMode ? "_dark" : ""}.svg`;
        }
    }

    get showBackgroundImage(): boolean {
        return !!((this.mode === "fulfill" || this.mode === "edit") && this.media);
    }

    openViewPOD() {
        if (!!this.media) this.viewingPOD = true;
    }

    closeViewPOD() {
        this.viewingPOD = false;
    }

    getMediaLink(): string {
        return RwCoreMedia.getLink(this.media);
    }

    getCurrentMediaBorder(): string {
        if (this.media.confType === ConfirmationType.Signature) {
            return 'solid 3px #B7B7B7';
        }
        return 'none';
    }

    getCurrentMediaBorderRadius(): string {
        return '8px';
    }
}
