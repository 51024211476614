export enum RwTravelModes {
    driving,
    biking,
    walking,
}


export enum RwSnackType {
    Info,
    Warn,
    Error,
    Primary
}

export enum RwLogLevels {
    None,
    Error,
    Warn,
    Info
}


export enum RwRouteCopyType {
    AllStops,
    ActiveStops,
    NoStops,
}


export enum RwLoginIssues {
    None,
    Unknown,
    InvalidCreds,
    SeshExpired,
    LoginDisabled,
    SeshAlt,
    RefreshFailed,
    SeshInvalid,
    NeedsReset
}

export enum RwNavPaths {
    Dashboard,
    Planner,
    Routes,
    Drivers,
    Sites,
    Schedules,
    Uploads,
    Settings
}


//from server
//public enum RwPinTypes { Route, Stop, Task, Site, Sked, Driver }

export enum RwPinTypes {
    Route,
    Stop,
    Task,
    Site,
    Sked,
    Driver,
    Target,
}

export enum RwPinSubTypesRoute {
    //xREF: RwPinSubTypesRoute
    StartStaticOneWay,
    StartDynamic,
    StartStaticRoundTrip,
}

export enum RwPinSubTypesStop {
    Stop,
    CheckIn,
    DoNotEnter,
    Cluster,
    Infeasible = 8
}

export enum RwCheckSubTeamMigrateError {
    None = 0,
    Legacy = 1,
    InvalidCreds = 2,
    InvalidAccountType = 3,
    InvalidMigrateAccount = 4
}

export enum RwCreateDriversError {
    None = 0,
    ChargifySubNotFound,
    ChargifyOtherError,
    PaymentError,
    InvalidUserNameFormat,
    DriverAlreadyExists,
    UnknownDriverCreationErrors,
    NoDomainSet
}

export enum CreateSubTeamError {
    None = 0,
    InternalError = 1,
    UnknownError = 2,
    AuthError = 3,
    InvalidModel = 4,
    LegacySub = 5,
    InvalidDispEmail = 6,
    DomainInUse = 7,
    SubTeamExistsActive = 8,
    DispatcherExists = 9,
    PaymentError = 10,
    DispatcherCreationError = 11
}

// export enum RwPinTypesLegacy {
//   Stop,
//   Site,
//   Start,
//   Star,
//   SkedAlarm,
//   Cluster,
//   Driver,
//   End,
//   Task
// }

export enum RwHistTypes {
    NONE,
    Appt,
    CheckIn,
    Nav,
    Text,
    Phone,
    CheckOut
}

export enum RwSkedTypes {
    None,
    Weekly, // Site Availability
    Appointed, //deprecated
    MultiUse,
    AdHoc, //deprecated by inline skeds on Stops
}

export enum RwStopTypes {
    AdHoc,
    Unappointed,
    Appointed
}

export enum RwDaysOfWeek {
    None = 0,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday
}

export enum RwDistUnits {
    Kilometers,
    Miles
}

export enum RwEditModes {
    EditMode,
    AddMode
}

export enum RwLimitType {
    None,
    RouteSize,
    TotalStops,
    TotalSearches
}

export enum RwFavoriteFilterType {
    All,
    Open,
    None
}

export enum RwStopFilterType {
    All,
    ActiveStopsOnly,
    None
}

export enum RwSearchType {
    Web,
    Contacts,
    Sites,
    SearchClick,
    Coord,
    Tasks
}

export enum RwStopVios {
    None = 0,
    Early = 1,
    Late = 2,
    Closed = 4,
    Unreachable = 8,
    Overtime = 16
}

export enum OptType {
    Distance,
    RoadDistance,
    RoadTime
}

export enum DisplayMessageType {
    Error = 0,
    Success = 1,
    Info = 2
}

export enum LocationPickerOrigin {
    SettingsDefaultDynLoc = 0,
    PlannerDefaultDynLoc = 1,
    ZoneCenterLoc = 2,
    CheckinLoc = 3,
    ZoneAreaDefine = 4,
    RelocateFavorite = 5,
    ResolveUploadLoc = 6
}

export enum TimelineScale {
    OneHour = 0,
    FourHours = 1,
    EightHours = 2,
    OneDay = 3
}

export enum TimelineItemType {
    Stop = 0,
    DriveLine = 1
}

export enum ImageSource {
    Unknown = 0,
    PNG = 1,
    JPG = 2,
}

// Sort
export enum DriverSortOrder {
    Name = 0,
    DateCreated = 1,
    LastUpdated = 2,
    Username = 3,
    AccessType = 4,
}

export enum RouteSortOrder {
    Name = 0,
    StartDate = 1,
    LastUpdated = 2,
    Driver = 3,
    Status = 4,
    TotalTime = 5,
    Distance = 6
}

export enum SubTeamSortOrder {
    Name = 0,
    Dispatcher = 1,
    Domain = 2,
    Drivers = 3,
    LastUpdated = 4,
}

export enum SiteSortOrder {
    Name = 0,
    Address = 1,
    Color = 2,
    CheckIn = 3,
    VisitTime = 4
}

export enum TaskSortOrder {
    Name = 0,
    Address = 1,
    Color = 2,
    VisitTime = 3
}

export enum SkedSortOrder {
    Name = 0,
    Priority = 1,
    Start = 2,
    End = 3
}


export enum SortDirection {
    Desc = 0,
    Asc = 1
}

export enum SchedSortOrder {
    Name = 0,
    Priority = 1
}

export enum FavSortOrder {
    Name = 0,
    Color = 1,
    LastCheckIn = 2
}

export enum HistSortOrder {
    Date = 0,
    Name = 1,
    Driver = 2
}

export enum RouteListTarget {
    Copy = 0,
    Move = 1
}

export enum RwAccessTypes {
    Restricted = 0,
    Normal = 1,
    Elevated = 2,
    SubDisp = 3
}

export enum RwRoleTypes {
    Admin = -1,
    Owner = 0,
    Driver = 1,
}

export enum RwPortType {
    Roof = 0,
    Road = 1,
    PedPrimary = 2,
    PedSecondary = 3,
    Parking = 4,
    Service = 5,
    Loading = 6
}

export enum RwAddressValidator {
    HERE = 0,
    User = 1,
    RoadWarrior = 2
}

export enum UploadType {
    Favorites = 0,
    Route = 1,
    OrderedRoute = 2,
    Tasks = 3,
    ReportingBackup = 4,
    Fedex = 5,
    OnTrac = 6
}

export enum UploadErrorType {
    None = 0,
    //None = 0,
    IncompatibleFile,
    MissingInvalidData,
    InvalidContentType,
    NoUploadFileSent,
    NoFileSelected,
    ErrorReadingFileInfo,
    UnauthorizedMissingToken,
    UnauthorizedSeshExpired,
    OverSizeLimit,
    OverUsageQuota,
    TemplateMismatch,
    HeaderIncorrect,
    HeaderMissing,
    BlankCell,
    EmptyRow,
    ExcelError,
    GeoCodeError,
    SaveError,
    GeocodingErrorsFatal,
    GeocodingErrorsResolvable,
    HasHeaderErrors,
    HasDataErrors,
    NoAddresses,
    FileNotSupported,
    RequiresDispatcher,
    MissingBatchId,
    MissingBlobId,
    ManifestTypeUnknown,
    UnhandledError,
    DriverCountWarning,
    DriverCountError,
    NeedsToAuthenticate,
    FedExAuthPermError,
    FedExExternalApiError,
    OnTracNoRsps,
    OnTracError,
    FedExError
}

export enum RwExtType {
    None = 0,
    FedEx = 1,
    OnTrac = 2
}

export enum ManifestSource {
    FedEx = 0,
    OnTrac = 1
}

export enum ManifestSetSeq {
    None = 0,
    Vendor = 1,
    SID = 2
}

export enum UploadErrorResolution {
    ErrorOut = 0,
    PickGPS = 1,
    Skip = 2
}

export enum RwPushNoteType {
    RouteAssigned,
    UploadRoute,
    UploadFavorites,
    DriverDelta,
    Logout,
    PushTest,
    RouteDelta,
    RouteReassigned,
    RouteDeltas,
}

export enum RwPushStatus {
    Error = -2,
    Gone = -1,
    Sent,
    Delivered,
    Opened
}

export enum RwEntityTypes {
    Route,
    Stop,
    Site,
    Sked,
    History,
    User,
    Task
}

export enum RwTxnTypes {
    NONE,
    GET,
    POST,
    PUT,
    DELETE
}

export enum ManifestUploadStage {
    Initial = 0,
    Login = 1,
    SelectId = 2,
    SelectRoutes = 3
}

export enum RwMultiMode {
    none = 0,
    stops = 1,
    sites = 2,
    tasks = 3,
}


export enum RwMutiModeTool {
    click = 0,
    lasso = 1,
}

export enum ManifestLimitIssue {
    None,
    Warning,
    Error
}

export enum OnboardingTab {
    Welcome = 0,
    PrimeGPSAccess = 1,
    ConfirmStartLoc = 2,
    StepperDefaults = 3,
    StopColor = 4,
    TaskColor = 5,
    FavoriteColor = 6,
    DistanceUnits = 7,
    OptimizeMode = 8,
    TravelMode = 9,
    RouteType = 10,
    Avoids = 11,
    VisitTime = 12,
    RouteTime = 13,
    GrantLocationPermission = 14
}

export enum OnboardingSteps {
    Colors = 1,
    Routes = 2,
    Times = 3,
    Notifications = 4,
    Confirm = 5
}

export enum flexDisplay {
    Solo = 0,
    Team = 1,
    ParentTeam = 2
}

export enum OptInPref {
    Decline = -1,
    NotSelected = 0,
    OptedIn = 1
}

export enum ResumeAutoRenewError {
    None = 0,
    SubscriptionEnded = 1,
    NoSubscriptionFound = 2,
    UnhandledError = 3,
    InvalidSession = 4
}

export enum NotifyErrorEnum {
    None = 0,
    NoPushTargetsFound = 1,
    UnhandledInternalError = 2
}

export enum VersionOutdatedEnum {
    Current,
    NeedsUpdate,
    NeedsUpdateSevere
}

export enum RwApiAuthError {
    None = 0,
    NoFlexSub = 1,
    NotDispatcher = 2,
    InternalError = 3,
    AuthError = 4
}

export enum ResubErrorType {
    None = 0,
    InternalError = 1,
    InvalidModel = 2,
    ExpiredSession = 3,
    NotDispatcher = 4,
    PrevSubNotFound = 5,
    ActiveFlexFound = 6,
    PaymentUpdateReq = 7,
    PaymentInfoNotFound = 8,
    CreatingSubError = 9,
    RestoringDriversError = 10,
    RestoringSubTeamError = 11
}

export enum OntLoginError {
    None = 0,
    NotDispatcher = 1,
    LockedOut = 2,
    InvalidUsernamePW = 3,
    PasswordExpired = 4,
    UnhandledError = 5,
    InvalidSesh = 6,
    RwOntIdMismatch = 7
}

export enum ReplaceDriverError {
    None = 0,
    UnhandledError = 1,
    InvalidUserNamePassword = 2,
    InvalidDriverToReplace = 3,
    SubTeamError = 4,
    NewDriverAlreadyExists = 5,
    CreatingUserError = 6,
    DeletingUserError = 7,
    RestoringUserError = 8
}

export enum StopStatus {
    // Not Completed
    NotCompleted = 0,
    NobodyThere = 1,
    NotOpen = 2,
    IncorrectAddress = 3,
    AddressUnreachable = 4,
    CustomerRefused = 5,
    ComeBackAnotherDay = 6,
    DoNotReattempt = 7,
    NotCompletedOther = 8,
    //Completed
    Completed = 9,
    LeftFrontDoorPorch = 10,
    LeftGateLobby = 11,
    LeftMailArea = 12,
    HandedToCust = 13,
    SigCollected = 14,
    SvcRendered = 15,
    PackagePickedUp = 16,
    //Communicated
    CommWithContact = 17,
    SentTextEmail = 18,
    LeftVoicemail = 19,
    SpokeOnPhone = 20,
    SpokeDirect = 21,
    LeftNote = 22,
    //Other
    Other = 23,
    AddressUpdated = 24,
    NewDelivery = 25,
    NewCustomer = 26
}

export enum RwSetNewEmailEnum {
    Success = 0,
    InvalidOrTakenEmail = 1,
    InvalidLogin = 2,
    InternalError = 3
}

export enum RwLogsSortOrder {
    Sev = 0,
    Date = 1,
    User = 2,
    Device = 3,
    Source = 4,
    Message = 5
}

export enum SubscriptionState {
    Trialing = 0,
    Trial_Ended = 1,
    Assessing = 2,
    Active = 3,
    Soft_Failure = 4,
    Past_Due = 5,
    Suspended = 6,
    Canceled = 7,
    Expired = 8,
    Unpaid = 9,
    Paid = 10,
    Partial = 11,
    On_Hold = 12,
    Paused = 13,
    Unknown = -1
}

export enum RwAddStatusEnum { Complete = -1, Pending = 0, WIP = 1 }

export enum RwAddVoteEnum { Rejected = -1, Indeterminate = 0, Accepted = 1 }

export enum RwAddReportEnum { Correction, Missing }

export enum RwApplyCouponError {
    None = "None",
    Unknown = "An unknown error happened applying the coupon code",
    InvalidCoupon = "The coupon code is invalid",
    AlreadyApplied = "The coupon code is already applied",
    Expired = "The coupon code is expired",
    SubscriptionNotFound = "There was no subscription found to apply the coupon"
}
