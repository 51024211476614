import Vue from "vue";
//import Vuetify from "vuetify";
import Vuetify from 'vuetify/lib'
import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition';

const fadeTransition = createSimpleTransition('callout-transition')
Vue.component('callout-transition', fadeTransition)

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: "#E2004F",
                secondary: "#B40048",
                accent: "#E91E63",
                error: "#ec0f0f",
                background: "#333333"
            },
            light: {
                primary: "#E2004F",
                secondary: "#B40048",
                accent: "#E91E63",
                error: "#b71c1c",
                background: "#eeeeee"
            },

        }
    }
});
