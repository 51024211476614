import {RwUser} from "@/app/dem/RwUser";
import {RwHistory} from "@/app/dem/RwHistory";
import {RwModel} from "@/app/dem/RwModel";
import {RwConstants} from "@/app/RwConstants";
import dal from "@/app/dal/RwDal";
import store from "@/app/RwGlobals";
import {RwSysUtils} from "@/app/utils/RwSysUtils";

export class RwTaskHistory {
    static getHistoryItem(histId: string): Promise<RwHistory> {
        return new Promise<RwHistory>((resolve, reject) => {
            if (histId && histId !== RwSysUtils.guidEmpty()) {
                let url = `${RwConstants.CoreUri}/hist/histitem?histId=${histId}`;
                //RwLog.consoleLog("addActAsync: ", url);

                dal.callWithToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        const json = res.data;
                        const hist = new RwHistory(json);
                        resolve(hist);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject(new Error("Invalid hist id"));
            }
        });
    }

    static getHistoryItemForStop(stopId: string): Promise<RwHistory> {
        return new Promise<RwHistory>((resolve, reject) => {
            if (stopId && stopId !== RwSysUtils.guidEmpty()) {
                let url = `${RwConstants.CoreUri}/hist/stophistitem?stopId=${stopId}`;
                //RwLog.consoleLog("addActAsync: ", url);

                dal.callWithToken
                    .get(url, {timeout: RwConstants.NetTimeout})
                    .then(res => {
                        const json = res.data;
                        const hist = new RwHistory(json);
                        resolve(hist);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject(new Error("Invalid stop id"));
            }
        });
    }

    static addHistory(act: RwHistory): Promise<RwModel> {
        return new Promise<RwModel>(function (resolve, reject) {

            if (!act.BaseColor) act.BaseColor = RwConstants.CheckinPinGrey;
            let payload = JSON.stringify(act.toJSON());
            let url = `${RwConstants.CoreUri}/hist/hist?uid=${store.orgId}&aid=${act.histId}&ls=${store.lastSyncMS}`;
            //RwLog.consoleLog("addActAsync: ", url);

            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let deltas = new RwModel();
                    deltas.fromJson(json);
                    resolve(deltas);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }

    static updateHistoryAsync(act: RwHistory): Promise<RwModel> {
        return new Promise<RwModel>(function (resolve, reject) {
            let payload = JSON.stringify(act.toJSON());
            let url = `${RwConstants.CoreUri}/hist/hist?uid=${store.orgId}&aid=${act.histId}&ls=${store.lastSyncMS}`;
            //RwLog.consoleLog("updateActAsync: ", url);

            dal.callWithToken
                .put(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let deltas = new RwModel();
                    deltas.fromJson(json);
                    resolve(deltas);
                })
                .catch((error) => {
                    //REFACTOR: UI Code: Move to caller
                    reject(error);
                });
        });
    }

    static deleteHistoryAsync(actIds: string[]): Promise<RwModel> {
        return new Promise<RwModel>(function (resolve, reject) {

            let payload = JSON.stringify(actIds);
            let url = `${RwConstants.CoreUri}/hist/hist?uid=${store.orgId}&ls=${store.lastSyncMS}`;
            dal.callWithToken
                .delete(url, {data: payload, timeout: RwConstants.NetTimeout})
                .then(res => {
                    const json = res.data;
                    let deltas = new RwModel();
                    deltas.fromJson(json);
                    resolve(deltas);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static archiveHistoryAsync(histIds: string[]): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {

            let payload = JSON.stringify(histIds);

            let uinfo = new RwUser();
            let url = `${RwConstants.CoreUri}/ops/ArchiveHistoryItems`;
            //RwLog.logConsole("mergeroutesasync: ", url);

            dal.callWithToken
                .post(url, payload, {timeout: RwConstants.NetTimeout})
                .then(res => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
