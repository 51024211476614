import {RwSearchResult} from "@/app/dem/RwSearchResult";
import {RwPinTypes} from "@/app/RwEnums";

const uploadModule = {
    state: {
        showUploadSearchPanel: false,
        searchResultsUploadWeb: [],
        searchResultsUploadCoords: [],
        uploadMapCentroid: {lat: 0, lng: 0, zoom: 0},
        uploadSearchPinData: {pinType: RwPinTypes.Stop, lat: 0, lng: 0},
        uploadSearchResult: null
    },
    getters: {
        showUploadSearchPanel(state): boolean {
            return state.showUploadSearchPanel;
        },
        searchResultsUploadWeb(state): RwSearchResult[] {
            return state.searchResultsUploadWeb;
        },
        searchResultsUploadCoords(state): RwSearchResult[] {
            return state.searchResultsUploadCoords;
        },
        uploadMapCentroid(state): { lat: number, lng: number, zoom: number } {
            return state.mapCentroid;
        },
        uploadSearchPinData(state): { pinType: RwPinTypes, lat: number, lng: number } {
            return state.uploadSearchPinData;
        },
        uploadSearchResult(state): RwSearchResult {
            return state.uploadSearchResult;
        },
    },
    actions: {
        showUploadSearchPanel({state, commit}, val: boolean) {
            commit("SHOW_UPLOAD_SEARCH_PANEL", val);
        },
        searchResultsUploadWeb({state, commit}, val: RwSearchResult[]) {
            commit("SEARCH_RESULTS_UPLOAD_WEB", val);
        },
        searchResultsUploadCoords({state, commit}, val: RwSearchResult[]) {
            commit("SEARCH_RESULTS_UPLOAD_COORDS", val);
        },
        uploadMapCentroid({state, commit}, val: { lat: number, lng: number, zoom: number }) {
            commit("UPLOAD_MAP_CENTROID", val);
        },
        uploadSearchPinData({state, commit}, val: { pinType: RwPinTypes, lat: number, lng: number }) {
            commit("UPLOAD_SEARCH_PIN_DATA", val);
        },
        uploadSearchResult({state, commit}, val: RwSearchResult) {
            commit("UPLOAD_SEARCH_RESULT", val);
        },

    },
    mutations: {
        SHOW_UPLOAD_SEARCH_PANEL(state, val: boolean) {
            state.showUploadSearchPanel = val;
        },
        SEARCH_RESULTS_UPLOAD_WEB(state, val: RwSearchResult[]) {
            state.searchResultsUploadWeb = val;
        },
        SEARCH_RESULTS_UPLOAD_COORDS(state, val: RwSearchResult[]) {
            state.searchResultsUploadCoords = val;
        },
        UPLOAD_MAP_CENTROID(state, val: { lat: number, lng: number, zoom: number }) {
            state.uploadMapCentroid = val;
        },
        UPLOAD_SEARCH_PIN_DATA(state, val: { pinType: RwPinTypes, lat: number, lng: number }) {
            state.uploadSearchPinData = val;
        },
        UPLOAD_SEARCH_RESULT(state, val: RwSearchResult) {
            state.uploadSearchResult = val;
        },
    }
};

export default uploadModule;