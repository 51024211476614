export default interface RwTrialUpgradeResponseModel {
    NewPurchase: any;
    ErrorType: TrialUpgradeErrorType;
}

export enum TrialUpgradeErrorType {
    None = 0,
    InternalError = 1,
    ExpiredSession = 3,
    NotDispatcher = 4,
    TrialNotFound = 5,
    ActiveFlexFound = 6,
    PaymentUpdateReq = 7,
    PaymentInfoNotFound = 8,
    CreatingSubError = 9,
    ChargifyTrialSubNotFound = 10,
    UpdatingTrialSubError = 11,
}